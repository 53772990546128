import { fonts } from '@styles';
import styled from 'styled-components';

export const StatusLine = styled.div<{ color: string }>`
  width: 4px;
  height: 16px;
  flex-shrink: 0;
  border-radius: 4px;
  background-color: ${({ color }) => color};
`;

export const TooltipContent = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-family: ${fonts.primary};
`;
