import React from 'react';
import { Info } from 'react-feather';
import { FormControlLabel, Checkbox, Tooltip, makeStyles } from '@material-ui/core';
import { Field } from 'formik';
import styled from 'styled-components';
import { colors, checkboxMui } from '@styles';
import { LabelSelector } from '../../Selector/LabelSelector';

const useStylesCheckbox = makeStyles(checkboxMui({ width: '30px', height: '30px' }));

const Wrapper = styled.div``;

const SetColored = styled.div`
  display: flex;
  align-items: center;
`;

type LabelInputTypes = {
  name?: string;
  isColored?: boolean;
  setIsColored?: (value: boolean) => void;
  isDisabled?: boolean;
};

const LabelInput: React.FC<LabelInputTypes> = (props) => {
  const { name, isColored, setIsColored, onFocus, onBlur, isDisabled } = props;

  const classesCheckbox = useStylesCheckbox();

  return (
    <Wrapper data-testid="tags">
      <Field name={name}>
        {({ field, form: { setFieldValue, values } }) => {
          return (
            <LabelSelector
              value={values[field.name]}
              onChange={(value) => setFieldValue(field.name, value)}
              onFocus={onFocus}
              onBlur={onBlur}
              isDisabled={isDisabled}
            />
          );
        }}
      </Field>

      {setIsColored ? (
        <SetColored>
          <FormControlLabel
            control={
              <Checkbox
                classes={classesCheckbox}
                checked={isColored}
                data-analyticsid="addBackgroundColorCheckbox"
                onChange={() => setIsColored(!isColored)}
                style={{ color: colors.gray }}
              />
            }
            label="Add background color"
          />
          <Tooltip title="This uses the color of the first label as the card color" placement="bottom">
            <Info size={16} color={colors.gray} />
          </Tooltip>
        </SetColored>
      ) : null}
    </Wrapper>
  );
};

export default LabelInput;
