import React from 'react';
import { Privilege, Scope, Subject } from '@types';
import { useAppSelector } from '@hooks';
import { selectCompanyIdBySelectedPage, selectCurrentUserId } from '@state/selectors';
import { useUserRoleSettings } from '@hooks/useRoles';
import { hasAccess } from '@utils';

interface ActionAccessProps {
  subject: Subject;
  scope?: Scope;
  privilege: Privilege;
  companyId?: number;
  children: React.ReactNode;
}

/**
 * Shows children only if current user has given privilege for given subject in given scope, or any scope if none given.
 *
 * E.g. show create project button:
 *
 * `<SubjectAccess subject="project" privilege="create">{button}</SubjectAccess>`
 *
 * Show invite members button (workspace settings are scoped differently, see {@link WorkspaceSettingsDTO}):
 *
 * `<SubjectAccess subject="workspace" scope="invite" privilege="view">{button}</SubjectAccess>`
 *
 * See default roles settings if not sure what values to choose.
 */
export const SubjectAccess = (props: ActionAccessProps) => {
  const { children, subject, scope, privilege, companyId: providedCompanyId } = props;

  const selectedCompanyId = useAppSelector(selectCompanyIdBySelectedPage);
  const companyId = providedCompanyId || selectedCompanyId;
  const userId = useAppSelector(selectCurrentUserId);

  const { data: rules } = useUserRoleSettings(companyId, userId);

  return <>{hasAccess(rules!, subject, privilege, scope) && children}</>;
};
