import styled, { css } from 'styled-components';
import { colors } from '@styles';

export const Container = styled.div<{ isFullWidth: boolean; variant: 'default' | 'outline' }>`
  background-color: ${({ variant }) => (variant === 'default' ? '#dfdfe8' : '')};
  border-radius: 8px;
  display: ${({ isFullWidth }) => (isFullWidth ? 'flex' : 'inline-flex')};
  align-items: center;
  gap: ${({ variant }) => (variant === 'default' ? '0' : '16px')};

  > * {
    font-size: 14px;
    color: #1d1d35;
    padding: 7px 12px;

    text-decoration: none;
    flex: 1;
    text-align: center;
  }
`;

export const Tab = styled.div<{ isActive: boolean; variant: 'default' | 'outline'; isDisabled?: boolean }>`
  white-space: nowrap;
  cursor: ${({ isDisabled }) => (isDisabled === true ? 'default' : 'pointer')};

  ${({ variant, isActive, isDisabled }) => {
    switch (variant) {
      case 'default':
        return css`
          border-radius: ${isActive ? '8px' : ''};
          background-color: ${isActive ? '#4C4C59' : ''};
          color: ${isActive ? colors.white : ''};
        `;
      case 'outline': {
        let color = isActive ? '#009688' : '#828D9A';

        if (isDisabled === true) {
          color = '#C8C8D3';
        }

        return css`
          text-transform: uppercase;
          font-weight: 500;
          font-size: 12px;
          padding: 4px 0;
          letter-spacing: 0.05em;
          color: ${color};
          border-bottom: 1px solid ${isActive ? '#009688' : 'transparent'};
        `;
      }
      default:
        return '';
    }
  }}
`;

export const SoonLabel = styled.span`
  font-size: 10px;
  font-weight: 400;
  text-transform: initial;
`;
