import styled, { css } from 'styled-components';
import { fonts, colors } from '@styles';

export const Text = styled.p`
  font-size: 14px;
  line-height: 1.5;
  color: ${colors.gray2};
  margin-bottom: 25px;
`;

export const Label = styled.label`
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 5px;
  display: block;
`;

export const RadioLabel = styled.span`
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  padding-left: 5px;
  font-family: ${fonts.primary};
`;

export const DatePickersWrapper = styled.div`
  margin-top: 25px;
`;

export const Wrapper = styled.div<{ disabled?: boolean }>`
  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.6;
          pointer-events: none;
          cursor: not-allowed;
        `
      : ''}
`;

export const Emoji = styled.div`
  margin-right: 5px;
`;

export const Name = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`;
