import styled from 'styled-components';
import { Link } from '@reach/router';
import { colors } from '@styles';

export const ChipContainer = styled.div`
  border: 1px solid #dfdfe8;
  background-color: #ffffff;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 3px 8px;

  white-space: nowrap;

  font-size: 12px;
  font-weight: 500;

  svg {
    flex-shrink: 0;
  }
`;

export const Container = styled.div``;

export const AutomationsListHeading = styled.div`
  color: #828d9a;
  font-size: 12px;
  font-weight: 500;
  padding: 8px;
`;

export const AutomationRow = styled(Link)<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 4px 8px;

  text-decoration: none;
  cursor: pointer;
  color: ${({ isActive }) => (isActive ? colors.black : '#828D9A')};

  transition: background-color 0.15s ease-in-out;

  &:visited,
  &:link,
  &:active,
  &:hover,
  &:focus {
    text-decoration: none;
    color: ${({ isActive }) => (isActive ? colors.black : '#828D9A')};
  }

  &:hover {
    background-color: #e8ecef;
  }
`;

export const AutomationTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 4px;
  svg {
    flex-shrink: 0;
  }
`;

export const AutomationDescription = styled.div`
  font-size: 12px;
  font-weight: 400;
`;

export const ExpandAutomations = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  font-size: 12px;
  color: #828d9a;
  padding: 8px 4px;
  cursor: pointer;
`;
