import styled, { css } from 'styled-components';
import {
  Triangle,
  Edit as EditIcon,
  Trash2 as TrashIcon,
  ArrowUp as ArrowUpIcon,
  ArrowDown as ArrowDownIcon
} from 'react-feather';
import { TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { colors, fonts, getColorWithOpacity } from '@styles';
import { Wrapper as Actions } from '@components/templates/Workspace/common/Actions/styled';

export const Edit = styled(EditIcon)``;
export const Trash = styled(TrashIcon)``;
export const GroupArrowUp = styled(ArrowUpIcon)``;
export const GroupArrowDown = styled(ArrowDownIcon)``;

export const TContainer = styled(TableContainer)``;

export const PropertyActions = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 8px;
`;

export const TRow = styled(TableRow)<{ hover?: boolean; isReadyToDrop?: boolean; isEmpty?: boolean }>`
  && {
    cursor: pointer;
    background-color: ${(props) =>
      (props.isReadyToDrop && getColorWithOpacity(colors.green, 50)) || (props.hover && colors.gray3) || colors.white};
    transition: 0.3s ease-in-out;

    ${PropertyActions}, ${Actions} {
      transition: 0.2s ease-in-out;
      opacity: 0;
      cursor: pointer;
    }

    &:hover {
      background-color: ${colors.white2};

      ${PropertyActions}, ${Actions} {
        opacity: 1;
      }
    }

    ${({ isEmpty, theme }) =>
      isEmpty
        ? css`
            border: dashed 1px ${theme.colors.gray};
            cursor: initial;
          `
        : ''}
  }
`;

export const ThCell = styled.div`
  display: flex;
  align-items: center;
`;

export const TCell = styled(TableCell)`
  && {
    font-size: 14px;
    line-height: 1.25;
    font-family: ${fonts.primary};
    padding: 8px 12px;
    border-bottom: 2px solid ${colors.white2};
    border-top: 2px solid ${colors.white2};
  }

  &:first-child {
    border-radius: 4px 0 0 4px;
  }

  &:last-child {
    width: 80px;
    border-radius: 0 4px 4px 0;
  }
`;

export const THead = styled(TableHead)`
  && {
    tr {
      background: none;

      &:hover {
        background: none;
      }
    }

    th {
      font-weight: 500;
      font-size: 14px;
      line-height: 1.25;
      font-family: ${fonts.primary};
      color: ${colors.black};
      border-bottom: 1px solid #ddd;
      background-color: #f8fafb;
      z-index: 11;

      &:first-child {
        border-radius: 0;
      }
      &:last-child {
        border-radius: 0;
      }
    }
  }
`;

export const WrapperSort = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  cursor: pointer;

  svg {
    width: 12px;
    height: 12px;
  }
`;

export const ArrowUp = styled(Triangle)<{ active?: boolean }>`
  fill: ${(props) => (props.active ? colors.gray : colors.gray3)};
  stroke: ${(props) => (props.active ? colors.gray : colors.gray3)};
`;

export const ArrowDown = styled(Triangle)<{ active?: boolean }>`
  fill: ${(props) => (props.active ? colors.gray : colors.gray3)};
  stroke: ${(props) => (props.active ? colors.gray : colors.gray3)};
  transform: rotate(180deg);
`;

export const TBody = styled(TableBody)``;

export const User = styled.div`
  display: flex;
  align-items: center;

  font-size: 14px;
  line-height: 17px;

  > span {
    margin-left: 10px;
  }
`;

export const Role = styled.div`
  color: ${colors.black};
  font-weight: 500;
  font-size: 14px;
  line-height: 1.25;
`;

export const Status = styled.div<{ guest?: boolean }>`
  color: ${(props) => (props.guest ? colors.yellow : colors.black)};
  font-weight: 500;
  font-size: 14px;
  line-height: 1.25;
`;

export const Workspace = styled.div`
  display: inline-flex;
  align-items: center;
  background: #f2f4f6;
  border-radius: 4px;
  font-size: 12px;
  line-height: 1.25;
  color: ${colors.black};
  padding: 2px 10px 2px 2px;
`;

export const WorkspaceIcon = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.color};
  border-radius: 4px;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  color: ${colors.white};
  text-transform: uppercase;
  font-weight: 500;
  font-size: 10px;
  line-height: 1.25;
`;

export const AccessNames = styled.div`
  max-width: 200px;
`;

export const AccessName = styled.span`
  text-transform: capitalize;

  &:after {
    content: ', ';
  }
  &:last-child {
    &:after {
      display: none;
    }
  }
`;

export const PropertyName = styled.div`
  display: flex;
  align-items: center;

  span {
    padding-left: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.25;
    color: ${colors.black};
  }
`;

export const TCellWrapper = styled.div`
  display: flex;
  align-items: center;
`;
