import React, { useCallback, useMemo } from 'react';

import { Bar, LabelList, Legend, YAxis } from 'recharts';
import { ProjectStage } from '@generated/types/graphql';
import { formatDuration } from '@utils/dates';
import { ChartBase } from './ChartBase';

import { Container, RightAxisTitles } from './styled';
import { Point } from '../types';
import { StageTick } from './StageTick';
import { NoData } from './NoData';

interface Props {
  points: {
    id: number;
    name: string;
    value: number;
    stage: ProjectStage;
    accumSum: number;
    green: number;
    yellow: number | null;
    red: number | null;
  }[];
  onClick: (point: Point) => void;
}

export const TimelineByStage = ({ points, onClick }: Props) => {
  const stagesMap = useMemo(
    () =>
      points.reduce(
        (acc, point) => {
          acc[point.name] = point.stage;

          return acc;
        },
        {} as Record<number, ProjectStage>
      ),
    [points]
  );

  const handleBarClick = useCallback(
    (data: any) => {
      const point = data.payload;

      onClick({
        x: point.name,
        originalX: point.id,
        isEmpty: false,
        y: point.value
      });
    },
    [onClick]
  );

  if (!points.length) {
    return <NoData />;
  }

  return (
    <Container>
      <RightAxisTitles noWrap>
        <div>Time in Stage</div>
        <div>Total time</div>
      </RightAxisTitles>
      <ChartBase points={points} xAxisTickFormatter={formatDuration}>
        <YAxis
          width={150}
          axisLine={false}
          tickLine={false}
          orientation="left"
          tick={<StageTick stages={stagesMap} />}
          dataKey="name"
          type="category"
          interval={0}
        />

        <YAxis
          width={70}
          stroke="#1D1D35"
          tickFormatter={formatDuration}
          yAxisId="average"
          axisLine={false}
          tickLine={false}
          orientation="right"
          dataKey="value"
          type="category"
          fontSize={12}
          interval={0}
        />
        <YAxis
          width={80}
          yAxisId="accumSum"
          axisLine={false}
          tickLine={false}
          stroke="#828D9A"
          tickFormatter={formatDuration}
          orientation="right"
          dataKey="accumSum"
          type="category"
          fontSize={12}
          interval={0}
        />

        <Bar name="Within SLA" stackId="a" maxBarSize={16} dataKey="green" fill="#71C4C4" onClick={handleBarClick}>
          <LabelList
            formatter={formatDuration}
            style={{ pointerEvents: 'none' }}
            fontSize={12}
            fill="#fff"
            dataKey="green"
            position="inside"
          />
        </Bar>
        <Bar
          name="Yellow SLA violation"
          stackId="a"
          maxBarSize={16}
          dataKey="yellow"
          fill="#F1AA12"
          onClick={handleBarClick}
        >
          <LabelList
            formatter={formatDuration}
            style={{ pointerEvents: 'none' }}
            fontSize={12}
            fill="#fff"
            dataKey="yellow"
            position="inside"
          />
        </Bar>
        <Bar name="Red SLA violation" stackId="a" maxBarSize={16} dataKey="red" fill="#D54855" onClick={handleBarClick}>
          <LabelList
            formatter={formatDuration}
            style={{ pointerEvents: 'none' }}
            fontSize={12}
            fill="#fff"
            dataKey="red"
            position="inside"
          />
        </Bar>

        <Legend iconSize={16} wrapperStyle={{ fontSize: 12 }} align="left" />
      </ChartBase>
    </Container>
  );
};
