import styled from 'styled-components';
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  withStyles
} from '@material-ui/core';
import { scroll } from '@styles';

export const Accordion = withStyles({
  root: {
    border: '1px solid #DFDFE8',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {
    '& .MuiAccordionDetails-root': {
      backgroundColor: '#F7F7FC'
    }
  }
})(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#FFFFFF',
    borderRadius: '4px',
    minHeight: 56,
    overflow: 'hidden',
    alignItems: 'center',
    '&$expanded': {
      minHeight: 56
    }
  },

  content: {
    overflow: 'hidden',
    alignItems: 'center',
    gap: '16px',
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expanded: {
    backgroundColor: '#F7F7FC'
  }
})(MuiAccordionSummary);

export const AccordionDetails = withStyles(() => ({
  root: {
    transition: 'background-color 0.15s ease-in-out',
    padding: '0 0 16px 0',
    '& > div': {
      width: '100%'
    }
  }
}))(MuiAccordionDetails);

export const AddressLink = styled.a`
  color: #235dff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 4px;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover,
  &:active,
  &:focus,
  &:link,
  &:visited {
    color: #235dff;
  }

  svg {
    flex-shrink: 0;
  }

  div {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const TabsContainer = styled.div`
  padding: 0 16px;
  overflow-x: auto;
  scroll-behavior: smooth;
  ${scroll};
`;

export const TabContent = styled.div`
  margin-top: 16px;
`;

export const Placeholder = styled.div`
  color: #828d9a;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
`;

export const Row = styled.div`
  padding: 8px 0;
  display: flex;
  gap: 16px;
  font-size: 14px;
  font-weight: 400;
`;

export const RowContainer = styled.div`
  padding: 0 16px;
  transition: background-color 0.2s ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #fff;
  }

  > * {
    border-bottom: 1px solid #dfdfe8;
  }

  &:last-child {
    > * {
      border-bottom: none;
    }
  }
`;

export const SiteMenuContainer = styled.div`
  padding: 0 4px;
`;

export const AddSiteContentButton = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1d1d35;
  cursor: pointer;
  background-color: #dfdfe8;
`;

export const TabsWithButtonsContainer = styled.div`
  position: relative;
`;

export const TabsScrollButton = styled.div<{ direction: 'left' | 'right'; isVisible: boolean }>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  top: 0;
  bottom: 5px;
  z-index: 1;
  background-color: #f7f7fc;
  padding: 2px;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.15s ease-in-out;
  left: ${({ direction }) => (direction === 'left' ? 0 : 'auto')};
  right: ${({ direction }) => (direction === 'right' ? 0 : 'auto')};
  box-shadow: ${({ direction }) => (direction === 'right' ? '-7px 0 7px 0px #F7F7FC' : '7px 0 7px 0px #F7F7FC')};
`;
