import * as actionTypes from '../../../actions/actionTypes/actionTypes';
import { kanbanReducerType, actionType } from './type';

export const initialState: kanbanReducerType = {
  isLoading: false,
  isUpdating: false,
  error: false,
  kanbanData: [],
  taskPriority: 1,
  projectDetail: {
    title: 'My title',
    address: {
      state: 'CA',
      city: 'Mountain View'
    }
  },
  withArchived: false
};

const fetchKanbanStart = (state: kanbanReducerType, action: actionType) => {
  return { ...state, isLoading: true };
};



const fetchKanbanFailed = (state: kanbanReducerType, action: actionType) => {
  return { ...state, error: action.error };
};

const updateKanbanStart = (state: kanbanReducerType, action: actionType) => {
  return { ...state, isUpdating: true };
};


const updateKanbanFailed = (state: kanbanReducerType, action: actionType) => {
  return {
    ...state,
    ...action.payload,
    isUpdating: false
  };
};
const updateKanban = (state: kanbanReducerType, action: actionType) => {
  return {
    ...state,
    ...action.payload
  };
};

const setKanbanWithArchived = (state: kanbanReducerType, action: actionType) => {
  return {
    ...state,
    withArchived: action.payload
  };
};

const kanbanBoardReducer = (
  state: kanbanReducerType = initialState,
  action: actionType
): kanbanReducerType => {
  switch (action.type) {
    case actionTypes.FETCH_KANBAN_START:
      return fetchKanbanStart(state, action);
    case actionTypes.FETCH_KANBAN_FAILED:
      return fetchKanbanFailed(state, action);
    case actionTypes.UPDATE_KANBAN_START:
      return updateKanbanStart(state, action);
    case actionTypes.UPDATE_KANBAN_FAILED:
      return updateKanbanFailed(state, action);
    case actionTypes.UPDATE_KANBAN:
      return updateKanban(state, action);
    case actionTypes.SET_KANBAN_WITH_ARCHIVED:
      return setKanbanWithArchived(state, action);
    default:
      return state;
  }
};

export default kanbanBoardReducer;
