import React from 'react';

interface Props {
  size?: string | number;
  color?: string;
}

export const UnarchiveIcon = React.forwardRef<SVGSVGElement, Props>(
  ({ size = '16px', color = 'currentColor', ...props }, ref) => (
    <svg
      ref={ref}
      {...props}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5080_333775)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.3957 1.3335C11.7436 1.33358 12.0802 1.45604 12.3468 1.67942L12.4431 1.76757L14.2313 3.55572C14.4772 3.80166 14.6288 4.12629 14.6594 4.47276L14.6654 4.60313V13.1853C14.6655 13.5591 14.5243 13.9191 14.2702 14.1932C14.016 14.4672 13.6677 14.6351 13.295 14.6631L13.1839 14.6668H2.81351C2.43975 14.6669 2.07976 14.5258 1.8057 14.2716C1.53164 14.0175 1.36377 13.6692 1.33574 13.2965L1.33203 13.1853V4.60313C1.33212 4.25531 1.45458 3.91861 1.67796 3.65201L1.76611 3.55572L3.55425 1.76757C3.8002 1.52163 4.12483 1.37007 4.47129 1.33942L4.60166 1.3335H11.3957ZM13.1839 5.77794H2.81351V13.1853H13.1839V5.77794ZM7.9987 6.81498C8.16151 6.81496 8.31979 6.86859 8.44907 6.96757L8.52314 7.03201L10.0935 8.60313C10.2278 8.73612 10.3061 8.9154 10.3125 9.10427C10.3189 9.29314 10.2529 9.47732 10.128 9.61911C10.003 9.7609 9.82864 9.84957 9.64046 9.86698C9.45228 9.88439 9.26457 9.82922 9.11574 9.71276L9.04611 9.65053L8.73944 9.34387V11.7039C8.73923 11.8927 8.66694 12.0743 8.53733 12.2115C8.40772 12.3488 8.23058 12.4314 8.04211 12.4425C7.85363 12.4536 7.66805 12.3923 7.52327 12.2711C7.37849 12.1499 7.28544 11.978 7.26314 11.7905L7.25796 11.7039V9.34387L6.95129 9.65053C6.8183 9.7848 6.63902 9.86315 6.45014 9.86956C6.26127 9.87597 6.07709 9.80994 5.93531 9.685C5.79352 9.56006 5.70484 9.38566 5.68744 9.19748C5.67003 9.0093 5.7252 8.82159 5.84166 8.67276L5.90388 8.60313L7.47425 7.03201C7.54311 6.96314 7.62487 6.90851 7.71486 6.87127C7.80485 6.83403 7.90131 6.8149 7.9987 6.81498ZM11.3957 2.81498H4.60166L3.12018 4.29646H12.8772L11.3957 2.81498Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_5080_333775">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
);
