import styled from 'styled-components';
import { SwitchVariant } from './types';

export const Container = styled.div<{ isReverse: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: ${({ isReverse }) => (isReverse ? 'row-reverse' : 'row')};
  gap: 8px;
`;

export const Toggle = styled.div<{ isActive: boolean; variant: SwitchVariant }>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  background: ${(props) =>
    props.variant === SwitchVariant.TwoWay || props.isActive
      ? 'linear-gradient(90deg, #009688 0%, #19C9B9 100%)'
      : '#C8C8D3'};
  box-shadow: 0px 2.76923px 5.53846px rgba(44, 39, 56, 0.16);
  left: ${(props) => (props.isActive ? '21px' : '2px')};

  /* Add transition for smooth animation */
  transition:
    background 0.3s ease,
    left 0.3s ease;
`;

export const SwitchContainer = styled.div<{ isActive: boolean; isDisabled: boolean }>`
  width: 40px;
  flex-shrink: 0;
  height: 20px;
  border-radius: 40px;
  border: 1px solid #c8c8d3;
  padding: 1px;
  position: relative;
  cursor: pointer;
  background: #fff;

  /* Add transition for smooth animation */
  transition: background 0.3s ease;

  ${({ isDisabled }) =>
    isDisabled &&
    `
    cursor: not-allowed;
    opacity: 0.4;
  `};
`;

export const Label = styled.label<{ isActive: boolean }>`
  color: ${(props) => (props.isActive ? '#000' : '#828D9A')};
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
`;
