import post from '@services/api/base/post';
import { Comment } from '@generated/types/graphql';
import { Urls } from '@services/api/base/axios';
import { CommentCreateDto, CommentPinDto, CommentUpdateDto } from '@types';
import { patch, deleteRequest } from '@services/api/base';

const BASE_URL = `${Urls.project}`;

export default {
  create: (companyId: number, dto: CommentCreateDto) => post<Comment>(`${BASE_URL}/comments`, dto, { companyId }),

  update: (companyId: number, id: number, dto: CommentUpdateDto) =>
    patch<Comment>(`${BASE_URL}/comments/${id}`, dto, { companyId }),

  remove: (id: number) => deleteRequest(`${BASE_URL}/comments/${id}`),

  pin: (id: number, dto: CommentPinDto) => patch<Comment>(`${BASE_URL}/comments/${id}/pin`, dto)
};
