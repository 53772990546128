import { SystemLogStatus, SystemLogThread } from '@generated/types/graphql';
import React, { useCallback, useState } from 'react';

import { RefreshCw } from 'react-feather';
import { CoperniqIcon } from '@kit/ui/icons/Coperniq';
import { DateTime } from 'luxon';
import { ChevronsUpIcon } from '@kit/ui/icons/ChevronsUp';
import { ChevronsDownIcon } from '@kit/ui/icons/ChevronsDown';
import { capitalize } from 'lodash';
import { SystemLogThreadEvent } from '@hooks/systems/useSystemSyncLogs';
import {
  Container,
  BorderContainer,
  FeedItemIconContainer,
  Content,
  Header,
  HeaderPart,
  HeaderTitle,
  AvatarContainer,
  CreatedAt,
  ItemActionButton,
  EventRow,
  EventTitle
} from './styled';

interface Props {
  logItem: SystemLogThread;
}

const ICON_COLOR_BY_STATUS: Record<SystemLogStatus, string> = {
  [SystemLogStatus.Success]: '#009A47',
  [SystemLogStatus.Error]: '#D54855',
  [SystemLogStatus.Skipped]: '#828D9A'
};

const TEXT_COLOR_BY_STATUS: Record<SystemLogStatus, string> = {
  [SystemLogStatus.Success]: '#1D1D35',
  [SystemLogStatus.Error]: '#D54855',
  [SystemLogStatus.Skipped]: '#828D9A'
};

const STATUS_TEXT_BY_STATUS: Record<SystemLogStatus, string> = {
  [SystemLogStatus.Success]: 'System synchronization was successful.',
  [SystemLogStatus.Error]: 'System synchronization failed.',
  [SystemLogStatus.Skipped]: 'System synchronization was skipped.'
};

export const SyncLogItem = ({ logItem }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = useCallback(() => {
    setIsExpanded((prev) => !prev);
  }, []);

  return (
    <Container id={`log_${logItem.thread}`}>
      <BorderContainer>
        <FeedItemIconContainer color={ICON_COLOR_BY_STATUS[logItem.status]}>
          <RefreshCw size="12px" color="#fff" />
        </FeedItemIconContainer>
        <Content>
          <Header>
            <HeaderPart verticalAlign="flex-start">
              <AvatarContainer>
                <CoperniqIcon size="24px" />
              </AvatarContainer>
              <HeaderTitle>
                <b>Coperniq Bot</b>
              </HeaderTitle>
            </HeaderPart>
            <HeaderPart verticalAlign="center">
              <CreatedAt>{DateTime.fromISO(logItem.createdAt).toFormat('D, hh:mma').toLowerCase()}</CreatedAt>
              <ItemActionButton onClick={toggleExpand}>
                {isExpanded ? <ChevronsUpIcon size="16px" /> : <ChevronsDownIcon size="16px" />}
              </ItemActionButton>
            </HeaderPart>
          </Header>
          <div style={{ color: TEXT_COLOR_BY_STATUS[logItem.status] }}>
            <div>{STATUS_TEXT_BY_STATUS[logItem.status]}</div>

            {isExpanded && (
              <div>
                {logItem.events.map((event: SystemLogThreadEvent) => (
                  <EventRow key={event.id}>
                    <EventTitle>
                      {' • '}
                      {capitalize(event.label)}
                    </EventTitle>
                    <CreatedAt>{DateTime.fromISO(event.created_at).toFormat('D, hh:mma').toLowerCase()}</CreatedAt>
                  </EventRow>
                ))}
              </div>
            )}
          </div>
        </Content>
      </BorderContainer>
    </Container>
  );
};
