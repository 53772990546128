import React, { useRef, useState } from 'react';
import { Search } from 'react-feather';
import { TextField } from '@common/ui';
import { Wrapper, LabelWrapper, SearchButton } from './styled';

interface IProps {
  label?: any;
  value?: string;
  name?: string;
  placeholder?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onValueChange?: (value: string) => unknown;
  onSubmit?: CallableFunction;
  renderComponent?: (props: any) => React.ReactNode;
  light?: boolean;
  disabled?: boolean;
  defaultValue?: string;
}

export const SearchBar: React.FC<IProps> = (props: IProps) => {
  const {
    label,
    value,
    name,
    placeholder,
    onChange,
    onValueChange,
    onSubmit,
    renderComponent,
    light = false,
    disabled = false,
    defaultValue
  } = props;

  const searchRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);

  const onKeyDownHandler = (event) => {
    if (onSubmit && event.keyCode === 13) {
      onSubmit();
    }
  };

  const onFocusHandler = () => {
    setIsFocused(true);
  };

  const onBlurHandler = () => {
    setIsFocused(false);
    if (onSubmit) {
      onSubmit();
    }
  };

  return (
    <Wrapper ref={searchRef} isFocused={isFocused} light={light}>
      {label && <LabelWrapper>{label}</LabelWrapper>}
      {renderComponent ? (
        renderComponent({ onFocus: onFocusHandler, onBlur: onBlurHandler, size: 'small', noBorder: true })
      ) : (
        <TextField
          name={name}
          placeholder={placeholder}
          noBorder
          size="small"
          defaultValue={defaultValue}
          value={value}
          onChange={(event) => {
            onChange?.(event);
            onValueChange?.(event.target.value);
          }}
          onKeyDown={onKeyDownHandler}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
          disabled={disabled}
        />
      )}
      <SearchButton type="submit" isFocused={isFocused} light={light} onClick={onSubmit || null}>
        <Search size={20} />
      </SearchButton>
    </Wrapper>
  );
};
