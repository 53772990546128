import React from 'react';
import { RichEditorFormik } from '@common';

import styled from 'styled-components';

const Wrapper = styled.div`
  .ql-editor {
    min-height: 80px !important;
  }
`;

type DescriptionInputType = {
  label?: string;
  name: string;
};

const DescriptionInput: React.FC<DescriptionInputType> = (props) => {
  const { label, name, onBlur, onFocus } = props;

  return (
    <Wrapper>
      <RichEditorFormik label={label} name={name} onBlur={onBlur} onFocus={onFocus} />
    </Wrapper>
  );
};

export default DescriptionInput;
