import React from 'react';
import Props from './types';

import styled from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  z-index: 110;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  background-color: rgba(29, 29, 53, 0.75);
`;

const BackDrop: React.FC<Props> = (props: Props) => {
  const { isVisible, onClick, className } = props;
  return isVisible ? (
    <Wrapper onClick={onClick} className={className}></Wrapper>
  ) : null;
};

export default BackDrop;
