import { Role } from '@generated/types/graphql';
import { DEFAULT_ROLES_NAMES, normalizeRoleName } from '@utils/roles';

export const checkRoleHasAnalyticsAccess = (role: Pick<Role, 'settings'>) => {
  return role.settings.workspace.analytics !== 'none';
};

export const pluralizeRoleName = (roleName: string) => {
  if (DEFAULT_ROLES_NAMES[roleName]) {
    return `${normalizeRoleName(roleName)}${roleName.endsWith('s') ? '' : 's'}`;
  }

  return roleName;
};
