import React from 'react';
import { ModalProps, Popover as MuiPopover, PopoverProps } from '@material-ui/core';

import { Menu } from './styled';

interface Props extends Omit<PopoverProps, 'anchorEl' | 'open' | 'onClose'> {
  children: React.ReactNode;
  content: React.ReactNode;
  open?: boolean;
  onClose?: ModalProps['onClose'];
}

export const Popover = ({ open, onClose, children, content, ...props }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose: ModalProps['onClose'] = (event, reason) => {
    setAnchorEl(null);
    onClose?.(event, reason);
  };

  const isOpen = open === undefined ? Boolean(anchorEl) : open;

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div onClick={handleClick}>{children}</div>
      <MuiPopover open={isOpen} anchorEl={anchorEl} onClose={handleClose} {...props}>
        <Menu>{content}</Menu>
      </MuiPopover>
    </>
  );
};
