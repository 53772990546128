import styled from 'styled-components';
import { colors } from '@styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 255px;

  border-radius: 4px;
  background-color: white;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-bottom: 30px;

  p {
    text-align: center;
    font-size: 14px;
    color: ${colors.gray};

    strong {
      color: ${colors.green};
    }
  }
`;

export const Actions = styled.div`
  background-color: ${colors.white};
  padding: 16px 24px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
`;

export const Body = styled.div`
  padding: 24px;
  background-color: #f7f7fc;
`;

export const UploadTo = styled.div`
  max-width: 332px;
  margin-bottom: 24px;
`;

export const FieldLabel = styled.label`
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #828d9a;
  display: flex;
  align-items: center;
  gap: 4px;
`;
