import { colors } from '../colors';

const redirectLink = () => `
  font-size: 14px;
  font-weight: 500;
  color: ${colors.green};
  cursor: pointer;
  text-decoration: none;

  &:hover, &:focus {
    text-decoration: underline;
  }
`;

export default redirectLink;
