import React from 'react';

export const CoperniqIcon = ({ size = '16px' }: { size?: string | number }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="2" fill="#009688" />
    <g clipPath="url(#clip0_6443_398052)">
      <path
        d="M16.8872 21.3122C16.4072 21.3122 15.8672 21.2522 15.3272 21.1322C13.5872 20.7722 11.6072 19.8122 9.74716 18.5522C5.54716 15.7322 3.32716 12.2522 4.52716 10.4522C5.12716 9.55219 6.50716 9.25219 8.36716 9.67219C10.1072 10.0322 12.0872 10.9922 13.9472 12.2522C18.1472 15.0722 20.3672 18.5522 19.1672 20.3522C18.7472 21.0122 17.9672 21.3122 16.8872 21.3122ZM6.74716 10.5122C6.02716 10.5122 5.54716 10.6922 5.30716 10.9922C4.58716 12.0722 6.44716 15.0722 10.2272 17.6522C12.0272 18.8522 13.8872 19.7522 15.5072 20.1122C16.8872 20.4122 17.9672 20.2922 18.3272 19.7522C19.0472 18.6722 17.1872 15.6722 13.4072 13.0922C11.6072 11.8922 9.74716 10.9922 8.12716 10.6322C7.64716 10.5722 7.16716 10.5122 6.74716 10.5122Z"
        fill="url(#paint0_linear_6443_398052)"
      />
      <path
        d="M17.5465 21.1922C15.6265 21.1922 12.8665 19.0322 10.5865 15.6122C7.76645 11.4722 6.98645 7.39219 8.78645 6.19219C10.5865 4.99219 14.0665 7.21219 16.8865 11.4122C19.7065 15.6122 20.4865 19.6922 18.6865 20.8922C18.3265 21.1322 17.9665 21.1922 17.5465 21.1922ZM9.92645 6.85219C9.68645 6.85219 9.50645 6.91219 9.32645 7.03219C8.24645 7.7522 8.84645 11.2322 11.4265 15.0722C14.0065 18.9122 17.0065 20.7722 18.0865 20.0522C19.1665 19.3322 18.5665 15.8522 15.9865 12.0122C13.8265 8.71219 11.2465 6.85219 9.92645 6.85219Z"
        fill="url(#paint1_linear_6443_398052)"
      />
      <path
        d="M17.6051 21.1927C16.6451 21.1927 15.6251 20.4127 14.6651 19.0327C13.7051 17.5927 12.9251 15.4927 12.4451 13.2727C11.4251 8.35265 12.2051 4.27265 14.3051 3.79265C15.3851 3.55265 16.5251 4.33265 17.6051 5.95265C18.5651 7.39265 19.3451 9.49265 19.8251 11.7127C20.8451 16.6327 20.0651 20.7127 17.9651 21.1927C17.8451 21.1927 17.7251 21.1927 17.6051 21.1927ZM14.7251 4.81265C14.6651 4.81265 14.6051 4.81265 14.5451 4.81265C13.2851 5.05265 12.5051 8.53265 13.4651 13.0327C13.8851 15.1327 14.6651 17.0527 15.5651 18.4327C16.3451 19.6327 17.1851 20.2927 17.7851 20.1127C19.0451 19.8727 19.8251 16.3927 18.8651 11.8927C18.4451 9.79265 17.6651 7.87265 16.7651 6.49265C16.0451 5.41265 15.2651 4.81265 14.7251 4.81265Z"
        fill="url(#paint2_linear_6443_398052)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_6443_398052"
        x1="1.03519"
        y1="10.8241"
        x2="6.48272"
        y2="4.96511"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.3045" stopColor="white" />
        <stop offset="0.8659" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_6443_398052"
        x1="5.42751"
        y1="5.24383"
        x2="12.673"
        y2="1.85235"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.3045" stopColor="white" />
        <stop offset="0.8659" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_6443_398052"
        x1="11.5686"
        y1="1.69508"
        x2="19.557"
        y2="1.26457"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.3045" stopColor="white" />
        <stop offset="0.8659" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <clipPath id="clip0_6443_398052">
        <rect width="18" height="18" fill="white" transform="translate(3 3.75)" />
      </clipPath>
    </defs>
  </svg>
);
