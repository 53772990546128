import { Badge } from '@kit/ui/Badge';
import { scroll } from '@styles';
import styled from 'styled-components';

export const Container = styled.div<{ isFullScreen: boolean }>`
  width: ${({ isFullScreen }) => (isFullScreen ? '212px' : '108px')};
  min-width: ${({ isFullScreen }) => (isFullScreen ? '212px' : '108px')};
  flex: 0 0 ${({ isFullScreen }) => (isFullScreen ? '212px' : '108px')};

  display: flex;
  flex-direction: column;
  gap: 24px;

  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  ${scroll};
`;

export const FieldTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const FieldDocList = styled.div<{ isTwoColumns: boolean }>`
  display: grid;
  grid-template-columns: ${({ isTwoColumns }) => (isTwoColumns ? '96px 96px' : '96px')};
  gap: 8px;
`;

export const Selected = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  border: 1px solid #4d7cfe;

  background: #4d7cfe94;
  border-radius: 4px;
`;

export const DocPreview = styled.div<{ src: string; isSelected: boolean }>`
  width: 96px;
  height: 96px;
  border-radius: 4px;
  background-image: url(${(props) => props.src});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;

  position: relative;
`;

export const VideoBadge = styled(Badge)`
  position: absolute;
  bottom: 4px;
  left: 4px;
  z-index: 1;
  padding: 0 4px;
`;
