import styled from 'styled-components';
import { colors } from '@styles';

export const Wrapper = styled.div`
  position: fixed;
  top: 0px;
  left: 0;
  bottom: 0;
  //width: 100vw;
  //height: 100vh;
  z-index: 9999;
  background-color: #000;

  &&& {
    video {
      object-fit: contain;
    }
  }
`;

export const Container = styled.div<{
  canvasHeightDiff: number;
}>`
  .react-html5-camera-photo > img,
  .react-html5-camera-photo > video {
    box-sizing: border-box;
    margin-top: 60px;
    //width: 100vw;
    height: calc(100vh - ${({ canvasHeightDiff }) => canvasHeightDiff}px);
  }
`;

export const Back = styled.button`
  display: flex;
  align-items: center;
  position: fixed;
  top: 60px;
  right: 24px;
  padding: 3px;
  cursor: pointer;
  margin: 0;
  color: ${colors.black};
  background: #fff;
  border: none;
  border-radius: 50%;
`;
