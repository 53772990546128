import React from 'react';
import { SmartViewFilterType, TaskViewFilterSubType } from '@types';
import { Briefcase, Calendar, DollarSign, IconProps, Truck } from 'react-feather';
import { AccountIcon as Account } from '@components/common/icons/Account';

interface RecordTypeIconProps extends IconProps {
  recordType: SmartViewFilterType;
  subType?: TaskViewFilterSubType;
}

export const RecordTypeIcon: React.FC<RecordTypeIconProps> = (props) => {
  const { recordType, subType, ...rest } = props;

  let Icon =
    {
      [SmartViewFilterType.ACCOUNT]: Account,
      [SmartViewFilterType.PROJECT]: Briefcase,
      [SmartViewFilterType.DEAL]: DollarSign,
      [SmartViewFilterType.ALL]: Briefcase,
      [SmartViewFilterType.TASK]: Briefcase
    }[recordType] || Briefcase;

  if (recordType === SmartViewFilterType.TASK) {
    Icon = {
      [TaskViewFilterSubType.SCHEDULER]: Calendar,
      [TaskViewFilterSubType.DISPATCHER]: Truck,
      [TaskViewFilterSubType.LIST]: Calendar
    }[subType ?? TaskViewFilterSubType.SCHEDULER];
  }

  return <Icon className="cq-recordType-icon" size={20} {...rest} />;
};
