import { AggregateColumn, Property, PropertyType, RecordType } from '@types';

export const TASK_WITH_TITLE_PROPERTY: Property = {
  id: AggregateColumn.TASK_WITH_TITLE,
  name: 'Work Order with title',
  position: 0,
  type: PropertyType.Text,
  isAdditional: false,
  mappedName: 'taskTitle',
  scope: [RecordType.PROJECT]
};

export const TIME_IN_STAGE_PROPERTY: Property = {
  id: AggregateColumn.TIME_IN_STAGE,
  name: 'Time in stage',
  position: 0,
  type: PropertyType.Date,
  isAdditional: false,
  reversedOrder: true,
  mappedName: 'projectStageUpdatesConnectionMaxCreatedAt',
  scope: [RecordType.DEAL, RecordType.ACCOUNT, RecordType.PROJECT]
};

export const VIRTUAL_FILTER_PROPERTIES: Property[] = [TASK_WITH_TITLE_PROPERTY];
