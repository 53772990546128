import { usePinnedComemnts } from '@hooks/usePinnedComments';
import React, { useCallback, useMemo, useState } from 'react';
import { Button, ButtonSize, ButtonVariant } from '@kit/ui/Button';
import { Collapse } from '@material-ui/core';
import { QueryParamsEnum, useQueryParam } from '@hooks/useQueryParam';
import { useEffectOnceBy } from '@hooks/useEffectOnceBy';
import { CommentItem } from '../FeedItem/CommentItem';
import { Container, Header, List, ListItem } from './styled';

interface Props {
  clientId?: number;
  projectId?: number;
  workOrderId?: number;
  fileId?: number;
  systemId?: number;
}

export const PinnedComments = ({ clientId, projectId, workOrderId, fileId, systemId }: Props) => {
  const [feedId, setQueryParamFeedId] = useQueryParam(QueryParamsEnum.FeedId);

  const [isExpanded, setIsExpanded] = useState(false);

  const { data: comments = [] } = usePinnedComemnts({ clientId, projectId, workOrderId, fileId, systemId });

  useEffectOnceBy(
    () => {
      if (feedId && comments.find((comment) => comment.id.toString() === feedId)) {
        setIsExpanded(true);
      }

      setTimeout(() => {
        setQueryParamFeedId(undefined);
      }, 1000);
    },
    () => comments.length > 0,
    [feedId, comments]
  );

  const sortedComments = useMemo(() => {
    const copy = [...comments];

    copy.sort((a, b) => {
      const aDate = new Date(a.relatedComment?.pinnedAt);
      const bDate = new Date(b.relatedComment?.pinnedAt);

      return bDate.getTime() - aDate.getTime();
    });

    return copy;
  }, [comments]);

  const context = useMemo(() => {
    if (clientId) {
      return { contextEntityId: clientId, context: 'client' };
    }

    if (projectId) {
      return { contextEntityId: projectId, context: 'project' };
    }

    if (workOrderId) {
      return { contextEntityId: workOrderId, context: 'workOrder' };
    }

    if (fileId) {
      return { contextEntityId: fileId, context: 'file' };
    }

    if (systemId) {
      return { contextEntityId: systemId, context: 'system' };
    }

    return {};
  }, [clientId, projectId, workOrderId, fileId, systemId]);

  const toggleExpand = useCallback(() => {
    setIsExpanded((prev) => !prev);
  }, []);

  if (!sortedComments.length) {
    return null;
  }

  const [firstComment] = sortedComments;

  return (
    <Container>
      <Header>
        <span>
          {sortedComments.length} pinned comment{sortedComments.length > 1 ? 's' : ''}
        </span>
        {sortedComments.length > 1 && (
          <Button size={ButtonSize.Small} onClick={toggleExpand} variant={ButtonVariant.Flat}>
            {isExpanded ? 'Collapse all' : 'Show all'}
          </Button>
        )}
      </Header>

      <List>
        <ListItem shadowCount={isExpanded ? 0 : Math.min(sortedComments.length - 1, 2)}>
          <CommentItem
            item={firstComment}
            context={context.context}
            contextEntityId={context.contextEntityId}
            visibleRepliesCount={0}
          />
        </ListItem>

        <Collapse in={isExpanded}>
          <List>
            {sortedComments.slice(1).map((commentFeedItem) => (
              <ListItem key={commentFeedItem.id}>
                <CommentItem
                  item={commentFeedItem}
                  context={context.context}
                  contextEntityId={context.contextEntityId}
                  visibleRepliesCount={0}
                />
              </ListItem>
            ))}
          </List>
        </Collapse>
      </List>
    </Container>
  );
};
