import React from 'react';

export const ProgressIcon = ({ size = '16px', color = 'currentColor' }: { size?: string | number; color?: string }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.416 2.14889C9.99626 2.28051 10.5536 2.49826 11.0693 2.79489M6.74933 13.8516C5.4239 13.5488 4.2405 12.8051 3.3929 11.7421C2.5453 10.6791 2.08371 9.35979 2.08371 8.00023C2.08371 6.64066 2.5453 5.32137 3.3929 4.25836C4.2405 3.19535 5.4239 2.45161 6.74933 2.14889M13.03 4.60489C13.3937 5.13325 13.6699 5.71672 13.848 6.3329M14 9.00023C13.8933 9.63356 13.688 10.2336 13.4 10.7836L13.2873 10.9869M11.478 12.9476C10.8542 13.377 10.1545 13.6838 9.416 13.8516"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
