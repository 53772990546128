import React from 'react';

export const AccountIcon = ({ size = 20, color = 'currentColor' }: { size?: number; color?: string } = {}) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_18470_422027)">
      <path
        d="M1.3 0.800049H9.1C9.44478 0.800049 9.77544 0.951763 10.0192 1.22181C10.263 1.49187 10.4 1.85814 10.4 2.24005V15.2H0V2.24005C0 1.85814 0.136964 1.49187 0.380761 1.22181C0.624558 0.951763 0.955219 0.800049 1.3 0.800049ZM1.3 13.76H3.9V10.88H6.5V13.76H9.1V2.24005H1.3V13.76ZM5.85 3.68005H7.8V5.84005H5.85V3.68005ZM2.6 7.28005H4.55V9.44005H2.6V7.28005ZM5.85 7.28005H7.8V9.44005H5.85V7.28005ZM2.6 3.68005H4.55V5.84005H2.6V3.68005Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3112 15.1998H15.289C15.6817 15.1998 16.0001 14.8815 16.0001 14.4887V10.6882C16.0001 10.4661 15.9003 10.2556 15.7284 10.1149L13.2691 8.10269C12.9963 7.87942 12.6039 7.87942 12.331 8.10269L9.87172 10.1149C9.69978 10.2556 9.60007 10.4661 9.60007 10.6882V14.4517C9.60007 14.8649 9.91844 15.1998 10.3112 15.1998ZM14.963 10.8286V14.1628H12.6668V12.6666H11.3335V14.1628H10.6371V10.8286L12.8001 9.05882L14.963 10.8286Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_18470_422027">
        <rect width={size} height={size} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
