import { ActionStatus, File as GqlFile } from '@generated/types/graphql';
import { post, deleteRequest, patch } from './base';
import { Urls } from './base/axios';

export type UpdateActionItemPayload = {
  title?: string;
  description?: string;
  status?: ActionStatus;
  dueDate?: string;
  assigneeContactIds?: number[];
  fileIds?: number[];
};

export type CreateActionItemPayload = {
  projectId: number;
  actionTemplateId: number;
};

export const actionItemApi = {
  create: (payload: CreateActionItemPayload) =>
    post<CreateActionItemPayload, { id: number }>(`${Urls.project}/action`, payload),

  update: (id: number, payload: UpdateActionItemPayload) => patch(`${Urls.project}/action/${id}`, payload),

  remove: (id: number) => deleteRequest(`${Urls.project}/action/${id}`),

  uploadFile: ({ actionId, file, companyId }: { actionId: number; file: File; companyId: number }) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('actionId', actionId.toString());

    return post<GqlFile>(`${Urls.dataBank}/file/upload/company?companyId=${companyId}`, formData);
  }
};
