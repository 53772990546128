import styled from 'styled-components';

export const Row = styled.div`
  height: 40px;
  min-height: 40px;
  padding: 0 16px;
  color: #828d9a;
  background: #f7f7fc;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 16px;
  position: sticky;
  top: 0;
  z-index: 1;
`;

export const Cell = styled.div<{ width: number }>`
  width: ${({ width }) => width}px;
  min-width: ${({ width }) => width}px;
  flex: 0 0 ${({ width }) => width}px;
`;

export const FileNameCell = styled.div`
  flex: 1;
  min-width: 0;
`;

export const NameAndCheckbox = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
