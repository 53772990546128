import { Tooltip } from '@material-ui/core';
import React from 'react';
import { ProjectRequestStatus } from '@generated/types/graphql';
import { STATUS_CONFIG } from '@features/RequestPortfolio/constants';
import { StatusLine, TooltipContent } from './styled';

interface Props {
  status: ProjectRequestStatus;
}

export const Status = ({ status }: Props) => {
  const { color, label } = STATUS_CONFIG[status];

  return (
    <Tooltip
      title={
        <TooltipContent>
          <StatusLine color={color} /> <div>{label}</div>
        </TooltipContent>
      }
    >
      <StatusLine color={color} />
    </Tooltip>
  );
};
