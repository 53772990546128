import { ProjectAdapted, Property, PropertyType } from '@types';
import React, { useCallback, useMemo, useRef } from 'react';
import { Form, FormValidationRules, useForm } from '@kit/components/Form';
import { capitalize, get, set } from 'lodash';
import { UpdatePropertyMutationProps, useCompanyPropertiesMutations } from '@hooks/useCompanyProperties';
import { Skeleton } from '@material-ui/lab';
import { useAllCompaniesUsers } from '@hooks/useCompanyUsers';
import { isButtonOrLink, isFiles, isStatus } from '@utils/properties';
import { PropertyValueField } from '@features/Platform/Properties/PropertyValueField';
import { validateUrl } from '@utils/validations';
import { FormFieldLoaderWrapper, FormFieldWrapper } from './styled';

interface Props {
  project: ProjectAdapted;
  property: Property;
  onFinishEdit: () => void;
}

const SOURCE_PROPERTY_NAME_MAP: { [key: string]: string } = {
  projectManagerId: 'projectManager',
  salesRepId: 'salesRep',
  ownerId: 'owner'
};

const getDefaultFormValues = ({ project, property, companyUsers }: any) => {
  const name = property.isAdditional ? `additional.${property.id}` : property.mappedName;

  const result = {};

  const sourceName = SOURCE_PROPERTY_NAME_MAP[name] || name;

  const existingValue = get(project.projectDetail, sourceName);

  if (property.type === PropertyType.Person && typeof existingValue === 'number') {
    const user = companyUsers.find((user: any) => user.id === existingValue);

    set(result, name, user);
  } else if (isStatus(property)) {
    set(result, name, { id: existingValue, label: capitalize(existingValue.replace('_', ' ')) });
  } else {
    set(result, name, existingValue);
  }

  return result;
};

export const PropertyForm = ({ property, project, onFinishEdit }: Props) => {
  const name = property.isAdditional ? `additional.${property.id}` : property.mappedName;
  const { updateProjectProperty } = useCompanyPropertiesMutations();
  const { data: companyUsers } = useAllCompaniesUsers();
  const formWrapperRef = useRef<HTMLDivElement>(null);

  const postForm = async (values: any) => {
    const recordDTO = {
      ...values,
      ownerId: values.ownerId?.id,
      salesRepId: values.salesRepId?.id ?? null,
      projectManagerId: values.projectManagerId?.id ?? null,
      status: values.status?.id ?? null
    };

    const dto = {
      projectId: project.projectId
    } as UpdatePropertyMutationProps;

    if (values.additional) {
      dto.property = property;
      dto.newValue = get(values, name);

      if (isFiles(property)) {
        dto.newValue = dto.newValue.map((file: File) => file.id);
      }
    } else {
      dto.property = property;
      dto.newValue = recordDTO[name];
    }

    await updateProjectProperty.mutateAsync(dto);

    onFinishEdit();

    return Promise.resolve();
  };

  const { form, handleSubmit } = useForm({
    onSubmit: postForm,
    mode: 'onBlur',
    defaultValues: getDefaultFormValues({ project, property, companyUsers })
  });

  const handleChanged = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  const rules = useMemo<FormValidationRules<any>>(
    () => ({
      [property.isAdditional ? `additional.${property.id}` : property.mappedName]: {
        validate: isButtonOrLink(property) ? validateUrl : undefined
      }
    }),
    [property]
  );

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <FormFieldWrapper ref={formWrapperRef} isRelative={form.formState.isSubmitting}>
        <PropertyValueField
          name={property.isAdditional ? `additional.${property.id}` : property.mappedName}
          property={property}
          control={form.control}
          onChanged={handleChanged}
          isAutoFocus
        />
        {form.formState.isSubmitting && (
          <FormFieldLoaderWrapper>
            <Skeleton height={40} />
          </FormFieldLoaderWrapper>
        )}
      </FormFieldWrapper>
    </Form>
  );
};
