import React, { useEffect, useState } from 'react';
import { map } from 'lodash';
import { Check, ChevronDown } from 'react-feather';
import { makeStyles, Paper } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Form } from '@components/Project/DataBank/types';
import { autocomplete } from '@common/ui/Autocomplete/styled';
import { TextField } from '@common/ui';
import { useTemplatesForm } from '@hooks';
import { useFormMutations } from '@hooks/useForms';
import { useForms } from './hooks';
import { AddButtonContainer, AddButton, FormOption } from './styled';

interface FormSelectProps {
  onBlur: (event: React.FocusEvent<HTMLDivElement>) => void;
  onSelect: (form: Form) => void;
  selectedForm?: Form;
  hideForms?: number[];
  projectId: number;
  onCreateNewForm?: () => void;
}

export const FormSelect: React.FC<FormSelectProps> = ({
  onBlur,
  onSelect,
  selectedForm,
  hideForms = [],
  projectId,
  onCreateNewForm
}: FormSelectProps) => {
  const classes = makeStyles(autocomplete({}))();

  const isWorkOrderContext = Boolean(projectId);

  const {
    create: { mutateAsync: createForm }
  } = useFormMutations();

  const { items: $forms = [], isLoading, projectIdRef, refetch } = useForms<Form>(projectId, true);
  const {
    fetch: { data: workspaceLevelFormsTemplates }
  } = useTemplatesForm();
  const [inputValue, setInputValue] = useState(selectedForm?.name);
  // that's how we detect the task-template context here
  const rawForms = (isWorkOrderContext ? $forms : workspaceLevelFormsTemplates?.results) ?? [];

  const forms = rawForms;

  useEffect(() => {
    if (projectId) {
      projectIdRef.current.value = projectId;
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, refetch]);

  const handleOnChange = async (event: React.ChangeEvent<{}>, form: Form | null, reason: string) => {
    if (reason !== 'select-option' || form === null) {
      return;
    }

    if (isWorkOrderContext && form.isTemplate) {
      let formName = form.name;
      const existingFormsWithThisTemplate = $forms.filter((f) => f.templateId === form.id).map((form) => form.name);
      // form name can contain (v1) or (v2) etc. suffixes so we need to add a new version
      if (existingFormsWithThisTemplate.length > 0) {
        formName += ` (v${existingFormsWithThisTemplate.length + 1})`;
      }

      const newForm = await createForm({
        dto: {
          name: formName,
          templateId: form.id,
          stageId: null,
          projectId,
          isTemplate: false
        }
      });

      onSelect(newForm);

      return;
    }

    onSelect(form);
  };

  const onInputChange = (event) => {
    const { value } = event.target;

    setInputValue(value);
  };

  return (
    <Autocomplete
      classes={classes}
      styles={{ width: '100%' }}
      options={map(forms as Form[], ({ isTemplate, ...props }) => ({
        ...props,
        isTemplate,
        groupLabel: isTemplate ? 'Create new Form from Template' : 'Link existing Form'
      }))}
      groupBy={isWorkOrderContext ? (option) => option.groupLabel : undefined}
      filterOptions={(options, { inputValue: typedValue }) =>
        options.filter((o) => typedValue.length === 0 || o.name?.toUpperCase().includes(typedValue.toUpperCase()))
      }
      isLoading={isLoading}
      inputValue={inputValue}
      value={selectedForm}
      onBlur={onBlur}
      disableClearable
      getOptionDisabled={(option) => hideForms.includes(option.id)}
      getOptionSelected={(option, value) => option.id === value.id}
      onChange={handleOnChange}
      getOptionLabel={(option) => option.name}
      renderOption={(option) => (
        <FormOption>
          <div>{option.name}</div>
          {hideForms.includes(option.id) && <Check size={16} color="#009A47" />}
        </FormOption>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus
          onChange={onInputChange}
          placeholder="Enter form name..."
          variant="outlined"
          size="small"
          noBorder
        />
      )}
      popupIcon={<ChevronDown />}
      openOnFocus
      // eslint-disable-next-line
      PaperComponent={({ children, ...props }) => (
        <Paper {...props}>
          {children}
          {onCreateNewForm && (
            <AddButtonContainer>
              <AddButton onMouseDown={() => onCreateNewForm()}>+ Create new form</AddButton>
            </AddButtonContainer>
          )}
        </Paper>
      )}
    />
  );
};

export default FormSelect;
