import { useState, useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';

const createWrapperAndAppendToContent = (wrapperId: string) => {
  const content = document.getElementById('content-under-top-menu');
  const wrapperElement = document.createElement('div');
  wrapperElement.setAttribute('id', wrapperId);

  content.appendChild(wrapperElement);

  return wrapperElement;
};

interface Props {
  children?: React.ReactNode;
  wrapperId?: string;
}

export const Portal = ({ children, wrapperId = 'react-portal-wrapper' }: Props) => {
  const [wrapperElement, setWrapperElement] = useState(null);

  useLayoutEffect(() => {
    let element = document.getElementById(wrapperId);
    // if element is not found with wrapperId or wrapperId is not provided,
    // create and append to body
    if (!element) {
      element = createWrapperAndAppendToContent(wrapperId);
    }
    setWrapperElement(element);
  }, [wrapperId]);

  // wrapperElement state will be null on the very first render.
  if (wrapperElement === null) {
    return null;
  }

  return createPortal(children, wrapperElement);
};
