import React, { useMemo } from 'react';
import { useWorkflowList } from '@hooks/workflows/useWorkflowList';
import { ANALYTICS_MODULE_RECORD_TYPE_MAP } from '@features/Analytics/constants';
import { FilterField, FilterFieldType } from '../types';
import { FilterValueProps } from './types';
import { SelectWrapper } from './SelectWrapper';

export const WorkflowFilterValue = ({ module, filter, onChange }: FilterValueProps) => {
  const { field, value } = filter;

  const { data: workflows = [] } = useWorkflowList();

  const workflowOptions = useMemo<FilterField[]>(
    () =>
      workflows
        .filter((workflow) => workflow.type === ANALYTICS_MODULE_RECORD_TYPE_MAP[module])
        .map((workflow) => ({
          id: workflow.id,
          name: workflow.name,
          type: FilterFieldType.WORKFLOW
        })),
    [workflows, module]
  );

  if (field.type !== FilterFieldType.WORKFLOW) {
    return null;
  }

  return (
    <SelectWrapper
      isMulti
      getOptionLabel={(option) => option.name}
      value={value ?? []}
      onChange={(_e, value) => onChange(value)}
      options={workflowOptions}
    />
  );
};
