import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;

export const NotificationContainer = styled.div`
  height: 64px;
  flex-shrink: 0;
  background-color: #e3f6f4;
  padding: 0 16px;
  display: flex;
  align-items: center;
  gap: 16px;
  border-bottom: 1px solid #e4e8ec;
  font-weight: 400;
  font-size: 14px;

  .title {
    text-overflow: ellipsis;
    min-width: 320px;

    > * {
      display: flex;
      align-items: center;
      gap: 8px;

      svg {
        flex-shrink: 0;
      }
    }
  }

  .content {
    overflow: hidden;
    text-overflow: ellipsis;
    > * {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .time {
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: auto;
    text-align: right;
    width: 200px;
    min-width: 200px;
    max-width: 200px;
  }
`;

export const Author = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
