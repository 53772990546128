import React from 'react';
import { Tooltip } from '@material-ui/core';
import { Wrapper } from './styled';

interface BadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  type?: 'error' | 'success' | 'info' | 'smartViewsCount';
  size?: 'small' | 'big';
  title?: string;
  children: React.ReactNode;
}

export const Badge: React.FC<BadgeProps> = (props) => {
  const {
    type = 'info',
    size = 'small',
    title,
    children,
    ...rest
  } = props;

  return (
    <Tooltip title={title} placement="top" arrow>
      <Wrapper type={type} size={size} {...rest}>
        {children}
      </Wrapper>
    </Tooltip>
  );
};
