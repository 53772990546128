import { ReactQueryKey } from '@enums';
import { useToast } from '@hooks/useToast';
import { errorHandler } from '@services/api/helpers';
import { useMutation, useQueryClient } from 'react-query';
import filterApi from '@services/api/smartViewFilterApi';
import { selectWorkspaceId } from '@state/selectors';
import { useAppSelector } from '..';

export const useDeleteSmartViewFolder = () => {
  const { showSuccess } = useToast();
  const queryClient = useQueryClient();
  const companyId = useAppSelector(selectWorkspaceId);

  return useMutation<void, Error, { id: number }>(
    async ({ id }) => {
      try {
        await filterApi.deleteFolder({ id, companyId });
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: async () => {
        showSuccess('Hub deleted successfully');
        queryClient.invalidateQueries(ReactQueryKey.SmartViewFolderList);
        queryClient.refetchQueries(ReactQueryKey.SmartViewList);
      }
    }
  );
};
