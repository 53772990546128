import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useAppDispatch, useAppSelector } from '@hooks/store';
import { errorHandler } from '@services/api/helpers';
import { alertShow } from '@state/actions/alert/alertAction';
import { selectWorkspaceId } from '@state/selectors';
import { makeMutationHandlers } from '@utils/reactQuery';
import { Identified, PaginationResult, Search } from '@types';
import { ReactQueryKey } from '@enums';

export interface UseCRUDParams<T extends Identified, ApiDTO, K = (string | number)[], SearchParams = Partial<Search>> {
  api: {
    create: (dto: ApiDTO, params: { companyId: Identified['id'] }) => Promise<{ data: T }>;
    update: (id: T['id'], dto: Partial<ApiDTO>) => Promise<{ data: T }>;
    remove: (id: T['id']) => Promise<any>;
    find: (params: SearchParams) => Promise<{ data: PaginationResult<T> }>;
  };
  queryKey: K;
  getReadParams: (args: { queryKey: K }) => SearchParams;
  entityName: string;
  initialFetch?: boolean;
  companyId?: number | string;
  invalidateKeys?: ReactQueryKey[];
  afterMutation?: () => unknown;
}

/**
 * @deprecated overengineering, do not use
 */
export function useCRUD<T extends Identified, ApiDTO = T>({
  api,
  entityName,
  queryKey,
  getReadParams,
  initialFetch = true,
  companyId: $companyId,
  invalidateKeys: invalidateKeysRaw,
  afterMutation
}: UseCRUDParams<T, ApiDTO>) {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const selectedCompanyId = useAppSelector(selectWorkspaceId);
  const companyId = ($companyId || selectedCompanyId) as number;
  const invalidateKeys = invalidateKeysRaw ?? [queryKey];

  const findQuery = useQuery(
    queryKey,
    async ({ queryKey }) => {
      try {
        const { data: result } = await api.find(
          getReadParams({
            queryKey
          })
        );

        return result;
      } catch (err) {
        throw errorHandler(`Exception on getting ${entityName}`);
      }
    },
    {
      onError: () => {
        dispatch(alertShow([`Couldn't fetch the ${entityName}`], 'error'));
      },
      enabled: initialFetch,
      staleTime: 1000 * 3600 * 24
    }
  );

  const createMutation = useMutation<T, Error, ApiDTO>(
    async (req) => {
      try {
        const { data: newEntity } = await api.create(req, {
          companyId
        });

        return newEntity;
      } catch (error) {
        throw errorHandler(error);
      }
    },
    makeMutationHandlers<T, T>(
      queryKey,
      queryClient,
      dispatch,
      (list: T[]) => {
        Promise.all([invalidateKeys.map((key) => queryClient.invalidateQueries(key))]);

        afterMutation?.();

        return list;
      },
      `Successfully created the ${entityName}`
    )
  );

  const updateMutation = useMutation<T, Error, { id: number; req: Partial<ApiDTO> }>(
    async ({ id, req }) => {
      try {
        const { data: updatedEntity } = await api.update(id, req, companyId);

        return updatedEntity as T;
      } catch (error) {
        throw errorHandler(error);
      }
    },
    makeMutationHandlers<T, T>(
      queryKey,
      queryClient,
      dispatch,
      (list: T[]) => {
        Promise.all([invalidateKeys.map((key) => queryClient.invalidateQueries(key))]);

        afterMutation?.();

        return list;
      },
      `Successfully updated the ${entityName}`
    )
  );

  const deleteMutation = useMutation<T, Error, Parameters<typeof api.remove>>(
    async (id, ...rest) => {
      try {
        await api.remove(id, ...rest, companyId);

        return { id, ...rest };
      } catch (error) {
        throw errorHandler(error);
      }
    },
    makeMutationHandlers<T, { id: number }>(
      queryKey,
      queryClient,
      dispatch,
      (list: T[]) => {
        Promise.all([invalidateKeys.map((key) => queryClient.invalidateQueries(key))]);

        afterMutation?.();

        return list;
      },
      `Successfully deleted the ${entityName}`
    )
  );

  return {
    findQuery,
    createMutation,
    updateMutation,
    deleteMutation
  };
}
