import { Tooltip } from '@material-ui/core';
import React from 'react';
import { ProjectStatus } from '@generated/types/graphql';
import { StatusLine, TooltipContent } from './styled';

interface Props {
  status: ProjectStatus;
}

export const getColorAndLabel = ({ status }: Props) => {
  switch (status) {
    case ProjectStatus.Completed:
      return { color: '#009A47', label: 'Completed' };
    case ProjectStatus.Active:
      return { color: '#235DFF', label: 'Active' };
    case ProjectStatus.Cancelled:
      return { color: '#D54855', label: 'Cancelled' };
    case ProjectStatus.OnHold:
      return { color: '#FFBD13', label: 'On hold' };
    default:
      return { color: '#000000', label: 'Unknown' };
  }
};

export const Status = ({ status }: Props) => {
  const { color, label } = getColorAndLabel({ status });

  return (
    <Tooltip
      title={
        <TooltipContent>
          <StatusLine color={color} /> <div>{label}</div>
        </TooltipContent>
      }
    >
      <StatusLine color={color} />
    </Tooltip>
  );
};
