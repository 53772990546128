import post from '@services/api/base/post';
import { Urls } from '@services/api/base/axios';
import put from '@services/api/base/put';
import deleteReq from '@services/api/base/delete';
import { Team, UpdateTeamDTO } from '@types';

export default {
  create: (companyId: number, dto: UpdateTeamDTO) =>
    post<Team>(`${Urls.project}/teams`, dto, { companyId }),

  update: (companyId: number, id: number, dto: UpdateTeamDTO) =>
    put<Team>(`${Urls.project}/teams/${id}`, dto, { companyId }),

  delete: (companyId: number, id: number) =>
    deleteReq<void>(`${Urls.project}/teams/${id}`, undefined, { companyId })
};
