export const isEmpty = (obj: object | unknown) => {
  if (obj == null) {
    return true;
  }

  if (obj === '') {
    return true;
  }

  return Object.keys(obj as object).length === 0;
};

/**
 * Creates an object with the same keys as object and values generated
 * by running each own enumerable string keyed property of object thru iteratee.
 * The iteratee is invoked with three arguments: (value, key, object).
 */
export function mapValues<T extends {}, K extends any>(
  obj: T,
  func: (value: T[keyof T], key: keyof T, obj: T) => any
) {
  return Object.fromEntries(
    Object.entries(obj).map<[keyof T, T[keyof T]]>(([k, v]) => [k, func(v, k, obj)])
  );
}

/*
 * Creates an object with properties of the object that are not omitted.
 */
export const omit = <T extends { [key: string]: any }, K extends [keyof T]>(keys: K, obj: T) =>
  Object.keys(obj)
    .filter((key) => keys.indexOf(key) < 0)
    .reduce((newObj, key) => Object.assign(newObj, { [key]: obj[key] }), {});
