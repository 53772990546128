import React, { useEffect, useRef } from 'react';
import 'video.js/dist/video-js.css';
import videojs from 'video.js';
import { VideoJsPlayerOptions } from '@types/video.js';
import { Container } from './styled';

const VIDEO_JS_OPTIONS: VideoJsPlayerOptions = {
  controls: true,
  responsive: true,
  fill: true,
  autoplay: false,
  preload: 'none',
  bigPlayButton: true,
  playbackRates: [0.5, 1, 1.5, 2],

  controlBar: {
    volumePanel: {
      inline: false
    },
    currentTimeDisplay: true,
    durationDisplay: true,
    timeDivider: true,
    children: [
      'playToggle',
      'playbackRateMenuButton',
      'volumePanel',
      'currentTimeDisplay',
      'timeDivider',
      'durationDisplay',
      'progressControl',
      'fullscreenToggle'
    ]
  }
};

interface Props {
  source: string;
  type: string;
  poster?: string;
}

export const Video = ({ source, type, poster }: Props) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    const options: VideoJsPlayerOptions = {
      ...VIDEO_JS_OPTIONS,
      poster,
      sources: [
        {
          src: source,
          type
        }
      ]
    };
    if (!playerRef.current) {
      const videoElement = document.createElement('video-js');

      videoElement.classList.add('vjs-big-play-centered');
      videoRef.current.appendChild(videoElement);

      playerRef.current = videojs(videoElement, options);
    } else {
      const player = playerRef.current;

      // player.autoplay(options.autoplay);
      player.src(options.sources);
      player.poster(options.poster);
      player.load();
    }
  }, [source, type, poster]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <Container data-vjs-player>
      <div ref={videoRef} />
    </Container>
  );
};
