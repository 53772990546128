import React from 'react';

export const NumericIcon = ({ size = '16px', color = 'currentColor' }: { size?: string | number; color?: string }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.66732 11.3337V6.00033H1.33398V4.66699H4.00065V11.3337H2.66732ZM14.6673 10.0003C14.6673 10.3539 14.5268 10.6931 14.2768 10.9431C14.0267 11.1932 13.6876 11.3337 13.334 11.3337H10.6673V10.0003H13.334V8.66699H12.0007V7.33366H13.334V6.00033H10.6673V4.66699H13.334C13.6876 4.66699 14.0267 4.80747 14.2768 5.05752C14.5268 5.30756 14.6673 5.6467 14.6673 6.00033V7.00033C14.6673 7.26554 14.562 7.5199 14.3744 7.70743C14.1869 7.89497 13.9325 8.00033 13.6673 8.00033C13.9325 8.00033 14.1869 8.10568 14.3744 8.29322C14.562 8.48075 14.6673 8.73511 14.6673 9.00033V10.0003ZM9.33398 10.0003V11.3337H5.33398V8.66699C5.33398 8.31337 5.47446 7.97423 5.72451 7.72418C5.97456 7.47413 6.3137 7.33366 6.66732 7.33366H8.00065V6.00033H5.33398V4.66699H8.00065C8.35427 4.66699 8.69341 4.80747 8.94346 5.05752C9.19351 5.30756 9.33398 5.6467 9.33398 6.00033V7.33366C9.33398 7.68728 9.19351 8.02642 8.94346 8.27647C8.69341 8.52652 8.35427 8.66699 8.00065 8.66699H6.66732V10.0003H9.33398Z"
      fill={color}
    />
  </svg>
);
