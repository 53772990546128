import React from 'react';
import { NoDataContainer, NoDataText } from './styled';

export const NoData = () => {
  return (
    <NoDataContainer>
      <NoDataText>No data to visualize yet</NoDataText>
    </NoDataContainer>
  );
};
