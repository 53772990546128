import { colors, device, deviceMax, progress } from '@styles';
import styled from 'styled-components';

export const FileProgress = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.black};
  font-size: 14px;
  line-height: 1.25;

  width: 70px;
  ${device.sm`
    width: 100px;
  `}

  ${deviceMax.md`
    margin-left: 8px;
  `}

  ${progress};

  progress {
    margin-right: 8px;
  }
`;
