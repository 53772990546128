import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 0;
  height: 100%;
  min-width: 0px;
  position: relative;

  .stage-wrapper {
    width: 132px;
    height: 24px;
  }
`;

export const RightAxisTitles = styled.div<{ noWrap?: boolean }>`
  display: flex;
  justify-content: flex-end
  align-items: center;
  gap: 8px;
  color: #828d9a;
  font-size: 12px;
  font-weight: 400;
  width: 164px;
  margin-left: auto;

  > * {
    flex: 1;
    white-space: ${({ noWrap }) => (noWrap ? 'nowrap' : 'normal')};
  }
`;

export const LineAndStatusName = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const NoDataContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const NoDataText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  width: 100%;
`;
