import * as Yup from 'yup';

import { capitalizeFirstLetter } from './strings';

export const isEmptyString = (value?: string) => {
  if (!value) {
    return true;
  }

  return !value.trim();
};

type IsInvalidString = {
  (value: undefined): true;
  (value: string, errorMessage: string): false | string;
  (value: string | undefined): boolean;
};
export const isInvalidString: IsInvalidString = (value: any, errorMessage?: any) => {
  const invalidResult = errorMessage || true;

  if (!value) {
    return invalidResult;
  }

  return isEmptyString(value) ? invalidResult : false;
};

type StringSchema = ReturnType<typeof Yup.string>;

const addRequired = (title: string, schema: StringSchema, requiredFlag?: boolean) => {
  if (requiredFlag) {
    return schema
      .required(`${title} is required!`)
      .test('empty-check', `${title} can't be blank`, (value) => !isInvalidString(value));
  }

  return schema;
};

type MakeStringLengthValidation = (params: { title: string; maxLength: number; required?: boolean }) => {
  [key: string]: StringSchema;
};

export const makeStringLengthValidation: MakeStringLengthValidation = ({ title, maxLength, required }) => {
  const capitalizedTitle = capitalizeFirstLetter(title);

  return {
    [title]: addRequired(
      capitalizedTitle,
      Yup.string().max(maxLength, `${capitalizedTitle} should contain less than ${maxLength} characters`),
      required
    )
  };
};

const EMAIL_REGEX = /.+@.+\..+/;

export const isEmail = (value: string) => {
  return EMAIL_REGEX.test(value);
};

export const validateUrl = (url: string | null | undefined) => {
  if (!url || !url.trim()) {
    return undefined;
  }

  const errorMessage = 'URL is not valid';

  if (!url.startsWith('https://')) {
    return 'URL should be HTTPS';
  }

  const urlRegex = /^(https:\/\/)?([\da-z.-]+)\.([a-z]{2,63})(?:\/|$)/;
  if (!urlRegex.test(url)) {
    return errorMessage;
  }

  return undefined;
};
