import React, { useState, useCallback, useEffect } from 'react';
import { MapPin, Plus } from 'react-feather';
import { useModal } from '@common/PromiseModal';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { useAppSelector } from '@hooks/store';
import { selectCurrentUser, selectWorkspaceId } from '@state/selectors';
import { useUserRoleSettings } from '@hooks/useRoles';
import { hasAccess } from '@utils/roles';
import { SiteConent } from './SiteContent';
import { Site } from './types';
import { useSites } from './useSites';
import { CreateWork, Widget, WidgetHeader, WidgetIcon, WidgetTitle } from '../styled';
import { Form } from './Form';

interface Props {
  clientId: number;
}

export const Sites = ({ clientId }: Props) => {
  const [expanded, setExpanded] = useState<string | null>(null);
  const companyId = useAppSelector(selectWorkspaceId);
  const user = useAppSelector(selectCurrentUser);
  const { data: access } = useUserRoleSettings(companyId, user.userId);

  const { openModal } = useModal();

  const handleChange = useCallback((site: Site, isExpanded: boolean) => {
    setExpanded(isExpanded ? site.address : null);
  }, []);

  const sites = useSites(clientId);

  const canUpdateRecord = hasAccess(access, 'project', 'edit');

  useEffect(() => {
    if (sites.length) {
      setExpanded((prev) => (!prev ? sites[0].address : prev));
    }
  }, [sites]);

  const handleAdd = useCallback(() => {
    openModal(({ onClose }) => <Form clientId={clientId} onClose={onClose} />, { title: 'Create Site' });
  }, [clientId, openModal]);

  return (
    <Widget isFullWidth size="large">
      <WidgetHeader>
        <WidgetTitle>
          <WidgetIcon backgroundColor="#DBE5FF">
            <MapPin size="16px" color="#235DFF" />
          </WidgetIcon>
          Sites
          {canUpdateRecord && (
            <CreateWork>
              <Button onClick={handleAdd} variant={ButtonVariant.Secondary}>
                <Plus size="16px" />
                Site
              </Button>
            </CreateWork>
          )}
        </WidgetTitle>
      </WidgetHeader>

      <div>
        {sites.map((site) => (
          <SiteConent
            clientId={clientId}
            key={site.address}
            site={site}
            isExpanded={expanded === site.address}
            onToggleExpand={handleChange}
          />
        ))}
      </div>
    </Widget>
  );
};
