import React from 'react';
import { FormValidationRules, FormValidationProvider } from './validation';

interface Props {
  rules?: FormValidationRules;
  children: React.ReactNode;
  onSubmit: (e: React.BaseSyntheticEvent) => void;
}

const Form = ({ rules = {}, children, onSubmit }: Props) => (
  <FormValidationProvider value={rules}>
    <form onSubmit={onSubmit}>{children}</form>
  </FormValidationProvider>
);

export { Form };
