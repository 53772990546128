import styled from 'styled-components';
import { colors, device } from '@styles';

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: calc(100% + 30px);
  padding: 0;

  ${device.sm`
    width: 100%;
    margin-bottom: 8px;
    padding: 11px 8px;
  `};

  height: 40px;

  border-radius: 4px;
  background-color: ${colors.white};
  border: 1px solid #dfdfe8;

  font-size: 14px;
  line-height: 1.25;
  color: ${colors.black};

  &:focus {
    background-color: ${colors.gray};
  }
`;

export const Name = styled.div`
  display: block;

  max-width: 100px;
  ${device.sm`
    max-width: 190px;
  `}

  margin-right: auto;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DragIcon = styled.img`
  margin-right: 10px;
  opacity: 1;
  cursor: grab;
`;
