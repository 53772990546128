import { FunnelData } from '@features/Analytics/Widget/types';
import { postGraphql } from '@services/api/base/graphql';
import {
  ProjectReportGroupBy,
  TaskReportAggregates,
  TaskReportCondition,
  TaskReportFilter,
  TaskReportsConnection
} from '@generated/types/graphql';
import { gql } from 'graphql-request';
import { DeepPartial } from 'redux';
import { WidgetSettingsError } from '@features/Analytics/WidgetSettingsError';
import { AnalyticsWidgetType, WidgetSettings } from '@features/Analytics/types';
import { InternalConfigutationError } from '@features/Analytics/InternalConfigurationError';
import { buildCommonFilters, buildTimelineDateRangeCondition, buildTimelineFilter } from './helpers';
import { WorkOrdersWidgetDataParams } from '../types';
import { buildCreateAtDateRangeFilter } from '../helpers';

const buildRequestParams = ({ companyId, settings }: WorkOrdersWidgetDataParams) => {
  if (!settings.workflowId) {
    throw new WidgetSettingsError('Workflow is not specified');
  }

  const filter: DeepPartial<TaskReportFilter> = {
    ...buildCommonFilters(companyId, settings),
    ...buildTimelineFilter(settings),
    ...buildCreateAtDateRangeFilter(settings)
  };

  const condition: DeepPartial<TaskReportCondition> = {
    withStatusTimeline: true,
    ...buildTimelineDateRangeCondition(settings)
  };

  const groupBy: ProjectReportGroupBy[] = [ProjectReportGroupBy.TimelineStatus];

  return {
    filter,
    condition,
    groupBy
  };
};

const buildAggregationResponse = (settings: WidgetSettings) => {
  switch (settings.widgetType) {
    case AnalyticsWidgetType.TIMELINE: {
      return `
        average {
          timelineStatusSpentTime
        }
      `;
    }

    case AnalyticsWidgetType.PIPELINE:
    case AnalyticsWidgetType.FUNNEL: {
      return `
        distinctCount {
          id
        }
      `;
    }

    default:
      throw new InternalConfigutationError(`Only TIMELINE, PIPELINE and FUNNEL widget types are supported`);
  }
};

const extractGroupAggregationValue = (settings: WidgetSettings, group: TaskReportAggregates) => {
  if (settings.widgetType === AnalyticsWidgetType.TIMELINE) {
    return group.average.timelineStatusSpentTime;
  }

  return group.distinctCount.id;
};

const requestGroupedData = (params: WorkOrdersWidgetDataParams) => {
  const { filter, condition, groupBy } = buildRequestParams(params);

  const response = buildAggregationResponse(params.settings);

  return postGraphql<{ taskReportsConnection: TaskReportsConnection }>(
    gql`
      query WORK_ORDERS_TIMELINE_DATA_QUERY(
        $filter: TaskReportFilter!
        $condition: TaskReportCondition
        $groupBy: [TaskReportGroupBy!]!
      ) {
        taskReportsConnection(filter: $filter, condition: $condition) {
          groupedAggregates(groupBy: $groupBy) {
            keys
            ${response}
          }
        }
      }
    `,
    {
      filter,
      condition,
      groupBy
    }
  );
};

export const fetchWorkOrdersFunnelData = async (params: WorkOrdersWidgetDataParams): Promise<FunnelData> => {
  const result = await requestGroupedData(params);

  return {
    pointsMap: result.taskReportsConnection.groupedAggregates.reduce(
      (acc, group) => {
        const key = group.keys[0] as string;

        if (!key) {
          return acc;
        }

        const y = extractGroupAggregationValue(params.settings, group);
        const x = key;

        acc[x] = y;

        return acc;
      },
      {} as FunnelData['pointsMap']
    )
  };
};
