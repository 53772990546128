import { createGlobalState } from 'react-use';
import { assign, chunk, every, filter, flatten, map, mapValues, range, reduce, some } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useMutation } from 'react-query';

import projectApi from '@services/api/projectApi';
import { errorHandler } from '@services/api/helpers';
import { alertShow } from '@state/actions/alert/alertAction';
import { useAppDispatch, useAppSelector } from '@hooks/store';
import { BulkInsertDto, BulkUpdateDto } from '@services/api/types';
import { selectWorkspaceId } from '@state/selectors';
import { ProjectBulkInsertResponse, RecordType } from '@types';
import { usePropertyMappedName } from '@hooks';
import { ENTITY_NAME_BY_RECORD_TYPE } from '@features/ProjectPortfolio/Project/History/FeedItem/constants';

type Selections = {
  [itemId: number]: { isSelected: boolean; isArchived: boolean; companyId?: number };
};

type GroupedSelections = {
  [groupName: string]: Selections;
};

type ItemGroups = {
  [itemId: number]: string;
};

export type SelectedContext = { id: number; isArchived?: boolean; companyId?: number };

type AvailableGroups = {
  [groupName: string]: SelectedContext[];
};

const useSelections = createGlobalState<GroupedSelections>({});
const useItemGroups = createGlobalState<ItemGroups>({});

/**
 * Hook for project bulk selection. Usage:
 * 1) use setAvailable to update the hook with ids of currently visible projects for root component with data
 * 2) use isAllSelected + toggleAllSelected for 'Select all' checkbox in specific group
 * 3) use isSelected + toggle for individual project checkboxes
 */
export const useBulkState = () => {
  const [selections, setSelections] = useSelections();
  const [, setItemGroups] = useItemGroups();

  const setAvailable = (groups: AvailableGroups) => {
    const reversed: ItemGroups = {};

    setSelections(
      mapValues(groups, (items, groupName) =>
        reduce(
          items,
          (acc, { id, isArchived, companyId }) => {
            reversed[id] = groupName;

            return assign(acc, {
              [id]: { isSelected: !!selections[groupName]?.[id]?.isSelected, isArchived, companyId }
            });
          },
          {} as Selections
        )
      )
    );

    setItemGroups(reversed);
  };

  const selected = useMemo(
    () =>
      flatten(
        map(selections, (group) =>
          reduce(
            group,
            (ids, { isArchived, isSelected, companyId }, id) => {
              if (isSelected) {
                ids.push({ id: +id, isArchived, companyId });
              }

              return ids;
            },
            [] as SelectedContext[]
          )
        )
      ),
    [selections]
  );

  const isAllSelectionArchived = useMemo(() => every(selected, { isArchived: true }), [selected]);

  const countSelected = () => reduce(selections, (acc, group) => acc + filter(group, { isSelected: true }).length, 0);

  const clearSelection = () =>
    setSelections(
      mapValues(selections, (items) =>
        reduce(items, (acc, val, index) => ({ ...acc, [index]: { ...val, isSelected: false } }), {})
      )
    );

  return {
    setAvailable,
    selected: selected.map(({ id }) => id),
    selectedWithContext: selected,
    countSelected,
    clearSelection,
    isAllSelectionArchived
  };
};

export const useProjectBulkGroup = (groupName: string) => {
  const [selections, setSelections] = useSelections();

  const group = selections[groupName];

  const isAllSelected = useMemo(
    () => every(group, { isSelected: true }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selections]
  );
  const isOneOrMoreSelected = useMemo(
    () => some(group, { isSelected: true }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selections]
  );

  const toggleAllSelected = () =>
    setSelections(
      assign({}, selections, {
        [groupName]: reduce(
          group,
          (acc, { isArchived }, id) => ({
            ...acc,
            [id]: { isArchived, isSelected: !isAllSelected }
          }),
          group
        )
      })
    );

  const unselectAll = () =>
    setSelections(
      assign({}, selections, {
        [groupName]: reduce(
          group,
          (acc, { isArchived }, id) => ({
            ...acc,
            [id]: { isArchived, isSelected: false }
          }),
          group
        )
      })
    );

  const toggle = (projectIds: number[] = []) =>
    setSelections(
      assign({}, selections, {
        [groupName]: reduce(
          projectIds,
          (acc, projectId) => ({
            ...acc,
            [projectId]: { ...group[projectId], isSelected: !isAllSelected }
          }),
          group
        )
      })
    );

  const unselect = (projectIds: number[] = []) =>
    setSelections(
      assign({}, selections, {
        [groupName]: reduce(
          projectIds,
          (acc, projectId) => ({
            ...acc,
            [projectId]: { ...group[projectId], isSelected: false }
          }),
          group
        )
      })
    );

  return {
    isAllSelected,
    toggleAllSelected,
    isOneOrMoreSelected,
    unselectAll,
    toggle,
    unselect
  };
};

const EMPTY = {};

export const useProjectBulkElement = (id: number) => {
  const [selections, setSelections] = useSelections();
  const [itemGroups] = useItemGroups();

  // could be missing until root component inits selections
  const groupName = itemGroups[id];

  const group = groupName && selections[groupName] ? selections[groupName] : EMPTY;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isSelected = useMemo(() => !!group?.[id]?.isSelected, [selections]);

  const toggle = useCallback(
    () =>
      setSelections(
        assign({}, selections, {
          [groupName]: assign({}, group, {
            [id]: { ...group[id], isSelected: !isSelected }
          })
        })
      ),
    [id, selections, groupName, group, isSelected, setSelections]
  );

  return {
    isSelected,
    toggle
  };
};

const getEntityName = (count: number, recordType: RecordType) => {
  return `${ENTITY_NAME_BY_RECORD_TYPE[recordType]}${count === 1 ? '' : 's'}`;
};

export const useProjectBulk = (recordType: RecordType) => {
  const dispatch = useAppDispatch();
  const companyId = useAppSelector(selectWorkspaceId);
  const [insertProgress, setInsertProgress] = useState(0);
  const {
    data: { id: isActiveFieldId }
  } = usePropertyMappedName('isActive');

  const updateMutation = useMutation<{ data: { message: string } }, Error, BulkUpdateDto>(
    async (dto: BulkUpdateDto) => {
      try {
        return await projectApi.bulkUpdate(dto, { companyId });
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: ({ data }, { projects }) => {
        if (data?.message?.includes('job')) {
          dispatch(
            alertShow(
              ['Your bulk update has been successfully scheduled! All records will be updated shortly.'],
              'warning'
            )
          );

          return;
        }

        dispatch(alertShow([`${getEntityName(projects.length, recordType)} updated successfully`], 'success'));
      },
      onError: (error: Error) => {
        dispatch(alertShow([error.message], 'error'));
      }
    }
  );

  const archiveMutation = useMutation<void, Error, { projects: BulkUpdateDto['projects']; isArchive: boolean }>(
    async ({ projects, isArchive }) => {
      try {
        if (!isActiveFieldId) {
          throw new Error('Could not found archived property, please try again.');
        }

        await projectApi.bulkUpdate(
          {
            projects,
            field: {
              fieldId: isActiveFieldId,
              value: !isArchive
            }
          },
          { companyId }
        );
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: (_, { projects, isArchive }) => {
        dispatch(
          alertShow(
            [`${getEntityName(projects.length, recordType)} ${isArchive ? 'archived' : 'unarchived'} successfully`],
            'success'
          )
        );
      },
      onError: (error: Error) => {
        dispatch(alertShow([error.message], 'error'));
      }
    }
  );

  const removeMutation = useMutation<void, Error, { projects: BulkUpdateDto['projects'] }>(
    async ({ projects }) => {
      try {
        await projectApi.bulkRemove(projects, companyId as number);
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: (_, { projects }) => {
        dispatch(alertShow([`${getEntityName(projects.length, recordType)} removed successfully`], 'success'));
      },
      onError: (error: Error) => {
        dispatch(alertShow([error.message], 'error'));
      }
    }
  );

  const insertMutation = useMutation<
    void,
    Error,
    { insertRows: BulkInsertDto[]; companyId: number; chunkSize?: number }
  >(async ({ insertRows, companyId, chunkSize = 10 }) => {
    setInsertProgress(0);
    const insertRowChunks = chunk(insertRows, chunkSize);
    const totalChunk = insertRowChunks.length;
    const result: ProjectBulkInsertResponse = { failedProjects: [] };

    if (totalChunk === 0) {
      return result;
    }

    let currentChunk = 0;
    // eslint-disable-next-line no-restricted-syntax
    for await (const rowChunk of insertRowChunks) {
      const rowIndexPadding = currentChunk === 0 ? 0 : currentChunk * chunkSize;
      try {
        result.failedProjects.push(
          ...(await projectApi.bulkInsert(rowChunk, companyId)).data.failedProjects.map((failedProject) => ({
            ...failedProject,
            index: rowIndexPadding + failedProject.index
          }))
        );
      } catch (err) {
        console.error("couldn't do csv import", err, rowChunk);

        const response = err?.response;
        const indexToError: { [key: number]: string } = {};

        if (response) {
          const { data, status } = response;
          if (status === 400) {
            const { message = [] } = data;

            if (Array.isArray(message)) {
              dispatch(
                alertShow(
                  message.map((error = {}) => {
                    const errorMessage = Object.keys(error.error?.[0]?.constraints ?? {}).map(
                      (key) => error.error?.[0]?.constraints?.[key]
                    );
                    indexToError[(rowIndexPadding + error.index) as unknown as number] =
                      errorMessage as unknown as string;

                    return `CSV row ${error.index + 2} has following error: ${errorMessage}`;
                  }),
                  'error'
                )
              );
            }
          }
        }

        result.failedProjects.push(
          ...range(rowChunk.length).map((rowNumber) => ({
            error: indexToError[rowIndexPadding + rowNumber] ?? 'VALIDATION ERROR',
            index: rowIndexPadding + rowNumber
          }))
        );
      }
      currentChunk += 1;
      setInsertProgress((currentChunk / totalChunk) * 100);
    }

    return result;
  });

  return {
    update: updateMutation,
    archive: archiveMutation,
    insert: insertMutation,
    remove: removeMutation,
    insertProgress
  };
};
