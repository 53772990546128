import styled from 'styled-components';
import { colors, dropdown } from '@styles';
import { Link } from 'gatsby';

export const Wrapper = styled.div`
  cursor: pointer;
  transition: 0.3s ease-out;
`;

export const IconWrapper = styled.div<{ colors: any }>`
  display: flex;
  align-items: center;

  svg {
    color: ${(props) => (props.colors ? props.colors[0] : colors.gray)};
  }

  &:hover, &:focus {
    svg {
      color: ${(props) => (props.colors ? props.colors[1] : colors.green)};
    }
  }
`;

export const Menu = styled.div`
  ${dropdown.menu};
`;

export const MenuItem = styled.div<{ color?: string }>`
  ${dropdown.item};

  color: ${(props) => props.color || colors.black};
  display: flex;
`;

export const MenuLink = styled(Link)`
  ${dropdown.item};
  display: flex;
  text-decoration: none;
`;
