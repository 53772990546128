import React from 'react';
import { Pagination, PaginationProps } from '@common/ui';
import { NO_GROUP, useTableGroupPagination } from '@common/Table';
import { Pager, Wrapper } from './styled';

interface TablePaginationProps<TGroupValue> extends PaginationProps {
  group?: TGroupValue;
  total: number;
}

export const TablePagination = <TGroupValue,>(props: TablePaginationProps<TGroupValue>) => {
  const {
    group = NO_GROUP,
    total,
    ...rest
  } = props;

  const { page, perPage, setPage, setPerPage } = useTableGroupPagination(group);

  return (
    <Wrapper>
      <Pagination
        count={Math.ceil(total / perPage)}
        page={page}
        onChange={(_event, page) => setPage(page)}
        {...rest}
      />
      <Pager>
        Items per page
        <select value={perPage} onChange={(event) => setPerPage(+event.target.value)}>
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
        </select>
      </Pager>
    </Wrapper>
  );
};
