import { Project } from '@generated/types/graphql';
import { RecordType } from '@types';
import React from 'react';
import solarPanelImage from '@features/ProjectPortfolio/assets/solarPanel.svg';
import { Status as ProjectStatus } from '@features/ProjectPortfolio/components/Status';
import { Status as ClientStatus } from '@features/ClientPortfolio/components/Status';
import { Status as RequestStatus } from '@features/RequestPortfolio/components/Status';

import { Archive } from 'react-feather';
import { AccountTypeBadge } from '@features/ClientPortfolio/components/Type';
import { DeepPartial } from 'redux';
import { ClientType, Image, ImageContainer, RecordNumber, RecordTitleCellContainer, StatusAndTitle } from './styled';

interface Props {
  record: DeepPartial<Project>;
}

export const RecordTitleCell = ({ record }: Props) => {
  if (record.type === RecordType.ACCOUNT) {
    return (
      <RecordTitleCellContainer>
        <div>
          <StatusAndTitle>
            <ClientStatus status={record.accountStatus} />
            {!record.isActive && <Archive color="#9C9CAA" size="16px" />}
            <RecordNumber>#{record.uid}:</RecordNumber>
            {record.title}
          </StatusAndTitle>
          <ClientType>
            <AccountTypeBadge color="#828D9A" type={record.accountType} />
          </ClientType>
        </div>
      </RecordTitleCellContainer>
    );
  }

  if (record.type === RecordType.DEAL) {
    return (
      <RecordTitleCellContainer>
        <div>
          <StatusAndTitle>
            <RequestStatus status={record.requestStatus} />
            {!record.isActive && <Archive color="#9C9CAA" size="16px" />}
            <RecordNumber>#{record.uid}:</RecordNumber>
            {record.title}
          </StatusAndTitle>
        </div>
      </RecordTitleCellContainer>
    );
  }

  return (
    <RecordTitleCellContainer>
      <ImageContainer>
        {!record.imageUrl && !record.streetViewUrl && <img src={solarPanelImage} width="26px" alt="" />}
        {(record.imageUrl || record.streetViewUrl) && (
          <Image
            style={{
              backgroundImage: `url(${record.imageUrl || record.streetViewUrl})`
            }}
          />
        )}
      </ImageContainer>
      <StatusAndTitle>
        <ProjectStatus status={record.status} />
        {!record.isActive && <Archive color="#9C9CAA" size="16px" />}
        <RecordNumber>#{record.uid}:</RecordNumber>
        {record.title}
      </StatusAndTitle>
    </RecordTitleCellContainer>
  );
};
