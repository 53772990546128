import React, { useCallback } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { Slider, SliderProps } from '@kit/ui/Slider';
import { FormField } from '../../FormField';
import { FormControl, FormInputPropsToOmit } from '../../types';
import { useControllerWithValidation } from '../../useControllerWithValidation';
import { Label } from './styled';

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends Omit<React.ComponentProps<typeof SliderProps>, FormInputPropsToOmit>,
    FormControl<TFieldValues, TName> {
  onChanged?: () => void;
}

export const ProgressField = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  label,
  description,
  name,
  control,
  onChanged,
  ...props
}: Props<TFieldValues, TName>) => {
  const {
    field: { value = 0, onChange },
    fieldState: { error }
  } = useControllerWithValidation(name, control, label);

  const handleChange = useCallback(
    (_e: any, value: number) => {
      onChange(value);
      onChanged?.();
    },
    [onChange, onChanged]
  );

  return (
    <FormField
      label={
        <Label>
          {label}
          <div>{value}%</div>
        </Label>
      }
      error={error?.message}
      description={description}
    >
      <Slider {...props} value={value} onChange={handleChange} />
    </FormField>
  );
};
