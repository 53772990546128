import { ReactQueryKey } from '@enums';
import { File, FileFilter, FilesConnection } from '@generated/types/graphql';
import { useAppSelector } from '@hooks/index';
import { postGraphql } from '@services/api/base/graphql';
import { selectWorkspaceId } from '@state/selectors';
import { gql } from 'graphql-request';
import { useQuery } from 'react-query';
import { DeepPartial } from 'redux';

const fetchDoc = async ({ companyId, fileId }: { companyId: number; fileId: number }) => {
  const filter: DeepPartial<FileFilter> = {
    id: {
      equalTo: fileId
    },
    companyId: {
      equalTo: companyId
    }
  };

  const {
    filesConnection: { nodes }
  } = await postGraphql<{ filesConnection: FilesConnection }>(
    gql`
      query DOC_DETAILS($filter: FileFilter!) {
        filesConnection(filter: $filter) {
          nodes {
            id
            name
            metaData
            downloadUrl
            type
            annotations
            projectId
            privilege
            isArchived
            sharedWithPortal
            stage {
              id
              name
            }
            form {
              id
              file {
                projectId
              }
              formLayouts(filter: { type: { equalTo: "COLUMN" } }) {
                column {
                  id
                  projectPropertyValuesByColumnId {
                    textValue
                  }
                  projectColumnId
                  mappedName
                  type
                }
              }
            }
            createdByUser {
              id
              firstName
              lastName
              avatarUrl
            }
            createdAt
            updatedAt
          }
        }
      }
    `,
    { filter }
  );

  return nodes[0];
};

interface Props {
  fileId?: number | null;
  refetchOnMount?: boolean;
}

export const useDocDetails = ({ fileId, refetchOnMount }: Props) => {
  const companyId = useAppSelector(selectWorkspaceId);

  const {
    data: doc,
    isLoading,
    isFetching
  } = useQuery<File>(
    [ReactQueryKey.Files, ReactQueryKey.FileDetails, fileId, companyId],
    () => fetchDoc({ companyId, fileId }),
    {
      enabled: Boolean(fileId),
      refetchOnMount,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 3600 * 24
    }
  );

  return {
    doc,
    isLoading,
    isFetching
  };
};
