import React, { useCallback } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { DateTimePicker } from '@kit/ui/DateTimePicker';
import { FormField } from '../FormField';
import { FormControl, FormInputPropsToOmit } from '../types';
import { useControllerWithValidation } from '../useControllerWithValidation';
import { useInlineEditableField } from './useInlineEditableField';

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends Omit<React.ComponentProps<typeof DateTimePicker>, FormInputPropsToOmit | 'onClose'>,
    FormControl<TFieldValues, TName> {
  onClose?: (value: Date) => void;
  renderView: (value: string, onClick: () => void) => React.ReactNode;
  onChanged?: () => void;
}

export const InlineEditableDateTimeField = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  label,
  description,
  name,
  control,
  onClose,
  clearOnUnmount,
  renderView,
  onChanged,
  ...inputProps
}: Props<TFieldValues, TName>) => {
  const { isEditMode, onEnterEditMode, onExitEditMode } = useInlineEditableField(onChanged);

  const {
    field: { onChange, ...controlProps },
    fieldState: { error }
  } = useControllerWithValidation(name, control, label, clearOnUnmount);

  const handleChange = useCallback(
    (value: Date) => {
      onChange(value);
      onClose?.(value);
      onExitEditMode();
    },
    [onChange, onClose, onExitEditMode]
  );

  return (
    <FormField name={name} label={label} error={error?.message} description={description}>
      {!isEditMode && renderView(controlProps.value, onEnterEditMode)}
      {isEditMode && (
        <DateTimePicker
          {...controlProps}
          {...inputProps}
          data-test-id={`field-${controlProps.name}`}
          onClose={handleChange}
          initialIsOpen
        />
      )}
    </FormField>
  );
};
