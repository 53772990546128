import React, { useCallback, useMemo } from 'react';

import { Form, useForm, FormValidationRules, SelectField, RichTextField } from '@kit/components/Form';
import { useAppSelector } from '@hooks/store';
import { selectCurrentUserId, selectWorkspaceId } from '@state/selectors';
import { useCompanyUsers } from '@hooks/useCompanyUsers';
import { useRelatedContacts } from '@hooks/useContacts';
import { getFullName } from '@utils/utils';
import { useTokebsWithValuesWithRecordId } from '@hooks/useTokens';
import { clearQuillValueForSms, convertQuillFormatToText, isQuilValueEmpty } from '@utils/quill';
import { useSmsMutations } from '@hooks/useSms';
import { RecordType, SendSmsDTO } from '@types';
import { useModal } from '@common/PromiseModal';
import { Maximize2 } from 'react-feather';
import { Button, ButtonSize, ButtonVariant } from '@kit/ui/Button';
import { useCompanyPhoneList } from '@hooks/workspace/phones/useCompanyPhoneList';
import { QueryParamsEnum, useQueryParamMutation } from '@hooks/useQueryParam';
import { ActionButtons, BodyWithActionButtons, ModalBody } from './styled';

interface FormValues {
  from: { id: number; title: string };
  to: {
    id: string;
    originalId: number;
    title: string;
    phone: string;
    isContact?: boolean;
    isCompanyUser?: boolean;
    isCompanyPhone?: boolean;
  };
  text: string;
}
interface Props {
  recordId: number;
  recordType: RecordType;
  onClose: () => void;
  initialValues?: FormValues;
  isExpandable?: boolean;
}

export const SmsForm = ({ recordId, recordType, onClose, isExpandable = true, initialValues }: Props) => {
  const companyId = useAppSelector(selectWorkspaceId);
  const currentUserId = useAppSelector(selectCurrentUserId);
  const { data: relatedContacts } = useRelatedContacts(companyId, recordId!);
  const tokens = useTokebsWithValuesWithRecordId(recordId, recordType);
  const { sendMutation } = useSmsMutations();
  const { data: companyPhones = [] } = useCompanyPhoneList();

  const {
    data: {
      data: { results: companyUsers }
    }
  } = useCompanyUsers(companyId);

  const { setParams } = useQueryParamMutation();

  const postForm = async (values: FormValues) => {
    const dto: SendSmsDTO = {
      fromCompanyPhoneId: values.from.id,
      text: clearQuillValueForSms(convertQuillFormatToText(values.text, true)),
      toCompanyPhoneId: values.to.isCompanyPhone ? values.to.originalId : undefined,
      toUserId: values.to.isCompanyUser ? values.to.originalId : undefined,
      toContactId: values.to.isContact ? values.to.originalId : undefined,
      toPhone: values.to.isContact ? values.to.phone : undefined
    };

    await sendMutation.mutateAsync({
      dto,
      companyId,
      recordId
    });

    setParams(
      {
        [QueryParamsEnum.FeedCursor]: undefined,
        [QueryParamsEnum.FeedId]: undefined
      },
      true
    );

    onClose();
  };

  const { handleSubmit, form } = useForm<FormValues>({
    onSubmit: postForm,
    defaultValues: initialValues ?? {}
  });

  const rules = useMemo<FormValidationRules<FormValues>>(
    () => ({
      from: { isRequired: true },
      to: { isRequired: true },
      text: {
        isRequired: true,
        validate: (value) => {
          if (value && isQuilValueEmpty(value as string)) {
            return 'This is required';
          }

          return undefined;
        }
      }
    }),
    []
  );

  const companyMembersOptions = companyUsers
    .filter(({ phone }) => phone)
    .map(({ id, phone, firstName, lastName }) => ({
      id: `isCompanyUser-${id}`,
      originalId: id,
      title: `${getFullName({ firstName, lastName })} (${phone})`,
      isCompanyUser: true
    }));

  const contactsOptions = relatedContacts!.flatMap(({ id, name, phones }) =>
    phones.map((phone) => ({
      title: `${name} (${phone})`,
      id: `isContact-${id}-${phone}`,
      phone,
      originalId: id,
      isContact: true
    }))
  );

  const toCompanyPhones =
    companyPhones?.map(({ id, alias, phone }) => ({
      id: `isCompanyPhone-${id}`,
      title: `${alias} (${phone})`,
      isCompanyPhone: true,
      originalId: id
    })) || [];

  const toFieldOptions = [...contactsOptions, ...companyMembersOptions, ...toCompanyPhones];

  const fromFieldOptions =
    companyPhones
      .filter((phone) => phone.companyPhoneUsers?.some((user) => user.canSend && user.user?.id === currentUserId))
      .map(({ id, alias, phone }) => ({
        id,
        alias,
        phone,
        title: `${alias} (${phone})`
      })) || [];

  const toFieldOptionsGrouping = ({
    isCompanyUser,
    isContact,
    isCompanyPhone
  }: {
    isCompanyUser?: boolean;
    isContact?: boolean;
    isCompanyPhone?: boolean;
  }) => {
    if (isContact) {
      return 'Contacts';
    }

    if (isCompanyUser) {
      return 'Workspace members';
    }

    if (isCompanyPhone) {
      return 'Workspace phones';
    }

    return null;
  };

  const {
    formState: { isSubmitting, errors },
    control
  } = form;

  const { openModal } = useModal();

  const handleExpandClick = useCallback(() => {
    const values = form.getValues();
    form.reset({ from: null, to: null, text: '' });
    onClose?.();

    openModal<void>(
      ({ onClose: onModalClose }) => (
        <ModalBody>
          <SmsForm
            recordId={recordId}
            onClose={() => {
              onModalClose();
            }}
            initialValues={values}
            isExpandable={false}
          />
        </ModalBody>
      ),
      {
        title: 'New SMS'
      }
    );
  }, [form, onClose, recordId, openModal]);

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <SelectField
        label="From"
        name="from"
        getOptionLabel={(option) => option.title}
        options={fromFieldOptions}
        control={control}
      />
      <SelectField
        label="To"
        name="to"
        getOptionLabel={(option) => option.title}
        options={toFieldOptions}
        groupBy={toFieldOptionsGrouping}
        control={control}
      />
      <BodyWithActionButtons>
        <RichTextField
          name="text"
          getTokens={tokens?.getAllTokens}
          control={control}
          placeholder="Type here..."
          isToolbarShown={false}
        />
        <ActionButtons isBodyWithError={Boolean(errors.text)}>
          {isExpandable && (
            <Button
              size={ButtonSize.Small}
              isUpperCase={false}
              variant={ButtonVariant.Flat}
              onClick={handleExpandClick}
            >
              <Maximize2 size="16px" />
              Expand
            </Button>
          )}
          <Button variant={ButtonVariant.Flat} onClick={onClose}>
            Cancel
          </Button>
          <Button disabled={isSubmitting} type="submit" variant={ButtonVariant.Primary}>
            Send
          </Button>
        </ActionButtons>
      </BodyWithActionButtons>
    </Form>
  );
};
