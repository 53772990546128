import React from 'react';
import { MenuItem } from '@material-ui/core';
import { Field } from 'formik';
import { Select } from 'formik-material-ui';
import { ChevronDown } from 'react-feather';
import { InputLabel } from '@common/ui';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { selectMui, dropdown } from '@styles';
import { PriorityIcon } from '@common/PriorityIcon';

const styles = selectMui({});
const useStyles = makeStyles(styles);

const Wrapper = styled.div`
  && {
    .MuiInputBase-root {
      width: 100%;
    }

    .MuiSelect-outlined.MuiSelect-outlined {
      text-transform: none;
    }
  }
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    ${dropdown.item};
    text-transform: none;
    min-width: auto;
    width: calc(100% - 20px);
    margin-left: 10px;
  }
`;

const Option = styled(({ high: _high, medium: _medium, ...props }) => <div {...props} />)`
  display: flex;
  align-items: center;

  svg {
    margin-right: 7px;
  }
`;

type Props = {
  label?: string;
  name?: string;
  component?: JSX.Element;
};

const options = [
  {
    id: 1,
    name: (
      <Option high>
        <PriorityIcon priority={1} /> High
      </Option>
    )
  },
  {
    id: 2,
    name: (
      <Option medium>
        <PriorityIcon priority={2} /> Medium
      </Option>
    )
  },
  {
    id: 3,
    name: (
      <Option low>
        <PriorityIcon priority={3} /> Low
      </Option>
    )
  }
];

const PrioritySelect: React.FC<Props> = (props: Props) => {
  const { label, name, component = Select, onOpen, onClose, open } = props;

  const classes = useStyles();

  return (
    <Wrapper>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Field
        classes={classes}
        component={component}
        name={name}
        variant="outlined"
        // autoFocus
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right'
          }
        }}
        IconComponent={ChevronDown}
        data-testid="prioritySelect"
        open={open}
        onOpen={onOpen}
        onClose={onClose}
      >
        {options.map((option) => (
          <StyledMenuItem value={option.id} key={option.id} data-testid="prioritySelectOption">
            {option.name}
          </StyledMenuItem>
        ))}
      </Field>
    </Wrapper>
  );
};

export default PrioritySelect;
