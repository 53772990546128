import { createAction } from 'redux-actions';
import download from 'downloadjs';
import moment, { Moment } from 'moment';

import { AsyncActionReturn } from '@state/reducers/types';
import { selectCompanyById, selectWorkspaceId } from '@state/selectors';

import projectApi from '@services/api/projectApi';

// archive project
export const archiveProjectRequest = createAction('ARCHIVE_PROJECT_REQUEST');
export const archiveProjectSuccess = createAction('ARCHIVE_PROJECT_SUCCESS');
export const archiveProjectFailure = createAction('ARCHIVE_PROJECT_ERROR');

// TODO: param type
export const archiveProject =
  (id: number, params: any, cb: () => void): AsyncActionReturn =>
  async (dispatch) => {
    try {
      await dispatch(archiveProjectRequest());

      const { data } = await projectApi.archiveProject(id, params);
      if (data) {
        await dispatch(archiveProjectSuccess());
        cb();
      } else {
        await dispatch(archiveProjectFailure());
      }
    } catch (error) {
      dispatch(archiveProjectFailure());
    }
  };

// delete project
export const deleteProjectRequest = createAction('DELETE_PROJECT_REQUEST');
export const deleteProjectSuccess = createAction('DELETE_PROJECT_SUCCESS');
export const deleteProjectFailure = createAction('DELETE_PROJECT_ERROR');

export const deleteProject =
  (id: number, cb: () => void): AsyncActionReturn =>
  async (dispatch) => {
    try {
      await dispatch(deleteProjectRequest());
      const { data } = await projectApi.deleteProject(id);

      if (data) {
        await dispatch(deleteProjectSuccess(id));
        cb();
      } else {
        dispatch(archiveProjectFailure());
      }
    } catch (error) {
      console.error('Error on project delete: ', error);
      dispatch(archiveProjectFailure());
    }
  };

// delete selected project
export const deleteSelectedProjectSuccess = createAction('DELETE_SELECTED_PROJECT_SUCCESS');

export const updateSelectedProjectSuccess = createAction('UPDATE_SELECTED_PROJECT_SUCCESS');

// fetch single project
export const fetchSingleProjectRequest = createAction('FETCH_SINGLE_PROJECT_REQUEST');
export const fetchSingleProjectSuccess = createAction('FETCH_SINGLE_PROJECT_SUCCESS');
export const fetchSingleProjectFailure = createAction('FETCH_SINGLE_PROJECT_ERROR');

export const fetchSingleProject =
  (id: number): AsyncActionReturn =>
  async (dispatch) => {
    try {
      dispatch(fetchSingleProjectRequest());
      const { data: project } = await projectApi.fetchSingleProject(id);

      if (project) {
        dispatch(fetchSingleProjectSuccess({ project }));
      } else {
        dispatch(fetchSingleProjectFailure());
      }
    } catch (error) {
      console.error('Error on fetch single project ', error);
      dispatch(fetchSingleProjectFailure());
    }
  };

type DownloadExportValues = {
  startDate: string | Moment | Date;
  endDate?: string | Date | Moment;
};

export const downloadExport =
  (values: DownloadExportValues): AsyncActionReturn =>
  async (dispatch, getState) => {
    const state = getState();
    const companyId = selectWorkspaceId(state);
    const company = selectCompanyById(state, companyId);

    try {
      const { data } = await projectApi.downloadExport({ ...values, companyId });

      const dateRange = [values.startDate, values.endDate || Date.now()]
        .map((value) => moment(value).format('MM_DD_YYYY'))
        .join('_');
      const filename = `${company?.name || 'your_company'}_${dateRange}.csv`;

      download(data, filename);
    } catch (err) {
      console.error('Error on CVS downloading', err);
    }
  };
