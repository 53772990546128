import React, { useRef } from 'react';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

import { XIcon } from '@kit/ui/icons/X';
import { Wrapper, Back } from './styled';

interface CustomCameraProps {
  attachFiles: (files: File[]) => void;
  onClose: () => void;
}

export const CustomCamera = (props: CustomCameraProps) => {
  const { attachFiles, onClose } = props;

  const wrapperRef = useRef<Element>(null);
  // const orientation = useOrientation();

  const onPhotoTaken = async (dataUri: string) => {
    const res = await fetch(dataUri); // magic
    const file = (await res.blob()) as File; // blob is technically a file, just without modified date and name
    file.lastModified = new Date();
    file.name = new Date().toISOString() + (file?.type ? `.${file.type.split('/').pop()}` : '');

    // push(file);
    attachFiles([file as File]);
  };

  const handleClose = async () => {
    onClose();
    // if (downloadPhotosAutomatically) {
    //   try {
    //     console.log('shots', shots.length);
    //   } catch (e) {
    //     // in case permission is not granted - we want to ignore this part instead of crashing
    //     console.error('Failed to download files', e);
    //   }
    // }
  };

  /* const canvasHeightDiff = useMemo(() => {
    const isSafari = md.is('iOS');
    if ([90, 270].includes(orientation?.angle)) {
      return 55;
    }

    if (isSafari) {
      return 170;
    }

    return 110;
  }, [orientation?.angle]); */

  return (
    <Wrapper ref={wrapperRef}>
      <Camera
        onTakePhoto={(dataUri: string) => onPhotoTaken(dataUri)}
        idealFacingMode={FACING_MODES.ENVIRONMENT}
        isImageMirror={false}
        imageType={IMAGE_TYPES.JPG}
        isMaxResolution
        isFullscreen
      />
      <Back className="close-button" onClick={handleClose}>
        <XIcon size="32px" />
      </Back>
    </Wrapper>
  );
};
