import { useAppSelector, useRecordDetail } from '@hooks/index';
import { useMemo } from 'react';
import { selectWorkspaceId } from '@state/selectors';
import { useWorkflow } from '@components/templates/Workspace/Automations/hooks';
import { useProjectWorkflow } from '@hooks/workflows/useProjectWorkflow';
import { AutomationStepType, ReactQueryKey, StepFieldKeys, Trigger } from '@enums';
import { RecordType } from '@types';
import { useWorkOrderList } from '@hooks/workOrders/useWorkOrderList';
import { EntityAutomation, EntityAutomationType, EntityType } from './types';

export const useAutomations = (entityId: number, entityType: EntityType) => {
  const companyId = useAppSelector(selectWorkspaceId);
  const {
    projectStageAutomations,
    fetchTaskAutomations,
    dealStageAutomations,
    projectPropertyUpdateAutomations,
    requestPropertyUpdateAutomations,
    fetch: { data: automations }
  } = useWorkflow(companyId);

  const { data: record } = useRecordDetail(entityId, { refetchOnMount: false });
  const { data: serviceWorkOrders = [] } = useWorkOrderList({
    queryKey: ReactQueryKey.TasksInSites,
    filters: {
      projectId: { equalTo: entityId }
    },
    isEnabled: entityType === EntityType.Client
  });

  const { data: workflow } = useProjectWorkflow(record.blueprintId);

  return useMemo<{ active: EntityAutomation[]; inactive: EntityAutomation[] }>(() => {
    const result: EntityAutomation[] = [];

    if (automations) {
      result.push(
        ...automations
          .filter((automation) => {
            const trigger = automation.steps.find((step) => step.key === Trigger.CALL_UPSERTED);

            if (!trigger) {
              return false;
            }

            return trigger.fields.find(
              (field) => field.key === StepFieldKeys.RECORD_TYPE && field.value === entityType
            );
          })
          .map((automation) => ({
            type: EntityAutomationType.Call as const,
            automation
          }))
      );
    }

    if (entityType === EntityType.Client && serviceWorkOrders.length) {
      const workOrderTemplates = serviceWorkOrders.map(({ templateTask }) => templateTask).filter(Boolean);
      // find WO templates automations
      workOrderTemplates.forEach((template) => {
        if (fetchTaskAutomations[template.id]?.length) {
          const automations = fetchTaskAutomations[template.id];
          automations.forEach((automation) => {
            const trigger = automation.steps.find((step) => step.type === AutomationStepType.TRIGGER);
            const parentType = trigger?.fields.find((field) => field.key === StepFieldKeys.RECORD_TYPE)?.value;

            if (!parentType || parentType === RecordType.ACCOUNT) {
              result.push({
                automation,
                workOrderTemplate: template,
                type: EntityAutomationType.WorkOrderTemplate
              });
            }
          });
        }
      });
    }

    if (workflow && [EntityType.Project, EntityType.Request].includes(entityType)) {
      const projectStages = workflow.blueprintProjectStages.map(({ projectStage }) => projectStage);

      // find project stage automations
      projectStages.forEach((stage) => {
        const stageAutomations =
          entityType === EntityType.Request ? dealStageAutomations[stage.id] : projectStageAutomations[stage.id];
        if (stageAutomations?.length) {
          stageAutomations.forEach((automation) => {
            const projectStageMovementStep = automation.steps.find(
              (step) => step.key === Trigger.PROJECT_MOVEMENT || step.key === Trigger.DEAL_MOVEMENT
            );
            const slaStep = automation.steps.find(
              (step) => step.key === Trigger.PROJECT_SLA_VIOLATION || step.key === Trigger.DEAL_SLA_VIOLATION
            );

            if (projectStageMovementStep) {
              const toStageId = projectStageMovementStep?.fields.find(
                (field) => field.key === StepFieldKeys.TO_STAGE_ID
              )?.value;

              const toStage = toStageId ? projectStages.find((stage) => stage.id === toStageId) : null;
              if (toStage) {
                result.push({
                  from: stage,
                  to: toStage,
                  automation,
                  type: EntityAutomationType.Stage
                });
              }
            }

            if (slaStep) {
              const stageId = slaStep?.fields.find((field) => field.key === StepFieldKeys.STAGE_ID)?.value;

              const stage = stageId ? projectStages.find((stage) => stage.id === stageId) : null;

              if (stage) {
                result.push({
                  stage,
                  automation,
                  type: EntityAutomationType.Stage
                });
              }
            }
          });
        }
      });
    }

    if (workflow && entityType === EntityType.Project) {
      result.push(
        ...projectPropertyUpdateAutomations.map((automation) => ({
          automation,
          type: EntityAutomationType.Property as const
        }))
      );

      const workOrderTemplates = workflow.blueprintTimeslice
        .map(({ blueprintTasksBySliceId }) => blueprintTasksBySliceId.map(({ task }) => task))
        .flat();

      // find WO templates automations
      workOrderTemplates.forEach((template) => {
        if (fetchTaskAutomations[template.id]?.length) {
          const taskAutomations = fetchTaskAutomations[template.id];

          taskAutomations.forEach((automation) => {
            const trigger = automation.steps.find((step) => step.type === AutomationStepType.TRIGGER);
            const parentType = trigger?.fields.find((field) => field.key === StepFieldKeys.RECORD_TYPE)?.value;

            if (!parentType || parentType === RecordType.PROJECT) {
              result.push({
                automation,
                workOrderTemplate: template,
                type: EntityAutomationType.WorkOrderTemplate
              });
            }
          });
        }
      });
    }

    if (entityType === EntityType.Request && automations) {
      result.push(
        ...requestPropertyUpdateAutomations.map((automation) => ({
          automation,
          type: EntityAutomationType.Property as const
        }))
      );
      result.push(
        ...automations
          .filter((automation) =>
            automation.steps.find(
              (step) => step.key === Trigger.APPOINTMENT_CREATED || step.key === Trigger.APPOINTMENT_RESCHEDULED
            )
          )
          .map((automation) => ({
            type: EntityAutomationType.Appointment as const,
            automation
          }))
      );
    }

    return {
      active: result.filter(({ automation }) => automation.isActive),
      inactive: result.filter(({ automation }) => !automation.isActive)
    };
  }, [
    projectStageAutomations,
    fetchTaskAutomations,
    workflow,
    automations,
    dealStageAutomations,
    entityType,
    serviceWorkOrders,
    projectPropertyUpdateAutomations,
    requestPropertyUpdateAutomations
  ]);
};
