import { device } from '@styles';
import styled from 'styled-components';

export const OuterButtons = styled.div<{ isRow: boolean }>`
  position: ${(props) => (props.isRow ? 'initial' : 'absolute')};
  display: flex;
  align-items: ${(props) => (props.isRow ? 'center' : 'stretch')};
  top: 48px;
  width: 100%;
  padding: ${(props) => (props.isRow ? '0 16px' : '0')};
  border-bottom: ${(props) => (props.isRow ? '1px solid #DFDFE8' : 'none')};
  ${device.md`
    left: -40px;
    top: 25px;
    flex-direction: ${(props) => (props.isRow ? 'row' : 'column')};
    width: auto;
    gap: 8px;
    > * {
      flex: ${(props) => (props.isRow ? '0' : 'auto')};
    }

  .close,
  .next,
  .prev {
      order: initial;
  }

  .prev {
    margin-top: ${(props) => (props.isRow ? '0' : '16px')};
  }
`}
`;

const OuterButton = styled.div<{ isDisabled?: boolean }>`
  background-color: white;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};

  width: 40px;
  min-width: 40px;

  ${device.md`
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    `}
`;

export const CloseButton = styled(OuterButton)`
  color: #9c9caa;
  transition: color 0.15s ease-in-out;
  height: 40px;

  &:hover {
    color: #1d1d35;
  }

  ${device.md`
     width: 40px;
    `}
`;

export const ArrowButton = styled(OuterButton)<{ isDisabled: boolean }>`
  height: 40px;
  color: #9c9caa;
  transition: color 0.15s ease-in-out;

  &:hover {
    color: #1d1d35;
  }

  ${device.md`
     width: 40px;
     height: 32px;
    `}
`;
