export const makeMergePayloadToState = (extraData) => (state, action) => ({
  ...state,
  ...action?.payload,
  ...extraData,
});

export const makeMergePayloadToStateKey = (key: string) => (state, { payload }) => ({
  ...state,
  [key]: payload,
});

export const mergeToState = (dataToMerge) => (state) => ({
  ...state,
  ...dataToMerge,
});
