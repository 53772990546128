import React from 'react';
import styled from 'styled-components';
import { UiSizes } from '@enums';
import { scroll } from '@styles';

const Menu = styled.div<{ minWidth: UiSizes }>`
  && {
    width: auto;
    min-width: ${(props) => {
      switch (props.minWidth) {
        case UiSizes.Small:
          return '120px !important';
        case UiSizes.Medium:
          return '180px !important';
        case UiSizes.Large:
          return '240px !important';
        default:
          return '';
      }
    }};
    
    ul {
      display: flex;
      flex-direction: column;
      max-height: 240px;
      overflow-y: auto;
        
      ${scroll};
    }
  }
`;

export const Paper = React.forwardRef((props, ref) => (
  <Menu {...props} ref={ref as React.RefObject<HTMLDivElement>} />
));
