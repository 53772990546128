import React from 'react';
import clsx from 'clsx';
import { Radio as MuiRadio, RadioProps } from '@material-ui/core';

import { useRadioStyles } from './styled';

export const Radio: React.FC<RadioProps> = (props) => {
  const classes = useRadioStyles();

  return (
    <MuiRadio
      className={classes.root}
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
};
