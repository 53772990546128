import React from 'react';
import { useFormikContext } from 'formik';
import { TitleField, TitleView } from './styled';
import { useInlineField } from '../useInlineField';

interface Props {
  isReadOnly?: boolean;
}

export const Title = ({ isReadOnly = false }: Props) => {
  const { isEdit, setIsEdit, setIsFocused, nodeRef } = useInlineField();

  const context = useFormikContext();

  if (isEdit) {
    return (
      <div ref={nodeRef}>
        <TitleField
          name="title"
          autoFocus
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            setIsFocused(false);
          }}
        />
      </div>
    );
  }

  return (
    <TitleView isReadOnly={isReadOnly} onClick={isReadOnly ? undefined : () => setIsEdit(true)}>
      {context.values.title}
    </TitleView>
  );
};
