import React from 'react';
import { Avatar, Icon, OptionItemWrapper, ValueSecondPart } from './styled';

interface IProps {
  name: string;
  avatarUrl?: string;
  onClick?: () => void;
  hideAvatar?: boolean;
}

export const OptionItem: React.FC<IProps> = (props) => {
  const { name, avatarUrl, onClick, hideAvatar } = props;

  let valueFirstPart;
  let valueSecondPart;

  if (name.includes(': ')) {
    [valueFirstPart, valueSecondPart] = name.split(': ');
  }

  const thumb = avatarUrl ? (
    <Avatar src={avatarUrl} alt={name} />
  ) : (
    <Icon size={20} />
  );

  return (
    <OptionItemWrapper className="cq-user-select-dropdown-item" onClick={onClick}>
      {hideAvatar ? null : thumb}
      {valueFirstPart && valueSecondPart ? (
        <>
          {valueFirstPart}
          {': '}
          <ValueSecondPart>{valueSecondPart}</ValueSecondPart>
        </>
      ) : (
        <>
          {name}
        </>
      )}
    </OptionItemWrapper>
  );
};
