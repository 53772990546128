import { PrivilegedTask } from '@generated/types/graphql';
import { AutomationFromApi, ProjectStage, RecordType } from '@types';

export enum EntityType {
  Client = RecordType.ACCOUNT,
  Project = RecordType.PROJECT,
  Request = RecordType.DEAL,
  WorkOrder = 'WORK_ORDER' // not used now
}

export enum EntityAutomationType {
  Stage = 'STAGE',
  WorkOrderTemplate = 'WORK_ORDER_TEMPLATE',
  Appointment = 'APPOINTMENT',
  Property = 'PROPERTY',
  Call = 'Call'
}

export type ProjectStageAutomation = {
  type: EntityAutomationType.Stage;
  automation: AutomationFromApi;
  from?: ProjectStage;
  to?: ProjectStage;
  stage?: ProjectStage;
};

export type WorkOrderTemplateAutomation = {
  type: EntityAutomationType.WorkOrderTemplate;
  automation: AutomationFromApi;
  workOrderTemplate: PrivilegedTask;
};

export type AppointmentAutomation = {
  type: EntityAutomationType.Appointment;
  automation: AutomationFromApi;
};

export type PropertyAutomation = {
  type: EntityAutomationType.Property;
  automation: AutomationFromApi;
};

export type CallAutomation = {
  type: EntityAutomationType.Call;
  automation: AutomationFromApi;
};

export type EntityAutomation =
  | ProjectStageAutomation
  | WorkOrderTemplateAutomation
  | AppointmentAutomation
  | PropertyAutomation
  | CallAutomation;
