import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const PortalIconWrapper = styled.div`
  right: -6px;
  top: -6px;
  position: absolute;
  z-index: 1;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #ffbd13;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  && {
    svg {
      opacity: 1;
    }
  }
`;
