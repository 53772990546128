import { device } from '@styles';
import styled from 'styled-components';

export const BodyWithActionButtons = styled.div`
  position: relative;
`;

export const ActionButtons = styled.div<{ isBodyWithError: boolean }>`
  position: absolute;
  z-index: 1;
  bottom: ${({ isBodyWithError }) => (isBodyWithError ? '26px' : '8px')};
  right: 12px;

  display: flex;
  align-items: center;
  gap: 8px;
`;

export const FormFields = styled.div<{ withPadding?: boolean }>`
  padding: ${({ withPadding }) => (withPadding ? '24px' : 0)};
`;

export const FooterActions = styled.div`
  background: #ffffff;
  padding: 24px;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;

  ${device.sm`
    min-width: 700px;
    `}
`;

export const OptionWithIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const FieldsRow = styled.div`
  ${device.sm`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 16px;

    > * {
      width: 100%;
      max-width: 268px;
    }
    `}
`;
