import { Tooltip, withStyles } from '@material-ui/core';

export const PaperTooltip = withStyles((theme) => ({
  // these are copy-pasted from Paper sources because we cannot use Paper classes to style Tooltip like Paper -
  // Tooltip's styles take priority over Paper's
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1]
  }
}))(Tooltip);
