import styled from 'styled-components';
import { colors, device, scroll } from '@styles';

export const HistoryContainer = styled.div`
  display: flex;
  overflow: auto;
  height: auto;
  overflow: hidden;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  overflow: auto;
  height: auto;
  overflow: hidden;
`;

export const Feed = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  width: 100%;
  height: 100%;
  overflow: auto;
  ${scroll};

  & > div:first-of-type > * {
    border-top: 0;
  }
`;

export const InfiniteLoaderNode = styled.p`
  display: block;
  height: 1px;
`;

export const Separator = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  background-color: #ffffff;
  padding: 10px 0;
  white-space: nowrap;

  &:before,
  &:after {
    content: '';
    width: 50px;
    border-bottom: 1px solid ${colors.gray3};
    margin: 0 50px;

    ${device.sm`
      width: 120px;
    `}
  }

  font-size: 14px;
  line-height: 1.25;
  color: ${colors.gray};
`;

export const Section = styled.div<{ isVisible: boolean }>`
  display: flex;
  flex-direction: column-reverse;
  position: relative;

  > span {
    top: 0;
    z-index: 10;
  }

  ${(props) =>
    props.isVisible
      ? `
    > span {
      position: sticky;
    }
  `
      : `
    > span {
      position: relative;
    }
  `};
`;

export const TabsAndFilters = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  gap: 8px;
  svg {
    flex-shrink: 0;
  }
`;

export const FilterIconWithArrow = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`;

export const FiltersContainer = styled.div`
  margin-top: 16px;
  padding: 0 16px;
`;

export const FiltersAndSearch = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
`;

export const InlineFormContainer = styled.div`
  margin-top: 16px;
  padding: 0 16px;
`;
