import { AnalyticsWidgetType } from '@features/Analytics/types';
import { WidgetData } from '../../Widget/types';
import { fetchRecordsBarLikeData } from './fetchRecordsBarLikeData';
import { WidgetSettingsError } from '../../WidgetSettingsError';
import { RecordsWidgetDataParams } from '../types';
import { fetchRecordsKpiData } from './fetchRecordsKpiData';
import { fetchRecordsFunnelData } from './fetchRecordsFunnelData';

export const fetchRecordsData = async (params: RecordsWidgetDataParams): Promise<WidgetData> => {
  switch (params.settings.widgetType) {
    case AnalyticsWidgetType.KPI: {
      const data = await fetchRecordsKpiData(params);

      return {
        ...data,
        type: params.settings.widgetType
      };
    }

    case AnalyticsWidgetType.BAR:
    case AnalyticsWidgetType.LINE:
    case AnalyticsWidgetType.PIE:
    case AnalyticsWidgetType.DOUGHNUT:
    case AnalyticsWidgetType.LEADERBOARD: {
      const data = await fetchRecordsBarLikeData(params);

      return {
        ...data,
        type: params.settings.widgetType
      };
    }

    case AnalyticsWidgetType.TIMELINE:
    case AnalyticsWidgetType.PIPELINE:
    case AnalyticsWidgetType.FUNNEL: {
      const data = await fetchRecordsFunnelData(params);

      return {
        ...data,
        type: params.settings.widgetType
      };
    }

    default:
      throw new WidgetSettingsError(`Unsupported widget type: ${params.settings.widgetType}`);
  }
};
