import React from 'react';
import { History } from '@features/ProjectPortfolio/Project/History';
import { RecordType } from '@types';
import { RightPanel as Container } from './styled';
import { CreateEventsPanel } from './CreateEventsPanel';

interface Props {
  projectId: number;
}

export const RightPanel = ({ projectId }: Props) => {
  return (
    <Container>
      <CreateEventsPanel recordId={projectId} recordType={RecordType.ACCOUNT} />
      <History context="client" recordId={projectId} />
    </Container>
  );
};
