import { colors } from './colors';
import { fonts } from './fonts';
import { typefaces } from './typeface';
import { medias, device } from './breakpoints';
import { borders, shadows } from './borders';

export const defaultTheme = {
  colors,
  fonts,
  typefaces,
  medias,
  devices: device,
  borders,
  shadows
};
