import React from 'react';
import { UserAvatar } from '@kit/components/UserAvatar';
import { InlineAvatar } from './styled';

export const AuthorAvatar = ({
  author
}: {
  author: { firstName?: string; lastName?: string; name?: string; id?: number; avatarUrl?: string };
}) => {
  if (!author) {
    return null;
  }

  return (
    <InlineAvatar>
      <UserAvatar user={author} />
    </InlineAvatar>
  );
};
