import { scroll } from '@styles';
import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1124px;
  width: 75vw;
  padding: 24px;
  background-color: #f7f7fc;
`;

export const EmailList = styled.div`
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 20px;
  ${scroll};

  &&& {
    > * {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;
