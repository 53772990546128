import React, { useMemo } from 'react';
import { CheckboxField, Form, FormValidationRules, RadioGroupField, useForm } from '@kit/components/Form';
import { useExportForm } from '@hooks/documents/forms/useExportForm';
import { RecordType } from '@types';
import { Container, Body, Footer, FieldLabel, PhotosPerRow, RadioLabel, SquareRadios, StepLabel } from './styled';
import { FormValues } from './types';
import { Button, ButtonVariant } from '@kit/ui/Button';

interface Props {
  formId: number;
  recordType?: RecordType;
  onClose: () => void;
}
export const PHOTOS_PER_ROW_OPTIONS = [1, 2, 4].map((value) => ({ value: value.toString(), label: value.toString() }));

export const Export = ({ formId, recordType = RecordType.PROJECT, onClose }: Props) => {
  const { mutateAsync: exportForm } = useExportForm();

  const postForm = async (values: FormValues) => {
    await exportForm({
      formId,
      dto: {
        exportType: values.exportType === 'formAsPdf' || values.exportImagesType === 'PDF' ? 'PDF' : 'ZIP',
        includeImageMetadata: values.includeImageMetadata,
        imagesInRow: values.includePhotos || values.exportType === 'onlyImages' ? +values.imagesInRow : 0,
        saveAsFile: values.saveToProjectDocuments,
        imagesOnly: values.exportType === 'onlyImages'
      }
    });

    onClose();
  };

  const { handleSubmit, form } = useForm<FormValues>({
    onSubmit: postForm,
    defaultValues: {
      exportType: null,
      includePhotos: true,
      includeImageMetadata: false,
      imagesInRow: '2',
      exportImagesType: 'ZIP'
    }
  });

  const {
    formState: { isSubmitting, isDirty },
    control,
    watch
  } = form;

  const exportType = watch('exportType');
  const includePhotos = watch('includePhotos');

  const rules = useMemo<FormValidationRules<FormValues>>(
    () => ({
      exportType: {
        isRequired: true
      }
    }),
    []
  );

  const docsLabel = `Save to ${recordType === RecordType.PROJECT ? 'Project' : 'Client'} Docs`;

  return (
    <Container>
      <Form rules={rules} onSubmit={handleSubmit}>
        <Body>
          <SquareRadios>
            <StepLabel>What would you like to export?</StepLabel>
            <RadioGroupField
              label=""
              name="exportType"
              control={control}
              renderOptionLabel={({ label, value, children }) => (
                <div>
                  <RadioLabel>{label}</RadioLabel>
                  {exportType === value ? <div>{children}</div> : null}
                </div>
              )}
              options={[
                {
                  value: 'formAsPdf',
                  label: 'All form fields (as PDF)',
                  children: (
                    <div>
                      <CheckboxField
                        label="Include photo fields"
                        control={control}
                        name="includePhotos"
                        clearOnUnmount={false}
                      />
                      {includePhotos && (
                        <>
                          <PhotosPerRow>
                            <FieldLabel>Photos per row</FieldLabel>
                            <RadioGroupField
                              label=""
                              options={PHOTOS_PER_ROW_OPTIONS}
                              control={control}
                              clearOnUnmount={false}
                              layout="row"
                              name="imagesInRow"
                            />
                          </PhotosPerRow>
                          <CheckboxField
                            label="Include metadata"
                            control={control}
                            name="includeImageMetadata"
                            clearOnUnmount={false}
                          />
                        </>
                      )}

                      <CheckboxField
                        label={docsLabel}
                        control={control}
                        name="saveToProjectDocuments"
                        clearOnUnmount={false}
                      />
                    </div>
                  )
                },
                {
                  value: 'onlyImages',
                  label: 'Only photo fields',
                  children: (
                    <div>
                      <PhotosPerRow>
                        <FieldLabel>Format</FieldLabel>
                        <RadioGroupField
                          label=""
                          options={[
                            {
                              value: 'ZIP',
                              label: 'as ZIP'
                            },
                            {
                              value: 'PDF',
                              label: 'as PDF'
                            }
                          ]}
                          control={control}
                          clearOnUnmount={false}
                          layout="row"
                          name="exportImagesType"
                        />
                      </PhotosPerRow>
                      <CheckboxField
                        label={docsLabel}
                        control={control}
                        name="saveToProjectDocuments"
                        clearOnUnmount={false}
                      />
                    </div>
                  )
                }
              ]}
            />
          </SquareRadios>
        </Body>
        <Footer>
          <Button variant={ButtonVariant.Secondary}  onClick={onClose}>
            Cancel
          </Button>

          <Button  variant={ButtonVariant.Primary} disabled={isSubmitting || !isDirty} type="submit">
            Export
          </Button>
        </Footer>
      </Form>
    </Container>
  );
};
