import { createAction } from 'redux-actions';
import projectApi from '../../../services/api/projectApi';
// TODO:
// fetchGeolocation stuff
export const fetchGoeLocationRequest = createAction(
  'FETCH_GOE_LOCATION_REQUEST'
);
export const fetchGoeLocationSuccess = createAction(
  'FETCH_GOE_LOCATION_SUCCESS'
);
export const fetchGoeLocationFailure = createAction('FETCH_GOE_LOCATION_ERROR');

export const fetchGeoLocation = (address: string) => async (dispatch) => {
    dispatch(fetchGoeLocationRequest());
    try {
      const { data } = await projectApi.getGoeLocation(address);
      if (data && data.features) {
        dispatch(fetchGoeLocationSuccess({ features: data.features }));
      } else {
        dispatch(fetchGoeLocationFailure(error));
      }
    } catch (error) {
      console.log(error);
      dispatch(fetchGoeLocationFailure(error));
    }
  };

export const getPromiseFetchGeoLocation = (address: string) => projectApi.getGoeLocation(address);

//
