import React from 'react';
import { useAppSelector } from '@hooks/index';
import { selectWorkspaceId } from '@state/selectors';
import { ZapIcon } from '@kit/ui/icons/Zap';
import { Badge } from '@kit/ui/Badge';
import { AutomationDescription, AutomationRow, AutomationTitle } from './styled';
import { EntityAutomation, EntityAutomationType } from './types';

interface Props {
  row: EntityAutomation;
}
export const Row = ({ row }: Props) => {
  const companyId = useAppSelector(selectWorkspaceId);

  if (row.type === EntityAutomationType.Stage) {
    const { automation, from, to, stage } = row;

    return (
      <AutomationRow isActive={automation.isActive} to={`/${companyId}/workspace/automations/${automation.id}`}>
        <AutomationTitle>
          {automation.isActive && <ZapIcon size="16px" fill="#F7A902" color="#F7A902" />}
          {!automation.isActive && <ZapIcon size="16px" fill="#737382" color="#737382" />}
          <span>{automation.name}</span>

          {!automation.isActive && (
            <Badge color="#828D9A" bgColor="white">
              Inactive
            </Badge>
          )}
        </AutomationTitle>

        {from && to && (
          <AutomationDescription>
            {from.name} → {to.name}
          </AutomationDescription>
        )}

        {stage && <AutomationDescription>{stage.name}</AutomationDescription>}
      </AutomationRow>
    );
  }

  if (row.type === EntityAutomationType.WorkOrderTemplate) {
    const { automation, workOrderTemplate } = row;

    return (
      <AutomationRow isActive={automation.isActive} to={`/${companyId}/workspace/automations/${automation.id}`}>
        <AutomationTitle>
          {automation.isActive && <ZapIcon size="16px" fill="#F7A902" color="#F7A902" />}
          {!automation.isActive && <ZapIcon size="16px" fill="#737382" color="#737382" />}

          <span>{automation.name}</span>

          {!automation.isActive && (
            <Badge color="#828D9A" bgColor="white">
              Inactive
            </Badge>
          )}
        </AutomationTitle>

        <AutomationDescription>{workOrderTemplate.title}</AutomationDescription>
      </AutomationRow>
    );
  }

  const { automation } = row;

  return (
    <AutomationRow isActive={automation.isActive} to={`/${companyId}/workspace/automations/${automation.id}`}>
      <AutomationTitle>
        {automation.isActive && <ZapIcon size="16px" fill="#F7A902" color="#F7A902" />}
        {!automation.isActive && <ZapIcon size="16px" fill="#737382" color="#737382" />}

        <span>{automation.name}</span>

        {!automation.isActive && (
          <Badge color="#828D9A" bgColor="white">
            Inactive
          </Badge>
        )}
      </AutomationTitle>
    </AutomationRow>
  );
};
