import { cloneDeep, isEmpty } from 'lodash';
import { LogicOp, Condition, FilterOperators, Property, PropertyType } from '@types';

export const getConditionNode = (op?: LogicOp, children?: Condition[] = []) => ({
  logicOp: op || LogicOp.AND,
  children
});

const mapOldOpToNew = (op: string): FilterOperators | '??' =>
  ({
    like: FilterOperators.Like,
    '=': FilterOperators.EqualTo,
    '!=': FilterOperators.NotEqualTo,
    in: FilterOperators.In,
    notIn: FilterOperators.NotIn,
    '<': FilterOperators.LessThan,
    '>': FilterOperators.GreaterThan,
    '>': FilterOperators.GreaterThan,
    '[=]': FilterOperators.Contains,
    '[!=]': FilterOperators.NotContains,
    '[!=]': FilterOperators.NotContains,
    '[===]': FilterOperators.ContainedBy,
    '[!===]': FilterOperators.NotContainedBy,
    '[like]': FilterOperators.ContainsLike
  })[op] || op;

export const isOldFilterable = (filter: Filter, properties: Property[]) =>
  filter &&
  !!filter.col &&
  !!filter.op &&
  (!!filter.val || properties!.find((p) => p.id === filter.col && p.type === PropertyType.Date && p.isAdditional));

const isOldFilters = (filters) => filters && !('logicOp' in filters) && !('operator' in filters);

export const convertSMFilters = ($filters, properties) => {
  if (!isOldFilters($filters) || isEmpty($filters)) {
    return $filters;
  }

  const filters = cloneDeep($filters);
  const root = getConditionNode(LogicOp.AND, []);

  const conditionsMap = Object.values(filters || {}).reduce((acc, $filter) => {
    const filter = $filter;
    const newOp = mapOldOpToNew(filter.op);
    filter.property = properties.find(({ id }) => id === filter.col);

    delete filter.col;

    filter.op = newOp;

    filter.value = filter.val;
    delete filter.val;

    // filter removed properties
    if (!filter.property) {
      return acc;
    }

    if (filter.or) {
      delete filter.or;

      acc.push([filter]);
    } else {
      if (acc.length === 0) {
        acc.push([]);
      }

      acc[acc.length - 1].push(filter);
    }

    return acc;
  }, []);

  if (conditionsMap.length > 1) {
    root.logicOp = LogicOp.OR;
    conditionsMap.forEach((nodes) => {
      if (nodes.length > 1) {
        root.children.push(getConditionNode(LogicOp.AND, nodes));
      } else {
        root.children.push(nodes[0]);
      }
    });
  } else {
    // eslint-disable-next-line prefer-destructuring
    root.children = conditionsMap[0];
  }

  return root;
};
