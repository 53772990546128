import { useMutation, useQueryClient } from 'react-query';
import { SendBulkSmsDto, SendSmsDTO } from '@types';
import projectApi from '@services/api/projectApi';
import { useToast } from '@hooks/useToast';
import { apiErrorHandler, invalidateQueriesBy, sleep } from '@utils';
import { ReactQueryKey } from '@enums';
import { SmsActivity } from '@generated/types/graphql';
import { selectWorkspaceId } from '@state/selectors';
import { useAppSelector } from './store';

export const useSmsMutations = () => {
  const { showSuccess, showError } = useToast();
  const queryClient = useQueryClient();
  const companyId = useAppSelector(selectWorkspaceId);

  const sendMutation = useMutation<SmsActivity, Error, { dto: SendSmsDTO; recordId: number; companyId: number }>(
    async ({ dto, recordId, companyId }) => {
      try {
        const { data } = await projectApi.sendSms(recordId, dto, companyId);
        await sleep(500);

        return data;
      } catch (e) {
        showError(e?.response?.data?.message || 'Error on sending sms');

        throw apiErrorHandler('Error on sending sms', e);
      }
    },
    {
      onSuccess: () => {
        showSuccess('Sms successfully sent');
        invalidateQueriesBy(queryClient, ReactQueryKey.ProjectActivity);
      }
    }
  );

  const sendBulkSms = useMutation<void, Error, SendBulkSmsDto>(
    async (dto) => {
      try {
        await projectApi.sendBulkSms({ dto, companyId });
      } catch (e) {
        showError(e?.response?.data?.message || 'Error on sending sms');

        throw apiErrorHandler('Error on sending sms', e);
      }
    },
    {
      onSuccess: () => {
        showSuccess('Sms successfully sent');
        invalidateQueriesBy(queryClient, ReactQueryKey.ProjectActivity);
      }
    }
  );

  return {
    sendMutation,
    sendBulkSms
  };
};
