import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { navigate } from 'gatsby';
import { useAppSelector } from '@hooks/store';
import { selectWorkspaceId } from '@state/selectors';
import { isNaN } from 'lodash';
import { useLocation, useMount } from 'react-use';
import { QueryParamsEnum, useQueryParam, useQueryParamMutation } from '@hooks/useQueryParam';
import { DrawerEntity, DrawerState } from './types';
import { Drawers } from './Drawers';

type DrawersContextType = {
  openDrawer: (entityType: DrawerEntity, entityId: number, listItemIds: number[], replace?: boolean) => void;
  closeDrawer: (index: number) => void;
};

const DrawersContext = createContext<DrawersContextType>(null);

export const DrawersProvider = ({ children }: { children: React.ReactNode }) => {
  const [drawerStates, setDrawerStates] = useState<DrawerState[]>([]);
  const companyId = useAppSelector(selectWorkspaceId);

  const location = useLocation();
  const { setParams } = useQueryParamMutation();
  const [feedCursor] = useQueryParam(QueryParamsEnum.FeedCursor);

  const updateDrawerStatesFromUrl = () => {
    const currentPath = window.location.pathname;
    const possibleDrawersPaths = Object.values(DrawerEntity).map((entity) => `/${entity}s`);
    const drawersPaths = currentPath.substring(
      Math.min(...possibleDrawersPaths.map((path) => currentPath.indexOf(path)).filter((index) => index !== -1))
    );
    const pathSegments = drawersPaths.split('/').filter(Boolean);
    const newDrawerStates = [];

    for (let i = 0; i < pathSegments.length; i += 2) {
      const entityType = pathSegments[i].slice(0, -1); // Remove trailing 's'
      const entityId = parseInt(pathSegments[i + 1], 10);

      const isSupportedEntity = Object.values(DrawerEntity).includes(entityType as DrawerEntity);

      if (!isSupportedEntity) {
        break;
      }

      if (!isNaN(entityId)) {
        const storageListKey = newDrawerStates
          .concat([{ type: entityType }])
          .map((state, index) => {
            if (index === newDrawerStates.length) {
              return `${state.type}s`;
            }

            return `${state.type}s/${state.currentId}`;
          })
          .join('/');

        const listItemIds = JSON.parse(sessionStorage.getItem(storageListKey)) ?? [];

        newDrawerStates.push({
          type: entityType as DrawerEntity,
          currentId: entityId,
          listItemIds
        });
      }
    }

    setDrawerStates(newDrawerStates);
  };

  useMount(() => updateDrawerStatesFromUrl());

  useEffect(() => {
    updateDrawerStatesFromUrl();
  }, [location]);

  const updateUrl = useCallback(
    (drawers: DrawerState[]) => {
      if (drawers.length === 0) {
        const HAS_LIST_PAGE_PATHS = [
          `/${companyId}/projects`,
          `/${companyId}/clients`,
          `/${companyId}/requests`,
          `/${companyId}/workspace/templates/actionItemTemplates`,
          `/${companyId}/systems`
        ];

        const resultPath = HAS_LIST_PAGE_PATHS.find((path) => window.location.pathname.startsWith(path));

        if (resultPath) {
          navigate(`${resultPath}${window.location.search}`);

          return;
        }
      }

      const drawersPath =
        drawers.length > 0 ? `/${drawers.map((state) => `${state.type}s/${state.currentId}`).join('/')}` : '';
      const currentPath = window.location.pathname;
      const possibleDrawersPaths = Object.values(DrawerEntity).map((entity) => `/${entity}s`);
      const minIndex = Math.min(
        ...possibleDrawersPaths.map((path) => currentPath.indexOf(path)).filter((index) => index !== -1)
      );

      if (Number.isFinite(minIndex)) {
        navigate(`${currentPath.substring(0, minIndex)}${drawersPath}${window.location.search}`);
      } else {
        navigate(`${currentPath}${drawersPath}${window.location.search}`);
      }
    },
    [companyId]
  );

  const openDrawer = useCallback(
    (entityType: DrawerEntity, entityId: number, listItemIds: number[], replace = false) => {
      if (feedCursor) {
        setParams(
          {
            [QueryParamsEnum.FeedCursor]: undefined,
            [QueryParamsEnum.FeedId]: undefined
          },
          true
        );
      }

      setDrawerStates((prevStates) => {
        const existingEntityTypeIndex = prevStates.findIndex((state) => state.type === entityType);

        let newState = [...prevStates];

        const newDrawerState = {
          type: entityType,
          currentId: entityId,
          listItemIds
        };

        if (existingEntityTypeIndex === -1) {
          if (replace) {
            newState = newState.slice(0, newState.length - 1);
          }

          newState = [...newState, newDrawerState];
        } else {
          newState = newState.slice(0, existingEntityTypeIndex);

          newState.push(newDrawerState);
        }

        updateUrl(newState);

        const storageListKey = newState
          .map((state, index) => {
            if (index === newState.length - 1) {
              return `${state.type}s`;
            }

            return `${state.type}s/${state.currentId}`;
          })
          .join('/');

        sessionStorage.setItem(storageListKey, JSON.stringify(listItemIds));

        return newState;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [feedCursor, updateUrl]
  );

  const closeDrawer = useCallback(() => {
    setDrawerStates((prevStates) => {
      const newState = prevStates.slice(0, prevStates.length - 1);

      updateUrl(newState);

      return newState;
    });
  }, [updateUrl]);

  const handleDrawerCurrentIdChange = async (newCurrentId: number, index: number) => {
    setDrawerStates((prevStates) => {
      const updatedStates = [...prevStates];
      updatedStates[index] = {
        ...updatedStates[index],
        currentId: newCurrentId
      };

      updateUrl(updatedStates);

      return updatedStates;
    });
  };

  const contextValue = useMemo(
    () => ({
      openDrawer,
      closeDrawer
    }),
    [openDrawer, closeDrawer]
  );

  return (
    <DrawersContext.Provider value={contextValue}>
      {children}

      <Drawers
        drawers={drawerStates}
        onUpdateDrawerCurrentId={handleDrawerCurrentIdChange}
        onDrawerClose={closeDrawer}
      />
    </DrawersContext.Provider>
  );
};

export const useDrawersContext = () => {
  const context = useContext(DrawersContext);

  if (!context) {
    console.warn('useDrawersContext must be used within a DrawersProvider');

    return {
      drawerStates: [],
      openDrawer: () => {},
      closeDrawer: () => {}
    };
  }

  return context;
};
