import { defaultTheme } from '@themes';
import { getColorWithOpacity } from '@styles';
import { ShapeType } from './types';

interface IconButtonProps {
  shapeType: ShapeType
  active?: boolean
}

export const iconButton = ({ shapeType, active }: IconButtonProps) => {
  return {
    // .MuiIconButton-root Styles applied to the root element.
    root: {
      borderRadius: shapeType === ShapeType.CIRCLE ? '50%' : '4px',
      padding: '10px',

      color: defaultTheme.colors.gray2,
      '&:hover, &:focus': {
        color: defaultTheme.colors.gray2,
      }
    },

    // .MuiIconButton-edgeStart Styles applied to the root element if edge="start".
    edgeStart: {},

    // .MuiIconButton-edgeEnd Styles applied to the root element if edge="end".
    edgeEnd: {},

    // .MuiIconButton-colorInherit Styles applied to the root element if color="inherit".
    colorInherit: {
      color: 'inherit',
      '&:hover, &:focus': {
        backgroundColor: 'inherit',
      }
    },

    // .MuiIconButton-colorPrimary Styles applied to the root element if color="primary".
    colorPrimary: {
      color: defaultTheme.colors.green,
      '&:hover, &:focus': {
        color: defaultTheme.colors.white,
        backgroundColor: defaultTheme.colors.green,
      }
    },

    // .MuiIconButton-colorSecondary Styles applied to the root element if color="secondary".
    colorSecondary: {
      color: defaultTheme.colors.white,
      backgroundColor: active ? getColorWithOpacity(defaultTheme.colors.white, 25) : defaultTheme.colors.black,
      '&:hover, &:focus': {
        color: defaultTheme.colors.white,
        backgroundColor: defaultTheme.colors.black,
      }
    },

    // .Mui-disabled Pseudo-class applied to the root element if disabled={true}.
    disabled: {},

    // .MuiIconButton-sizeSmall Styles applied to the root element if size="small".
    sizeSmall: {
      padding: '4px',
    },

    // .MuiIconButton-label Styles applied to the children container element.
    label: {
      fontSize: '14px',
      fontWeight: '600',
      textIndent: '3px',
      '& svg': {
        width: '20px',
        height: '20px'
      }
    }
  };
};
