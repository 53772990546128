import styled from 'styled-components';

export const EditableFieldContainer = styled.div<{ isReadOnly?: boolean }>`
  cursor: ${({ isReadOnly }) => (isReadOnly ? 'default' : 'pointer')};
  border: 1px solid transparent;
  padding: 8px 12px;
  border-radius: 4px;
  min-height: 40px;
  transition: border-color 0.15s ease-in-out;

  &:hover {
    border-color: ${({ isReadOnly }) => (isReadOnly ? 'transparent' : '#e8ecef')};
  }

  .ql-editor {
    padding-top: 0;
    padding-left: 0;
  }
`;
