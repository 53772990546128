import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  margin: auto;
  min-height: 0;
`;

export const TrendLine = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #828d9a;
  font-size: 12px;
  font-weight: 400;
`;

export const DiffValue = styled.div`
  color: #1d1d35;
  font-size: 18px;
  font-weight: 500;
`;

export const Value = styled.div`
  font-size: 48px;
  font-weight: 500;
`;

export const NoDataContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const NoDataText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  width: 100%;
`;
