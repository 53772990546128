import React from 'react';
import { AlertTriangle, RotateCw, X } from 'react-feather';
import { FormHelperText as FormHelperTextMui } from '@material-ui/core';
import { UiStates } from '@enums';
import { HelperText } from './styled';

interface FormHelperText {
  helperText?: string;
  helperState?: UiStates;
  disabled?: boolean;
}

export const FormHelperText: React.FC<FormHelperText> = (props) => {
  const {
    helperText,
    helperState,
    disabled,
  } = props;

  return (
    <FormHelperTextMui>
      {helperState !== UiStates.DEFAULT ? (
        <HelperText helperState={helperState} disabled={disabled}>
          {helperState === UiStates.LOADING ? <RotateCw /> : null}
          {helperState === UiStates.WARNING ? <AlertTriangle /> : null}
          {helperState === UiStates.ERROR ? <X /> : null}
          {helperText}
        </HelperText>
      ) : null}
    </FormHelperTextMui>
  );
};
