import React from 'react';
import { TableFooter as TableFooterMui, TableFooterProps } from '@material-ui/core';
import { useStylesTableFooter } from './styled';

export const TableFooter: React.FC<TableFooterProps> = (props) => {
  const classesTableFooter = useStylesTableFooter();

  return (
    <TableFooterMui
      classes={classesTableFooter}
      {...props}
    />
  );
};
