import { ANALYTICS_MODULE_RECORD_TYPE_MAP } from '@features/Analytics/constants';
import { AnalyticsModule, PredefinedWidgetFilterFieldId } from '@features/Analytics/types';
import { useCompanyProperties } from '@hooks/useCompanyProperties';
import { Property } from '@types';
import { isDefault } from '@utils/properties';
import { useMemo } from 'react';
import { FilterField, FilterFieldType } from './types';
import { WORK_ORDERS_FILTER_OPTIONS } from './constants';

const sortByNameFn = (a: Property, b: Property) => a.name.localeCompare(b.name);

const sortProperties = (properties: Property[]) => {
  const standard = properties.filter((p) => isDefault(p)).sort(sortByNameFn);
  const custom = properties.filter((p) => !p.virtual && p.isAdditional).sort(sortByNameFn);
  const virtual = properties.filter((p) => p.virtual).sort(sortByNameFn);

  return [...standard, ...custom, ...virtual];
};

export const useFilterOptions = (module: AnalyticsModule) => {
  const { scopeToColumns, allProperties } = useCompanyProperties();

  const options = useMemo<FilterField[]>(() => {
    if (module === AnalyticsModule.WORK_ORDERS) {
      return WORK_ORDERS_FILTER_OPTIONS.sort((a, b) => a.name.localeCompare(b.name));
    }

    if (allProperties.length === 0) {
      return [];
    }

    const properties = sortProperties(scopeToColumns[ANALYTICS_MODULE_RECORD_TYPE_MAP[module]] || []).map(
      (property) => ({
        id: property.id as number,
        name: property.name,
        type: FilterFieldType.PROPERTY,
        property
      })
    );

    if (module === AnalyticsModule.CLIENTS) {
      return properties;
    }

    const additionalProperties = [
      {
        id: PredefinedWidgetFilterFieldId.WORKFLOW,
        name: 'Workflow',
        type: FilterFieldType.WORKFLOW
      }
    ];

    if (module === AnalyticsModule.PROJECTS) {
      additionalProperties.push({
        id: PredefinedWidgetFilterFieldId.WORK_ORDER_WITH_TITLE,
        name: 'Work Order with title',
        type: FilterFieldType.WORK_ORDER_WITH_TITLE
      });
    }

    return additionalProperties.concat(properties);
  }, [module, scopeToColumns, allProperties]);

  return {
    options,
    isLoading: allProperties.length === 0
  };
};

export const getFilterOptionsGroupBy = (option: FilterField) => {
  if (option.type !== FilterFieldType.PROPERTY) {
    return undefined;
  }

  if (isDefault(option.property)) {
    return 'Standard Properties';
  }

  if (option.property.virtual) {
    return 'Virtual Properties';
  }

  return 'Custom Properties';
};
