import React, { useCallback, useState } from 'react';
import { Checkbox } from '@kit/ui/Checkbox';
import { ChevronUpIcon } from '@kit/ui/icons/ChevronUp';
import { ChevronDownIcon } from '@kit/ui/icons/ChevronDown';
import { Collapse } from '@material-ui/core';
import {
  GroupContainer,
  GroupHeaderContainer,
  GroupHeaderTitleCell,
  GroupHeaderTitleCellInner,
  TableRows,
  Th
} from './styled';

interface Props {
  renderGroupHeader: () => React.ReactNode;
  columns: { id: string; name: string; width: string }[];
  children: React.ReactNode;
  isAllSelected: boolean;
  onToggleAllSelected: (isChecked: boolean) => void;
  canBulkEdit: boolean;
}

export const Group = ({
  isAllSelected,
  onToggleAllSelected,
  renderGroupHeader,
  columns,
  children,
  canBulkEdit
}: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggle = useCallback(() => {
    setIsCollapsed((prev) => !prev);
  }, []);

  return (
    <GroupContainer>
      <GroupHeaderContainer>
        <thead>
          <tr>
            <th>
              <GroupHeaderTitleCell>
                <GroupHeaderTitleCellInner onClick={toggle}>
                  {canBulkEdit && <Checkbox isChecked={isAllSelected} onChange={onToggleAllSelected} />}

                  {renderGroupHeader()}
                  {isCollapsed ? (
                    <ChevronUpIcon size="24px" color="#9C9CAA" />
                  ) : (
                    <ChevronDownIcon size="24px" color="#9C9CAA" />
                  )}
                </GroupHeaderTitleCellInner>
              </GroupHeaderTitleCell>
            </th>
            {columns.map((column) => (
              <Th width={column.width} key={column.id} aria-label={column.name} />
            ))}
            <Th width="5%" />
          </tr>
        </thead>
      </GroupHeaderContainer>

      <Collapse in={!isCollapsed} timeout="auto">
        <TableRows>
          <tbody>{children}</tbody>
        </TableRows>
      </Collapse>
    </GroupContainer>
  );
};
