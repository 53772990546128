import React from 'react';
import { makeStyles, Slider as SliderMui } from '@material-ui/core';
import { SliderTypeMap } from '@material-ui/core/Slider/Slider';
import { sliderStyles } from './styled';

export type SliderProps = Omit<SliderTypeMap['props'], 'classes'>;

export const Slider = (props: SliderProps) => {
  const classesSlider = makeStyles(sliderStyles())();

  return <SliderMui classes={classesSlider} {...props} />;
};
