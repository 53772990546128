import { colors, scroll, device, deviceMax } from '@styles';
import styled, { css } from 'styled-components';

export const OverlayPanel = styled.div<{ isFullScreen: boolean }>`
  position: fixed;
  right: 0;
  top: 48px;
  bottom: 0;
  z-index: 1001;
  background: ${colors.white};
  width: 100%;
  box-shadow: -16px -16px 200px 0px #1d1d3552;
  ${deviceMax.sm`
     height: 100%;
  `};

  ${device.md`
    position: absolute;
    top: initial;
    width: ${({ isFullScreen }) => (isFullScreen ? '100%' : '80%')};
    height: calc(100vh - 48px);
  `};

  @media (min-width: 1500px) {
    width: 1050px;
    max-width: 90%;
  }
`;

export const Container = styled.div`
  position: relative;
  font-size: 14px;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #f7f7fc;

  form {
    height: 100%;
  }

  .PSPDFKit-Toolbar {
    background-color: black;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid ${colors.gray3};
`;

export const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const IconButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  color: ${colors.gray};

  &:hover {
    color: ${colors.black};
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Action = styled.div<{ isDisabled?: boolean; isRemove?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;

  color: ${colors.green};

  svg {
    flex-shrink: 0;
  }

  border: 1px solid ${colors.green};
  border-radius: 4px;
  cursor: pointer;
  padding: 0 8px;
  transition-property: color, background-color;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;

  &:hover {
    color: ${colors.white};
    background-color: ${colors.greenBg};
  }

  ${({ isRemove }) =>
    isRemove &&
    css`
      color: ${colors.red};
      border: 1px solid ${colors.red};

      &:hover {
        color: ${colors.white};
        background-color: ${colors.redBg};
      }
    `}

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      visibility: hidden;
    `}
`;

export const Right = styled.div<{ isCollapsed: boolean }>`
  flex: 0 0 336px;
  min-width: 336px;
  max-width: 336px;
  background-color: #f7f7fc;
  border-left: 1px solid #dfdfe8;
  overflow: auto;
  position: initial;
  max-width: 100%;
  border-bottom: 1px solid #dfdfe8;
  transition: margin-right 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

  ${scroll};

  .info {
    display: ${({ isCollapsed }) => (isCollapsed ? 'none' : 'block')};
  }

  ${device.md`
        max-width: 336px;
        position: relative;
        box-shadow: unset;
        border-bottom: unset
    `}

  ${(props) =>
    props.isCollapsed
      ? css`
          margin-right: calc(-336px + 39px);
          padding-left: 39px;
        `
      : ''};
`;

export const FullScreenRightHeader = styled.div`
  padding: 16px 16px 0 16px;
`;

export const FeedWrapper = styled.div`
  background-color: #fff;
  border-radius: 8px;
  margin: 16px;
  border: 1px solid #dfdfe8;
  padding-bottom: 16px;
`;

export const PSPDFKitContainer = styled.div`
  height: 100%;
  width: 100%;
`;

export const PSPDFKitTopToolbar = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 18px;

  .custom-page-switcher-input {
    width: 46px;

    input {
      height: 30px;
      text-align: center;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const PSPDFKitToogleSigebarButton = styled.div<{ isSidebarShown: boolean }>`
  background-color: ${(props) => (props.isSidebarShown ? colors.green : colors.white)};
  color: ${(props) => (props.isSidebarShown ? colors.white : colors.green)};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  border-radius: 4px;
`;

export const OuterButtons = styled.div`
  position: absolute;
  display: flex;
  top: 48px;
  width: 100%;

  > * {
    flex: 1;
  }

  .close {
    order: 2;
  }

  .prev {
    order: 1;
  }

  .next {
    order: 3;
  }

  ${device.md`
  left: -40px;
  top: 25px;
  flex-direction: column;
  width: auto;
  gap: 8px;
  > * {
    flex: auto;
  }

.close,
.next,
.prev {
    order: initial;
}
  `}
`;

const OuterButton = styled.div<{ isDisabled?: boolean }>`
  background-color: white;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};

  ${device.md`
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    `}
`;

export const CloseButton = styled(OuterButton)`
  color: #9c9caa;
  transition: color 0.15s ease-in-out;
  height: 40px;

  &:hover {
    color: #1d1d35;
  }

  ${device.md`
     width: 40px;
     margin-bottom: 8px;
    `}
`;

export const ArrowButton = styled(OuterButton)<{ isDisabled: boolean }>`
  height: 40px;
  color: #9c9caa;
  transition: color 0.15s ease-in-out;

  &:hover {
    color: #1d1d35;
  }

  ${device.md`
     width: 40px;
     height: 32px;
    `}
`;

export const PSPDFKitToolbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  background-color: #fff;
  border: 1px solid #dfdfe8;
  border-radius: 8px;
  margin-top: 16px;

  .prev,
  .next {
    border-radius: 50%;
    border: 1px solid ${colors.gray3};
  }

  ${ArrowButton} {
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }
`;

export const FormBuilderContainer = styled.div<{ isFullScreen: boolean }>`
  ${scroll};
  overflow: ${({ isFullScreen }) => (isFullScreen ? 'auto' : 'visible')};

  .FormViewport {
    height: 100%;
    overflow: hidden;
  }

  .FormField {
    max-width: unset;
    box-shadow: unset;
    margin: 32px auto;

    &:first-child {
      margin-top: 0;
    }

    :hover {
      box-shadow: unset;
      border: none;
      animation: unset;
      transition: unset;
    }
  }
`;

export const HeaderAction = styled.div<{ hideWhenMd?: boolean }>`
  align-items: center;
  display: flex;
  gap: 16px;
  justify-content: space-between;

  ${({ hideWhenMd }) =>
    hideWhenMd &&
    css`
      ${device.md`
      display: none;
    `}
    `}
`;

export const Content = styled.div`
  display: flex;
  height: 100%;
  overflow: hidden;

  @media (max-width: 767px) {
    flex-direction: column;
    height: auto;
  }
`;

export const Left = styled.div`
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 16px;
  ${scroll};
`;

export const FullScreenHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid ${colors.gray3};
`;

export const FullscreenHeaderRight = styled.div`
  flex: 0 0 296px;
  width: 296px;
  min-width: 296px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
`;

export const FullScreenHeaderLeft = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;

  > *:first-child {
    min-width: 200px;
  }

  ${PSPDFKitTopToolbar} {
    margin: 0;
    padding: 0;
  }
`;

export const FullScreenRightTitle = styled.div<{ isCollapsed: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  gap: 8px;
  margin-bottom: 16px;

  .title {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: auto;
    display: ${({ isCollapsed }) => (isCollapsed ? 'none' : 'block')};
  }
`;

export const DocView = styled.div<{ isFullScreen: boolean; withBottomToolbar: boolean }>`
  display: flex;
  height: ${({ withBottomToolbar }) => (withBottomToolbar ? 'calc(100% - 80px)' : '100%')};
  gap: ${({ isFullScreen }) => (isFullScreen ? '24px' : '16px')};
`;

export const DocContentWrapper = styled.div`
  flex: 1;
`;

export const Video = styled.video`
  width: 100%;
  height: 100%;
`;
