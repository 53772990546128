import React from 'react';

interface Props {
  size?: string | number;
  color?: string;
}

export const PinIcon = React.forwardRef<SVGSVGElement, Props>(
  ({ size = '16px', color = 'currentColor', ...props }, ref) => (
    <svg
      ref={ref}
      fill="none"
      width={size}
      height={size}
      {...props}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.19396 2.19526C9.45431 1.93491 9.87642 1.93491 10.1368 2.19526L13.8034 5.86193C14.0638 6.12228 14.0638 6.54439 13.8034 6.80474C13.5911 7.01705 13.2712 7.05622 13.0192 6.92227L10.9055 9.03596L9.95625 11.5674C9.92284 11.6565 9.87073 11.7374 9.80344 11.8047L8.80344 12.8047C8.54309 13.0651 8.12098 13.0651 7.86063 12.8047L5.9987 10.9428L3.4701 13.4714C3.20975 13.7318 2.78764 13.7318 2.52729 13.4714C2.26694 13.2111 2.26694 12.7889 2.52729 12.5286L5.05589 10L3.19396 8.13807C2.93361 7.87772 2.93361 7.45561 3.19396 7.19526L4.19396 6.19526C4.26125 6.12797 4.34217 6.07586 4.43128 6.04245L6.96274 5.09315L9.07643 2.97946C8.94248 2.72745 8.98165 2.40757 9.19396 2.19526ZM9.9987 3.94281L7.80344 6.13807C7.73614 6.20536 7.65522 6.25747 7.56611 6.29089L5.03466 7.24018L4.60817 7.66667L8.33203 11.3905L8.75852 10.964L9.70781 8.43258C9.74123 8.34348 9.79333 8.26256 9.86063 8.19526L12.0559 6L9.9987 3.94281Z"
        fill={color}
      />
    </svg>
  )
);
