import { useAppSelector } from '@hooks/store';
import { useUserRoleSettings } from '@hooks/useRoles';
import { selectCurrentUserId, selectWorkspaceId } from '@state/selectors';
import { hasAccess } from '@utils/roles';

export const useDocsAccess = () => {
  const companyId = useAppSelector(selectWorkspaceId);
  const userId = useAppSelector(selectCurrentUserId);

  const { data: access, isFetching } = useUserRoleSettings(companyId, userId);

  const canCreateForm = !isFetching && hasAccess(access, 'form', 'create');
  const canCreateFile = !isFetching && hasAccess(access, 'file', 'create');

  return {
    canCreateForm,
    canCreateFile
  };
};
