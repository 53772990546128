import { ReactQueryKey } from '@enums';
import { errorHandler } from '@services/api/helpers';
import { useMutation, useQueryClient } from 'react-query';
import { useToast } from '@hooks/useToast';
import { selectWorkspaceId } from '@state/selectors';
import filterApi from '@services/api/smartViewFilterApi';
import { useAppSelector } from '..';

export const useCreateSmartViewFolder = () => {
  const queryClient = useQueryClient();
  const { showSuccess } = useToast();

  const companyId = useAppSelector(selectWorkspaceId);

  return useMutation<number, Error, { name: string }>(
    async ({ name }) => {
      try {
        const { data } = await filterApi.createFolder({ name, companyId });

        return data.id;
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: () => {
        showSuccess('Hub created successfully');
        queryClient.invalidateQueries(ReactQueryKey.SmartViewFolderList);
      }
    }
  );
};
