import React, { useCallback } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { Input } from '@kit/ui/Input';
import { FormField } from '../FormField';
import { FormControl, FormInputPropsToOmit } from '../types';
import { useControllerWithValidation } from '../useControllerWithValidation';
import { useInlineEditableField } from './useInlineEditableField';

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends Omit<React.ComponentProps<typeof Input>, FormInputPropsToOmit>,
    FormControl<TFieldValues, TName> {
  renderView: (value: string, onClick: () => void) => React.ReactNode;
  onChanged?: () => void;
}

export const InlineEditableInputField = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  label,
  description,
  name,
  control,
  type = 'text',
  clearOnUnmount,
  renderView,
  onChanged,
  ...inputProps
}: Props<TFieldValues, TName>) => {
  const { isEditMode, onEnterEditMode, onExitEditMode } = useInlineEditableField(onChanged);

  const {
    field: { value, onChange, ...controlProps },
    fieldState: { error }
  } = useControllerWithValidation(name, control, label, clearOnUnmount);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (type === 'number') {
        const { value: newValue } = e.target;
        const parsedValue = parseFloat(newValue);

        // eslint-disable-next-line no-restricted-globals
        if (isNaN(parsedValue)) {
          onChange(null);

          return;
        }

        onChange(parsedValue);

        return;
      }

      onChange(e);
    },
    [onChange, type]
  );

  return (
    <FormField name={name} label={label} error={error?.message} description={description}>
      {!isEditMode && renderView(value as string, onEnterEditMode)}
      {isEditMode && (
        <Input
          {...controlProps}
          {...inputProps}
          value={value ?? ''}
          onChange={handleChange}
          type={type}
          data-test-id={`field-${controlProps.name}`}
          autoFocus
          onBlur={onExitEditMode}
        />
      )}
    </FormField>
  );
};
