import { colors } from '@styles';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const RemoveButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  cursor: pointer;
  color: #98a9bc;
  opacity: 0;
  transition: opacity 0.15s ease-in-out;

  &:hover {
    color: ${colors.green};
  }

  svg {
    flex-shrink: 0;
  }
`;

export const VisitRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    ${RemoveButton} {
      opacity: 1;
    }
  }
`;

export const VisitRowRight = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 4px;
`;

export const Name = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #828d9a;
  width: 60px;
  flex-shrink: 0;
`;

export const IsCompleted = styled.div`
  display: flex;
  padding-left: 12px;
  align-items: center;
  margin-left: auto;

  &&& {
    label {
      margin-left: 0;
      margin-right: 0;
    }

    .MuiTypography-body1 {
      padding-left: 8px;
      font-weight: 400;
    }

    .MuiSvgIcon-root {
      font-size: 20px;
    }
  }
`;
