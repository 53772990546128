import { lowerCase, upperFirst, find, omit } from 'lodash/fp';
import {
  SmsEventType,
  EmailEventType,
  ProjectEventType,
  TaskEventType,
  ReminderEventType,
  WorkflowEventType,
  EntityType,
  LineItemEventType
} from '@enums';
import { RESTRICTED_TYPES } from '@hooks/useActivity';

const mapEntityTypeToEventTypes = {
  [EntityType.SMS]: SmsEventType,
  [EntityType.EMAIL]: EmailEventType,
  [EntityType.PROJECT]: ProjectEventType,
  [EntityType.TASK]: ProjectEventType,
  [EntityType.WORKFLOW]: omit(WorkflowEventType.WORKFLOW_EXECUTED, WorkflowEventType),
  [EntityType.REMINDER]: ReminderEventType,
  [EntityType.LINE_ITEM]: LineItemEventType
};

const EXCLUDED = [
  ...RESTRICTED_TYPES,
  {
    entity: EntityType.TASK,
    event: TaskEventType.TASK_GROUP
  }
];

const normalizeName = (key: string, value: string) => {
  let result = `${key}_${value}`;

  if (key === EntityType.WORKFLOW) {
    result = `${key}_${value}`.replace(/(_?workflow)+/i, 'automation');
  }

  if (key === EntityType.LINE_ITEM) {
    result = 'contract_change_order';
  }

  if (value === ReminderEventType.DUE_DATE) {
    result = `${key}_overdue`;
  }

  return upperFirst(lowerCase(result));
};

export const getTypeOptions = () => {
  return Object.entries(mapEntityTypeToEventTypes).flatMap(([key, value]) => {
    return Object.values(value)
      .map(($value) => {
        const itemValue = {
          entity: key,
          event: $value
        };

        if (find(itemValue, EXCLUDED)) {
          return null;
        }

        return {
          id: `${key}_${$value}`,
          name: normalizeName(key, $value),
          value: itemValue
        };
      })
      .filter(Boolean);
  });
};
