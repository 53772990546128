import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 16px;
  margin: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  background: var(--neutral-100, #f7f7fc);
`;

export const InfoSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #828d9a;
  cursor: pointer;
`;

export const DataGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal-width columns */
  grid-gap: 20px; /* Gap between grid items */
`;

export const DataLabel = styled.div`
  color: #828d9a;
`;

export const LocationValue = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const DataBlock = styled.div`
  display: grid;
  grid-template-columns: 2fr 5fr;
  font-size: 12px;
  font-weight: 400;
  row-gap: 12px;
  gap: 16px;
`;

export const FSContainer = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  border: 1px solid #dfdfe8;

  ${DataGrid} {
    grid-template-columns: 1fr;
    row-gap: 12px;
    padding-top: 12px;
  }
`;

export const FSTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #98a9bc;

  cursor: pointer;
`;
