import styled from 'styled-components';
import { DragHandle2Icon } from '@kit/ui/icons/DragHandle2';
import { colors } from '@styles';

export const Container = styled.div`
  padding: 16px 24px;
  height: calc(100vh - 48px);
  overflow-y: auto;

  .grid-layout {
    position: relative;
  }

  .react-grid-placeholder {
    background-color: transparent;
    border: 2px dashed #dfdfe8;
    border-radius: 8px;
  }
`;

export const PlaceholderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  flex-direction: column;
  gap: 40px;

  img {
    max-width: 640px;
  }
`;

export const WidgetPlaceholderContainer = styled(PlaceholderContainer)`
  margin-top: 150px;
  height: auto;
  img {
    max-width: 188px;
  }
`;

export const PlaceholderTitle = styled.div`
  text-align: center;
  color: #1d1d35;

  font-size: 24px;
  font-weight: 500;
  margin-bottom: 16px;
`;

export const PlaceholderSubtitle = styled.div`
  color: #828d9a;
  font-size: 20px;
  max-width: 520px;
  font-weight: 500;
  text-align: center;
`;

export const Header = styled.div`
  padding-bottom: 24px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-bottom: 1px solid #dfdfe8;
`;

export const HeaderTopRow = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
  min-height: 32px;

  > *:first-child {
    margin-right: auto;
  }
`;

export const LastUpdated = styled.div`
  color: #828d9a;
  font-size: 12px;
  font-weight: 400;
`;

export const DragHandle = styled(DragHandle2Icon)`
  cursor: se-resize;
  position: absolute;
  bottom: 0;
  right: 0;
  color: ${colors.green};
`;

export const Description = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #828d9a;
`;

export const CurrentDashboardName = styled.div`
  font-size: 24px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const DashboardListItem = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 0 4px;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  width: 296px;
  cursor: pointer;
  transition: background-color 0.15s ease-in-out;

  &:hover {
    background-color: #f4f5f7;
  }

  > *:first-child {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  svg {
    flex-shrink: 0;
  }
`;

export const DashboardMenuSeparator = styled.div`
  height: 1px;
  background-color: #dfdfe8;
  margin: 12px 0;
`;
