import React, { useMemo } from 'react';
import { Users, Shield, Phone, Mail } from 'react-feather';
import { find, filter, property, uniqBy } from 'lodash';
import { Avatar as AvatarMui, AvatarProps as AvatarPropsMui } from '@material-ui/core';
import { bindHover, bindPopover } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import { AvatarGroup as AvatarGroupMui, AvatarGroupProps as AvatarGroupPropsMui } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { User } from '@types';
import { CopyButton } from '@common/ui';
import { getAvatarColors, getInitials, normalizeRoleName, getFullName } from '@utils';
import { UiSizes } from '@enums';
import { useTeams } from '@hooks/useTeams';
import { useAppSelector, useCompanyUsers } from '@hooks';
import { selectWorkspaceId } from '@state/selectors';
import {
  avatar,
  avatarGroup,
  Wrapper,
  Workspace,
  InfoPopup,
  InfoListItem,
  InfoListLink,
  InfoAvatarWrapper,
  InfoNameSpan,
  InfoIconWrapper,
  InfoListItemValue
} from './styled';

export interface IUser {
  profileImgUrl?: string;
  position?: number;
  id: string | number;
  username: string;
  companyId?: number;
  workspace?: {
    name: string;
    label?: string;
  };
}

type BaseAvatarType = {
  size?: UiSizes;
  disableInfoPopper?: boolean;
  popupNameOnly?: boolean;
};

type AvatarProps = AvatarPropsMui &
  BaseAvatarType & {
    user: IUser;
  };

type AvatarGroupProps = AvatarGroupPropsMui &
  BaseAvatarType & {
    users?: IUser[];
  };

export const Avatar: React.FC<AvatarProps> = (props) => {
  const { user, size = UiSizes.Tiny, disableInfoPopper, popupNameOnly, classes: classesFromProps, ...rest } = props;
  const { id, profileImgUrl, username, companyId: companyIdFromUser } = user;
  const companyIdFromRoute = useAppSelector(selectWorkspaceId);
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'user-info'
  });
  const companyId = companyIdFromUser ?? companyIdFromRoute;

  const {
    data: {
      data: { results: companyUsers }
    }
  } = useCompanyUsers(companyId, popupState.isOpen);
  const { data: teams } = useTeams(companyId);

  const avatarColor = getAvatarColors(username);

  let capitalizeUsername = '';
  username.split(' ').forEach((name) => {
    capitalizeUsername += name !== '' ? name[0].toUpperCase() : '';
  });

  const classesAvatar = makeStyles(avatar({ size, color: avatarColor.bg, textColor: avatarColor.text }))();

  const renderInner = (props = {}) => (
    <Wrapper {...props}>
      <AvatarMui classes={classesFromProps ?? classesAvatar} src={profileImgUrl} variant="rounded" {...rest}>
        <span>{username ? capitalizeUsername : id}</span>
      </AvatarMui>
      {user.workspace && (
        <Workspace colors={getAvatarColors(user.workspace.label || user.workspace.name)}>
          {user.workspace.label || getInitials(user.workspace.name)}
        </Workspace>
      )}
    </Wrapper>
  );

  const extendedUser = find(companyUsers, { id: user.id as any });
  const userTeams = extendedUser && filter(teams, ({ teamUsers }) => find(teamUsers, { id: extendedUser.id }));
  const userRoleName =
    extendedUser && normalizeRoleName(find(extendedUser.companies, { id: companyId })?.roles?.[0]?.name);
  const userTeamsString = userTeams && userTeams.length ? userTeams.map(property('name')).join(', ') : null;

  return (
    <>
      {renderInner(bindHover(popupState))}
      {disableInfoPopper ? null : (
        <HoverPopover
          {...bindPopover(popupState)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'start'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'start'
          }}
        >
          <InfoPopup>
            <InfoAvatarWrapper nameOnly={popupNameOnly}>
              {renderInner()}
              <InfoNameSpan>{username}</InfoNameSpan>
            </InfoAvatarWrapper>
            {extendedUser && !popupNameOnly ? (
              <>
                {extendedUser.email ? (
                  <InfoListItem>
                    <InfoIconWrapper>
                      <Mail />
                    </InfoIconWrapper>
                    <InfoListLink href={`mailto:${extendedUser.email}`}>{extendedUser.email}</InfoListLink>
                    <CopyButton value={extendedUser.email} size={14} />
                  </InfoListItem>
                ) : null}
                {extendedUser.phone ? (
                  <InfoListItem>
                    <InfoIconWrapper>
                      <Phone />
                    </InfoIconWrapper>
                    <InfoListLink href={`tel:${extendedUser.phone}`}>{extendedUser.phone}</InfoListLink>
                    <CopyButton value={extendedUser.phone} size={14} />
                  </InfoListItem>
                ) : null}
                {userRoleName ? (
                  <InfoListItem>
                    <InfoIconWrapper>
                      <Shield />
                    </InfoIconWrapper>
                    <InfoListItemValue>{userRoleName}</InfoListItemValue>
                  </InfoListItem>
                ) : null}
                {userTeamsString ? (
                  <InfoListItem>
                    <InfoIconWrapper>
                      <Users />
                    </InfoIconWrapper>
                    <InfoListItemValue>{userTeamsString}</InfoListItemValue>
                  </InfoListItem>
                ) : null}
              </>
            ) : null}
          </InfoPopup>
        </HoverPopover>
      )}
    </>
  );
};

export const AvatarGroup: React.FC<AvatarGroupProps> = (props) => {
  const { users, max = 5, size = UiSizes.Tiny, ...rest } = props;

  const makeStylesParam = useMemo(() => avatarGroup({ size }), [size]);

  const classesAvatarGroup = makeStyles(makeStylesParam)();

  const avatars: React.ReactElement[] = [];
  if (!users || !Object.keys(users).length) {
    return <AvatarGroupMui key="avatargroup" />;
  }

  Object.keys(uniqBy(users, 'id')).forEach((key) => {
    if (!key) {
      return;
    }
    const user = users[key as keyof typeof users] as unknown as User;
    user.id = user.id ?? (key as unknown as User['id']);
    avatars.push(<Avatar key={getFullName(user) + user.id} user={user} size={size} {...rest} />);
  });

  return avatars.length ? (
    <AvatarGroupMui max={max} classes={classesAvatarGroup} {...rest}>
      {avatars}
    </AvatarGroupMui>
  ) : null;
};
