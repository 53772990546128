import { useCallback } from 'react';
import { createGlobalState } from 'react-use';
import { useBlueprints, useRoutes, useQueryParam, QueryParamsEnum } from '@hooks';
import { RecordType, Search, BlueprintDTO } from '@types';
import { SEARCH_DEFAULT } from '@components/Project/redux/reducer/defaultValues';
import { AccountIsActiveStatus, DealStatus, ProjectStatus } from '@components/Project/ProjectView/types';

const useGlobalSearch = createGlobalState<Search>(SEARCH_DEFAULT);
export const getDefaultSearch = (recordType: RecordType, blueprints: BlueprintDTO[]) => {
  switch (recordType) {
    case RecordType.ACCOUNT:
      return {
        ...SEARCH_DEFAULT,
        type: AccountIsActiveStatus.active
      };

    case RecordType.PROJECT:
      return {
        ...SEARCH_DEFAULT,
        type: ProjectStatus.active
      };

    case RecordType.DEAL: {
      const dealBlueprint = blueprints.filter((blueprint) => blueprint.type === RecordType.DEAL)[0];

      return {
        ...SEARCH_DEFAULT,
        type: DealStatus.active,
        groupBy: dealBlueprint ? `blueprint_${dealBlueprint.id}` : 0
      };
    }

    default:
      throw new Error(`Unrecognized record type ${recordType}`);
  }
};

export const useSearch = () => {
  const [search, setSearch] = useGlobalSearch();

  const { companyId } = useRoutes();
  const [, setQueryParamSmartViewId] = useQueryParam(QueryParamsEnum.SmartViewId);

  const {
    fetchAll: { data: blueprints }
  } = useBlueprints();

  const handleFiltersChange = useCallback(
    ($search: Partial<Search>) => {
      const newSearch = {
        ...search,
        companyId,
        ...$search
      };

      setSearch(newSearch);

      if (!newSearch.selectedView) {
        setQueryParamSmartViewId(undefined);
      }
    },
    [companyId, search, setQueryParamSmartViewId, setSearch]
  );

  const defaultSearch = useCallback(
    (type: RecordType) => getDefaultSearch(type, blueprints?.results ?? []),
    [blueprints?.results]
  );

  return {
    search,
    handleFiltersChange,
    getDefaultSearch: defaultSearch
  };
};
