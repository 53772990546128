import React, { ReactNode, useCallback, useMemo } from 'react';
import { useField } from 'formik';
import { Select as MuiSelectFormik, SelectProps as MuiSelectPropsFormik } from 'formik-material-ui';
import { Select as MuiSelect, PaperProps, makeStyles, SelectProps as SelectPropsMui } from '@material-ui/core';
import { ChevronDown } from 'react-feather';
import { noop, castArray } from 'lodash';
import { UiSizes } from '@enums';
import { Identified, Disableable } from '@types';
import { colors, selectMui } from '@styles';
import { capitalizeFirstLetter } from '@utils';
import { InputLabel } from '@common/ui';
import { FieldErrorFormik } from '@common/ui/FieldError';
import { Paper } from '../Paper';
import {
  MenuItem,
  Tooltip,
  TooltipProperty,
  Helper,
  Dot,
  Field,
  RenderValue,
  Checkbox,
  useStylesCheckbox,
  Text,
  ListSubheader
} from './styled';

export type BaseOption = {
  name: string;
  description?: string;
  icon?: {
    component: React.ReactNode;
  };
} & Partial<Identified> &
  Disableable;

export type SelectStandardOption = { id: number; name: string };

export type SelectProps<T = SelectStandardOption, K = number> = {
  fieldValue?: any;
  setValue?: (value: any) => unknown;
  options: T[];
  name?: string;
  renderedError?: React.ReactNode;
  renderValue?: (value: K, options: T[]) => string | ReactNode;
  renderOption?: (value: K) => ReactNode;
  optionTitleKey?: string;
  optionIdKey?: string;
  dot?: boolean;
  placeholder?: string;
  classes?: Object;
  PaperComponent?: React.FC<PaperProps>;
  withCheckboxes?: boolean;
  paperMinWidth?: UiSizes;
  paperPosition?: 'right' | 'center' | 'left';
  size?: UiSizes;
  isFormik?: boolean;
  isOpen?: boolean;
  withIcon?: boolean;
  addComponent?: any;
  tooltip?: string;
  grouping?: (id: K, option: T) => string | null;
  noBorder?: boolean;
} & Partial<Omit<MuiSelectPropsFormik, 'renderValue'>>;

export const defaultSelectMenuProps: SelectPropsMui['MenuProps'] = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right'
  },
  PaperProps: {
    component: Paper
  }
};

function defaultRenderValue<T extends string | number>(
  value: T | T[],
  options: (Identified & { name: string })[],
  optionTitleKey: string,
  optionIdKey: string,
  multiple = false
) {
  if (multiple) {
    const res = (value as T[])
      .reduce((acc, id) => [...acc, options.find((item) => item.id === id)?.name ?? ''], [] as string[])
      .join(', ');

    return <RenderValue>{res}</RenderValue>;
  }

  const result = options.find((item) => item[optionIdKey] === value);

  return <RenderValue>{(result?.[optionTitleKey] || value) ?? 'Removed or missing option'}</RenderValue>;
}

export function Select<T extends BaseOption, K = string | number>(props: SelectProps<T, K>) {
  const {
    options,
    name,
    onChange,
    onClose,
    fieldValue,
    setValue = noop,
    label,
    disabled,
    renderedError,
    renderValue = defaultRenderValue,
    renderOption,
    optionTitleKey = 'name',
    optionIdKey = 'id',
    multiple = false,
    defaultValue = multiple ? [] : '',
    classes,
    PaperComponent = Paper,
    dot = false,
    withIcon = false,
    placeholder = '',
    withCheckboxes = false,
    paperMinWidth = UiSizes.Medium,
    paperPosition = 'right',
    isFormik,
    isOpen,
    size = UiSizes.Medium,
    addComponent,
    tooltip,
    grouping,
    required,
    noBorder = false
  } = props;

  const Component = isFormik ? Field : MuiSelect;
  const componentProps = isFormik
    ? {
        component: MuiSelectFormik
      }
    : {};

  const classesCheckbox = useStylesCheckbox();
  const classesSelect = makeStyles(selectMui({ size, noBorder }))();

  const handleRenderValue = useCallback(
    (value) => {
      return renderValue(value, options, optionTitleKey, optionIdKey, multiple);
    },
    [renderValue, options, optionTitleKey, optionIdKey, multiple]
  );

  const handleOnChange = useCallback(
    (event, elem) => {
      const {
        target: { value: $value }
      } = event;
      const value = Array.isArray($value) ? $value.filter(Boolean) : $value;

      setValue(value);

      onChange?.(event, elem);
    },
    [setValue, onChange]
  );

  const menuProps = useMemo(
    () => ({
      getContentAnchorEl: null,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: paperPosition
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: paperPosition
      },
      PaperProps: {
        component: PaperComponent,
        minWidth: paperMinWidth
      }
    }),
    [PaperComponent, paperMinWidth, paperPosition]
  );

  const isChecked = (value: T) =>
    !!castArray(fieldValue).find((item: any) => item === value[optionTitleKey] || item === value[optionIdKey]);

  return (
    <>
      {label && (
        <InputLabel htmlFor={name} required={required} tooltip={tooltip}>
          {label}
          {renderedError}
        </InputLabel>
      )}
      <Component
        open={isOpen}
        renderValue={handleRenderValue}
        classes={classes || classesSelect}
        name={name}
        variant="outlined"
        MenuProps={menuProps}
        IconComponent={ChevronDown}
        onChange={handleOnChange}
        onClose={onClose}
        disabled={disabled}
        defaultValue={defaultValue}
        multiple={multiple}
        placeholder={placeholder}
        selected={fieldValue}
        value={multiple ? castArray(fieldValue) : fieldValue ?? null}
        error={!!renderedError}
        {...componentProps}
      >
        {options.map((option) => {
          const optionId = option[optionIdKey];
          const groupLabel = grouping && grouping(optionId, option);

          return [
            groupLabel && <ListSubheader key={groupLabel}>{groupLabel}</ListSubheader>,

            <MenuItem value={optionId} key={`${optionId}_${isChecked(option)}`} disabled={option.disabled}>
              {dot && <Dot color={option.color} />}

              {withIcon && option.icon?.component}

              {renderOption ? renderOption(optionId) : <Text>{option[optionTitleKey]}</Text>}

              {option.description && (
                <Tooltip
                  title={<TooltipProperty>{capitalizeFirstLetter(option.description)}</TooltipProperty>}
                  placement="top"
                >
                  <Helper size={16} />
                </Tooltip>
              )}
              {withCheckboxes && (
                <Checkbox classes={classesCheckbox} style={{ color: colors.gray }} checked={isChecked(option)} />
              )}
            </MenuItem>
          ];
        })}
        {addComponent || null}
      </Component>
    </>
  );
}

export function SelectFormik<T extends BaseOption, K = string | number>(props: SelectProps<T, K>) {
  const [{ value: fieldValue = '', name }, , { setValue }] = useField(props);

  const renderedError = FieldErrorFormik({ name });

  return <Select<T, K> fieldValue={fieldValue} setValue={setValue} isFormik renderedError={renderedError} {...props} />;
}
