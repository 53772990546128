import * as PusherPushNotifications from '@pusher/push-notifications-web';
import { config } from '../config';

export const subscribeForNotification = (
  accessToken: number,
  userId: number
) => {
  try {
    if (
      'Notification' in window &&
      (Notification.permission === 'default' ||
        Notification.permission === 'granted')
    ) {
      // token service for authenticating user in pusher beams
      const tokenProvider = new PusherPushNotifications.TokenProvider({
        url: `${config.notificationServiceUrl}/notifications/beams-auth`,
        headers: { Authorization: `Bearer ${accessToken}` }
      });

      //initiating client
      const beamsClient = new PusherPushNotifications.Client({
        instanceId: config.beamClientKey
      });

      //start the client and start listening to it
      const USER_PREFIX = 'USER-';

      const pushUserId = `${USER_PREFIX}${userId}`;
      beamsClient
        .start()
        .then(() => beamsClient.setUserId(pushUserId, tokenProvider))
        .then(() => console.log('User ID has been set'))
        .catch((e) => {
          if (e.message && e.message.includes('HTTPS')) return;
          console.error('Could not authenticate with Beams:');
        });
    }
  } catch (err) {
    console.log('couldnt start pusher beam', err);
  }
};
