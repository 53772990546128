export enum NotificationEntityType {
  Request = 'Request',
  Client = 'Client',
  Project = 'Project',
  WorkOrder = 'WorkOrder',
  File = 'File',
  Reminder = 'Reminder',
  Geolocation = 'Geolocation',
  Action = 'Action',
  System = 'System'
}

export type NotficationEntity = {
  id: number;
  uid?: number;
  title: string;
  type: NotificationEntityType;
};
