import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Clipboard, Edit2, FileText, Plus } from 'react-feather';
import { Financial } from '@components/Project/Financial';
import { useAppSelector } from '@hooks/store';
import { isCustomerPortalEnabled, selectCurrentUserId, selectWorkspaceId } from '@state/selectors';
import { useUserRoleSettings } from '@hooks/useRoles';
import { hasAccess } from '@utils/roles';
import { ChevronRightIcon } from '@kit/ui/icons/ChevronRight';
import { InfoIcon } from '@kit/ui/icons/Info';
import { DollarIcon } from '@kit/ui/icons/Dollar';
import { ContextMenu, MenuItem } from '@kit/components/ContextMenu';
import { useTaskDrawer } from '@components/Scheduler/useTaskDrawer';
import { useRecordDetail } from '@hooks/useRecordDetail';

import { Accounting } from '@features/ClientPortfolio/Client/Overview/Accounting';
import { Docs as DocsWidget } from '@features/ClientPortfolio/Client/Overview/Docs';
import { Stakeholders } from '@features/ClientPortfolio/Client/Overview/Stakeholders';
import { Reminders } from '@features/ClientPortfolio/Client/Overview/Reminders';
import { RecordType } from '@types';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { GroupedDocList, useCreateDocMenuItems } from '@features/DocumentManagement/DocList';
import { FileUpIcon } from '@kit/ui/icons/FileUp';
import { useModal } from '@common/PromiseModal';
import { DrawerEntity, useDrawersContext } from '@contexts/DrawersContext';
import { stopPropagationClick } from '@utils';
import {
  Container,
  CreateWork,
  Details,
  DetailsContent,
  DetailsHeader,
  DetailsTitle,
  EditIconButton,
  Widget,
  WidgetDetailsButton,
  WidgetHeader,
  WidgetIcon,
  WidgetTitle
} from './styled';
import { Financials as FinancialsWidget } from './Financials';
import { FullWork, Work as WorkWidget } from './Work';

import { Properties as PropertiesWidget } from './Properties';

import { OverviewSkeleton } from './Skeleton';
import { EditProperties } from './Properties/FullProperties/EditProperties';
import { CreateActionItemForm } from './Work/CreateActionItemForm';

interface Props {
  projectId: number;
}

type TDetails = 'financials' | 'docs' | 'properties' | 'work';

export const Overview = ({ projectId }: Props) => {
  const { data: record, isLoading } = useRecordDetail(projectId, { refetchOnMount: false });

  const [expandedDetails, setExpandedDetails] = useState<TDetails | null>(null);
  const companyId = useAppSelector(selectWorkspaceId);
  const userId = useAppSelector(selectCurrentUserId);
  const { data: access, isFetching } = useUserRoleSettings(companyId, userId);
  const canViewFinances = hasAccess(access, 'contract', 'view', 'contract');
  const canViewWork = hasAccess(access, 'task', 'view');
  const canViewDocs = hasAccess(access, 'file', 'view') || hasAccess(access, 'form', 'view');
  const canCreateForm = hasAccess(access, 'form', 'create');
  const canCreateFile = hasAccess(access, 'file', 'create');

  const { openModal } = useModal();

  const { openDrawer } = useDrawersContext();

  const isPortalEnabled = useAppSelector(isCustomerPortalEnabled);

  const { onAddClick } = useTaskDrawer();

  const handleCreateWorkOrderClick = useCallback(() => {
    onAddClick({
      project: record
    });
  }, [onAddClick, record]);

  const createFileMenuItems = useCreateDocMenuItems(projectId);

  const createWorkMenuItems = useMemo<MenuItem[]>(() => {
    return [
      {
        title: 'Work Order',
        icon: <Clipboard size="16px" color="#9C9CAA" />,
        onClick: () => {
          handleCreateWorkOrderClick();
        }
      },
      {
        title: 'Action Item',
        icon: <FileUpIcon size="16px" color="#9C9CAA" />,
        onClick: async () => {
          const result = await openModal<number | void>(
            ({ onClose }) => <CreateActionItemForm initialRecord={record} onClose={onClose} />,
            { title: 'Create Action Item' }
          );

          if (result) {
            openDrawer(DrawerEntity.ACTION_ITEM, result, []);
          }
        }
      }
    ];
  }, [handleCreateWorkOrderClick, openModal, record, openDrawer]);

  const expandDetails = useCallback(
    (id: TDetails) => () => {
      if (id === 'properties' && (isLoading || record?.id !== projectId)) {
        return;
      }
      setExpandedDetails(id);
    },
    [isLoading, record, projectId]
  );

  useEffect(() => {
    setExpandedDetails(null);
  }, [projectId]);

  const details = useMemo(() => {
    if (!expandedDetails) {
      return null;
    }

    switch (expandedDetails) {
      case 'financials':
        return {
          title: 'Financials',
          content: <Financial recordId={projectId} />
        };
      case 'work':
        return {
          title: 'Work',
          content: <FullWork recordId={projectId} />
        };
      case 'docs':
        return {
          title: 'Documents',
          content: <GroupedDocList recordId={projectId} recordType={RecordType.PROJECT} />
        };
      case 'properties':
        return {
          title: 'Properties'
        };
      default:
        return null;
    }
  }, [expandedDetails, projectId]);

  if (expandedDetails && details) {
    if (expandedDetails === 'properties') {
      return (
        <EditProperties
          projectId={projectId}
          recordType={RecordType.PROJECT}
          onClose={() => setExpandedDetails(null)}
        />
      );
    }

    return (
      <Details>
        <DetailsHeader>
          <DetailsTitle>{details.title}</DetailsTitle>
          <Button variant={ButtonVariant.Secondary} onClick={() => setExpandedDetails(null)}>
            Close
          </Button>
        </DetailsHeader>
        <DetailsContent>{details.content}</DetailsContent>
      </Details>
    );
  }

  if (isFetching) {
    return <OverviewSkeleton />;
  }

  return (
    <Container>
      <Widget size="large" isFullWidth>
        <WidgetHeader>
          <WidgetTitle onClick={expandDetails('properties')}>
            <WidgetIcon backgroundColor="#DFDFE8">
              <InfoIcon size="16px" color="#737382" />
            </WidgetIcon>
            Properties
          </WidgetTitle>
          <EditIconButton onClick={expandDetails('properties')}>
            <Edit2 size="16px" />
          </EditIconButton>
        </WidgetHeader>

        <PropertiesWidget projectId={projectId} />
      </Widget>

      {canViewWork && <Reminders projectId={projectId} />}

      {canViewWork && (
        <Widget isFullWidth size="large">
          <WidgetHeader>
            <WidgetTitle onClick={expandDetails('work')}>
              <WidgetIcon backgroundColor="#FCF2DB">
                <Clipboard size="16px" color="#F1AA12" />
              </WidgetIcon>
              Work
              {!isPortalEnabled && hasAccess(access, 'task', 'create') && (
                <CreateWork {...stopPropagationClick}>
                  <Button onClick={handleCreateWorkOrderClick} variant={ButtonVariant.Secondary}>
                    <Plus size="16px" />
                    Work Order
                  </Button>
                </CreateWork>
              )}
              {isPortalEnabled && hasAccess(access, 'task', 'create') && (
                <CreateWork {...stopPropagationClick}>
                  <ContextMenu items={createWorkMenuItems}>
                    <Button variant={ButtonVariant.Secondary}>
                      <Plus size="16px" />
                      Work
                    </Button>
                  </ContextMenu>
                </CreateWork>
              )}
            </WidgetTitle>
            <WidgetDetailsButton onClick={expandDetails('work')}>
              <ChevronRightIcon size={24} />
            </WidgetDetailsButton>
          </WidgetHeader>

          <WorkWidget projectId={projectId} onExpandDetails={expandDetails('work')} />
        </Widget>
      )}

      {canViewDocs && (
        <Widget isFullWidth>
          <WidgetHeader>
            <WidgetTitle onClick={expandDetails('docs')}>
              <WidgetIcon backgroundColor="#DBE5FF">
                <FileText size="16px" color="#235DFF" />
              </WidgetIcon>
              Docs
              {(canCreateFile || canCreateForm) && (
                <CreateWork {...stopPropagationClick}>
                  <ContextMenu position="left" items={createFileMenuItems}>
                    <Button variant={ButtonVariant.Secondary}>
                      <Plus size="16px" />
                      Doc
                    </Button>
                  </ContextMenu>
                </CreateWork>
              )}
            </WidgetTitle>
            <WidgetDetailsButton onClick={expandDetails('docs')}>
              <ChevronRightIcon size={24} />
            </WidgetDetailsButton>
          </WidgetHeader>

          <DocsWidget projectId={projectId} />
        </Widget>
      )}

      <Stakeholders title="Stakeholders" addButtonText="Stakeholder" isFullWidth projectId={projectId} />

      {canViewFinances && (
        <Widget isFullWidth>
          <WidgetHeader>
            <WidgetTitle onClick={expandDetails('financials')}>
              <WidgetIcon backgroundColor="#CDF3DF">
                <DollarIcon size="16px" color="#009A47" />
              </WidgetIcon>
              Financials
            </WidgetTitle>
            <WidgetDetailsButton onClick={expandDetails('financials')}>
              <ChevronRightIcon size={24} />
            </WidgetDetailsButton>
          </WidgetHeader>

          <FinancialsWidget projectId={projectId} />
        </Widget>
      )}

      {canViewFinances && <Accounting recordId={projectId} />}
    </Container>
  );
};
