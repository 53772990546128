import {
  PrivilegedTaskFilter,
  PrivilegedTasksOrderBy,
  ProjectRequestStatus,
  ProjectStatus,
  RemindersOrderBy,
  TaskStatus
} from '@generated/types/graphql';
import { AccountStatus, RecordType } from '@types';
import { createGlobalState } from 'react-use';
import { DeepPartial } from 'redux';

export type ClientFilterState = {
  clientStatuses: AccountStatus[];
  projectStatuses: ProjectStatus[];
  requestStatuses: ProjectRequestStatus[];
  assignees: number[];
  templates: number[];
  statuses: TaskStatus[];
  labels: number[];
  priorities: number[];
  reminderTypes: string[];
  sortBy: {
    field: string;
    direction: 'DESC' | 'ASC';
  };
  query: string;
  map: {
    showAllRoutes: boolean;
    showUndispatchedWorkOrders: boolean;
  };
};

export const DEFAULT_CLIENT_FILTERS: ClientFilterState = {
  clientStatuses: [],
  projectStatuses: [],
  requestStatuses: [],
  assignees: [],
  templates: [],
  statuses: [],
  labels: [],
  priorities: [],
  reminderTypes: [],
  sortBy: {
    field: 'priority',
    direction: 'DESC'
  },
  query: '',
  map: {
    showAllRoutes: true,
    showUndispatchedWorkOrders: true
  }
};

export const useClientFilterState = createGlobalState<ClientFilterState>(DEFAULT_CLIENT_FILTERS);

export const mapClientSortToServerSort = (sortBy: { field: string; direction: 'ASC' | 'DESC' }) => {
  if (sortBy.field === 'priority') {
    return sortBy.direction === 'ASC' ? PrivilegedTasksOrderBy.PriorityDesc : PrivilegedTasksOrderBy.PriorityAsc;
  }

  if (sortBy.field === 'status') {
    return sortBy.direction === 'ASC' ? PrivilegedTasksOrderBy.StatusAsc : PrivilegedTasksOrderBy.StatusDesc;
  }

  if (sortBy.field === 'createdAt') {
    return sortBy.direction === 'ASC' ? PrivilegedTasksOrderBy.CreatedAtAsc : PrivilegedTasksOrderBy.CreatedAtDesc;
  }

  if (sortBy.field === 'startDate') {
    return sortBy.direction === 'ASC' ? PrivilegedTasksOrderBy.StartDateAsc : PrivilegedTasksOrderBy.StartDateDesc;
  }

  if (sortBy.field === 'endDate') {
    return sortBy.direction === 'ASC' ? PrivilegedTasksOrderBy.EndDateAsc : PrivilegedTasksOrderBy.EndDateDesc;
  }

  return PrivilegedTasksOrderBy.IdDesc;
};

export const mapClientSortToRemindersServerSort = (sortBy: { field: string; direction: 'ASC' | 'DESC' }) => {
  if (sortBy.field === 'priority') {
    return sortBy.direction === 'ASC' ? RemindersOrderBy.DueDateDesc : RemindersOrderBy.DueDateAsc;
  }

  if (sortBy.field === 'status') {
    return sortBy.direction === 'ASC' ? RemindersOrderBy.CompletedAtAsc : RemindersOrderBy.CompletedAtDesc;
  }

  if (sortBy.field === 'createdAt') {
    return sortBy.direction === 'ASC' ? RemindersOrderBy.CreatedAtAsc : RemindersOrderBy.CreatedAtDesc;
  }

  if (sortBy.field === 'startDate') {
    return sortBy.direction === 'ASC' ? RemindersOrderBy.DueDateAsc : RemindersOrderBy.DueDateDesc;
  }

  if (sortBy.field === 'endDate') {
    return sortBy.direction === 'ASC' ? RemindersOrderBy.DueDateAsc : RemindersOrderBy.DueDateDesc;
  }

  return PrivilegedTasksOrderBy.IdDesc;
};

export const mapSearchFilterToServerSearchFilter = (search: string) => {
  if (!search) {
    return {};
  }

  const result: DeepPartial<PrivilegedTaskFilter> = {
    or: [
      {
        title: { includesInsensitive: search }
      },
      {
        project: {
          title: { includesInsensitive: search }
        }
      },
      {
        project: {
          address: { includesInsensitive: search }
        }
      },
      {
        project: {
          street: { includesInsensitive: search }
        }
      },
      {
        project: {
          city: { includesInsensitive: search }
        }
      },
      {
        project: {
          state: { includesInsensitive: search }
        }
      },
      {
        project: {
          zipcode: { includesInsensitive: search }
        }
      }
    ]
  };

  return result;
};

export const mapClientFiltersToServerFilters = (
  clientFilters: ClientFilterState
): DeepPartial<PrivilegedTaskFilter> => {
  let resultFilter: DeepPartial<PrivilegedTaskFilter> = {};

  if (
    clientFilters.clientStatuses.length ||
    clientFilters.projectStatuses.length ||
    clientFilters.requestStatuses.length
  ) {
    resultFilter.project = {
      or: [
        {
          type: { equalTo: RecordType.PROJECT },
          status: { in: clientFilters.projectStatuses }
        },
        {
          type: { equalTo: RecordType.DEAL },
          requestStatus: { in: clientFilters.requestStatuses }
        },
        {
          type: { equalTo: RecordType.ACCOUNT },
          accountStatus: { in: clientFilters.clientStatuses }
        }
      ]
    };
  }

  if (clientFilters.priorities.length) {
    resultFilter.priority = { in: clientFilters.priorities };
  }

  if (clientFilters.assignees.length) {
    if (clientFilters.assignees.includes('unassigned')) {
      if (clientFilters.assignees.length === 1) {
        resultFilter.assigneeExists = false;
      } else {
        resultFilter.or = [
          {
            assigneeId: { in: clientFilters.assignees.filter((id) => id !== 'unassigned') }
          },
          {
            assigneesByTaskId: {
              some: { userId: { in: clientFilters.assignees.filter((id) => id !== 'unassigned') } }
            }
          },
          { assigneeExists: false }
        ];
      }
    } else {
      resultFilter.or = [
        {
          assigneeId: { in: clientFilters.assignees }
        },
        {
          assigneesByTaskId: { some: { userId: { in: clientFilters.assignees } } }
        }
      ];
    }
  }

  if (clientFilters.templates.length) {
    resultFilter.templateTaskId = { in: clientFilters.templates };
  }

  if (clientFilters.labels.length) {
    resultFilter.privilegedTaskLabels = { some: { labelId: { in: clientFilters.labels } } };
  }

  if (clientFilters.statuses.length) {
    resultFilter.status = { in: clientFilters.statuses };
  }

  if (clientFilters.query.trim()) {
    const searchFilter = mapSearchFilterToServerSearchFilter(clientFilters.query.trim());

    if (resultFilter.or && searchFilter.or) {
      resultFilter = {
        ...resultFilter,
        ...searchFilter,
        or: undefined,
        and: [{ or: resultFilter.or }, { or: searchFilter.or }]
      };
    } else {
      resultFilter = {
        ...resultFilter,
        ...searchFilter
      };
    }
  }

  return resultFilter;
};
