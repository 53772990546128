import React, { useMemo } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Radio as RadioMui, RadioProps as RadioPropsMui } from '@material-ui/core';
import { radio, icon } from './styled';

interface RadioProps extends RadioPropsMui {

}

export const Radio: React.FC<RadioProps> = (props) => {
  const makeStylesParam = useMemo(() => (radio()), []);
  const classes = makeStyles(makeStylesParam)();
  const classesIcon = makeStyles(icon)();

  return (
    <RadioMui
      classes={classes}
      color="primary"
      icon={<span className={classesIcon.icon} />}
      checkedIcon={<span className={clsx(classesIcon.icon, classesIcon.checkedIcon)} />}
      {...props}
    />
  );
};
