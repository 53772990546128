import imageCompression from 'browser-image-compression';

import { isBrowser } from '@utils';

let heic2any: any = null;

if (isBrowser) {
  // eslint-disable-next-line global-require
  heic2any = require('heic2any');
}

const ALLOWED_FORMATS = ['jpeg', 'png', 'heic'];
const options = {
  maxSizeMB: 3.5,
  maxWidthOrHeight: 5500,
  useWebWorker: true
};

const extractExtension = (file: File) => {
  const fileNameConstruction = file.name.split('.');

  return fileNameConstruction.length > 1 ? fileNameConstruction.pop() : '';
};

const compressImage = async (file: File) => {
  const extension = extractExtension(file);

  let compressFile = file;
  let { name, type } = file;
  if (extension?.toLowerCase() === 'heic') {
    try {
      const jpegName = file.name.replace(extension, 'jpeg');
      const jpegMime = 'image/jpeg';
      compressFile = new File(
        [
          (await heic2any({
            toType: 'image/jpeg',
            blob: file
          })) as Blob
        ],
        jpegName,
        {
          lastModified: file.lastModified,
          type: jpegMime
        }
      );

      type = jpegMime;
      name = jpegName;
    } catch (error) {
      console.error('Couldnt compress: Error converting HEIC to JPEG', error);

      return new File([file], name, {
        lastModified: file.lastModified,
        type: 'image/heic'
      });
    }
  }

  return new File([await imageCompression(compressFile, options)], name, {
    lastModified: file.lastModified,
    type
  });
};

export { compressImage, ALLOWED_FORMATS, extractExtension };
