import { QueryClient } from 'react-query';

/**
 * @deprecated If you want to use it directly, most probably you are invalidating queries from redux action. Consider
 * migrating your data from redux to useQuery instead.
 */
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});
