import { useCallback } from 'react';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import api, { downloadFile, downloadForm } from '@services/api/databankApi';
import { useToast } from '@hooks/useToast';
import { errorHandler } from '@services/api/helpers';
import { uploadCompressedFile } from '@services/UploadServices/uploadService';
import { File as FileType, File } from '@generated/types/graphql';
import { ReactQueryKey } from '@enums';
import { PathType } from '@hooks/useDatabank';
import { downloadMultiple } from '@components/Project/DataBank/redux/actions';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { apiErrorHandler, isBrowser } from '@utils';
import { isPreviewableFile, isImageFile } from '@utils/files';
import { Instance } from 'pspdfkit';
import { selectWorkspaceId } from '@state/selectors';
import { config } from '../config';
import { useExportForm } from './documents/forms/useExportForm';
import { useAppSelector } from './store';

export type DownloadFile = {
  id: number;
  type: PathType.FILE | PathType.FORM | PathType.STAGE;
  pspdfkitInstance?: Instance;
  downloadFileRef?: HTMLAnchorElement;
  projectId?: number;
  cachedFile?: FileType;
};

const getFile = async (fileId: number) =>
  (
    await postGraphql<{ file: File }>(gql`
    query USE_FILES_GET_FILE {
      file(id: ${fileId}) {
        id
        name
        type
        metaData
        annotations
        downloadUrl
        projectId
        annotations
        createdBy
        form {
          id
        }
      }
    }
  `)
  ).file;

export const useFile = (fileId: number, options?: { refetchOnMount?: boolean }) =>
  useQuery<File>(
    [ReactQueryKey.File, fileId],
    async () => {
      try {
        return await getFile(fileId);
      } catch (e) {
        throw apiErrorHandler('Error fetching file', e);
      }
    },
    {
      enabled: !!fileId,
      initialDataUpdatedAt: Date.now() - 1000 * 3600 * 24,
      staleTime: 1000 * 3600 * 24,
      refetchOnMount: options?.refetchOnMount
    }
  );

export const useFileMutation = () => {
  const { showError, showSuccess } = useToast();
  const companyId = useAppSelector(selectWorkspaceId);
  const queryClient = useQueryClient();
  const { mutateAsync: exportForm } = useExportForm();

  const remove = useMutation<void, Error, { projectId: number; fileId: number }>(
    async ({ projectId, fileId }) => {
      try {
        await api.deleteFile(fileId, projectId);
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: async () => {
        showSuccess('Successfully removed the file');

        await Promise.all([
          queryClient.invalidateQueries([ReactQueryKey.DatabankTree]),
          queryClient.invalidateQueries([ReactQueryKey.RecordDocList]),
          queryClient.invalidateQueries([ReactQueryKey.Files])
        ]);
      }
    }
  );

  const bulkRemove = useMutation<void, Error, { fileIds: number[]; projectId: number }>(
    async ({ fileIds, projectId }) => {
      try {
        await Promise.all(fileIds.map((fileId) => api.deleteFile(fileId, projectId)));
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: async () => {
        showSuccess('Successfully removed the file(s)');

        queryClient.invalidateQueries([ReactQueryKey.RecordDocList]);
      }
    }
  );

  const removePublic = useMutation<void, Error, { fileId: number }>(
    async ({ fileId }) => {
      try {
        await api.deletePublicFile(fileId, companyId);
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: async () => {
        showSuccess('Successfully removed the file');
      }
    }
  );

  const update = useMutation<
    File,
    Error,
    {
      stageId?: number;
      fileId: number;
      annotations: File['annotations'];
      isArchived?: boolean;
      showSuccessMessage?: boolean;
      refetchDatabankTree?: boolean;
    }
  >(
    async ({ fileId, ...dto }) => {
      try {
        return (await api.updateFile(fileId, dto)).data as File;
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: async (response, { showSuccessMessage = true, refetchDatabankTree = true, fileId, annotations }) => {
        if (showSuccessMessage) {
          showSuccess('Successfully updated the file');
        }

        if (refetchDatabankTree) {
          await queryClient.invalidateQueries([ReactQueryKey.DatabankTree]);
        }

        queryClient.invalidateQueries([ReactQueryKey.RecordDocList]);
        queryClient.invalidateQueries([ReactQueryKey.Files, ReactQueryKey.FileDetails, fileId]);

        await queryClient.setQueryData([ReactQueryKey.File, fileId], (oldData) => {
          if (oldData && annotations) {
            return {
              ...(oldData as File),
              annotations
            };
          }

          return oldData;
        });
      }
    }
  );

  const bulkUpdateFiles = useMutation<void, Error, { projectId: number; fileIds: number[]; sharedWithPortal: boolean }>(
    async ({ projectId, fileIds, sharedWithPortal }) => {
      try {
        await api.bulkUpdateFiles({ projectId, fileIds, sharedWithPortal });
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: async () => {
        showSuccess('Successfully updated files');
        queryClient.invalidateQueries([ReactQueryKey.RecordDocList]);
      }
    }
  );

  const bulkUpdate = useMutation<
    void,
    Error,
    {
      stageId?: number;
      fileIds: number[];
      annotations: File['annotations'];
      isArchived?: boolean;
      showSuccessMessage?: boolean;
      refetchDatabankTree?: boolean;
    }
  >(
    async ({ fileIds, ...dto }) => {
      try {
        await Promise.all(fileIds.map((fileId) => api.updateFile(fileId, dto)));
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: async (_, { showSuccessMessage = true, refetchDatabankTree = true }) => {
        if (showSuccessMessage) {
          showSuccess('Successfully updated.');
        }

        queryClient.invalidateQueries([ReactQueryKey.RecordDocList]);

        if (refetchDatabankTree) {
          await queryClient.invalidateQueries([ReactQueryKey.DatabankTree]);
        }
      }
    }
  );

  const uploadFiles = useMutation<
    FileType[],
    Error,
    {
      files: File[];
      isPrivate: boolean;
      projectId?: number;
      stageId?: number;
      showSuccessMessage?: boolean;
      refetchDatabankTree?: boolean;
    }
  >(
    async ({ files, isPrivate, projectId, stageId }) => {
      try {
        const payload = (file: File) => {
          const formData = new FormData();
          formData.append('file', file);
          if (projectId) {
            formData.append('projectId', projectId.toString());
          }
          if (stageId) {
            formData.append('stageId', stageId.toString());
          }

          return {
            companyId,
            formData,
            isPrivate,
            projectId
          };
        };

        return (await Promise.all(files.map((file) => uploadCompressedFile(payload(file))))).map(({ data }) => data);
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: async (_, { showSuccessMessage = true, refetchDatabankTree = true }) => {
        if (showSuccessMessage) {
          showSuccess('File(s) successfully uploaded!');
        }

        queryClient.invalidateQueries([ReactQueryKey.RecordDocList]);

        queryClient.invalidateQueries([ReactQueryKey.Files]);

        if (refetchDatabankTree) {
          await queryClient.invalidateQueries([ReactQueryKey.DatabankTree]);
        }
      }
    }
  );

  const getDownloadUrl = useCallback(
    (id: number, type: PathType.FILE | PathType.FORM) =>
      PathType.FORM === type ? downloadForm(id) : downloadFile(id, companyId),
    [companyId]
  );

  const downloadMutation = useMutation<void, Error, DownloadFile>(
    async ({ type, cachedFile, id, projectId, pspdfkitInstance }) => {
      try {
        const download = (blob, filename?: string) => {
          if (isBrowser) {
            const a = document.createElement('a');
            a.href = blob;
            a.style.display = 'none';
            if (filename) {
              a.download = filename;
              a.setAttribute('download', filename);
            }

            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
        };

        if (PathType.FILE === type) {
          const file = cachedFile ?? (await getFile(id));

          // pspdfkit downloadable file
          if (isBrowser && isPreviewableFile({ file }) && file.annotations?.annotations?.length) {
            let PSPDFKit;

            if (!pspdfkitInstance) {
              ({ default: PSPDFKit } = await import('pspdfkit'));
            }

            const downloadFileRef = document.createElement('a');
            document.body.appendChild(downloadFileRef);

            const instance: Instance =
              pspdfkitInstance ??
              (await PSPDFKit.load({
                licenseKey: config.pspdfkitKey,
                container: downloadFileRef,
                document: getDownloadUrl(id, PathType.FILE),
                enableHistory: true,
                instantJSON: file.annotations
              }));

            const exportAsImage = isImageFile(file) && instance.totalPageCount === 1;
            const fileName = `${file.name.split('.')[0]}.${exportAsImage ? 'png' : 'pdf'}`;

            // download image
            if (exportAsImage) {
              const { width } = instance.pageInfoForIndex(0);
              const src = await instance.renderPageAsImageURL({ width }, 0);
              download(src, fileName);
            } else {
              // download pdf
              const buffer = await instance.exportPDF();
              // eslint-disable-next-line no-prototype-builtins
              const supportsDownloadAttribute = HTMLAnchorElement.prototype.hasOwnProperty('download');
              const blob = new Blob([buffer], { type: 'application/pdf' });

              if (navigator.msSaveOrOpenBlob) {
                navigator.msSaveOrOpenBlob(blob, fileName);
              } else if (!supportsDownloadAttribute) {
                const reader = new FileReader();
                reader.onloadend = function () {
                  const dataUrl = reader.result;
                  download(dataUrl, fileName);
                };
                reader.readAsDataURL(blob);
              } else {
                const objectUrl = window.URL.createObjectURL(blob);
                download(objectUrl, fileName);
                window.URL.revokeObjectURL(objectUrl);
              }
            }

            if (PSPDFKit) {
              PSPDFKit.unload(downloadFileRef);
            }

            document.body.removeChild(downloadFileRef);
          } else {
            // other files
            download(downloadFile(id, companyId));
          }
        } else if (PathType.FORM === type) {
          exportForm({ formId: id });
        } else {
          // folder download
          await downloadMultiple(
            {
              stageIds: [id],
              files: [],
              projectId
            },
            projectId
          );
        }
      } catch (e) {
        console.error('download failed:', e);
      }
    },
    {
      onError: () => {
        showError('Failed to download the file, please try again');
      }
    }
  );

  return {
    update,
    remove,
    uploadFiles,
    download: downloadMutation,
    removePublic,
    getDownloadUrl,
    bulkUpdate,
    bulkRemove,
    bulkUpdateFiles
  };
};
