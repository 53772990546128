import { post, patch, deleteRequest } from './base';
import { Urls } from './base/axios';

export type CreateCallPayload = {
  fromNumber: string;
  toNumber: string;
  isInbound: boolean;
  outcome: string;
  startTime: Date | null;
  reason: string;
  disposition: string;
  note: string;
  projectId: number;
};

export type UpdateCallPayload = Partial<CreateCallPayload>;

export const callApi = {
  create: (payload: CreateCallPayload) => post(`${Urls.project}/call`, payload),
  update: (id: number, payload: UpdateCallPayload) => patch(`${Urls.project}/call/${id}`, payload),
  delete: (id: number) => deleteRequest(`${Urls.project}/call/${id}`)
};
