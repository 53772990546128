import { useMemo } from 'react';
import { QueryParamsEnum, useQueryParam } from '..';
import { useSmartViewList } from './useSmartViewList';

export const useCurrentSmartView = () => {
  const { data: smartViews = [] } = useSmartViewList();
  const [currentSmartViewId] = useQueryParam(QueryParamsEnum.SmartViewId);

  const currentSmartView = useMemo(() => {
    if (!currentSmartViewId) {
      return null;
    }

    return smartViews.find((sv) => sv.id === parseInt(currentSmartViewId, 10));
  }, [currentSmartViewId, smartViews]);

  return currentSmartView;
};
