import React, { Component } from 'react';
import { render } from 'react-dom';
import BackDrop from '../../common/Backdrop';
import ConfirmationBody from './ConfimationBody/ConfirmationBody';

import styled from 'styled-components';
import { device } from '../../../styles/breakpoints';
import modal from '../../../styles/Components/modal';

const StyledModal = styled.div`
  ${modal.modal};

  /* Temp styles, remove after refactor to Mui modal */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1400;

  display: ${(props) => (props.show ? 'flex' : 'none')};

  ${device.sm`
    height: 100vh;
    width: 100vw;
  `};
`;

let resolve;

interface IConfirmationModal {
  cancelMessege?: string;
  confirmMessege?: string;
  messege?: string;
  isOpen?: boolean;
}

class ConfirmationModal extends Component<IConfirmationModal> {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      cancelMessege: this.props.cancelMessege,
      confirmMessege: this.props.confirmMessege,
      messege: this.props.messege
    };
  }
  static create = () => {
    return typeof document !== 'undefined'
      ? render(
          <ConfirmationModal />,
          document.body.appendChild(document.createElement('div'))
        ) as unknown as ConfirmationModal
      : { show: () => null };
  };

  handleCanceled = () => {
    this.setState({ isOpen: false });
    resolve(false);
  };

  handleConfirmed = () => {
    this.setState({ isOpen: false });
    resolve(true);
  };

  show = (messege?: string, cancelMessege?: string, confirmMessege?: string) => {
    this.setState({ isOpen: true, confirmMessege, cancelMessege, messege });
    return new Promise((res) => {
      resolve = res;
    });
  };

  render() {
    return (
      /*
       * className={this.state.isOpen ? scss.confirmation_Layout : scss.hidden}
       * */
      <StyledModal show={this.state.isOpen}>
        <BackDrop onClick={this.handleCanceled} isVisible={true} />
        <ConfirmationBody
          cancelMessege={this.state.cancelMessege}
          confirmMessege={this.state.confirmMessege}
          messege={this.state.messege}
          onCanceled={this.handleCanceled}
          onConfirmed={this.handleConfirmed}
        />
      </StyledModal>
    );
  }
}

export default ConfirmationModal;
