import React from 'react';

export const DragHandIcon = ({ size = '16px', color = 'currentColor' }: { size?: string | number; color?: string }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.02885 3.23376C8.1539 2.53243 8.76853 2 9.50547 2C10.0376 2 10.5058 2.27632 10.7735 2.69804L11.2105 3.38665L11.9729 3.09699C12.1371 3.03462 12.3161 3 12.5055 3C13.2424 3 13.857 3.53243 13.9821 4.23376L14.1647 5.25825L15.1811 5.03494C15.2847 5.01219 15.3931 5 15.5055 5C16.3339 5 17.0055 5.67157 17.0055 6.5V12C17.0055 15.3132 14.3181 18 11.0019 18H10.3431C7.63347 18 5.108 16.63 3.63153 14.3597L2.49445 12.6112C1.34902 10.8499 2.34343 8.5692 4.2489 8.09096L5.00547 7.90107V5.5C5.00547 4.67157 5.67704 4 6.50547 4C6.61785 4 6.72624 4.01219 6.82979 4.03494L7.84619 4.25825L8.02885 3.23376Z"
      fill="none"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5 3C9.22386 3 9 3.22386 9 3.5V5.5V8C9 8.55228 8.55228 9 8 9C7.44772 9 7 8.55228 7 8V5.5C7 5.22386 6.77614 5 6.5 5C6.22386 5 6 5.22386 6 5.5V10C6 10.5523 5.55228 11 5 11C4.44772 11 4 10.5523 4 10V9.26217C3.07144 9.79882 2.67196 11.057 3.32841 12.0665L4.46549 13.8149C5.75741 15.8015 7.96705 17 10.3376 17H10.9964C13.7593 17 15.999 14.7614 15.999 12V6.5H16C16 6.22386 15.7761 6 15.5 6C15.2239 6 15 6.22386 15 6.5V8C15 8.55228 14.5523 9 14 9C13.4477 9 13 8.55228 13 8V6.5V4.5C13 4.22386 12.7761 4 12.5 4C12.2239 4 12 4.22386 12 4.5V7C12 7.55228 11.5523 8 11 8C10.4477 8 10 7.55228 10 7V4.5V3.5C10 3.22386 9.77614 3 9.5 3ZM14.9611 4.05823C14.7525 2.88823 13.73 2 12.5 2C12.1874 2 11.8882 2.05738 11.6123 2.16218C11.169 1.46363 10.3886 1 9.5 1C8.27003 1 7.24752 1.88823 7.03891 3.05823C6.86534 3.0201 6.68502 3 6.5 3C5.11929 3 4 4.11929 4 5.5V7.12104C1.45581 7.75959 0.116029 10.7966 1.65067 13.1564L2.78775 14.9049C4.44878 17.459 7.28975 19 10.3376 19H10.9964C14.8644 19 18 15.866 18 12V6.5C18 5.11929 16.8807 4 15.5 4C15.315 4 15.1347 4.0201 14.9611 4.05823Z"
      fill={color}
    />
  </svg>
);
