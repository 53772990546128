import { AnalyticsWidgetType } from '@features/Analytics/types';
import { WidgetData } from '../../Widget/types';
import { WorkOrdersWidgetDataParams } from '../types';
import { WidgetSettingsError } from '../../WidgetSettingsError';
import { fetchWorkOrdersBarLikeData } from './fetchWorkOrdersBarLikeData';
import { fetchWorkOrdersKpiData } from './fetchWorkOrdersKpiData';
import { fetchWorkOrdersFunnelData } from './fetchWorkOrdersFunnelData';

export const fetchWorkOrdersData = async (params: WorkOrdersWidgetDataParams): Promise<WidgetData> => {
  switch (params.settings.widgetType) {
    case AnalyticsWidgetType.KPI: {
      const data = await fetchWorkOrdersKpiData(params);

      return {
        ...data,
        type: params.settings.widgetType
      };
    }
    case AnalyticsWidgetType.BAR:
    case AnalyticsWidgetType.LINE:
    case AnalyticsWidgetType.PIE:
    case AnalyticsWidgetType.DOUGHNUT:
    case AnalyticsWidgetType.LEADERBOARD: {
      const data = await fetchWorkOrdersBarLikeData(params);

      return {
        ...data,
        type: params.settings.widgetType
      };
    }

    case AnalyticsWidgetType.TIMELINE:
    case AnalyticsWidgetType.PIPELINE:
    case AnalyticsWidgetType.FUNNEL: {
      const data = await fetchWorkOrdersFunnelData(params);

      return {
        ...data,
        type: params.settings.widgetType
      };
    }

    default:
      throw new WidgetSettingsError(`Unsupported widget type: ${params.settings.widgetType}`);
  }
};
