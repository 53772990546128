import { makeStyles } from '@material-ui/core/styles';

const tableFooter = () => {
  return {
    root: {

    }
  };
};

export const useStylesTableFooter = makeStyles(tableFooter());
