import { Quill } from 'react-quill';

const Embed = Quill.import('blots/embed');

export class MentionBlot extends Embed {
  static create(data) {
    const node = super.create();

    node.addEventListener('click', (e) => {
      e.preventDefault();
    }, false);

    node.innerHTML += data.denotationChar;
    node.innerHTML += data.value;

    return MentionBlot.setDataValues(node, data);
  }

  static setDataValues(element, data) {
    const domNode = element;
    Object.keys(data)
      .forEach((key) => {
        domNode.dataset[key] = data[key];
      });
    return domNode;
  }

  static value(domNode) {
    return domNode.dataset;
  }
}

MentionBlot.blotName = 'mention-strong';
MentionBlot.tagName = 'strong';
MentionBlot.className = 'cq-mention-strong';

Quill.register(MentionBlot);
