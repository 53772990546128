import { ReactQueryKey } from '@enums';
import { errorHandler } from '@services/api/helpers';
import { useMutation, useQueryClient } from 'react-query';
import { useToast } from '@hooks/useToast';
import { selectWorkspaceId } from '@state/selectors';
import userApi from '@services/api/userApi';
import { useAppSelector } from '..';

export const useDeleteDashboard = () => {
  const queryClient = useQueryClient();
  const { showSuccess } = useToast();
  const companyId = useAppSelector(selectWorkspaceId);

  return useMutation<void, Error, number>(
    async (id) => {
      try {
        await userApi.deleteDashboard(companyId, id);
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: () => {
        showSuccess('Dashboard deleted successfully');
        queryClient.invalidateQueries(ReactQueryKey.Analytics);
      }
    }
  );
};
