import { useMutation, useQueryClient } from 'react-query';
import projectApi from '@services/api/projectApi';
import { errorHandler } from '@services/api/helpers';
import { ReactQueryKey } from '@enums';
import { useToast } from '..';

export const useCreateRecordMember = () => {
  const { showSuccess } = useToast();
  const queryClient = useQueryClient();

  return useMutation<void, Error, { recordId: number; userId: number }>(
    async ({ recordId, userId }) => {
      try {
        await projectApi.createProjectMembers(recordId, [userId]);
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: (_, { recordId }) => {
        showSuccess('Member successfully added!');
        queryClient.invalidateQueries([ReactQueryKey.RecordDetail, recordId]);
        queryClient.invalidateQueries([ReactQueryKey.ProjectActivity]);
      }
    }
  );
};
