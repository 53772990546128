import React from 'react';
import { formatMoney } from '@utils/utils';
import { BriefcaseIcon } from '@kit/ui/icons/Briefcase';
import { FeedItemProps } from './types';
import { BaseItemTemplate } from './BaseItemTemplate';
import { FeedItemIconContainer } from './styled';

export const LineItemFeedItem = ({ item }: FeedItemProps) => {
  const author = item.createdBy || { id: 0, firstName: item.userName, lastName: '', avatarUrl: item.userImage };

  const userName = author ? [author.firstName, author.lastName].join(' ') : '';

  const {
    added,
    modified,
    removed,
    newTotalCost,
    newTotalPrice,
    newMargin,
    oldTotalCost,
    oldTotalPrice,
    oldMargin,
    isTotalCostChanged,
    isTotalPriceChanged,
    isMarginChanged,
    revision
  } = item.payload;

  const valueToMoney = (property: string, value: number | string) => {
    if (['margin', 'description', 'quantity'].includes(property)) {
      return value;
    }

    return formatMoney(value as number);
  };

  const removedText = (
    <>
      - Removed <b>{removed.map(({ name }) => name).join(', ')}</b>
    </>
  );

  const addedText = added.map((item) => (
    <div key={item.name}>
      - Added <b>{item.name}</b>, Quantity: <b>{item.quantity}</b>, Unit Cost: <b>{formatMoney(item.unitCost)}</b>, Unit
      Price: <b>{formatMoney(item.unitPrice)}</b>
    </div>
  ));

  const changedText = modified.map((item) => (
    <div key={item.name}>
      - Changed <b>{item.name}</b>{' '}
      {item.modifiedProperties.map((property, index) => (
        <span key={property.property}>
          <b>{property.property}</b> from <b>{valueToMoney(property.property, property.oldValue)}</b> to{' '}
          <b>{valueToMoney(property.property, property.newValue)}</b>
          {index !== item.modifiedProperties.length - 1 ? ', ' : ''}
        </span>
      ))}
    </div>
  ));

  const totalChange = (name: string, old: number, newValue: number) => (
    <div key={name}>
      Total <b>{name}</b> changed from <b>{valueToMoney(name, old)}</b> to <b>{valueToMoney(name, newValue)}</b>
    </div>
  );

  return (
    <BaseItemTemplate
      id={item.id}
      context="project"
      icon={
        <FeedItemIconContainer color="#DFDFE8">
          <BriefcaseIcon color="#737382" size="12px" />
        </FeedItemIconContainer>
      }
      author={author}
      date={item.createdAt}
      title={
        revision ? (
          <>
            <b>{userName}</b> created change order <b>#{revision}</b>
          </>
        ) : (
          <>
            <b>{userName}</b> created contract
          </>
        )
      }
    >
      <div>
        {modified.length > 0 && changedText}
        {added.length > 0 && addedText}
        {removed.length > 0 && removedText}
        {isTotalPriceChanged && totalChange('revenue', oldTotalPrice, newTotalPrice)}
        {isTotalCostChanged && totalChange('cost', oldTotalCost, newTotalCost)}
        {isMarginChanged && totalChange('margin', oldMargin, newMargin)}
      </div>
    </BaseItemTemplate>
  );
};
