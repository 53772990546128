import { DimensionType, UserSubdimentionType } from '@features/Analytics/types';
import { DateTime } from 'luxon';
import { TaskStatus } from '@generated/types/graphql';
import { WORK_ORDER_PRIORITY_CONFIG } from '@features/Analytics/constants';
import { BarData } from '../../Widget/types';
import { remapAndAggregateByTeamPoints } from '../helpers';
import { WorkOrdersWidgetDataParams } from '../types';

export const postProcessingData = ({
  data,
  settings,
  usersMap,
  userToTeamsMap,
  timezone,
  workOrderStatusesMap,
  workOrderTemplatesMap
}: WorkOrdersWidgetDataParams & {
  data: BarData;
}) => {
  if (settings.dimensionId === DimensionType.TIME) {
    return {
      ...data,
      points: data.points.map((point) => {
        if (point.isEmpty) {
          return point;
        }

        return {
          x: DateTime.fromISO(point.x as string, { zone: timezone }).toFormat('MM/dd'),
          y: point.y,
          originalX: point.x
        };
      })
    };
  }

  if (settings.subdimensionId === UserSubdimentionType.USER) {
    return {
      ...data,
      points: data.points.map((point) => {
        if (point.isEmpty) {
          return point;
        }

        const user = usersMap[parseInt(point.x as string, 10)];

        return {
          x: user ? `${user.firstName} ${user.lastName}` : point.x,
          y: point.y,
          originalX: point.x
        };
      })
    };
  }

  if (settings.subdimensionId === UserSubdimentionType.TEAM) {
    return {
      ...data,
      points: remapAndAggregateByTeamPoints({
        points: data.points,
        userToTeamsMap,
        aggregationFunction: settings.aggregateFunctionId
      })
    };
  }

  if (settings.dimensionId === DimensionType.PRIORITY) {
    return {
      ...data,
      points: data.points.map((point) => {
        if (point.isEmpty) {
          return point;
        }

        return {
          x: WORK_ORDER_PRIORITY_CONFIG[point.x as number].name,
          y: point.y,
          originalX: point.x,
          color: WORK_ORDER_PRIORITY_CONFIG[point.x as number].color
        };
      })
    };
  }

  if (settings.dimensionId === DimensionType.STATUS) {
    return {
      ...data,
      points: data.points.map((point) => {
        if (point.isEmpty) {
          return point;
        }

        return {
          x: workOrderStatusesMap[point.x as TaskStatus].label,
          y: point.y,
          originalX: point.x
        };
      })
    };
  }

  if (settings.dimensionId === DimensionType.TEMPLATE) {
    return {
      ...data,
      points: data.points.map((point) => {
        if (point.isEmpty) {
          return point;
        }

        return {
          x: workOrderTemplatesMap[point.x as number].title,
          y: point.y,
          originalX: point.x
        };
      })
    };
  }

  return data;
};
