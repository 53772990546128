import { FieldPath, UseFormSetError } from 'react-hook-form';

import { scrollToFirstError } from './scrollToFirstError';

const applyServerValidation = <TFormValues>(
  setError: UseFormSetError<TFormValues>,
  errors?: { [key in keyof TFormValues]?: string | undefined }
) => {
  if (!errors) {
    return;
  }

  Object.entries(errors).forEach(([key, value], index) => {
    const fieldName = key as FieldPath<TFormValues>;
    const fieldError = value as string;

    if (fieldError) {
      setError(
        fieldName,
        {
          message: fieldError,
          type: 'server validations'
        },
        { shouldFocus: index === 0 }
      );
    }
  });

  scrollToFirstError();
};

export { applyServerValidation };
