import { RoleDTO } from '@types';
import * as actionTypes from '../../actions/actionTypes/actionTypes';
import { Company, UserSettings } from '../authentication/types';

export type AccountSettingType = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  avatarUrl?: string;
  phone: string;
  password?: string;
  passwordConfirmation?: string;
  roles: RoleType[] | number[];
  isLoading?: boolean;
  company: Company;
  settings: UserSettings;
  role: RoleDTO;
};

export type RoleType = {
  id: number;
  name: string;
};

export const initialState: AccountSettingType = {
  id: 0,
  firstName: '',
  lastName: '',
  email: '',
  avatarUrl: '',
  phone: '',
  password: '***************',
  isLoading: true,
  roles: []
};

const updateAccountReducer = (state, action) => {
  return { ...state, ...action.payload };
};

const accountReducer = (state = initialState, action): AccountSettingType => {
  switch (action.type) {
    case actionTypes.UPDATE_ACCOUNT_SETTINGS:
      return updateAccountReducer(state, action);
    default:
      return state;
  }
};

export default accountReducer;
