import React from 'react';
import styled from 'styled-components';
import { device } from '@styles';

export const Wrapper = styled.div`
  padding: 0 10px;
  text-decoration: none;
  display: none;

  ${device.xs`
    display: block;
  `};
`;

const ValueWrapperInner = styled.div<{ disableName: boolean }>`
  display: block;
  min-width: calc(${(props) => (props.disableName ? '100% - 20px' : '60% - 20px')});
  width: calc(${(props) => (props.disableName ? '100% - 20px' : '60% - 20px')});
  min-height: 19px;
  line-height: 19px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

export const ValueWrapper = React.forwardRef((props, ref) => (
  <ValueWrapperInner {...props} ref={ref} />
));

export const PropertyWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: 4px;
  width: 100%;
  min-height: 24px;
  color: ${(props) => props.theme.colors.black};
  
  svg {
    opacity: 1;
    ${({ theme }) => theme.devices.sm`
      opacity: 0; 
    `}
  }

  &:hover, &:focus {
    background-color: ${(props) => props.theme.colors.white3};
    
    svg {
      opacity: 1;
    }
  }
`;

const PropertyWrapperInner = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  position: relative;
  
  > div[aria-describedby] {
    display: flex;
    align-items: center;
    width: 100%;
  }
`;

export const MainWrapper: React.FC = ({ children, ...rest }) => (
  <PropertyWrapper {...rest}>
    <PropertyWrapperInner>{ children }</PropertyWrapperInner>
  </PropertyWrapper>
);

export const NameWrapper = styled.div`
  width: 40%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
