import { AnalyticsModule } from '@features/Analytics/types';
import React, { useCallback } from 'react';
import { Bar, LabelList, YAxis } from 'recharts';
import { Point } from '../types';
import { Container, RightAxisTitles } from './styled';
import { ChartBase } from './ChartBase';
import { StatusTick } from './StatusTick';
import { percentTickFormatter } from './helpers';
import { NoData } from './NoData';

interface Props {
  module: AnalyticsModule;
  points: { id: string; name: string; value: number; accumSum: number }[];
  statuses: { id: string; name: string }[];
  onClick: (point: Point) => void;
}

export const FunnelByStatus = ({ module, points, statuses, onClick }: Props) => {
  const handleBarClick = useCallback(
    (data: any) => {
      const point = data.payload;

      onClick({
        x: point.name,
        originalX: point.id,
        isEmpty: false,
        y: point.value
      });
    },
    [onClick]
  );

  if (!points.length) {
    return <NoData isXAxisReversed />;
  }

  return (
    <Container>
      <RightAxisTitles>
        <div>Next status conversion</div>
        <div>Cumulative conversion</div>
      </RightAxisTitles>
      <ChartBase points={points} isXAxisReversed>
        <YAxis
          width={150}
          axisLine={false}
          tickLine={false}
          orientation="left"
          tick={<StatusTick module={module} statuses={statuses} />}
          dataKey="name"
          type="category"
        />
        <YAxis
          width={70}
          stroke="#1D1D35"
          tickFormatter={percentTickFormatter}
          yAxisId="average"
          axisLine={false}
          tickLine={false}
          orientation="right"
          dataKey="percentFromPrev"
          type="category"
          fontSize={12}
        />
        <YAxis
          width={80}
          yAxisId="accumSum"
          axisLine={false}
          tickLine={false}
          stroke="#828D9A"
          tickFormatter={percentTickFormatter}
          orientation="right"
          dataKey="percentFromStart"
          type="category"
          fontSize={12}
        />
        <Bar name="Amount" stackId="a" maxBarSize={16} dataKey="value" fill="#71C4C4" onClick={handleBarClick}>
          <LabelList style={{ pointerEvents: 'none' }} fontSize={12} fill="#fff" dataKey="value" position="inside" />
        </Bar>
      </ChartBase>
    </Container>
  );
};
