import React, { memo } from 'react';
import { IconProps, Phone } from 'react-feather';
import { stopPropagationClick } from '@utils';
import { Anchor, AnchorContainer } from '../styled';

type Props = {
  iconProps?: IconProps;
  value: string;
  iconSize?: string | number;
};

export const PhoneNumber = memo(({ value, iconSize = 12, iconProps }: Props) => {
  return (
    <AnchorContainer>
      <Phone size={iconSize} {...iconProps} />
      <Anchor href={`tel:${value}`} rel="noopener noreferrer" {...stopPropagationClick}>
        {value}
      </Anchor>
    </AnchorContainer>
  );
});
