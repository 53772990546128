import { has } from 'lodash';

export const apiErrorHandler = (message: string = 'Exception on api call', error: any) => {
  const response = error?.response;
  let errorMessage = 'Something unexpected has happened, please try again.';

  if (response) {
    const { data, status } = response;
    if (status === 400) {
      const { message = [] } = data || {};

      errorMessage = Array.isArray(message) ? message.join('<br/>') : message;
    } else {
      errorMessage = JSON.stringify(data);
    }
  }

  // eslint-disable-next-line no-console
  console.info(message, error);

  return new Error(errorMessage);
};

export const isFilterObject = (value: any) => {
  return ['col', 'val', 'op'].every((key) => has(value, key));
};
