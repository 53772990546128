import { Input } from '@kit/ui/Input';
import { colors, device } from '@styles';
import styled, { css } from 'styled-components';
import { Link } from '@reach/router';

export const Container = styled.div`
  display: flex;
  padding: 16px;
  border-bottom: 1px solid #dfdfe8;
  gap: 16px;

  svg {
    flex-shrink: 0;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  flex: 1;
  overflow: hidden;
`;

export const Image = styled.div<{ isUpdating: boolean }>`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 88px;
  width: 88px;
  position: relative;
  border-radius: 8px;

  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 88px;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    opacity: ${({ isUpdating }) => (isUpdating ? 1 : 0)};
    transition: opacity 0.15s ease-in-out;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-radius: 8px;
    transition: background-color 0.15s ease-in-out;

  }

  :hover {
    cursor: pointer;

    &:after {
      background-color: rgba(29, 29, 53, 0.5);
    }

    svg {
      opacity: 1;
      z-index: 1;
    }
  }

}

`;

export const FirstRow = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
`;

export const SecondRow = styled.div`
  display: flex;
  align-items: center;
  gap: 48px;

  font-size: 14px;
`;

export const SecondRowItem = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const NameAndAvatar = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const CardInfoBlockHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;

  & > div:last-of-type {
    transform: rotate(90deg);
  }

  .MuiSelect-selectMenu {
    height: 32px !important;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const InfoTitle = styled.p<{ dimmed: boolean }>`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  ${(props) =>
    props.dimmed
      ? css`
          color: #9c9caa;
        `
      : css`
          color: #1d1d35;
        `}
  white-space: nowrap;
`;

export const PreTitle = styled(InfoTitle)`
  font-weight: 400;
`;

export const EditableWrapper = styled.div`
  cursor: pointer;
  padding: 4px;
  margin-left: -4px;
  margin-right: 4px;
  border: 1px solid transparent;
  width: 100%;
  transition:
    border-color 0.15s ease-in-out,
    background-color 0.15s ease-in-out;

  border-radius: 4px;

  &:hover {
    border: 1px solid #c8c8d3;

    background-color: ${colors.white};
  }

  svg {
    flex-shrink: 0;
  }
`;

export const NameInput = styled(Input)`

  && {

    .MuiInputBase-formControl {
      margin-left: -4px;
    }

    .MuiOutlinedInput-input {
      font-size: 20px;
      line-height: 24px;

      font-weight: 500;
      padding: 5px 12px;
      padding-left: 5px;
      height: auto;
      letter-spacing normal;
      min-width: 300px;
    }
  }
`;

export const AutomationsChip = styled.div`
  border: 1px solid #dfdfe8;
  background-color: #ffffff;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;

  white-space: nowrap;

  font-size: 12px;
  font-weight: 500;

  svg {
    flex-shrink: 0;
  }
`;

export const AutomationChipContainer = styled.div`
  margin-left: 80px;
`;

export const AutomationsListHeading = styled.div`
  color: #828d9a;
  font-size: 12px;
  font-weight: 500;
  padding: 8px;
`;

export const AutomationRow = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 8px;

  text-decoration: none;
  cursor: pointer;
  color: ${colors.black};
  transition: background-color 0.15s ease-in-out;

  &:visited,
  &:link,
  &:active,
  &:hover,
  &:focus {
    text-decoration: none;
    color: ${colors.black};
  }

  &:hover {
    background-color: #e8ecef;
  }
`;

export const AutomationTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 4px;

  span {
    max-width: 150px;
  }

  svg {
    flex-shrink: 0;
  }
`;

export const AutomationDescription = styled.div`
  max-width: 300px;
  font-size: 14px;
  font-weight: 400;
`;

export const ProjectAccountInfoChip = styled.div<{ isEditable: boolean; isClickable?: boolean }>`
  border: 1px solid #dfdfe8;
  border-radius: 20px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  cursor: ${({ isClickable, isEditable }) => (isClickable || isEditable ? 'pointer' : 'default')};
  color: ${({ isClickable }) => (isClickable ? '#009688' : '#1d1d35')};
  background-color: ${({ isEditable, isClickable }) => {
    return isEditable || isClickable ? '#FFF' : '#F7F7FC';
  }};

  display: inline-flex;
  align-items: center;
  padding: 2px 8px;
  height: 24px;

  & svg {
    margin-right: 4px;
  }

  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${device.md`
  max-width: 165px;
  `}
`;

export const Workflow = styled.div`
  min-height: 46px;
`;

export const NoWorkflowName = styled.div`
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 400;
  height: 14px;
`;

export const WorkflowName = styled(Link)`
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 8px;
  text-decoration: none;
  display: block;
  color: #235dff;
  cursor: pointer;

  &:hover,
  &:active,
  &:link,
  &:visited,
  &:focus {
    color: #235dff;
  }
`;

export const TimelineWrapper = styled.div`
  display: flex;
`;

export const TimelineItem = styled.div<{ isStarted: boolean; isFinished: boolean }>`
  height: 24px;
  padding: 0px 24px;
  position: relative;
  white-space: nowrap;
  width: 100%;
  background-color: #f7f7fc;
  color: rgb(119, 140, 162);
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  margin: 0 1px;
  min-width: 0;
  text-overflow: ellipsis;

  display: flex;
  align-items: center;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0px;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-right: none;
    border-left: 12px solid #f7f7fc;
  }

  &::after {
    content: '';
    position: absolute;
    left: 100%;
    z-index: 1;
    border-left: 12px solid transparent;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
  }

  & svg {
    margin-left: 8px;
  }

  ${({ isStarted, isFinished }) => {
    if (isStarted && isFinished) {
      return `
      color: #fff;
      background-color: #009A47;

      &::after {
        border-left-color: #009A47;
      }
      `;
    }
    if (isStarted && !isFinished) {
      return `
      color: #1D1D35;
      background-color: #CDF3DF;

      &::after {
        border-left-color: #CDF3DF;
      }
      `;
    }
    if (!isStarted && !isFinished) {
      return `
      background-color: #E4E8EC;
      color: #828D9A;

      &::after {
        border-left-color: #E4E8EC;
      }
      `;
    }

    return '';
  }};
`;
