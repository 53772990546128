import styled from 'styled-components';
import { colors, Unlink as IconUnlink } from '@styles';
import { Trash2, Edit as IconEdit, Copy as IconCopy, CheckSquare } from 'react-feather';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  svg, img, image {
    width: 20px;
    height: 20px;
    color: ${colors.gray};
    margin: 0 5px;
    
    &:hover, &:focus {
      color: ${colors.green};
    }
  } 
`;

export const Edit = styled(IconEdit)`
  
`;

export const Copy = styled(IconCopy)`

`;

export const MarkDefault = styled(CheckSquare)`

`;

export const Unlink = styled(IconUnlink)`

`;

export const Remove = styled(Trash2)`
  &:hover, &:focus {
    color: ${colors.red};
  }
`;
