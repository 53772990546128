import { Select } from '@kit/ui/Select';
import { isPlainObject } from 'lodash';
import React, { useMemo } from 'react';

interface Props extends React.ComponentProps<typeof Select> {}

export const SelectWrapper = ({ value, options, isMulti, ...props }: Props) => {
  const valueForSelect = useMemo(() => {
    if (options.length === 0) {
      return isMulti ? [] : null;
    }

    const getOptionByValue = (optionValue: any) => {
      if (isPlainObject(optionValue)) {
        return optionValue;
      }

      return options.find(
        (option) => option === optionValue || option.value === optionValue || option.id === optionValue
      );
    };

    if (isMulti) {
      const valueOrEmptyArray = value ?? [];

      return (Array.isArray(valueOrEmptyArray) ? valueOrEmptyArray : [value]).map(getOptionByValue).filter(Boolean);
    }

    return getOptionByValue(value) ?? null;
  }, [isMulti, value, options]);

  return <Select {...props} value={valueForSelect} options={options} isMulti={isMulti} />;
};
