import React, { useCallback } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { Checkbox } from '@kit/ui/Checkbox';
import { FormField } from '../../FormField';
import { FormControl, FormInputPropsToOmit } from '../../types';
import { useControllerWithValidation } from '../../useControllerWithValidation';
import { List } from './styled';

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends Omit<React.ComponentProps<typeof Checkbox>, FormInputPropsToOmit | 'isChecked'>,
    FormControl<TFieldValues, TName> {
  label?: string;
  layout?: 'row' | 'column';
  isDisabled?: boolean;
  options: { value: string; label: string; isDisabled?: boolean }[];
}

export const CheckboxGroupField = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  label,
  description,
  name,
  control,
  options,
  layout = 'column',
  isDisabled,
  clearOnUnmount,
  ...inputProps
}: Props<TFieldValues, TName>) => {
  const {
    field: { value, onChange, ...controlProps },
    fieldState: { error }
  } = useControllerWithValidation(name, control, label, clearOnUnmount);

  const handleCheckboxChange = useCallback(
    (optionValue: string) => (isChecked: boolean) => {
      if (isChecked) {
        onChange([...(value ?? []), optionValue]);
      } else {
        onChange((value ?? []).filter((v) => v !== optionValue));
      }
    },
    [value, onChange]
  );

  return (
    <FormField name={name} label={label} error={error?.message} description={description}>
      <List layout={layout} className="checkbox-group">
        {options.map((option) => (
          <Checkbox
            onChange={handleCheckboxChange(option.value)}
            key={option.value}
            label={option.label}
            isDisabled={isDisabled || option.isDisabled}
            isChecked={(value ?? []).includes(option.value)}
            {...controlProps}
            {...inputProps}
            data-test-id={`field-${controlProps.name}`}
          />
        ))}
      </List>
    </FormField>
  );
};
