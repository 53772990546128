import { errorHandler } from '@services/api/helpers';
import { actionItemApi } from '@services/api/actionItemApi';
import { useMutation } from 'react-query';
import { useToast } from '@hooks/useToast';
import { useSelector } from 'react-redux';
import { selectWorkspaceId } from '@state/selectors';
import { File as GqlFile } from '@generated/types/graphql';

export const useUploadActionItemDoc = () => {
  const { showSuccess } = useToast();
  const companyId = useSelector(selectWorkspaceId);

  return useMutation<GqlFile, Error, { actionId: number; file: File }>(
    async ({ actionId, file }) => {
      try {
        const result = await actionItemApi.uploadFile({ actionId, file, companyId });

        return result.data;
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: () => {
        showSuccess('Action Item updated successfully');
      }
    }
  );
};
