import styled from 'styled-components';

export const Container = styled.div<{ color: string; backgroundColor: string; borderColor?: string }>`
  padding: 3px;
  border-radius: 4px;
  border: 1px solid;
  border-color: ${({ borderColor = 'transparent' }) => borderColor};
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.6px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
`;
