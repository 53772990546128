import { useCreateActionItem } from '@hooks/actionItems/useCreateActionItem';
import { Form, FormValidationRules, SelectField, useForm } from '@kit/components/Form';
import React, { useMemo } from 'react';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { useActionTemplateList } from '@hooks/templates/actions/useActionTemplateList';
import { Fields, FooterActions } from './styled';

interface FormValues {
  project: { id: number; title: string };
  template: { id: number; title: string };
}

interface Props {
  initialRecord: { id: number; title: string };
  onClose: (newId: number | void) => void;
}

export const CreateActionItemForm = ({ initialRecord, onClose }: Props) => {
  const { mutateAsync: create } = useCreateActionItem();

  const { data: templates = [] } = useActionTemplateList();

  const postForm = async (values: FormValues) => {
    const newId = await create({
      projectId: values.project.id,
      actionTemplateId: values.template.id
    });

    onClose(newId);
  };

  const { handleSubmit, form } = useForm<FormValues>({
    onSubmit: postForm,
    defaultValues: {
      project: initialRecord,
      template: null
    }
  });

  const rules = useMemo<FormValidationRules<FormValues>>(
    () => ({
      project: {
        isRequired: true
      },
      template: {
        isRequired: true
      }
    }),
    []
  );

  const {
    formState: { isSubmitting, isDirty },
    control
  } = form;

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <Fields>
        <SelectField
          options={[]}
          disabled
          label="Project"
          getOptionLabel={(option) => option.title}
          control={control}
          name="project"
        />
        <SelectField
          options={templates}
          getOptionLabel={(option) => option.title}
          label="Action Item Template"
          control={control}
          name="template"
        />
      </Fields>
      <FooterActions>
        <Button variant={ButtonVariant.Secondary} onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={isSubmitting || !isDirty} type="submit" variant={ButtonVariant.Primary}>
          Create
        </Button>
      </FooterActions>
    </Form>
  );
};
