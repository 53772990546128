import * as Yup from 'yup';

export const schema = Yup.object({
  $startDate: Yup.mixed().test((value) => {
    return value === 'custom'
      || !!value;
  }),
  customStartDate: Yup.date().test('customStartDate', 'Start date is required', function test(value) {
    const { $startDate } = this.parent;

    if ($startDate === 'custom' && !value) {
      return false;
    }

    return true;
  }),
  customEndDate: Yup.date().test('customEndDate', 'End date must be later than start date', function test(value) {
    const { $startDate, customStartDate } = this.parent;

    if ($startDate === 'custom' && (!value || +(new Date(customStartDate)) > +(new Date(value)))) {
      return false;
    }

    return true;
  })
});
