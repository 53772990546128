import { StandardPropertyId, TimeGranularity } from '@features/Analytics/types';
import { ProjectReportGroupBy } from '@generated/types/graphql';

export const TIME_GRANULARITY_TO_CREATED_AT_GROUP_BY: Record<TimeGranularity, ProjectReportGroupBy> = {
  [TimeGranularity.DAY]: ProjectReportGroupBy.CreatedAtTruncatedToDay,
  [TimeGranularity.WEEK]: ProjectReportGroupBy.CreatedAtTruncatedToWeek,
  [TimeGranularity.MONTH]: ProjectReportGroupBy.CreatedAtTruncatedToMonth,
  [TimeGranularity.QUARTER]: ProjectReportGroupBy.CreatedAtTruncatedToQuarter,
  [TimeGranularity.YEAR]: ProjectReportGroupBy.CreatedAtTruncatedToYear
};

export const TIME_GRANULARITY_TO_TIMELINE_STATUS_STARTED_AT_GROUP_BY: Record<TimeGranularity, ProjectReportGroupBy> = {
  [TimeGranularity.DAY]: ProjectReportGroupBy.TimelineStatusStartedAtTruncatedToDay,
  [TimeGranularity.WEEK]: ProjectReportGroupBy.TimelineStatusStartedAtTruncatedToWeek,
  [TimeGranularity.MONTH]: ProjectReportGroupBy.TimelineStatusStartedAtTruncatedToMonth,
  [TimeGranularity.QUARTER]: ProjectReportGroupBy.TimelineStatusStartedAtTruncatedToQuarter,
  [TimeGranularity.YEAR]: ProjectReportGroupBy.TimelineStatusStartedAtTruncatedToYear
};

export const TIME_GRANULARITY_TO_TIMELINE_STATUS_ENDED_AT_GROUP_BY: Record<TimeGranularity, ProjectReportGroupBy> = {
  [TimeGranularity.DAY]: ProjectReportGroupBy.TimelineStatusEndedAtTruncatedToDay,
  [TimeGranularity.WEEK]: ProjectReportGroupBy.TimelineStatusEndedAtTruncatedToWeek,
  [TimeGranularity.MONTH]: ProjectReportGroupBy.TimelineStatusEndedAtTruncatedToMonth,
  [TimeGranularity.QUARTER]: ProjectReportGroupBy.TimelineStatusEndedAtTruncatedToQuarter,
  [TimeGranularity.YEAR]: ProjectReportGroupBy.TimelineStatusEndedAtTruncatedToYear
};

export const TIME_GRANULARITY_TO_TIMELINE_STAGE_STARTED_AT_GROUP_BY: Record<TimeGranularity, ProjectReportGroupBy> = {
  [TimeGranularity.DAY]: ProjectReportGroupBy.TimelineStageStartedAtTruncatedToDay,
  [TimeGranularity.WEEK]: ProjectReportGroupBy.TimelineStageStartedAtTruncatedToWeek,
  [TimeGranularity.MONTH]: ProjectReportGroupBy.TimelineStageStartedAtTruncatedToMonth,
  [TimeGranularity.QUARTER]: ProjectReportGroupBy.TimelineStageStartedAtTruncatedToQuarter,
  [TimeGranularity.YEAR]: ProjectReportGroupBy.TimelineStageStartedAtTruncatedToYear
};

export const TIME_GRANULARITY_TO_TIMELINE_STAGE_ENDED_AT_GROUP_BY: Record<TimeGranularity, ProjectReportGroupBy> = {
  [TimeGranularity.DAY]: ProjectReportGroupBy.TimelineStageEndedAtTruncatedToDay,
  [TimeGranularity.WEEK]: ProjectReportGroupBy.TimelineStageEndedAtTruncatedToWeek,
  [TimeGranularity.MONTH]: ProjectReportGroupBy.TimelineStageEndedAtTruncatedToMonth,
  [TimeGranularity.QUARTER]: ProjectReportGroupBy.TimelineStageEndedAtTruncatedToQuarter,
  [TimeGranularity.YEAR]: ProjectReportGroupBy.TimelineStageEndedAtTruncatedToYear
};

export const STANDARD_DROPDOWN_PROPERTY_ID_TO_GROUP_BY: Record<number, ProjectReportGroupBy> = {
  [StandardPropertyId.CLIENT_TYPE]: ProjectReportGroupBy.AccountType,
  [StandardPropertyId.CLIENT_STATUS]: ProjectReportGroupBy.AccountStatus,
  [StandardPropertyId.STAGE]: ProjectReportGroupBy.StageId,
  [StandardPropertyId.TRADES]: ProjectReportGroupBy.Trade,
  [StandardPropertyId.REQUEST_STATUS]: ProjectReportGroupBy.RequestStatus,
  [StandardPropertyId.PROJECT_STATUS]: ProjectReportGroupBy.Status,
  [StandardPropertyId.LAST_INBOUND_CALL_DISPOSITION]: ProjectReportGroupBy.LastInboundCallDisposition,
  [StandardPropertyId.LAST_OUTBOUND_CALL_DISPOSITION]: ProjectReportGroupBy.LastOutboundCallDisposition
};
