import styled from 'styled-components';
import { tag } from '@styles';
import { X } from 'react-feather';

export const Wrapper = styled.div<{ type: string }>`
  ${tag.wrapper};
`;

export const Text = styled.div`
  ${tag.text};
`;

export const Icon = styled.div<{size: number}>`
  ${tag.icon};
`;

export const User = styled.div`
  ${tag.user};
`;

export const Remove = styled(X)`
  ${tag.remove};
`;
