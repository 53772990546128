import { SubjectAccess } from '@common/RoleAccess';
import { ExportModal } from '@components/Project/ProjectHeader/ExportModal';
import { useAppDispatch, useAppSelector } from '@hooks/store';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { selectCompanies, selectSettingsByCompanyId, selectWorkspaceId, selectUser } from '@state/selectors';
import { getAvatarColors } from '@utils/colors';
import { getInitials } from '@utils/utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ChevronDown, ChevronUp, Download, Settings, UploadCloud, UserPlus } from 'react-feather';
import { Modal } from '@common/ModalMui';
import { useAuth } from '@hooks/useAuth';
import { useEffectOnceBy } from '@hooks/useEffectOnceBy';
import { isLoggedIn } from '@services/AuthenticationServices/AuthenticationService';
import { fetchCompanies } from '@state/actions/companies';
import UserPreferences from '@services/UserPreferences';
import { Company, RecordType } from '@types';
import { navigateToRecordListing } from '@state/actions/ui';
import { useModal } from '@common/PromiseModal';
import { UserForm } from '@features/Platform/Users/Form';
import {
  CurrentWorkspace,
  CurrentWorkspaceName,
  Popover,
  Menu,
  WorkspaceIcon,
  WorkspaceName,
  WorkspaceList
} from './styled';
import { NavLink } from '../styled';

interface Props {
  isDrawerOpen: boolean;
}

const useWorkspaces = () => {
  const user = useAppSelector(selectUser);
  const workspaces = useAppSelector(selectCompanies);

  const currentWorkspaceId = useAppSelector(selectWorkspaceId);
  const dispatch = useAppDispatch();

  const isUserInformationLoaded = user && user.userId;

  const signedIn = isLoggedIn() && isUserInformationLoaded;

  useEffectOnceBy(
    () => {
      dispatch(fetchCompanies());
    },
    () => !!signedIn,
    [dispatch, signedIn]
  );

  const handleSwitchWorkspace = useCallback(
    (workspace: Company) => {
      UserPreferences.selectedCompanyId.set(workspace.id);
      dispatch(navigateToRecordListing(workspace.id, RecordType.PROJECT));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!workspaces?.length) {
      return;
    }

    if (!workspaces.some(({ id }) => id === currentWorkspaceId)) {
      handleSwitchWorkspace(workspaces[0]);
    }
  }, [workspaces, currentWorkspaceId, handleSwitchWorkspace]);

  const currentWorkspace = useMemo(() => {
    if (!workspaces?.length) {
      return null;
    }

    if (workspaces.length === 1) {
      return workspaces[0];
    }

    return workspaces.find(({ id }) => currentWorkspaceId === id) ?? workspaces[0];
  }, [workspaces, currentWorkspaceId]);

  return {
    currentWorkspace,
    workspaces,
    onSwitchWorkspace: handleSwitchWorkspace
  };
};

export const WorkspaceSettings = ({ isDrawerOpen }: Props) => {
  const user = useAppSelector(selectUser); // suspension bypass for @coperniq.io users

  const { currentWorkspace, workspaces, onSwitchWorkspace } = useWorkspaces();

  const currentWorkspaceId = currentWorkspace?.id;

  const { isAdmin } = useAuth(currentWorkspaceId);

  const settings = useAppSelector(selectSettingsByCompanyId(currentWorkspaceId));

  const [exportModal, setExportModal] = useState(false);

  const { openModal } = useModal();

  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleInvite = useCallback(() => {
    openModal<void>(({ onClose }) => <UserForm onClose={onClose} />, { title: 'Invite user' });
  }, [openModal]);

  const open = Boolean(anchorEl);

  if (!currentWorkspace) {
    return null;
  }

  return (
    <>
      <CurrentWorkspace onClick={handleClick}>
        <WorkspaceIcon
          imageUrl={currentWorkspace.logoUrl}
          withBorder
          size="28px"
          colors={getAvatarColors(currentWorkspace.name)}
        >
          {!currentWorkspace.logoUrl && getInitials(currentWorkspace.name)}
        </WorkspaceIcon>

        <CurrentWorkspaceName isDrawerOpen={isDrawerOpen}>{currentWorkspace.name}</CurrentWorkspaceName>
        {open ? <ChevronUp size="20px" /> : <ChevronDown size="20px" />}
      </CurrentWorkspace>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Menu>
          {workspaces.length > 0 && (
            <WorkspaceList>
              {workspaces.map((workspace) => (
                <ListItem
                  button
                  key={workspace.id}
                  selected={workspace.id === currentWorkspaceId}
                  onClick={() => onSwitchWorkspace(workspace)}
                >
                  <ListItemIcon>
                    <WorkspaceIcon
                      imageUrl={workspace.logoUrl}
                      withBorder={workspace.id === currentWorkspaceId}
                      size="24px"
                      colors={getAvatarColors(workspace.name)}
                    >
                      {!workspace.logoUrl && getInitials(workspace.name)}
                    </WorkspaceIcon>
                  </ListItemIcon>
                  <WorkspaceName>{workspace.name}</WorkspaceName>
                </ListItem>
              ))}
            </WorkspaceList>
          )}
          <List>
            <SubjectAccess subject="workspace" scope="import" privilege="view">
              <ListItem button onClick={() => setExportModal(true)}>
                <ListItemIcon>
                  <Download size="16px" />
                </ListItemIcon>
                <ListItemText primary="Export records" />
              </ListItem>
            </SubjectAccess>
            <SubjectAccess subject="workspace" scope="import" privilege="view">
              <NavLink to={`/${currentWorkspaceId}/import`}>
                <ListItem button>
                  <ListItemIcon>
                    <UploadCloud size="16px" />
                  </ListItemIcon>
                  <ListItemText primary="Import records" />
                </ListItem>
              </NavLink>
            </SubjectAccess>
            <SubjectAccess subject="workspace" scope="settings" privilege="view">
              <NavLink to={`/${currentWorkspaceId}/workspace/general`}>
                <ListItem button>
                  <ListItemIcon>
                    <Settings size="16px" />
                  </ListItemIcon>
                  <ListItemText primary="Workspace settings" />
                </ListItem>
              </NavLink>
            </SubjectAccess>
            <SubjectAccess subject="workspace" scope="invite" privilege="view">
              <ListItem button onClick={handleInvite}>
                <ListItemIcon>
                  <UserPlus size="16px" />
                </ListItemIcon>
                <ListItemText primary="Invite members" />
              </ListItem>
            </SubjectAccess>
          </List>
        </Menu>
      </Popover>

      {exportModal && <ExportModal open={exportModal} onClose={() => setExportModal(false)} />}

      {settings?.suspended &&
        !user?.email?.includes('@coperniq.io') && ( // suspension bypass for @coperniq.io users
          <Modal open title="Workspace suspended" onClose={(event) => event.preventDefault()}>
            {isAdmin ? (
              <>
                Your workspace has been suspended for non-payment. Please contact&nbsp;
                <a href="mailto:team@coperniq.io">team@coperniq.io</a> or&nbsp;
                <a href="mailto:accounting@coperniq.io">accounting@coperniq.io</a>.
              </>
            ) : (
              <>Your workspace has been suspended. Please contact your workspace admins.</>
            )}
          </Modal>
        )}
    </>
  );
};
