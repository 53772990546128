import React from 'react';
import { Skeleton } from '@material-ui/lab';

import { Container, Info } from './styled';

export const HeaderSkeleton = () => {
  return (
    <Container>
      <Skeleton variant="rect" animation="wave" width={88} height={88} />

      <Info>
        <Skeleton animation="wave" width={120} height={50} />

        <Skeleton animation="wave" height={66} />
      </Info>
    </Container>
  );
};
