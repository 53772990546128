import React, { useState, useCallback, useRef } from 'react';
import { SearchBar } from '@common/SearchBar';
import { useClickOutside, useUpdateEffect } from '@react-hookz/web';
import { useDebouncedState } from '@hooks/useDebouncedState';
import { Container } from './styled';

interface Props {
  onChange: (value: string) => void;
}

export const Search = ({ onChange }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [, debouncedSearch, setSearch] = useDebouncedState('', 300);

  const valueRef = useRef('');

  const nodeRef = useRef(null);

  useUpdateEffect(() => {
    onChange(debouncedSearch);
  }, [debouncedSearch, onChange]);

  const expand = useCallback(() => {
    setIsOpen(true);
  }, []);

  useClickOutside(nodeRef, () => {
    if (!valueRef.current) {
      setIsOpen(false);
    }
  });

  return (
    <Container ref={nodeRef} isOpen={isOpen} onClick={expand}>
      <SearchBar placeholder="Search..." onValueChange={setSearch} />
    </Container>
  );
};
