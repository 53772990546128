import React, { useEffect, useMemo, useRef } from 'react';
import { CommentType, File } from '@generated/types/graphql';
import { useIntersectionObserver } from '@react-hookz/web/esm/useIntersectionObserver';
import { useActivity } from '@hooks/useActivity';
import { EntityType, FileEventType, ReactQueryKey } from '@enums';
import { flatPagesData } from '@utils/reactQuery';
import { StateMessege } from '@common/index';
import { folderEmpty } from '@assets/svg';

import { FeedItem } from '@features/ProjectPortfolio/Project/History/FeedItem';
import { List } from 'react-feather';
import { QueryParamsEnum, useQueryParam, useQueryParamMutation } from '@hooks/useQueryParam';
import { useUnmountEffect, useUpdateEffect } from '@react-hookz/web';
import { useQueryClient } from 'react-query';
import { CommentForm } from '@features/ClientPortfolio/Client/CreateEventsPanel/forms/CommentForm';
import { PinnedComments } from '@features/ProjectPortfolio/Project/History/PinnedComments';
import { Activity, Title, TitleIcon, HistoryContainer, FormContainer, Container, Header } from './styled';

interface Props {
  file: File;
  isCollapsed: boolean;
}

export const Feed = ({ file, isCollapsed }: Props) => {
  const loadingNodeRef = useRef<HTMLDivElement>();
  const loadingTopNodeRef = useRef<HTMLDivElement>();

  const loadingRef = useRef<boolean>(false);
  const isLoadPreviousEnabled = useRef<boolean>(true);
  const isScrolledToFeedItem = useRef<boolean>(false);
  const { setParams } = useQueryParamMutation();

  const [feedCursor] = useQueryParam(QueryParamsEnum.FeedCursor);
  const [feedId] = useQueryParam(QueryParamsEnum.FeedId);
  const entry = useIntersectionObserver(loadingNodeRef.current);
  const topEntry = useIntersectionObserver(loadingTopNodeRef.current);
  const queryClient = useQueryClient();
  const { data, isLoading, fetchNextPage, fetchPreviousPage, hasNextPage, hasPreviousPage } = useActivity({
    recordId: file.projectId,
    fileId: file.id,
    types: [
      {
        entity: EntityType.FILE,
        event: FileEventType.COMMENTED
      }
    ],
    equalOrBeforeDate: feedCursor ? decodeURIComponent(feedCursor) : undefined
  });

  const flatFeed = useMemo(() => {
    return flatPagesData(data);
  }, [data]);

  useUpdateEffect(() => {
    if (feedCursor) {
      queryClient.removeQueries([ReactQueryKey.ProjectActivity]);
    }
  }, [feedCursor]);

  useUnmountEffect(() => {
    if (feedCursor) {
      queryClient.removeQueries([ReactQueryKey.ProjectActivity]);

      setParams(
        {
          [QueryParamsEnum.FeedCursor]: undefined,
          [QueryParamsEnum.FeedId]: undefined
        },
        true
      );
    }
  });

  useEffect(() => {
    if (entry?.isIntersecting && hasNextPage) {
      if (!loadingRef.current) {
        loadingRef.current = true;
        fetchNextPage().finally(() => {
          loadingRef.current = false;
        });
      }
    }
  }, [entry, hasNextPage, data, fetchNextPage]);

  useEffect(() => {
    if (feedId && feedCursor && topEntry?.isIntersecting && hasPreviousPage && isLoadPreviousEnabled.current) {
      if (!loadingRef.current) {
        loadingRef.current = true;
        fetchPreviousPage().finally(() => {
          loadingRef.current = false;

          const node = document.getElementById(`feed_${feedId}`);

          if (node && !isScrolledToFeedItem.current) {
            isLoadPreviousEnabled.current = false;
            node.scrollIntoView();
            isScrolledToFeedItem.current = true;

            setTimeout(() => {
              isLoadPreviousEnabled.current = true;
            }, 1000);
          }
        });
      }
    }
  }, [feedId, feedCursor, topEntry, flatFeed, data, fetchPreviousPage, hasPreviousPage]);

  return (
    <HistoryContainer>
      <Header>
        <Title isRotated={isCollapsed}>
          <TitleIcon>
            <List size="16px" color="#B04EC2" />
          </TitleIcon>
          Feed
        </Title>
      </Header>
      <FormContainer>
        <CommentForm
          expandSize="small"
          size="small"
          type={CommentType.File}
          fileId={file.id}
          recordId={file.projectId}
        />
      </FormContainer>
      <Container>
        <Activity>
          <PinnedComments fileId={file.id} />
          {flatFeed.length === 0 && !isLoading ? (
            <StateMessege img={folderEmpty} subTitle="Looks like there's no activity here yet." />
          ) : (
            <>
              <p ref={loadingTopNodeRef} />
              {flatFeed.map((item) => (
                <FeedItem contextEntityId={file.id} key={item.id} item={item} context="file" />
              ))}

              <p ref={loadingNodeRef} />
            </>
          )}
        </Activity>
      </Container>
    </HistoryContainer>
  );
};
