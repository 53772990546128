import React, { useEffect, useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { autocomplete } from '@common/ui/Autocomplete/styled';
import { ChevronDown } from 'react-feather';
import { TextField } from '@common/ui';
import { CatalogItem } from '@generated/types/graphql';
import { debounce } from 'lodash/fp';
import { useCatalogItems } from '@hooks';
import { Pagination } from '@common/Table';
import { CatalogType } from '@types';
import { capitalize, lowerCase } from 'lodash';

const styles = autocomplete({});
const useStyles = makeStyles(styles);

interface CatalogItemSelectProps {
  onBlur?: (event: React.FocusEvent<HTMLDivElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onSelect?: (CatalogItem: CatalogItem) => void;
  selectedCatalogItem?: CatalogItem;
  onInputChange?: (value: string) => void;
  inputText?: string;

  error?: string;
  type: CatalogType;
}

export const CatalogItemSelector: React.FC<CatalogItemSelectProps> = ({
  onBlur,
  onSelect,
  onInputChange,
  selectedCatalogItem,
  inputText = selectedCatalogItem?.name ?? '',
  onFocus,
  error,
  type
}: CatalogItemSelectProps) => {
  const classes = useStyles();

  const [inputValue, setInputValue] = useState(inputText);
  const [debouncedValue, setDebouncedValue] = useState(inputValue);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchValueChange = useCallback(
    debounce(300, (value) => {
      onInputChange?.(value);
      setDebouncedValue(value);
    }),
    []
  );

  useEffect(() => {
    handleSearchValueChange(inputValue);
  }, [inputValue]);

  const {
    data: { results: items },
    isLoading
  } = useCatalogItems({
    paging: {
      page: 1,
      perPage: 50
    } as Pagination,
    catalogType: type,
    seeOnlyEnabled: true,
    searchFilter: debouncedValue
  });

  useEffect(() => {
    setInputValue(inputText);
  }, [inputText]);

  const handleOnChange = (event: React.ChangeEvent<{}>, CatalogItem: CatalogItem | null, reason: string) => {
    if (reason !== 'select-option' || CatalogItem === null) {
      return;
    }

    onSelect?.(CatalogItem);
  };

  return (
    <Autocomplete
      filterOptions={(options) => options}
      classes={classes}
      options={items}
      isLoading={isLoading}
      inputValue={inputValue}
      value={selectedCatalogItem}
      onBlur={onBlur}
      getOptionSelected={(option, value) => option.id === value.id}
      onChange={handleOnChange}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={({ target: { value } }) => setInputValue(value)}
          placeholder={`Search for a ${lowerCase(type)}...`}
          onFocus={onFocus}
          error={!!error}
        />
      )}
      popupIcon={<ChevronDown />}
    />
  );
};
