import { useCompanyProperties } from '@hooks/useCompanyProperties';
import { useMemo } from 'react';
import { isDropdown, isReferredBy } from '@utils/properties';
import { useCatalog } from '@hooks/useCatalog';
import { Dictionary } from '@generated/types/graphql';
import { AnalyticsModule, DimensionType } from '../types';
import {
  ANALYTICS_MODULE_RECORD_TYPE_MAP,
  DIMENSION_TYPES_WITH_SUBDIMENSION,
  TIME_SUBDIMENSION_OPTIONS,
  USER_SUBDIMENSION_OPTIONS
} from '../constants';

export const useSubdimensionOptions = (module: AnalyticsModule, dimension?: DimensionType) => {
  const { properties } = useCompanyProperties();
  const { data: catalogs } = useCatalog();

  return useMemo(() => {
    switch (dimension) {
      case DimensionType.TIME:
        return TIME_SUBDIMENSION_OPTIONS;
      case DimensionType.DROPDOWN_PROPERTY:
        return module === AnalyticsModule.WORK_ORDERS
          ? []
          : properties
              .filter(
                (property) =>
                  isDropdown(property) &&
                  !isReferredBy(property) &&
                  property.scope.includes(ANALYTICS_MODULE_RECORD_TYPE_MAP[module])
              )
              .map((property) => ({
                id: `property_${property.id}`,
                name: property.name,
                property
              }));
      case DimensionType.PRODUCT_CATEGORY:
        return catalogs.flatMap((catalog) =>
          catalog.catalogs.map((category) => ({
            id: `${catalog.parentCatalog}_category_${category.id}`,
            name: category.value,
            category
          }))
        );
      case DimensionType.ASSIGNEE:
      case DimensionType.CREATOR:
      case DimensionType.OWNER:
      case DimensionType.PROJECT_MANAGER:
      case DimensionType.SALES_REP:
        return USER_SUBDIMENSION_OPTIONS;

      default:
        return [];
    }
  }, [dimension, properties, catalogs, module]);
};

export const isSubdimensionRequired = (dimension?: DimensionType) => {
  return DIMENSION_TYPES_WITH_SUBDIMENSION.includes(dimension);
};

export const getSubdimensionGroupBy = (subdimension: { category?: Dictionary }) => {
  if (!subdimension.category) {
    return undefined;
  }

  return `${subdimension.category.parentCode}S`;
};
