import { Search } from '@components/Project/ProjectView/types';
import { NotificationSettings } from '@components/templates/Settings/Notification/type';
import { Feed, Paging } from '@types';
import { put, get, post } from './base';
import { Urls } from './base/axios';
import { generateParams, generateQueryParams } from './helpers';
import { ListParams, ReadBatchFeedDTO } from './types';

export enum FilterType {
  project = 'project',
  label = 'label',
  people = 'people'
}

export default {
  fetchNotificationSettings: () => get(`${Urls.notification}/settings`),
  updateNotificationSettings: (notificationSettings: NotificationSettings) =>
    put(`${Urls.notification}/settings`, notificationSettings),
  fetchFeedFiltersByType: (type: FilterType, params: { query: string }) =>
    get(
      `${Urls.notification}/feed/filters`,
      generateQueryParams({
        filters: [
          {
            col: 'type',
            val: type,
            op: '='
          },
          params?.query
            ? {
                col: 'name',
                val: params.query,
                op: 'like'
              }
            : null
        ].filter(Boolean) as Parameters<typeof generateQueryParams>[0]['filters'],
        orders: [
          {
            col: 'name',
            desc: true
          }
        ]
      })
    ),
  getProjectActivities: (projectId: number, search: Partial<Search>, params: ListParams, taskId: number = 0) =>
    get(
      `${Urls.notification}/projects/${projectId}/feeds`,
      generateParams({
        ...generateQueryParams(params, undefined, true),
        ...{ taskId },
        search
      })
    ),

  findUserNotifications: (userId: number, search: Partial<Search>, params: ListParams, body: { [key: string]: any }) =>
    post<Paging<Feed>>(
      `${Urls.notification}/feed/${userId}/all`,
      body,
      generateParams({
        ...generateQueryParams(params, undefined, true),
        search
      })
    ),

  readNotification: (feedId: number) => put(`${Urls.notification}/feed/${feedId}/read`, {}),

  readAllNotifications: () => put(`${Urls.notification}/feed/read`, {}),

  unreadNotificationsCount: () => get<{ unreadCount: number }>(`${Urls.notification}/feed/unread-count`),

  readBatch: (dto: ReadBatchFeedDTO) => put(`${Urls.notification}/feed/batch-read-inbox`, dto)
};
