import { Popover } from '@common/Popover';
import { useConfirmDeleteModal } from '@common/PromiseModal';
import { PrivilegedTask } from '@generated/types/graphql';
import { List, ListItemIcon, ListItemText } from '@material-ui/core';
import { hasEntityAccessNew } from '@utils/roles';
import React, { useMemo } from 'react';
import { Archive, MoreVertical, Trash2 } from 'react-feather';
import { colors } from '@styles';
import tasksApi from '@services/api/tasksApi';
import { useQueryClient } from 'react-query';
import { ReactQueryKey } from '@enums';
import { noop } from 'lodash';
import { useToast } from '@hooks/useToast';
import { useInlineUpdateField } from '../Fields/useInlineUpdateField';
import { MenuItem, MoreMenu } from './styled';

export const useWorkOrderMenuActions = (task: PrivilegedTask, onDeleted: () => void) => {
  const confirmDelete = useConfirmDeleteModal();

  const queryClient = useQueryClient();

  const { showError } = useToast();

  const { isLoading, onSubmit: updateTask } = useInlineUpdateField(task);

  const actions = useMemo(() => {
    return [
      {
        title: 'Archive',
        icon: <Archive size="16px" color="#D54855" />,
        isAllowed: !task.isArchived && hasEntityAccessNew(task, 'delete'),
        onClick: async () => {
          if (isLoading) {
            return;
          }
          if (await confirmDelete(`Are you sure you want to archive Work Order "${task.title}"?`, 'Archive')) {
            await updateTask({ isArchived: true });
          }
        }
      },
      {
        title: 'Unarchive',
        icon: <Archive size="16px" color={colors.green} />,
        isAllowed: task.isArchived && hasEntityAccessNew(task, 'edit'),
        onClick: async () => {
          if (isLoading) {
            return;
          }

          await updateTask({ isArchived: false });
        }
      },
      {
        title: 'Delete forever',
        icon: <Trash2 size="16px" color="#D54855" />,
        isAllowed: task.isArchived && hasEntityAccessNew(task, 'delete'),
        onClick: async () => {
          if (isLoading) {
            return;
          }
          if (await confirmDelete(`Are you sure you want to delete Work Order "${task.title}"?`)) {
            try {
              await tasksApi.deleteTask(task as any);

              queryClient.invalidateQueries([ReactQueryKey.Tasks]);

              onDeleted();
            } catch (error) {
              showError(`Couldn't delete Work Order`);
            }
          }
        }
      }
    ].filter((action) => action.isAllowed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task, confirmDelete, isLoading, queryClient, onDeleted, showError]);

  return actions;
};

interface Props {
  task: PrivilegedTask;
  size?: string;
  className?: string;
  onDeleted?: () => void;
}

export const Actions = ({ task, className, size = '16px', onDeleted = noop }: Props) => {
  const actions = useWorkOrderMenuActions(task, onDeleted);

  if (!actions.length) {
    return null;
  }

  return (
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      content={
        <List disablePadding dense>
          {actions.map((action) => (
            <MenuItem button onClick={action.onClick}>
              <ListItemIcon>{action.icon}</ListItemIcon>
              <ListItemText primary={action.title} />
            </MenuItem>
          ))}
        </List>
      }
    >
      <MoreMenu className={className}>
        <MoreVertical size={size} />
      </MoreMenu>
    </Popover>
  );
};
