import { combineReducers, createStore, applyMiddleware, compose, PreloadedState, Middleware } from 'redux';
import thunk from 'redux-thunk';
import { globalHistory } from '@reach/router';
import { createReduxHistoryContext, reachify } from 'redux-first-history';
import { createBrowserHistory } from 'history';

import { reducers } from './reducers';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const isBrowser = typeof window !== 'undefined';

const { createReduxHistory, routerMiddleware, routerReducer: routerReducerInternal } = createReduxHistoryContext({
  history: isBrowser ? createBrowserHistory() : undefined,
  reachGlobalHistory: globalHistory,
  savePreviousLocations: 1,
});

let composeEnhancers = compose;

if (
  (process.env.NODE_ENV === 'development' || process.env.GATSBY_ACTIVE_ENV === 'develop')
  && isBrowser
) {
  // eslint-disable-next-line no-underscore-dangle
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

export const createAppStore = (state?: PreloadedState<any>, middlewares: Middleware[] = []) => createStore(
  combineReducers({
    router: routerReducerInternal,
    ...reducers,
  }),
  state,
  composeEnhancers(
    applyMiddleware(...[
      ...middlewares,
      thunk,
      isBrowser ? routerMiddleware : null,
    ].filter(Boolean) as any)
  )
);

export const store = createAppStore();

const history = isBrowser ? createReduxHistory(store) : undefined;
export const reachHistory = isBrowser && history ? reachify(history) : undefined;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
