import { colors } from '@styles';
import styled from 'styled-components';

export const Square = styled.div<{ isActive: boolean }>`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;

  color: ${({ isActive }) => (isActive ? colors.green : '#9C9CAA')};
  border: 1px solid ${({ isActive }) => (isActive ? colors.green : '#DFDFE8')};
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out,
    color 0.15s ease-in-out;
  box-shadow: ${({ isActive }) => (isActive ? '0px 0px 16px 0px rgba(0, 150, 136, 0.12)' : 'none')};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  cursor: pointer;

  &:hover {
    ${Square} {
      border-color: #828d9a;
    }
  }
`;

export const Label = styled.label<{ isActive: boolean }>`
  color: ${({ isActive }) => (isActive ? colors.green : '#828D9A')};
  transition: color 0.15s ease-in-out;
  font-size: 10px;
  font-weight: 400;
  width: 60px;
  text-align: center;
`;
