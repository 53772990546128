import { FilterOperators, PropertyType } from '@types';
import { isAddress, isButtonOrLink, isDropdown, isStage, isText } from '@utils/properties';
import { v4 as uuid } from '@lukeed/uuid';
import { useMemo } from 'react';
import {
  isWorkOrderDateFilterField,
  isWorkOrderTextFilterField,
  isWorkOrderTypeFilterField,
  isWorkOrderSingleSelectFilterField
} from '../helpers';
import { FilterField, FilterFieldType } from './types';

const isRegularDropdown = (field: FilterField) =>
  field.type === FilterFieldType.PROPERTY && isDropdown(field.property) && !isStage(field.property);

const isRegularSingleDropdown = (field: FilterField) =>
  field.type === FilterFieldType.PROPERTY && isRegularDropdown(field) && !field.property.multiple;

const isRegularMultipleDropdown = (field: FilterField) =>
  field.type === FilterFieldType.PROPERTY && isRegularDropdown(field) && field.property.multiple;

const isRegularText = (field: FilterField) =>
  field.type === FilterFieldType.PROPERTY &&
  (isText(field.property) || isButtonOrLink(field.property)) &&
  !isAddress(field.property);

const isAddressProperty = (field: FilterField) => field.type === FilterFieldType.PROPERTY && isAddress(field.property);

const isStageProperty = (field: FilterField) => field.type === FilterFieldType.PROPERTY && isStage(field.property);

const isWorkflowField = (field: FilterField) => field.type === FilterFieldType.WORKFLOW;

const isWorkOrderWithTitleField = (field: FilterField) => field.type === FilterFieldType.WORK_ORDER_WITH_TITLE;

const isWorkOrderSingleSelectField = (field: FilterField) => isWorkOrderSingleSelectFilterField(field.id);

const isWorkOrderTextField = (field: FilterField) => isWorkOrderTextFilterField(field.id);

const isWorkOrderTypeField = (field: FilterField) => isWorkOrderTypeFilterField(field.id);

const isWorkOrderDateField = (field: FilterField) => isWorkOrderDateFilterField(field.id);

export const OPERATORS = [
  {
    title: 'is',
    op: FilterOperators.Like,
    scope: [isRegularText, isWorkOrderWithTitleField, isWorkOrderTextField]
  },
  {
    title: 'is',
    op: FilterOperators.EqualTo,
    scope: [PropertyType.Date, PropertyType.DateTime, PropertyType.Numeric, isWorkOrderTypeField, isWorkOrderDateField]
  },
  {
    title: 'is not',
    op: FilterOperators.NotEqualTo,
    scope: [
      isRegularText,
      isWorkOrderWithTitleField,
      PropertyType.Date,
      PropertyType.DateTime,
      PropertyType.Numeric,
      isWorkOrderTextField,
      isWorkOrderDateField
    ]
  },
  {
    title: 'contains',
    op: FilterOperators.ContainsLike,
    scope: [isAddressProperty]
  },
  {
    title: 'is any',
    op: FilterOperators.In,
    scope: [
      PropertyType.Person,
      isStageProperty,
      isRegularSingleDropdown,
      isWorkflowField,
      isWorkOrderSingleSelectField
    ]
  },
  {
    title: 'is not any',
    op: FilterOperators.NotIn,
    scope: [
      PropertyType.Person,
      isStageProperty,
      isRegularSingleDropdown,
      isWorkflowField,
      isWorkOrderSingleSelectField
    ]
  },

  {
    title: 'is any',
    op: FilterOperators.Contains,
    scope: [isRegularMultipleDropdown]
  },
  {
    title: 'is not any',
    op: FilterOperators.NotContains,
    scope: [isRegularMultipleDropdown]
  },
  {
    title: 'is all',
    op: FilterOperators.ContainedBy,
    scope: [isRegularMultipleDropdown]
  },
  {
    title: 'is not all',
    op: FilterOperators.NotContainedBy,
    scope: [isRegularMultipleDropdown]
  },
  {
    title: 'is before',
    op: FilterOperators.Before,
    scope: [PropertyType.Date, PropertyType.DateTime, isWorkOrderDateField]
  },
  {
    title: 'is after',
    op: FilterOperators.After,
    scope: [PropertyType.Date, PropertyType.DateTime, isWorkOrderDateField]
  },
  {
    title: 'is within',
    op: FilterOperators.Within,
    scope: [PropertyType.Date, PropertyType.DateTime, isWorkOrderDateField]
  },
  {
    title: 'is not within',
    op: FilterOperators.NotWithin,
    scope: [PropertyType.Date, PropertyType.DateTime, isWorkOrderDateField]
  },
  {
    title: 'is more than',
    op: FilterOperators.GreaterThan,
    scope: [PropertyType.Numeric]
  },
  {
    title: 'is less than',
    op: FilterOperators.LessThan,
    scope: [PropertyType.Numeric]
  },
  {
    title: 'is empty',
    op: FilterOperators.IsEmpty,
    scope: [PropertyType.File]
  },
  {
    title: 'is not empty',
    op: FilterOperators.IsNotEmpty,
    scope: [PropertyType.File]
  }
].map((operator) => ({ id: uuid(), ...operator }));

export const useFilterOperatorOptions = (filterField: FilterField) => {
  return useMemo(() => {
    if (!filterField) {
      return [];
    }

    return OPERATORS.filter((operator) =>
      operator.scope.some((scope) => {
        if (typeof scope === 'function') {
          return scope(filterField);
        }

        return filterField.type === FilterFieldType.PROPERTY && scope === filterField.property.type;
      })
    );
  }, [filterField]);
};
