import { scroll } from '@styles';
import styled from 'styled-components';

export const HistoryContainer = styled.div`
  display: flex;
  overflow: auto;
  height: auto;
  overflow: hidden;
  flex-direction: column;
  height: 100%;
`;

export const Container = styled.div`
  display: flex;
  overflow: auto;
  height: auto;
  overflow: hidden;
`;

export const Feed = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  ${scroll};

  & > div:first-of-type > * {
    border-top: 0;
  }
`;

export const FormContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  padding: 16px 16px 0 16px;

  > * {
    width: 100%;
  }
`;
