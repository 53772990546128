import React, { useCallback, useMemo } from 'react';
import { PositiveTrendType } from '@features/Analytics/types';
import { GoldMedalIcon } from '@kit/ui/icons/GoldMedal';
import { SilverMedalIcon } from '@kit/ui/icons/SilverMedal';
import { BronzeMedalIcon } from '@kit/ui/icons/BronzeMedal';
import { formatCurrencyWithoutCents, formatNumber } from '@utils/utils';
import { formatDuration } from '@utils/dates';
import { NoData } from './NoData';
import { Container, ContainerInner, Row } from './styled';
import { LeaderboardProps } from '../types';

const top3Emojis = [<GoldMedalIcon size="24px" />, <SilverMedalIcon size="24px" />, <BronzeMedalIcon size="24px" />];

const formatter = {
  number: formatNumber,
  currency: formatCurrencyWithoutCents,
  time: formatDuration
};

export const Leaderboard = ({ numberFormat = 'number', positiveTrendType, points, onClick }: LeaderboardProps) => {
  const top10 = useMemo(() => {
    const sorted = [...points].sort((a, b) => {
      if (positiveTrendType === PositiveTrendType.UP) {
        return b.y - a.y;
      }

      return a.y - b.y;
    });

    return sorted.slice(0, 10);
  }, [points, positiveTrendType]);

  const handleRowClick = useCallback(
    (point) => () => {
      onClick(point);
    },
    [onClick]
  );

  if (!points.length) {
    return <NoData />;
  }

  return (
    <Container>
      <ContainerInner>
        {top10.map((point, index) => (
          <Row key={index} onClick={handleRowClick(point)}>
            <div>{top3Emojis[index] || index + 1}</div>
            <div>{point.x}</div>
            <div>{formatter[numberFormat](point.y)}</div>
          </Row>
        ))}
      </ContainerInner>
    </Container>
  );
};
