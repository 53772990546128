import { Property } from '@types';
import { TIME_IN_STAGE_PROPERTY } from '@components/Project/ProjectHeader/Filters/helpers';
import { isFiles } from '@utils/properties';

const distinctColumns = (columns: Property[]) => {
  const addedColumnIDs: any = {};
  const distinctColumn: Property[] = [];

  columns.forEach((column) => {
    if (addedColumnIDs[column.id]) {
      return;
    }
    distinctColumn.push(column);
    addedColumnIDs[column.id] = true;
  });

  return distinctColumn;
};

export const sortColumnAdapter = (columns: Property[]) => {
  return [
    ...distinctColumns(columns).filter((property) => !isFiles(property) && !property.multiple),
    TIME_IN_STAGE_PROPERTY
  ];
};
