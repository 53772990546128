import React, { useCallback, useMemo, useRef } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { Select, CreatableSelect } from '@kit/ui/Select';
import { identity, isPlainObject } from 'lodash';
import { useClickOutside } from '@react-hookz/web';
import { FormField } from '../FormField';
import { FormControl, FormInputPropsToOmit } from '../types';
import { useControllerWithValidation } from '../useControllerWithValidation';
import { useInlineEditableField } from './useInlineEditableField';

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends Omit<React.ComponentProps<typeof Select>, FormInputPropsToOmit>,
    FormControl<TFieldValues, TName> {
  isMulti?: boolean;
  isCreatable?: boolean;
  getOptionValue?: (option: any) => any;
  renderView: (value: string, onClick: () => void) => React.ReactNode;
  onChanged?: () => void;
}

const SelectFieldInner = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  label,
  description,
  name,
  control,
  getOptionValue = identity,
  options,
  isCreatable,
  clearOnUnmount,
  renderView,
  onChanged,
  ...inputProps
}: Props<TFieldValues, TName>) => {
  const focusRef = useRef<boolean>(false);
  const { isEditMode, onEnterEditMode, onExitEditMode } = useInlineEditableField(onChanged);
  const nodeRef = useRef<HTMLDivElement>(null);

  const handleFocus = useCallback(() => {
    focusRef.current = true;
  }, []);

  const handleBlur = useCallback(() => {
    focusRef.current = false;
    onExitEditMode();
  }, [onExitEditMode]);

  useClickOutside(nodeRef, () => {
    if (!focusRef.current) {
      onExitEditMode();
    }
  });

  const {
    field: { value, onChange, ...controlProps },
    fieldState: { error }
  } = useControllerWithValidation(name, control, label, clearOnUnmount);

  const handleChange = useCallback(
    (_: any, newValue: unknown) => {
      onChange(newValue);
    },
    [onChange]
  );

  const valueForSelect = useMemo(() => {
    const getOptionByValue = (optionValue: any) => {
      if (isPlainObject(optionValue)) {
        return optionValue;
      }

      return options.find((option) => getOptionValue(option) === optionValue);
    };

    if (inputProps.isMulti) {
      const valueOrEmptyArray = value ?? [];

      return (Array.isArray(valueOrEmptyArray) ? valueOrEmptyArray : [value]).map(getOptionByValue);
    }

    return getOptionByValue(value) ?? null;
  }, [inputProps.isMulti, value, getOptionValue, options]);

  const SelectComponent = isCreatable ? CreatableSelect : Select;

  return (
    <FormField ref={nodeRef} name={name} label={label} error={error?.message} description={description}>
      {!isEditMode && renderView(value, onEnterEditMode)}
      {isEditMode && (
        <SelectComponent
          options={options}
          {...controlProps}
          {...inputProps}
          value={valueForSelect}
          data-test-id={`field-${controlProps.name}`}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      )}
    </FormField>
  );
};

export const InlineEditableSelectField = React.memo(SelectFieldInner) as typeof SelectFieldInner;
