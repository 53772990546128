import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { filter, get } from 'lodash';
import * as yup from 'yup';

import { useAppSelector, usePropertyMappedName } from '@hooks';
import { CancelButton, Modal, SubmitButton } from '@components/common/ModalMui';
import { PropertyField, PropertyTypeSelect, WorkflowSelect } from '@common';
import { SelectFormik } from '@common/ui';
import { makeConfirmAction } from '@utils';
import { RecordType, WorkspaceStageFromAPI } from '@types';
import { selectWorkspaceId } from '@state/selectors';
import ProjectStageSelect from '@common/ProjectEditor/FieldComponents/ProjectStageSelect';

import { Grid, Item } from './styled';

type Values = { fieldId: number; value: any; target: number | null };

const EDIT_TARGET_OPTIONS = [
  { id: 0, name: 'Update property', for: [RecordType.ACCOUNT, RecordType.DEAL, RecordType.PROJECT] },
  { id: -1, name: 'Update workflow', for: [RecordType.DEAL, RecordType.PROJECT] },
  { id: -2, name: 'Update stage', for: [RecordType.DEAL, RecordType.PROJECT] }
];

const RECORD_TYPE_NAMES = {
  [RecordType.PROJECT]: 'Project',
  [RecordType.DEAL]: 'Request'
};

const isWorkflowUpdateState = ({ target }: Partial<Values>) => target === EDIT_TARGET_OPTIONS[1].id;
const isUpdatePropertyState = ({ target }: Partial<Values>) => target === EDIT_TARGET_OPTIONS[0].id;
const isTransitionStageState = ({ target }: Partial<Values>) => target === EDIT_TARGET_OPTIONS[2].id;

interface BulkModalProps {
  open: boolean;
  recordType: RecordType;
  onClose: () => void;
  onSubmit: (form: { fieldId: number; value: number }) => Promise<void>;
}

const transactionStateValidationSchema = yup.object().shape({
  value: yup.mixed().when('target', {
    is: (target) => isTransitionStageState({ target }),
    then: yup.number().typeError('Stage must be number')
  })
});

export const BulkModal: React.FC<BulkModalProps> = (props) => {
  const { open, onClose, onSubmit, recordType } = props;

  const {
    data: { id: blueprintFieldId }
  } = usePropertyMappedName('blueprintId');

  const {
    data: { id: stageFieldId }
  } = usePropertyMappedName('stageId');

  const [selectedStage, setSelectedStage] = useState<WorkspaceStageFromAPI>();
  const companyId = useAppSelector(selectWorkspaceId);

  const initialValues = {
    target: null
  } as Values;

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={transactionStateValidationSchema}>
      {({ values, handleSubmit: $handleSubmit, setFieldValue, setErrors }) => {
        const handleSubmit = async () => {
          if (isWorkflowUpdateState(values)) {
            makeConfirmAction($handleSubmit)({
              message: `Please note that updating the Workflow will keep existing Work Orders in the same ${RECORD_TYPE_NAMES[recordType]} Stages, as long as they exist in the new Workflow. Otherwise they will appear in the first ${RECORD_TYPE_NAMES[recordType]} Stage of the new Workflow. Existing Docs will remain in the same ${RECORD_TYPE_NAMES[recordType]} Stage Folders, as long as they exist as well. Otherwise they will be moved to the "Other" Folder. This action can not be undone.`,
              submitButtonTitle: 'UPDATE'
            });

            return;
          }

          if (isTransitionStageState(values)) {
            makeConfirmAction($handleSubmit)({
              message: `Please note that if a project doesn’t have <b>${
                selectedStage?.name ?? 'None'
              }</b> in its workflow, 
                this action will interrupt the workflow. This action can not be undone.`,
              submitButtonTitle: 'CONFIRM'
            });

            return;
          }

          $handleSubmit();
        };

        const handleTargetChange = ({ target }: any) => {
          const isWorkflow = isWorkflowUpdateState({ target: target.value });
          const isTransitionStage = isTransitionStageState({
            target: target.value
          });

          if (isWorkflow) {
            setFieldValue('fieldId', blueprintFieldId);
          } else if (isTransitionStage) {
            setFieldValue('fieldId', stageFieldId);
          } else {
            setFieldValue('fieldId', null);
          }

          setFieldValue('value', null);
          setErrors({});
        };

        const propertyId = get(values, 'fieldId');

        return (
          <Modal
            open={open}
            onClose={onClose}
            title="Bulk edit"
            footerButtons={[
              <CancelButton onClick={onClose} />,
              <SubmitButton data-analyticsid="updateBulkProjects" onClick={handleSubmit} text="Save" />
            ]}
          >
            <Form>
              <Grid>
                <Item>
                  <SelectFormik
                    label="Bulk edit type"
                    options={filter(EDIT_TARGET_OPTIONS, ($o) => $o.for.includes(recordType))}
                    onChange={handleTargetChange}
                    name="target"
                  />
                </Item>
                {values.target !== null ? (
                  <>
                    <Item>
                      {isWorkflowUpdateState(values) && (
                        <WorkflowSelect name="value" label="New workflow" recordType={recordType} required />
                      )}
                      {isUpdatePropertyState(values) && (
                        <PropertyTypeSelect name="fieldId" label="Property" recordType={recordType} />
                      )}
                      {isTransitionStageState(values) && (
                        <ProjectStageSelect
                          name="value"
                          recordType={recordType}
                          label="New stage"
                          companyId={companyId}
                          onChange={(event, el, stage) => setSelectedStage(stage)}
                          required
                        />
                      )}
                    </Item>
                    {isWorkflowUpdateState(values) && (
                      <Item>
                        <ProjectStageSelect
                          name="stageId"
                          recordType={recordType}
                          label="New stage"
                          companyId={companyId}
                          blueprintId={+values.value}
                          onChange={(event, el, stage) => setSelectedStage(stage)}
                          required
                          hideNonblueprintStages
                        />
                      </Item>
                    )}
                  </>
                ) : null}
                {isUpdatePropertyState(values) && propertyId && (
                  <Item>
                    <PropertyField name="value" label="Value" propertyId={propertyId} />
                  </Item>
                )}
              </Grid>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};
