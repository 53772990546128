import { useWorkflowList } from '@hooks/workflows/useWorkflowList';
import { useMemo } from 'react';

export const useStageIdsByWorkflowMap = () => {
  const { data: workflows = [], isLoading } = useWorkflowList();

  const stageIdsByWorkflowMap = useMemo(
    () =>
      workflows.reduce(
        (acc, workflow) => {
          acc[workflow.id] = workflow.blueprintProjectStages.map(({ projectStage }) => projectStage.id);

          return acc;
        },
        {} as Record<number, number[]>
      ),
    [workflows]
  );

  return {
    stageIdsByWorkflowMap,
    isWorkflowsLoading: isLoading
  };
};
