import { useMutation, useQueryClient } from 'react-query';
import { callApi } from '@services/api/callApi';
import { errorHandler } from '@services/api/helpers';
import { ReactQueryKey } from '@enums';
import { useToast } from '..';

export const useDeleteCall = () => {
  const { showSuccess } = useToast();
  const queryClient = useQueryClient();

  return useMutation<void, Error, number>(
    async (id) => {
      try {
        await callApi.delete(id);
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: () => {
        showSuccess('Call successfully deleted');
        queryClient.invalidateQueries(ReactQueryKey.ProjectActivity);
      }
    }
  );
};
