import React from 'react';
import { PieChart as RechartsPieChart, ResponsiveContainer, Pie, Cell } from 'recharts';

import { NoDataContainer, NoDataText } from './styled';

const POINTS = [
  {
    x: 1,
    y: 30
  },
  {
    x: 2,
    y: 30
  },
  {
    x: 3,
    y: 50
  },
  {
    x: 4,
    y: 50
  },
  {
    x: 5,
    y: 65
  },
  {
    x: 10,
    y: 15
  },
  {
    x: 11,
    y: 65
  },
  {
    x: 12,
    y: 65
  }
];

interface Props {
  innerRadius?: number | string;
}

export const NoData = ({ innerRadius }: Props) => {
  return (
    <NoDataContainer>
      <ResponsiveContainer width="100%" height="100%">
        <RechartsPieChart
          width={500}
          height={300}
          data={POINTS}
          margin={{
            top: 16,
            right: 16,
            left: 16,
            bottom: 16
          }}
        >
          <Pie
            dataKey="y"
            nameKey="x"
            isAnimationActive={false}
            data={POINTS}
            cx="50%"
            cy="50%"
            outerRadius="70%"
            innerRadius={innerRadius}
            fill="#F7F7FC"
          >
            {POINTS.map((entry, index) => (
              <Cell key={`cell-${index}`} fill="#F7F7FC" />
            ))}
          </Pie>
        </RechartsPieChart>
      </ResponsiveContainer>

      <NoDataText>No data to visualize yet</NoDataText>
    </NoDataContainer>
  );
};
