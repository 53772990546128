import styled from 'styled-components';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  grid-template-rows: 1fr 1fr;
  padding-bottom: 30px;
`;

export const Item = styled.div`
  margin-bottom: 10px;
`;
