import styled from 'styled-components';

export const Description = styled.div`
  font-size: 14px;
  margin-bottom: 16px;

  b {
    font-weight: 500;
  }
`;

export const Label = styled.label`
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #828d9a;
  display: flex;
  align-items: center;
  gap: 4px;
`;
