import styled from 'styled-components';

const highlightAnimation = (endColor = 'transparent') => css`
  background-color: #e3f6f4;
  animation: backgroundChange 5s forwards;
  animation-delay: 3s;
  @keyframes backgroundChange {
    from {
      background-color: #e3f6f4;
    }
    to {
      background-color: ${endColor};
    }
  }
`;

export const BorderContainer = styled.div`
  padding: 16px 0px;
  border-top: 1px solid #dfdfe8;

  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

export const Container = styled.div<{ isHighlighted?: boolean; withGrayBg?: boolean }>`
  padding: 0 16px;
  background-color: ${({ withGrayBg }) => (withGrayBg ? '#F7F7FC' : 'transparent')};
  ${({ isHighlighted, withGrayBg }) => isHighlighted && highlightAnimation(withGrayBg ? '#F7F7FC' : 'transparent')};

  font-size: 14px;
  font-weight: 400;

  b {
    font-weight: 500;
  }

  a {
    cursor: pointer;
    color: #235dff;
    text-decoration: none;

    a:focus,
    a:visted,
    a:active,
    &:hover,
    &:focus {
      text-decoration: none;
      color: #235dff;
    }
  }

  &:first-child {
    ${BorderContainer} {
      border-top: none;
    }
  }
`;

export const FeedItemIconContainer = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 2px;
  flex-shrink: 0;
  background-color: ${({ color }) => color};
`;

export const Content = styled.div`
  flex: 1;
  min-width: 0;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 8px;

  margin-bottom: 8px;
`;

export const HeaderPart = styled.div<{ verticalAlign: string }>`
  display: flex;
  align-items: ${({ verticalAlign }) => verticalAlign};
  gap: 8px;
`;

export const HeaderTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  padding-top: 3px;
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;

  a {
    cursor: pointer;
    color: #235dff;
    text-decoration: none;

    a:focus,
    a:visted,
    a:active,
    &:hover,
    &:focus {
      text-decoration: none;
      color: #235dff;
    }
  }
`;

export const AvatarContainer = styled.div`
  display: inline-block;

  > * {
    display: inline-flex;
    vertical-align: text-bottom;
  }
`;

export const CreatedAt = styled.div<{ noPaddingTop?: boolean }>`
  font-size: 12px;
  color: #828d9a;
  white-space: nowrap;
  line-height: 18px;
  padding-top: ${({ noPaddingTop }) => (noPaddingTop ? '0' : '4px')};
`;

export const ItemActionButton = styled.div`
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #9c9caa;
`;

export const EventRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding-left: 4px;

  &:first-child {
    margin-top: 8px;
  }

  ${CreatedAt} {
    padding-top: 0;
  }
`;

export const EventTitle = styled.div`
  min-width: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
