import React, { forwardRef } from 'react';
import { colors } from '@styles';
import { Tooltip } from '@material-ui/core';
import { HelpCircleIcon } from '@kit/ui/icons/Help';
import { Asterisk, Container, Description, Error, InputWrapper, Label } from './styled';
import { UserInputError } from '../UserInputError';
import { useFormValidationContext } from '../validation';

interface Props {
  label?: React.ReactNode;
  hint?: React.ReactNode;
  error?: string;
  description?: React.ReactNode;
  children: React.ReactNode;
  name?: string;
}

export const FormField = forwardRef<HTMLDivElement, Props>(
  ({ label, hint, children, description, error, name }, ref) => {
    const context = useFormValidationContext();

    const isRequired = Boolean(name && context?.[name]?.('').required);

    return (
      <Container ref={ref} className="field-container">
        {label && (
          <Label>
            {label}{' '}
            {hint && (
              <Tooltip title={hint}>
                <HelpCircleIcon size="16px" color={colors.gray} />
              </Tooltip>
            )}
            {isRequired && <Asterisk>*</Asterisk>}
          </Label>
        )}
        <InputWrapper>{children}</InputWrapper>

        {error && (
          <Error>
            <UserInputError>{error}</UserInputError>
          </Error>
        )}
        {description && <Description>{description}</Description>}
      </Container>
    );
  }
);
