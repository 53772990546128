import React from 'react';

const INVALID_INPUT_ATTRIBUTE_NAME = 'data-invalid-input';
const INVALID_INPUT_ATTRIBUTE_VALUE = 'true';

const INVALID_INPUT_SELECTOR = `[${INVALID_INPUT_ATTRIBUTE_NAME}=${INVALID_INPUT_ATTRIBUTE_VALUE}]`;

interface Props {
  children: React.ReactNode;
}

const UserInputError = ({ children }: Props) => (
  <span {...{ [INVALID_INPUT_ATTRIBUTE_NAME]: INVALID_INPUT_ATTRIBUTE_VALUE }}> {children}</span>
);

export { UserInputError, INVALID_INPUT_SELECTOR };
