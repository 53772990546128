import React from 'react';
import { Avatar } from '@common/ui';
import { colors } from '@styles';

import Props from './types';
import { Wrapper, Title, Description, ExtraTitle, MainTitle, AvatarWrapper, ProjectLabel, Info } from './styled';

const UserTile: React.FC<Props> = (props: Props) => {
  const {
    imgSrc,
    userId,
    userName,
    title,
    titleExtra,
    description,
    className,
    workspace,
    projectLabel,
    descriptionColor = colors.white,
    disableInfoPopper = false
  } = props;
  /* const position = useMemo(() => Math.floor(Math.random() * 40), [userId]); */

  return (
    <Wrapper className={className}>
      {typeof imgSrc !== 'undefined' && (
        <AvatarWrapper>
          <Avatar
            user={{
              profileImgUrl: imgSrc,
              position: undefined,
              id: userId,
              username: userName,
              workspace: workspace ? { name: workspace } : null
            }}
            disableInfoPopper={disableInfoPopper}
          />
        </AvatarWrapper>
      )}
      <div>
        <Title>
          {title && (
            <MainTitle
              dangerouslySetInnerHTML={{
                __html: title
              }}
            />
          )}
          {title && titleExtra ? ' ' : null}
          {titleExtra && (
            <ExtraTitle
              dangerouslySetInnerHTML={{
                __html: titleExtra
              }}
            />
          )}
        </Title>
        <Info>
          {description && <Description color={descriptionColor}>{description}</Description>}
          {projectLabel && <ProjectLabel>{projectLabel}</ProjectLabel>}
        </Info>
      </div>
    </Wrapper>
  );
};

export default UserTile;
