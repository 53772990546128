import React from 'react';

interface Props {
  size?: string | number;
  color?: string;
}

export const UnpinIcon = React.forwardRef<SVGSVGElement, Props>(
  ({ size = '16px', color = 'currentColor', ...props }, ref) => (
    <svg
      ref={ref}
      {...props}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.99869 1.72407C4.25904 1.46372 4.68115 1.46372 4.9415 1.72407L14.2748 11.0574C14.5352 11.3178 14.5352 11.7399 14.2748 12.0002C14.0145 12.2606 13.5924 12.2606 13.332 12.0002L10.7589 9.42713L9.95625 11.5676C9.92284 11.6567 9.87073 11.7377 9.80344 11.805L8.80344 12.805C8.54309 13.0653 8.12098 13.0653 7.86063 12.805L5.9987 10.943L3.4701 13.4716C3.20975 13.732 2.78764 13.732 2.52729 13.4716C2.26694 13.2113 2.26694 12.7892 2.52729 12.5288L5.05589 10.0002L3.19396 8.13829C2.93361 7.87794 2.93361 7.45583 3.19396 7.19548L4.19396 6.19548C4.26125 6.12818 4.34217 6.07608 4.43128 6.04266L6.57178 5.23997L3.99869 2.66688C3.73834 2.40653 3.73834 1.98442 3.99869 1.72407ZM7.60632 6.27451L9.7244 8.3926C9.71845 8.40579 9.71291 8.4192 9.70781 8.4328L8.75852 10.9643L8.33203 11.3907L4.60817 7.66688L5.03466 7.2404L7.56611 6.2911C7.57971 6.286 7.59312 6.28047 7.60632 6.27451Z"
        fill={color}
      />
      <path
        d="M10.1368 2.19547C9.87641 1.93512 9.4543 1.93512 9.19395 2.19547C8.98164 2.40777 8.94246 2.72765 9.07642 2.97966L8.01957 4.03651L8.94149 5.0002L9.99869 3.94301L12.0559 6.0002L10.9582 7.09783L11.8925 8.03216L13.0192 6.92247C13.2712 7.05643 13.5911 7.01725 13.8034 6.80494C14.0638 6.54459 14.0638 6.12248 13.8034 5.86213L10.1368 2.19547Z"
        fill={color}
      />
    </svg>
  )
);
