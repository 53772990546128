import React, { useCallback } from 'react';
import { get } from 'lodash';
import { makeStyles, TextField as TextFieldMui } from '@material-ui/core';
import { FieldConfig, FieldHelperProps, useFormikContext } from 'formik';
import { TextFieldProps as TextFieldPropsFormikMui } from 'formik-material-ui';
import { usePropsForMuiComponent } from '@hooks';
import { FieldTypes, UiStates } from '@enums';
import { InputLabel } from '@common/ui';
import { FormHelperText } from '@common/ui/TextField/FormHelperText';
import { textField, Wrapper } from './styled';

type TextFieldValue = string;

export type TextFieldProps = FieldConfig<TextFieldValue> & Partial<Omit<TextFieldPropsFormikMui, 'size'>> & {
  value?: string;
  onValueChange?: (value: string) => unknown;
  helperText?: string;
  helperState?: UiStates;
  disabled?: boolean;
  error?: boolean;
  noBorder?: boolean;
  size?: 'small';
  tooltip?: string;
};

export const TextField:
  React.FC<Omit<TextFieldProps, 'name'>
  & Partial<FieldHelperProps<TextFieldValue>>> =
(props) => {
  const {
    type = 'text',
    variant = 'outlined',
    label,
    tooltip,
    value,
    onValueChange,
    onChange,
    required,
    placeholder,
    helperText,
    helperState = UiStates.DEFAULT,
    disabled,
    error,
    hiddenLabel = false,
    noBorder = false,
    setError, // omit formik-related props
    setTouched,
    setValue,
    ...rest
  } = props;

  const classesTextField = makeStyles(textField({ helperState, noBorder }))();

  const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = useCallback((...params) => {
    onChange?.(...params);
    onValueChange?.(get(params, '[0].target.value'));
  }, [onChange, onValueChange]);

  // TODO: htmlFor with uniq input id
  return (
    <Wrapper>
      {label && !hiddenLabel ? <InputLabel required={required} tooltip={tooltip}>{ label }</InputLabel> : null}
      <TextFieldMui
        // label={placeholder} // we can use it as animated placeholder
        classes={classesTextField}
        placeholder={placeholder}
        type={type}
        value={value}
        onChange={handleOnChange}
        variant={variant}
        fullWidth
        disabled={disabled}
        error={error}
        {...rest}
      />
      {helperState !== UiStates.DEFAULT && (
        <FormHelperText helperState={helperState} helperText={helperText} disabled={disabled} error={error} />
      )}
    </Wrapper>
  );
};

export const TextFieldFormik: React.FC<TextFieldProps> = (props) => {

  const { name } = props;

  const newProps = usePropsForMuiComponent(FieldTypes.Text, props);

  const { getFieldMeta } = useFormikContext();
  const meta = getFieldMeta(name);

  return (
    <TextField
      {...newProps}
      helperText={meta.error || (meta.error && meta.touched) ? meta.error : ''}
      helperState={meta.error ? UiStates.ERROR : UiStates.DEFAULT}
    />
  );
};
