import { colors } from '../colors';
import { fonts } from '../fonts';

const input = (props) => `
  position: relative;
  box-sizing: border-box;
  
  width: 100%;
  height: ${props.tableInput ? '32px' : '40px'};
  
  padding: 0 ${props.tableInput ? '8px' : '16px'};
  
  border-radius: 4px;
  border: 1px ${props.tableInput ? 'dashed' : 'solid'} transparent;
  
  border-color: ${props.error ? colors.red : '#DFDFE8'};  

  background: #FFFFFF;

  font-size: 14px;
  line-height: 1.25;
  font-family: ${fonts.primary};
  color: ${colors.black2};
    
  transition: .3s;
  
  &:hover, &:focus {
    border-color: ${
      // eslint-disable-next-line no-nested-ternary
      props.error ? colors.red : props.tableInput ? colors.gray : colors.green
    };
  } 

  &:focus {
    outline: none;
    /*box-shadow: ${
      props.error || props.tableInput
        ? ''
        : 'inset -1px -1px 4px rgba(0, 150, 136, 0.5), inset 1px 1px 4px rgba(0, 150, 136, 0.5)'
    };*/
  }
  
  &::placeholder {
    color: ${colors.gray2};
  }
`;

export default input;
