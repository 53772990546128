import { FunnelData } from '@features/Analytics/Widget/types';
import { AnalyticsWidgetType, WidgetSettings } from '@features/Analytics/types';
import { postGraphql } from '@services/api/base/graphql';
import {
  ProjectReportAggregates,
  ProjectReportCondition,
  ProjectReportFilter,
  ProjectReportGroupBy,
  ProjectReportsConnection
} from '@generated/types/graphql';
import { gql } from 'graphql-request';
import { DeepPartial } from 'redux';
import { WidgetSettingsError } from '@features/Analytics/WidgetSettingsError';
import { STATUS_LIFECYCLE_WORKFLOWS } from '@features/Analytics/constants';
import { isStatusLifecycleWorkflow } from '@features/Analytics/helpers';
import { InternalConfigutationError } from '@features/Analytics/InternalConfigurationError';
import { buildCommonFilters, buildTimelineCondition, buildTimelineFilter } from './helpers';
import { RecordsWidgetDataParams } from '../types';
import { buildCreateAtDateRangeFilter } from '../helpers';

const buildRequestParams = ({ companyId, settings, propertiesMap, stageIdsByWorkflowMap }: RecordsWidgetDataParams) => {
  if (!settings.workflowId) {
    throw new WidgetSettingsError('Workflow is not specified');
  }

  const isStatus = STATUS_LIFECYCLE_WORKFLOWS.includes(settings.workflowId.toString());

  const filter: DeepPartial<ProjectReportFilter> = {
    ...buildCommonFilters(companyId, settings, propertiesMap),
    ...buildTimelineFilter(settings, stageIdsByWorkflowMap),
    ...buildCreateAtDateRangeFilter(settings)
  };

  const condition: DeepPartial<ProjectReportCondition> = {
    ...buildTimelineCondition(settings)
  };

  const groupBy: ProjectReportGroupBy[] = isStatus
    ? [ProjectReportGroupBy.TimelineStatus]
    : [ProjectReportGroupBy.TimelineStageId];

  return {
    filter,
    condition,
    groupBy
  };
};

const buildAggregationResponse = (settings: WidgetSettings) => {
  const isStatus = isStatusLifecycleWorkflow(settings.workflowId);

  switch (settings.widgetType) {
    case AnalyticsWidgetType.TIMELINE: {
      return `
        average {
          ${isStatus ? 'timelineStatusSpentTime' : 'timelineStageSpentTime'}
        }
      `;
    }

    case AnalyticsWidgetType.PIPELINE:
    case AnalyticsWidgetType.FUNNEL: {
      return `
        distinctCount {
          id
        }
      `;
    }

    default:
      throw new InternalConfigutationError(`Only TIMELINE, PIPELINE and FUNNEL widget types are supported`);
  }
};

const extractGroupAggregationValue = (settings: WidgetSettings, group: ProjectReportAggregates) => {
  const isStatus = isStatusLifecycleWorkflow(settings.workflowId);

  if (settings.widgetType === AnalyticsWidgetType.TIMELINE) {
    return group.average[isStatus ? 'timelineStatusSpentTime' : 'timelineStageSpentTime'];
  }

  return group.distinctCount.id;
};

const requestGroupedData = (params: RecordsWidgetDataParams) => {
  const { filter, condition, groupBy } = buildRequestParams(params);

  const response = buildAggregationResponse(params.settings);

  return postGraphql<{ projectReportsConnection: ProjectReportsConnection }>(
    gql`
          query RECORDS_TIMELINE_DATA_QUERY($filter: ProjectReportFilter!, $condition: ProjectReportCondition, $groupBy: [ProjectReportGroupBy!]!) {
              projectReportsConnection(filter: $filter, condition: $condition) {
                  groupedAggregates(groupBy: $groupBy) {
                      keys
                      ${response}
                  }   
              }
          }
      `,
    {
      filter,
      condition,
      groupBy
    }
  );
};

export const fetchRecordsFunnelData = async (params: RecordsWidgetDataParams): Promise<FunnelData> => {
  const result = await requestGroupedData(params);

  const isStatus = isStatusLifecycleWorkflow(params.settings.workflowId);

  return {
    pointsMap: result.projectReportsConnection.groupedAggregates.reduce(
      (acc, group) => {
        const key = group.keys[0] as string;

        if (!key) {
          return acc;
        }

        const y = extractGroupAggregationValue(params.settings, group);
        const x = isStatus ? key : parseInt(key, 10);

        acc[x] = y;

        return acc;
      },
      {} as FunnelData['pointsMap']
    )
  };
};
