import React from 'react';
import Props from './types';
import { XCircle } from 'react-feather';

import styled from 'styled-components';
import { modal } from '../../../../styles';

const Wrapper = styled.div`
  ${modal.header};
`;

const Title = styled.div`
  ${modal.title};
`;

const ModalHeader: React.FC<Props> = (props: Props) => {
  const closeButton = props.closeButton ? (
    <XCircle onClick={props.handleClose} />
  ) : null;
  return (
    <Wrapper>
      <Title>{props.title}</Title>
      {closeButton}
    </Wrapper>
  );
};

export default ModalHeader;
