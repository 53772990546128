import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { TableContainer as TableContainerMui } from '@material-ui/core';
import { scroll } from '@styles';

export const TableContainer = styled(TableContainerMui)`
  && {
    ${scroll};
  }
`;

const tableContainer = () => {
  return {
    root: {
      height: '100%',
      overflow: 'auto',
    }
  };
};

export const useStylesTableContainer = makeStyles(tableContainer());
