import styled from 'styled-components';

export const Wrapper = styled.div<{ size: number }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  margin-left: auto;
  cursor: pointer;
`;

export const Copied = styled.div`
  display: flex;
  align-items: center;
`;
