import { Search } from '@components/Project/ProjectView/types';
import { ActionCheckResultFromApi, DynamicDropdownFilter, KeyType } from '@types';

import { post, put, get, deleteRequest } from './base';
import { generateParams } from './helpers';
import { Urls } from './base/axios';
import { WorflowApiRequest, StepApiRequest, CheckActionRequest } from './types';

type GenerateParamsArgumentsType = Parameters<typeof generateParams>[0];

const generateDropdownFilters = ({ keys, entity, companyId }: DynamicDropdownFilter & { companyId: number }) => {
  const params: { [keys: string]: string | number | boolean } = {
    companyId,
    entity
  };

  let keyIndex = 0;
  keys?.forEach((filter) => {
    (Object.keys(filter) as (keyof KeyType)[])?.forEach((key) => {
      params[`keys[${keyIndex}][${key}]`] = filter[key];
    });
    // eslint-disable-next-line no-plusplus
    keyIndex++;
  });

  return params;
};

export default {
  find: (search: Partial<Search>) => {
    return get(`${Urls.project}/workflows`, generateParams({ search }));
  },

  create: (req: WorflowApiRequest, params: GenerateParamsArgumentsType) =>
    post(`${Urls.project}/workflows`, req, generateParams(params)),

  duplicate: (id: number, req: Partial<WorflowApiRequest>, params: GenerateParamsArgumentsType) =>
    post(`${Urls.project}/workflows/${id}/duplicate`, req, generateParams(params)),

  update: (id: number, req: Partial<WorflowApiRequest>) => put(`${Urls.project}/workflows/${id}`, req),

  remove: (id: number) => deleteRequest(`${Urls.project}/workflows/${id}`),

  // step actions
  createStep: (workflowId: number, req: StepApiRequest, params: GenerateParamsArgumentsType) =>
    post(`${Urls.project}/workflows/${workflowId}/steps`, req, generateParams(params)),

  updateStep: (workflowId: number, id: number, req: Partial<StepApiRequest>) =>
    put(`${Urls.project}/workflows/${workflowId}/steps/${id}`, req),

  removeStep: (workflowId: number, id: number) => deleteRequest(`${Urls.project}/workflows/${workflowId}/steps/${id}`),

  findActionsTriggers: (type?: string | undefined) => get(`${Urls.project}/workflows/actions-triggers?type=${type}`),

  findDropdownValues: (...params: Parameters<typeof generateDropdownFilters>) =>
    get(`${Urls.project}/workflows/dynamic-dropdown-values`, generateDropdownFilters(...params)),

  retry: (feedItemId: number) => post<any, { success: boolean }>(`${Urls.project}/workflows/feed/${feedItemId}/retry`),
  checkAction: (req: CheckActionRequest) =>
    post<ActionCheckResultFromApi>(`${Urls.project}/workflows/check-action`, req)
};
