import styled from 'styled-components';
import { User } from 'react-feather';
import { defaultTheme } from '@themes';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;
`;

export const Icon = styled(User)`
  border-radius: 4px;
  margin-right: 10px;
  // since the component renders outside the theme provider
  // WARNING: do not use theme from props
  background-color: ${defaultTheme.colors.gray3};
  padding: 2px;
  color: ${defaultTheme.colors.gray};
`;
