import { useAppSelector } from '@hooks/store';
import { useTeams } from '@hooks/useTeams';
import { selectWorkspaceId } from '@state/selectors';
import { useMemo } from 'react';

export const useUserToTeamsMap = () => {
  const companyId = useAppSelector(selectWorkspaceId);

  const { data: teams = [], isLoading } = useTeams(companyId);

  const userToTeamsMap = useMemo<Record<number, string[]>>(() => {
    const result = {};

    teams.forEach((team) => {
      team.teamUsers.forEach((user) => {
        if (!result[user.id]) {
          result[user.id] = [];
        }
        result[user.id].push(team.name);
      });
    });

    return result;
  }, [teams]);

  return {
    userToTeamsMap,
    isTeamsLoading: isLoading
  };
};
