import { Dispatch } from 'redux';
import { createAction } from 'redux-actions';
import { isNumber, uniqueId } from 'lodash';

import { extractIds } from '@utils';
import { Identified } from '@types';
import tasksApi from '../../../services/api/tasksApi';
import { AssigneeMember, Label, Task } from '../types';
import { alertShow } from '../../../state/actions/alert/alertAction';

export const fetchKanbanTasksRequest = createAction(
  'FETCH_KANBAN_TASKS_REQUEST'
);
export const fetchKanbanTasksSuccess = createAction(
  'FETCH_KANBAN_TASKS_SUCCESS'
);
export const fetchKanbanTasksFailure = createAction('FETCH_KANBAN_TASKS_ERROR');

// project is id for specific project (only one atm)
// isAssignee - admins only. By default looks at all tasks on workspace
export const getKanbanTasks = (
  startDate: Date,
  endDate: Date,
  project = null, // NOT USED
  isAssignee = false // NOT USED
) => async (dispatch: Dispatch) => {
  dispatch(fetchKanbanTasksRequest());
  try {
    const query = {
      startDate,
      endDate
    } as any;

    if (project) {
      query.project = project;
    }
    if (typeof isAssignee === 'boolean') {
      query.isAssignee = isAssignee;
    }

    const { data } = await tasksApi.getKanbanTasks(query, {
      filters: [
        { col: 'isArchived', val: 'false', op: '=' }
      ]
    });
    dispatch(fetchKanbanTasksSuccess({ tasks: data.results }));
  } catch (e) {
    console.error('error fetch calendar: ', e);
    dispatch(fetchKanbanTasksFailure({ error: e }));
  }
};

// task update
export const taskUpdateRequest = createAction('TASK_UPDATE_REQUEST');
export const taskUpdateSuccess = createAction('TASK_UPDATE_SUCCESS');
export const taskUpdateFailure = createAction('TASK_UPDATE_FAILURE');

export const updateTask = (task: Identified & Partial<Task>) => async (dispatch: Dispatch) => {
  dispatch(taskUpdateRequest({ task }));
  try {
    const payload = {
      ...task
    };
    if (task.assignees !== null && typeof task.assignees !== 'undefined') {
      payload.assignees = (task.assignees as AssigneeMember[] | number[]).map(
        (assignee) => {
          if (isNumber(assignee)) return assignee;
         return assignee?.member?.id ?? +assignee.id ?? +assignee
        }
      );
    }
    const hasLabelsUpdate = task.labels !== null && typeof task.labels !== 'undefined';

    if (hasLabelsUpdate) {
      payload.labels = extractIds(task.labels);
    }

    const { data } = await tasksApi.updateTask(payload);

    dispatch(
      taskUpdateSuccess({
        task: {
          ...task,
          ...data,
          privilegeAll: task.privilegeAll,
          privilegeOwn: task.privilegeOwn,
          privilegeTeam: task.privilegeTeam,
          ...(hasLabelsUpdate ? { labels: task.labels } : undefined),
          showIsUpdated: uniqueId('updatedId-'),
        }
      })
    );

    return data;
  } catch (error) {
    console.error('couldn\'t update work order', error);
    const { status, data } = error?.response || {};
    let errorMessage = 'Something unexpected has happened.';
    if (status === 400) {
      const { message = [] } = data;
      errorMessage = Array.isArray(message) ? message.join('. ') : message;
    } else if (status === 403) {
      errorMessage = data;
    }

    dispatch(taskUpdateFailure({ error: errorMessage }));
    dispatch(
      alertShow([`Failed to update the work order: ${errorMessage}`], 'error')
    );

    return { isSuccess: false, error: data }
  }
};

// task update
export const taskCreateRequest = createAction('TASK_CREATE_REQUEST');
export const taskCreateSuccess = createAction('TASK_CREATE_SUCCESS');
export const taskCreateFailure = createAction('TASK_CREATE_FAILURE');

export const createTask = (task: Task) => async (dispatch: Dispatch) => {
  dispatch(taskCreateRequest());
  try {
    const payload = {
      ...task
    };
    if (task.assignees !== null && typeof task.assignees !== 'undefined') {
      payload.assignees = (task.assignees as (AssigneeMember | number)[]).map((assignee) => typeof assignee === 'number' ? assignee : assignee.id);
    }
    if (task.labels !== null && typeof task.labels !== 'undefined') {
      payload.labels = (task.labels as (Label | number)[]).map((label) => typeof label === 'number' ? label : label.id);
    }

    const { data } = await tasksApi.createTask(payload);

    dispatch(
      taskCreateSuccess({
        task: {
          ...task,
          id: data.id,
          stage: data.stage,
          isCompleted: data.isCompleted ?? task.isCompleted,
          completionDate: data.completionDate ?? task.completionDate,
          privileges: ['view', 'edit', 'create'],
          contacts: data.contacts,
          showIsNew: uniqueId('showNewId-')
        }
      })
    );

    return data;
  } catch (error) {
    console.error('couldn\'t create work order', error);
    const { status, data } = error?.response;
    let errorMessage = 'Something unexpected has happened.';
    if (status === 400) {
      const { message = [] } = data;
      errorMessage = Array.isArray(message) ? message.join('. ') : message;
    } else if (status === 403) {
      errorMessage = data;
    }

    dispatch(taskCreateFailure({ error: errorMessage }));
    dispatch(alertShow([`Failed to create work order: ${errorMessage}`], 'error'));
  }
};

// task sync
export const taskSyncRequest = createAction('TASK_SYNC_REQUEST');
export const taskSyncSuccess = createAction('TASK_SYNC_SUCCESS');
export const taskSyncFailure = createAction('TASK_SYNC_FAILURE');

export const syncTaskHandler = (task: Task, sync: boolean) => async (
  dispatch: Dispatch
) => {
  dispatch(taskSyncRequest());
  try {
    await tasksApi.syncTaskHandler(task, sync);
    dispatch(
      taskSyncSuccess({
        task: {
          ...task,
          googleEventId: sync ? null : task.googleEventId
        }
      })
    );
  } catch (error) {
    console.error('couldn\'t sync work order', error);
    const { status, data } = error?.response;
    let errorMessage = 'Something unexpected has happened.';
    if (status === 400) {
      const { message = [] } = data;
      errorMessage = Array.isArray(message) ? message.join('. ') : message;
    } else if (status === 403) {
      errorMessage = data;
    }

    dispatch(taskSyncFailure({ error: errorMessage }));
    dispatch(
      alertShow([`Failed to sync the work order: ${errorMessage}`], 'error')
    );
  }
};
