import styled from 'styled-components';

export const WidgetHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  > *:first-child {
    margin-right: auto;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  svg {
    cursor: pointer;

    color: #828d9a;
    flex-shrink: 0;

    &:hover {
      color: #000000;
    }
  }
`;

export const WidgetContainer = styled.div`
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #dfdfe8;
  background-color: #ffffff;

  &.react-draggable-dragging,
  &.resizing {
    z-index: 1;
    border: 1px solid var(--primary-500-main, #009688);
    box-shadow: 0px 0px 16px 0px rgba(0, 150, 136, 0.12);
  }

  ${WidgetHeader} svg, > svg {
    transition:
      opacity 0.15s ease-in-out,
      color 0.15s ease-in-out;
    opacity: 0;
  }

  &:hover {
    ${WidgetHeader} svg, > svg {
      opacity: 1;
    }
  }
`;

export const ContainerInner = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const WidgetAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WidgetTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
`;

export const WidgetSubtitle = styled.div`
  margin-top: 8px;
  color: #828d9a;
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
