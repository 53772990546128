import { useCallback } from 'react';
import { createGlobalState } from 'react-use';

export enum NotificationStatus {
  Unread = 'unread',
  Read = 'read'
}

export enum NotificationsTypeFilter {
  All = 'all',
  Portal = 'portal',
  Email = 'emails',
  SMS = 'sms',
  Mentions = 'mentions',
  Reminders = 'reminders',
  Requests = 'requests',
  Projects = 'projects',
  Clients = 'clients',
  WorkOrders = 'workOrders'
}

export type ClientFilterState = {
  statuses: NotificationStatus[];
  sortByCreatedAtAsc: boolean;
  query: string;
  types: NotificationsTypeFilter[];
};

const clientFilterState = createGlobalState<ClientFilterState>({
  statuses: [NotificationStatus.Unread],
  sortByCreatedAtAsc: false,
  types: [],
  query: ''
});

export const useClientFilterState = () => {
  const [clientFilters, setClientFilters] = clientFilterState();

  const handleFiltersChange = useCallback(
    (newFilters: Partial<ClientFilterState>) => {
      setClientFilters((prev) => ({
        ...prev,
        ...newFilters
      }));
    },
    [setClientFilters]
  );

  return {
    clientFilters,
    updateFilters: handleFiltersChange
  };
};
