import { useCallback, useState } from 'react';

export type DocFilters = {
  isArchivedShown: boolean;
  query: '';
};

export const useFilters = () => {
  const [filters, setFilters] = useState<DocFilters>({
    isArchivedShown: false,
    query: ''
  });

  const handleFiltersChange = useCallback((newFilters: Partial<DocFilters>) => {
    setFilters((prev) => ({
      ...prev,
      ...newFilters
    }));
  }, []);

  return {
    filters,
    updateFilters: handleFiltersChange
  };
};
