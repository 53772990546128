import styled from 'styled-components';

export const ProjectStatusOption = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const FieldWrapper = styled.div<{ width?: string }>`
  width: ${({ width }) => width || '300px'};

  .ql-editor {
    max-height: none;
  }
`;

export const Option = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: start;

  strong {
    font-weight: 500;
  }
  small {
    font-weight: 300;
  }
`;
