import React from 'react';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';

import { ChevronDownIcon } from '@kit/ui/icons/ChevronDown';
import TextField from '@material-ui/core/TextField';
import { autocompleteStyles } from './styled';

interface Props<T>
  extends Omit<
    AutocompleteProps<T, false, false, false>,
    'freeSolo' | 'disableClearable' | 'multiple' | 'renderInput'
  > {
  placeholder?: string;
  isMulti?: boolean;
  isClearable?: boolean;
}

const useStyles = makeStyles(autocompleteStyles({}));

export const Select = <T,>({ placeholder, isMulti, isClearable = true, ...props }: Props<T>) => {
  const classes = useStyles();

  return (
    <Autocomplete
      classes={classes}
      disableClearable={!isClearable}
      freeSolo={false}
      multiple={isMulti}
      popupIcon={<ChevronDownIcon />}
      renderInput={(params) => <TextField {...params} placeholder={placeholder} variant="outlined" />}
      {...props}
    />
  );
};
