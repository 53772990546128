import { useTask } from '@components/Scheduler/useTaskDrawer';
import React, { useState, useCallback, useEffect } from 'react';
import { noop } from 'lodash';
import { ReactQueryKey } from '@enums';
import { useQueryClient } from 'react-query';
import { useDrawersContext } from '@contexts/DrawersContext';
import { WorkOrderView } from './WorkOrderView';

export const WorkOrderContainer = ({ id }: { id: number }) => {
  const [loadedTask, setLoadedTask] = useState(null);

  const { task, isLoading, isFetching } = useTask(id);

  useEffect(() => {
    // there is some react-query cache issue - need to investigate
    // for now just use local state
    if (!isLoading && !isFetching && task) {
      setLoadedTask(task);
    }
  }, [isLoading, isFetching, task]);

  const queryClient = useQueryClient();

  const { closeDrawer } = useDrawersContext();

  const invalidateProjectFeed = useCallback(() => {
    queryClient.invalidateQueries([ReactQueryKey.ProjectActivity]);
  }, [queryClient]);

  if (isLoading || !loadedTask || loadedTask.id !== id) {
    return null;
  }

  return (
    <WorkOrderView
      key={task.id}
      isViewMode={false}
      workOrder={loadedTask}
      toggleViewMode={noop}
      onClose={closeDrawer}
      onSaved={invalidateProjectFeed}
    />
  );
};
