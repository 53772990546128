import { Link } from '@reach/router';
import React from 'react';

export enum ButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Danger = 'danger',
  Flat = 'flat',
  Link = 'link'
}

export enum ButtonSize {
  Default = 'default',
  Small = 'small',
  Large = 'large'
}

interface BaseButtonProps {
  size?: ButtonSize;
  isUpperCase?: boolean;
  children?: React.ReactNode;
}

export interface RegularButtonProps extends BaseButtonProps, React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant: ButtonVariant.Danger | ButtonVariant.Flat | ButtonVariant.Primary | ButtonVariant.Secondary;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  isBlock?: boolean;
}

export interface LinkButtonProps extends BaseButtonProps, React.ComponentProps<typeof Link> {
  variant: ButtonVariant.Link;
  type?: never;
}

export type ButtonProps = RegularButtonProps | LinkButtonProps;
