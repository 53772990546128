import React from 'react';
import {
  ProjectReportCondition,
  ProjectReportFilter,
  TaskReportCondition,
  TaskReportFilter
} from '@generated/types/graphql';
import { WidgetSettingsError } from '../WidgetSettingsError';
import { AnalyticsModule, DrilldownFilter, WidgetSettings } from '../types';
import { Records } from './Records';
import { WorkOrders } from './WorkOrders';

interface Props {
  settings: WidgetSettings;
  drilldownFilter?: DrilldownFilter;
}

export const DataTable = ({ settings, drilldownFilter }: Props) => {
  switch (settings.module) {
    case AnalyticsModule.CLIENTS:
    case AnalyticsModule.PROJECTS:
    case AnalyticsModule.REQUESTS:
      return (
        <Records
          settings={settings}
          drilldownFilter={drilldownFilter as DrilldownFilter<ProjectReportFilter, ProjectReportCondition>}
        />
      );
    case AnalyticsModule.WORK_ORDERS:
      return (
        <WorkOrders
          settings={settings}
          drilldownFilter={drilldownFilter as DrilldownFilter<TaskReportFilter, TaskReportCondition>}
        />
      );
    default:
      throw new WidgetSettingsError('Invalid module type');
  }
};
