import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import moment from 'moment';
import { parseUtcDate } from '@utils/dates';
import { times } from 'lodash';
import { Skeleton } from '@material-ui/lab';
import { Container, WorkOrderStatus } from './styled';
import { useTaskStatusTimelines } from './useTaskStatusTimelines';

interface Props {
  taskId: number;
}

export const Tracking = ({ taskId }: Props) => {
  const { data = [], isLoading } = useTaskStatusTimelines(taskId);

  return (
    <Container>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell>Time in status</TableCell>
            <TableCell>Last entered date</TableCell>
            <TableCell align="right"># times</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {isLoading &&
            times(3, (index) => (
              <TableRow key={index}>
                <TableCell className="skeleton-cell">
                  <Skeleton animation="wave" width={75} height={22} />
                </TableCell>
                <TableCell className="skeleton-cell">
                  <Skeleton animation="wave" width={70} height={17} />
                </TableCell>
                <TableCell className="skeleton-cell">
                  <Skeleton animation="wave" width={70} height={17} />
                </TableCell>
                <TableCell className="skeleton-cell" align="right">
                  <Skeleton animation="wave" width={20} height={17} />
                </TableCell>
              </TableRow>
            ))}
          {data.map((row) => (
            <TableRow key={row.taskStatus.id}>
              <TableCell>
                <WorkOrderStatus status={row.taskStatus} />
              </TableCell>
              <TableCell>{moment.duration({ seconds: row.spentTime }).humanize()}</TableCell>
              <TableCell>{moment(parseUtcDate(row.startedAt)).format('MM/DD/YYYY')}</TableCell>
              <TableCell align="right">{row.count}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  );
};
