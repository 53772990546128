import { ReactQueryKey } from '@enums';
import { errorHandler } from '@services/api/helpers';
import { useMutation, useQueryClient } from 'react-query';
import { useToast } from '@hooks/useToast';
import { selectWorkspaceId } from '@state/selectors';
import { UpdateDashboardDto } from '@types';
import userApi from '@services/api/userApi';
import { useAppSelector } from '..';

export const useUpdateDashboard = () => {
  const queryClient = useQueryClient();
  const { showSuccess } = useToast();
  const companyId = useAppSelector(selectWorkspaceId);

  return useMutation<void, Error, { id: number; dto: UpdateDashboardDto }>(
    async ({ id, dto }) => {
      try {
        await userApi.updateDashboard(companyId, id, dto);
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: () => {
        showSuccess('Dashboard updated successfully');
        queryClient.invalidateQueries(ReactQueryKey.Analytics);
      }
    }
  );
};
