import { systemAlertConditionToString } from '@domains/systems/utils';
import { SystemAlert } from '@generated/types/graphql';
import { Alert, AlertType } from '@kit/ui/Alert';
import React, { useCallback } from 'react';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { useDismissSystemAlert } from '@hooks/systems/useDismissSystemAlert';
import { Actions } from './styled';

interface AlertItemProps {
  systemId: number;
  alert: SystemAlert;
  onCreateWorkOrder?: () => void;
}

const AlertItem = ({ alert, systemId, onCreateWorkOrder }: AlertItemProps) => {
  const { mutateAsync: dimiss } = useDismissSystemAlert();

  const handleDismiss = useCallback(() => {
    dimiss({ id: alert.id, systemId });
  }, [alert.id, systemId, dimiss]);

  return (
    <Alert
      title={alert.alertConfig.name}
      description={systemAlertConditionToString(alert.condition)}
      type={AlertType.Error}
      actions={
        <Actions>
          <Button onClick={handleDismiss} variant={ButtonVariant.Flat}>
            Dismiss
          </Button>
          {onCreateWorkOrder && (
            <Button onClick={onCreateWorkOrder} variant={ButtonVariant.Primary}>
              Create Work Order
            </Button>
          )}
        </Actions>
      }
    />
  );
};

interface Props {
  systemId: number;
  alerts: SystemAlert[];
  onCreateWorkOrder?: () => void;
}

export const Alerts = ({ systemId, alerts, onCreateWorkOrder }: Props) => {
  if (!alerts.length) {
    return null;
  }

  return (
    <>
      {alerts.map((alert) => (
        <AlertItem key={alert.id} alert={alert} systemId={systemId} onCreateWorkOrder={onCreateWorkOrder} />
      ))}
    </>
  );
};
