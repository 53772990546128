import React, { useMemo } from 'react';
import { AnalyticsModule } from '@features/Analytics/types';
import { Status as ProjectStatus } from '@features/ProjectPortfolio/components/Status';
import { Status as RequestStatus } from '@features/RequestPortfolio/components/Status';
import { Status as ClientStatus } from '@features/ClientPortfolio/components/Status';
import { WorkOrderStatus } from '@components/WorkOrder/WorkOrderStatus';
import { AccountStatus } from '@types';
import { ProjectRequestStatus, ProjectStatus as TProjectStatus, TaskStatusEntity } from '@generated/types/graphql';
import { LineAndStatusName } from './styled';

interface Props {
  x?: number;
  y?: number;
  statuses: any[];
  payload?: any;
  module: AnalyticsModule;
}

export const StatusTick = ({ x, y, statuses, payload, module }: Props) => {
  const status = useMemo(() => {
    return statuses.find((status) => status.name === payload.value);
  }, [statuses, payload]);

  return (
    <foreignObject className="stage-wrapper" x={x - 140} y={y - 12}>
      {status && module !== AnalyticsModule.WORK_ORDERS && (
        <LineAndStatusName>
          {module === AnalyticsModule.CLIENTS && <ClientStatus status={status.id as AccountStatus} />}
          {module === AnalyticsModule.REQUESTS && <RequestStatus status={status.id as ProjectRequestStatus} />}
          {module === AnalyticsModule.PROJECTS && <ProjectStatus status={status.id as TProjectStatus} />}
          {status.name}
        </LineAndStatusName>
      )}

      {status && module === AnalyticsModule.WORK_ORDERS && <WorkOrderStatus status={status as TaskStatusEntity} />}
    </foreignObject>
  );
};
