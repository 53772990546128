import { FilterGroupOperator, PredefinedWidgetFilterFieldId } from '@features/Analytics/types';
import { Property, FilterOperators } from '@types';

export type Filter = {
  id: string;
  field: FilterField;
  operator: { op: FilterOperators; title: string };
  value: unknown;
};

export type FilterTree = {
  id: string;
  operator: FilterGroupOperator;
  children: (FilterTree | Filter)[];
};

export enum FilterFieldType {
  PROPERTY = 'property',
  WORKFLOW = 'workflow',
  WORK_ORDER_WITH_TITLE = 'workOrderWithTitle',
  WORK_ORDER_ATTRIBUTE = 'workOrderAttribute'
}

interface FilterFieldBase {
  id: PredefinedWidgetFilterFieldId | number;
  name: string;
  type: FilterFieldType;
}

export interface PropertyFilterField extends FilterFieldBase {
  type: FilterFieldType.PROPERTY;
  property: Property;
}

export interface WorkflowFilterField extends FilterFieldBase {
  type: FilterFieldType.WORKFLOW;
}

export interface WorkOrderFilterField extends FilterFieldBase {
  type: FilterFieldType.WORK_ORDER_ATTRIBUTE;
}

export interface WorkOrderWithTitleField extends FilterFieldBase {
  type: FilterFieldType.WORK_ORDER_WITH_TITLE;
}

export type FilterField = PropertyFilterField | WorkflowFilterField | WorkOrderFilterField | WorkOrderWithTitleField;
