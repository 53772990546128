import React from 'react';
import { DollarIcon } from '@kit/ui/icons/Dollar';
import { AccountIcon } from '@common/icons/Account';
import { BriefcaseIcon } from '@kit/ui/icons/Briefcase';
import { File, Monitor, Sunset, Tool } from 'react-feather';
import { FeedInbox } from '@generated/types/graphql';
import { EntityChip } from './styled';
import { NotficationEntity, NotificationEntityType } from './types';

const ICONS: Record<NotificationEntityType, React.ReactNode> = {
  [NotificationEntityType.Request]: <DollarIcon size="12px" />,
  [NotificationEntityType.Client]: <AccountIcon size={12} />,
  [NotificationEntityType.Project]: <BriefcaseIcon size="12px" />,
  [NotificationEntityType.File]: <File size="12px" />,
  [NotificationEntityType.System]: <Sunset size="12px" />,
  [NotificationEntityType.WorkOrder]: null
};

interface Props {
  entity: NotficationEntity | null;
  notification: FeedInbox;
}

export const NotificationEntityChip = ({ entity, notification }: Props) => {
  switch (entity.type) {
    case NotificationEntityType.Request:
    case NotificationEntityType.Client:
    case NotificationEntityType.Project:
    case NotificationEntityType.File: {
      return (
        <EntityChip>
          {ICONS[entity.type]}
          <div>
            #{entity.uid}
            {' · '}
            {entity.title}
          </div>
        </EntityChip>
      );
    }
    case NotificationEntityType.WorkOrder: {
      const { isField } = notification.feed.task;

      return (
        <EntityChip>
          {isField ? <Tool size="12px" /> : <Monitor size="12px" />}
          <div>
            #{entity.uid}
            {' · '}
            {entity.title}
          </div>
        </EntityChip>
      );
    }

    case NotificationEntityType.System: {
      return (
        <EntityChip>
          {ICONS[entity.type]}
          <div>{entity.title}</div>
        </EntityChip>
      );
    }

    default:
      return null;
  }
};
