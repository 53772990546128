import React from 'react';
import styled from 'styled-components';

import { getAvatarColors, getInitials } from '@utils';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Thumb = styled.div<{ bgColor: string; textColor: string; }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.textColor};;
  margin-right: 12px;
  font-size: 12px;
`;

const Title = styled.div`
  text-overflow: ellipsis;
  max-width: calc(100% - 30px);
  white-space: nowrap;
  overflow: hidden;
  text-transform: none;
`;

interface Props {
  name: string;
}

export const WorkspaceOption: React.FC<Props> = ({ name }) => {
  const { bg, text } = getAvatarColors(name);
  return (
    <Wrapper>
      <Thumb
        bgColor={bg}
        textColor={text}
      >
        {getInitials(name)}
      </Thumb>
      <Title>{name}</Title>
    </Wrapper>
  );
};
