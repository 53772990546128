import React, { memo } from 'react';
import { MapPin } from 'react-feather';
import { stopPropagationClick } from '@utils';
import { Anchor, AnchorContainer } from '../styled';

type Props = {
  value: string;
  link?: string;
  iconSize?: string | number;
};

export const Address = memo(({ value, link, iconSize = 12 }: Props) => {
  return (
    <AnchorContainer>
      <MapPin size={iconSize} />
      <Anchor target="_blank" href={link} rel="noopener noreferrer" {...stopPropagationClick}>
        {value}
      </Anchor>
    </AnchorContainer>
  );
});
