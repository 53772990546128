import { colors, getColorWithOpacity, scroll } from '@styles';
import React from 'react';
import styled from 'styled-components';

export const Title = styled.div`
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 8px;
`;

export const TableContainer = styled.div`
  max-height: 400px;
  overflow-y: auto;
  ${scroll};
`;

export const Table = styled.table`
  background-color: #fff;
  border-spacing: 0;
  border-radius: 8px;
  width: 100%;

  thead {
    position: sticky;
    top: 0px;
    z-index: 1;
    background-color: #f7f7fc;
  }

  th {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    color: #828d9a;
    height: 48px;
    text-align: left;
    padding: 8px 16px;
    border-bottom: 1px solid #dfdfe8;
  }
  tr {
    cursor: pointer;

    transition: background-color 0.15s ease-in-out;

    &:hover {
      background-color: #f5f5f5;
    }
  }

  tr.blur {
    background-color: #f5f5f5;
  }

  td {
    overflow: hidden;
    border-bottom: 1px solid #dfdfe8;
    padding: 8px 16px;
    height: 64px;
    font-size: 14px;
    font-weight: 400;

    &:last-child {
      border-right: 1px solid #dfdfe8;
    }

    &:first-child {
      border-left: 1px solid #dfdfe8;
      width: 300px;
      min-width: 300px;
      max-width: 300px;
    }

    &:not(:first-child) {
      width: 180px;
      min-width: 180px;
      max-width: 180px;
    }
  }

  tfoot {
    min-height: 1px;
    height: 1px;
  }
`;

export const Blurred = styled.div`
  filter: blur(7px);
`;

export const NoAccess = styled.div`
  color: #828d9a;
  font-size: 14px;
  font-weight: 400;
  border-radius: 16px;
  background-color: #fff;
  padding: 0 12px;
  height: 24px;
  display: inline-flex;
  align-items: center;
`;

export const WorkOrderTitleCell = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    flex-shrink: 0;
  }
`;

export const NextPageLoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const UserCell = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ProjectChipContainer = styled.div`
  display: flex;
`;

export const LabelsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  font-size: 12px;
`;

export const LabelChip = styled.div`
  color: ${(props) => props.color};
  background-color: ${(props) => getColorWithOpacity(props.color, 20) || colors.white3};
  border-radius: 4px;
  padding: 2px 4px;
  display: flex;
  align-items: center;
  gap: 4px;
  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100px;
  }
  svg {
    flex-shrink: 0;
  }
`;

export const DataLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 300px;
`;

export const ImageContainer = styled.div`
  width: 44px;
  height: 44px;
  flex-shrink: 0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dfdfe8;
`;

export const Image = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 44px;
  height: 44px;
  flex-shrink: 0;
  border-radius: 4px;
`;

export const RecordTitleCellContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  font-size: 14px;
  font-weight: 500;
`;

export const StatusAndTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const RecordNumber = styled.span`
  font-weight: 400;
`;

export const ClientType = styled.div`
  margin-top: 4px;
`;

const ValueWrapperInner = styled.div<{ disableName: boolean; isOverflowAllowed: boolean }>`
  display: block;
  min-width: calc(${(props) => (props.disableName ? '100% - 20px' : '60% - 20px')});
  width: calc(${(props) => (props.disableName ? '100% - 20px' : '60% - 20px')});

  ${({ isOverflowAllowed }) =>
    !isOverflowAllowed &&
    `
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
  word-break: break-word;
  `};
`;

export const ValueWrapper = React.forwardRef((props, ref) => <ValueWrapperInner {...props} ref={ref} />);

export const PropertyWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;

  &:hover,
  &:focus {
    svg {
      opacity: 1;
    }
  }

  svg {
    opacity: 0;
  }
`;

export const Description = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
  word-break: break-word;
`;
