import { ReactQueryKey } from '@enums';
import { errorHandler } from '@services/api/helpers';
import { actionItemApi, UpdateActionItemPayload } from '@services/api/actionItemApi';
import { useMutation, useQueryClient } from 'react-query';
import { useToast } from '@hooks/useToast';

export const useUpdateActionItem = () => {
  const queryClient = useQueryClient();
  const { showSuccess } = useToast();

  return useMutation<void, Error, { id: number; dto: UpdateActionItemPayload }>(
    async ({ id, dto }) => {
      try {
        await actionItemApi.update(id, dto);
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: () => {
        showSuccess('Action Item updated successfully');
        queryClient.invalidateQueries([ReactQueryKey.ActionItemList]);
        queryClient.invalidateQueries([ReactQueryKey.ProjectActivity]);
      }
    }
  );
};
