import React from 'react';

import { ReactQueryKey } from '@enums';
import { parseUtcDate } from '@utils/dates';
import moment from 'moment';
import { DrawerEntity, useDrawersContext } from '@contexts/DrawersContext';
import { Badge } from '@kit/ui/Badge';
import { useDocs } from './useDocs';
import { Row, Container, Title, Meta, Placeholder } from './styled';
import { FileThumbnail } from './Thumbnail';

interface Props {
  projectId: number;
}

export const Docs = ({ projectId }: Props) => {
  const { docs } = useDocs({
    queryKey: ReactQueryKey.FilesWidget,
    projectId
  });

  const { openDrawer } = useDrawersContext();

  return (
    <Container>
      {docs.length === 0 && <Placeholder>No docs yet</Placeholder>}
      {docs.slice(0, 3).map((doc) => (
        <Row
          onClick={() =>
            openDrawer(
              DrawerEntity.DOC,
              doc.id,
              docs.slice(0, 3).map((doc) => doc.id)
            )
          }
        >
          <FileThumbnail file={doc} />
          <Title>{doc.name}</Title>
          {!doc.createdByContact && doc.sharedWithPortal && (
            <Badge color="#828D9A" bgColor="#fff">
              Shared
            </Badge>
          )}
          {doc.createdByContact && (
            <Badge bgColor="#FCF2DB" color="#1D1D35">
              From Client
            </Badge>
          )}
          <Meta>Updated: {moment(parseUtcDate(doc.updatedAt)).format('MM/DD/YY h:mma').replace(':00', '')}</Meta>
        </Row>
      ))}
    </Container>
  );
};
