import { useRecordDetail } from '@hooks/useRecordDetail';
import React, { ChangeEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { Archive, Edit2, Trash2 } from 'react-feather';
import { useRecordsMutations } from '@hooks/useRecords';
import { useAppSelector } from '@hooks/store';
import { selectUser, selectWorkspaceId } from '@state/selectors';
import { CreateProjectDTO, RecordType } from '@types';
import { useQueryClient } from 'react-query';
import { ReactQueryKey } from '@enums';
import { Formik } from 'formik';
import { prettyMillisecond } from '@utils/dates';

import ProjectStageSelect from '@common/ProjectEditor/FieldComponents/ProjectStageSelect';
import { Status } from '@features/RequestPortfolio/components/Status';
import { useConfirmDeleteModal, useModal } from '@common/PromiseModal';
import { Tooltip } from '@material-ui/core';
import { hasAccess, hasEntityAccess } from '@utils/roles';
import { colors } from '@styles';
import { EditWorkflowForm } from '@features/ProjectPortfolio/Project/EditWorkflowForm';
import { useUserRoleSettings } from '@hooks/useRoles';
import { ContextMenu, MenuItem } from '@kit/components/ContextMenu';
import { MoreVerticalIcon } from '@kit/ui/icons/MoreVertical';
import { navigate } from 'gatsby';
import { UnarchiveIcon } from '@kit/ui/icons/Unarchive';
import { AutomationChip, EntityType } from '@features/Platform/Automations/Chip';
import { ProjectTitleChip } from '@components/Scheduler/components/ProjectTitleChip';
import {
  Container,
  FirstRow,
  CardInfoBlockHeader,
  Info,
  InfoTitle,
  TitleAndStatus,
  EditableWrapper,
  NameInput,
  PreTitle,
  Workflow,
  WorkflowName,
  TimelineItem,
  TimelineWrapper,
  NoWorkflowName,
  WorkflowNameAndEdit,
  WorkflowNameReadOnly
} from './styled';
import { HeaderSkeleton } from './Skeleton';

interface Props {
  recordId: number;
}

export const Header = ({ recordId }: Props) => {
  const { data: record } = useRecordDetail(recordId);

  const companyId = useAppSelector(selectWorkspaceId);

  const { openModal } = useModal();

  const user = useAppSelector(selectUser);
  const { data: access } = useUserRoleSettings(companyId, user.userId);
  const confirmDelete = useConfirmDeleteModal();

  const isFetching = !record?.id;
  const {
    update: { mutateAsync: update },
    delete: { mutateAsync: deleteRecord }
  } = useRecordsMutations(companyId, record?.type as RecordType);
  const queryClient = useQueryClient();

  const [title, setTitle] = useState(record?.title);
  const [isTitleEditMode, setIsTitleEditMode] = useState(false);

  useEffect(() => {
    if (!record) {
      return;
    }

    setTitle((prev) => {
      if (!prev) {
        return record.title;
      }

      if (prev !== record.title) {
        return record.title;
      }

      return prev;
    });
  }, [record]);

  const handleTitleClick = useCallback(() => {
    setIsTitleEditMode(true);
  }, []);

  const handleTitleInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    setTitle(e.target.value);
  }, []);

  const invalidateCache = useCallback(() => {
    if (record?.parentProjectId) {
      queryClient.invalidateQueries([ReactQueryKey.RecordDetail, record.parentProjectId]);
    }
  }, [queryClient, record?.parentProjectId]);

  const handleTitleBlur = useCallback(() => {
    if (title) {
      setIsTitleEditMode(false);

      update(
        {
          id: record.id,
          dto: { title } as unknown as CreateProjectDTO
        },
        {
          onSuccess: () => {
            queryClient.setQueryData([ReactQueryKey.RecordDetail, record.id], (oldData: any) => {
              if (!oldData) {
                return oldData;
              }

              return {
                ...oldData,
                title
              };
            });
          }
        }
      );
    }
  }, [title, update, record?.id, queryClient]);

  const handleStageUpdate = async (stageId: number) => {
    update(
      {
        id: record.id,
        dto: { stageId } as unknown as CreateProjectDTO
      },
      { onSuccess: () => invalidateCache() }
    );
  };

  const handleChangeWorkflow = useCallback(() => {
    openModal<void>(
      ({ onClose }) => (
        <EditWorkflowForm
          currentWorkflowId={record.blueprintId}
          recordId={recordId}
          recordType={RecordType.DEAL}
          onClose={onClose}
        />
      ),
      { title: 'Edit Request Workflow' }
    );
  }, [openModal, recordId, record?.blueprintId]);

  const canEdit = record && hasEntityAccess(record, 'edit');
  const hasAcessToSettings = hasAccess(access, 'workspace', 'view', 'settings');

  const menuItems = useMemo<MenuItem[]>(
    () =>
      [
        {
          title: 'Archive',
          icon: <Archive size="16px" color="#9C9CAA" />,
          isAllowed: record && record.isActive && hasEntityAccess(record, 'edit'),
          onClick: async () => {
            if (await confirmDelete('Are you sure you want to archive this Request?', 'Archive')) {
              await update({
                id: record.id,
                dto: { isActive: false } as unknown as CreateProjectDTO
              });
            }
          }
        },
        {
          title: 'Unarchive',
          icon: <UnarchiveIcon size="16px" color={colors.green} />,
          isAllowed: record && !record.isActive && hasEntityAccess(record, 'edit'),
          onClick: async () => {
            await update({
              id: record.id,
              dto: { isActive: true } as unknown as CreateProjectDTO
            });
          }
        },
        {
          title: 'Delete forever',
          icon: <Trash2 size="16px" color={colors.red} />,
          isAllowed: record && !record.isActive && hasEntityAccess(record, 'delete'),

          onClick: async () => {
            if (await confirmDelete('Are you sure you want to delete this Request?')) {
              await deleteRecord(
                {
                  id: record.id
                },
                {
                  onSuccess: () => {
                    navigate(`/${companyId}/requests`);
                  }
                }
              );
            }
          }
        }
      ].filter((item) => item.isAllowed),
    [record, update, deleteRecord, confirmDelete, companyId]
  );

  if (isFetching) {
    return <HeaderSkeleton />;
  }

  return (
    <Container>
      <Info>
        <FirstRow>
          <TitleAndStatus>
            <Status status={record.requestStatus} />
            {!record.isActive && <Archive color="#9C9CAA" size="16px" />}
            <PreTitle>Request #{record.uid}:</PreTitle>

            {!isTitleEditMode && (
              <EditableWrapper onClick={handleTitleClick}>
                <InfoTitle>{title}</InfoTitle>
              </EditableWrapper>
            )}

            {isTitleEditMode && (
              <NameInput autoFocus value={title} onChange={handleTitleInputChange} onBlur={handleTitleBlur} />
            )}
          </TitleAndStatus>

          {record.account && (
            <ProjectTitleChip maxWidth="160px" iconSize="16px" project={record.account} variant="link" />
          )}

          <AutomationChip entityId={record.id} entityType={EntityType.Request} />

          <CardInfoBlockHeader>
            <Formik
              initialValues={{ stageId: record.stage?.id }}
              onSubmit={({ stageId }) => handleStageUpdate(stageId as number)}
              enableReinitialize
            >
              {({ submitForm, isSubmitting }) => (
                <ProjectStageSelect
                  name="stageId"
                  companyId={record.companyId as number}
                  blueprintId={record.blueprintId}
                  onChange={() => submitForm()}
                  disabled={!canEdit || isSubmitting}
                  recordType={record.type as RecordType}
                />
              )}
            </Formik>
            {menuItems.length > 0 && (
              <ContextMenu items={menuItems}>
                <MoreVerticalIcon size="24px" className="moreMenu" />
              </ContextMenu>
            )}
          </CardInfoBlockHeader>
        </FirstRow>

        <Workflow>
          {!record.blueprintId && (
            <>
              {canEdit && <NoWorkflowName onClick={handleChangeWorkflow}>Select Workflow</NoWorkflowName>}
              <TimelineWrapper>
                <TimelineItem width="auto">
                  <span>No workflow selected</span>
                </TimelineItem>
              </TimelineWrapper>
            </>
          )}
          {Boolean(record.blueprintId) && (
            <>
              <WorkflowNameAndEdit>
                {!hasAcessToSettings && <WorkflowNameReadOnly>{record.blueprint?.name}</WorkflowNameReadOnly>}
                {hasAcessToSettings && (
                  <WorkflowName to={`/${companyId}/workspace/workflows/${record.blueprintId}`}>
                    {record.blueprint?.name}
                  </WorkflowName>
                )}
                {canEdit && (
                  <Tooltip title="Edit Request Workflow">
                    <Edit2 onClick={handleChangeWorkflow} size="16px" color={colors.green} />
                  </Tooltip>
                )}
              </WorkflowNameAndEdit>
              <TimelineWrapper>
                {record.blueprint.blueprintProjectStages.map(({ projectStage }, index) => {
                  const timelineStage = record.projectStageTimelinesByProjectId.find(
                    (timelineItem) => timelineItem.stage.id === projectStage.id
                  );
                  const isFinished = Boolean(timelineStage?.isEnded);
                  const isStarted = Boolean(timelineStage);

                  const ms = (timelineStage?.spentTime ?? 0) * 1000;

                  const stageNameAndTime = `${projectStage.name} ${
                    ms >= 1000 * 60 ? ` | ${prettyMillisecond(ms)}` : ''
                  }`;

                  return (
                    <Tooltip key={projectStage.id} title={stageNameAndTime}>
                      <TimelineItem
                        isFinished={isFinished && record.stage?.id !== projectStage.id}
                        isStarted={isStarted || record.stage?.id === projectStage.id}
                      >
                        <span>{stageNameAndTime}</span>
                        {index !== record.blueprint.blueprintProjectStages.length - 1 && (
                          <div className="arrow right" />
                        )}
                      </TimelineItem>
                    </Tooltip>
                  );
                })}
              </TimelineWrapper>
            </>
          )}
        </Workflow>
      </Info>
    </Container>
  );
};
