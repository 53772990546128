import React, { useCallback, useState } from 'react';
import { stripHtml } from '@utils/strings';
import { ChevronsUpIcon } from '@kit/ui/icons/ChevronsUp';
import { ChevronsDownIcon } from '@kit/ui/icons/ChevronsDown';
import { SmsIcon } from '@kit/ui/icons/Sms';
import { RecordType } from '@types';
import { Link } from '@reach/router';
import { QueryParamsEnum, useQueryParam } from '@hooks/useQueryParam';
import { UserAvatar } from '@kit/components/UserAvatar';
import { getHumanFriendlyTimeAgo } from './BaseItemTemplate';
import {
  ItemActionButton,
  FeedItemIconContainer,
  CreatedAt,
  CommentWrapper,
  CommentFeedItem,
  CommentHeader,
  CommentHeaderPart,
  CommentHeaderTitle,
  CommentContent,
  BorderContainer,
  EmailFrom,
  Clamped
} from './styled';
import { FeedItem } from './types';
import { ENTITY_NAME_BY_RECORD_TYPE } from './constants';

interface Props {
  item: FeedItem;
  context: 'project' | 'client' | 'workOrder' | 'file';
  contextEntityId?: number;
}
export const SmsFeedItem = ({ item, context, contextEntityId }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [feedId] = useQueryParam(QueryParamsEnum.FeedId);

  const { sms } = item.payload;

  const toggleExpanded = useCallback(() => {
    setIsExpanded((prev) => !prev);
  }, []);

  const author = sms.isIncoming ? sms.from : item.createdBy;

  const userName = author.name || [author.firstName, author.lastName].join(' ');

  const rawText = stripHtml(sms.text);

  const showProjectLink = context === 'client' && contextEntityId !== item.payload.project?.id;

  return (
    <CommentFeedItem id={`feed_${item.id}`} isHighlighted={feedId && +feedId === item.id}>
      <BorderContainer>
        <FeedItemIconContainer color="#EEF5E3">
          <SmsIcon color="#5F8425" size="16px" />
        </FeedItemIconContainer>

        <CommentContent>
          <CommentHeader>
            <CommentHeaderPart verticalAlign="flex-start">
              {author && <UserAvatar user={author} />}
              <CommentHeaderTitle>
                {sms.isIncoming ? (
                  <>
                    <b>{sms.from.name}</b>
                    {sms.fromPhone && <EmailFrom>{` <${sms.fromPhone}> `}</EmailFrom>}
                    sent SMS to <b>{sms.to.name}</b>
                  </>
                ) : (
                  <>
                    <b>{userName}</b>
                    {sms.fromPhone && sms.fromPhone !== userName && <EmailFrom>{` <${sms.fromPhone}> `}</EmailFrom>}
                    sent SMS to <b>{sms.to.name}</b>
                  </>
                )}
                {showProjectLink && (
                  <>
                    {` in ${ENTITY_NAME_BY_RECORD_TYPE[item.payload.project?.type || RecordType.PROJECT]} `}
                    <Link
                      to={`${window.location.pathname}/projects/${item.payload.project?.id}${window.location.search}`}
                    >
                      {item.payload.project?.title}
                    </Link>
                  </>
                )}
              </CommentHeaderTitle>
            </CommentHeaderPart>
            <CommentHeaderPart verticalAlign="center">
              <CreatedAt>{getHumanFriendlyTimeAgo(item.createdAt)}</CreatedAt>
              <ItemActionButton onClick={toggleExpanded}>
                {isExpanded ? <ChevronsUpIcon size="16px" /> : <ChevronsDownIcon size="16px" />}
              </ItemActionButton>
            </CommentHeaderPart>
          </CommentHeader>

          <CommentWrapper withPadding={false}>
            {isExpanded && <div dangerouslySetInnerHTML={{ __html: item.payload?.sms?.text }} />}
            {!isExpanded && <Clamped>{rawText}</Clamped>}
          </CommentWrapper>
        </CommentContent>
      </BorderContainer>
    </CommentFeedItem>
  );
};
