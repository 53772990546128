import { Project } from '@generated/types/graphql';
import React from 'react';
import { Status } from '@features/ProjectPortfolio/components/Status';
import { DrawerEntity, useDrawersContext } from '@contexts/DrawersContext';
import { Badge } from '@kit/ui/Badge';
import { Image, FirstLine, SecondLine, RowRight, Title, OverdueBadge } from './styled';
import { Placeholder, RowContainer, Row } from '../styled';

interface Props {
  projects: Project[];
}

export const ProjectList = ({ projects }: Props) => {
  const { openDrawer } = useDrawersContext();

  const handleProjectClick = (projectId: number) => {
    openDrawer(
      DrawerEntity.PROJECT,
      projectId,
      projects.map((project) => project.id)
    );
  };

  return (
    <div>
      {projects.length === 0 && <Placeholder>No projects yet</Placeholder>}

      {projects.map((project) => (
        <RowContainer key={project.id} onClick={() => handleProjectClick(project.id)}>
          <Row>
            <Image style={{ backgroundImage: `url(${project.imageUrl || project.streetViewUrl})` }} />

            <RowRight>
              <FirstLine>
                <Status status={project.status} />
                <Title>
                  <span>#{project.uid}:</span> {project.title}
                </Title>
              </FirstLine>
              <SecondLine>
                {project.stage && (
                  <Badge maxWidth="160px" color="#1D1D35" bgColor="#DFDFE8">
                    <span>{project.stage.name}</span>
                  </Badge>
                )}
                <OverdueBadge record={project} />
              </SecondLine>
            </RowRight>
          </Row>
        </RowContainer>
      ))}
    </div>
  );
};
