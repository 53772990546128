import { useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import rolesAPI from '@services/api/rolesAPI';
import { errorHandler } from '@services/api/helpers';
import { EMPTY_ROLE_SETTINGS } from '@utils';

export const useUserRoleSettings = (companyId?: number, userId?: number) => {
  return useQuery(
    [ReactQueryKey.WorkspaceRoles, companyId, userId],
    async () => {
      try {
        return (await rolesAPI.getAccessRules(companyId!)).data;
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      enabled: !!companyId && !!userId,
      initialData: EMPTY_ROLE_SETTINGS,
      staleTime: 1000 * 3600 * 24,
      initialDataUpdatedAt: Date.now() - 1000 * 3600 * 24
    }
  );
};
