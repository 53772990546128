import { SearchPartial } from '@components/Project/ProjectView/types';

import { Paging, Property, RecordType } from '@types';
import { deleteRequest, get, post, put } from './base';
import { generateParams } from './helpers';

import { Urls } from './base/axios';

export default {
  createColumn: (column: Property | { scope: RecordType }, search: SearchPartial) =>
    post<Property>(`${Urls.project}/table-view/project-columns`, column, generateParams({ search })),
  updateColumn: (column: Property, search: SearchPartial) =>
    put<Property>(`${Urls.project}/table-view/project-columns/${column.id}`, column, generateParams({ search })),
  deleteColumn: (id: number, search: SearchPartial) =>
    deleteRequest(`${Urls.project}/table-view/project-columns/${id}`, generateParams({ search })),
  findByMappedName: (mappedName: string) =>
    get<Property>(`${Urls.project}/table-view/project-columns/by-name/${mappedName}`),
  fetchColumns: (search: SearchPartial) => get<Paging<Property>>(
    `${Urls.project}/table-view/project-columns?sorts[0][col]=id&sorts[0][desc]=false`,
    generateParams({ search })
  )
};
