import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Alert as AlertMui } from '@material-ui/lab';

import { alertClose } from '@state/actions/alert/alertAction';
import IAlertReducerTypes from '@state/reducers/alert/types';

import IProps from './types';

const Alert: React.FC<Props> = (props) => {
  const { message, type, show, onClose } = props;

  useEffect(() => {
    if (show) {
      setTimeout(onClose, 4500);
    }
  }, [show]);

  return show ? (
    message && message.length > 1 ? (
      <>
        {message.map((mes, idx) => {
          return (
            <AlertMui
              key={idx}
              style={{
                position: 'fixed',
                bottom: idx === 0 ? '70px' : `${70 + 50}px`,
                right: '40px',
                transition: 'all .3s ease'
              }}
              onClose={onClose}
              severity={type}
            >
              {/* eslint-disable-next-line react/no-danger */}
              <span dangerouslySetInnerHTML={{ __html: mes }} />
            </AlertMui>
          );
        })}
      </>
    ) : (
      <AlertMui
        style={{
          position: 'fixed',
          bottom: '70px',
          right: '30px',
          transition: 'all .3s ease',
          zIndex: '100000'
        }}
        onClose={onClose}
        severity={type}
      >
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: message[0] }} />
      </AlertMui>
    )
  ) : null;
};

const mapStateToProps = (state: { alert: IAlertReducerTypes }) => {
  return {
    show: state.alert.show,
    message: state.alert.message,
    type: state.alert.type
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClose: () => dispatch(alertClose())
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & IProps;

export default connector(Alert);
