import {
  WorkOrderTemplateListItem,
  useWorkOrderTemplateList
} from '@hooks/templates/workOrders/useWorkOrderTemplateList';
import { useMemo } from 'react';

export const useWorkOrderTemplatesMap = () => {
  const { data: templates = [], isLoading } = useWorkOrderTemplateList();

  const workOrderTemplatesMap = useMemo(
    () =>
      templates.reduce(
        (acc, template) => {
          acc[template.id] = template;

          return acc;
        },
        {} as Record<number, WorkOrderTemplateListItem>
      ),
    [templates]
  );

  return {
    workOrderTemplatesMap,
    isWorkOrderTemplatesLoading: isLoading
  };
};
