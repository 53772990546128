import { ModalBody, ModalFooter } from '@common/PromiseModal';
import { CommentType, ProjectStatus } from '@generated/types/graphql';
import { Button, ButtonVariant } from '@kit/ui/Button';
import React from 'react';
import { PROJECT_STATUS_OPTIONS } from '@features/Analytics/constants';
import { useComments } from '@hooks/useComments';
import { Form, RichTextField, useForm } from '@kit/components/Form';
import { useMentions } from '@hooks/useMentions';
import { convertQuillFormatToText } from '@utils/quill';
import { Description } from './styled';

interface Props {
  project: {
    id: number;
    uid: number;
    title: string;
    status: ProjectStatus;
  };
  newStatus: ProjectStatus;
  onClose: () => void;
}

interface FormValues {
  comment: string;
}

export const StatusChangeComment = ({ project, newStatus, onClose }: Props) => {
  const statusDisplayName = PROJECT_STATUS_OPTIONS.find((option) => option.id === newStatus)?.name;

  const oldStatusDisplayName = PROJECT_STATUS_OPTIONS.find((option) => option.id === project.status)?.name;

  const { getMentions } = useMentions(project.id);

  const {
    create: { mutateAsync: createComment }
  } = useComments();

  const {
    handleSubmit,
    form: {
      control,
      formState: { isSubmitting }
    }
  } = useForm<FormValues>({
    onSubmit: async ({ comment }) => {
      await createComment({
        projectId: project.id,
        comment: convertQuillFormatToText(comment),
        type: CommentType.Project
      });

      onClose();
    }
  });

  return (
    <Form onSubmit={handleSubmit}>
      <ModalBody width="752px">
        <Description>
          You’ve changed the Status for{' '}
          <b>
            Project #{project.uid}: {project.title}
          </b>{' '}
          from “<b>{oldStatusDisplayName}</b>“ to “<b>{statusDisplayName}</b>“.
          <br /> Add an optional comment with an explanation.
        </Description>
        <div>
          <RichTextField control={control} name="comment" label="Comment" getMentions={getMentions} autoFocus />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button variant={ButtonVariant.Secondary} onClick={onClose}>
          Cancel
        </Button>
        <Button type="submit" disabled={isSubmitting} variant={ButtonVariant.Primary}>
          Comment
        </Button>
      </ModalFooter>
    </Form>
  );
};
