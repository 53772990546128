import { WidgetSettings } from '@features/Analytics/types';
import React from 'react';
import { DataTable } from '@features/Analytics/DataTable';
import { isSettingsValid } from '@features/Analytics/helpers';
import { Title } from './styled';

interface Props {
  settings: WidgetSettings;
}

export const DataPreview = ({ settings }: Props) => {
  const isValid = isSettingsValid(settings);

  return (
    <div>
      <Title>Data preview</Title>

      {isValid && <DataTable settings={settings} />}
      {!isValid && <div>Fill all required fields to see the preview</div>}
    </div>
  );
};
