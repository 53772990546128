import React, { useEffect, useRef } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { useClickOutside } from '@react-hookz/web';

import { noop, set } from 'lodash';
import { FormField } from '../../FormField';
import { FormControl, FormInputPropsToOmit } from '../../types';
import { useControllerWithValidation } from '../../useControllerWithValidation';
import { RichEditor } from './styled';

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends Omit<React.ComponentProps<typeof RichEditor>, FormInputPropsToOmit>,
    FormControl<TFieldValues, TName> {
  isToolbarShown?: boolean;
  isToolbarHiddenCompletely?: boolean;
  isOneLine?: boolean;
}

export const RichTextField = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  label,
  description,
  name,
  control,
  isToolbarShown = true,
  isToolbarHiddenCompletely = false,
  isOneLine = false,
  clearOnUnmount,
  onBlur,
  ...inputProps
}: Props<TFieldValues, TName>) => {
  const editorRef = useRef();
  const editorContainerNodeRef = useRef();

  useClickOutside(editorContainerNodeRef, () => {
    onBlur?.();
  });

  useEffect(() => {
    if (isToolbarShown) {
      return;
    }
    let editorInstance: any = null;
    try {
      editorInstance = editorRef.current?.getEditor();
    } catch {
      editorInstance = null;
    }
    set(editorInstance, 'keyboard.bindings[13][5].handler', noop);
  }, [isToolbarShown]);

  const {
    field: { ...controlProps },
    fieldState: { error }
  } = useControllerWithValidation(name, control, label, clearOnUnmount);

  return (
    <FormField name={name} label={label} error={error?.message} description={description}>
      <div ref={editorContainerNodeRef}>
        <RichEditor
          isOneLine={isOneLine}
          hideToolbar={!isToolbarShown}
          hideToolbarCompletly={isToolbarHiddenCompletely}
          editorRef={editorRef}
          {...controlProps}
          {...inputProps}
          data-test-id={`field-${controlProps.name}`}
          formikMode
        />
      </div>
    </FormField>
  );
};
