import GoogleCalendarApi from '../utils/google-calendar-api';

// Returns header part sending requests
export const getHeaders = () => {
  const myHeaders = new Headers();
  const token = localStorage.getItem('token');
  if (token) {
    let parsedToken;
    try {
      parsedToken = JSON.parse(token);

      if (parsedToken?.accessToken?.token) {
        myHeaders.append('Authorization', `Bearer ${parsedToken.accessToken.token}`);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error parsing token: ', error);
    }
  }
  myHeaders.append('Content-Type', 'application/json');

  return myHeaders;
};

const getFileHeader = () => {
  const myHeaders = new Headers();
  const token = localStorage.getItem('token');
  if (token) {
    const myToken = JSON.parse(token).accessToken.token;
    myHeaders.append('Authorization', `Bearer ${myToken}`);
  }

  return myHeaders;
};

export const appendHeaderForCalendar = (header: Headers) => {
  const { uc } = GoogleCalendarApi.getCurrentUser();
  if (uc) {
    header.append('calendarToken', uc.access_token);
  } else {
    GoogleCalendarApi.handleAuthClick();
  }

  return header;
};

// Returns configiuration for fetch api
export const getConfig = (data: any, request: string) => {
  switch (request) {
    case 'GET':
      return {
        method: request,
        headers: getHeaders()
      };
    case 'DELETE':
      return {
        method: request,
        headers: getHeaders(),
        body: data
      };
    case 'POST_FILE':
      return {
        method: 'POST',
        headers: getFileHeader(),
        body: data
      };
    default:
      return {
        method: request,
        headers: getHeaders(),
        body: data
      };
  }
};
