import React from 'react';
import { TableHead as TableHeadMui, TableHeadProps } from '@material-ui/core';

export const TableHead: React.FC<TableHeadProps> = (props) => {
  return (
    <TableHeadMui
      {...props}
    />
  );
};
