import React from 'react';

export const MegaphoneIcon = ({
  size = '16px',
  color = 'currentColor'
}: {
  size?: string | number;
  color?: string;
}) => (
  <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.5844 0.779167C13.7106 0.852446 13.8154 0.957601 13.8882 1.08411C13.961 1.21061 13.9994 1.35403 13.9994 1.5V4.83333C14.4683 4.83282 14.9321 4.93153 15.3603 5.12298C15.7884 5.31443 16.1712 5.59429 16.4835 5.94417C17.0311 6.55486 17.3335 7.34644 17.3327 8.16667C17.3327 9.02 17.011 9.8 16.4835 10.3892C16.1711 10.7389 15.7883 11.0187 15.3602 11.2101C14.9321 11.4016 14.4683 11.5004 13.9994 11.5V14.8333C13.9993 14.9793 13.9609 15.1226 13.8881 15.2491C13.8152 15.3755 13.7105 15.4806 13.5842 15.5539C13.458 15.6271 13.3148 15.6659 13.1688 15.6664C13.0229 15.6669 12.8794 15.629 12.7527 15.5567L7.33268 12.46V16.5C7.33268 16.721 7.24489 16.933 7.08861 17.0893C6.93232 17.2455 6.72036 17.3333 6.49935 17.3333H3.16602C2.945 17.3333 2.73304 17.2455 2.57676 17.0893C2.42048 16.933 2.33268 16.721 2.33268 16.5V12.3333C1.89065 12.3333 1.46673 12.1577 1.15417 11.8452C0.84161 11.5326 0.666016 11.1087 0.666016 10.6667V5.66667C0.666016 5.22464 0.84161 4.80072 1.15417 4.48816C1.46673 4.1756 1.89065 4 2.33268 4H7.11102L12.7527 0.776667C12.8794 0.704216 13.0229 0.666313 13.1689 0.666752C13.3148 0.667191 13.4581 0.705956 13.5844 0.779167ZM7.33268 10.6667C7.47768 10.6667 7.62016 10.7047 7.74602 10.7767L12.3327 13.3975V2.93583L7.74602 5.55667C7.62016 5.62867 7.47768 5.66658 7.33268 5.66667H2.33268V10.6667H7.33268ZM3.99935 12.3333V15.6667H5.66602V12.3333H3.99935ZM13.9994 9.83333C14.4927 9.83333 14.9352 9.62 15.2418 9.2775C15.506 8.9825 15.666 8.59417 15.666 8.16667C15.666 7.73917 15.506 7.35083 15.2418 7.05583C15.0858 6.88068 14.8943 6.74059 14.6802 6.64478C14.466 6.54898 14.234 6.49963 13.9994 6.5V9.83333Z"
      fill={color}
    />
  </svg>
);
