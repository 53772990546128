import { css } from 'styled-components';
import { mapValues } from '@utils/objects';

export const breakpoints = {
  xs: '480px',
  sm: '768px',
  md: '992px',
  lg: '1200px',
  xl: '1400px',
  xxl: '1620px'
};

const getMediaStatement = (value: number | string) => `(min-width: ${value})`;

export const medias = mapValues(breakpoints, getMediaStatement);

// https://tobbelindstrom.com/blog/how-to-create-a-breakpoint-mixin-with-styled-components/
export const device = Object.keys(breakpoints).reduce((accumulator, label) => {
  accumulator[label] = (...args) => css`
    @media (min-width: ${breakpoints[label]}) {
      ${css(...args)};
    }
  `;
  return accumulator;
}, {} as { [key in string]: typeof css });

export const deviceMax = Object.keys(breakpoints).reduce((accumulator, label) => {
  accumulator[label] = (...args) => css`
  @media (max-width: ${breakpoints[label]}) {
    ${css(...args)};
  }
`;
  return accumulator;
}, {} as { [key in string]: typeof css });

// Dynamic height for mobile
if (typeof window !== 'undefined') {
  const heights = () => {
    const doc = document.documentElement;
    doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    doc.style.setProperty(
      '--modal-body-height',
      `${window.innerHeight - 128}px`
    );
    doc.style.setProperty('--chat-height', `${window.innerHeight - 193}px`);
  };

  window.addEventListener('resize', heights);
  heights();
}
