import React, { useMemo, useState } from 'react';
import { Feed } from '@generated/types/graphql';
import { Tooltip, withStyles } from '@material-ui/core';
import { colors } from '@styles';
import { PinFilledIcon } from '@kit/ui/icons/PinFilled';
import { CommentItem } from '@features/ProjectPortfolio/Project/History/FeedItem/CommentItem';
import { useNavigate } from '@reach/router';
import { QueryParamsEnum } from '@hooks/useQueryParam';
import { Counter, TooltipContent, List, ListItem, Clickable } from './styled';

const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: colors.white,
    color: colors.black,
    boxShadow: '0 0 20px 0 rgba(0, 0, 74, 0.12)',
    maxWidth: 424
  }
}))(Tooltip);

interface Props {
  recordId: number;
  comments: Feed[];
}

export const PinnedCommentsPopover = ({ recordId, comments }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();
  const sortedComments = useMemo(() => {
    const copy = [...comments];

    copy.sort((a, b) => {
      const aDate = new Date(a.relatedComment?.pinnedAt);
      const bDate = new Date(b.relatedComment?.pinnedAt);

      return bDate.getTime() - aDate.getTime();
    });

    return copy;
  }, [comments]);

  if (!comments.length) {
    return null;
  }

  return (
    <LightTooltip
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      interactive
      title={
        <TooltipContent>
          <List>
            {sortedComments.map((commentFeedItem) => (
              <ListItem key={commentFeedItem.id}>
                <CommentItem
                  item={commentFeedItem}
                  context="portfolio"
                  contextEntityId={recordId}
                  visibleRepliesCount={0}
                />
                <Clickable
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`${recordId}?${QueryParamsEnum.FeedId}=${commentFeedItem.id}`);
                    setIsOpen(false);
                  }}
                />
              </ListItem>
            ))}
          </List>
        </TooltipContent>
      }
    >
      <Counter>
        <PinFilledIcon size="16px" color="#235dff" />
        {comments.length}
      </Counter>
    </LightTooltip>
  );
};
