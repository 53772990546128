import React, { useMemo } from 'react';
import { IconButton as IconButtonMui, makeStyles, IconButtonProps as IconButtonPropsMui } from '@material-ui/core';
import { iconButton } from './styled';
import { ShapeType } from './types';

interface IconButtonProps extends IconButtonPropsMui {
  shapeType?: ShapeType
  active?: boolean
}

export const IconButton: React.FC<IconButtonProps> = (props) => {
  const {
    shapeType = ShapeType.CIRCLE,
    active,
    ...rest
  } = props;

  const makeStylesParam = useMemo(() => (
    iconButton({ shapeType, active })
  ), [shapeType, active]);

  const classes = makeStyles(makeStylesParam)();

  return (
    <IconButtonMui
      classes={classes}
      centerRipple={false}
      focusRipple
      {...rest}
    />
  );
};
