import React, { useMemo } from 'react';
import { InputLabel as InputLabelMui, InputLabelProps, makeStyles, Tooltip } from '@material-ui/core';
import { inputLabel, Helper, TooltipText, RightSection } from './styled';

interface Props extends InputLabelProps {
  bold?: boolean
  rightSection?: any
  tooltip?: any
}

export const InputLabel: React.FC<Props> = (props) => {
  const {
    bold = false,
    children,
    rightSection,
    tooltip,
    ...rest
  } = props;

  const makeStylesParam = useMemo(() => inputLabel({ bold }), [bold]);
  const classes = makeStyles(makeStylesParam)();

  return (
    <InputLabelMui
      {...rest}
      classes={classes}
    >
      {children}
      {!!tooltip && (
        <Tooltip
          placement="top"
          arrow
          title={(
            <TooltipText>
              {tooltip}
            </TooltipText>
          )}
        >
          <Helper size={16} />
        </Tooltip>
      )}
      {rightSection && (
        <RightSection>{rightSection}</RightSection>
      )}
    </InputLabelMui>
  );
};
