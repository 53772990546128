import styled from 'styled-components';

export const FooterActions = styled.div`
  background: #ffffff;
  padding: 24px;
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: flex-end;
`;

export const Fields = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  padding: 24px 24px 8px;

  > * {
    flex: 1;
  }
`;
