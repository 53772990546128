import styled from 'styled-components';

export const NoDataContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const NoDataText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  width: 100%;
`;
