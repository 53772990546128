import { useAppSelector } from '@hooks/store';
import { getToken } from '@services/api/base/axios';
import { selectUser, selectWorkspaceId } from '@state/selectors';
import { useEffect } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as io from 'socket.io-client';
import { useQueryClient } from 'react-query';
import { EntityType, ReactQueryKey } from '@enums';
import { config } from '../../../../../config';

const socketInstance: { current: io.Socket | null } = {
  current: null
};

export const useSubscribeToUpdates = (workOrderId: number) => {
  const user = useAppSelector(selectUser);
  const token = getToken();
  const companyId = useAppSelector(selectWorkspaceId);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (user.userId && user.userId !== 0 && (socketInstance.current === null || !socketInstance.current.connected)) {
      // @ts-ignore
      socketInstance.current = io(`${config.socketIoUrl}`, {
        query: `jwt=${token}`,
        path: '/socket',
        rejectUnauthorized: false
      });

      socketInstance.current?.on('connect', () => {
        // socketInstance.current?.emit('join', `WORK-ORDER-CHANGES-${companyId}`);
        socketInstance.current?.emit('join', `FEED-CHANGES-${companyId}`);
        // can also leave channel by emitting @{leave} event
      });

      socketInstance.current?.on('FEED-CREATED', (payload) => {
        if (payload.event === EntityType.TASK && payload.taskId === workOrderId) {
          queryClient.invalidateQueries([ReactQueryKey.ProjectActivity, ReactQueryKey.WorkOrderActivity]);
        }
      });
    }

    return () => {
      if (socketInstance.current != null) {
        socketInstance.current.disconnect();
      }
    };
  }, [user, companyId, workOrderId, queryClient, token]);
};
