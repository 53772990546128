import * as muiColors from '@material-ui/core/colors';
import { uniqBy, toLower, defaults } from 'lodash';
import { defaultTheme } from '@themes';

export const COLOR_ARRAY = uniqBy([
  '#1d1d35',
  '#00B3E6',
  '#E6B333',
  '#3366E6',
  '#999966',
  '#99FF99',
  '#B34D4D',
  '#80B300',
  '#809900',
  '#E6B3B3',
  '#6680B3',
  '#66991A',
  '#FF99E6',
  '#CCFF1A',
  '#FF1A66',
  '#E6331A',
  '#33FFCC',
  '#66994D',
  '#B366CC',
  '#4D8000',
  '#B33300',
  '#CC80CC',
  '#66664D',
  '#991AFF',
  '#E666FF',
  '#4DB3FF',
  '#1AB399',
  '#E666B3',
  '#33991A',
  '#CC9999',
  '#B3B31A',
  '#00E680',
  '#4D8066',
  '#809980',
  '#E6FF80',
  '#1AFF33',
  '#999933',
  '#FF3380',
  '#CCCC00',
  '#66E64D',
  '#4D80CC',
  '#9900B3',
  '#E64D66',
  '#4DB380',
  '#FF4D4D',
  '#99E6E6',
  '#6666FF',
  '#1D1D35',
  '#FFFFFF'
], toLower);

export const parseHexValuesFromString = (value: string): [string, string, string] | null => {
  const normalized = value?.replace(
    /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
    (m, r, g, b) => r + r + g + g + b + b
  );

  const parsed = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(normalized);

  return (parsed && [parsed[1], parsed[2], parsed[3]]) || null;
};

/**
 * componentToHex convert two digit htx value to R, G or B channel value
 * @param  number c  value from 0 to 225
 * @return string    value of R, G or B channel
 * @usage            //componentToHex(255) // ff
 */
const componentToHex = (c: number) => {
  const hex = c.toString(16);

  return hex.length === 1 ? '0' + hex : hex;
};

/**
 * rgbToHex
 * @param  number r Red value
 * @param  number g Green value
 * @param  number b Blue value
 * @return string   hexadecimal value
 * @usage           //rgbToHex(0, 51, 255) // #0033ff
 */
export const rgbToHex = (r: number, g: number, b: number) => (
  '#' + componentToHex(r) + componentToHex(g) + componentToHex(b)
);

const hexToRgb = ($hex: string): [number, number, number] | null => {
  const result = parseHexValuesFromString($hex);

  if (result) {
    return [
      parseInt(result[0], 16),
      parseInt(result[1], 16),
      parseInt(result[2], 16)
    ];
  }

  return null;
};

export const getLighterBgColor = ($value: string) => {
  const found = Object.entries(defaultTheme.colors).find(([, value]) => {
    return toLower(value) === toLower($value);
  });

  return (found && defaultTheme.colors[`${found[0]}Bg`]) || changeHexColorHSL($value, { l: 0.28 });
};

/**
 * Converts an RGB color value to HSL. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes r, g, and b are contained in the set [0, 255] and
 * returns h, s, and l in the set [0, 1].
 *
 * @param   Number  r       The red color value
 * @param   Number  g       The green color value
 * @param   Number  b       The blue color value
 * @return  Array           The HSL representation
 */
export const rgbToHsl = (r: number, g: number, b: number) => {
  r /= 255;
  g /= 255;
  b /= 255;

  var max = Math.max(r, g, b), min = Math.min(r, g, b);
  var h, s, l = (max + min) / 2;

  if (max == min) {
    h = s = 0; // achromatic
  } else {
    var d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case r: h = (g - b) / d + (g < b ? 6 : 0); break;
      case g: h = (b - r) / d + 2; break;
      case b: h = (r - g) / d + 4; break;
    }

    h /= 6;
  }

  return [h, s, l];
};

/**
 * Converts an HSL color value to RGB. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes h, s, and l are contained in the set [0, 1] and
 * returns r, g, and b in the set [0, 255].
 *
 * @param   Number  h       The hue
 * @param   Number  s       The saturation
 * @param   Number  l       The lightness
 * @return  Array           The RGB representation
 */
export const hslToRgb = (h: number, s: number, l: number) => {
  let r, g, b;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    function hue2rgb(p, q, t) {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1/6) return p + (q - p) * 6 * t;
      if (t < 1/2) return q;
      if (t < 2/3) return p + (q - p) * (2/3 - t) * 6;

      return p;
    }

    var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    var p = 2 * l - q;

    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return [r * 255, g * 255, b * 255].map(Math.round);
};

export const changeHexColorHSL = (value: string, $hsl: { [key in 'h' | 's' | 'l']?: number; }) => {
  const diff = defaults($hsl, { h: 0, s: 0, l: 0 });
  const rgb = hexToRgb(value);

  if (!rgb) {
    return null;
  }

  const asHsl = rgbToHsl(...rgb);

  if (!asHsl) {
    return null;
  }

  const [h, s, l] = asHsl;

  return rgbToHex(...hslToRgb(h + diff.h, s + diff.s, l + diff.l));
};

const colors = Object.values(muiColors).reduce(
  (accum, value) => accum.concat([...Object.values(value)]),
  []
);

export const stringToColor = (value: string, schema: string[] = colors) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < value.length; i += 1) {
    hash = value.charCodeAt(i) + ((hash << 5) - hash);
  }

  return schema[Math.abs(hash) % schema.length];
};

export const getTextColor = (color: string) => {
  const hexColor = hexToRgb(color);

  if (!hexColor) {
    return '#222';
  }

  const colorValue = hexColor[0] * 299 + hexColor[1] * 587 + hexColor[2] * 114;

  return Math.round(colorValue / 1000) > 125 ? '#222' : '#fff';
};

const avatarColors = [
  '#1abc9c',
  '#2ecc71',
  '#3498db',
  '#9b59b6',
  '#34495e',
  '#16a085',
  '#27ae60',
  '#2980b9',
  '#8e44ad',
  '#2c3e50',
  '#f1c40f',
  '#e67e22',
  '#e74c3c',
  '#95a5a6',
  '#f39c12',
  '#d35400',
  '#c0392b',
  '#bdc3c7',
  '#7f8c8d'
];

const DEFAULT_USER_BG_COLOR = '#c0392b';
const DEFAULT_USER_TEXT_COLOR = '#fff';

export const getAvatarColors = (name?: string) => {
  if (!name || typeof name !== 'string') {
    return {
      bg: DEFAULT_USER_BG_COLOR,
      text: DEFAULT_USER_TEXT_COLOR
    };
  }

  const sum = name
    .split('')
    .reduce((acc, value, index) => acc + name.charCodeAt(index), 0);

  const bg = avatarColors[sum % avatarColors.length];

  return {
    bg,
    text: getTextColor(bg)
  };
};
