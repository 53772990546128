import React from 'react';

import { Container, Label, Square } from './styled';
import { WIDGET_TYPE_OPTIONS } from '../constants';

interface Props {
  isActive: boolean;
  option: (typeof WIDGET_TYPE_OPTIONS)[number];
}

export const WidgetTypeOption = ({ isActive, option }: Props) => {
  return (
    <Container>
      <Square isActive={isActive}>{option.icon}</Square>
      <Label isActive={isActive}>{option.label}</Label>
    </Container>
  );
};
