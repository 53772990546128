import React from 'react';
import { Container } from './styled';
import { Portal } from './Portal';

interface Props {
  children?: React.ReactNode;
  isFullScreen?: boolean;
  width?: string;
  height?: string;
  zIndexIncrement?: number;
}

export const SlidePanel = ({
  isFullScreen = false,
  children,
  width = '80%',
  height = 'calc(100vh - 48px)',
  zIndexIncrement = 0
}: Props) => {
  return (
    <Portal>
      <Container zIndexIncrement={zIndexIncrement} width={width} height={height} isFullScreen={isFullScreen}>
        {children}
      </Container>
    </Portal>
  );
};

export type { Props as SlidePanelProps };
