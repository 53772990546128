import { createGlobalState } from 'react-use';
import { QueryFunctionContext, useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as io from 'socket.io-client';

import { useAppDispatch, useAppSelector } from '@hooks/store';
import { selectCurrentUserId, selectUser } from '@state/selectors';
import { ProjectEventType, ReactQueryKey, TaskEventType } from '@enums';
import notificationsApi from '@services/api/notificationsApi';
import { apiErrorHandler } from '@utils';
import moment from 'moment';
import { ActivityFeedFromApi } from '@types';
import { Filter } from '@services/api/types';
import { getToken } from '@services/api/base/axios';
import { useEffect } from 'react';
import { alertShow } from '@state/actions/alert/alertAction';
import { config } from '../config';

export enum FilterBy {
  ALL = 'All',
  ASSIGNED_TO_ME = 'Assigned to me',
  MENTIONS = '@Mentions'
}

const DEFAULT_PER_PAGE = 10;
const NOTIFICATION_FILTERS: {
  [key in FilterBy]: { filters: Filter[] };
} = {
  [FilterBy.ALL]: {
    filters: []
  },
  [FilterBy.ASSIGNED_TO_ME]: {
    filters: [
      {
        col: 'eventType',
        val: TaskEventType.ASSIGNED_TO_ME,
        op: '='
      }
    ]
  },
  [FilterBy.MENTIONS]: {
    filters: [
      {
        col: 'eventType',
        val: [ProjectEventType.COMMENT_MENTIONED, TaskEventType.COMMENT_MENTIONED].join(','),
        op: 'in'
      }
    ]
  }
};

export type NotificationFilter = {
  filterBy: FilterBy;
  onlyUnread: boolean;
};

const useFilterState = createGlobalState<NotificationFilter>({
  filterBy: FilterBy.ALL,
  onlyUnread: false
});

export const useNotifications = () => {
  const queryClient = useQueryClient();
  const userId = useAppSelector(selectCurrentUserId);
  const [filterBy, setFilterBy] = useFilterState();
  const QUERY_KEY_NOTIFICATION = [ReactQueryKey.Notifications, filterBy];
  const dispatch = useAppDispatch();

  const fetchNotificationQuery = useInfiniteQuery(
    QUERY_KEY_NOTIFICATION,
    async ({ pageParam = 1, queryKey }: QueryFunctionContext) => {
      try {
        const [, filterBy] = queryKey as [string, NotificationFilter];
        const { data } = await notificationsApi.findUserNotifications(
          userId,
          {
            page: pageParam,
            perPage: DEFAULT_PER_PAGE,
            sortCol: 'createdAt',
            sortDesc: true,
            fetchAll: false
          },
          {
            filters: [
              ...NOTIFICATION_FILTERS[filterBy.filterBy].filters,
              {
                col: 'createdAt',
                op: '<',
                val: moment().add(1, 'months').toISOString()
              }
            ]
          },
          {
            read: filterBy.onlyUnread ? false : undefined,
            withSelf: false
          }
        );

        const page = pageParam as number;

        return {
          ...data,
          page: page as number,
          nextPage: DEFAULT_PER_PAGE * page < data.total ? page + 1 : undefined,
          previousPage: page > 1 ? page - 1 : undefined
        };
      } catch (error) {
        throw apiErrorHandler('exception on notification fetch.', error);
      }
    },
    {
      getPreviousPageParam: (firstPage) => firstPage.previousPage,
      getNextPageParam: (lastPage) => lastPage.nextPage,
      onError: () => {
        dispatch(alertShow(["Couldn't fetch the notifications"], 'error'));
      }
    }
  );

  const fetchUnreadCount = useQuery(
    ReactQueryKey.UnreadNotificationCount,
    async () => {
      try {
        const {
          data: { unreadCount }
        } = await notificationsApi.unreadNotificationsCount();

        return unreadCount;
      } catch (error) {
        throw apiErrorHandler('exception on unread notification count.', error);
      }
    },
    {
      onError: () => {
        dispatch(alertShow(["Couldn't fetch the unread notification count"], 'error'));
      },
      enabled: true
    }
  );

  const resetFeedReadState = async () => {
    await Promise.all(
      [
        ReactQueryKey.Inbox,
        ReactQueryKey.Notifications,
        ReactQueryKey.UnreadNotificationCount,
        ReactQueryKey.InboxUnreadCount
      ].map((key) => queryClient.invalidateQueries(key))
    );
  };

  const toggleReadMutation = useMutation(
    async (feedId: number) => {
      try {
        const {
          data: { read }
        }: { data: { read: boolean } } = await notificationsApi.readNotification(feedId);

        return {
          feedId,
          read
        };
      } catch (error) {
        throw apiErrorHandler('exception on toggle notification read.', error);
      }
    },
    {
      onSuccess: async () => {
        await resetFeedReadState();
      },
      onError: () => {
        dispatch(alertShow(["Couldn't toggle read for the notification"], 'error'));
      }
    }
  );

  const markAllAsReadMutation = useMutation(
    async () => {
      try {
        await notificationsApi.readAllNotifications();
      } catch (error) {
        throw apiErrorHandler('exception on mark all as read.', error);
      }
    },
    {
      onSuccess: async () => {
        await resetFeedReadState();
      },
      onError: () => {
        dispatch(alertShow(["Couldn't read all notifications"], 'error'));
      }
    }
  );

  return {
    fetch: {
      ...fetchNotificationQuery,
      filterBy,
      setFilterBy: ($filterBy: Partial<NotificationFilter>) => setFilterBy({ ...filterBy, ...$filterBy })
    },
    toggleRead: toggleReadMutation,
    markAllAsRead: markAllAsReadMutation,
    unreadCount: fetchUnreadCount
  };
};

const socketInstance: { current: io.Socket | null } = {
  current: null
};

export const useNotificationSocket = ({
  onNewNotification
}: {
  onNewNotification: (notification: ActivityFeedFromApi) => unknown;
}) => {
  const user = useAppSelector(selectUser);
  const token = getToken();

  useEffect(() => {
    if (user.userId && user.userId !== 0 && (socketInstance.current === null || !socketInstance.current.connected)) {
      // @ts-ignore
      socketInstance.current = io(`${config.socketIoUrl}`, {
        query: `jwt=${token}`,
        path: '/socket',
        rejectUnauthorized: false
      });

      socketInstance.current?.on('general', (notification: string) => {
        // transform the notification and add to the notification list
        const newNotification = JSON.parse(notification) as ActivityFeedFromApi;

        newNotification.id = newNotification.payload.feedId as number;
        newNotification.createdAt = newNotification.payload.createdAt as string;

        onNewNotification(newNotification);
      });
    }

    return () => {
      if (socketInstance.current != null) {
        socketInstance.current.disconnect();
      }
    };
  }, [user]);

  return {
    socket: socketInstance.current
  };
};
