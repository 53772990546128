import { TimeGranularity } from '@features/Analytics/types';
import { TaskReportGroupBy } from '@generated/types/graphql';

export const TIME_GRANULARITY_TO_CREATED_AT_GROUP_BY: Record<TimeGranularity, TaskReportGroupBy> = {
  [TimeGranularity.DAY]: TaskReportGroupBy.CreatedAtTruncatedToDay,
  [TimeGranularity.WEEK]: TaskReportGroupBy.CreatedAtTruncatedToWeek,
  [TimeGranularity.MONTH]: TaskReportGroupBy.CreatedAtTruncatedToMonth,
  [TimeGranularity.QUARTER]: TaskReportGroupBy.CreatedAtTruncatedToQuarter,
  [TimeGranularity.YEAR]: TaskReportGroupBy.CreatedAtTruncatedToYear
};

export const TIME_GRANULARITY_TO_TIMELINE_STATUS_STARTED_AT_GROUP_BY: Record<TimeGranularity, TaskReportGroupBy> = {
  [TimeGranularity.DAY]: TaskReportGroupBy.TimelineStatusStartedAtTruncatedToDay,
  [TimeGranularity.WEEK]: TaskReportGroupBy.TimelineStatusStartedAtTruncatedToWeek,
  [TimeGranularity.MONTH]: TaskReportGroupBy.TimelineStatusStartedAtTruncatedToMonth,
  [TimeGranularity.QUARTER]: TaskReportGroupBy.TimelineStatusStartedAtTruncatedToQuarter,
  [TimeGranularity.YEAR]: TaskReportGroupBy.TimelineStatusStartedAtTruncatedToYear
};

export const TIME_GRANULARITY_TO_TIMELINE_STATUS_ENDED_AT_GROUP_BY: Record<TimeGranularity, TaskReportGroupBy> = {
  [TimeGranularity.DAY]: TaskReportGroupBy.TimelineStatusEndedAtTruncatedToDay,
  [TimeGranularity.WEEK]: TaskReportGroupBy.TimelineStatusEndedAtTruncatedToWeek,
  [TimeGranularity.MONTH]: TaskReportGroupBy.TimelineStatusEndedAtTruncatedToMonth,
  [TimeGranularity.QUARTER]: TaskReportGroupBy.TimelineStatusEndedAtTruncatedToQuarter,
  [TimeGranularity.YEAR]: TaskReportGroupBy.TimelineStatusEndedAtTruncatedToYear
};

export const TIME_GRANULARITY_TO_COMPLETED_AT_GROUP_BY: Record<TimeGranularity, TaskReportGroupBy> = {
  [TimeGranularity.DAY]: TaskReportGroupBy.CompletionDateTruncatedToDay,
  [TimeGranularity.WEEK]: TaskReportGroupBy.CompletionDateTruncatedToWeek,
  [TimeGranularity.MONTH]: TaskReportGroupBy.CompletionDateTruncatedToMonth,
  [TimeGranularity.QUARTER]: TaskReportGroupBy.CompletionDateTruncatedToQuarter,
  [TimeGranularity.YEAR]: TaskReportGroupBy.CompletionDateTruncatedToYear
};

export const TIME_GRANULARITY_TO_VISIT_STARTED_AT_GROUP_BY: Record<TimeGranularity, TaskReportGroupBy> = {
  [TimeGranularity.DAY]: TaskReportGroupBy.VisitStartDateTruncatedToDay,
  [TimeGranularity.WEEK]: TaskReportGroupBy.VisitStartDateTruncatedToWeek,
  [TimeGranularity.MONTH]: TaskReportGroupBy.VisitStartDateTruncatedToMonth,
  [TimeGranularity.QUARTER]: TaskReportGroupBy.VisitStartDateTruncatedToQuarter,
  [TimeGranularity.YEAR]: TaskReportGroupBy.VisitStartDateTruncatedToYear
};
