import { useMutation, useQueryClient } from 'react-query';
import { SendEmailDTO, EmailMessage, SendBulkEmailDto } from '@types';
import emailApi from '@services/api/emailAPI';
import { useToast } from '@hooks/useToast';
import { apiErrorHandler, invalidateQueriesBy, formatDate, sleep } from '@utils';
import { ReactQueryKey } from '@enums';
import { useSelector } from 'react-redux';
import { selectWorkspaceId } from '@state/selectors';

export const useEmails = () => {
  const { showSuccess, showError } = useToast();
  const queryClient = useQueryClient();
  const companyId = useSelector(selectWorkspaceId);

  const sendMutation = useMutation<EmailMessage, Error, { dto: SendEmailDTO; recordId: number; accountId: number }>(
    async ({ dto, recordId, accountId }) => {
      try {
        const { data } = await emailApi.sendEmail(recordId, dto, accountId);
        await sleep(500);

        return data;
      } catch (e) {
        showError(e?.response?.data?.message || 'Error on sending email');

        throw apiErrorHandler('Error on sending email', e);
      }
    },
    {
      onSuccess: (data, { dto }) => {
        if (dto.sendAt === 'now') {
          showSuccess('Email successfully sent');
        } else {
          showSuccess(`Email successfully scheduled to send at ${formatDate(dto.sendAt, 'MM/DD/YY hh:mm A')}`);
        }

        invalidateQueriesBy(queryClient, ReactQueryKey.ProjectActivity);
      }
    }
  );

  const sendBulkEmail = useMutation<void, Error, SendBulkEmailDto>(
    async (dto) => {
      try {
        await emailApi.sendBulkEmail({ companyId, dto });
      } catch (e) {
        showError(e?.response?.data?.message || 'Error on sending email');

        throw apiErrorHandler('Error on sending email', e);
      }
    },
    {
      onSuccess: () => {
        showSuccess('Emails successfully sent');
        invalidateQueriesBy(queryClient, ReactQueryKey.ProjectActivity);
      }
    }
  );

  return {
    sendMutation,
    sendBulkEmail
  };
};
