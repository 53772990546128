export enum UiSizes {
  XXTiny = 'xxtiny',
  XTiny = 'xtiny',
  Tiny = 'tiny',
  Small = 'small',
  Medium = 'medium',
  Large = 'large'
}

export const UiSizesValues = {
  [UiSizes.XXTiny]: 16,
  [UiSizes.XTiny]: 20,
  [UiSizes.Tiny]: 24,
  [UiSizes.Small]: 32,
  [UiSizes.Medium]: 40,
  [UiSizes.Large]: 52
};

export enum FieldTypes {
  Text = 'text'
}
