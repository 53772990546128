import React, { useEffect, useMemo } from 'react';

import { FeedInbox } from '@generated/types/graphql';
import { useAppSelector } from '@hooks/store';
import { selectCurrentUserId } from '@state/selectors';
import { EntityType } from '@enums';
import { WorkOrderContainer } from '@components/Scheduler/components/WorkOrderView/WorkOrderContainer';
import { useNotification } from '@hooks/notifications/useNotification';
import { RecordType } from '@types';
import { Client } from '@features/ClientPortfolio/Client';
import { Project } from '@features/ProjectPortfolio/Project';
import { Request } from '@features/RequestPortfolio/Request';
import { FileContainer } from '@components/Project/DataBank/DataBankContent/FileList/FileProperties/FileProperties';
import { useMarkReadNotification } from '@hooks/notifications/useMarkReadNotification';
import { navigate } from 'gatsby';
import { UserAvatar } from '@kit/components/UserAvatar';
import { DrawerEntity, useDrawersContext } from '@contexts/DrawersContext';
import { ContactAvatar } from '@kit/components/ContactAvatar';
import { System } from '@features/SystemPortfolio/System';
import { getHumanFriendlyTimeAgo } from '../List/List';
import { Author, Container, NotificationContainer } from './styled';
import { getNotificationInfo } from '../List/helpers';

interface Props {
  id: number;
  isFullScreen?: boolean;
}

const NotificationBody = ({ notification, isFullScreen }: { notification: FeedInbox; isFullScreen?: boolean }) => {
  switch (notification.feed.event) {
    case EntityType.TASK:
      return <WorkOrderContainer id={notification.feed.payload.id} />;
    case EntityType.SMS:
    case EntityType.EMAIL:
    case EntityType.PROJECT:
    case EntityType.WORKFLOW:
    case EntityType.REMINDER:
    case EntityType.PORTAL:
    case EntityType.ACTION: {
      const recordType = notification.feed.project.type as RecordType;
      if (recordType === RecordType.ACCOUNT) {
        return <Client clientId={notification.feed.project.id} />;
      }
      if (recordType === RecordType.PROJECT) {
        return <Project projectID={notification.feed.project.id} />;
      }

      if (recordType === RecordType.DEAL) {
        return <Request requestId={notification.feed.project.id} />;
      }

      return null;
    }
    case EntityType.FILE:
    case EntityType.FORM: {
      return <FileContainer id={notification.feed.payload.id} isFullScreen={isFullScreen} />;
    }

    case EntityType.SYSTEM:
      return <System systemId={notification.feed.payload.id} />;

    default:
      return null;
  }
};

export const CurrentNotification = ({ id, isFullScreen }: Props) => {
  const { data: notification, isLoading } = useNotification(id ?? undefined);
  const { mutateAsync: markRead } = useMarkReadNotification();

  const { openDrawer } = useDrawersContext();

  const userId = useAppSelector(selectCurrentUserId);

  const info = useMemo(() => {
    if (!notification) {
      return null;
    }

    return getNotificationInfo(notification, userId);
  }, [notification, userId]);

  useEffect(() => {
    if (!notification) {
      return;
    }

    if (info.feedCursor) {
      setTimeout(() => {
        navigate(`?feedId=${notification.feed.id}&feedCursor=${info.feedCursor}`);
      }, 0);
    } else if (notification.feed?.reminder) {
      setTimeout(() => {
        navigate(`?reminderId=${notification.feed.reminder?.id}`);
      }, 1000);
    } else if (notification.entityType === EntityType.ACTION) {
      setTimeout(() => {
        openDrawer(DrawerEntity.ACTION_ITEM, notification.feed.payload.id, []);
      }, 0);
    }

    const isRead = notification?.read;

    if (!isRead) {
      markRead({
        inboxIds: [notification.id],
        read: true
      });
    }
    // openDrawer excluded from deps because it is changed when URL changed
    // and it triggers setting feedId and feedCursor in URL again
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification, markRead, userId, info]);

  if (isLoading || !notification || !info) {
    return null;
  }

  return (
    <Container>
      <NotificationContainer>
        <div className="title">
          <div>
            {info.icon}

            {info.author && (
              <Author>
                <UserAvatar user={info.author} size={20} />
                <div>
                  {info.author.firstName} {info.author.lastName}
                </div>
              </Author>
            )}

            {info.contact && (
              <Author>
                <ContactAvatar contact={info.contact} />

                <div>{info.contact.name}</div>
              </Author>
            )}
          </div>
        </div>
        <div className="content">{info.content ? <div>{info.content}</div> : info.contentNode}</div>
        <div className="time">{getHumanFriendlyTimeAgo(notification.createdAt)}</div>
      </NotificationContainer>

      <NotificationBody notification={notification} isFullScreen={isFullScreen} />
    </Container>
  );
};
