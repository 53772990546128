import { IActionType } from '../actionTypes/actionTypeInterface';
import * as actionTypes from '../actionTypes/actionTypes';

/**
 * @deprecated use {@link useToast} or create new hook for react-query
 */
export const alertShow = (
  message: string[],
  type: 'success' | 'info' | 'warning' | 'error'
): IActionType => {
  return {
    type: actionTypes.MESSAGE_ALERT_SHOW,
    payload: {
      message,
      type
    }
  };
};

export const alertClose = (): IActionType => {
  return {
    type: actionTypes.MESSAGE_ALERT_CLOSE
  };
};
