import styled from 'styled-components';
import { getColorWithOpacity, colors } from '@styles';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const AvatarWrapper = styled.div`
  margin-right: 10px;
`;

export const Title = styled.div`
  font-size: 12px;
  line-height: 1.25;
`;

export const MainTitle = styled.span`
  font-weight: 400;
  font-size: 14px;
`;

export const ExtraTitle = styled.span<{ noHeight: boolean }>`
  font-weight: 300;

  span {
    font-weight: 500;
  }

  height: ${(props) => (props.noHeight ? 'unset' : 'auto')};
  white-space: ${(props) => (props.noHeight ? 'unset' : 'auto')};
`;

export const Description = styled.div<{ color: string }>`
  font-size: 12px;
  line-height: 1.25;

  color: ${(props) => props.color};
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

export const ProjectLabel = styled.div`
  position: relative;
  background-color: ${getColorWithOpacity(colors.green, 20)};
  color: ${colors.green};
  border-radius: 4px;
  font-size: 12px;
  padding: 0 4px;
  height: 24px;
  display: flex;
  align-items: center;
  margin-left: 15px;

  &:before {
    content: '';
    position: absolute;
    left: -8px;
    width: 3px;
    height: 3px;
    background-color: ${colors.gray};
    border-radius: 3px;
  }
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
`;
