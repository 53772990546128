import React from 'react';
import { Paperclip } from 'react-feather';
import { colors } from '@styles';
import { getFixedFilename } from '@utils/files';
import { Badge } from '@common/Badge';
import { FileX } from './styled';

type FileBadgeProps = {
  file: { name: string; downloadUrl: string };
  onRemove?: () => void;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};

export const FileBadge: React.FC<FileBadgeProps> = ({ file, onRemove, onClick }) => (
  <Badge color={colors.gray} onClick={onClick}>
    <Paperclip size={20} color={colors.gray2} />
    <a
      onClick={(event) => {
        if (onClick) {
          event.preventDefault();
        }
      }}
      href={file.downloadUrl}
      target="_blank"
      rel="noreferrer"
    >
      {getFixedFilename(file.name)}{' '}
    </a>

    {onRemove && (
      <FileX
        onClick={(e) => {
          e.stopPropagation();
          onRemove();
        }}
      />
    )}
  </Badge>
);
