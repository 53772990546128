import styled from 'styled-components';

export const Widget = styled.div`
  height: auto;
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  border: 1px solid #dfdfe8;

  display: flex;
  gap: 16px;
`;

export const WidgetLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const WidgetRight = styled.div`
  min-height: 144px;
  height: 144px;
  width: 100%;
`;

export const WidgetTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const WidgetIcon = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

export const WidgetMetric = styled.div`
  font-size: 32px;
  font-weight: 400;
`;

export const WidgetMetricLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #828D9A;
`;