import { Project } from '@generated/types/graphql';
import React from 'react';
import { DrawerEntity, useDrawersContext } from '@contexts/DrawersContext';
import { Status } from '@features/RequestPortfolio/components/Status';
import { Badge } from '@kit/ui/Badge';
import { Placeholder, RowContainer } from '../styled';
import { CenteredRow, Title, TitleCell } from './styled';

interface Props {
  requests: Project[];
}

export const RequestList = ({ requests }: Props) => {
  const { openDrawer } = useDrawersContext();

  const handleRequestClick = (id: number) => {
    openDrawer(
      DrawerEntity.REQUEST,
      id,
      requests.map((request) => request.id)
    );
  };

  return (
    <div>
      {requests.length === 0 && <Placeholder>No requests yet</Placeholder>}

      {requests.map((request) => (
        <RowContainer key={request.id} onClick={() => handleRequestClick(request.id)}>
          <CenteredRow>
            <TitleCell>
              <Title>
                <Status status={request.requestStatus} />
                <span>#{request.uid}:</span> {request.title}
              </Title>
              <div>
                {request.stage && (
                  <Badge maxWidth="160px" color="#1D1D35" bgColor="#DFDFE8">
                    <span>{request.stage.name}</span>
                  </Badge>
                )}
              </div>
            </TitleCell>
          </CenteredRow>
        </RowContainer>
      ))}
    </div>
  );
};
