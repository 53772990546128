import { Badge, BadgeSize } from '@kit/ui/Badge';
import { LabelIcon } from '@kit/ui/icons/Label';
import React from 'react';

interface Props {
  label: {
    label: string;
    color: string;
  };

  size?: BadgeSize;
}

export const Label = ({ label, size = BadgeSize.Large }: Props) => {
  return (
    <Badge size={size} color={label.color} bgColor={`${label.color}33`}>
      <LabelIcon size="16px" />
      <span>{label.label}</span>
    </Badge>
  );
};
