import { debounce } from 'lodash/fp';
import React, { useState, useCallback, useRef } from 'react';
import { SearchBar } from '@common/SearchBar';
import { useClickOutside } from '@react-hookz/web';
import { Container } from './styled';

interface Props {
  setFilter: (key: string, value: any) => unknown;
}

export const Search = ({ setFilter }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const valueRef = useRef('');

  const nodeRef = useRef(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchChange = useCallback(
    debounce(400, (value) => {
      setFilter('search', value);
    }),
    [setFilter]
  );

  const handleChange = useCallback(
    (value) => {
      valueRef.current = value;
      handleSearchChange(value);
    },
    [handleSearchChange]
  );

  const expand = useCallback(() => {
    setIsOpen(true);
  }, []);

  useClickOutside(nodeRef, () => {
    if (!valueRef.current) {
      setIsOpen(false);
    }
  });

  return (
    <Container ref={nodeRef} isOpen={isOpen} onClick={expand}>
      <SearchBar placeholder="Search..." onValueChange={handleChange} />
    </Container>
  );
};
