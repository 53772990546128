import React from 'react';
import styled from 'styled-components';

export const EmojiAndName = styled.div`
  display: flex;
  gap: 40px;

  > * {
    &:first-child {
      width: 80px;
      min-width: 80px;
      flex-shrink: 0;

      input {
        font-size: 20px;
      }
    }
    &:last-child {
      flex: 1;
    }
  }
`;

const Menu = styled.div`
  && {
    box-shadow: 0px 0px 16px 0px rgba(0, 150, 136, 0.12);

    width: 256px;
    height: 176px;
    background-color: #fff;
    border-radius: 4px;

    ul {
      display: flex;
      flex-wrap: wrap;
      padding: 8px;

      li {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        margin: 0;
      }
    }
  }
`;

export const EmojiList = React.forwardRef((props, ref) => (
  <Menu {...props} ref={ref as React.RefObject<HTMLDivElement>} />
));
