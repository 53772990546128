import styled, { css } from 'styled-components';
import { Field as FormikField } from 'formik';
import { HelpCircle, X } from 'react-feather';
import {
  MenuItem as MuiMenuItem,
  makeStyles,
  Tooltip as MuiTooltip,
  Checkbox as MUiCheckbox,
  ListSubheader as MuiListSubheader
} from '@material-ui/core';
import { colors, dropdown, fonts, checkboxMui } from '@styles';

const stylesCheckbox = checkboxMui({ size: '30px' });

export const useStylesCheckbox = makeStyles(stylesCheckbox);

export const Tooltip = styled(MuiTooltip)`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.25;
  margin-left: auto;
  font-family: ${fonts.primary};
`;

export const Helper = styled(HelpCircle)`
  margin: 0 auto 0 5px;
  color: ${colors.gray};
  transition: 0.3s ease-out;

  &:hover {
    color: ${colors.green};
  }
`;

export const Checkbox = styled(MUiCheckbox)`
  && {
    margin: 0 -5px 0 auto;
    min-width: 0 !important;
  }
`;

export const TooltipProperty = styled.div`
  font-size: 12px;
  line-height: 1.25;
  font-family: ${fonts.primary};
`;

export const MenuItem = styled(MuiMenuItem)`
  && {
    ${dropdown.item};
    min-width: auto;
    width: calc(100% - 20px);
    margin-left: 10px;
    padding: 0 7px;
  }
`;

export const Remove = styled(X)`
  cursor: pointer;
  color: ${colors.gray};
  margin: 0 0px 0 auto !important;

  &:hover,
  &:focus {
    color: ${colors.green};
  }
`;

export const Dot = styled.div<{ color: string }>`
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 8px;
  margin-right: 8px;
  background-color: ${(props) => props.color};
`;

export const Field = styled(FormikField)<{ placeholder: string; selected: boolean; multiple: boolean }>`
  && {
    width: 100%;

    .MuiSelect-root {
      &:before {
        display: ${(props) => (props.placeholder && !props.selected ? 'inline-block' : 'none')};
        overflow: hidden;
        text-overflow: ellipsis;
        content: '${(props) => props.placeholder}';
        font-weight: 500;
        font-size: 12px;
        line-height: 1.25;
        color: ${colors.gray};
        text-transform: initial;
      }
    }
  }
`;

export const RenderValue = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Text = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ListSubheader = styled(MuiListSubheader)`
  && {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 32px;
    color: ${colors.gray};
    font-weight: 500;
    font-size: 12px;
    line-height: 1.25;
    pointer-events: none;
    ${(props: { order?: number }) =>
      props.order
        ? css`
            order: ${props.order};
          `
        : ''};
  }
`;
