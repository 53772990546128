import { SystemMetric } from '@services/api/systemProfilesAPI';

export const SYSTEM_METRIC_TITLES_MAP: Record<SystemMetric, string> = {
  [SystemMetric.EnergyProduction]: 'Production',
  [SystemMetric.EnergyConsumption]: 'Consumption',
  [SystemMetric.EnergyImport]: 'Import',
  [SystemMetric.EnergyExport]: 'Export',
  [SystemMetric.PowerProduction]: 'Production',
  [SystemMetric.PowerConsumption]: 'Consumption',
  [SystemMetric.PowerImport]: 'Import',
  [SystemMetric.PowerExport]: 'Export',
  [SystemMetric.Storage]: 'Storage',
  [SystemMetric.Metadata]: 'Metadata',
  [SystemMetric.Devices]: 'Devices'
};
