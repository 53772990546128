import React, { useCallback } from 'react';
import { useForm as useFormOriginal, FieldValues, UseFormProps, UseFormReturn } from 'react-hook-form';

import { useToast } from '@hooks/useToast';
import { applyServerValidation, scrollToFirstError } from './utils';

interface IUseFormProps<TFieldValues> extends Omit<UseFormProps<TFieldValues>, 'shouldUnregister'> {
  onSubmit: (formValues: TFieldValues, form: UseFormReturn<TFieldValues>) => Promise<void>;
}

interface IUseFormReturn<TFieldValues> {
  form: UseFormReturn<TFieldValues>;
  hasErrors: boolean;
  handleSubmit: (e?: React.BaseSyntheticEvent) => void;
}

const useForm = <TFieldValues extends FieldValues = FieldValues>(
  props: IUseFormProps<TFieldValues>
): IUseFormReturn<TFieldValues> => {
  const { onSubmit, ...useFormProps } = props;
  const { showError } = useToast();

  const form = useFormOriginal({ mode: 'onChange', ...useFormProps });

  const hasErrors = Object.keys(form.formState.errors).length > 0;

  const handleSubmitWrapped = useCallback(
    async (formValues: TFieldValues) => {
      try {
        await onSubmit(formValues, form);
      } catch (error) {
        if (error.payload?.errors) {
          applyServerValidation(form.setError, error.payload.errors);
        }

        if (error.message) {
          showError(error.message);
        }

        // TODO show common error or applyServerValidation
        // TODO log other exceptions somewhere, sentry for example
      }
    },
    [onSubmit, form, showError]
  );

  return {
    form,
    hasErrors,
    handleSubmit: form.handleSubmit(handleSubmitWrapped, scrollToFirstError)
  };
};

export { useForm };
