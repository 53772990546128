import { colors, scroll } from '@styles';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  outline: none;
`;

export const Scrollable = styled.div`
  overflow: auto;
  ${scroll};
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 40px;
`;

export const ActiveFileDropZone = styled.div`
  position: absolute;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: rgba(255, 255, 255, 0.85);
  font-size: 24px;
  z-index: 2;
  border: 2px dashed ${colors.green};

  display: flex;
  align-items: center;
  justify-content: center;
`;
