import styled from 'styled-components';

export const Container = styled.span<{ color: string; background?: string }>`
  padding: 3px;
  border-radius: 4px;
  color: ${(props) => props.color};
  border: 1px solid ${(props) => (props.background ? 'transparent' : props.color)};
  background-color: ${(props) => props.background ?? '#fff'};
  flex-shrink: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  max-width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
`;
