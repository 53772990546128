import { InvoiceStatus } from '@generated/types/graphql';
import React from 'react';
import { Tooltip } from '@material-ui/core';
import { Container } from './styled';

interface Props {
  status: InvoiceStatus;
}

const STATUS_COLORS: Record<InvoiceStatus, { color: string; backgroundColor: string; borderColor?: string }> = {
  [InvoiceStatus.Draft]: {
    color: '#778CA2',
    backgroundColor: '#E4E8EC'
  },
  [InvoiceStatus.Open]: {
    color: '#F1AA12',
    backgroundColor: '#FCF2DB'
  },
  [InvoiceStatus.PartiallyPaid]: {
    color: '#009A47',
    backgroundColor: '#FFFFFF',
    borderColor: '#009A47'
  },
  [InvoiceStatus.Paid]: {
    color: '#009A47',
    backgroundColor: '#CDF3DF'
  }
};

export const Status = ({ status }: Props) => {
  return (
    <Tooltip title={status.replace('_', ' ')}>
      <Container
        color={STATUS_COLORS[status].color}
        backgroundColor={STATUS_COLORS[status].backgroundColor}
        borderColor={STATUS_COLORS[status].borderColor}
      >
        {status.replace('_', ' ')}
      </Container>
    </Tooltip>
  );
};
