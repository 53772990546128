export const table = (props) => {
  const {
    fullWidthTable
  } = props;

  return {
    root: {
      width: fullWidthTable ? '100%' : 'auto',
      borderCollapse: 'separate'
    },
    stickyHeader: {

    }
  };
};
