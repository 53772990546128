import { useMutation, useQuery, useQueryClient } from 'react-query';

import { ReactQueryKey, StepFieldKeys, Trigger, AutomationStepType } from '@enums';
import {
  AutomationFromApi,
  PaginationResult,
  StepFromApi,
  DynamicDropdownFilter,
  DropdownValueFromApi,
  ActionsTriggersFromApi
} from '@types';
import workflowsApi from '@services/api/workflowsApi';
import { WorflowApiRequest } from '@services/api/types';
import { errorHandler } from '@services/api/helpers';
import { useAppDispatch, useAppSelector } from '@hooks/store';
import { selectWorkspaceIdFromLocation } from '@state/selectors';
import { makeMutationHandlers } from '@utils/reactQuery';
import { alertShow } from '@state/actions/alert/alertAction';
import { apiErrorHandler } from '@utils/api';
import { flatMap, flow, identity } from 'lodash';
import { groupBy, mapValues } from 'lodash/fp';
import { useMemo } from 'react';

type StepMutationsPayload = Pick<StepFromApi, 'key' | 'type' | 'fields'> & Partial<Pick<StepFromApi, 'parentId'>>;

export const useWorkflow = (workspaceId?: number) => {
  const queryClient = useQueryClient();
  const guessCompanyId = useAppSelector(selectWorkspaceIdFromLocation);
  const companyId = workspaceId || guessCompanyId;

  const dispatch = useAppDispatch();
  const QUERY_KEY = [ReactQueryKey.ProjectWorkflows];

  const findWorkflowsQuery = useQuery(
    QUERY_KEY,
    async () => {
      try {
        const {
          data: workflows
        }: {
          data: PaginationResult<AutomationFromApi>;
        } = await workflowsApi.find({
          sortCol: 'createdAt',
          sortDesc: true,
          fetchAll: true,
          companyId
        });

        return workflows.results;
      } catch (err) {
        throw apiErrorHandler('exception on getting workflows', err);
      }
    },
    {
      onError: () => {
        dispatch(alertShow(["Couldn't fetch the workflows"], 'error'));
      },
      enabled: true
    }
  );

  const requestPropertyUpdateAutomations = useMemo(() => {
    if (!findWorkflowsQuery.data) {
      return [];
    }

    return findWorkflowsQuery.data.filter((automation) =>
      automation.steps.find((step) => step.key === Trigger.DEAL_PROPERTY_UPDATED)
    );
  }, [findWorkflowsQuery.data]);

  const projectPropertyUpdateAutomations = useMemo(() => {
    if (!findWorkflowsQuery.data) {
      return [];
    }

    return findWorkflowsQuery.data.filter((automation) =>
      automation.steps.find((step) => step.key === Trigger.PROJECT_PROPERTY_UPDATED)
    );
  }, [findWorkflowsQuery.data]);

  const dealStageAutomations = useMemo(() => {
    if (!findWorkflowsQuery.data) {
      return {};
    }

    return findWorkflowsQuery.data.reduce(
      (acc, automation) => {
        const projectStageMovementStep = automation.steps.find((step) => step.key === Trigger.DEAL_MOVEMENT);
        const slaStep = automation.steps.find((step) => step.key === Trigger.DEAL_SLA_VIOLATION);

        if (!projectStageMovementStep && !slaStep) {
          return acc;
        }

        if (projectStageMovementStep) {
          const projectStageIdField = projectStageMovementStep.fields.find(
            (field) => field.key === StepFieldKeys.FROM_STAGE_ID
          );

          if (!projectStageIdField) {
            return acc;
          }

          const projectStageId = +projectStageIdField.value;

          if (!projectStageId) {
            return acc;
          }

          if (!acc[projectStageId]) {
            acc[projectStageId] = [];
          }

          return {
            ...acc,
            [projectStageId]: [...acc[projectStageId], automation]
          };
        } else {
          const stageIdField = slaStep.fields.find((field) => field.key === StepFieldKeys.STAGE_ID);

          if (!stageIdField) {
            return acc;
          }

          const stageId = +stageIdField.value;

          if (!stageId) {
            return acc;
          }

          if (!acc[stageId]) {
            acc[stageId] = [];
          }

          return {
            ...acc,
            [stageId]: [...acc[stageId], automation]
          };
        }
      },
      {} as { [projectFromStageId: number]: AutomationFromApi[] }
    );
  }, [findWorkflowsQuery.data]);

  const projectStageAutomations = useMemo(() => {
    if (!findWorkflowsQuery.data) {
      return {};
    }

    return findWorkflowsQuery.data.reduce(
      (acc, automation) => {
        const projectStageMovementStep = automation.steps.find((step) => step.key === Trigger.PROJECT_MOVEMENT);
        const slaStep = automation.steps.find((step) => step.key === Trigger.PROJECT_SLA_VIOLATION);

        if (!projectStageMovementStep && !slaStep) {
          return acc;
        }

        if (projectStageMovementStep) {
          const projectStageIdField = projectStageMovementStep.fields.find(
            (field) => field.key === StepFieldKeys.FROM_STAGE_ID
          );

          if (!projectStageIdField) {
            return acc;
          }

          const projectStageId = +projectStageIdField.value;

          if (!projectStageId) {
            return acc;
          }

          if (!acc[projectStageId]) {
            acc[projectStageId] = [];
          }

          return {
            ...acc,
            [projectStageId]: [...acc[projectStageId], automation]
          };
        } else {
          const stageIdField = slaStep.fields.find((field) => field.key === StepFieldKeys.STAGE_ID);

          if (!stageIdField) {
            return acc;
          }

          const stageId = +stageIdField.value;

          if (!stageId) {
            return acc;
          }

          if (!acc[stageId]) {
            acc[stageId] = [];
          }

          return {
            ...acc,
            [stageId]: [...acc[stageId], automation]
          };
        }
      },
      {} as { [projectFromStageId: number]: AutomationFromApi[] }
    );
  }, [findWorkflowsQuery.data]);

  let taskAutomations: { [taskId: number]: AutomationFromApi[] } = {};
  if (findWorkflowsQuery.data) {
    const automationsWithTask: [number, AutomationFromApi][] = flatMap(findWorkflowsQuery.data, (automation) =>
      flatMap(automation.steps, (step) =>
        step.fields
          .filter((field) => field.key === StepFieldKeys.TASK_ID)
          .map((field) => [+field.value, automation] as [number, AutomationFromApi])
      )
    ).filter(identity);

    taskAutomations = flow(
      groupBy(([taskId, _automation]) => taskId),
      mapValues((pairs: [number, AutomationFromApi][]) => pairs.map(([_taskId, automation]) => automation))
    )(automationsWithTask);
  }

  const createMutation = useMutation<AutomationFromApi, Error, WorflowApiRequest>(
    async (req: WorflowApiRequest) => {
      try {
        const { data: createdObj } = await workflowsApi.create(req, {
          companyId
        });

        return createdObj;
      } catch (error) {
        throw errorHandler(error);
      }
    },
    makeMutationHandlers<AutomationFromApi, AutomationFromApi>(
      QUERY_KEY,
      queryClient,
      dispatch,
      (list: AutomationFromApi[]) => {
        findWorkflowsQuery.refetch();

        return list;
      },
      'Successfully created the workflow'
    )
  );

  const updateMutation = useMutation<AutomationFromApi, Error, { req: Partial<WorflowApiRequest>; id: number }>(
    async ({ id, req }: { req: Partial<WorflowApiRequest>; id: number }) => {
      try {
        const { data: workflow } = await workflowsApi.update(id, req);

        return workflow as AutomationFromApi;
      } catch (error) {
        throw errorHandler(error);
      }
    },
    makeMutationHandlers<AutomationFromApi, AutomationFromApi>(
      QUERY_KEY,
      queryClient,
      dispatch,
      (list: AutomationFromApi[], data: AutomationFromApi) =>
        list?.map((item) => (item.id !== data.id ? item : { ...data, createdBy: item.createdBy, steps: item.steps })),
      'Successfully updated the workflow'
    )
  );

  const deleteMutation = useMutation<{ id: number }, Error, number>(
    async (id: number) => {
      try {
        await workflowsApi.remove(id);

        return { id };
      } catch (error) {
        throw errorHandler(error);
      }
    },
    makeMutationHandlers<AutomationFromApi, { id: number }>(
      QUERY_KEY,
      queryClient,
      dispatch,
      (list: AutomationFromApi[], data: { id: number }) => list?.filter((result) => result.id !== data.id),
      'Successfully deleted the workflow'
    )
  );

  // steps mutations
  const createStepMutation = useMutation<StepFromApi, Error, { workflowId: number; req: StepMutationsPayload }>(
    async ({ workflowId, req }) => {
      try {
        const { data: createdObj } = await workflowsApi.createStep(workflowId, req, {
          companyId
        });

        return createdObj;
      } catch (error) {
        throw errorHandler(error);
      }
    },
    makeMutationHandlers<AutomationFromApi, StepFromApi>(
      QUERY_KEY,
      queryClient,
      dispatch,
      (list: AutomationFromApi[], data: StepFromApi) =>
        list?.map((item) => {
          if (item.id === data.workflowId) {
            return {
              ...item,
              steps: [...item.steps, data]
            };
          }

          return item;
        }),
      'Successfully create the step'
    )
  );

  const updateStepMutation = useMutation<
    StepFromApi,
    Error,
    {
      workflowId: number;
      stepId: number;
      req: Omit<StepMutationsPayload, 'type'>;
    }
  >(
    async ({ workflowId, stepId, req }) => {
      try {
        const { data: updatedobj } = await workflowsApi.updateStep(workflowId, stepId, req);

        return updatedobj;
      } catch (error) {
        throw errorHandler(error);
      }
    },
    makeMutationHandlers<AutomationFromApi, StepFromApi>(
      QUERY_KEY,
      queryClient,
      dispatch,
      (list: AutomationFromApi[], data: StepFromApi) =>
        list?.map((item) => {
          if (item.id === data.workflowId) {
            return {
              ...item,
              steps: item.steps?.map((step) => {
                return step.id !== data.id ? step : data;
              })
            };
          }

          return item;
        }),
      'Successfully updated the step'
    )
  );

  const deleteStepMutation = useMutation<{ id: number; workflowId: number }, Error>(
    async (workflowId: number, id: number) => {
      try {
        await workflowsApi.removeStep(workflowId, id);

        return { id, workflowId };
      } catch (error) {
        throw errorHandler(error);
      }
    },
    makeMutationHandlers<AutomationFromApi, StepFromApi>(
      QUERY_KEY,
      queryClient,
      dispatch,
      (list: AutomationFromApi[], data: StepFromApi) =>
        list?.map((item) => {
          if (item.id === data.workflowId) {
            return {
              ...item,
              steps: item.steps?.filter((step) => step.id !== data.id)
            };
          }

          return item;
        }),
      'Successfully deleted the step'
    )
  );

  return {
    fetch: { ...findWorkflowsQuery },
    fetchTaskAutomations: taskAutomations,
    projectStageAutomations,
    dealStageAutomations,
    requestPropertyUpdateAutomations,
    projectPropertyUpdateAutomations,
    create: createMutation,
    update: updateMutation,
    delete: deleteMutation,
    // steps
    createStep: createStepMutation,
    updateStep: updateStepMutation,
    deleteStep: deleteStepMutation
  };
};

export const useActionsTriggers = (type?: AutomationStepType) => {
  const QUERY_KEY = [ReactQueryKey.AutomationTriggersAndActions, type];

  const findActionsTriggers = async ({ queryKey }: { queryKey: [string, string] }): Promise<ActionsTriggersFromApi> => {
    try {
      const [, $type] = queryKey;
      const { data } = await workflowsApi.findActionsTriggers($type);

      return data as ActionsTriggersFromApi;
    } catch (error) {
      throw errorHandler(error);
    }
  };

  const findActionsTriggerQuery = useQuery(QUERY_KEY, findActionsTriggers, {
    // enabled: false
  });

  return {
    fetch: findActionsTriggerQuery
  };
};

type DynamicValuesFilter = DynamicDropdownFilter & { initial: boolean };

export const useDynamicDropdownValues = (filterBy: DynamicValuesFilter) => {
  const companyId = useAppSelector(selectWorkspaceIdFromLocation);
  const QUERY_KEY = [ReactQueryKey.DynamicDropdownValues, companyId, filterBy];

  const findDropdownValues = async ({
    queryKey
  }: {
    queryKey: [string, number, typeof filterBy];
  }): Promise<DropdownValueFromApi[]> => {
    try {
      const [, $companyId, $filterBy] = queryKey;

      if (!$companyId) {
        return [];
      }

      const { data } = await workflowsApi.findDropdownValues({
        companyId: $companyId,
        ...$filterBy
      });

      return data as DropdownValueFromApi[];
    } catch (error) {
      throw errorHandler(error);
    }
  };

  const findDropdownValuesQuery = useQuery(QUERY_KEY, findDropdownValues, {
    // enabled: true
  });

  return {
    fetch: findDropdownValuesQuery
  };
};
