import React from 'react';
import { useLineItem } from '@hooks/useLineItem';
import { formatMoney } from '@utils';
import { NumberLabel, NumbersContainer, NumberValue, Dollar } from './styled';

interface Props {
  projectId: number;
}

export const Financials = ({ projectId }: Props) => {
  const { data } = useLineItem(projectId);

  return (
    <div>
      <NumbersContainer>
        <div>
          <NumberLabel>Revenue</NumberLabel>
          <NumberValue>
            <Dollar>$</Dollar>
            {formatMoney(data.price).replace('$', '')}
          </NumberValue>
        </div>
        <div>
          <NumberLabel>Cost</NumberLabel>
          <NumberValue>
            <Dollar>$</Dollar>
            {formatMoney(data.cost).replace('$', '')}
          </NumberValue>
        </div>
        <div>
          <NumberLabel>Profit</NumberLabel>
          <NumberValue isNegative={data.profit < 0} isPositive={data.profit > 0}>
            <Dollar>$</Dollar>
            {formatMoney(data.profit).replace('$', '')}
          </NumberValue>
        </div>
      </NumbersContainer>
    </div>
  );
};
