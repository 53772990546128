import styled from 'styled-components';

/**
 * https://stackoverflow.com/a/50566101/1216939
 * https://caniuse.com/css-line-clamp
 * https://css-tricks.com/line-clampin/
 */
export const MultilineEllipsis = styled.div<{ lines: number }>`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: ${(props) => props.lines}) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: ${(props) => props.lines};
    -webkit-box-orient: vertical;
  }
`;
