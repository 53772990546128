import styled from 'styled-components';

export const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
`;

export const List = styled.div`
  margin: 8px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;

  a {
    text-decoration: none;
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  font-weight: 400;
  font-size: 14px;
  color: #235dff;
`;

export const ItemTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
