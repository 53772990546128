import { useState, useRef, useCallback } from 'react';
import { useEffectOnce } from 'react-use';
import { debounce } from 'lodash';

export type SearchParams = {
  query: string;
};

export type UseRemoteSearchProps<T = { id: number; }> = {
  getItems?: (params: SearchParams) => Promise<T[]>,
  prefetch?: boolean
};

export type UseRemoteSearchReturn<T, K> = {
  items: T[];
  initialItems: T[];
  refetch: (updateParams?: K) => void;
  search: (query: string, searchParams?: SearchParams) => void;
  isLoading: boolean;
  error: Error | null;
};

export const useRemoteSearch = <T extends { id: number; }, K extends any>(
  props: UseRemoteSearchProps<T>
): UseRemoteSearchReturn<T, K> => {
  const {
    getItems = () => Promise.resolve([]),
    prefetch = true,
  } = props;
  const [initialItems, setInitialItems] = useState<T[]>([]);
  const [items, setItems] = useState<T[]>([]);
  const [isLoading, setIsLoading] = useState(!!prefetch);
  const [error, setError] = useState<Error | null>(null);

  const handleError = useCallback((err) => {
    setError(err);
    setIsLoading(false);
  }, [setIsLoading, setError]);

  const search = useRef(debounce((query: string) => {
    setIsLoading(true);
    getItems({ query } as unknown as SearchParams).then((newItems) => {
      setItems(newItems);
      setIsLoading(false);
      setError(null);
    }).catch(handleError);
  }, 300));

  const refetch = useCallback((params?) => {
    const { initial, ...updateParams } = params || {};

    getItems({ query: '', ...updateParams }).then((newItems) => {
      setItems(newItems);
      setInitialItems(newItems);
      setIsLoading(false);
      setError(null);
    }).catch(handleError);
  }, [getItems, handleError]);

  useEffectOnce(() => {
    if (prefetch) {
      refetch({ initial: true });
    }
  });

  return {
    items,
    initialItems,
    refetch,
    search: search.current,
    isLoading,
    error
  };
};
