import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDebounce } from 'react-use';
import { isString } from 'lodash';

import Props from './type';

import { input, error } from '../../../styles';

const Input = styled.input`
  ${input};
  -webkit-user-select: text !important;
  user-select: text !important;
`; // fix IOS cant type

const ErrorMessage = styled.span`
  ${error};
`;

const InputText: React.FC<Props> = (props) => {
  const {
    value,
    onChange = () => {},
    name,
    type = 'TEXT',
    placeHolder = '',
    isRequired = false,
    onClick = () => {},
    onKeyDown = () => {},
    autoFocus = true,
    errorMessage = 'This field is required!',
    onBlur,
    tableInput,
    onFocus,
    tabIndex = null,
    className,
    disabled,
    debounceTime = 0
  } = props;
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    if (localValue !== value) {
      setLocalValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useDebounce(
    () => {
      if (localValue !== value) {
        onChange({ target: { value: localValue } });
      }
    },
    debounceTime,
    [localValue, debounceTime]
  );

  const onDrag = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const isInvalid = isRequired && (value === null || (isString(value) && value?.trim() === ''));

  const handleKeyDown = useCallback(
    (e: any) => {
      if (type === 'number' && ['e', 'E', '+'].includes(e.key)) {
        e.preventDefault();
      }
      onKeyDown?.(e);
    },
    [onKeyDown, type]
  );

  return (
    <>
      <Input
        draggable
        onDragStart={onDrag}
        autoFocus={autoFocus}
        placeholder={placeHolder}
        name={name}
        value={localValue}
        onChange={(e) => setLocalValue(e.target.value)}
        type={type}
        onClick={onClick}
        onKeyDown={handleKeyDown}
        onBlur={onBlur}
        onFocus={onFocus}
        tabIndex={tabIndex}
        error={isInvalid}
        tableInput={tableInput}
        className={className}
        disabled={disabled}
      />
      {isInvalid && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  );
};

export default InputText;
