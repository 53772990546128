import get from '@services/api/base/get';
import post from '@services/api/base/post';
import deleteReq from '@services/api/base/delete';
import put from '@services/api/base/put';
import { Urls } from '@services/api/base/axios';
import { ContactDTO, CreateContactDto, Paging, Search } from '@types';
import { generateParams } from '@services/api/helpers';

export default {
  findAll: (companyId: number, search: Partial<Search>) =>
    get<Paging<ContactDTO>>(`${Urls.project}/contacts`, { companyId, ...generateParams({ search }) }),

  create: (companyId: number, dto: Partial<CreateContactDto>) =>
    post<ContactDTO>(`${Urls.project}/contacts`, dto, { companyId }),

  update: (companyId: number, id: number, dto: Partial<CreateContactDto>) =>
    put<ContactDTO>(`${Urls.project}/contacts/${id}`, dto, { companyId }),

  remove: (companyId: number, id: number) => deleteReq<void>(`${Urls.project}/contacts/${id}`, {}, { companyId }),

  inviteToPortal: (companyId: number, id: number) =>
    post<void>(`${Urls.project}/contacts/${id}/invite`, {}, { companyId }),
  getPreviewToken: (companyId: number, id: number) =>
    post<{ accessToken: string }>(`${Urls.project}/contacts/${id}/preview-token`, {}, { companyId })
};
