import { Collapse, ListItemIcon, ListItemText } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import { Badge } from '@common/ui';
import { SmartViewsMenuItem } from './styled';

export const ListItemWithSubMenu = ({
  text,
  children,
  icon,
  rightAction,
  initialIsSubMenuOpen = false,
  withNewBadge = false
}: {
  text: string;
  children: React.ReactNode;
  icon: React.ReactNode;
  rightAction?: React.ReactNode;
  initialIsSubMenuOpen?: boolean;
  withNewBadge?: boolean;
}) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(initialIsSubMenuOpen);

  const toggleSubMenu = useCallback(() => {
    setIsSubMenuOpen((prev) => !prev);
  }, []);

  return (
    <>
      <SmartViewsMenuItem button onClick={toggleSubMenu}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
        <ListItemIcon className="arrow">
          {isSubMenuOpen ? <ChevronUp size="16px" /> : <ChevronDown size="16px" />}
        </ListItemIcon>
        {withNewBadge && <Badge type="success">New</Badge>}

        {rightAction}
      </SmartViewsMenuItem>
      <Collapse in={isSubMenuOpen} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </>
  );
};
