import React from 'react';
import { File } from '@generated/types/graphql';
import { colors } from '@styles';
import { FileImageStyledBadge, FileImageThumbnail, FileX } from './styled';

type FileImageBadgeProps = {
  file: File;
  onClick?: (file: File) => void;
  onRemove?: () => void;
};

export const FileImageBadge: React.FC<FileImageBadgeProps> = ({ file, onClick, onRemove }) => (
  <FileImageStyledBadge color={colors.gray} clickable={!!onClick}>
    <FileImageThumbnail
      src={file.metaData.thumbnailUrl}
      alt={file.name}
      onClick={onClick ? () => onClick(file) : undefined}
    />
    {onRemove && (
      <FileX
        onClick={(e) => {
          e.stopPropagation();
          onRemove();
        }}
      />
    )}
  </FileImageStyledBadge>
);
