import { css } from 'styled-components';

export const borders = {
  main: css`
    border: 1px solid ${({ theme }) => theme.colors.gray3};
  `
};

export const shadows = {
  main: css`
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  `
};
