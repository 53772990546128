import styled, { css } from 'styled-components';
import { colors, scroll, device, deviceMax } from '@styles';
import { Link } from '@reach/router';

export const Container = styled.div`
  position: relative;
  font-size: 14px;
  height: 100%;
  overflow: auto;

  form {
    height: 100%;
  }
`;

export const IconButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  color: ${colors.gray};
  &:hover {
    color: ${colors.black};
  }
`;

export const CloseButton = styled(IconButton)`
  ${device.md`
  display: none;
`}
`;

export const FullScreenButton = styled(IconButton)`
  @media (max-width: 767px) {
    display: none;
  }
`;

export const Content = styled.div`
  display: flex;
  height: calc(100% - 72px);

  @media (max-width: 767px) {
    flex-direction: column;
    height: auto;
  }
`;

export const Left = styled.div`
  flex: 2;
  padding: 16px 16px 16px 24px;
  overflow: auto;
  ${scroll};
`;

export const Right = styled.div`
  flex: 1;
  border-left: 1px solid #dfdfe8;
  overflow: auto;
  position: relative;
  ${scroll};

  @media (max-width: 767px) {
    position: initial;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  height: 72px;
  border-bottom: 1px solid #e8ecef;
`;

export const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;

  @media (max-width: 767px) {
    gap: 16px;
  }
`;

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  > * {
    flex-shrink: 0;
  }
`;

export const TitleAndPriority = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 8px;
  margin-right: 75px;

  svg {
    flex-shrink: 0;
  }
`;

export const Title = styled.h3`
  font-size: 24px;
  font-weight: 500;
  margin-top: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 767px) {
    white-space: initial;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  color: ${colors.gray2};
  position: absolute;
  right: 19px;
  top: 15px;
  background: ${colors.white};
  z-index: 12;
  padding: 5px;

  > * {
    cursor: pointer;
    transition: color 0.15s ease-in-out;

    &: hover {
      color: ${colors.black};
    }
  }
`;

export const CreatedAt = styled.div`
  color: #828d9a;
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
`;

export const FieldLabel = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #828d9a;

  padding-top: ${(props) => (props.fixPadding ? '14px' : 0)};
`;

export const InfoBlock = styled.div`
  display: ${(props) => (props.block ? 'block' : 'flex')};
  align-items: ${(props) => (props.centered ? 'center' : 'flex-start')};
  gap: 16px;
  margin-bottom: 16px;

  > *:last-child {
    flex: 1;
  }

  .ql-editor {
    a {
      word-break: break-word;
    }
  }

  ${FieldLabel} {
    margin-bottom: ${(props) => (props.block ? '8px' : 0)};
    width: ${(props) => (props.block ? 'auto' : '75px')};
  }
`;

export const InfoBlockTitle = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${colors.gray};
  margin-bottom: 12px;
`;

export const SubtaskRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: -4px;
`;

export const FormRow = styled.div`
  > div,
  > div > * {
    font-size: 12px;
  }

  .link {
    cursor: pointer;
    text-decoration: underline;
  }

  > div {
    border: 0;
    height: auto;
    &:hover {
      background-color: #fff;
    }

    img {
      display: none;
    }
  }
`;

export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  border-top: 1px solid #e8ecef;
  left: 0;
  right: 0;
  height: 70px;
  z-index: 11;
  background-color: ${colors.white};
  padding: 20px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PhonesList = styled.div`
  && {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`;

export const ProjectLink = styled(Link)`
  color: #235dff;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  min-width: 0;

  &:link,
  &:visited,
  &:hover,
  &:active,
  &:focus {
    color: #235dff;
  }

  svg {
    flex-shrink: 0;
  }
`;

export const PhoneWithCopy = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${colors.gray2};
  white-space: nowrap;

  > *:last-child {
    opacity: 0;
    transition: opacity 0.15s ease-in-out;
  }

  &:hover > *:last-child {
    opacity: 1;
  }
`;

export const OrderDates = styled.div`
  color: ${colors.gray2};
`;

export const NotEditableInfo = styled.div`
  background-color: #f7f7fc;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 24px;
  margin-top: 8px;

  display: flex;
  gap: 40px;

  > * {
    min-width: 0;
  }
`;

export const ProjectMetaData = styled.div<{ gap: string }>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap};
`;

export const MetaData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-width: 156px;
`;

export const FormPreview = styled.div<{ isFullScreen: boolean }>`
  position: absolute;
  z-index: 12;
  left: 0;
  right: 0;
  background: ${({ isFullScreen }) => (isFullScreen ? '#fff' : '#f7f7fc')};
  padding: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
  ${scroll};

  @media (max-width: 767px) {
    position: fixed;
  }
`;

export const FormHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 16px;
  padding: 0 16px;

  ${CloseButton} {
    position: static;
  }
`;

export const FormActions = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-end;
  padding: 12px;
`;

export const InstructionsTitle = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const progressStyles = css`
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  border-radius: 4px;
`;

const hiddenFn = css`
  visibility: ${(props) => (props.hidden ? 'hidden' : 'visible')};
`;

export const ChecklistProgress = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  margin: 8px 0;

  ${hiddenFn};

  progress {
    ${progressStyles};
  }
  progress::-webkit-progress-bar {
    ${progressStyles};
  }
  progress::-webkit-progress-value {
    ${progressStyles};
    background: linear-gradient(90deg, #009688 0%, #19c9b9 100%);
  }
  progress::-moz-progress-bar {
    ${progressStyles};
  }

  progress[value]::-webkit-progress-bar {
    background-color: #ededef;
    background-image: #009688;
    border-radius: 4px;
  }
`;

export const ChecklistCounter = styled.div`
  color: #1d1d35;
  ${hiddenFn};
`;

export const CompletedAt = styled.div`
  color: #828d9a;
  font-size: 12px;

  @media (max-width: 767px) {
    display: none;
  }
`;

export const FilePropertiesContainer = styled.div<{ isBig?: boolean }>`
  position: fixed;
  right: 0;
  top: 48px;
  bottom: 0;
  z-index: 1001;
  background: ${colors.white};
  width: 100%;

  box-shadow: -16px -16px 200px 0px #1d1d3552;
  ${deviceMax.sm`
     height: 100%;
  `}

  ${device.md`
  position: absolute;
  top: initial;
      width: 80%;
      height: ${({ isBig }) => (isBig ? '100vh' : 'calc(100vh - 48px)')};
    `}


  @media (min-width: 1500px) {
    width: ${({ isBig }) => (isBig ? '1200px' : '850px')};
  }
`;

export const OuterButtons = styled.div`
  position: absolute;
  display: none;
  ${device.md`
left: -40px;
top: 25px;
display: flex;
flex-direction: column;
width: auto;
gap: 8px;
> * {
  flex: auto;
}
`}
`;

const OuterButton = styled.div<{ isDisabled?: boolean }>`
  background-color: white;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};

  ${device.md`
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    `}
`;

export const OuterCloseButton = styled(OuterButton)`
  color: #9c9caa;
  transition: color 0.15s ease-in-out;
  height: 40px;

  &:hover {
    color: #1d1d35;
  }

  ${device.md`
     width: 40px;
     margin-bottom: 8px;
    `}
`;

export const NumberAndTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  gap: 1px;

  > *:last-child {
    flex: 1;
  }
`;
