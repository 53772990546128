import * as actionTypes from '../actionTypes/actionTypes';
import { IActionType } from '../actionTypes/actionTypeInterface';

export const updateUserDataAction = (payload): IActionType => {
  return {
    type: actionTypes.USER_DATA_UPDATE,
    payload
  };
};

export const updateUsersCacheAction = (payload): IActionType => {
  return {
    type: actionTypes.USER_DATA_UPDATE_CACHE,
    payload
  };
};

export const fetchCompanyUsersStart = (payload): IActionType => {
  return {
    type: actionTypes.FETCH_COMPANY_USERS_START,
    payload
  };
};

export const fetchCompanyUsersSuccess = (
  companyUsers,
  totalCompanyUsers
): IActionType => {
  return {
    type: actionTypes.FETCH_COMPANY_USERS_SUCCESS,
    payload: {
      companyUsers,
      totalCompanyUsers
    }
  };
};

export const fetchCompanyUsersFailed = (error) => {
  return {
    type: actionTypes.FETCH_COMPANY_USERS_FAILED,
    error
  };
};
