import styled from 'styled-components';

export const Body = styled.div`
  padding: 24px;
  background-color: #f7f7fc;
  width: 700px;
  max-width: 60vw;
`;

export const Footer = styled.div`
  padding: 16px 24px;
  display: flex;
  justify-content: flex-end;
  gap: 24px;
  background-color: #fff;
`;

export const FieldsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 8px;
  column-gap: 40px;
`;
