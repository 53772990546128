import { ReactQueryKey } from '@enums';
import { errorHandler } from '@services/api/helpers';
import { useMutation, useQueryClient } from 'react-query';
import { useToast } from '@hooks/useToast';
import filterApi from '@services/api/smartViewFilterApi';
import { SmartViewDTO } from '@services/api/types';
import { convertMultiValuesToString } from './helpers';

export const useUpdateSmartView = () => {
  const queryClient = useQueryClient();
  const { showSuccess } = useToast();

  return useMutation<void, Error, { id: number; dto: SmartViewDTO }>(
    async ({ id, dto }) => {
      try {
        await filterApi.update(id, convertMultiValuesToString(dto));
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: () => {
        showSuccess('Smart View updated successfully');
        queryClient.refetchQueries(ReactQueryKey.SmartViewList);
      }
    }
  );
};
