import React, { useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { UserAvatar } from '@kit/components/UserAvatar';
import { TrashIcon } from '@kit/ui/icons/Trash';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { v4 as uuid } from '@lukeed/uuid';
import { useCompanyUsersWithRoles } from '@hooks/useCompanyUsers';
import { getFullName } from '@utils/utils';
import { useTeams } from '@hooks/useTeams';
import { useAppSelector } from '@hooks/store';
import { selectWorkspaceId } from '@state/selectors';
import { useUserRoleSettings } from '@hooks/useRoles';
import { Team } from '@generated/types/graphql';
import { AlertTriangle } from 'react-feather';
import { hasAccess } from '@utils/roles';
import { Link } from '@reach/router';
import { useAuth } from '@hooks/useAuth';
import {
  List,
  Header,
  MemberRow,
  Member,
  PlaceholderRow,
  Remove,
  Scrollable,
  TeamWarning,
  TeamWarningTitle
} from './styled';
import {
  AccessEntityType,
  AccessOption,
  CheckAccessFn,
  RoleAccessEntityOption,
  TAccessList,
  TeamAccessEntityOption,
  UserAccessEntityOption
} from './types';
import { GROUP_TO_ICON } from './constants';
import { pluralizeRoleName } from './helpers';
import { AccessItemSelect } from './AccessItemSelect';

interface InitialValue {
  sharedRoles?: { id: number; name: string }[];
  sharedTeams?: { id: number; name: string }[];
  sharedUsers?: { id: number; firstName: string; lastName: string }[];
}

interface Props {
  initialValue?: InitialValue;
  checkAccessFn: CheckAccessFn;
  accessEntityName: string;
}

const AccessValue = ({
  option,
  teamByIdMap,
  checkAccessFn,
  accessEntityName
}: {
  option: AccessOption;
  teamByIdMap: Record<number, Team>;
  checkAccessFn: CheckAccessFn;
  accessEntityName: string;
}) => {
  const companyId = useAppSelector(selectWorkspaceId);
  const { user } = useAuth();

  const { data: access } = useUserRoleSettings(companyId, user.userId);

  const teamMembersIds =
    option.type === AccessEntityType.TEAM ? teamByIdMap[option.value.id]?.teamUsers.map((teamUser) => teamUser.id) : [];
  const { data: usersWithRoles = [] } = useCompanyUsersWithRoles({
    isEnabled: option.type === AccessEntityType.TEAM && Boolean(teamMembersIds) && teamMembersIds.length > 0,
    userIds: teamMembersIds
  });

  if (option.type === AccessEntityType.TEAM) {
    const teamWithUsers = teamByIdMap[option.value.id];
    const hasCount = Boolean(teamWithUsers);

    const usersWithoutAccessCount = usersWithRoles.filter((user) => !user.role || !checkAccessFn(user.role));

    return (
      <Member>
        {GROUP_TO_ICON[option.type]}
        <div>
          {option.value.name} {hasCount && `(${teamWithUsers.teamUsers.length})`}
        </div>
        {usersWithoutAccessCount.length > 0 && (
          <TeamWarning>
            <TeamWarningTitle>
              <AlertTriangle size="16px" color="#D54855" />
              {usersWithoutAccessCount.length} Team member{usersWithoutAccessCount.length > 1 ? 's' : ''} don&apos;t
              have access to {accessEntityName} due to their roles
            </TeamWarningTitle>

            {hasAccess(access, 'workspace', 'edit', 'settings') && (
              <Link to={`/${companyId}/workspace/roles`}>Configure in Workspace Settings</Link>
            )}
          </TeamWarning>
        )}
      </Member>
    );
  }

  return (
    <Member>
      {option.type === AccessEntityType.USER ? <UserAvatar user={option.value} /> : GROUP_TO_ICON[option.type]}
      <div>
        {option.type === AccessEntityType.USER && getFullName(option.value)}
        {option.type === AccessEntityType.ROLE && pluralizeRoleName(option.value.name)}
      </div>
    </Member>
  );
};

const initState = (initialValue: InitialValue | undefined): TAccessList => {
  if (!initialValue) {
    return [];
  }

  return [
    ...((initialValue.sharedRoles || []).map((role) => ({
      uniqueId: `${AccessEntityType.ROLE}-${role.id}`,
      type: AccessEntityType.ROLE,
      value: role
    })) as RoleAccessEntityOption[]),
    ...((initialValue.sharedTeams || []).map((team) => ({
      uniqueId: `${AccessEntityType.TEAM}-${team.id}`,
      type: AccessEntityType.TEAM,
      value: team
    })) as TeamAccessEntityOption[]),
    ...((initialValue.sharedUsers || []).map((user) => ({
      uniqueId: `${AccessEntityType.USER}-${user.id}`,
      type: AccessEntityType.USER,
      value: user
    })) as UserAccessEntityOption[])
  ];
};

export const AccessList = React.forwardRef<TAccessList, Props>(
  ({ initialValue, accessEntityName, checkAccessFn }, ref) => {
    const scrollableRef = useRef<HTMLDivElement>(null);

    const companyId = useAppSelector(selectWorkspaceId);

    const [list, setList] = useState<TAccessList>(() => initState(initialValue));
    const { data: teams = [] } = useTeams(companyId);

    const teamByIdMap = useMemo(() => {
      return teams.reduce(
        (acc, team) => {
          acc[team.id] = team;

          return acc;
        },
        {} as Record<number, Team>
      );
    }, [teams]);

    const handleAddMember = useCallback(() => {
      setList((prev) => [
        ...prev,
        {
          uniqueId: uuid(),
          type: AccessEntityType.USER,
          isNew: true,
          value: null
        }
      ]);

      setTimeout(() => {
        scrollableRef.current?.scrollTo({ top: scrollableRef.current.scrollHeight, behavior: 'smooth' });
      }, 0);
    }, []);

    const handleRemove = useCallback(
      (id: string) => () => {
        setList((prev) => prev.filter((option) => option.uniqueId !== id));
      },
      []
    );

    const handleChangeOption = useCallback((id: string, option: AccessOption) => {
      setList((prev) => prev.map((item) => (item.uniqueId === id ? { ...item, ...option } : item)));
    }, []);

    useImperativeHandle(ref, () => {
      return list.filter((item) => item.value);
    });

    return (
      <List>
        <Header>
          <div>User</div>
          <div />
        </Header>

        {list.length === 0 && (
          <PlaceholderRow>
            <div>No one has access yet</div>
            <div />
          </PlaceholderRow>
        )}
        <Scrollable ref={scrollableRef}>
          {list.map((item) => (
            <MemberRow key={item.uniqueId}>
              {!item.value && (
                <AccessItemSelect
                  selected={list}
                  item={item}
                  onChange={handleChangeOption}
                  checkAccessFn={checkAccessFn}
                  accessEntityName={accessEntityName}
                />
              )}
              {item.value && (
                <AccessValue
                  accessEntityName={accessEntityName}
                  checkAccessFn={checkAccessFn}
                  option={item}
                  teamByIdMap={teamByIdMap}
                />
              )}
              <Remove onClick={handleRemove(item.uniqueId)}>
                <TrashIcon size="16px" color="#D54855" />
              </Remove>
            </MemberRow>
          ))}
        </Scrollable>

        <MemberRow>
          <div>
            <Button onClick={handleAddMember} isUpperCase={false} variant={ButtonVariant.Flat}>
              + Add Users, Teams and/or Roles
            </Button>
          </div>
          <div />
        </MemberRow>
      </List>
    );
  }
);
