import { SubTaskField } from '@common/index';
import { useFormikContext } from 'formik';
import React from 'react';

interface Props {
  isReadOnly?: boolean;
}

export const Checklist = ({ isReadOnly }: Props) => {
  const { submitForm } = useFormikContext();

  return (
    <SubTaskField
      name="subTasks"
      isDisabled={isReadOnly}
      onChange={() => {
        setTimeout(() => {
          submitForm();
        }, 0);
      }}
    />
  );
};
