import React from 'react';
import { Skeleton } from '@material-ui/lab';

import { Container, Widget, WidgetHeader, WidgetTitle } from './styled';

import { Chips, ProjectContacts } from './Properties/styled';

export const OverviewSkeleton = () => {
  return (
    <Container>
      <Widget size="large" isFullWidth>
        <WidgetHeader>
          <WidgetTitle>
            <Skeleton animation="wave" width={120} height={50} />
          </WidgetTitle>
        </WidgetHeader>

        <div>
          <Skeleton animation="wave" height={40} />
          <Chips>
            <Skeleton animation="wave" width={80} height={40} />
            <Skeleton animation="wave" width={80} height={40} />
            <Skeleton animation="wave" width={80} height={40} />
            <Skeleton animation="wave" width={80} height={40} />
            <Skeleton animation="wave" width={80} height={40} />
          </Chips>

          <ProjectContacts>
            <Skeleton animation="wave" height={40} />
            <Skeleton animation="wave" height={40} />
            <Skeleton animation="wave" height={40} />
          </ProjectContacts>

          <ProjectContacts>
            <Skeleton animation="wave" height={40} />
            <Skeleton animation="wave" height={40} />
            <Skeleton animation="wave" height={40} />
          </ProjectContacts>
        </div>
      </Widget>

      <Widget size="large" isFullWidth>
        <WidgetHeader>
          <WidgetTitle>
            <Skeleton animation="wave" width={120} height={50} />
          </WidgetTitle>
        </WidgetHeader>

        <Skeleton animation="wave" height={40} />
        <Skeleton animation="wave" height={40} />
        <Skeleton animation="wave" height={40} />
        <Skeleton animation="wave" height={40} />
      </Widget>

      <Widget isFullWidth size="large">
        <WidgetHeader>
          <WidgetTitle>
            <Skeleton animation="wave" width={120} height={50} />
          </WidgetTitle>
        </WidgetHeader>

        <Skeleton animation="wave" height={40} />
        <Skeleton animation="wave" height={40} />
        <Skeleton animation="wave" height={40} />
        <Skeleton animation="wave" height={40} />
      </Widget>

      <Widget isFullWidth={false}>
        <WidgetHeader>
          <WidgetTitle>
            <Skeleton animation="wave" width={120} height={50} />
          </WidgetTitle>
        </WidgetHeader>

        <Skeleton animation="wave" height={40} />
        <Skeleton animation="wave" height={40} />
        <Skeleton animation="wave" height={40} />
      </Widget>

      <Widget isFullWidth={false}>
        <WidgetHeader>
          <WidgetTitle>
            <Skeleton animation="wave" width={120} height={50} />
          </WidgetTitle>
        </WidgetHeader>

        <Skeleton animation="wave" height={40} />
        <Skeleton animation="wave" height={40} />
        <Skeleton animation="wave" height={40} />
      </Widget>

      <Widget isFullWidth size="large">
        <WidgetHeader>
          <WidgetTitle>
            <Skeleton animation="wave" width={120} height={50} />
          </WidgetTitle>
        </WidgetHeader>

        <Skeleton animation="wave" height={40} />
        <Skeleton animation="wave" height={40} />
      </Widget>
    </Container>
  );
};
