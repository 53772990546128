import React from 'react';
import { Router as ReachRouter, RouterProps } from '@reach/router';

import { reachHistory } from '@state/configureStore';

export const Router: React.FC<RouterProps> = ({ children, ...rest }) => {
  return (
    <ReachRouter history={reachHistory} {...rest}>
      {children}
    </ReachRouter>
  );
};
