import { ReactQueryKey } from '@enums';
import { useToast } from '@hooks/useToast';
import { errorHandler } from '@services/api/helpers';
import workflowsApi from '@services/api/workflowsApi';
import { useMutation, useQueryClient } from 'react-query';

export const useRetryExecuteAutomation = () => {
  const { showError, showSuccess } = useToast();
  const queryClient = useQueryClient();

  return useMutation<{ success: boolean }, Error, { feedItemId: number }>(
    async ({ feedItemId }) => {
      try {
        const result = await workflowsApi.retry(feedItemId);

        return result.data;
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: async ({ success }) => {
        await queryClient.invalidateQueries(ReactQueryKey.ProjectActivity);
        if (success) {
          showSuccess('Successfully executed the automation');
        } else {
          showError('Failed to execute the automation');
        }
      }
    }
  );
};
