import confirmationModal from '@common/ConfirmationService/ConfirmationModal';

export const makeConfirmAction = <T extends (...args: any) => any>(
  fn: T,
  ...args: Parameters<T>
) => async (params: {
  message?: string;
  cancelButtonTitle?: string;
  submitButtonTitle?: string;
} = {}) => {
  const confirmModal = confirmationModal.create();
  const answer = await confirmModal?.show(params.message, params.cancelButtonTitle, params.submitButtonTitle);

  if (answer) {
    await fn(...[...args]);
  }
};
