import { useAppSelector } from '@hooks/store';
import { useAuth } from '@hooks/useAuth';
import { useRemindersMutations } from '@hooks/useReminders';
import {
  DateTimeField,
  Form,
  FormValidationRules,
  InputField,
  RichTextField,
  SelectField,
  useForm
} from '@kit/components/Form';
import { selectWorkspaceId } from '@state/selectors';
import React, { useEffect, useMemo, useRef } from 'react';
import { getFullName } from '@utils/utils';
import { DropDownItem } from '@common/Selector/UserSelector/DropDownItem';
import { REMINDER_OPTIONS } from '@common/Reminder/ReminderTypeSelector';
import { useAllCompaniesUsers } from '@hooks/useCompanyUsers';
import { Reminder } from '@generated/types/graphql';
import { omit } from 'lodash';
import { roundToNearestHour } from '@utils/dates';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { BodyWithActionButtons, FieldsRow, OptionWithIcon, FooterActions, FormFields } from './styled';

interface FormValues {
  title: string;
  assignee: any;
  type: any;
  dueDate: Date;
  description: string;
}

interface Props {
  recordId: number;
  onClose: () => void;
  initialValues?: Reminder;
}

const mapInitialValuesToFormValues = (reminder: Reminder) => ({
  assignee: reminder.assignee,
  type: REMINDER_OPTIONS.find((option) => option.id === reminder.type),
  title: reminder.title,
  description: reminder.description,
  dueDate: reminder.dueDate ? new Date(reminder.dueDate) : null
});

export const ReminderForm = ({ recordId, initialValues, onClose }: Props) => {
  const companyId = useAppSelector(selectWorkspaceId);
  const { user } = useAuth();

  const isEdit = Boolean(initialValues?.id);
  const { data: companyUsers } = useAllCompaniesUsers();

  const { createMutation, updateMutation } = useRemindersMutations(companyId);

  const postForm = async (values: FormValues) => {
    const dto = {
      assigneeId: values.assignee.id,
      dueDate: values.dueDate.toISOString(),
      isCompleted: false,
      title: values.title,
      type: values.type.id,
      description: values.description
    };

    if (isEdit) {
      const dueDateChanged = +new Date(values.dueDate) !== +new Date(initialValues.dueDate);
      await updateMutation.mutate({
        id: initialValues.id,
        dto: dueDateChanged ? dto : omit(dto, 'dueDate')
      });
    } else {
      await createMutation.mutate({
        dto,
        recordId
      });
    }

    onClose();
  };

  const { handleSubmit, form } = useForm<FormValues>({
    onSubmit: postForm,
    defaultValues: initialValues
      ? mapInitialValuesToFormValues(initialValues)
      : {
          assignee: null,
          type: REMINDER_OPTIONS[0],
          title: 'Follow-up',
          description: '',
          dueDate: roundToNearestHour(new Date())
        }
  });

  const rules = useMemo<FormValidationRules<FormValues>>(
    () => ({
      title: {
        isRequired: true
      },
      assignee: {
        isRequired: true
      },
      type: {
        isRequired: true
      },
      dueDate: {
        isRequired: true
      }
    }),
    []
  );

  const {
    formState: { isSubmitting },
    control,
    watch,
    setValue
  } = form;

  const assignee = watch('assignee');

  const isAssigneeInited = useRef(false);

  useEffect(() => {
    if (isAssigneeInited.current) {
      return;
    }

    if (companyUsers.length) {
      isAssigneeInited.current = true;

      if (!assignee) {
        setValue(
          'assignee',
          companyUsers.find((companyUser) => companyUser.id === user.userId)
        );
      }
    }
  }, [assignee, companyUsers, user, setValue]);

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <FormFields withPadding>
        <InputField label="Title" name="title" control={control} />
        <SelectField
          name="type"
          label="Type"
          options={REMINDER_OPTIONS}
          control={control}
          getOptionLabel={(option) => option.name}
          renderOption={({ icon, name }) => (
            <OptionWithIcon>
              {icon.component}
              <div>{name}</div>
            </OptionWithIcon>
          )}
        />

        <FieldsRow>
          <DateTimeField withPortal label="Due date" name="dueDate" control={control} />

          <SelectField
            name="assignee"
            label="Assignee"
            options={companyUsers}
            control={control}
            getOptionLabel={getFullName}
            noOptionsText="User not found"
            renderOption={(option: any) => (
              <DropDownItem id={option.id} avatarUrl={option.avatarUrl} name={getFullName(option)} />
            )}
          />
        </FieldsRow>
        <BodyWithActionButtons>
          <RichTextField name="description" control={control} placeholder="Reminder description..." />
        </BodyWithActionButtons>
      </FormFields>

      <FooterActions>
        <Button variant={ButtonVariant.Flat} onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={isSubmitting} type="submit" variant={ButtonVariant.Primary}>
          {isEdit ? 'Update' : 'Create'}
        </Button>
      </FooterActions>
    </Form>
  );
};
