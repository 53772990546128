import { Search } from '@components/Project/ProjectView/types';

import { TaskTemplate } from '@types';

import { post, put, get, deleteRequest } from './base';
import { generateParams } from './helpers';
import { Urls } from './base/axios';

type GenerateParamsArgumentsType = Parameters<typeof generateParams>[0];
export default {
  find: (search: Partial<Search>) => {
    return get(`${Urls.project}/task-templates`, generateParams({ search }));
  },

  findOne: (id: number, companyId: number) =>
    get(
      `${Urls.project}/task-templates`,
      generateParams({ companyId, search: { filters: [{ col: 'id', op: '=', val: id }] } })
    ),

  create: (payload: Partial<TaskTemplate>, params: GenerateParamsArgumentsType) =>
    post(`${Urls.project}/task-templates`, payload, generateParams(params)),

  update: (id: number, payload: Partial<TaskTemplate>) => put(`${Urls.project}/task-templates/${id}`, payload),

  remove: (id: number) => deleteRequest(`${Urls.project}/task-templates/${id}`)
};
