import { ReactQueryKey } from '@enums';
import { Form } from '@generated/types/graphql';
import { useToast } from '@hooks/useToast';
import { UpdateFormFieldValueDto, formsApi } from '@services/api/formsApi';
import { errorHandler } from '@services/api/helpers';
import { selectWorkspaceId } from '@state/selectors';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

export const useUpdateFormFieldValue = () => {
  const { showError } = useToast();
  const companyId = useSelector(selectWorkspaceId);
  const queryClient = useQueryClient();

  return useMutation<
    { success: boolean; error?: string },
    Error,
    { clientSocketId: string; formId: number; dto: UpdateFormFieldValueDto; projectId?: number }
  >(
    async ({ formId, clientSocketId, dto }) => {
      try {
        const response = await formsApi.updateFormFieldValue({
          clientSocketId,
          formId,
          companyId,
          dto
        });

        if ('error' in response.data && response.data.error) {
          return { success: false, error: response.data.error };
        }

        return { success: true };
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: (_data, { formId, projectId }) => {
        queryClient.invalidateQueries<Form>([ReactQueryKey.FormFull, formId]);
        queryClient.invalidateQueries([ReactQueryKey.FormProgress, formId]);
        if (projectId) {
          queryClient.invalidateQueries([ReactQueryKey.RecordDetail, projectId]);
        }
      },
      onError: (error) => {
        showError(error.message);
      }
    }
  );
};
