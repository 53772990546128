import React from 'react';
import { UserAvatar } from '@kit/components/UserAvatar';
import { Member, MemberList as Container } from './styled';

interface Props {
  members: {
    id: number;
    name: string;
    avatarUrl: string;
  }[];
}

export const MemberList = ({ members }: Props) => {
  if (members.length === 0) {
    return null;
  }

  return (
    <Container>
      {members.map((member) => (
        <Member key={member.id}>
          <UserAvatar size={16} user={{ ...member, firstName: member.name }} />
          <div>{member.name}</div>
        </Member>
      ))}
    </Container>
  );
};
