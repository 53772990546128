import React, { ReactNode, ReactElement } from 'react';
import { css } from 'styled-components';
import { ModalProps as MUIModalProps } from '@material-ui/core';
import { Inner, Wrapper, Header, Title, Body, Footer } from './styled';

interface ModalProps extends Omit<MUIModalProps, 'title'> {
  title?: ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
  footerButtons?: ReactElement[];
  size?: string;
  wrapperCssMixin?: ReturnType<typeof css>;
}

export const Modal: React.FC<ModalProps> = (props) => {
  const {
    children,
    title,
    footer,
    footerButtons,
    size,
    ...other
  } = props;

  return (
    <Wrapper {...other}>
      <Inner size={size}>
        <Header>
          <Title>
            {title}
          </Title>
        </Header>
        <Body>
          {children}
        </Body>
        <Footer>
          {footer || footerButtons}
        </Footer>
      </Inner>
    </Wrapper>
  );
};
