import React from 'react';
import { RightPanel as Container } from './styled';
import { Feed } from './Feed';

interface Props {
  systemId: number;
}

export const RightPanel = ({ systemId }: Props) => {
  return (
    <Container>
      <Feed systemId={systemId} />
    </Container>
  );
};
