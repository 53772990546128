import React, { useCallback } from 'react';
import { FileText, Upload } from 'react-feather';
import { useModal } from '@common/PromiseModal';
import { CreateFormForm } from '@features/DocumentManagement/Forms/CreateFormForm';
import { ActionText, EmptySectionDropZone, EmptySectionDropZoneContent, EmptySectionDropZoneInner } from './styled';

interface Props {
  openFileDialog: () => void;
  recordId: number;
  section?: { name: string; id: number };
}

export const EmptyDropZone = ({ recordId, section, openFileDialog }: Props) => {
  const { openModal } = useModal();

  const handleAddForm = useCallback(() => {
    openModal<void>(
      ({ onClose }) => <CreateFormForm initialSection={section} recordId={recordId} onClose={onClose} />,
      {
        title: 'Create Form'
      }
    );
  }, [openModal, recordId, section]);

  return (
    <EmptySectionDropZone>
      <EmptySectionDropZoneInner>
        <EmptySectionDropZoneContent>
          <ActionText onClick={handleAddForm}>
            <FileText size="16px" />
            Create form
          </ActionText>
          {' or '}
          <ActionText onClick={openFileDialog}>
            <Upload size="16px" />
            Upload file
          </ActionText>
          {' or '}
          drop here
        </EmptySectionDropZoneContent>
      </EmptySectionDropZoneInner>
    </EmptySectionDropZone>
  );
};
