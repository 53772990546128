import { PrivilegedTask } from '@generated/types/graphql';
import React from 'react';
import moment from 'moment';
import { WorkOrderStatus } from '@components/WorkOrder/WorkOrderStatus';
import { WorkOrderTypeIcon } from '@common/WorkOrderTypeIcon';
import { Calendar, User } from 'react-feather';
import { parseUtcDate } from '@utils/dates';
import { DrawerEntity, useDrawersContext } from '@contexts/DrawersContext';
import { UserAvatar } from '@kit/components/UserAvatar';
import { AvatarPlaceholder, CenteredRow, Meta, Title, TitleCell } from './styled';
import { Placeholder, RowContainer } from '../styled';

const formatDate = (date: string, isAllDay: boolean) => {
  const format = isAllDay ? 'MM/DD/YY' : 'MM/DD/YY h:mma';

  return moment(parseUtcDate(date)).format(format).replace(':00', '');
};

interface Props {
  workOrders: PrivilegedTask[];
}

export const Service = ({ workOrders }: Props) => {
  const { openDrawer } = useDrawersContext();

  const handleWorkOrderClick = (id: number) => {
    openDrawer(
      DrawerEntity.WORK_ORDER,
      id,
      workOrders.map((workOrder) => workOrder.id)
    );
  };

  return (
    <div>
      {workOrders.length === 0 && <Placeholder>No service work orders yet</Placeholder>}

      {workOrders.map((workOrder) => (
        <RowContainer key={workOrder.id} onClick={() => handleWorkOrderClick(workOrder.id)}>
          <CenteredRow>
            <TitleCell>
              {workOrder.assignee && <UserAvatar user={workOrder.assignee} />}
              {!workOrder.assignee && (
                <AvatarPlaceholder>
                  <User size="16px" />
                </AvatarPlaceholder>
              )}
              <WorkOrderTypeIcon isField={workOrder.isField} />#{workOrder.uid}: <Title>{workOrder.title}</Title>
            </TitleCell>

            <Meta>
              {workOrder.isField && workOrder.taskVisitsByTaskIdConnection?.nodes?.length > 0 && (
                <>
                  <Calendar size="12px" color="#9C9CAA" />
                  {formatDate(
                    workOrder.taskVisitsByTaskIdConnection.nodes[0].startDate,
                    workOrder.taskVisitsByTaskIdConnection.nodes[0].startDateAllDay
                  )}
                </>
              )}
              {!workOrder.isField && (workOrder.endDate || workOrder.startDate) && (
                <>
                  <Calendar size="12px" color="#9C9CAA" />
                  {formatDate(
                    workOrder.endDate ?? workOrder.startDate,
                    workOrder.endDate ? workOrder.endDateAllDay : workOrder.startDateAllDay
                  )}
                </>
              )}
            </Meta>

            <WorkOrderStatus status={workOrder.taskStatus} />
          </CenteredRow>
        </RowContainer>
      ))}
    </div>
  );
};
