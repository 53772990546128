import { RecordDocListItem, useRecordDocList } from '@hooks/documents/useRecordDocList';
import { RecordStageListItem, useRecordStageList } from '@hooks/records/useRecordStageList';
import { useMemo } from 'react';

const EMPTY_ARRAY: RecordDocListItem[] = [];
const EMPTY_STAGE_ARRAY: RecordStageListItem[] = [];

export const useDocListGroupedByStage = (recordId: number, isArchivedShown: boolean) => {
  const { data: stages = EMPTY_STAGE_ARRAY, isLoading: isStagesLoading } = useRecordStageList(recordId);
  const { data: docs = EMPTY_ARRAY, isLoading: isDocsLoading } = useRecordDocList(recordId, isArchivedShown);

  const groupedDocs = useMemo(() => {
    if (isStagesLoading || isDocsLoading) {
      return EMPTY_ARRAY;
    }

    return [
      ...stages.map((stage) => ({
        stage,
        docs: docs.filter((doc) => doc.stageId === stage.id)
      })),
      {
        stage: {
          id: -1,
          name: 'Other'
        },
        docs: docs.filter((doc) => !doc.stageId || !stages.some((stage) => doc.stageId === stage.id))
      }
    ];
  }, [docs, isDocsLoading, isStagesLoading, stages]);

  return {
    groupedDocs,
    isLoading: isStagesLoading || isDocsLoading
  };
};
