import React from 'react';
import { Undo, Redo } from '@material-ui/icons';
import { RotateCcw, RotateCw, MinusCircle, PlusCircle, Edit2, Square, Circle } from 'react-feather';
import { Instance } from 'pspdfkit';
import { DragHandIcon } from '@kit/ui/icons/DragHand';
import { ToolbarAction, ActionGroup, ZoomActionGroup, RightActionsGroup } from './styled';
import { useTools } from './useTools';
// import { default as PS, Instance } from 'pspdfkit';

interface Props {
  // PSPDFKit: PS;
  PSPDFKit: any;
  pspdfkitInstance: Instance;
  isFullScreen: boolean;
}

const iconStyle = {
  width: 18,
  height: 18
};

export const PSPDFKitToolbar = ({ isFullScreen, PSPDFKit, pspdfkitInstance }: Props) => {
  const {
    interactionMode,
    zoomValue,
    onZoomIn,
    onZoomOut,
    onResetZoom,
    onRotateLeft,
    onRotateRight,
    onFreeHand,
    onFreeDraw,
    onSquare,
    onCircle,
    onUndo,
    onRedo
  } = useTools(pspdfkitInstance);

  if (!PSPDFKit || !pspdfkitInstance) {
    return null;
  }

  return (
    <>
      <ActionGroup isPaired isWide={isFullScreen}>
        <ToolbarAction onClick={onUndo}>
          <Undo style={iconStyle} />
        </ToolbarAction>
        <ToolbarAction onClick={onRedo}>
          <Redo style={iconStyle} />
        </ToolbarAction>
      </ActionGroup>

      <ActionGroup isWide={isFullScreen}>
        <ToolbarAction isActive={interactionMode === 'PAN'} onClick={onFreeHand}>
          <DragHandIcon size={16} />
        </ToolbarAction>
        <ToolbarAction isActive={interactionMode === 'INK'} onClick={onFreeDraw}>
          <Edit2 size={16} />
        </ToolbarAction>
        <ToolbarAction isActive={interactionMode === 'SHAPE_RECTANGLE'} onClick={onSquare}>
          <Square size={16} />
        </ToolbarAction>
        <ToolbarAction isActive={interactionMode === 'SHAPE_ELLIPSE'} onClick={onCircle}>
          <Circle size={16} />
        </ToolbarAction>
      </ActionGroup>

      <RightActionsGroup isFullScreen={isFullScreen}>
        <ActionGroup isPaired isWide={isFullScreen}>
          <ToolbarAction onClick={onRotateLeft}>
            <RotateCcw size={16} />
          </ToolbarAction>
          <ToolbarAction onClick={onRotateRight}>
            <RotateCw size={16} />
          </ToolbarAction>
        </ActionGroup>

        <ZoomActionGroup isWide={isFullScreen}>
          <ToolbarAction onClick={onZoomOut}>
            <MinusCircle size={16} />
          </ToolbarAction>
          <ToolbarAction onClick={onResetZoom}>{zoomValue}%</ToolbarAction>
          <ToolbarAction onClick={onZoomIn}>
            <PlusCircle size={16} />
          </ToolbarAction>
        </ZoomActionGroup>
      </RightActionsGroup>
    </>
  );
};
