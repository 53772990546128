import React, { useCallback, useMemo, useState } from 'react';

import { useRecordDetail } from '@hooks/useRecordDetail';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { Select } from '@kit/ui/Select';
import { Info } from 'react-feather';
import { useContactMutations } from '@hooks/useContacts';
import { selectWorkspaceId } from '@state/selectors';
import { useAppSelector } from '@hooks/store';
import { useQueryClient } from 'react-query';
import { ReactQueryKey } from '@enums';
import { Body, Description, FieldLabel, Footer } from './styled';

interface Props {
  recordId: number;
  onClose: () => void;
}

export const InviteToPortal = ({ recordId, onClose }: Props) => {
  const { data: record } = useRecordDetail(recordId, { refetchOnMount: false });
  const companyId = useAppSelector(selectWorkspaceId);
  const queryClient = useQueryClient();
  const [selectedContact, setSelectedContact] = useState(record.projectContacts[0]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    inviteToPortal: { mutateAsync: invite }
  } = useContactMutations(companyId);

  const handleInvite = useCallback(async () => {
    setIsSubmitting(true);
    await invite(selectedContact.id);

    await queryClient.invalidateQueries([ReactQueryKey.RecordDetail, recordId]);

    setIsSubmitting(false);
    onClose();
  }, [invite, selectedContact, queryClient, recordId, onClose]);

  const contactEmailsAndPhones = useMemo(() => {
    if (!selectedContact) {
      return [];
    }

    const emails = selectedContact.emails || [];
    const phones = selectedContact.phones || [];

    return [...emails, ...phones].filter(Boolean);
  }, [selectedContact]);

  return (
    <>
      <Body>
        <FieldLabel>Contact to invite</FieldLabel>
        <Select
          value={selectedContact}
          onChange={(_, value) => setSelectedContact(value)}
          getOptionLabel={(option) => option.name}
          options={record.projectContacts}
        />

        {contactEmailsAndPhones.length === 0 && (
          <Description>This contact does not have any email or phone</Description>
        )}

        {contactEmailsAndPhones.length > 0 && (
          <Description>
            <Info size="16px" />
            <div>
              Invitation will be sent to {selectedContact.name}:
              {contactEmailsAndPhones.map((contact) => (
                <div key={contact}>{contact}</div>
              ))}
            </div>
          </Description>
        )}
      </Body>
      <Footer>
        <Button variant={ButtonVariant.Secondary} onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={isSubmitting || contactEmailsAndPhones.length === 0}
          variant={ButtonVariant.Primary}
          onClick={handleInvite}
        >
          Invite
        </Button>
      </Footer>
    </>
  );
};
