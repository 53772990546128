import React from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { Textarea } from '@kit/ui/Textarea';
import { FormField } from '../FormField';
import { FormControl, FormInputPropsToOmit } from '../types';
import { useControllerWithValidation } from '../useControllerWithValidation';
import { useInlineEditableField } from './useInlineEditableField';

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends Omit<React.ComponentProps<typeof Textarea>, FormInputPropsToOmit>,
    FormControl<TFieldValues, TName> {
  renderView: (value: string, onClick: () => void) => React.ReactNode;
  onChanged?: () => void;
}

export const InlineEditableTextareaField = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  label,
  description,
  name,
  control,
  clearOnUnmount,
  renderView,
  onChanged,
  ...inputProps
}: Props<TFieldValues, TName>) => {
  const { isEditMode, onEnterEditMode, onExitEditMode } = useInlineEditableField(onChanged);

  const {
    field: { ...controlProps },
    fieldState: { error }
  } = useControllerWithValidation(name, control, label, clearOnUnmount);

  return (
    <FormField name={name} label={label} error={error?.message} description={description}>
      {!isEditMode && renderView(controlProps.value as string, onEnterEditMode)}
      {isEditMode && (
        <Textarea
          autoFocus
          multiline
          minRows={1}
          {...controlProps}
          {...inputProps}
          data-test-id={`field-${controlProps.name}`}
          onBlur={onExitEditMode}
        />
      )}
    </FormField>
  );
};
