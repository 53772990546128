import { STATUS_LIFECYCLE_WORKFLOWS } from './constants';
import { isWorkflowRequired } from './hooks';
import {
  AnalyticsModule,
  AnalyticsWidgetType,
  DimensionType,
  PredefinedWidgetFilterFieldId,
  WidgetSettings
} from './types';

export const isStatusLifecycleWorkflow = (id: number | string) => {
  return STATUS_LIFECYCLE_WORKFLOWS.includes(id.toString());
};

export const isMeasureRequired = (widgetType: AnalyticsWidgetType) => {
  return !isWorkflowRequired(widgetType);
};

export const isSettingsValid = (settings: Partial<WidgetSettings>) => {
  if (!settings.widgetType) {
    return false;
  }

  if (!settings.module) {
    return false;
  }

  if (!settings.dateRangeType) {
    return false;
  }

  if (!settings.measure && !isWorkflowRequired(settings.widgetType)) {
    return false;
  }

  if (isWorkflowRequired(settings.widgetType) && !settings.workflowId) {
    return false;
  }

  if (!isWorkflowRequired(settings.widgetType) && settings.widgetType !== AnalyticsWidgetType.KPI) {
    if (settings.module === AnalyticsModule.WORK_ORDERS) {
      if (!settings.dimensionId) {
        return false;
      }

      if (settings.dimensionId === DimensionType.ASSIGNEE && !settings.subdimensionId) {
        return false;
      }
    }

    if (!settings.dimensionId || (settings.module !== AnalyticsModule.WORK_ORDERS && !settings.subdimensionId)) {
      return false;
    }
  }

  return true;
};

export const isWorkOrderSingleSelectFilterField = (filterFieldId: PredefinedWidgetFilterFieldId | number) =>
  filterFieldId === PredefinedWidgetFilterFieldId.WORK_ORDER_ASSIGNEE ||
  filterFieldId === PredefinedWidgetFilterFieldId.WORK_ORDER_STATUS ||
  filterFieldId === PredefinedWidgetFilterFieldId.WORK_ORDER_PRIORITY ||
  filterFieldId === PredefinedWidgetFilterFieldId.WORK_ORDER_LABEL ||
  filterFieldId === PredefinedWidgetFilterFieldId.WORK_ORDER_TEMPLATE;

export const isWorkOrderTextFilterField = (filterFieldId: PredefinedWidgetFilterFieldId | number) =>
  filterFieldId === PredefinedWidgetFilterFieldId.WORK_ORDER_TITLE ||
  filterFieldId === PredefinedWidgetFilterFieldId.WORK_ORDER_DESCRIPTION;

export const isWorkOrderTypeFilterField = (filterFieldId: PredefinedWidgetFilterFieldId | number) =>
  filterFieldId === PredefinedWidgetFilterFieldId.WORK_ORDER_TYPE;

export const isWorkOrderNextVisitDateFilterField = (filterFieldId: PredefinedWidgetFilterFieldId | number) =>
  filterFieldId === PredefinedWidgetFilterFieldId.WORK_ORDER_NEXT_VISIT_DATE;

export const isWorkOrderDateFilterField = (filterFieldId: PredefinedWidgetFilterFieldId | number) =>
  filterFieldId === PredefinedWidgetFilterFieldId.WORK_ORDER_CREATED_AT ||
  filterFieldId === PredefinedWidgetFilterFieldId.WORK_ORDER_COMPLETED_AT ||
  filterFieldId === PredefinedWidgetFilterFieldId.WORK_ORDER_START_DATE ||
  filterFieldId === PredefinedWidgetFilterFieldId.WORK_ORDER_END_DATE ||
  filterFieldId === PredefinedWidgetFilterFieldId.WORK_ORDER_NEXT_VISIT_DATE;
