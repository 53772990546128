import { Form, FormValidationRules, SelectField, useForm } from '@kit/components/Form';
import React, { useCallback, useMemo } from 'react';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { RecordType } from '@types';
import { WorkflowListItem, useWorkflowList } from '@hooks/workflows/useWorkflowList';
import { useUpdateEffect } from '@react-hookz/web';
import { useProjectBulk } from '@hooks/useProjectBulk';
import { ReactQueryKey } from '@enums';
import { useQueryClient } from 'react-query';
import { ModalFooter, useConfirmDeleteModal } from '@common/PromiseModal';
import { Body } from './styled';

type FormValues = {
  workflow: WorkflowListItem;
  stage: { id: number; name: string };
};

interface Props {
  recordId: number;
  recordType: RecordType;
  currentWorkflowId: number | null;
  onClose: () => void;
}

const rules: FormValidationRules<FormValues> = {
  workflow: {
    isRequired: true
  },
  stage: {
    isRequired: true
  }
};

const RECORD_TYPE_NAMES = {
  [RecordType.PROJECT]: 'Project',
  [RecordType.DEAL]: 'Request'
};

export const EditWorkflowForm = ({ recordId, currentWorkflowId, recordType, onClose }: Props) => {
  const { data: workflows = [] } = useWorkflowList();
  const queryClient = useQueryClient();
  const confirmDelete = useConfirmDeleteModal();

  const workflowOptions = useMemo(
    () => workflows.filter((workflow) => workflow.type === recordType),
    [workflows, recordType]
  );

  const {
    update: { mutateAsync: bulkUpdateProjects }
  } = useProjectBulk(recordType);

  const invalidateCache = useCallback(async () => {
    // TODO refactor and move it bulk update hook logic
    if (recordType === RecordType.PROJECT) {
      await Promise.all([
        queryClient.invalidateQueries([ReactQueryKey.WorkspaceProjects]),
        queryClient.invalidateQueries([ReactQueryKey.ProjectsListInitialGroupData]),
        queryClient.invalidateQueries([ReactQueryKey.ProjectsByIds]),
        queryClient.invalidateQueries([ReactQueryKey.RecordDetail, recordId])
      ]);
    } else if (recordType === RecordType.DEAL) {
      await Promise.all([
        queryClient.invalidateQueries([ReactQueryKey.WorkspaceDeals]),
        queryClient.invalidateQueries([ReactQueryKey.RequestsListInitialGroupData]),
        queryClient.invalidateQueries([ReactQueryKey.RequestsByIds]),
        queryClient.invalidateQueries([ReactQueryKey.RecordDetail, recordId])
      ]);
    }
    queryClient.invalidateQueries([ReactQueryKey.ProjectActivity]);
  }, [queryClient, recordId, recordType]);

  const postForm = async (values: FormValues) => {
    const selectedWorkflowId = values.workflow?.id ?? values.workflow;
    if (
      selectedWorkflowId !== currentWorkflowId &&
      !(await confirmDelete(
        `Please note that updating the Workflow will keep existing Work Orders in the same ${RECORD_TYPE_NAMES[recordType]} Stages, as long as they exist in the new Workflow. Otherwise they will appear in the first ${RECORD_TYPE_NAMES[recordType]} Stage of the new Workflow. Existing Docs will remain in the same ${RECORD_TYPE_NAMES[recordType]} Stage Folders, as long as they exist as well. Otherwise they will be moved to the "Other" Folder. This action can not be undone.`,
        'UPDATE'
      ))
    ) {
      return;
    }

    await bulkUpdateProjects({
      projects: [recordId],
      field: {
        fieldId: -24, // workflow
        target: -1,
        value: selectedWorkflowId,
        stageId: values.stage.id
      }
    });

    setTimeout(() => {
      invalidateCache();
    }, 1000);

    onClose();
  };

  const { form, handleSubmit } = useForm({
    onSubmit: postForm,
    defaultValues: {
      workflow: currentWorkflowId,
      stage: null
    }
  });

  const {
    formState: { isSubmitting },
    control,
    watch,
    setValue
  } = form;

  const workflowValue = watch('workflow');

  useUpdateEffect(() => {
    setValue('stage', null);
  }, [workflowValue, setValue]);

  const stageOptions = useMemo(() => {
    if (!workflowValue) {
      return [];
    }

    const selectedWorkflow = workflowValue?.id
      ? workflowValue
      : workflows.find((workflow) => workflow.id === workflowValue);

    return selectedWorkflow?.blueprintProjectStages.map((workflowStage) => workflowStage.projectStage) || [];
  }, [workflowValue, workflows]);

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <Body width="750px">
        <SelectField
          getOptionLabel={(option) => option.name}
          control={control}
          name="workflow"
          getOptionValue={(option) => option.id}
          options={workflowOptions}
          label={`${RECORD_TYPE_NAMES[recordType]} Workflow`}
        />
        <SelectField
          getOptionLabel={(option) => option.name}
          control={control}
          name="stage"
          options={stageOptions}
          label="Workflow Stage"
        />
      </Body>
      <ModalFooter>
        <Button onClick={onClose} variant={ButtonVariant.Secondary}>
          Cancel
        </Button>
        <Button type="submit" variant={ButtonVariant.Primary} disabled={isSubmitting}>
          Update
        </Button>
      </ModalFooter>
    </Form>
  );
};
