import styled from 'styled-components';

export const ConfigAndPreview = styled.div`
  display: flex;
  gap: 24px;

  > * {
    flex: 1;
    min-width: 0;
  }
`;

export const Separator = styled.div`
  height: 1px;
  background-color: #dfdfe8;
  margin: 24px 0;
`;

export const WidgetTypeRadioGroupWrapper = styled.div`
  label + * {
    margin-top: 8px;
  }

  .MuiRadio-root {
    display: none;
  }
`;

export const DoubleField = styled.div<{ leftInputWidth?: string; rightInputWidth?: string }>`
  display: flex;
  align-items: flex-start;

  > *:first-child {
    ${({ leftInputWidth }) =>
      leftInputWidth
        ? `
      width: ${leftInputWidth};
      min-width: ${leftInputWidth};
      flex: 0 0 ${leftInputWidth};  
    `
        : `flex: 1`};

    .MuiOutlinedInput-root {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    label + * {
      &:hover {
        position: relative;
        z-index: 1;
      }
    }

    .Mui-disabled {
      .MuiAutocomplete-endAdornment {
        display: none;
      }

      .MuiOutlinedInput-notchedOutline {
        border-color: #dfdfe8;
      }
    }
  }

  > *:last-child {
    margin-left: -1px;

    ${({ rightInputWidth }) =>
      rightInputWidth
        ? `
      width: ${rightInputWidth};
      min-width: ${rightInputWidth};
      flex: 0 0 ${rightInputWidth};  
    `
        : `flex: 1`};

    label {
      visibility: hidden;
    }

    .MuiOutlinedInput-root {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
`;

export const TwoFields = styled.div`
  display: flex;
  gap: 24px;
  align-items: flex-start;

  > * {
    flex: 1;
  }
`;

export const FiltersTitle = styled.div`
  color: #828d9a;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

export const DateRangeFilterRow = styled(TwoFields)`
  align-items: flex-end;
  > *:last-child {
    display: flex;
    gap: 16px;
    align-items: center;

    > * {
      margin-bottom: 16px;
    }
  }

  .date-field-input {
    .MuiInputAdornment-positionEnd {
      display: none;
    }
    .MuiInputAdornment-positionStart {
      margin-right: 0px;
    }

    .MuiOutlinedInput-adornedStart {
      padding-left: 4px;
    }

    .MuiOutlinedInput-adornedEnd {
      padding-right: 4px !important;
    }
  }
`;

export const Preview = styled.div`
  height: 684px;
  max-height: calc(100vh - 64px - 64px - 64px - 48px);

  > * {
    height: 100%;
  }
`;

export const FieldWrapper = styled.div<{ isLabelHidden?: boolean }>`
  label {
    visibility: ${({ isLabelHidden }) => (isLabelHidden ? 'hidden' : 'visible')};
  }
`;
