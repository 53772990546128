import React, { useCallback } from 'react';
import { ProjectAdapted } from '@types';
import { Checkbox } from '@kit/ui/Checkbox';
import { useRoutes } from '@hooks/useRoutes';
import { Archive } from 'react-feather';
import { Status } from '@features/ClientPortfolio/components/Status';
import { AccountTypeBadge } from '@features/ClientPortfolio/components/Type';
import { PinnedCommentsPopover } from '@features/ProjectPortfolio/List/Table/TableBody/TitleCell/PinnedCommentsPopover';
import { Container, Td, Right, FirstLine, SecondLine, Title, ImageAndCheckboContainer } from './styled';

interface Props {
  project: ProjectAdapted;
  isSelected: boolean;
  toggleItem: (project: ProjectAdapted) => void;
}

export const TitleCell = ({ project, isSelected, toggleItem }: Props) => {
  const { companyId } = useRoutes();
  const handleCheckboxChange = useCallback(() => {
    toggleItem(project);
  }, [toggleItem, project]);

  const handleClick = useCallback((e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
  }, []);

  return (
    <Td isSelected={isSelected}>
      <Container>
        <ImageAndCheckboContainer>
          <Checkbox isChecked={isSelected} onChange={handleCheckboxChange} />
        </ImageAndCheckboContainer>
        <Right>
          <FirstLine>
            <Status status={project.projectDetail.accountStatus} />
            {!project.projectDetail.isActive && <Archive color="#9C9CAA" size="16px" />}
            <Title
              isGray={!project.projectDetail.isActive}
              onClick={handleClick}
              href={`/${companyId}/clients/${project.projectId}`}
            >
              <span>#{project.projectDetail.uid}:</span> {project.projectDetail.title}
            </Title>
          </FirstLine>
          <SecondLine>
            <AccountTypeBadge color="#828D9A" type={project.projectDetail.accountType} />
            <PinnedCommentsPopover
              recordId={project.projectDetail.id}
              comments={project.projectDetail.pinnedComments.nodes}
            />
          </SecondLine>
        </Right>
      </Container>
    </Td>
  );
};
