import styled from 'styled-components';
import { defaultTheme } from '@themes';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: -10px;
`;

export const Pager = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-size: 12px;
  line-height: 1.25;
  color: ${defaultTheme.colors.gray2};

  select {
    border: none;
    background: none;
    margin-left: 5px;
    color: ${defaultTheme.colors.gray2};
  }
`;
