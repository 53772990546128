import * as actionTypes from '../../actions/actionTypes/actionTypes';

export const initialState = {
  isLoading: false,
  userList: [],
  error: ''
};

const updateReducer = (state, action) => {
  return { ...state, ...action.payload };
};

const updateSingleUser = (state, action) => {
  const userData = action.payload.user;
  const userList = [...state.userList];
  const userIndex = userList.findIndex((user) => user.id === userData.id);
  if (userIndex < 0) return;
  if (!userData.isActive) {
    //if updated user is inactive then remove
    userList.splice(userIndex, 1);
  } else {
    userData.role = userData.roles;
    userList[userIndex] = userData;
  }
  return { ...state, userList: [...userList] };
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_USERS_SETTING:
      return updateReducer(state, action);
    case actionTypes.UPDATE_SINGLE_USER:
      return updateSingleUser(state, action);
    default:
      return state;
  }
};

export default usersReducer;
