import { Field } from 'formik';
import React from 'react';
import { Form } from '@components/Project/DataBank/types';

import { FormLink } from './FormLink';

interface FormLinkFieldProps {
  name?: string;
  projectId: number;
  companyId: number;
  onFormClick?: (form: Form) => void;
  onChange?: (newValue: any) => void;
  isDisabled?: boolean;
  onCreateNewForm?: (formIdToReplace?: number) => void;
}

const sortFormsByCreatedAtDesc = (forms: Form[]) =>
  [...forms].sort((a, b) => {
    const aCreatedAt = a.isTemplate ? new Date() : new Date(a.createdAt);
    const bCreatedAt = b.isTemplate ? new Date() : new Date(b.createdAt);

    if (aCreatedAt < bCreatedAt) {
      return 1;
    }

    if (aCreatedAt > bCreatedAt) {
      return -1;
    }

    return 0;
  });

export const FormLinkField: React.FC<FormLinkFieldProps> = ({
  projectId,
  name,
  companyId,
  onFormClick,
  onChange,
  isDisabled,
  onCreateNewForm
}: FormLinkFieldProps) => (
  <Field name={name}>
    {({ field, form: { setFieldValue, values } }) => (
      <FormLink
        forms={values[field.name]}
        onAddLink={(form) => {
          const newValue = [form, ...values[field.name]];
          setFieldValue(field.name, sortFormsByCreatedAtDesc(newValue));
          onChange?.(newValue);
        }}
        onRemoveLink={(id) => {
          const newValue = [...values[field.name].filter((form) => form.id !== id)];
          setFieldValue(field.name, newValue);
          onChange?.(newValue);
        }}
        onUpdateLink={(id, newForm) => {
          const newValue = values[field.name].map((form) => {
            if (form.id === id) {
              return { ...newForm };
            }

            return form;
          });
          setFieldValue(field.name, newValue);
          onChange?.(newValue);
        }}
        projectId={projectId}
        companyId={companyId}
        onFormClick={onFormClick}
        isDisabled={isDisabled}
        onCreateNewForm={onCreateNewForm}
      />
    )}
  </Field>
);
