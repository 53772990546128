import { ListItem, makeStyles } from '@material-ui/core';
import { Link } from '@reach/router';
import { colors, fonts, getColorWithOpacity, scroll } from '@styles';

import styled from 'styled-components';
import { Badge } from '@common/ui';

const drawerWidth = 240;

export const useDrawerStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    whiteSpace: 'nowrap',
    flexShrink: 0
  },
  paper: {
    backgroundColor: colors.black,
    color: colors.white
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: 56
  }
}));

export const UserMenuItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 0;
  transition: margin-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

  > * {
    flex-shrink: 0;

    &:last-child {
      flex: 1;
      overflow: hidden;

      > * {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
`;

export const UserContact = styled.div`
  color: #828d9a;
  font-size: 12px;
  font-weight: 400;
  line-height: 130%;
`;

export const HoverableMenu = styled.div`
  position: relative;
  height: 100%;
  overflow-y: auto;
  ${scroll};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;
  padding-bottom: 12px;

  .MuiTypography-body1 {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    font-family: ${fonts.primary};
  }

  .MuiListItem-root {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .MuiListItem-gutters {
    padding-left: 19px;
  }

  .MuiListItemIcon-root {
    transition: min-width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    min-width: ${(props) => (props.isOpen ? '32px' : '56px')};
    color: ${colors.white};
  }

  .MuiListItem-button:hover,
  .MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover {
    background-color: #ffffff29;
  }

  &::-webkit-scrollbar-track {
    background-color: ${colors.black};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${getColorWithOpacity(colors.white, 20)};
    outline: none;
    border-radius: 4px;
  }

  ${UserMenuItem} {
    margin-left: ${({ isOpen }) => (isOpen ? 0 : '-8px')};
  }
`;

export const BottomMenuItems = styled.div`
  margin-top: auto;

  .MuiButtonBase-root {
    margin-top: 16px;
  }
`;

export const NestedMenuItem = styled(ListItem)`
  && {
    transition: padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

    padding-left: ${(props) => (props.isOpen ? '40px' : '19px')};
  }
`;

export const NavLink = styled(Link)`
  color: ${colors.white};
  text-decoration: none;
`;

export const Toggle = styled.div`
  background-color: ${colors.black};
  border-radius: 50%;
  color: ${colors.white};
  position: absolute;
  top: 14px;
  left: ${(props) => (props.isDrawerOpen ? '230px' : '47px')};
  transition: left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  line-height: 12px;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1201;
  cursor: pointer;

  .mobile {
    display: none;
  }

  @media screen and (max-width: 767px) {
    & {
      display: none;
    }
  }
`;

export const SeparatorMenuItem = styled.div`
  margin-top: 16px;

  @media (min-height: 990px) {
    margin-top: 24px;
  }
`;

export const IconBadge = styled(Badge)`
  position: relative;
  top: -10px;
  left: -7px;
`;

export const SmartViewsContainer = styled.div<{ isDrawerShown: boolean }>`
  padding-left: ${(props) => (props.isDrawerShown ? '40px' : '55px')};
  transition: padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  padding-right: 16px;
  padding-bottom: 8px;
  margin-top: 8px;
  overflow-y: auto;
  overflow-x: hidden;
  ${scroll};
  /*max-height: calc(100vh - 44px - 8px - 4 * 16px - 10 * 32px - 44px - 16px - 32px - 16px - 12px - 16px - 16px);*/
  max-height: calc(100vh - 588px);

  > *:first-child {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  &::-webkit-scrollbar-track {
    background-color: ${colors.black};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${getColorWithOpacity(colors.white, 20)};
    outline: none;
    border-radius: 4px;
  }

  @media (min-height: 990px) {
    max-height: 300px;
  }
`;

export const SmartViewsMenuItem = styled(ListItem)`
  .MuiListItemText-root {
    flex-grow: 0;
    margin-right: 8px;
  }

  .arrow {
    min-width: auto;
  }

  > svg {
    margin-left: auto;
  }
`;
