import { useQuery } from 'react-query';
import { gql } from 'graphql-request';

import { useAppSelector } from '@hooks';
import { UserFromApi } from '@types';
import { ReactQueryKey } from '@enums';
import { postGraphql } from '@services/api/base/graphql';
import { CompanyUser, CompanyUserFilter, MainUser, MainUserFilter, Role } from '@generated/types/graphql';
import userApi from '@services/api/userApi';
import { apiErrorHandler } from '@utils';
import { selectWorkspaceId } from '@state/selectors';
import { DeepPartial } from 'redux';

export const useCompanyUsers = (companyIdFromParam?: number, enabled: boolean = true) => {
  // const { companyId: $companyId } = useRoutes();
  const $companyId = useAppSelector(selectWorkspaceId);
  const companyId = companyIdFromParam ?? $companyId;

  const fetchQuery = useQuery<UserFromApi[]>([ReactQueryKey.CompanyUsers, companyId], userApi.getAssigneesAll, {
    enabled: enabled && !!companyId,
    initialData: { data: { results: [] } },
    staleTime: 1000 * 3600 * 24,
    initialDataUpdatedAt: Date.now() - 1000 * 3600 * 24
  });

  return {
    ...fetchQuery
  };
};

const getSeachUserFilter = (search: string): DeepPartial<MainUserFilter> => {
  const isEmail = search.includes('@');
  const isPhone = search.match(/^\+?\d+$/);
  const hasSpace = search.includes(' ');

  if (isEmail) {
    return { email: { includesInsensitive: search } };
  }

  if (isPhone) {
    return { phone: { includesInsensitive: search } };
  }

  if (!hasSpace) {
    return {
      or: [
        {
          firstName: { includesInsensitive: search }
        },
        {
          lastName: { includesInsensitive: search }
        }
      ]
    };
  }

  const [namePart1, namePart2] = search.split(' ').filter(Boolean);

  if (!namePart2) {
    return {
      or: [
        {
          firstName: { includesInsensitive: namePart1 }
        },
        {
          lastName: { includesInsensitive: namePart1 }
        }
      ]
    };
  }

  return {
    or: [
      {
        firstName: { includesInsensitive: namePart1 },
        lastName: { includesInsensitive: namePart2 }
      },
      {
        firstName: { includesInsensitive: namePart2 },
        lastName: { includesInsensitive: namePart1 }
      },
      {
        firstName: { includesInsensitive: search }
      },
      {
        lastName: { includesInsensitive: search }
      }
    ]
  };
};

// it was previously used to fetch all users from all current user's companies
// but in new design user is working only with one company at a time
// so it should be callled "useCompanyUsers" but it is already used
export const useAllCompaniesUsers = ({
  enabled,
  search,
  onlyActive = true
}: { enabled?: boolean; search?: string; onlyActive?: boolean } = {}) => {
  const companyId = useAppSelector(selectWorkspaceId);

  const withSearch = typeof search !== 'undefined';

  const limit = withSearch ? 50 : undefined;

  const filters: DeepPartial<MainUserFilter> = {
    companyUsersByUserId: {
      some: {
        companyId: { equalTo: companyId },
        isActive: onlyActive ? { equalTo: true } : undefined
      }
    },
    ...(withSearch && getSeachUserFilter(search))
  };

  return useQuery<MainUser[]>(
    [ReactQueryKey.CompanyUsers, 'graphql', companyId, search, onlyActive],
    async () => {
      try {
        const data = await postGraphql(
          gql`
            query COMPANY_USERS_QUERY($limit: Int, $filters: MainUserFilter) {
              mainUsers(first: $limit, filter: $filters, orderBy: [FIRST_NAME_ASC, LAST_NAME_ASC]) {
                id
                firstName
                lastName
                avatarUrl
                email
                phone
              }
            }
          `,
          { limit, filters }
        );

        return data.mainUsers;
      } catch (e) {
        throw apiErrorHandler('Error fetching all companies assignees', e);
      }
    },
    {
      enabled: enabled ?? true,
      /// / initialData: { data: { results: [] } },
      initialData: [],

      staleTime: 1000 * 3600 * 24,
      initialDataUpdatedAt: Date.now() - 1000 * 3600 * 24
    }
  );
};

export type ExtendedUser = Pick<MainUser, 'id' | 'firstName' | 'lastName' | 'avatarUrl' | 'email' | 'phone'> & {
  roles: { id: number; name: string }[];
  teams: { id: number; name: string }[];
};

type UseCompanyUserQuery = {
  userTeams: {
    team: {
      id: number;
      name: string;
    };
  }[];
  mainUser: MainUser;
};

export const useCompanyUser = (userId: number) => {
  const companyId = useAppSelector(selectWorkspaceId);

  return useQuery<ExtendedUser>(
    [ReactQueryKey.CompanyUsers, companyId, 'byId', userId],
    async () => {
      try {
        const data = await postGraphql<UseCompanyUserQuery>(
          gql`
            query USE_COMPANY_USER($userId: Int!, $companyId: Int!) {
              userTeams(filter: { workerId: { equalTo: $userId }, team: { companyId: { equalTo: $companyId } } }) {
                team {
                  id
                  name
                }
              }
              mainUser(id: $userId) {
                id
                firstName
                lastName
                email
                phone
                avatarUrl
                companyUsersByUserId(filter: { companyId: { equalTo: $companyId } }) {
                  companyUserRoles {
                    roles {
                      id
                      name
                    }
                  }
                }
              }
            }
          `,
          { userId, companyId }
        );

        return {
          ...data.mainUser,
          roles:
            data.mainUser.companyUsersByUserId[0]?.companyUserRoles.map((userRoles) => userRoles.roles).flat() ?? [],
          teams: data.userTeams.map((userTeam) => userTeam.team)
        };
      } catch (e) {
        throw apiErrorHandler('Error fetching all companies assignees', e);
      }
    },
    {
      enabled: !!userId && !!companyId,
      staleTime: 1000 * 3600 * 24
    }
  );
};

type UserWithRole = Pick<MainUser, 'id' | 'firstName' | 'lastName' | 'avatarUrl'> & {
  role: Pick<Role, 'id' | 'name' | 'settings'>;
};

type CompanyUserWithRoleArgs = {
  search?: string;
  userIds?: number[];
  isEnabled?: boolean;
};

export const useCompanyUsersWithRoles = ({ search = '', userIds = [], isEnabled }: CompanyUserWithRoleArgs) => {
  const companyId = useAppSelector(selectWorkspaceId);
  const limit = 50;

  const filter: DeepPartial<CompanyUserFilter> = {
    companyId: { equalTo: companyId },
    isActive: { equalTo: true },
    user: getSeachUserFilter(search),
    userId: userIds.length ? { in: userIds } : undefined
  };

  return useQuery<UserWithRole[]>(
    [ReactQueryKey.CompanyUsers, 'graphql', companyId, 'with-roles', search, userIds],
    async () => {
      try {
        const data = await postGraphql<{ companyUsers: CompanyUser[] }>(
          gql`
            query COMPANY_USERS_WITH_ROLES($limit: Int, $filter: CompanyUserFilter) {
              companyUsers(first: $limit, filter: $filter) {
                user {
                  id
                  firstName
                  lastName
                  avatarUrl
                }

                companyUserRoles {
                  roles {
                    id
                    name
                    settings
                  }
                }
              }
            }
          `,
          { limit, filter }
        );

        return data.companyUsers.map((companyUser) => ({
          ...companyUser.user,
          role: companyUser.companyUserRoles[0]?.roles
        }));
      } catch (e) {
        throw apiErrorHandler('Error fetching users with roles', e);
      }
    },
    {
      enabled: isEnabled
    }
  );
};
