// import './wdyr';

import Alert from '@common/Alert';
import { useAppDispatch } from '@hooks/store';
import { isLoggedIn } from '@services/AuthenticationServices/AuthenticationService';
import { authCheckState, logOut } from '@state/actions/authentication/authenticationAction';
import { updateReleaseVersion } from '@utils/utils';
import { SnackbarProvider } from 'notistack';
import React, { useEffect } from 'react';
import { useEffectOnce } from 'react-use';
import { Helmet } from 'react-helmet';
import { ModalsProvider } from '@common/PromiseModal';
import { useIntercom } from '@hooks';
import { useCanny } from '@hooks/useCanny';
import { Tooltip } from 'react-tooltip';
import { Content } from './Content';
import { MainMenu } from './MainMenu';
import { Container, ContentUnderTopMenu } from './styled';
import { TopBar } from './TopBar';
import { useLayout } from './useLayout';
import { DrawersProvider } from '../../contexts/DrawersContext';
import './index.css';

interface Props {
  children: React.ReactNode;
}

const MAX_SNACKS_COUNT = 10;

export const LayoutNew = ({ children }: Props) => {
  const { isHidden } = useLayout();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isHidden) {
      return;
    }

    dispatch(authCheckState(true, true));
  }, [isHidden, dispatch]);

  useEffectOnce(() => {
    (async () => {
      const releaseVersionUpdated = await updateReleaseVersion();

      if (releaseVersionUpdated && isLoggedIn()) {
        console.warn('new app version, logging out');
        dispatch(logOut());
      }
    })();
  });

  useIntercom();

  useCanny();

  if (isHidden) {
    return (
      <>
        <Helmet>
          <meta httpEquiv="Cross-Origin-Opener-Policy" content="same-origin, same-origin-allow-popups" />
        </Helmet>
        {children}
      </>
    );
  }

  return (
    <ModalsProvider>
      <DrawersProvider>
        <SnackbarProvider maxSnack={MAX_SNACKS_COUNT}>
          <Container id="app-container">
            <MainMenu />

            <Content>
              <TopBar />

              <ContentUnderTopMenu id="content-under-top-menu">{children}</ContentUnderTopMenu>
              <Alert />
              <Tooltip id="react-root-tooltip" className="tooltip" />
            </Content>
          </Container>
        </SnackbarProvider>
      </DrawersProvider>
    </ModalsProvider>
  );
};
