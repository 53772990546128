import { useWorkflowList } from '@hooks/workflows/useWorkflowList';
import { useDeepCompareMemo } from '@react-hookz/web';
import {
  ANALYTICS_MODULE_RECORD_TYPE_MAP,
  WORKFLOW_CLIENT_STATUS_LIFECYCLE_ID,
  WORKFLOW_PROJECT_STATUS_LIFECYCLE_ID,
  WORKFLOW_REQUEST_STATUS_LIFECYCLE_ID,
  WORKFLOW_WORK_ORDER_STATUS_LIFECYCLE_ID,
  WIDGET_TYPES_WITH_WORKFLOW
} from '../constants';
import { AnalyticsModule, AnalyticsWidgetType } from '../types';

export const isWorkflowRequired = (widgetType: AnalyticsWidgetType) => {
  return WIDGET_TYPES_WITH_WORKFLOW.includes(widgetType);
};

export const useWorkflowOptions = (module: AnalyticsModule, widgetType: AnalyticsWidgetType) => {
  const recordType = ANALYTICS_MODULE_RECORD_TYPE_MAP[module];

  const { data: allWorkflows = [], isLoading } = useWorkflowList();

  const isWithWorkflow = isWorkflowRequired(widgetType);

  return useDeepCompareMemo(() => {
    if (!isWithWorkflow || isLoading) {
      return [];
    }

    if (module === AnalyticsModule.WORK_ORDERS) {
      return [
        {
          id: WORKFLOW_WORK_ORDER_STATUS_LIFECYCLE_ID,
          name: 'Work Order Status Lifecycle'
        }
      ];
    }

    if (module === AnalyticsModule.CLIENTS) {
      return [
        {
          id: WORKFLOW_CLIENT_STATUS_LIFECYCLE_ID,
          name: 'Client Status Lifecycle'
        }
      ];
    }

    return allWorkflows
      .filter((workflow) => workflow.type === recordType)
      .concat(
        module === AnalyticsModule.PROJECTS
          ? [{ id: WORKFLOW_PROJECT_STATUS_LIFECYCLE_ID, name: 'Project Status Lifecycle' }]
          : [{ id: WORKFLOW_REQUEST_STATUS_LIFECYCLE_ID, name: 'Request Status Lifecycle' }]
      );
  }, [allWorkflows, recordType, isWithWorkflow, module, isLoading]);
};
