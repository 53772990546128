import React, { useCallback, useMemo, useState } from 'react';
import { EmailEventType } from '@enums';
import { ChevronsUpIcon } from '@kit/ui/icons/ChevronsUp';
import { ChevronsDownIcon } from '@kit/ui/icons/ChevronsDown';
import { MailIcon } from '@kit/ui/icons/Mail';
import { useModal } from '@common/PromiseModal';
import { CornerUpLeft, User } from 'react-feather';
import { colors } from '@styles';
import { QueryParamsEnum, useQueryParam } from '@hooks/useQueryParam';
import { RecordType } from '@types';
import { Link } from '@reach/router';
import { UserAvatar } from '@kit/components/UserAvatar';
import { cleanHtml } from '@utils/utils';
import { getHumanFriendlyTimeAgo } from './BaseItemTemplate';
import { FeedItem, FeedItemProps } from './types';
import {
  AvatarPlaceholder,
  BorderContainer,
  ChildFeedItems,
  Clamped,
  CommentContent,
  CommentHeader,
  CommentHeaderPart,
  CommentHeaderTitle,
  CreatedAt,
  EmailFeedItemContainer,
  EmailFeedItemContent,
  EmailFeedItemHeader,
  EmailWrapper,
  ExpandRepliesButton,
  FeedItemIconContainer,
  GroupItemContainer,
  ItemActionButton,
  MetaInfo,
  MetaLabel,
  Title,
  EmailFrom
} from './styled';
import { EmailReplyForm } from './EmailReplyForm';
import { ENTITY_NAME_BY_RECORD_TYPE } from './constants';

interface Props {
  item: FeedItem;
  parentItem?: FeedItem;

  isRepliesEnabled?: boolean;
  isHighlighted?: boolean;
}

const EmailItem = ({ item, parentItem, isHighlighted, isRepliesEnabled }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const emailMessage = item.emailMessage || item.payload?.emailMessage;

  const toggleExpanded = useCallback(() => {
    setIsExpanded((prev) => !prev);
  }, []);

  const isIncoming = item.eventType === EmailEventType.RECEIVED;

  const author = !isIncoming ? item.createdBy : { id: '', firstName: emailMessage.from, lastName: '' };

  const userName = [author.firstName, author.lastName].filter(Boolean).join(' ');

  const body = emailMessage.bodyCleaned || emailMessage.body;

  const rawText = cleanHtml(body);

  const { openModal } = useModal();

  const replyFrom = useMemo(() => {
    if (!isIncoming) {
      return item.emailMessage.from;
    }

    const parent = parentItem ?? item;

    const outgoingReplies = parent.childFeedsConnection.nodes
      .filter((child) => child.eventType === EmailEventType.SENT)
      .reverse();

    if (outgoingReplies.length > 0) {
      return outgoingReplies[0].emailMessage.from;
    }

    if (parent.eventType === EmailEventType.SENT) {
      return parent.emailMessage.from;
    }

    return undefined;
  }, [isIncoming, parentItem, item]);

  const handleReplyClick = useCallback(() => {
    openModal<void>(
      ({ onClose }) => (
        <EmailReplyForm
          replyToMessageId={item.emailMessage.id}
          replyTo={isIncoming ? [item.emailMessage.from] : item.emailMessage.to}
          onClose={onClose}
          parentEmail={parentItem ?? item}
          replyFrom={replyFrom}
        >
          <EmailItem item={parentItem ?? item} initialIsRepliesExpanded isRepliesEnabled={false} />
          {(parentItem ?? item).childFeedsConnection.nodes.map((child) => (
            <EmailItem key={child.id} item={child} parentItem={parentItem ?? item} isRepliesEnabled={false} />
          ))}
        </EmailReplyForm>
      ),
      { title: `Email: ${emailMessage.subject || 'No subject'}` }
    );
  }, [parentItem, item, openModal, emailMessage.subject, isIncoming, replyFrom]);

  const isRoot = !parentItem;

  return (
    <GroupItemContainer
      isHighlighted={isHighlighted}
      noRightPadding={isRoot}
      id={isRoot ? undefined : `feed_${item.id}`}
    >
      <BorderContainer hideTopBorder={isRoot}>
        <CommentContent>
          <CommentHeader>
            <CommentHeaderPart verticalAlign="flex-start">
              {!isIncoming && author && <UserAvatar user={author} />}
              {isIncoming && (
                <AvatarPlaceholder>
                  <User size="16px" />
                </AvatarPlaceholder>
              )}
              <CommentHeaderTitle>
                <b>{userName}</b>
                {userName !== emailMessage.from && <EmailFrom>{`<${emailMessage.from}>`}</EmailFrom>}
              </CommentHeaderTitle>
            </CommentHeaderPart>
            <CommentHeaderPart verticalAlign="center">
              <CreatedAt>{getHumanFriendlyTimeAgo(item.createdAt)}</CreatedAt>
              <ItemActionButton onClick={toggleExpanded}>
                {isExpanded ? <ChevronsUpIcon size="16px" /> : <ChevronsDownIcon size="16px" />}
              </ItemActionButton>
              {isRepliesEnabled && (
                <ItemActionButton onClick={handleReplyClick}>
                  <CornerUpLeft size="16px" color={colors.green} />
                </ItemActionButton>
              )}
            </CommentHeaderPart>
          </CommentHeader>
          <EmailWrapper>
            {isExpanded && (
              <>
                <MetaInfo>
                  <MetaLabel>To:</MetaLabel> <b>{emailMessage.to.join(', ')}</b>
                </MetaInfo>
                {(emailMessage.cc ?? []).length > 0 && (
                  <MetaInfo>
                    <MetaLabel>cc:</MetaLabel> <b>{emailMessage.cc.join(', ')}</b>
                  </MetaInfo>
                )}
                {(emailMessage.bcc ?? []).length > 0 && (
                  <MetaInfo>
                    <MetaLabel>bcc:</MetaLabel> <b>{emailMessage.bcc.join(', ')}</b>
                  </MetaInfo>
                )}
              </>
            )}
            {!isExpanded && <Clamped>{rawText}</Clamped>}
            {isExpanded && <div dangerouslySetInnerHTML={{ __html: body }} />}
          </EmailWrapper>
        </CommentContent>
      </BorderContainer>
    </GroupItemContainer>
  );
};

interface EmailFeedItemProps extends FeedItemProps {
  initialIsRepliesExpanded?: boolean;
  isRepliesEnabled?: boolean;
}

export const EmailFeedItem = ({
  item,
  context,
  contextEntityId,
  initialIsRepliesExpanded,
  isRepliesEnabled = true
}: EmailFeedItemProps) => {
  const emailMessage = item.emailMessage || item.payload?.emailMessage;

  const ChildWrapperComponent = ChildFeedItems;

  const replies = !item.parentId ? item.childFeedsConnection.nodes : [];

  const [isRepliesExapanded, setIsRepliesExpanded] = useState(initialIsRepliesExpanded);

  const [feedId] = useQueryParam(QueryParamsEnum.FeedId);

  const isHighlighted = feedId && +feedId === item.id;

  const showProjectLink = context === 'client' && contextEntityId !== item.payload.project?.id;

  return (
    <>
      <EmailFeedItemContainer id={`feed_${item.id}`} isHighlighted={isHighlighted}>
        <BorderContainer>
          <div>
            <EmailFeedItemHeader>
              <FeedItemIconContainer color="#ECE3FF">
                <MailIcon color="#7256AC" size="12px" />
              </FeedItemIconContainer>
              <Title>
                <b>Email: {emailMessage.subject || 'No subject'}</b>
                {showProjectLink && (
                  <>
                    {` in ${ENTITY_NAME_BY_RECORD_TYPE[item.payload.project?.type || RecordType.PROJECT]} `}
                    <Link
                      to={`${window.location.pathname}/projects/${item.payload.project?.id}${window.location.search}`}
                    >
                      {item.payload.project?.title}
                    </Link>
                  </>
                )}
              </Title>
            </EmailFeedItemHeader>

            <EmailFeedItemContent>
              <EmailItem item={item} isRepliesEnabled />
            </EmailFeedItemContent>
          </div>
        </BorderContainer>
      </EmailFeedItemContainer>

      {replies.length > 0 && (
        <ChildWrapperComponent>
          {!isRepliesExapanded && replies.length > 1 && (
            <ExpandRepliesButton onClick={() => setIsRepliesExpanded(true)}>
              {replies.length - 1} more {replies.length === 2 ? 'reply' : 'replies'}...
            </ExpandRepliesButton>
          )}
          {isRepliesExapanded &&
            replies.length > 1 &&
            replies
              .slice(0, -1)
              .map((child) => (
                <EmailItem
                  isHighlighted={feedId && child.id === +feedId}
                  key={child.id}
                  item={child}
                  parentItem={item}
                  isRepliesEnabled={isRepliesEnabled}
                />
              ))}

          <EmailItem
            isHighlighted={feedId && replies[replies.length - 1].id === +feedId}
            item={replies[replies.length - 1]}
            parentItem={item}
            isRepliesEnabled={isRepliesEnabled}
          />
        </ChildWrapperComponent>
      )}
    </>
  );
};
