import * as React from 'react';
import { CircularProgressProps, CircularProgress, makeStyles } from '@material-ui/core';
import { defaultTheme } from '@themes';

const useStylesFacebook = makeStyles(() => ({
  root: {
    position: 'relative'
  },
  bottom: {
    color: defaultTheme.colors.gray4
  },
  top: {
    color: defaultTheme.colors.green,
    animationDuration: '550ms',
    position: 'absolute',
    left: 0
  },
  circle: {
    strokeLinecap: 'round'
  }
}));

export const FacebookCircularProgress = (props: CircularProgressProps) => {
  const classes = useStylesFacebook();

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </div>
  );
};
