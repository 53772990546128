import styled from 'styled-components';
import { colors, scroll, deviceMax } from '@styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 80px;

  border-radius: 4px;
  background-color: white;
  border: 1px dashed #dfdfe8;

  > span,
  .ant-upload-drag-container,
  .ant-upload {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  text-align: center;
  font-size: 14px;
  color: ${colors.green};
`;

export const FileList = styled.div`
  max-height: 200px;
  overflow-y: auto;
  margin-top: 12px;

  ${scroll};

  ${deviceMax.md`
    max-height: initial;
  `}
`;
