import React from 'react';
import { System } from '@generated/types/graphql';
import { SYSTEM_STATUS_COLORS } from '@styles';
import { getStatusDesc } from '@features/SystemPortfolio/constants';
import { Badge } from '@kit/ui/Badge';
import { Container, Text } from './styled';

type Props = {
  system: System;
  vertical?: boolean;
};

export const SystemStatus = ({ system, vertical = false }: Props) => {
  return (
    <Container vertical={vertical}>
      <Badge color={SYSTEM_STATUS_COLORS[system.status].color} bgColor={SYSTEM_STATUS_COLORS[system.status].bgColor}>
        {system.status.toUpperCase()}
      </Badge>

      <Text title={getStatusDesc(system)}>{getStatusDesc(system)}</Text>
    </Container>
  );
};
