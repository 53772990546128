import { useMutation, useQueryClient } from 'react-query';
import { Call } from '@generated/types/graphql';
import { CreateCallPayload, callApi } from '@services/api/callApi';
import { errorHandler } from '@services/api/helpers';
import { ReactQueryKey } from '@enums';
import { useToast } from '..';

export const useCreateCall = () => {
  const { showSuccess } = useToast();
  const queryClient = useQueryClient();

  return useMutation<Call, Error, CreateCallPayload>(
    async (dto) => {
      try {
        const { data } = await callApi.create(dto);

        return data;
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: () => {
        showSuccess('Call successfully created');
        queryClient.invalidateQueries(ReactQueryKey.ProjectActivity);
      }
    }
  );
};
