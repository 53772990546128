import React from 'react';
import { NoResult } from '@kit/components/NoResult';
import { useRecordDetail } from '@hooks/useRecordDetail';
import { Container, Wrapper, Content, ErrorWrapper } from './styled';
import { Overview } from './Overview';
import { RightPanel } from './RightPanel';
import { Header } from './Header';

interface Props {
  projectID: string;
}

export const Project = React.memo(({ projectID }: Props) => {
  const { data: record, isFetching } = useRecordDetail(+projectID);

  if (!isFetching && !record?.id) {
    return (
      <ErrorWrapper>
        <NoResult subject="project" />
      </ErrorWrapper>
    );
  }

  return (
    <Container>
      <Header recordId={+projectID} />
      <Wrapper>
        <Content>
          <Overview projectId={+projectID} />
        </Content>

        <RightPanel projectId={+projectID} />
      </Wrapper>
    </Container>
  );
});
