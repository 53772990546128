import { Form, FormValidationRules, RichTextField, useForm } from '@kit/components/Form';
import React, { forwardRef, useCallback, useMemo } from 'react';
import { convertQuillFormatToText, isQuilValueEmpty } from '@utils/quill';
import { useComments } from '@hooks/useComments';
import { CommentType } from '@generated/types/graphql';
import { Maximize2, Send } from 'react-feather';
import { UseFormReturn } from 'react-hook-form';
import { useModal } from '@common/PromiseModal';
import { Button, ButtonSize, ButtonVariant, IconButton } from '@kit/ui/Button';
import { ActionButtons, BodyWithActionButtons, ExpandedContainer, SendIconButton } from './styled';

interface Props {
  initialValues?: { id?: number; comment: string };
  recordId: number;
  onClose?: () => void;
  size?: 'small' | 'default';
  isExpandable?: boolean;
  expandSize?: 'small' | 'default';
}

interface FormValues {
  text: string;
}

export const PortalMessageForm = forwardRef<HTMLDivElement, Props>(
  ({ recordId, onClose, initialValues, size = 'default', isExpandable = true, expandSize = 'default' }, ref) => {
    const isEdit = Boolean(initialValues?.id);

    const {
      create: { mutateAsync: createComment },
      update: { mutateAsync: updateComment }
    } = useComments();

    const postForm = async (values: FormValues, form: UseFormReturn<FormValues>) => {
      if (isEdit) {
        await updateComment({
          id: initialValues.id,
          dto: { comment: convertQuillFormatToText(values.text) }
        });
      } else {
        await createComment({
          projectId: recordId,
          comment: convertQuillFormatToText(values.text),
          type: CommentType.Project,
          scope: 'PORTAL_COMMUNICATION'
        });
      }

      form.reset({ text: '' });

      onClose?.();
    };

    const { handleSubmit, form } = useForm<FormValues>({
      onSubmit: postForm,
      defaultValues: initialValues?.comment ? { text: initialValues.comment } : {}
    });

    const rules = useMemo<FormValidationRules<FormValues>>(
      () => ({
        text: {
          isRequired: true,
          validate: (value) => {
            if (value && isQuilValueEmpty(value as string)) {
              return 'This is required';
            }

            return undefined;
          }
        }
      }),
      []
    );

    const {
      formState: { isSubmitting, errors },
      control
    } = form;

    const { openModal } = useModal();

    const handleExpandClick = useCallback(() => {
      const comment = form.getValues('text');
      form.reset({ text: '' });
      onClose?.();

      openModal(
        ({ onClose: onModalClose }) => (
          <ExpandedContainer>
            <PortalMessageForm
              recordId={recordId}
              onClose={() => {
                onModalClose();
              }}
              initialValues={{
                comment
              }}
              isExpandable={false}
            />
          </ExpandedContainer>
        ),
        { title: 'New message' }
      );
    }, [recordId, form, openModal, onClose]);

    return (
      <Form rules={rules} onSubmit={handleSubmit}>
        <BodyWithActionButtons size={size} ref={ref}>
          <RichTextField name="text" control={control} placeholder="Type your message to Client here..." autoFocus />
          <ActionButtons size={size} isBodyWithError={Boolean(errors.text)}>
            {isExpandable &&
              (expandSize === 'default' ? (
                <Button
                  size={ButtonSize.Small}
                  isUpperCase={false}
                  variant={ButtonVariant.Flat}
                  onClick={handleExpandClick}
                >
                  <Maximize2 size="16px" />
                  Expand
                </Button>
              ) : (
                <IconButton size={ButtonSize.Small} variant={ButtonVariant.Flat} onClick={handleExpandClick}>
                  <Maximize2 size="16px" />
                </IconButton>
              ))}

            {size === 'default' && (
              <Button variant={ButtonVariant.Flat} onClick={onClose}>
                Cancel
              </Button>
            )}

            {size === 'small' && (
              <SendIconButton disabled={isSubmitting} type="submit">
                <Send size="12px" color="#fff" />
              </SendIconButton>
            )}

            {size === 'default' && (
              <Button disabled={isSubmitting} type="submit" variant={ButtonVariant.Primary}>
                {isEdit ? 'Save' : 'Send'}
              </Button>
            )}
          </ActionButtons>
        </BodyWithActionButtons>
      </Form>
    );
  }
);
