import React, { useMemo } from 'react';
import { ProjectEventType } from '@enums';
import { ArrowRight } from 'react-feather';
import { PropertyType, RecordType } from '@types';
import { formatDate } from '@utils/dates';
import { BriefcaseIcon } from '@kit/ui/icons/Briefcase';
import { Link, useLocation } from '@reach/router';
import { AccountIcon } from '@common/icons/Account';
import { DollarIcon } from '@kit/ui/icons/Dollar';
import { ContactAvatar } from '@kit/components/ContactAvatar';
import { useCompanyProperties } from '@hooks/useCompanyProperties';
import { OldValue, StageName, UpdatedPropertes, ValuesWithArrow, FeedItemIconContainer } from './styled';
import { MemberList } from './MemberList';
import { CommentItem } from './CommentItem';
import { BaseItemTemplate } from './BaseItemTemplate';
import { FeedItemProps } from './types';
import { AutomationAvatar } from './WorkflowFeedItem';
import { ENTITY_NAME_BY_RECORD_TYPE } from './constants';

export const ProjectFeedItem = ({ item, context, contextEntityId }: FeedItemProps) => {
  const recordType: RecordType = item.payload.project?.type ?? RecordType.PROJECT;

  const { scopeToColumnsById } = useCompanyProperties({ recordType });

  const { pathname, search } = useLocation();

  const { title, children } = useMemo(() => {
    const author = item.createdBy ||
      item.createdByContact || { id: 0, firstName: item.userName, lastName: '', avatarUrl: item.userImage };

    const userName = author.name || [author.firstName, author.lastName].join(' ') || '';

    const entityName = ENTITY_NAME_BY_RECORD_TYPE[recordType];

    const getRecordNameWithPreposition = (preposition = '') =>
      context === 'client' && contextEntityId !== item.payload.project?.id ? (
        <>
          {' '}
          {preposition}
          {` ${entityName} `}
          <Link to={`${pathname}/projects/${item.payload.project?.id}${search}`}>{item.payload.project?.title}</Link>
        </>
      ) : (
        <b>{entityName}</b>
      );

    switch (item.eventType) {
      case ProjectEventType.CREATED:
        return {
          title: (
            <>
              <b>{userName}</b> created {getRecordNameWithPreposition()}
            </>
          ),
          children: null
        };
      case ProjectEventType.UPDATED:
        return {
          title: (
            <>
              <b>{userName}</b> updated <b>{entityName}</b>
            </>
          ),
          children: null
        };
      case ProjectEventType.ARCHIVED:
        return {
          title: (
            <>
              <b>{userName}</b> archived {getRecordNameWithPreposition()}
            </>
          ),
          children: null
        };
      case ProjectEventType.DELETED:
        return {
          title: (
            <>
              <b>{userName}</b> removed {getRecordNameWithPreposition()}
            </>
          ),
          children: null
        };

      case ProjectEventType.MEMBER_ADDED:
        if (!item.payload?.members?.length) {
          return {
            title: null,
            children: null
          };
        }

        return {
          title: (
            <>
              <b>{userName}</b> added <b>{entityName} members</b>
            </>
          ),
          children: <MemberList members={item.payload.members} />
        };

      case ProjectEventType.MEMBER_REMOVED:
        if (!item.payload?.members?.length) {
          return {
            title: null,
            children: null
          };
        }

        return {
          title: (
            <>
              <b>{userName}</b> removed <b>{entityName} members</b>
            </>
          ),
          children: <MemberList members={item.payload.members} />
        };

      case ProjectEventType.PROPERTY_UPDATED: {
        const { updatedProperties: allUpdatedProperties = [] } = item.payload;

        const updatedProperties = allUpdatedProperties.filter(
          (property) => scopeToColumnsById[recordType][property.id] || property.mappedName === 'isActive'
        );

        const renderPropertyValue = (property: any, isNew = true) => {
          const value = isNew ? property.newValue : property.oldValue;
          if (!value) {
            return 'None';
          }

          if (property.type === PropertyType.Person) {
            return `${value.firstName} ${value.lastName}`;
          }

          if (property.type === PropertyType.DateTime) {
            return formatDate(value as string, 'MM/DD/YY hh:mm A');
          }

          if (property.type === PropertyType.Date) {
            return formatDate(value as string, 'MM/DD/YY');
          }

          if (property.name.toLowerCase() === 'description') {
            return <div dangerouslySetInnerHTML={{ __html: value }} />;
          }

          if (Array.isArray(value)) {
            return value
              .filter(Boolean)
              .map((item) => (typeof item === 'string' ? item : item.label || item.title || item.id || ''))
              .join(', ');
          }

          if (typeof value === 'object') {
            return value.label || value.title || value.id || '';
          }

          return value;
        };

        if (updatedProperties.length === 0) {
          return {
            title: null,
            children: null
          };
        }

        if (updatedProperties.length === 1) {
          return {
            title: (
              <>
                <b>{userName}</b> updated <b>{updatedProperties[0].name}</b>
              </>
            ),
            children: (
              <UpdatedPropertes>
                <div>
                  <ValuesWithArrow isVertical={updatedProperties[0].name.toLowerCase() === 'description'}>
                    <OldValue>
                      <div>{renderPropertyValue(updatedProperties[0], false)}</div>
                    </OldValue>
                    <ArrowRight size="16px" />
                    <div>{renderPropertyValue(updatedProperties[0], true)}</div>
                  </ValuesWithArrow>
                </div>
              </UpdatedPropertes>
            )
          };
        }

        return {
          title: (
            <>
              <b>{userName}</b> updated <b>{entityName} properties</b>
            </>
          ),
          children: (
            <UpdatedPropertes>
              {updatedProperties.map((property: any) => (
                <div>
                  <div>
                    <b>{property.name}:</b>
                  </div>
                  <ValuesWithArrow isVertical={property.name.toLowerCase() === 'description'}>
                    <OldValue>
                      <div>{renderPropertyValue(property, false)}</div>
                    </OldValue>
                    <ArrowRight size="16px" />
                    <div>{renderPropertyValue(property, true)}</div>
                  </ValuesWithArrow>
                </div>
              ))}
            </UpdatedPropertes>
          )
        };
      }

      case ProjectEventType.STAGE_UPDATED:
        return {
          title: (
            <>
              <b>{userName}</b> updated <b>{entityName} stage</b>
            </>
          ),
          children: (
            <ValuesWithArrow>
              <OldValue>
                <StageName>{item.payload.previousStage?.name || 'None'}</StageName>
              </OldValue>
              <ArrowRight size="16px" />
              <StageName>{item.payload.currentStage?.name}</StageName>
            </ValuesWithArrow>
          )
        };

      default:
        return {
          title: null,
          children: null
        };
    }
  }, [item, context, contextEntityId, scopeToColumnsById, recordType, pathname, search]);

  if (item.eventType === ProjectEventType.COMMENTED) {
    return <CommentItem item={item} context={context} contextEntityId={contextEntityId} />;
  }

  if (!title && !children) {
    return null;
  }

  const author = item.createdBy || { id: 0, firstName: item.userName, lastName: '', avatarUrl: item.userImage };

  const isAutomation = author.firstName === 'Coperniq' && author.lastName === 'Automation';

  let authorIcon;

  if (isAutomation) {
    authorIcon = <AutomationAvatar />;
  } else if (item.createdByContact) {
    authorIcon = <ContactAvatar isPortalStatusShown={false} contact={item.createdByContact} />;
  }

  return (
    <BaseItemTemplate
      id={item.id}
      context={context}
      author={!authorIcon ? author : undefined}
      authorIcon={authorIcon}
      icon={
        <FeedItemIconContainer color="#F4E9EF">
          {recordType === RecordType.PROJECT && <BriefcaseIcon color="#9A6784" size="12px" />}
          {recordType === RecordType.ACCOUNT && <AccountIcon color="#9A6784" size={12} />}
          {recordType === RecordType.DEAL && <DollarIcon color="#9A6784" size="12px" />}
        </FeedItemIconContainer>
      }
      date={item.createdAt}
      title={title}
    >
      {children}
    </BaseItemTemplate>
  );
};
