import React from 'react';

export const PipelineChartIcon = ({
  size = '16px',
  color = 'currentColor'
}: {
  size?: string | number;
  color?: string;
}) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5 1.5C21.0523 1.5 21.5 1.94772 21.5 2.5L21.5 6.5C21.5 7.05228 21.0523 7.5 20.5 7.5L2.5 7.5C1.94772 7.5 1.5 7.05228 1.5 6.5L1.5 2.5C1.5 1.94771 1.94772 1.5 2.5 1.5L20.5 1.5ZM19.5 3.5L12 3.5L12 5.5L19.5 5.5L19.5 3.5Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.4333 16.5C17.0224 16.5 17.5 16.9477 17.5 17.5L17.5 21.5C17.5 22.0523 17.0224 22.5 16.4333 22.5L2.56667 22.5C1.97756 22.5 1.5 22.0523 1.5 21.5L1.5 17.5C1.5 16.9477 1.97756 16.5 2.56667 16.5L16.4333 16.5ZM15.5 18.5L10 18.5L10 20.5L15.5 20.5L15.5 18.5Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 9C11.0523 9 11.5 9.44772 11.5 10L11.5 14C11.5 14.5523 11.0523 15 10.5 15L2.5 15C1.94771 15 1.5 14.5523 1.5 14L1.5 10C1.5 9.44771 1.94771 9 2.5 9L10.5 9ZM9.5 11L6.5 11L6.5 13L9.5 13L9.5 11Z"
      fill={color}
    />
  </svg>
);
