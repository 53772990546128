import { Quill } from 'react-quill';

const Embed = Quill.import('blots/embed');

class TokenBlot extends Embed {
  static create(data: { value: string; }) {
    const node = super.create();

    let valueFirstPart;
    let valueSecondPart;

    if (data.value.includes(': ')) {
      [valueFirstPart, valueSecondPart] = data.value.split(': ');

      node.innerHTML += `/${valueFirstPart}: `;
      node.innerHTML += `<strong>${valueSecondPart}</strong>`;
    } else {
      node.innerHTML += `/${data.value}`;
    }

    node.addEventListener('click', (e: MouseEvent) => {
      e.preventDefault();
    }, false);

    return TokenBlot.setDataValues(node, data);
  }

  static setDataValues(element: HTMLElement, data: Record<string, string>) {
    const domNode = element;
    Object.keys(data)
      .forEach((key) => {
        domNode.dataset[key] = data[key];
      });

    return domNode;
  }

  static value(domNode: HTMLElement) {
    return domNode.dataset;
  }
}

TokenBlot.blotName = 'token-strong';
TokenBlot.tagName = 'span';
TokenBlot.className = 'cq-token-strong';

Quill.register(TokenBlot);
