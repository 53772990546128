import React from 'react';

export const PieChartIcon = ({ size = '16px', color = 'currentColor' }: { size?: string | number; color?: string }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 1.875C9.99747 1.875 8.0399 2.46882 6.37486 3.58137C4.70981 4.69392 3.41206 6.27523 2.64572 8.12533C1.87939 9.97543 1.67888 12.0112 2.06955 13.9753C2.46023 15.9393 3.42454 17.7435 4.84055 19.1595C6.25656 20.5755 8.06066 21.5398 10.0247 21.9305C11.9888 22.3211 14.0246 22.1206 15.8747 21.3543C17.7248 20.5879 19.3061 19.2902 20.4186 17.6251C21.5312 15.9601 22.125 14.0025 22.125 12C22.122 9.3156 21.0543 6.74199 19.1562 4.84383C17.258 2.94567 14.6844 1.87798 12 1.875ZM13.125 4.20656C15.1253 4.49579 16.9379 5.54304 18.1875 7.13156L13.125 10.0519V4.20656ZM4.6875 14.9222C4.24445 13.8161 4.05838 12.6238 4.14331 11.4352C4.22825 10.2467 4.58198 9.09297 5.17782 8.06108C5.77366 7.02919 6.59605 6.14609 7.58296 5.47838C8.56987 4.81067 9.69552 4.3758 10.875 4.20656V11.3503L4.6875 14.9222ZM12 19.875C10.809 19.8748 9.63367 19.604 8.56262 19.0832C7.49157 18.5624 6.55282 17.8051 5.81719 16.8684L19.3125 9.07781C19.79 10.2726 19.9674 11.5662 19.8292 12.8455C19.6911 14.1247 19.2416 15.3506 18.5201 16.416C17.7986 17.4813 16.827 18.3536 15.6904 18.9566C14.5538 19.5596 13.2867 19.875 12 19.875Z"
      fill={color}
    />
  </svg>
);
