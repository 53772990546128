import { BuildingIcon } from '@kit/ui/icons/Building';
import { AccountType } from '@types';
import React from 'react';
import { Home } from 'react-feather';
import { Container } from './styled';

interface Props {
  type: AccountType;
  size?: string;
  color?: string;
}

export const Type = ({ type, size = '16px', color }: Props) => {
  return (
    <Container color={color}>
      {type === AccountType.RESIDENTIAL ? (
        <Home size={size} color="#9C9CAA" />
      ) : (
        <BuildingIcon size={size} color="#9C9CAA" />
      )}
      <div>{type === AccountType.RESIDENTIAL ? 'Residential' : 'Commercial'}</div>
    </Container>
  );
};
