import * as Sentry from "@sentry/gatsby";

const STAND = process.env.STAND || 'local';

Sentry.init({
    dsn: 'https://c7ffb6210b1c65c4ee5f9f2f96efeb15@o476151.ingest.sentry.io/5515247',
    environment: STAND,
    integrations: [new Sentry.Replay()],
    tracesSampleRate: STAND !== 'local' ? 0.01 : 0,
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracePropagationTargets: ['develop.coperniq.dev', 'staging.coperniq.dev', 'coperniq.dev'],
});