import React from 'react';
import { File, ProjectProperty } from '@generated/types/graphql';
import { AlertCircle, AlignLeft, Calendar, Disc, Layers, Paperclip, User } from 'react-feather';
import { CheckSquareIcon } from '@kit/ui/icons/CheckSquare';
import { NumericIcon } from '@kit/ui/icons/Numeric';
import { FormTemplate, FormTemplateLayout, FormTemplateLayoutColumn } from '@hooks/useForms';
import { PropertyType } from '@types';

export const ROOT_LAYOUT_ELEMENT_TYPE = 'GROUP';

export enum FormLayoutType {
  COLUMN = 'COLUMN',
  GROUP = ROOT_LAYOUT_ELEMENT_TYPE
}

export enum FormLayoutColumnType {
  Dropdown = 'DROPDOWN',
  Numeric = 'NUMERIC',
  Text = 'TEXT',
  File = 'FILE',
  Project = 'PROJECT'
}

export enum FormFieldType {
  Text = 'text',
  Numeric = 'numeric',
  SingleSelect = 'single-select',
  MultiSelect = 'multi-select',
  File = 'file',
  Person = 'person',
  Date = 'date',
  Unknown = 'unknown'
  // Project = 'project'
}

export const mapProjectPropertyToFormFieldType = (property: ProjectProperty) => {
  switch (property.type) {
    case PropertyType.Date:
    case PropertyType.DateTime:
      return FormFieldType.Date;
    case PropertyType.Numeric:
      return FormFieldType.Numeric;
    case PropertyType.Text:
    case PropertyType.Link:
      return FormFieldType.Text;
    case PropertyType.Dropdown:
      return property.multiple ? FormFieldType.MultiSelect : FormFieldType.SingleSelect;
    case PropertyType.File:
      return FormFieldType.File;
    case PropertyType.Person:
      return FormFieldType.Person;
    default:
      return FormFieldType.Text;
  }
};

const mapFormLayoutColumnTypeToFormFieldType = (column: FormTemplateLayoutColumn) => {
  switch (column.type) {
    case FormLayoutColumnType.Dropdown:
      return column.multiple ? FormFieldType.MultiSelect : FormFieldType.SingleSelect;
    case FormLayoutColumnType.Numeric:
      return FormFieldType.Numeric;
    case FormLayoutColumnType.Text:
      return FormFieldType.Text;
    case FormLayoutColumnType.File:
      return FormFieldType.File;
    case FormLayoutColumnType.Project:
      if (!column.projectColumn) {
        return FormFieldType.Unknown;
      }

      return mapProjectPropertyToFormFieldType(column.projectColumn);
    default:
      return FormFieldType.Text;
  }
};

export const mapFormFieldTypeToFormLayoutColumnType = (fieldType: FormFieldType) => {
  switch (fieldType) {
    case FormFieldType.MultiSelect:
      return FormLayoutColumnType.Dropdown;
    case FormFieldType.SingleSelect:
      return FormLayoutColumnType.Dropdown;
    case FormFieldType.Numeric:
      return FormLayoutColumnType.Numeric;
    case FormFieldType.Text:
      return FormLayoutColumnType.Text;
    case FormFieldType.File:
      return FormLayoutColumnType.File;
    default:
      return FormLayoutColumnType.Text;
  }
};

export const FORM_FIELD_TYPE_CONFIGS = {
  [FormFieldType.Text]: {
    id: FormFieldType.Text,
    label: 'Text',
    icon: <AlignLeft size="16px" />
  },
  [FormFieldType.Numeric]: {
    id: FormFieldType.Numeric,
    label: 'Numeric',
    icon: <NumericIcon size="16px" />
  },
  [FormFieldType.SingleSelect]: {
    id: FormFieldType.SingleSelect,
    label: 'Single select',
    icon: <Disc size="16px" />
  },
  [FormFieldType.MultiSelect]: {
    id: FormFieldType.MultiSelect,
    label: 'Multiple select',
    icon: <CheckSquareIcon size="16px" />
  },
  [FormFieldType.File]: {
    id: FormFieldType.File,
    label: 'File',
    icon: <Paperclip size="16px" />
  },
  [FormFieldType.Date]: {
    id: FormFieldType.Date,
    label: 'Date',
    icon: <Calendar size="16px" />,
    isHidden: true
  },
  [FormFieldType.Person]: {
    id: FormFieldType.Person,
    label: 'Person',
    icon: <User size="16px" />,
    isHidden: true
  },
  [FormFieldType.Unknown]: {
    id: FormFieldType.Unknown,
    label: 'Unknown',
    icon: <AlertCircle size="16px" />,
    isHidden: true
  }
};

export const FORM_FIELD_TYPES = Object.values(FORM_FIELD_TYPE_CONFIGS).filter((fieldType) =>
  'isHidden' in fieldType ? !fieldType.isHidden : true
);

export const LAYOUT_ITEMS = [
  {
    id: ROOT_LAYOUT_ELEMENT_TYPE,
    label: 'Group',
    icon: <Layers size="16px" />
  }
];

const DEFAULT_GROUP_ID = 'default_group';

export const mapProjectPropertyToFormBuilderElementConfig = (property: Property) => {
  return {
    columnId: property.id,
    name: property.name,
    multiple: property.multiple,
    isEditable: property.readonly ? false : property.isReadOnly !== true,
    isReadOnlyProperty: property.readonly,
    isRequired: property.isRequired,
    options: property.additional?.values ? property.additional.values.map((value: string) => ({ value })) : undefined,
    projectColumnId: property.id
  };
};

export const mapFormLayoutToFormBuilderElementConfig = (formLayout: FormTemplateLayout) => {
  const { column } = formLayout;

  const options = column.projectColumn?.additional?.values ?? column.additional?.values;

  return {
    layoutElementId: formLayout.id,
    columnId: column.id,
    name: column.name,
    multiple: column.multiple,
    isEditable: column.isReadOnly !== true,
    isRequired: column.isRequired,
    options: options ? options.map((value: string) => ({ value })) : undefined,
    projectColumnId: column.projectColumnId,
    notes: column.notes,
    referenceFiles: column.projectPropertyFileReferencesByColumnId?.map((fileRef) => fileRef.file) ?? []
  };
};

export const mapFormLayoutsToDndStructure = (formLayouts: FormTemplate['formLayouts']) => {
  // 2 scenarios
  // 1. Everything is in groups
  // 2. There are some elements without group
  // DnD structure requires everything to be in groups
  // If there are elements without group, we create a default group for them

  const originalGroups = formLayouts.filter((formElement) => formElement.type === FormLayoutType.GROUP);
  const isAllGroups = originalGroups.length === formLayouts.length;

  let groups = originalGroups;

  if (!isAllGroups) {
    const defaultGroup: FormTemplateLayout = {
      id: DEFAULT_GROUP_ID,
      type: FormLayoutType.GROUP,
      name: '',
      childFormLayouts: formLayouts.filter((formElement) => formElement.type !== FormLayoutType.GROUP)
    };

    groups = [defaultGroup, ...groups];
  }

  const configsById: { [id: string]: FormBuilderElement } = {
    ...groups.reduce(
      (acc, group) => ({
        ...acc,
        [group.id]: {
          id: group.id,
          type: FormLayoutType.GROUP,
          config: {
            name: group.name
          },
          children: group.childFormLayouts.map((layout) => layout.id.toString())
        },
        ...group.childFormLayouts.reduce((acc, formElement) => {
          if (formElement.type !== FormLayoutType.COLUMN) {
            return acc;
          }

          return {
            ...acc,
            [formElement.id]: {
              id: formElement.id,
              type: mapFormLayoutColumnTypeToFormFieldType(formElement.column),
              config: mapFormLayoutToFormBuilderElementConfig(formElement)
            }
          };
        }, {})
      }),
      {}
    )
  };

  const layout = groups.map((group) => group.id);

  return { layout, configsById };
};

export const mapDndStructureToFormTemplateLayout = (
  layout: string[],
  configsById: { [key: string]: FormBuilderElement },
  forBackend = false
): FormTemplateLayout[] => {
  const result: FormTemplateLayout[] = [];

  const includeIds = !forBackend;

  const childrenPropertyName = forBackend ? 'children' : 'childFormLayouts';

  layout.forEach((id) => {
    const config = configsById[id];

    if (config.type === FormLayoutType.GROUP) {
      const group = config as FormBuilderGroupElement;
      result.push({
        id: includeIds ? group.id : undefined,
        type: FormLayoutType.GROUP,
        name: group.config.name,
        [childrenPropertyName]: group.children
          .map((childId) => configsById[childId] as FormBuilderFieldElement)
          .map((child) => ({
            id: includeIds ? child.id : undefined,
            type: FormLayoutType.COLUMN,
            column: {
              name: child.config.name,
              projectColumnId: child.config.projectColumnId ?? null,
              notes: child.config.notes ?? null,
              isReadOnly: !child.config.isEditable,
              isRequired: child.config.isRequired ?? false,
              referenceFiles: forBackend ? undefined : child.config.referenceFiles,
              referenceFileIds: child.config.referenceFiles?.map((file) => file.id) ?? [],
              type: child.config.projectColumnId
                ? FormLayoutColumnType.Project
                : mapFormFieldTypeToFormLayoutColumnType(child.type),
              additional:
                !child.config.projectColumnId && child.config.options
                  ? { values: child.config.options.map(({ value }) => value.trim()).filter(Boolean) }
                  : {},
              multiple: child.type === FormFieldType.MultiSelect
            }
          }))
      });
    }
  });

  return result;
};

type FormBuilderElementType = FormFieldType | FormLayoutType.GROUP;

export interface FormBuilderElementBase {
  id: string;
  type: FormBuilderElementType;
  config: {
    name: string;
  };
}

export interface FormBuilderGroupElement extends FormBuilderElementBase {
  children: string[];
  type: FormLayoutType.GROUP;
}

export interface FormBuilderFieldElement extends FormBuilderElementBase {
  type: FormFieldType;
  config: {
    name: string;
    multiple?: boolean;
    isEditable?: boolean;
    isReadOnlyProperty?: boolean;
    isRequired?: boolean;
    options: { value: string }[];
    projectColumnId?: number;
    notes: string;
    referenceFiles: File[];
  };
}

export type FormBuilderElement = FormBuilderGroupElement | FormBuilderFieldElement;
