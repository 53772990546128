import { createGlobalState } from 'react-use';
import { Identified } from '@types';
import { Filter } from '@services/api/types';
import { useAppSelector } from '@hooks/store';
import { useCRUD, UseCRUDParams } from '@hooks/useCRUD';
import { selectCompanyIdBySelectedProject, selectWorkspaceIdFromLocation } from '@state/selectors';
import { ReactQueryKey } from '@enums';

const TEMPLATE_FILTERS = {
  all: {
    filters: []
  },
  active: {
    filters: [
      {
        col: 'isArchived',
        val: false,
        op: '='
      }
    ]
  },
  archive: {
    filters: [{ col: 'isArchived', val: true, op: '=' }]
  }
};

export enum TemplateFilterBy {
  Active = 'active',
  Archive = 'archived',
  All = 'all'
}

export const makeStates = () => {
  return {
    useFilterState: createGlobalState<TemplateFilterBy>(TemplateFilterBy.All),
    useSearchState: createGlobalState<string>('')
  };
};

type UseTemplatesGenericParams<T extends Identified, ApiDTO> = Pick<
  UseCRUDParams<T, ApiDTO>,
  'api' | 'initialFetch' | 'entityName'
> & {
  states: ReturnType<typeof makeStates>;
  fetchAll?: boolean;
  cacheKey: string;
  findCol?: string;
  sortCol?: string;
  sortDesc?: boolean;
  invalidateKeys?: ReactQueryKey[];
  afterMutation?: () => unknown;
};

export function useTemplatesGeneric<T extends Identified, ApiDTO = T>({
  api,
  findCol = 'name',
  sortCol = 'createdAt',
  sortDesc = true,
  cacheKey,
  entityName,
  initialFetch = false,
  fetchAll = false,
  states: { useFilterState, useSearchState },
  invalidateKeys: invalidateKeysRaw,
  afterMutation
}: UseTemplatesGenericParams<T, ApiDTO>) {
  const companyIdFromLocation = useAppSelector(selectWorkspaceIdFromLocation);
  const companyIdFromSelectedProject = useAppSelector(selectCompanyIdBySelectedProject);
  const companyId = companyIdFromLocation || companyIdFromSelectedProject;

  const [filterBy, setFilterBy] = useFilterState();
  const [searchBy, setSearchBy] = useSearchState();
  const QUERY_KEY = [cacheKey, companyId, filterBy, searchBy];
  const invalidateKeys = invalidateKeysRaw ?? ([cacheKey] as ReactQueryKey[]);
  const { findQuery, createMutation, updateMutation, deleteMutation } = useCRUD<T, ApiDTO>({
    initialFetch,
    api,
    entityName,
    queryKey: QUERY_KEY,
    invalidateKeys,
    afterMutation,
    getReadParams: ({ queryKey }) => {
      const [, companyId, filterBy, searchBy] = queryKey;

      return {
        fetchAll,
        companyId: +companyId,
        sortCol,
        sortDesc,
        filters: [
          ...(TEMPLATE_FILTERS[filterBy]?.filters as Filter[]),
          searchBy
            ? {
                col: findCol,
                op: 'similarTo',
                val: searchBy
              }
            : ({} as Filter)
        ]
      };
    }
  });

  return {
    fetch: {
      ...findQuery,
      filterBy,
      setFilterBy,
      searchBy,
      setSearchBy
    },
    create: createMutation,
    update: updateMutation,
    remove: deleteMutation
  };
}
