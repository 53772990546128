import React, { useState } from 'react';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import { makeStyles, Paper } from '@material-ui/core';

import { ChevronDownIcon } from '@kit/ui/icons/ChevronDown';
import TextField from '@material-ui/core/TextField';
import { AddButton, AddButtonContainer, autocompleteStyles, NewBadge } from './styled';

const NEW_OPTION_ATTRIBUTE_NAME = '__isNew__';

interface CreatableProps {
  createButtonText: (inputValue: string) => string;
  buildNewOption: (inputValue: string) => any;
}

const DEFAULT_CREATABLE_PROPS: CreatableProps = {
  createButtonText: (_inputValue: string) => '+ Add new',
  buildNewOption: (inputValue: string) => ({ id: 0, name: inputValue })
};

interface Props<Tz>
  extends Omit<AutocompleteProps<T, false, false, false>, 'renderInput' | 'freeSolo' | 'disableClearable' | 'multiple'>,
    CreatableProps {
  placeholder?: string;
  isMulti?: boolean;
  isClearable?: boolean;
  // onChange?: () => void;
}

const useStyles = makeStyles(autocompleteStyles({}));

export const CreatableSelect = <T,>({
  placeholder,
  isMulti,
  buildNewOption = DEFAULT_CREATABLE_PROPS.buildNewOption,
  createButtonText = DEFAULT_CREATABLE_PROPS.createButtonText,
  isClearable = true,
  ...props
}: Props<T>) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState('');
  const ref = React.useRef<any>();

  const handleChange = (e, value, reason, details) => {
    setInputValue('');
    props.onChange(e, value, reason, details);
  };

  return (
    <Autocomplete
      classes={classes}
      disableClearable={!isClearable}
      freeSolo={false}
      multiple={isMulti}
      autoComplete="off"
      popupIcon={<ChevronDownIcon />}
      PaperComponent={({ children, ...paperProps }) => (
        <Paper {...paperProps}>
          {children}
          {inputValue.length > 0 && (
            <AddButtonContainer>
              <AddButton
                onMouseDown={(e) =>
                  handleChange(e, { [NEW_OPTION_ATTRIBUTE_NAME]: true, ...buildNewOption(inputValue) })
                }
              >
                {createButtonText(inputValue)}
              </AddButton>
            </AddButtonContainer>
          )}
        </Paper>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          innerRef={ref}
          placeholder={placeholder}
          variant="outlined"
          onChange={(e) => setInputValue(e.target.value)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {props.value && props.value?.[NEW_OPTION_ATTRIBUTE_NAME] ? <NewBadge>New</NewBadge> : undefined}
                {params?.InputProps?.endAdornment}
              </>
            )
          }}
        />
      )}
      {...props}
      onChange={handleChange}
      onBlur={(e) => {
        if (!props.value && inputValue.length > 0 && inputValue.trim().length > 0) {
          handleChange(e, { [NEW_OPTION_ATTRIBUTE_NAME]: true, ...buildNewOption(inputValue) });
        }
      }}
    />
  );
};
