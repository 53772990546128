import moment from 'moment';

const formatTime = (date: moment.Moment) => {
  return date.format('h:mma').replace(':00', '');
};

export const formatDateTime = (date: moment.Moment, isAllDay: boolean) => {
  if (isAllDay) {
    return date.format('ddd D MMM');
  }

  return `${formatTime(date)}, ${date.format('ddd D MMM')}`;
};

export const formatOrderDates = (order: any) => {
  if (!order.startDate && !order.endDate) {
    return '';
  }

  if (order.startDate && order.endDate) {
    const start = moment(order.startDate);
    const end = moment(order.endDate);

    if (start.isSame(end, 'date')) {
      return `${formatTime(start)} - ${formatTime(end)} ${start.format('ddd D MMM')}`;
    } else {
      return `${formatDateTime(start, order.startDateAllDay)} - ${formatDateTime(end, order.endDateAllDay)}`;
    }
  }

  if (order.startDate) {
    return formatDateTime(moment(order.startDate, order.startDateAllDay));
  }

  return formatDateTime(moment(order.endDate, order.endDateAllDay));
};
