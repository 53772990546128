import get from '@services/api/base/get';
import post from '@services/api/base/post';
import put from '@services/api/base/put';
import deleteReq from '@services/api/base/delete';
import { Urls } from '@services/api/base/axios';
import { WorkspaceStageDTO, WorkspaceStageFromAPI } from '@types';

export default {
  fetchProjectStages: (companyId: number) =>
    get<WorkspaceStageFromAPI[]>(`${Urls.project}/project-stages`, { companyId }),

  createProjectStage: (companyId: number, dto: WorkspaceStageDTO) =>
    post(`${Urls.project}/project-stages`, dto, { companyId }),

  updateProjectStage: (companyId: number, id: number, dto: WorkspaceStageDTO) =>
    put(`${Urls.project}/project-stages/${id}`, dto, { companyId }),

  deleteProjectStage: (companyId: number, id: number) =>
    deleteReq(`${Urls.project}/project-stages/${id}`, {}, { companyId })
};
