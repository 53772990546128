import { useMutation, useQueryClient } from 'react-query';
import { useToast } from '@hooks';
import { errorHandler } from '@services/api/helpers';
import { ReactQueryKey } from '@enums';
import systemsAPI from '@services/api/systemsAPI';

export const useUpdateSystemsFleet = () => {
  const queryClient = useQueryClient();
  const { showSuccess } = useToast();

  return useMutation<void, Error, { systemIds: number[]; fleetId: number }>(
    async ({ systemIds, fleetId }) => {
      try {
        return (await systemsAPI.monitorSystems({ systems: systemIds, profile: fleetId })).data;
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: async (_, { systemIds }) => {
        showSuccess(`System${systemIds.length > 0 ? 's' : ''} Fleet updated successfully`);
        await queryClient.invalidateQueries([ReactQueryKey.System]);
        await queryClient.invalidateQueries([ReactQueryKey.SystemsSettings]); // because monitoring affects budget
      }
    }
  );
};
