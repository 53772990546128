import React, { useCallback } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { DateTimePicker } from '@kit/ui/DateTimePicker';
import { FormField } from '../FormField';
import { FormControl, FormInputPropsToOmit } from '../types';
import { useControllerWithValidation } from '../useControllerWithValidation';

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends Omit<React.ComponentProps<typeof DateTimePicker>, FormInputPropsToOmit | 'onClose'>,
    FormControl<TFieldValues, TName> {
  onClose?: (value: Date, isChanged: boolean) => void;
}

const isDatesEqual = (date1: Date | null | string, date2: Date | null | string) => {
  if (!date1 || !date2) {
    return date1 === date2;
  }

  return new Date(date1).getTime() === new Date(date2).getTime();
};

export const DateTimeField = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  label,
  description,
  name,
  control,
  onClose,
  clearOnUnmount,
  ...inputProps
}: Props<TFieldValues, TName>) => {
  const {
    field: { onChange, ...controlProps },
    fieldState: { error }
  } = useControllerWithValidation(name, control, label, clearOnUnmount);

  const handleChange = useCallback(
    (value: Date) => {
      onChange(value);
      onClose?.(value, !isDatesEqual(value, controlProps.value));
    },
    [onChange, onClose, controlProps.value]
  );

  return (
    <FormField name={name} label={label} error={error?.message} description={description}>
      <DateTimePicker
        {...controlProps}
        {...inputProps}
        data-test-id={`field-${controlProps.name}`}
        onClose={handleChange}
      />
    </FormField>
  );
};
