import React, { useEffect, useMemo, useState } from 'react';
import { castArray, isEqual } from 'lodash';

export type Pagination = {
  page: number;
  perPage: number;
  setPage: (page: number) => void;
  setPerPage: (perPage: number) => void;
};

export type Group<T> = {
  value: T;
  label: string;
  color?: string;
};

export type GroupPagination<TGroupValue> = Pagination & {
  group: Group<TGroupValue>;
};

type PaginationOptions = {
  perPage: number
};

export const NO_GROUP = 'NO_GROUP';

// FIXME: how to get rid of 'any' here?
const TablePaginationContext = React.createContext<GroupPagination<any>[] | Pagination>(
  undefined as unknown as GroupPagination<any>[]
);
TablePaginationContext.displayName = 'TablePaginationContext';

export const TablePaginationProvider = TablePaginationContext.Provider;

/**
 * Creates hook which provides grouped pagination, i.e. list of pagination objects each with corresponding group value
 * (groups defined externally and not interpreted by pagination).
 */
export const createTablePagination = <TGroupValue>() =>
  (groups: Group<TGroupValue>[], options?: PaginationOptions) => {
    const defaults = (groups: Group<TGroupValue>[], value: number) =>
      groups.reduce((acc, _group, index) => ({ ...acc, [index]: value }), {});

    const { perPage: optionsPerPage = 10 } = options || {};
    const [groupsPage, setGroupsPage] =
      useState<{ [groupIndex: number]: number }>(defaults(groups, 1));
    const [groupsPerPage, setGroupsPerPage] =
      useState<{ [groupIndex: number]: number }>(defaults(groups, optionsPerPage));

    useEffect(() => {
      setGroupsPage(defaults(groups, 1));
      setGroupsPerPage(defaults(groups, optionsPerPage));
    }, [groups, optionsPerPage]);

    return useMemo(() => groups.map((group, index) => ({
        group,
        page: groupsPage[index] || 1,
        perPage: groupsPerPage[index] || optionsPerPage,
        setPage: (page: number) => setGroupsPage({ ...groupsPage, [index]: page }),
        setPerPage: (perPage: number) => setGroupsPerPage({ ...groupsPerPage, [index]: perPage })
      })),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [groupsPage, groupsPerPage]);
  };

export const createUngroupedTablePagination = () =>
  () => {
    const singleGroup = useMemo(() => [{ value: NO_GROUP, label: '' }], []);

    return createTablePagination<string>()(singleGroup)[0] as Pagination;
  };

/**
 * Hook providing grouped pagination created somewhere above in nodes tree.
 */
export const useTablePagination = <TGroupValue>() => {
  return castArray(React.useContext(TablePaginationContext)) as GroupPagination<TGroupValue>[];
};

/**
 * Hook providing pagination object for single group by it's value (of any type, searched with `isEqual`).
 */
export const useTableGroupPagination = <TGroupValue>(group: TGroupValue) => {
  const groupsPagination = castArray(React.useContext(TablePaginationContext)) as GroupPagination<TGroupValue>[];

  return groupsPagination.find((candidate) => isEqual(candidate?.group.value, group))
    || {} as GroupPagination<TGroupValue>;
};
