import { ReactQueryKey } from '@enums';
import { errorHandler } from '@services/api/helpers';
import tasksApi from '@services/api/tasksApi';
import { useMutation, useQueryClient } from 'react-query';
import { useToast } from '@hooks/useToast';

type CreateWorkOrderPayload = {
  projectId: number;
  templateTaskId: number;
  address?: string;
  systemId?: number;
};

export const useCreateWorkOrder = () => {
  const queryClient = useQueryClient();
  const { showSuccess } = useToast();

  return useMutation<number, Error, CreateWorkOrderPayload>(
    async (req) => {
      try {
        const {
          data: { id }
        } = await tasksApi.createTask({
          ...req,
          fillFromTemplate: true
        });

        return id;
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: () => {
        showSuccess('Work Order created successfully');
        queryClient.invalidateQueries([ReactQueryKey.Tasks]);
        queryClient.invalidateQueries([ReactQueryKey.ProjectsListInitialGroupData]);
        queryClient.invalidateQueries([ReactQueryKey.ProjectsByIds]);
      }
    }
  );
};
