import React from 'react';
import { Field } from 'formik';
import styled from 'styled-components';

import SubTask from './subTask';

const Wrapper = styled.div``;

type SubTaskInputType = {
  name?: string;
  onChange?: (values: any) => void;
  isDisabled?: boolean;
  isCheckboxDisabled?: boolean;
};

const SubTaskInput: React.FC<SubTaskInputType> = ({ name, onChange, isDisabled = false, isCheckboxDisabled = false }) => {
  return (
    <Wrapper data-testid="subTasks">
      <Field name={name}>
        {({ field, form: { setFieldValue, values, errors } }) => {
          return (
            <SubTask
              isCheckboxDisabled={isCheckboxDisabled}
              isDisabled={isDisabled}
              errors={errors}
              subtasks={values[field.name]}
              addSubTask={(task) => {
                const newValue = [
                  ...values[field.name],
                  {
                    ...task,
                    position:
                      values[field.name].reduce(
                        (prev, current) => (prev.position > current.position ? prev.position : current.position),
                        0
                      ) + 1
                  }
                ];
                setFieldValue(field.name, newValue);
                onChange?.(newValue);
              }}
              removeSubTask={(id) => {
                const newValue = [...values[field.name].filter((task) => task.id !== id)];
                setFieldValue(field.name, newValue);

                onChange?.(newValue);
              }}
              markAsComplete={(id) => {
                const newSubtasks = values[field.name].map((sub) => {
                  if (sub.id === id) {
                    return { ...sub, isCompleted: !sub.isCompleted };
                  } else {
                    return { ...sub };
                  }
                });
                setFieldValue(field.name, newSubtasks);
                onChange?.(newSubtasks);
              }}
              renameSubtask={(id, newVal) => {
                const newSubtasks = values[field.name].map((sub) => {
                  if (sub.id === id) {
                    return { ...sub, detail: newVal };
                  } else {
                    return { ...sub };
                  }
                });
                setFieldValue(field.name, newSubtasks);
                onChange?.(newSubtasks);
              }}
              positionChange={(dragIndex, dropIndex) => {
                const tasks = [...(values[field.name] ?? [])];
                // change subtask position

                const dragPosition = tasks[dragIndex].position;

                tasks[dragIndex] = {
                  ...tasks[dragIndex],
                  position: tasks[dropIndex].position
                };

                tasks[dropIndex] = {
                  ...tasks[dropIndex],
                  position: dragPosition
                };

                // change array position
                [tasks[dragIndex], tasks[dropIndex]] = [tasks[dropIndex], tasks[dragIndex]];

                setFieldValue(field.name, tasks);
                onChange?.(tasks);
              }}
            />
          );
        }}
      </Field>
    </Wrapper>
  );
};

export default SubTaskInput;
