import React, { useCallback, useEffect, useMemo } from 'react';
import { RecordDocListItem, useRecordDocList } from '@hooks/documents/useRecordDocList';
import { RecordType } from '@types';
import { DrawerEntity, useDrawersContext } from '@contexts/DrawersContext';
import { Header } from '../components/Header';
import { useFilters } from '../useFilters';
import { ActiveFileDropZone, Container, Scrollable } from './styled';
import { ListHeader } from '../components/ListHeader';
import { useSelection } from '../GroupedDocList/useSelection';
import { DocListItem } from '../components/DocListItem';
import { useDropFiles } from '../useDropFiles';
import { useDocsAccess } from '../useDocsAccess';
import { EmptyDropZone } from '../components/EmptyDropZone';

interface Props {
  recordId: number;
  recordType: RecordType;
}

const EMPTY_ARRAY: RecordDocListItem[] = [];

export const PlainDocList = ({ recordId, recordType }: Props) => {
  const { filters, updateFilters } = useFilters();
  const { canCreateFile } = useDocsAccess();

  const { openDrawer } = useDrawersContext();

  const selection = useSelection();
  const {
    bulkInfo,
    resetDataForGroups,
    clearSelection,
    isAllSelected,
    toggleAllSelected,
    checkIsItemSelected,
    toggleItem
  } = selection;

  const { data: docs = EMPTY_ARRAY, isLoading: isDocsLoading } = useRecordDocList(recordId, filters.isArchivedShown);

  const filteredDocs = useMemo(() => {
    if (!filters.query) {
      return docs;
    }

    return docs.filter((doc) => {
      return doc.name.toLowerCase().includes(filters.query.toLowerCase());
    });
  }, [docs, filters.query]);

  useEffect(() => {
    if (docs) {
      resetDataForGroups([
        {
          stage: {
            id: -1,
            name: 'Other'
          },
          docs
        }
      ]);
    }
  }, [docs, resetDataForGroups]);

  const handleToggleItem = useCallback(
    (doc, meta) => {
      toggleItem(-1, doc, meta);
    },
    [toggleItem]
  );

  const handleRowClick = useCallback(
    (doc: RecordDocListItem) => {
      openDrawer(
        DrawerEntity.DOC,
        doc.id,
        docs.map((doc) => doc.id)
      );
    },
    [openDrawer, docs]
  );

  const { getRootProps, getInputProps, isDragActive, openFileDialog } = useDropFiles({
    recordId
  });

  return (
    <Container {...getRootProps()}>
      <input {...getInputProps()} />
      {canCreateFile && isDragActive && <ActiveFileDropZone>Drop file here</ActiveFileDropZone>}
      <Header
        recordId={recordId}
        {...bulkInfo}
        clearSelection={clearSelection}
        filters={filters}
        onFiltersChange={updateFilters}
      />

      <Scrollable>
        <ListHeader isAllSelected={isAllSelected} onToggleAllSelected={toggleAllSelected} />

        {!isDocsLoading && docs.length === 0 && <EmptyDropZone recordId={recordId} openFileDialog={openFileDialog} />}
        {filteredDocs.map((doc) => (
          <DocListItem
            key={doc.id}
            isSelected={checkIsItemSelected(-1, doc)}
            toggleItem={handleToggleItem}
            doc={doc}
            recordType={recordType}
            onClick={handleRowClick}
          />
        ))}
      </Scrollable>
    </Container>
  );
};
