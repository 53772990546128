import { useMutation, useQueryClient } from 'react-query';
import { useToast } from '@hooks';
import { ReactQueryKey } from '@enums';
import systemAlertsAPI from '@services/api/systemAlertsAPI';
import { AlertStatus } from '@generated/types/graphql';
import { apiErrorHandler } from '@utils/api';

export const useDismissSystemAlert = () => {
  const queryClient = useQueryClient();
  const { showSuccess, showError } = useToast();

  return useMutation<void, Error, { id: number; systemId: number }>(
    async ({ id }) => {
      try {
        return (await systemAlertsAPI.updateAlert(id, { status: AlertStatus.Dismissed })).data;
      } catch (error) {
        throw apiErrorHandler('Error dismissing Alert', error);
      }
    },
    {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: async (_, { systemId }) => {
        showSuccess(`Alert dismissed successfully`);
        queryClient.invalidateQueries([ReactQueryKey.System, 'useSystemsGroups_pages']);
        queryClient.invalidateQueries([ReactQueryKey.System, 'useSystems']);
        queryClient.invalidateQueries([ReactQueryKey.System, systemId]);
      }
    }
  );
};
