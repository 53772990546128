import styled from 'styled-components';
import { RichEditor as RichEditorComponent } from '@common/RichEditor';
import { colors } from '@styles';

export const RichEditor = styled(RichEditorComponent)`
  border-color: #dfdfe8;

  .quill {
    display: flex;
    flex-direction: column-reverse;
  }
  .ql-container.ql-snow,
  .ql-toolbar.ql-snow {
    border: 0;
  }

  .ql-toolbar.ql-snow {
    background: transparent;
  }

  &:hover {
    border-color: ${colors.green};
  }

  .ql-editor {
    min-height: ${({ isOneLine }) => (isOneLine ? '40px' : '120px')};
  }

  .ql-editor.ql-blank::before {
    font-style: normal;
  }
`;
