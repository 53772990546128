import React, { useRef, useState } from 'react';
import { Paperclip, Camera as CameraIcon, Upload } from 'react-feather';
import { CircularProgress } from '@material-ui/core';
import 'react-html5-camera-photo/build/css/index.css';

import { isPdfFile } from '@utils/files';
import { md } from '@utils';
import { useOffline } from '@hooks';

import { colors } from '@styles';
import { confirmationModal } from '..';

import { CustomCamera } from './CustomCamera';
import {
  Body,
  Actions,
  Remove,
  Buttons,
  Name,
  ErrorMsg,
  File,
  FileChoseButton,
  Previews,
  Size,
  Spinner,
  Thumbnail
} from './styled';

interface IFileChoser {
  multiple?: boolean;
  values: any[];
  onFormFilesChange: (files: File[], operation: string) => Promise<any> | undefined;
  config: { fileSize: number; fileMaxCount: number };
  isRequired?: boolean;
  onOpenGallery?: (formId: number, activeImageId: number, activeAttachmentImages: {}) => void;
  formId: number;
  disabled?: boolean;
}

export const FileChoser: React.FC<IFileChoser> = (props) => {
  const {
    multiple = false,
    values = [],
    onFormFilesChange,
    isRequired = false,
    config,
    onOpenGallery,
    formId,
    disabled
  } = props;

  const fileChoserRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [cameraOpen, setCameraOpen] = useState(false);
  const isOffline = useOffline();

  const hideButtons = md.is('iOS') && isOffline;

  const onUploadClick = () => {
    if (!fileChoserRef.current) {
      return;
    }
    fileChoserRef.current.click();
  };

  const onFileChange = async (event) => {
    setErrorMessage('');
    if (!fileChoserRef) {
      return;
    }
    const valuesArray = values.map((file) => file.name);
    const chosenFiles = fileChoserRef.current.files;
    const files = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const file of chosenFiles) {
      if (file.size / 1000000 >= config.fileSize) {
        setErrorMessage(
          `Sorry, '${file.name} ${(file.size / 1000000).toFixed(2)} MB' exceeds maximum file size of ${
            config.fileSize
          } MB.`
        );
        // eslint-disable-next-line no-param-reassign
        event.target.value = '';

        return;
      }
      if (valuesArray.includes(file.name)) {
        setErrorMessage(`Sorry, '${file.name}' is already uploaded.`);

        return;
      }
      files.push(file);
    }
    if (values.length + files.length > config.fileMaxCount) {
      // TODO: check it after maxCount will be fixed
      // setCameraOpen(false);
      setErrorMessage(`Sorry, you can only upload total of ${config.fileMaxCount} files.`);
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';

      return;
    }
    // eslint-disable-next-line no-param-reassign
    event.target.value = '';

    onFormFilesChange(files, 'add');
  };

  const onFileRemove = async (file) => {
    const confirmModal = confirmationModal.create();
    const result = await confirmModal.show('Are you sure you want to remove this file?', 'NO', 'YES');

    if (result) {
      onFormFilesChange(file, 'remove');
    }
  };

  const isInvalid = isRequired && values.length === 0;

  if (cameraOpen) {
    return (
      <CustomCamera
        attachFiles={(files: File[]) => onFormFilesChange(files, 'add')}
        onClose={() => setCameraOpen(false)}
      />
    );
  }

  return (
    <>
      {hideButtons ? null : (
        <Body>
          <Buttons>
            <FileChoseButton onClick={onUploadClick}>
              <Upload size={16} color={colors.green} />
              <span>Browse</span>
              <input
                multiple={multiple}
                type="file"
                ref={fileChoserRef}
                style={{ display: 'none' }}
                onChange={onFileChange}
                accept="image/*, .pdf,video/mp4,video/x-m4v,video/*, .heic"
                disabled={disabled}
              />
            </FileChoseButton>
            <FileChoseButton onClick={() => !disabled && setCameraOpen(true)}>
              <CameraIcon size={16} color={colors.green} />
              <span>Camera</span>
            </FileChoseButton>
          </Buttons>
        </Body>
      )}
      {isInvalid ? <ErrorMsg> Please, Upload file! </ErrorMsg> : null}
      {errorMessage ? <ErrorMsg> {errorMessage} </ErrorMsg> : null}
      <Previews>
        {values.map((file) =>
          file.isUploading ? (
            <Spinner key={file.id} style={file.previewUrl ? { backgroundImage: `url(${file.previewUrl})` } : undefined}>
              <CircularProgress size={20} style={{ color: colors.green }} />
            </Spinner>
          ) : (
            file?.metaData?.thumbnailUrl && (
              <Thumbnail
                key={file.id}
                url={file.metaData.thumbnailUrl}
                onClick={() => onOpenGallery?.(formId, file.id, values)}
              />
            )
          )
        )}
      </Previews>
      {false &&
        values.map((file) => (
          <File key={file.name}>
            <Paperclip size={20} color={colors.green} />

            {file?.metaData?.thumbnailUrl || isPdfFile(file) ? (
              <Name onClick={() => onOpenGallery?.(formId, file.id, values)}>{file.name}</Name>
            ) : (
              <Name href={file.downloadUrl} download>
                {file.name}
              </Name>
            )}

            <Size>{((file.size ?? file.metaData?.size ?? 0) / 1000000).toFixed(2)} MB</Size>
            <Actions>
              {file.isUploading ? (
                <CircularProgress size={20} style={{ color: colors.green }} />
              ) : (
                !disabled && <Remove size={20} onClick={() => onFileRemove(file)} />
              )}
            </Actions>
            {file?.error && <ErrorMsg> {file.error} </ErrorMsg>}
          </File>
        ))}
    </>
  );
};
