import { DimensionType, StandardPropertyId, UserSubdimentionType, WidgetSettings } from '@features/Analytics/types';
import { MainUser, ProjectStage } from '@generated/types/graphql';
import { USER_DIMENSION_TYPES } from '@features/Analytics/constants';
import { DateTime } from 'luxon';
import { BarData } from '../../Widget/types';
import { remapAndAggregateByTeamPoints } from '../helpers';

export const postProcessingData = ({
  data,
  settings,
  stagesMap,
  usersMap,
  userToTeamsMap,
  timezone
}: {
  data: BarData;
  settings: WidgetSettings;
  stagesMap: Record<number, ProjectStage>;
  usersMap: Record<number, MainUser>;
  userToTeamsMap: Record<number, string[]>;
  timezone: string;
}) => {
  if (settings.dimensionId === DimensionType.TIME) {
    return {
      ...data,
      points: data.points.map((point) => {
        if (point.isEmpty) {
          return point;
        }

        return {
          x: DateTime.fromISO(point.x as string, { zone: timezone }).toFormat('MM/dd'),
          y: point.y,
          originalX: point.x
        };
      })
    };
  }
  if (settings.dimensionId === DimensionType.DROPDOWN_PROPERTY) {
    if (settings.subdimensionId === `property_${StandardPropertyId.STAGE}`) {
      return {
        ...data,
        points: data.points.map((point) => {
          if (point.isEmpty) {
            return point;
          }

          const stage = stagesMap[parseInt(point.x, 10)];

          return {
            x: stage ? stage.name : point.x,
            y: point.y,
            originalX: point.x
          };
        })
      };
    }
  }

  if (USER_DIMENSION_TYPES.includes(settings.dimensionId)) {
    if (settings.subdimensionId === UserSubdimentionType.USER) {
      return {
        ...data,
        points: data.points.map((point) => {
          if (point.isEmpty) {
            return point;
          }

          const user = usersMap[parseInt(point.x, 10)];

          return {
            x: user ? `${user.firstName} ${user.lastName}` : point.x,
            y: point.y,
            originalX: point.x
          };
        })
      };
    }

    if (settings.subdimensionId === UserSubdimentionType.TEAM) {
      return {
        ...data,
        points: remapAndAggregateByTeamPoints({
          points: data.points,
          userToTeamsMap,
          aggregationFunction: settings.aggregateFunctionId
        })
      };
    }
  }

  return data;
};
