import moment from 'moment';
import { config } from '../config';

interface TrackEvent {
  event: string;
  description: string;
  customFields?: Record<string, string | boolean | number>;
}

interface TrackingUserInfo {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  accountType: string;
}

interface TrackingCompanyInfo {
  id: number;
  name: string;
  userAddedAt: string;
  createdAt: string;
}

class EventTracking {
  private static instance: EventTracking;

  private userId: number | null = null;

  private companyId: number | null = null;

  private static isEnabled() {
    if (typeof window === 'undefined') {
      return false;
    }

    return config.STAND === 'production';
  }

  private constructor() {
    this.init();
  }

  public static getInstance(): EventTracking {
    if (!EventTracking.instance) {
      EventTracking.instance = new EventTracking();
    }

    return EventTracking.instance;
  }

  // eslint-disable-next-line
  private init() {
    if (!EventTracking.isEnabled()) {
      return;
    }

    window?.ChurnZero?.push(['setAppKey', config.CHURNZERO_APP_KEY]);
  }

  setUser(company: TrackingCompanyInfo, user: TrackingUserInfo) {
    if (this.userId === user.id && this.companyId === company.id) {
      return;
    }

    this.userId = user.id;
    this.companyId = company.id;

    if (!EventTracking.isEnabled()) {
      return;
    }

    const companyCreatedAt = moment.utc(company.createdAt).utcOffset('-08:00').toISOString();
    const userAddedAt = moment.utc(company.userAddedAt).utcOffset('-08:00').toISOString();

    window?.ChurnZero?.push(['setContact', company.id, user.email]);
    window?.ChurnZero?.push([
      'setAttribute',
      'contact',
      { FirstName: user.firstName, LastName: user.lastName, Role: user.accountType, 'User Added On': userAddedAt }
    ]);
    window?.ChurnZero?.push(['setAttribute', 'account', { Name: company.name, StartDate: companyCreatedAt }]);
  }

  // eslint-disable-next-line
  track(event: TrackEvent) {
    if (!EventTracking.isEnabled()) {
      // eslint-disable-next-line no-console
      console.info('TRACK_EVENT', event);

      return;
    }

    window?.ChurnZero?.push(['trackEvent', event.event, event.description, 1, event.customFields]);
  }

  trackOnceWithinMinutes(event: TrackEvent, minutes: number, identifier?: string) {
    if (!EventTracking.isEnabled()) {
      return;
    }

    const key = `trackOnceWithinMinutes:${identifier ?? event.event}`;
    const currentTime = new Date().getTime();
    const storedTime = localStorage.getItem(key);

    if (storedTime && currentTime - parseInt(storedTime, 10) < minutes * 60 * 1000) {
      return;
    }

    localStorage.setItem(key, currentTime.toString());
    this.track(event);
  }
}

export const eventTracking = EventTracking.getInstance();
