import React from 'react';
import { ReminderType } from '@enums';
import { Icon, Clock, Phone, Mail, User, Video, AlertTriangle } from 'react-feather';
import { capitalize } from 'lodash';

export const REMINDER_TYPE_TO_ICON: { [key in ReminderType]: Icon } = {
  [ReminderType.CALL]: Phone,
  [ReminderType.EMAIL]: Mail,
  [ReminderType.IN_PERSON]: User,
  [ReminderType.VIRTUAL]: Video,
  [ReminderType.DEADLINE]: AlertTriangle,
  [ReminderType.OTHER]: Clock
};

export const REMINDER_OPTIONS = Object.entries(REMINDER_TYPE_TO_ICON).map(([name, Ic]) => ({
  id: name,
  name: capitalize(name.replace('_', ' ')),
  icon: {
    component: <Ic size={16} color="#9C9CAA" />
  }
}));
