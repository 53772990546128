import styled, { css } from 'styled-components';
import { UiSizes, UiSizesValues } from '@enums';
import { defaultTheme } from '@themes';
import { Wrapper as CopyButton } from '@common/ui/CopyButton/styled';

export const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
`;

export const avatar = (props: { size: UiSizes; color: string; textColor?: string }) => {
  const { size, color, textColor } = props;

  return {
    // .MuiAvatar-root Styles applied to the root element.
    root: {
      width: UiSizesValues[size],
      height: UiSizesValues[size],
      fontWeight: 500,
      lineHeight: 1.25,
      fontSize: size === UiSizes.XXTiny ? `8px` : `10px`,
      ...(textColor
        ? {
            color: textColor
          }
        : {})
    },

    // .MuiAvatar-colorDefault Styles applied to the root element if not src or srcSet.
    colorDefault: {
      backgroundColor: color
    },

    // .MuiAvatar-circle Styles applied to the root element if variant="circle".
    circle: {},

    // .MuiAvatar-circular Styles applied to the root element if variant="circular".
    circular: {},

    // .MuiAvatar-rounded Styles applied to the root element if variant="rounded".
    rounded: {},

    // .MuiAvatar-square Styles applied to the root element if variant="square".
    square: {},

    // .MuiAvatar-img Styles applied to the img element if either src or srcSet is defined.
    img: {},

    // .MuiAvatar-fallback Styles applied to the fallback icon
    fallback: {}
  };
};

export const Workspace = styled.div<{ colors: { bg: string; text: string } }>`
  position: absolute;
  right: -1px;
  bottom: -1px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  background-color: ${({ colors }) => (colors ? colors.bg : '#fff')};
  border-radius: 4px;
  border: 1px solid #fff;
  font-weight: 600;
  font-size: 6.5px;
  line-height: 14px;
  color: ${({ colors }) => (colors ? colors.text : '#fff')};
  text-transform: uppercase;
`;

export const avatarGroup = (props: { size: UiSizes }) => {
  const { size } = props;

  return {
    // .MuiAvatarGroup-root Styles applied to the root element.
    root: {
      '& > div': {
        marginRight: '2px'
      }
    },

    // .MuiAvatarGroup-avatar Styles applied to the avatar elements.
    avatar: {
      border: 'none',
      '&.MuiAvatar-circular': {
        // for more counter
        backgroundColor: 'transparent',
        color: defaultTheme.colors.gray,
        fontSize: '14px',
        border: 'none',
        width: UiSizesValues[size],
        height: UiSizesValues[size],
        marginLeft: 0
      }
    }
  };
};

export const InfoPopup = styled.div`
  width: 209px;
  padding: 8px 8px 4px 8px;
  line-break: anywhere;

  ${({ theme }) => theme.shadows.main};
  ${({ theme }) => theme.borders.main};
`;

export const InfoListItemValue = styled.div`
  display: flex;
  align-self: center;
`;

export const InfoIconWrapper = styled.div``;

const baseItemStyles = css`
  display: flex;
  align-items: self-start;
  margin: 0;
  font: ${({ theme }) => theme.typefaces.textLight};
  color: ${({ theme }) => theme.colors.gray};

  & svg {
    position: relative;
    top: 3px;
    width: 16px;
    height: 16px;
    stroke-width: 2.5px;
    margin-right: 4px;
    margin-left: initial;
    flex: none;
  }

  ${CopyButton} {
    margin-left: 4px;
  }
`;

export const InfoListItem = styled.div`
  ${baseItemStyles}
`;

export const InfoListLink = styled.a`
  ${baseItemStyles};

  align-self: center;
  text-decoration: none;

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.gray2};
    text-decoration: underline;
  }
`;

export const InfoNameSpan = styled.span`
  font: ${({ theme }) => theme.typefaces.text};
`;

export const InfoAvatarWrapper = styled.div<{ nameOnly?: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: ${({ nameOnly }) => (nameOnly ? '4px' : '8px')};

  ${InfoNameSpan} {
    margin-left: 4px;
  }
`;
