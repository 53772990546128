import { TaskStatus, TaskStatusEntity } from '@generated/types/graphql';
import { useWorkOrderStatuses } from '@hooks/workOrders/useWorkOrderStatuses';
import { useMemo } from 'react';

export const useWorkOrderStatusesMap = () => {
  const { data: statuses = [], isLoading } = useWorkOrderStatuses();

  const workOrderStatusesMap = useMemo(
    () =>
      statuses.reduce(
        (acc, status) => {
          acc[status.id] = status;

          return acc;
        },
        {} as Record<TaskStatus, TaskStatusEntity>
      ),
    [statuses]
  );

  return {
    workOrderStatusesMap,
    isWorkOrderStatusLoading: isLoading
  };
};
