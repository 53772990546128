import React from 'react';

export const IncomingEmailIcon = ({
  size = '16px',
  color = 'currentColor'
}: {
  size?: string | number;
  color?: string;
}) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 4.50006C1 3.94778 1.44772 3.50006 2 3.50006H22C22.5523 3.50006 23 3.94778 23 4.50006V12.0001C23 12.5523 22.5523 13.0001 22 13.0001C21.4477 13.0001 21 12.5523 21 12.0001V5.50006H3V18.5001H12C12.5523 18.5001 13 18.9478 13 19.5001C13 20.0523 12.5523 20.5001 12 20.5001H2C1.44772 20.5001 1 20.0523 1 19.5001V4.50006ZM14 17.0001C14 16.4478 14.4477 16.0001 15 16.0001H22C22.5523 16.0001 23 16.4478 23 17.0001C23 17.5523 22.5523 18.0001 22 18.0001H15C14.4477 18.0001 14 17.5523 14 17.0001Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.20006 3.9C1.53143 3.45817 2.15823 3.36863 2.60006 3.7L12.0001 10.75L21.4001 3.7C21.8419 3.36863 22.4687 3.45817 22.8001 3.9C23.1314 4.34183 23.0419 4.96863 22.6001 5.3L12.6001 12.8C12.2445 13.0667 11.7556 13.0667 11.4001 12.8L1.40006 5.3C0.958231 4.96863 0.868688 4.34183 1.20006 3.9ZM18.2072 13.7929C18.5977 14.1834 18.5977 14.8166 18.2072 15.2071L16.4143 17L18.2072 18.7929C18.5977 19.1834 18.5977 19.8166 18.2072 20.2071C17.8166 20.5976 17.1835 20.5976 16.793 20.2071L14.293 17.7071C13.9024 17.3166 13.9024 16.6834 14.293 16.2929L16.793 13.7929C17.1835 13.4024 17.8166 13.4024 18.2072 13.7929Z"
      fill={color}
    />
  </svg>
);
