import { ReactQueryKey } from '@enums';
import { errorHandler } from '@services/api/helpers';
import { actionTemplateApi, ActionTemplatePayload } from '@services/api/actionTemplateApi';
import { useMutation, useQueryClient } from 'react-query';
import { useToast } from '@hooks/useToast';

export const useCreateActionTemplate = () => {
  const queryClient = useQueryClient();
  const { showSuccess } = useToast();

  return useMutation<number, Error, ActionTemplatePayload>(
    async (req) => {
      try {
        const {
          data: { id }
        } = await actionTemplateApi.create(req);

        return id;
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: () => {
        showSuccess('Action template created successfully');
        queryClient.invalidateQueries(ReactQueryKey.ActionTemplateList);
      }
    }
  );
};
