import { useEffect } from 'react';
import { createGlobalState, useMeasure } from 'react-use';

export const makeDimensionHook = () => {
  const useDimensionState = createGlobalState<number>();

  return (): [number, any] => {
    const [value, setValue] = useDimensionState();
    const [headerRef, { height }] = useMeasure<HTMLElement>();

    useEffect(() => {
      if (height !== 0 && height !== value) {
        setValue(height);
      }
    }, [height, setValue]);

    return [value, headerRef];
  };
};

export const useUiDatabankContentHeight = makeDimensionHook();
export const useUiHeaderHeight = makeDimensionHook();

const useGlobalRecordsFilter = createGlobalState<boolean>(false);

export const useUiRecordsFilter = () => {
  const [isOpen, setIsOpen] = useGlobalRecordsFilter();

  return {
    isOpen,
    setIsOpen
  };
};
