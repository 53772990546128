import { selectWorkspaceId } from '@state/selectors';
import { ActionReturn } from '@state/reducers/types';

import {
  updateUsersCacheAction,
  fetchCompanyUsersStart,
  fetchCompanyUsersSuccess,
  fetchCompanyUsersFailed
} from '../../state/actions/userData/userDataAction';
import { getMultipleUserServiceURL, getUsersURL, getProjectMembersUsersURL } from '../service.config';
import { getConfig } from '../service.utils';
import { userDataAdapterFromService } from '../../adapters/UserDataAdapters/userDataAdapter';

interface Pager {
  page: number;
  limit: number;
  skip: number;
  name?: string;
}

export const fetchMultipleUser = (userIds: number[], companyId: number, dispatch = null) =>
  new Promise(function (resolve, reject) {
    if (userIds.length <= 0) {
      resolve([]);

      return;
    }
    const URL = getMultipleUserServiceURL(userIds, companyId);
    fetch(URL, getConfig('', 'GET'))
      .then((response) => response.json())
      .then((result) => {
        const datas = {};
        result.results.forEach((user) => {
          const userData = userDataAdapterFromService(user);
          datas[user.id] = { ...userData };
        });
        dispatch?.(updateUsersCacheAction(datas));
        resolve(result.results);
      })
      .catch((error) => reject(error));
  });

// Returns promise
export const fetchMultipleUserDataService = (userIds: number[], companyId: number) => (dispatch) =>
  fetchMultipleUser(userIds, companyId, dispatch);

export const fetchCompanyUsers =
  (pager: Pager, membersOnly, projectId): ActionReturn =>
  (dispatch, getState) => {
    dispatch(fetchCompanyUsersStart({ pager }));

    const companyId = selectWorkspaceId(getState());
    const { name } = pager;
    const URL = membersOnly ? getProjectMembersUsersURL(pager, projectId) : getUsersURL(pager, companyId);

    fetch(URL, getConfig('', 'GET'))
      .then((response) => response.json())
      .then((result) => {
        if (membersOnly) {
          const data = result
            ? result
                .filter((item) =>
                  `${item.member.firstName} ${item.member.lastName}`.toLowerCase().includes(name.toLowerCase())
                )
                .map((item) => {
                  const user = item.member;

                  return {
                    id: item.id,
                    name: `${user.firstName} ${user.lastName}`,
                    avatarUrl: user.avatarUrl,
                    email: user.email,
                    member: user,
                    memberType: item.memberType
                  };
                })
            : [];

          const total = data.length;
          dispatch(fetchCompanyUsersSuccess(data, total));
        } else {
          const data = result
            ? result.results.map((user) => {
                return {
                  id: user.id,
                  name: `${user.firstName} ${user.lastName}`,
                  avatarUrl: user.avatarUrl,
                  email: user.email
                };
              })
            : [];
          const total = result ? result.total : 0;
          dispatch(fetchCompanyUsersSuccess(data, total));
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        dispatch(fetchCompanyUsersFailed(error));
      });
  };
