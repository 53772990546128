import React from 'react';
import { Input } from '@kit/ui/Input';
import { PredefinedWidgetFilterFieldId } from '@features/Analytics/types';
import { FilterFieldType } from '../types';
import { PropertyFilterValue } from './PropertyFilterValue';
import { WorkflowFilterValue } from './WorkflowFilterValue';
import { FilterValueProps } from './types';
import { WorkOrderFilterValue } from './WorkOrderFilterValue';

export const FilterValue = ({ module, filter, onChange }: FilterValueProps) => {
  const { field, operator } = filter;

  if (!field || !operator) {
    return <Input disabled />;
  }

  const { type } = field;

  if (type === FilterFieldType.WORKFLOW) {
    return <WorkflowFilterValue module={module} filter={filter} onChange={onChange} />;
  }

  // type was introduced later, so we need to check for predefined fields, which were created before
  if (
    type === FilterFieldType.WORK_ORDER_ATTRIBUTE ||
    filter.id === PredefinedWidgetFilterFieldId.WORK_ORDER_TEMPLATE ||
    filter.id === PredefinedWidgetFilterFieldId.WORK_ORDER_TYPE
  ) {
    return <WorkOrderFilterValue module={module} filter={filter} onChange={onChange} />;
  }

  if (type === FilterFieldType.WORK_ORDER_WITH_TITLE) {
    return <Input value={(filter.value as string) ?? ''} onChange={(e) => onChange(e.target.value)} />;
  }

  return <PropertyFilterValue module={module} filter={filter} onChange={onChange} />;
};
