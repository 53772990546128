import get from '@services/api/base/get';
import post from '@services/api/base/post';
import put from '@services/api/base/put';
import deleteReq from '@services/api/base/delete';
import { Urls } from '@services/api/base/axios';
import { generateParams } from '@services/api/helpers';
import {
  BlueprintDTO,
  PaginationResult,
  CreateBlueprintProjectStageDTO,
  CreateBlueprintTaskDTO
} from '@types';
import { Search } from '@components/Project/ProjectView/types';

export default {
  findAll: (companyId: number, search: Partial<Search>) => get<PaginationResult<BlueprintDTO>>(
    `${Urls.project}/blueprints`,
    { ...generateParams({ search }), companyId }
  ),

  create: (companyId: number, dto: { name: string }) => post<BlueprintDTO>(`${Urls.project}/blueprints`, dto,
    { companyId }),

  remove: (companyId: number, id: number) => deleteReq(`${Urls.project}/blueprints/${id}`, {}, { companyId }),

  update: (companyId: number, id: number, dto: Partial<BlueprintDTO>) => put(`${Urls.project}/blueprints/${id}`, dto,
    { companyId }),

  copy: (companyId: number, id: number, dto: Partial<BlueprintDTO>) => post<BlueprintDTO>(
    `${Urls.project}/blueprints/${id}/copy`,
    dto,
    { companyId }
  ),

  createProjectStage: (blueprintId: number, dto: CreateBlueprintProjectStageDTO) =>
    post(`${Urls.project}/blueprints/${blueprintId}/blueprint-project-stages`, dto),

  removeProjectStage: (blueprintId: number, stageId: number) =>
    deleteReq(`${Urls.project}/blueprints/${blueprintId}/blueprint-project-stages/${stageId}`),

  createTask: (blueprintId: number, dto: CreateBlueprintTaskDTO) =>
    post(`${Urls.project}/blueprints/${blueprintId}/blueprint-tasks`, dto),

  removeTask: (blueprintId: number, taskId: number) =>
    deleteReq(`${Urls.project}/blueprints/${blueprintId}/blueprint-tasks/${taskId}`),
};
