import React, { useState } from 'react';
import { Plus } from 'react-feather';
import { Form } from '@components/Project/DataBank/types';
import { Button, ButtonSize, ButtonVariant } from '@kit/ui/Button';
import { FormLinkItem } from './FormLinkItem';

interface AddFormLinkProps {
  onAddLink: (form: Form) => void;
  hideForms?: number[];
  projectId: number;
  companyId: number;
  onCreateNewForm?: (formIdToReplace?: number) => void;
}

export const AddFormLink: React.FC<AddFormLinkProps> = ({
  onAddLink,
  hideForms = [],
  projectId,
  companyId,
  onCreateNewForm
}: AddFormLinkProps): JSX.Element => {
  const [isAddingForm, setIsAddingForm] = useState(false);

  const toggleIsAddingForm = () => setIsAddingForm(!isAddingForm);
  const onSelect = (form: Form) => {
    onAddLink(form);
    setIsAddingForm(false);
  };

  return !isAddingForm ? (
    <Button variant={ButtonVariant.Flat} onClick={toggleIsAddingForm} size={ButtonSize.Small} isUpperCase={false}>
      <Plus size="16px" />
      Link Form
    </Button>
  ) : (
    <FormLinkItem
      projectId={projectId}
      inEditState
      onBlur={toggleIsAddingForm}
      onSelect={onSelect}
      hideForms={hideForms}
      companyId={companyId}
      onCreateNewForm={onCreateNewForm}
    />
  );
};
