import styled from 'styled-components';
import { ProjectStageBadge } from '@components/ProjectStages';
import { SlaFlag } from '@common/SlaFlag';

export const RowRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Image = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 44px;
  height: 44px;
  flex-shrink: 0;
  border-radius: 4px;
`;

export const FirstLine = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`;

export const SecondLine = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #1d1d35;

  span {
    font-weight: 400;
  }
`;

export const StageBadge = styled(ProjectStageBadge)`
  width: auto;
  max-width: 150px;
  height: 20px;
`;

export const OverdueBadge = styled(SlaFlag)`
  height: 20px;
`;
