import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Droppable, OnDragEndResponder, OnDragStartResponder } from 'react-beautiful-dnd';
import { useFileMutation } from '@hooks/useFiles';
import { RecordType } from '@types';
import { DrawerEntity, useDrawersContext } from '@contexts/DrawersContext';
import { useSelection } from './useSelection';
import { Group } from './Group';
import { Scrollable } from './styled';
import { ListHeader } from '../components/ListHeader';
import { useDocListGroupedByStage } from './useDocListGroupedByStage';
import { useFilters } from '../useFilters';
import { Header } from '../components/Header';

interface Props {
  recordId: number;
  recordType: RecordType;
}

export const GroupedDocList = ({ recordId, recordType }: Props) => {
  const { filters, updateFilters } = useFilters();
  const {
    bulkUpdate: { mutateAsync: bulkUpdate }
  } = useFileMutation();

  const { openDrawer } = useDrawersContext();

  const [currentDraggingId, setCurrentDraggingId] = useState<number | null>(null);
  const selection = useSelection();
  const {
    bulkInfo,
    selectedIds,
    clearSelection,
    isAllSelected,
    toggleAllSelected,
    resetDataForGroups,
    isAllInGroupSelected,
    checkIsItemSelected,
    toggleItem,
    toggleGroup
  } = selection;

  const { groupedDocs } = useDocListGroupedByStage(recordId, filters.isArchivedShown);

  const filteredGroups = useMemo(() => {
    if (!filters.query) {
      return groupedDocs;
    }

    return groupedDocs
      .map((group) => {
        return {
          ...group,
          docs: group.docs.filter((doc) => {
            return doc.name.toLowerCase().includes(filters.query.toLowerCase());
          })
        };
      })
      .filter((group) => group.docs.length > 0);
  }, [groupedDocs, filters.query]);

  const currentlyDraggingDocIds = useMemo(() => {
    if (!currentDraggingId) {
      return [];
    }

    if (selectedIds.includes(currentDraggingId)) {
      return selectedIds;
    }

    return [currentDraggingId];
  }, [selectedIds, currentDraggingId]);

  const handleDragStart = useCallback<OnDragStartResponder>(({ draggableId }) => {
    setCurrentDraggingId(parseInt(draggableId, 10));
  }, []);

  const handleDragEnd = useCallback<OnDragEndResponder>(
    async ({ source, destination, reason, draggableId }) => {
      setCurrentDraggingId(null);

      if (reason !== 'DROP') {
        return;
      }

      if (!destination) {
        return;
      }

      const sourceGroupId = parseInt(source.droppableId.replace('droppableSection-', ''), 10);
      const destinationGroupId = parseInt(destination.droppableId.replace('droppableSection-', ''), 10);

      const sourceGroup = groupedDocs.find((group) => group.stage.id === sourceGroupId);
      const destinationGroup = groupedDocs.find((group) => group.stage.id === destinationGroupId);

      if (!sourceGroup || !destinationGroup) {
        return;
      }

      const docId = parseInt(draggableId, 10);

      const isMovingSelected = selectedIds.includes(docId);

      let movingFileIds = isMovingSelected ? selectedIds : [docId];

      if (sourceGroup.stage.id === destinationGroup.stage.id) {
        // exclude files that are already in the destination group
        movingFileIds = movingFileIds.filter((id) => !destinationGroup.docs.find((doc) => doc.id === id));
      }

      if (movingFileIds.length === 0) {
        return;
      }

      await bulkUpdate({
        fileIds: movingFileIds,
        projectId: recordId,
        stageId: destinationGroup.stage.id > 0 ? destinationGroup.stage.id : null
      });

      // clearSelection();
    },
    [groupedDocs, recordId, bulkUpdate, selectedIds]
  );

  useEffect(() => {
    if (groupedDocs) {
      resetDataForGroups(groupedDocs);
    }
  }, [groupedDocs, resetDataForGroups]);

  const handleRowClick = useCallback(
    (doc: RecordDocListItem) => {
      openDrawer(
        DrawerEntity.DOC,
        doc.id,
        groupedDocs.flatMap((group) => group.docs.map((doc) => doc.id))
      );
    },
    [openDrawer, groupedDocs]
  );

  return (
    <div>
      <Header
        recordId={recordId}
        {...bulkInfo}
        clearSelection={clearSelection}
        filters={filters}
        onFiltersChange={updateFilters}
      />

      <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        <Scrollable>
          <ListHeader isAllSelected={isAllSelected} onToggleAllSelected={toggleAllSelected} />

          {filteredGroups.map((group) => (
            <Droppable key={group.stage.id} droppableId={`droppableSection-${group.stage.id}`}>
              {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <Group
                    recordId={recordId}
                    group={group}
                    isDraggingOver={snapshot.isDraggingOver}
                    isAllSelected={isAllSelected || isAllInGroupSelected[group.stage.id]}
                    onToggleAllSelected={toggleGroup}
                    checkIsItemSelected={checkIsItemSelected}
                    toggleItem={toggleItem}
                    currentlyDraggingIds={currentlyDraggingDocIds}
                    recordType={recordType}
                    onRowClick={handleRowClick}
                  />
                </div>
              )}
            </Droppable>
          ))}
        </Scrollable>
      </DragDropContext>
    </div>
  );
};
