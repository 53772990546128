import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Property } from '@types';
import { useCompanyProperties, useRoutes, PORTFOLIO_ONLY, usePropertyGroups } from '@hooks';
import { SearchBar } from '@common/SearchBar';
import { concat, without, keyBy } from 'lodash';
import { PropertyBar } from '@components/Project/ProjectHeader/PropertyModal/PropertyBar/PropertyBar';
import { debounce } from 'lodash/fp';
import { move } from 'formik';
import { List, Clipboard, X } from 'react-feather';
import { useTaskTemplates } from '@components/templates/Workspace/Templates/TaskTemplates/hooks';
import { isBrowser } from '@utils';

import { Button, ButtonVariant } from '@kit/ui/Button';
import { usePortfolioColumns } from '@hooks/usePortfolioColumns';
import { Checkbox } from '@kit/ui/Checkbox';
import {
  Body,
  Footer,
  Header,
  Modal,
  Title,
  Wrapper,
  Col,
  Row,
  LeftWrapper,
  RightWrapper,
  WrapperHeader,
  WrapperBody,
  WrapperTitle,
  WrapperSearchbar,
  GroupItem,
  GroupItemName,
  GroupItemCounter,
  LeftCol,
  ColWrapper,
  StyledRow,
  ClearButton,
  StyledWrapperBody,
  PropertyGroupName,
  PropertyItem,
  PropertyGroup
} from './styled';

const PropertyRow = memo(
  ({
    property,
    isVisible,
    onCheck
  }: {
    property: Property;
    isVisible: boolean;
    onCheck: (property: Property, isVisible: boolean) => void;
  }) => {
    const handleClick = useCallback(
      (isChecked: boolean) => {
        onCheck(property, isChecked);
      },
      [property, onCheck]
    );

    return (
      <PropertyItem>
        <Checkbox label={property.name} isChecked={isVisible} onChange={handleClick} />
      </PropertyItem>
    );
  },
  (prevProps, nextProps) => prevProps.isVisible === nextProps.isVisible
);

type PropertyModalProps = {
  onClose: () => void;
};

type GroupedColumns = { name: string; columns: Property[]; isVirtual: boolean };

export const PropertyModal: React.FC<PropertyModalProps> = (props) => {
  const { onClose } = props;

  const { scopeToColumns } = useCompanyProperties();
  const { recordType } = useRoutes();

  const { currentColumns, saveUserPreferredColumns } = usePortfolioColumns(recordType);

  const { data: groupedProperties = [] } = usePropertyGroups({
    fullAccess: true,
    scope: recordType
  });
  const [shownProps, setShownProps] = useState(currentColumns.map((prop) => prop.id));

  useEffect(() => {
    setShownProps(currentColumns.map((prop) => prop.id));
  }, [currentColumns]);
  const {
    fetch: { data: taskTemplates }
  } = useTaskTemplates();
  const taskTemplatesById = useMemo(() => keyBy(taskTemplates?.results ?? [], 'id'), [taskTemplates]);
  const originalColumns: Property[] = useMemo(
    () =>
      (scopeToColumns[recordType] || [])
        .map((item, index) => ({
          ...item,
          position: index
        }))
        .filter(({ id }) => !PORTFOLIO_ONLY.includes(id)),
    [scopeToColumns, recordType]
  );
  const columnsById = useMemo(() => keyBy(originalColumns, 'id'), [originalColumns]);

  const [searchInputValue, setSearchInputValue] = useState('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchValueChange = useCallback(debounce(300, setSearchInputValue), []);

  const columns = useMemo<Property[]>(
    () =>
      originalColumns.filter((column) =>
        column.name.toLowerCase().includes(searchInputValue.trim().toLocaleLowerCase())
      ),
    [originalColumns, searchInputValue]
  );

  const columnGroups = useMemo<GroupedColumns[]>(
    () =>
      groupedProperties
        .map(({ name, properties }) => ({
          name: name === 'Default' ? 'Standard' : name,
          columns: properties.filter((p) => columns.some(({ id }) => id === p.id)),
          isVirtual: false
        }))
        .filter((propertiesColumns) => propertiesColumns.columns.length)
        .concat(
          Object.entries(
            columns.reduce(
              (acc, column) => {
                if (column.virtual) {
                  const groupName =
                    taskTemplatesById[column.taskTemplateId]?.title ??
                    column.name.substring(0, column.name.lastIndexOf(' '));

                  return {
                    ...acc,
                    [groupName]: [...(acc[groupName] ?? []), column]
                  };
                }

                return acc;
              },
              {} as { [key in GroupedColumns['name']]: Property[] }
            )
          ).map(([name, cols]) => ({
            name,
            columns: cols,
            isVirtual: true
          }))
        ),
    [columns, taskTemplatesById, groupedProperties]
  );

  const handleSave = () => {
    saveUserPreferredColumns(shownProps);
    onClose();
  };

  const isVisible = (prop: Property) => shownProps.includes(prop.id);
  const handleChangeVisibility = useCallback((prop: Property, visible: boolean) => {
    setShownProps((prev) => (visible ? concat(prev, prop.id) : without(prev, prop.id)));
  }, []);

  const getGroupLink = useCallback(
    ({ name }: GroupedColumns) => `property-group-${name.replace(/[^a-zA-Z0-9]+/g, '')}`,
    []
  );
  const changePosition = (columnId: number, newPosition: number) => {
    const oldPosition = shownProps.indexOf(columnId);
    if (oldPosition !== newPosition) {
      setShownProps(move(shownProps, shownProps.indexOf(columnId), newPosition) as number[]);
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangePosition = useCallback(debounce(10, changePosition), [shownProps]);

  const [activeGroup, setActiveGroup] = useState<string>('');

  return (
    <Modal open onClose={onClose}>
      <Wrapper>
        <Header>
          <Title>Manage columns</Title>
        </Header>
        <Body>
          <Row>
            <Col>
              <LeftWrapper>
                <WrapperHeader>
                  <WrapperTitle>
                    Add / Remove columns <GroupItemCounter>{columns.length}</GroupItemCounter>
                  </WrapperTitle>
                  <WrapperSearchbar>
                    <SearchBar placeholder="Search for a property..." onValueChange={handleSearchValueChange} />
                  </WrapperSearchbar>
                </WrapperHeader>
                <WrapperBody>
                  <StyledRow>
                    <LeftCol>
                      <ColWrapper>
                        {columnGroups.map((group) => (
                          <GroupItem isActive={activeGroup === group.name} onClick={() => setActiveGroup(group.name)}>
                            <button
                              type="button"
                              onClick={() => {
                                if (isBrowser) {
                                  const section = document.querySelector(`#${getGroupLink(group)}`);
                                  section.scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'start'
                                  });
                                }
                              }}
                            >
                              {group.isVirtual ? <Clipboard size="16px" /> : <List size="16px" />}
                              <GroupItemName title={group.name}>{group.name}</GroupItemName>
                              <GroupItemCounter>{group.columns.length}</GroupItemCounter>
                            </button>
                          </GroupItem>
                        ))}
                      </ColWrapper>
                    </LeftCol>
                    <Col>
                      <ColWrapper>
                        {columnGroups.map((group) => (
                          <div id={getGroupLink(group)}>
                            <PropertyGroupName>{group.name}</PropertyGroupName>
                            <PropertyGroup>
                              {group.columns.map((column) => (
                                <PropertyRow
                                  key={column.id}
                                  property={column}
                                  isVisible={isVisible(column)}
                                  onCheck={handleChangeVisibility}
                                />
                              ))}
                            </PropertyGroup>
                          </div>
                        ))}
                      </ColWrapper>
                    </Col>
                  </StyledRow>
                </WrapperBody>
              </LeftWrapper>
            </Col>
            <Col>
              <RightWrapper>
                <WrapperHeader>
                  <WrapperTitle>
                    Reorder columns <GroupItemCounter>{shownProps.length}</GroupItemCounter>
                    <ClearButton startIcon={<X size={16} />} variant="text" onClick={() => setShownProps([])}>
                      Clear All
                    </ClearButton>
                  </WrapperTitle>
                </WrapperHeader>
                <StyledWrapperBody>
                  {shownProps.map((columnId, position) => {
                    const column = {
                      ...columnsById[columnId],
                      position
                    };

                    return (
                      <PropertyBar
                        key={column.id}
                        column={column}
                        onChangePosition={handleChangePosition}
                        onRemoval={() => handleChangeVisibility(column, false)}
                      />
                    );
                  })}
                </StyledWrapperBody>
              </RightWrapper>
            </Col>
          </Row>
        </Body>
        <Footer>
          <Button variant={ButtonVariant.Secondary} onClick={onClose}>
            Close
          </Button>
          <Button type="submit" onClick={handleSave} variant={ButtonVariant.Primary}>
            Save
          </Button>
        </Footer>
      </Wrapper>
    </Modal>
  );
};
