import { colors } from '../colors';
import scroll from '../Utils/scroll';

type Modal = {
  size?: String;
};

export const modal = () => `
  display: flex;
  align-items: center;
  justify-content: center;
  
  > div:first-child {
    background-color: rgba(29,29,53,0.75) !important;
  }
`;

export const wrapper = (props: Modal) => `
  width: 100vw;
  height: var(--app-height);
  outline: none;
   
  @media (min-width: 768px) {
    min-height: auto;
    
    max-width: ${
      // eslint-disable-next-line
      props.size === 'sm'
        ? '360px'
        : // eslint-disable-next-line
    props.size === 'md' 
          ? '600px'
          : props.size === 'lg'
            ? '80vw'
            : '750px'
    };
    height: auto;
    
    margin: auto;
  }
  
`;

export const header = () => `
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  width: 100%;
  height: 60px;

  padding: 0 15px;
  
  @media (min-width: 768px) {
    padding: 0 30px;
    border-radius: 4px 4px 0 0;
  };
  
  border-bottom: 1px solid ${colors.gray3};
  background-color: ${colors.white};

  svg {
    cursor: pointer;
    color: ${colors.green};
  }
`;

export const title = () => `
  font-size: 20px;
  line-height: 1.25;
  font-weight: 500;
  color: ${colors.green};
`;

export const body = () => `
  height: var(--modal-body-height);
  overflow: auto;
  
  ${scroll};
  
  padding: 15px;
  background-color: ${colors.white2};

  @media (min-width: 768px) {
    height: auto;
    max-height: calc(100vh - 300px);
    padding: 20px 30px;
  }
`;

export const footer = () => `
  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: 100%;

  padding: 15px 10px;
  
  @media (min-width: 768px) {
    padding: 20px;
    border-radius: 0 0 4px 4px;
  };
  
  border-top: 1px solid ${colors.gray3}; 
  background-color: ${colors.white};

  button {
    margin: 0 5px;
    @media (min-width: 768px) {
      margin: 0 10px;
    }
  }
`;

export default {
  modal,
  wrapper,
  header,
  title,
  body,
  footer
};
