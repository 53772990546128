import { colors } from '@styles';
import styled from 'styled-components';

export const NumbersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > * {
    flex: 1;
  }
`;

export const NumberLabel = styled.div`
  color: #828d9a;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 4px;
`;

export const NumberValue = styled.div<{ isNegative?: boolean; isPositive?: boolean }>`
  font-size: 20px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${({ isNegative = false, isPositive = false }) => {
    if (isNegative) {
      return '#D54855';
    }
    if (isPositive) {
      return '#009A47';
    }

    return colors.black;
  }};
`;

export const NumberRow = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const Dollar = styled.span``;
