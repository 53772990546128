import React, { useState, useMemo, useCallback } from 'react';
import { CopyButton } from '@common/ui';
import UserTile from '@common/UserTile';

import { RecordDetail, useAppSelector, useRecordDetail } from '@hooks';
import { ContextMenu } from '@kit/components/ContextMenu';
import { Contact } from '@generated/types/graphql';
import { EditIcon } from '@kit/ui/icons/Edit';
import { useRecordsMutations } from '@hooks/useRecords';
import { RecordType } from '@types';
import { useConfirmDeleteModal, useModal } from '@common/PromiseModal';
import { ReactQueryKey } from '@enums';
import { useQueryClient } from 'react-query';
import { Plus, UserPlus, Users, XCircle } from 'react-feather';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { Tooltip } from '@material-ui/core';
import { isCustomerPortalEnabled } from '@state/selectors';
import { UserAvatar } from '@kit/components/UserAvatar';
import { PortalIcon } from '@kit/ui/icons/Portal';
import { PhoneNumber } from '@kit/ui/anchors/PhoneNumber';
import { EmailAddress } from '@kit/ui/anchors/EmailAddress';
import { hasEntityAccess } from '@utils/roles';
import { useDeleteRecordMember } from '@hooks/records/useDeleteRecordMember';
import { getFullName } from '@utils/utils';
import { ContactForm, CreateContactForm } from './ContactForm';
import { CreateWork, Widget, WidgetHeader, WidgetIcon, WidgetTitle } from '../styled';
import { Tabs, Tab, List, ContactRow, MemberRow, Copyable, UserCell, PortalIconWrapper } from './styled';
import { AddMemberForm } from './AddMemberForm';

interface Props {
  projectId: number;
  isFullWidth?: boolean;
  title?: string;
  addButtonText?: string;
}

const TABS = [
  {
    id: 'contacts',
    title: 'Contacts'
  },
  {
    id: 'members',
    title: 'Members'
  }
];

const RECORD_TYPE_TITLE_MAP: Record<RecordType, string> = {
  [RecordType.ACCOUNT]: 'Client',
  [RecordType.PROJECT]: 'Project',
  [RecordType.DEAL]: 'Request'
};

const ContactMenu = ({ contact, record }: { contact: Contact; record: RecordDetail }) => {
  const {
    update: { mutateAsync: updateRecord }
  } = useRecordsMutations(record?.companyId || 0, record?.type as RecordType);
  const confirmDelete = useConfirmDeleteModal();
  const queryClient = useQueryClient();
  const { openModal } = useModal();

  const canEdit = record && hasEntityAccess(record, 'edit');

  const invalidateCache = useCallback(() => {
    queryClient.invalidateQueries([ReactQueryKey.WorkspaceContacts]);
    queryClient.invalidateQueries([ReactQueryKey.RecordDetail]);
  }, [queryClient]);

  const items = useMemo(
    () =>
      [
        {
          title: 'Edit',
          icon: <EditIcon size="16px" color="#9C9CAA" />,
          onClick: () => {
            openModal<void>(
              ({ onClose }) => <ContactForm recordId={record.id} initialValues={contact} onClose={onClose} />,
              { title: 'Edit Contact' }
            );
          }
        },
        canEdit && {
          title: `Remove from ${RECORD_TYPE_TITLE_MAP[record.type as RecordType]}`,
          icon: <XCircle size="16px" color="#9C9CAA" />,
          onClick: async () => {
            if (
              await confirmDelete(
                `Are you sure you want to remove this Contact from ${RECORD_TYPE_TITLE_MAP[record.type as RecordType]}?`,
                'Remove'
              )
            ) {
              await updateRecord({
                id: record.id,
                dto: {
                  contacts: record.projectContacts
                    .filter((related) => related.id !== contact.id)
                    .map((related) => ({ contactId: related.id }))
                }
              });

              invalidateCache();
            }
          }
        }
      ].filter(Boolean),
    [contact, invalidateCache, openModal, confirmDelete, record, updateRecord, canEdit]
  );

  return <ContextMenu items={items} />;
};

export const Stakeholders = ({
  title = 'Stakeholders',
  addButtonText = 'Stakeholder',
  projectId,
  isFullWidth = false
}: Props) => {
  const [activeTab, setActiveTab] = useState('contacts');
  const { data: record } = useRecordDetail(projectId, { refetchOnMount: false });
  const canEdit = record && hasEntityAccess(record, 'edit');
  const confirmDelete = useConfirmDeleteModal();

  const { mutateAsync: deleteRecordMember } = useDeleteRecordMember();

  const { openModal } = useModal();
  const isPortalEnabled = useAppSelector(isCustomerPortalEnabled);

  const projectMembers = record?.projectMembers ?? [];

  const handleAdd = useCallback(() => {
    openModal<void>(({ onClose }) => <CreateContactForm recordId={record.id} onClose={onClose} />, {
      title: 'Create Contact'
    });
  }, [openModal, record?.id]);

  const createMenuItems = useMemo(
    () => [
      {
        title: 'Create Contact',
        icon: <UserPlus size="16px" color="#9C9CAA" />,
        onClick: () => {
          handleAdd();
        }
      },
      {
        title: 'Add Member',
        icon: <Plus size="16px" color="#9C9CAA" />,
        onClick: () => {
          openModal<void>(({ onClose }) => <AddMemberForm recordId={record.id} onClose={onClose} />, {
            title: 'Add Member'
          });
        }
      }
    ],
    [handleAdd, openModal, record?.id]
  );

  return (
    <Widget isFullWidth={isFullWidth}>
      <WidgetHeader>
        <WidgetTitle>
          <WidgetIcon backgroundColor="#ECE3FF">
            <Users size="16px" color="#7256AC" />
          </WidgetIcon>
          {title}
          <CreateWork>
            {!canEdit && (
              <Button onClick={handleAdd} variant={ButtonVariant.Secondary}>
                <Plus size="16px" />
                {addButtonText}
              </Button>
            )}

            {canEdit && (
              <ContextMenu items={createMenuItems}>
                <Button variant={ButtonVariant.Secondary}>
                  <Plus size="16px" />
                  {addButtonText}
                </Button>
              </ContextMenu>
            )}
          </CreateWork>
        </WidgetTitle>
      </WidgetHeader>

      <Tabs>
        {TABS.map((tab) => (
          <Tab key={tab.id} onClick={() => setActiveTab(tab.id)} isActive={activeTab === tab.id}>
            {tab.title}
          </Tab>
        ))}
      </Tabs>

      {activeTab === 'contacts' && (
        <List>
          {record?.projectContacts.map((contact) => (
            <ContactRow key={contact.id}>
              <UserTile title={contact.name} userName={contact.name} userId={contact.id} imgSrc="" disableInfoPopper />
              {isPortalEnabled && contact.portalStatus === 'INVITED' && (
                <Tooltip title="Contact is invited to Portal">
                  <PortalIconWrapper>
                    <PortalIcon size="12px" color="#FFF" />
                  </PortalIconWrapper>
                </Tooltip>
              )}
              <Copyable isEnabled={Boolean(contact.phones[0])}>
                <PhoneNumber value={contact.phones[0]} />
                <CopyButton value={contact.phones[0]} />
              </Copyable>
              <Copyable isEnabled={Boolean(contact.emails[0])}>
                <EmailAddress value={contact.emails[0]} />
                <CopyButton value={contact.emails[0]} />
              </Copyable>

              <ContactMenu contact={contact} record={record} />
            </ContactRow>
          ))}
        </List>
      )}

      {activeTab === 'members' && (
        <List>
          {projectMembers.map(({ id, member: user }) => (
            <MemberRow key={user.id}>
              <UserCell>
                <UserAvatar user={user} />
                <div>
                  {user.firstName} {user.lastName}
                </div>
              </UserCell>
              {user.email && (
                <Copyable isEnabled={Boolean(user.email)}>
                  <EmailAddress value={user.email} />
                  <CopyButton value={user.email} />
                </Copyable>
              )}
              <div>
                {canEdit && (
                  <ContextMenu
                    items={[
                      {
                        title: `Remove from ${RECORD_TYPE_TITLE_MAP[record.type as RecordType]}`,
                        icon: <XCircle size="16px" color="#9C9CAA" />,
                        onClick: async () => {
                          if (
                            await confirmDelete(
                              `Are you sure you want to remove ${getFullName(user)}  from ${RECORD_TYPE_TITLE_MAP[record.type as RecordType]}?`,
                              'Remove'
                            )
                          ) {
                            await deleteRecordMember({
                              recordId: record.id,
                              memberId: id
                            });
                          }
                        }
                      }
                    ]}
                  />
                )}
              </div>
            </MemberRow>
          ))}
        </List>
      )}
    </Widget>
  );
};
