import { colors } from '@styles';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px 16px 0 16px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const EventButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const EventButton = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;

  color: ${({ isActive }) => (isActive ? colors.white : colors.green)};
  background-color: ${({ isActive }) => (isActive ? colors.green : colors.white)};

  svg {
    flex-shrink: 0;
  }

  border: 1px solid ${colors.green};
  border-radius: 4px;
  cursor: pointer;
  padding: 0 8px;
  transition-property: color, background-color;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;

  &:hover {
    color: ${colors.white};
    background-color: ${colors.green};
  }
`;

export const EventButtonText = styled.div`
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  margin-left: 4px;
`;

export const FormContainer = styled.div`
  margin-top: 20px;
`;

export const WidgetTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const WidgetIcon = styled.div<{ backgroundColor: string }>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;
