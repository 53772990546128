import { forEach, isEmpty, isString, assign, set } from 'lodash';

import { AggregateColumn, RecordType } from '@types';
import { isFilterObject } from '@utils';
import { SearchPartial } from '@components/Project/ProjectView/types';

import { ListParams, Filter } from './types';

const MAP_SEARCH_TYPE_TO_FILTER = {
  // project status filters
  Active: [
    { col: 'isActive', val: true },
    { col: 'type', val: RecordType.PROJECT }
  ],
  Archived: [
    { col: 'isActive', val: false },
    { col: 'type', val: RecordType.PROJECT }
  ],
  All: [{ col: 'type', val: RecordType.PROJECT }],
  AccountAll: [{ col: 'type', val: RecordType.ACCOUNT }],
  AccountActive: [
    { col: 'isActive', val: true },
    { col: 'type', val: RecordType.ACCOUNT }
  ],
  AccountArchived: [
    { col: 'isActive', val: false },
    { col: 'type', val: RecordType.ACCOUNT }
  ],
  DealAll: [{ col: 'type', val: RecordType.DEAL }],
  DealActive: [
    { col: 'isActive', val: true },
    { col: 'type', val: RecordType.DEAL }
  ],
  DealArchived: [
    { col: 'isActive', val: false },
    { col: 'type', val: RecordType.DEAL }
  ]
};

const mapLikeOp = (col: number | string): string =>
  ({
    [-3]: '[like]',
    address: '[like]'
  }[col] || 'like');

const makeQueryFilterObject = ({ val, op, col, or, stage }: Filter, index: number = 0) => {
  const filter: {
    [key: string]: any;
  } = {
    [`filters[${index}][op]`]: op ?? mapLikeOp(col),
    [`filters[${index}][val]`]: `${isString(val) ? encodeURIComponent(val.trim()) : val}`,
    [`filters[${index}][col]`]: col
  };

  if (or) {
    filter[`filters[${index}][or]`] = or;
  }

  if (stage) {
    filter[`filters[${index}][stage]`] = stage;
  }

  return filter;
};

export const generateSearchParams = ($search?: SearchPartial) => {
  if (isEmpty($search)) {
    return {};
  }

  const search = $search as SearchPartial;
  let activeIndex = 0;

  const params: { [keys: string]: string | number | boolean } = {
    'paging[skip]': 0,
    'paging[limit]': 10
  };

  if (search.groupBy && search.groupBy !== '') {
    // see `groupColumnAdapter` at client/src/adapters/ProjectAdapters/ProjectHeaderAdapter.ts
    const isBlueprint = `${search.groupBy}`.includes('blueprint_');
    const paramName = isBlueprint ? 'blueprintId' : 'columnId';
    const value = isBlueprint ? `${search.groupBy}`.replace(/blueprint_/, '') : search.groupBy;

    params[paramName] = value;
  }

  // we change Search sorting to `orderBy` issue-140
  // not sure if we use it only for records fetch
  if (search.sortCol && search.sortCol !== '') {
    params['sorts[0][col]'] = search.sortCol;
    params['sorts[0][desc]'] = search.sortDesc === false ? search.sortDesc : true;
  }

  if (search.type) {
    MAP_SEARCH_TYPE_TO_FILTER[search.type]?.forEach((filter) => {
      assign(params, makeQueryFilterObject({ ...filter, op: '=' }, activeIndex));

      activeIndex += 1;
    });
  }

  if (search.startDate && search.endDate) {
    params.startDate = search.startDate;
    params.endDate = search.endDate;
  }

  if (search.search && search.search.trim() !== '') {
    if (search.searchBy && Number.isSafeInteger(search.searchBy)) {
      activeIndex += 1;

      assign(
        params,
        makeQueryFilterObject(
          {
            val: search.search,
            col: search.searchBy
          },
          activeIndex
        )
      );
    } else {
      params.searchKey = `${encodeURIComponent(search.search.trim())}`;
    }
  }

  if (search.perPage && search.page) {
    params['paging[skip]'] = (search.page - 1) * search.perPage;
    params['paging[limit]'] = search.perPage;
  }

  if (search.companyId) {
    params.companyId = search.companyId;
  }

  if (search.fetchAll) {
    params.fetchAll = search.fetchAll;
  }

  if (search.customer) {
    params.customer = search.customer;
  }

  forEach(search.filters, (item) => {
    if (isFilterObject(item)) {
      // just a workaround for not to implement same task title filter for BE, that's on FE
      if (item.val && item.col !== AggregateColumn.TASK_WITH_TITLE) {
        assign(params, makeQueryFilterObject(item, activeIndex));
        activeIndex += 1;
      }
    } else {
      // TODO: what it is for?
      forEach(item, (key) => {
        params[`filters[${activeIndex}][${key}]`] = item[key];
        activeIndex += 1;
      });
    }
  });

  if (search.selectedView) {
    params.viewId = search.selectedView;
  }

  return params;
};

type GenerateParamsArgument = {
  search?: SearchPartial;
  groupFilter?: string;
  startDate?: string;
  isTemplate?: boolean;
  companyId?: number | string;
};

export const generateParams = (values?: GenerateParamsArgument) => {
  if (!values || isEmpty(values)) {
    return {};
  }

  const { search, ...rest } = values;

  if (rest.companyId) {
    set(rest, 'headers["Company-Id"]', rest.companyId);
  }

  return {
    ...generateSearchParams(search),
    ...rest
  };
};

export const generateQueryParams = (listParams: ListParams, companyId?: number, omitPagination = false) => {
  const params: { [keys: string]: string | number | boolean } = {};

  if (!omitPagination) {
    params['paging[skip]'] = listParams.page?.skip ?? 0;
    params['paging[limit]'] = listParams.page?.limit ?? 100;
  }

  // build filters
  listParams.filters?.forEach((filter, index) => {
    assign(params, makeQueryFilterObject(filter, index));
  });

  // build orders
  listParams.orders?.forEach((order, index) => {
    params[`sorts[${index}][col]`] = order.col;
    params[`sorts[${index}][desc]`] = !order.desc ? order.desc : true;
  });

  if (companyId) {
    params.companyId = companyId;
  }

  return params;
};

export const errorHandler = (error: any) => {
  const response = error?.response;
  let errorMessage = 'Something unexpected has happened, please try again.';

  if (response) {
    const { data, status } = response;
    if (status === 400) {
      const { message = [] } = data;

      errorMessage = Array.isArray(message) ? message.join('<br/>') : message;
    } else {
      errorMessage = JSON.stringify(data); // could be json or string
    }
  }

  console.error('exception on api services', error);

  return new Error(errorMessage);
};
