import { DateTime, Duration, Interval } from 'luxon';

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/PluralRules/PluralRules#using_options
const ordinalFormatter = new Intl.PluralRules('en-US', { type: 'ordinal' });
const suffixes = new Map([
  ['one', 'st'],
  ['two', 'nd'],
  ['few', 'rd'],
  ['other', 'th']
]);
export const formatOrdinal = (n: number) => `${n}${suffixes.get(ordinalFormatter.select(n))}`;

export const formatKilometers = (meters: number) =>
  new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 1
  }).format(meters / 1000.0);

export const formatTripDuration = (duration: Duration) =>
  duration.shiftTo('minutes').normalize().toHuman({
    maximumFractionDigits: 0,
    type: 'unit',
    unitDisplay: 'short',
    listStyle: 'narrow'
  });

export const formatVisitInterval = (interval: Interval) =>
  `${interval.toLocaleString(DateTime.TIME_SIMPLE)}, ${interval.start.toFormat('ccc LLL d')}`;
