import { makeStyles } from '@material-ui/core';
import { colors, fonts } from '@styles';

export const useRadioGroupStyles = ({ layout }: { layout: 'row' | 'column' }) =>
  makeStyles({
    root: {
      gap: layout === 'row' ? '8px' : undefined,
      '& .MuiFormControlLabel-root': {
        margin: 0
      }
    }
  });

export const useRadioStyles = makeStyles({
  root: {
    height: '20px',
    minHeight: '40px',
    padding: '0 8px 0 0',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  icon: {
    borderRadius: '50%',
    width: 20,
    height: 20,
    backgroundColor: '#fff',
    border: '1px solid #C8C8D3',
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5'
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: '#fff'
    },
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2
    }
  },
  checkedIcon: {
    backgroundColor: `${colors.green}`,
    border: 0,
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 20,
      height: 20,
      backgroundImage: 'radial-gradient(#fff,#fff 5px,transparent 6px)',
      content: '""'
    },
    'input:hover ~ &': {
      backgroundColor: `${colors.green}`
    }
  },
  label: {
    color: '#1D1D35',
    font: `400 14px/17px ${fonts.primary}`
  }
});
