import styled from 'styled-components';
import { ProjectStageBadge } from '@components/ProjectStages';
import { Row } from '../styled';

export const TitleCell = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  min-width: 125px;
  flex: 0 0 125px;
  max-width: 125px;
  width: 125px;
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 4px;

  span {
    font-weight: 400;
  }
`;

export const Created = styled.div`
  color: #828d9a;
  font-size: 14px;
  font-weight: 400;
`;

export const StageBadge = styled(ProjectStageBadge)`
  width: auto;
  max-width: none;
  height: 20px;
`;

export const CenteredRow = styled(Row)`
  align-items: center;
  justify-content: space-between;

  > * {
    flex: 1;
  }
`;
