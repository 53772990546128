import React from 'react';
import { File } from '@generated/types/graphql';
import { Badge } from '@common/Badge';
import { DrawerEntity, useDrawersContext } from '@contexts/DrawersContext';
import { FileBadge } from './FileBadge';
import { FileImageBadge } from './FileImageBadge';
import { Wrapper } from './styled';

type FilesListProps = {
  files: File[];
  onRemove?: (file: File) => void;
};

export const FilesList: React.FC<FilesListProps> = (props) => {
  const { files, onRemove } = props;

  const { openDrawer } = useDrawersContext();

  const handleFileClick = (id: number) => {
    // setTimeout to first process the click event on outside component
    setTimeout(() => {
      openDrawer(
        DrawerEntity.DOC,
        id,
        files.map((file) => file.id)
      );
    }, 0);
  };

  return (
    <Wrapper>
      {!files.length && <Badge key="placeholder" color="" style={{ visibility: 'hidden' }} />}

      {files.map((file) =>
        file.metaData?.thumbnailUrl ? (
          <FileImageBadge
            key={file.id}
            file={file}
            onClick={() => {
              handleFileClick(file.id);
            }}
            onRemove={onRemove ? () => onRemove(file) : undefined}
          />
        ) : (
          <FileBadge
            key={file.id}
            file={file}
            onClick={() => handleFileClick(file.id)}
            onRemove={onRemove ? () => onRemove(file) : undefined}
          />
        )
      )}
    </Wrapper>
  );
};
