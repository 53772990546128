import { MainUser } from '@generated/types/graphql';
import { useAllCompaniesUsers } from '@hooks/useCompanyUsers';
import { useMemo } from 'react';

export const useUsersMap = () => {
  const { data: users = [], isLoading } = useAllCompaniesUsers({ onlyActive: false });

  const usersMap = useMemo(
    () =>
      users.reduce(
        (acc, user) => {
          acc[user.id] = user;

          return acc;
        },
        {} as Record<number, MainUser>
      ),
    [users]
  );

  return {
    usersMap,
    isUsersLoading: isLoading
  };
};
