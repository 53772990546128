import styled from 'styled-components';

export const Container = styled.section`
  margin-bottom: 8px;
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 4px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 24px;
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 400;
  padding-left: 8px;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ListItem = styled.div<{ shadowCount?: number }>`
  border: 1px solid #dfdfe8;
  border-radius: 8px;
  overflow: hidden;

  > div:first-of-type > * {
    border-top: 0;
  }

  position: relative;

  transition: box-shadow 0.1s;

  box-shadow: ${({ shadowCount }) => {
    switch (shadowCount) {
      case 1:
        return '0px 4px 0px #F7F7FC, 0px 5px 0px #DFDFE8';
      case 2:
        return '0px 4px 0px #F7F7FC, 0px 5px 0px #DFDFE8, 0px 8px 0px #F7F7FC, 0px 9px 0px #DFDFE8';
      default:
        return 'none';
    }
  }};
`;
