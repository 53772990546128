import { SearchBar } from '@common/SearchBar';
import { useSmartViewList } from '@hooks/smartViews/useSmartViewList';
import React, { useMemo, useState } from 'react';
import { SmartViewFilter } from '@types';
import { useSmartViewFolderList } from '@hooks/smartViews/useSmartViewFolderList';
import { DashboardListItem, useDashboardList } from '@hooks/analytics/useDashboardList';
import { Container } from './styled';
import { Folder } from './Folder';

export const List = () => {
  const { data: folders = [], isLoading } = useSmartViewFolderList();
  const { data: smartViews = [] } = useSmartViewList();
  const { data: dashboards = [] } = useDashboardList();

  const allFolders = useMemo(() => {
    if (isLoading) {
      return [];
    }

    return [...folders, { id: -1, name: 'Other' }];
  }, [folders, isLoading]);

  const [search, setSearch] = useState('');

  const groupedData = useMemo(() => {
    const groups = [...smartViews, ...dashboards].reduce(
      (acc, smartViewOrDashboard) => {
        if (search && !smartViewOrDashboard.name.toLowerCase().includes(search.trim().toLowerCase())) {
          return acc;
        }

        if ('layout' in smartViewOrDashboard) {
          if (smartViewOrDashboard.filterFolder?.id && !acc[smartViewOrDashboard.filterFolder.id]) {
            acc[smartViewOrDashboard.filterFolder.id] = [];
          }

          acc[smartViewOrDashboard.filterFolder?.id ?? -1].push(smartViewOrDashboard);
        } else {
          if (smartViewOrDashboard.filterFolderId && !acc[smartViewOrDashboard.filterFolderId]) {
            acc[smartViewOrDashboard.filterFolderId] = [];
          }

          acc[smartViewOrDashboard.filterFolderId ?? -1].push(smartViewOrDashboard);
        }

        return acc;
      },
      { '-1': [] as (SmartViewFilter | DashboardListItem)[] } as Record<string, (SmartViewFilter | DashboardListItem)[]>
    );

    return Object.keys(groups).reduce(
      (acc, key) => {
        if (groups[key].length) {
          acc[key] = [...groups[key]].sort((a, b) => a.name.localeCompare(b.name));
        }

        return acc;
      },
      {} as Record<string, (SmartViewFilter | DashboardListItem)[]>
    );
  }, [smartViews, dashboards, search]);

  return (
    <Container>
      <SearchBar light placeholder="Search..." onValueChange={setSearch} />

      {allFolders.map((folder) => {
        if (!groupedData[folder.id]?.length) {
          return null;
        }

        return <Folder key={folder.id} folder={folder} views={groupedData[folder.id] ?? []} />;
      })}
    </Container>
  );
};
