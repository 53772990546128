import { FilterSort } from '@components/Project/ProjectView/types';
import { ProjectsOrderBy, ProjectFilter } from '@generated/types/graphql';
import { AccountStatus } from '@types';
import { useCallback } from 'react';
import { createGlobalState } from 'react-use';
import { DeepPartial } from 'redux';
import { WidgetFilters } from '@features/Analytics/types';
import { PER_PAGE_OPTIONS } from '../constants';

export type ClientFilterState = {
  isArchivedShown: boolean;
  sortBy: FilterSort[];
  query: string;
  groupBy: string | number | null;
  status: AccountStatus | null;
  filters: WidgetFilters | undefined;
  perPage: { label: string; value: number };
};

export const DEFAULT_CLIENT_FILTERS: ClientFilterState = {
  isArchivedShown: false,
  sortBy: [
    {
      columnId: -5,
      desc: true
    }
  ],
  query: '',
  groupBy: null,
  status: null,
  filters: undefined,
  perPage: PER_PAGE_OPTIONS[0]
};

const clientFilterState = createGlobalState<ClientFilterState>(DEFAULT_CLIENT_FILTERS);

export const useClientFilterState = () => {
  const [clientFilters, setClientFilters] = clientFilterState();

  const handleFiltersChange = useCallback(
    (newFilters: Partial<ClientFilterState>) => {
      setClientFilters((prev) => ({
        ...prev,
        ...newFilters
      }));
    },
    [setClientFilters]
  );

  const resetFilters = useCallback(
    (values: Partial<ClientFilterState>) => {
      setClientFilters((prev) => ({
        ...prev,
        ...values
      }));
    },
    [setClientFilters]
  );

  return {
    clientFilters,
    updateFilters: handleFiltersChange,
    resetFilters
  };
};

export const mapClientSortToServerSort = (sortBy: { field: string; direction: 'ASC' | 'DESC' }) => {
  return sortBy.direction === 'ASC' ? ProjectsOrderBy.CreatedAtAsc : ProjectsOrderBy.CreatedAtDesc;
};

export const mapSearchFilterToServerSearchFilter = (search: string) => {
  if (!search) {
    return {};
  }

  const result: DeepPartial<ProjectFilter> = {
    or: [
      {
        title: {
          includesInsensitive: search
        }
      }
    ]
  };

  return result;
};

export const mapClientFiltersToServerFilters = (clientFilters: ClientFilterState): DeepPartial<ProjectFilter> => {
  let resultFilter: DeepPartial<ProjectFilter> = {};

  if (clientFilters.query.trim()) {
    const searchFilter = mapSearchFilterToServerSearchFilter(clientFilters.query.trim());

    if (resultFilter.or && searchFilter.or) {
      resultFilter = {
        ...resultFilter,
        ...searchFilter,
        or: undefined,
        and: [{ or: resultFilter.or }, { or: searchFilter.or }]
      };
    } else {
      resultFilter = {
        ...resultFilter,
        ...searchFilter
      };
    }
  }

  return resultFilter;
};
