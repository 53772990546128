import { Integration, SyncFrequency, SystemProfile } from '@generated/types/graphql';
import { useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import { apiErrorHandler } from '@utils';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { selectWorkspaceId } from '@state/selectors';
import { SystemMetric } from '@services/api/systemProfilesAPI';
import { useAppSelector } from '../../..';

export type MetricConfigs = {
  [metric in SystemMetric]: {
    enabled: boolean;
    by: 'manual' | 'auto';
    reason?: string;
  };
};

export type Fleet = Pick<SystemProfile, 'id' | 'name' | 'default'> & {
  systemProfileConfigs: {
    metrics: MetricConfigs;
    frequency: SyncFrequency;
    integration: Pick<Integration, 'id' | 'provider' | 'active'>;
  }[];
};

export const useFleetList = () => {
  const companyId = useAppSelector(selectWorkspaceId);

  return useQuery([ReactQueryKey.SystemsSettings, ReactQueryKey.FleetsSettings, companyId], async () => {
    try {
      const { systemProfiles } = await postGraphql<{ systemProfiles: SystemProfile[] }>(
        gql`
          query FLEET_LIST_QUERY($companyId: Int!) {
            systemProfiles(condition: { companyId: $companyId }) {
              id
              name
              default

              systemProfileConfigs {
                metrics
                frequency
                integration {
                  id
                  provider
                  active
                }
              }
            }
          }
        `,
        { companyId }
      );

      return systemProfiles as Fleet[];
    } catch (e) {
      throw apiErrorHandler('Error fetching fleets', e);
    }
  });
};
