import React from 'react';
import { Select } from '@kit/ui/Select';
import { useLabelList } from '@hooks/workspace/labels/useLabelList';
import { Label } from '@domains/labels';
import { Badge, BadgeSize } from '@kit/ui/Badge';
import { LabelIcon } from '@kit/ui/icons/Label';
import { XIcon } from '@kit/ui/icons/X';
import { Label as TLabel } from '@generated/types/graphql';
import { TagsContainer } from './styled';

interface Props {
  value: TLabel[];
  onChange: (value: TLabel[]) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  isDisabled?: boolean;
}

export const LabelSelector = ({ value, onChange, onBlur, onFocus }: Props) => {
  const { data: labels = [] } = useLabelList();

  return (
    <Select<TLabel>
      options={labels}
      value={value}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.label}
      renderOption={(option) => <Label label={option} />}
      renderTags={(tags, getTagProps) => {
        return (
          <TagsContainer>
            {tags.map((tag, index) => {
              const { onDelete, ...tagProps } = getTagProps({ index });

              return (
                <Badge {...tagProps} size={BadgeSize.Large} color={tag.color} bgColor={`${tag.color}33`}>
                  <LabelIcon size="16px" />
                  <span>{tag.label}</span>
                  <XIcon size="16px" onClick={onDelete} />
                </Badge>
              );
            })}
          </TagsContainer>
        );
      }}
      name="labels"
      isMulti
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={(_, newValue) => onChange(newValue)}
    />
  );
};
