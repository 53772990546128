import { useRef, useCallback } from 'react';
import { useDrop } from 'react-dnd';
import { debounce, find, toString } from 'lodash';
import { useBlueprints, useCompanyPropertiesMutations, useRoutes, useSearch } from '@hooks';

import { createGlobalState } from 'react-use';
import { useQueryClient } from 'react-query';
import { TargetGroup } from '@types';
import { getRecordListKey } from '@hooks/useRecords';
import { invalidateQueriesBy } from '@utils';

export const useDragBetweenGroups = ({
  group,
  accept
}: { group: TargetGroup; accept: string; }) => {
  const queryClient = useQueryClient();
  const dndRef = useRef();
  const { updateProjectProperty } = useCompanyPropertiesMutations();
  const { fetchAll: { data: blueprints } } = useBlueprints();
  const { search } = useSearch();

  const { recordType } = useRoutes();

  const [{ isOver, draggingRecord }, drop] = useDrop({
    accept: [accept],
    drop: async (monitor: { type: string; record: any; }) => {
      const isBlueprint = `${search.groupBy}`.match(/blueprint/);

      const onSuccess = () => {
        invalidateQueriesBy(queryClient, [getRecordListKey(recordType)]);
      };

      if (isBlueprint) {
        const blueprint = find(blueprints?.results, { id: +toString(search.groupBy).match(/\d+/)?.[0] });
        const stageId = find(blueprint?.projectStages, { projectStage: { name: group.group.name } })?.projectStage.id;

        await updateProjectProperty.mutate({
          projectId: monitor.record.projectId,
          property: { mappedName: 'stageId' },
          newValue: stageId
        }, { onSuccess });
      } else {
        await updateProjectProperty.mutate({
          projectId: monitor.record.projectId,
          property: {
            id: search.groupBy,
            isAdditional: true,
            mappedName: 'stageId'
          },
          newValue: group.group.name !== 'Ungrouped' ? group.group.name : null
        }, { onSuccess });
      }
    },
    collect: (monitor) => {
      return {
        isOver: () => {
          return monitor.isOver() && monitor.getItem()?.groupId !== group.group.id;
        },
        canDrop: () => {
          return monitor.canDrop() && monitor.getItemType() === accept;
        },
        draggingRecord:
          monitor.getItemType() === accept
            ? monitor.getItem().record
            : null
      };
    },
  });

  drop(dndRef);

  return {
    dndRef,
    isOver: isOver(),
    draggingRecord: isOver() && draggingRecord
  };
};

let firstCellMaxWidth = 0;
const updateMaxWidth = debounce((cb) => cb(), 0);
const useFirstCellMaxWidth = createGlobalState<number>(258);

export const useFirstCellMeasure = (): [number, (candidate: number) => void, () => void] => {
  const [globalFirstCellMaxWidth, setFirstCellMaxWidth] = useFirstCellMaxWidth();

  const resetFirstCellWidth = useCallback(() => {
    firstCellMaxWidth = 0;
  }, []);
  const updateFirstCellMaxWidth = useCallback(
    (maxCandidate: number) => {
      // if (maxCandidate > firstCellMaxWidth) {
      if (maxCandidate - firstCellMaxWidth > 1) {
        firstCellMaxWidth = maxCandidate;
      }

      updateMaxWidth(() => {
        setFirstCellMaxWidth(firstCellMaxWidth);
      });
    }, [setFirstCellMaxWidth]
  );

  return [
    globalFirstCellMaxWidth,
    updateFirstCellMaxWidth,
    resetFirstCellWidth
  ];
};
