import { defaultTheme } from '@themes';
import { getColorWithOpacity } from '@styles';

export const radio = () => {
  return {
    // .MuiRadio-root Styles applied to the root element.
    root: {
      '&:hover, &:focus': {
        backgroundColor: getColorWithOpacity(defaultTheme.colors.white, 20)
      }
    },
    // .Mui-checked Pseudo-class applied to the root element if checked={true}.
    checked: {},
    // .Mui-disabled Pseudo-class applied to the root element if disabled={true}.
    disabled: {
      '& span': {
        opacity: '0.5'
      }
    },
    // .MuiRadio-colorPrimary Styles applied to the root element if color="primary".
    colorPrimary: {
      color: defaultTheme.colors.gray,
      '&$checked': {
        color: defaultTheme.colors.green
      }
    },
    // .MuiRadio-colorSecondary Styles applied to the root element if color="secondary".
    colorSecondary: {}
  };
};

export const icon = {
  icon: {
    borderRadius: '50%',
    width: 20,
    height: 20,
    // backgroundColor: defaultTheme.colors.gray3,
    border: `1px solid ${defaultTheme.colors.gray}`
  },
  checkedIcon: {
    backgroundColor: defaultTheme.colors.green,
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    borderColor: 'transparent',
    '&:before': {
      display: 'block',
      width: 20,
      height: 20,
      backgroundImage: 'radial-gradient(#fff,#fff 5px,transparent 6px)',
      content: '""',
      top: '-1px',
      left: '-1px',
      position: 'relative'
    }
  }
};
