import React from 'react';
import { TableRow as TableRowMui, TableRowProps } from '@material-ui/core';
import { useStylesTableRow } from './styled';

interface Props extends TableRowProps {
  isUnseen?: boolean;
}

export const TableRow: React.FC<Props> = (props) => {
  const {
    ...rest
  } = props;

  const classesTableRow = useStylesTableRow();

  return (
    <TableRowMui
      classes={classesTableRow}
      {...rest}
    />
  );
};
