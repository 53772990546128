import React, { useRef } from 'react';
import { DragObjectWithType, useDrag, useDrop } from 'react-dnd';
import { Column, Property } from '@types';
import { drag as Drag } from '@assets/svg';
import { X } from 'react-feather';

import { DragIcon, Item, Name } from './styled';

interface PropertyBarProps {
  column: Property;
  onChangePosition: (propId: number, position: number) => void;
  onRemoval: () => void;
}

interface DragObject extends DragObjectWithType {
  column: Column;
}

export const PropertyBar: React.FC<PropertyBarProps> = (props) => {
  const { column, onChangePosition, onRemoval } = props;

  const ref = useRef(null);

  const { position } = column;

  const [, drop] = useDrop({
    accept: 'column',
    hover: (item: DragObject, monitor) => {
      if (!ref.current) {
        return;
      }
      const dragPosition = item.column.position || 0;
      const hoverPosition = position || 0;
      // Don't replace items with themselves
      if (dragPosition === hoverPosition) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      if (!clientOffset) {
        return;
      }
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragPosition < hoverPosition && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragPosition > hoverPosition && hoverClientY > hoverMiddleY) {
        return;
      }

      onChangePosition(item.column.id, hoverPosition);
    }
  });
  const [{ isDragging }, drag] = useDrag<{ type: string; column: Column; position: number }, any, any>({
    accept: 'column',
    item: {
      type: 'column',
      column,
      position
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    })
  });

  drop(ref);
  drag(ref);

  return (
    <Item ref={ref} style={{ opacity: isDragging ? 0.4 : 1 }}>
      <DragIcon src={Drag} style={{ marginRight: '15px', opacity: '1', marginLeft: '5px' }} />
      <Name>{column.name}</Name>
      <X onClick={onRemoval} size={20} color="#778CA2" />
    </Item>
  );
};
