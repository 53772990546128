import { ModalBody, ModalFooter } from '@common/PromiseModal';
import { DashboardListItem, LayoutItem, useDashboardList } from '@hooks/analytics/useDashboardList';
import { useUpdateDashboard } from '@hooks/analytics/useUpdateDashboard';
import { Form, FormValidationRules, InputField, SelectField, useForm } from '@kit/components/Form';
import { Button, ButtonVariant } from '@kit/ui/Button';
import React from 'react';

interface Props {
  currentDashboardId: number;
  widget: LayoutItem;
  onClose: () => void;
}

interface FormValues {
  name: string;
  dashboard: DashboardListItem | null;
}

const RULES: FormValidationRules<FormValues> = {
  name: {
    isRequired: true
  },
  dashboard: {
    isRequired: true
  }
};

export const DuplicateWidget = ({ currentDashboardId, widget, onClose }: Props) => {
  const { data: dashboards = [] } = useDashboardList();
  const { mutateAsync: update } = useUpdateDashboard();

  const postForm = async (values: FormValues) => {
    const { name, dashboard } = values;
    const nextIndex = dashboard.layout.length ? Math.max(...dashboard.layout.map(({ i }) => +i)) + 1 : 1;

    await update({
      id: dashboard.id,
      dto: {
        layout: [
          ...dashboard.layout,
          {
            ...widget,
            widgetConfig: { ...widget.widgetConfig, title: name },
            i: nextIndex.toString(),
            y: dashboard.layout.length,
            x: 0
          }
        ]
      }
    });

    onClose();
  };

  const { form, handleSubmit } = useForm<FormValues>({
    onSubmit: postForm,
    defaultValues: {
      name: `Copy of ${widget.widgetConfig.title}`,
      dashboard: dashboards.find((d) => d.id === currentDashboardId)
    }
  });

  const {
    formState: { isSubmitting },
    control
  } = form;

  return (
    <Form rules={RULES} onSubmit={handleSubmit}>
      <ModalBody>
        <InputField name="name" label="Name" control={control} />
        <SelectField
          name="dashboard"
          label="Duplicate to Dashboard"
          options={dashboards}
          getOptionLabel={(option) => option.name}
          control={control}
        />
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose} variant={ButtonVariant.Secondary}>
          Cancel
        </Button>
        <Button disabled={isSubmitting} type="submit" variant={ButtonVariant.Primary}>
          Create
        </Button>
      </ModalFooter>
    </Form>
  );
};
