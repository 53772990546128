import { ProjectDetail, RecordType, TaskViewFilterSubType } from '@types';
import { ProjectViewType } from '@enums';
import qs from 'querystring';

import { isBrowser } from './utils';

type Id = number | string;

type ProjectArgument = { projectId?: Id } & { id?: Id; companyId: Id } & { project?: { id?: Id } } & {
  projectDetail?: ProjectDetail;
} & { type?: RecordType };

type ProjectActivityArgument = ProjectArgument & { feedId?: number; feedCursor?: string };

const getId = (project: ProjectArgument) =>
  project.projectId || project.id || project.project?.id || project.projectDetail?.id;

const getCompanyId = (project: ProjectArgument) => project.projectDetail?.companyId ?? project.companyId;

export const getPathnameByRecordType = (recordType: RecordType) =>
  ({
    [RecordType.ACCOUNT]: 'clients',
    [RecordType.DEAL]: 'requests',
    [RecordType.PROJECT]: 'projects'
  }[recordType]);

export const getPathnameByTaskViewSubType = (subType: TaskViewFilterSubType) =>
  ({
    [TaskViewFilterSubType.SCHEDULER]: 'scheduler',
    [TaskViewFilterSubType.DISPATCHER]: 'dispatcher-new',
    [TaskViewFilterSubType.LIST]: 'list'
  }[subType]);

export const getViewTypeSubPath = (recordType: RecordType, viewType: ProjectViewType) =>
  ({
    [RecordType.ACCOUNT]: {
      [ProjectViewType.table]: ''
    },
    [RecordType.PROJECT]: {
      [ProjectViewType.map]: '/map',
      [ProjectViewType.table]: '',
      [ProjectViewType.pipeline]: '/pipeline',
      [ProjectViewType.grid]: ''
    },
    [RecordType.DEAL]: {
      [ProjectViewType.table]: '',
      [ProjectViewType.pipeline]: '/pipeline'
    }
  }[recordType][viewType] || '');

export const getRecordListPagePath = (record: { companyId: number; type: RecordType }, viewType?: ProjectViewType) =>
  `/${record.companyId}` +
  `/${getPathnameByRecordType(record.type)}` +
  `${viewType ? getViewTypeSubPath(record.type, viewType) : ''}`;

export const getPipelinePath = (companyId: number, dealId: number) =>
  `${getRecordListPagePath({ companyId, type: RecordType.DEAL })}` +
  `${getViewTypeSubPath(RecordType.DEAL, ProjectViewType.pipeline)}` +
  `${dealId ? `?dealId=${dealId}` : ''}`;

export const getRecordDetailsPagePath = (record: ProjectArgument) => {
  const type = record.projectDetail?.type ?? record.type ?? RecordType.PROJECT;

  return `/${getCompanyId(record)}/${getPathnameByRecordType(type)}/${getId(record)}`;
};

export const getRecordActivityPagePath = (record: ProjectActivityArgument) => {
  const query = qs.stringify({ feedId: record.feedId, feedCursor: record.feedCursor });

  return `${getRecordDetailsPagePath(record)}/activity?${query}`;
};

export const getRecordPropertiesPagePath = (record: ProjectArgument) => {
  return `${getRecordDetailsPagePath(record)}/details`;
};

export const getRecordDatabankPagePath = (record: ProjectArgument) => {
  return `${getRecordDetailsPagePath(record)}/databank`;
};

export const getRecordFinancialsPagePath = (record: ProjectArgument) => {
  return `${getRecordDetailsPagePath(record)}/financial`;
};

export const getRecordKanbanPagePath = (project: ProjectArgument) => {
  const type = project.projectDetail?.type ?? project.type ?? RecordType.PROJECT;

  if (type === RecordType.DEAL) {
    return getRecordActivityPagePath(project);
  }

  return `${getRecordDetailsPagePath(project)}/kanban`;
};

export const getRecordWorkPagePath = (project: ProjectArgument) => {
  const type = project.projectDetail?.type ?? project.type ?? RecordType.PROJECT;

  if (type === RecordType.DEAL) {
    return getRecordActivityPagePath(project);
  }

  return `${getRecordDetailsPagePath(project)}/work`;
};

export const getRecordMainPagePath = (project: ProjectArgument) => {
  return getRecordActivityPagePath(project);
};

export const getAutomationPage = (workspaceId: number, automationId: number) =>
  `/${workspaceId}/workspace/automations/${automationId}`;

export const getPropertySettingsPage = (workspaceId: number) => `/${workspaceId}/workspace/properties`;

export const getIntegrationSettingsPage = (workspaceId: number) => `/${workspaceId}/workspace/integrations`;

export const isPipelinePage = (location?: Window['location']) => {
  if (!isBrowser) {
    return RecordType.PROJECT;
  }

  return !!location?.pathname.match(/pipeline/);
};

export const isContactPage = (location?: Window['location']) => {
  if (!isBrowser) {
    return '';
  }

  return !!location?.pathname.match(/contacts/);
};

export const getRecordViewTypeFromUrl = (location: Location, companyId: number, type: RecordType): ProjectViewType => {
  switch (location.pathname) {
    case getRecordListPagePath({ companyId, type }, ProjectViewType.table):
      return ProjectViewType.table;

    case getRecordListPagePath({ companyId, type }, ProjectViewType.grid):
      return ProjectViewType.grid;

    case getRecordListPagePath({ companyId, type }, ProjectViewType.map):
      return ProjectViewType.map;

    case getRecordListPagePath({ companyId, type }, ProjectViewType.pipeline):
      return ProjectViewType.pipeline;

    default:
      switch (type) {
        case RecordType.ACCOUNT:
          return ProjectViewType.table;

        case RecordType.PROJECT:
          return ProjectViewType.grid;

        case RecordType.DEAL:
          return ProjectViewType.pipeline;

        default:
          throw new Error(`Unrecognized record type ${type}`);
      }
  }
};
