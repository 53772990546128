import React from 'react';
import { UserAvatar } from '@kit/components/UserAvatar';
import { Wrapper } from './styled';

interface IProps {
  id: string | number;
  name: string;
  avatarUrl?: string;
  onClick?: () => void;
  hideAvatar?: boolean;
}

export const DropDownItem: React.FC<IProps> = (props) => {
  const { id, name, avatarUrl, onClick, hideAvatar } = props;

  return (
    <Wrapper className="cq-user-select-dropdown-item" onClick={onClick}>
      {hideAvatar ? null : (
        <UserAvatar
          user={{
            firstName: name,
            avatarUrl,
            id
          }}
        />
      )}
      {name}
    </Wrapper>
  );
};
