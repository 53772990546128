import { InfiniteData, QueryClient } from 'react-query';
import { castArray, get } from 'lodash';
import { PaginationResult } from '@types';
import { alertShow } from '@state/actions/alert/alertAction';
import { Dispatch } from 'react';

export const flatPagesData = <T>(pagesData?: InfiniteData<PaginationResult<T>>, path?: string[]): T[] => {
  if (!pagesData || !pagesData.pages) {
    return [];
  }

  return pagesData.pages.flatMap((item) => get(item, [...(path || []), 'results']));
};

/**
 * @deprecated overengeneering, do not use
 */
export const makeMutationHandlers = <ListType, ResponseType>(
  QUERY_KEY: any[],
  queryClient: QueryClient,
  dispatch: Dispatch<any>,
  resultMergeFunction: (list: ListType[], response: ResponseType) => ListType[],
  messageSuccess: string,
  messageFail?: string
) => ({
  onSuccess: (data: ResponseType) => {
    const list = queryClient.getQueryData<PaginationResult<ListType>>(QUERY_KEY);
    queryClient.setQueryData(QUERY_KEY, {
      ...list,
      results: resultMergeFunction(list?.results || [], data)
    });

    dispatch(alertShow([messageSuccess], 'success'));
  },
  onError: (error: Error) => {
    dispatch(alertShow([messageFail || error.message], 'error'));
  }
});

export const invalidateQueriesBy = (client: QueryClient, keys: string | string[]) => {
  Object.entries(client.queryCache.queriesMap).forEach(([key]) => {
    if (castArray(keys).every((type) => key.includes(type))) {
      client.invalidateQueries(JSON.parse(key));
    }
  });
};
