import mapViewReducer from './mapViewReducer';
import projectViewReducer from './reducer';

export * from './defaultValues';

const projectReducer = {
  project: projectViewReducer,
  mapView: mapViewReducer,
};

export default projectReducer;
