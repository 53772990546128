import React, { useEffect, useMemo } from 'react';
import {
  DateTimeField,
  Form,
  FormValidationRules,
  InputField,
  RichTextField,
  SelectField,
  useForm
} from '@kit/components/Form';
import { Invoice } from '@generated/types/graphql';
import { useRecordDetail } from '@hooks/useRecordDetail';
import { useCreateInvoice } from '@hooks/invoices/useCreateInvoice';
import { useUpdateInvoice } from '@hooks/invoices/useUpdateInvoice';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { STATUS_OPTIONS } from '../constants';
import { Body, FieldsGrid, Footer } from './styled';
import { Status } from '../Status';

interface Props {
  recordId: number;
  isBill?: boolean;
  onClose: () => void;
  invoice?: Invoice;
}

interface FormValues {
  title: string;
  description: string;
  dueDate: Date;
  amount: number;
  amountPaid: number;
  status: { id: string; title: string };
  record: { id: number; title: string };
}

const mapInvoiceToFormValues = (invoice: Invoice): FormValues => ({
  title: invoice.title,
  description: invoice.description,
  dueDate: invoice.dueDate,
  amount: +invoice.amount,
  amountPaid: +invoice.amountPaid,
  status: STATUS_OPTIONS.find((option) => option.id === invoice.status) || STATUS_OPTIONS[0],
  record: invoice.project
});

const rules: FormValidationRules<FormValues> = {
  title: {
    isRequired: true
  },
  dueDate: {
    isRequired: true
  },
  amount: {
    isRequired: true
  },
  status: {
    isRequired: true
  },
  record: {
    isRequired: true
  }
};

export const InvoiceForm = ({ invoice, recordId, isBill = false, onClose }: Props) => {
  const isNew = !invoice;
  const { data: record } = useRecordDetail(recordId, { refetchOnMount: false });

  const { mutateAsync: createInvoice } = useCreateInvoice();
  const { mutateAsync: updateInvoice } = useUpdateInvoice();

  const relatedToOptions = useMemo(
    () => [
      { id: recordId, title: record?.title },
      ...record.deals.map((deal) => ({ id: deal.id, title: deal.title })),
      ...record.projects.map((project) => ({ id: project.id, title: project.title }))
    ],
    [record, recordId]
  );

  const postForm = async (values: FormValues) => {
    if (isNew) {
      await createInvoice({
        ...values,
        relatedToRecordId: values.record.id,
        status: values.status.id,
        isBill
      });
    } else {
      await updateInvoice({
        id: invoice.id,
        dto: {
          ...values,
          relatedToRecordId: values.record.id,
          status: values.status.id
        }
      });
    }

    onClose();
  };

  const { form, handleSubmit } = useForm({
    onSubmit: postForm,
    defaultValues: isNew
      ? {
          record: relatedToOptions[0],
          status: STATUS_OPTIONS[0]
        }
      : mapInvoiceToFormValues(invoice)
  });

  const {
    formState: { isSubmitting },
    control,
    watch
  } = form;

  const relatedTo = watch('record');

  useEffect(() => {
    if (!relatedTo && relatedToOptions.length) {
      form.setValue('record', relatedToOptions[0]);
    }
  }, [relatedToOptions, form, relatedTo]);

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <Body>
        <InputField label="Title" name="title" placeholder="Enter invoice name" control={control} />

        <RichTextField
          label="Description"
          name="description"
          placeholder="Describe details here..."
          control={control}
        />

        <FieldsGrid>
          <DateTimeField label="Due date" name="dueDate" isOnlyDate control={control} />
          <InputField label="Amount" type="number" name="amount" placeholder="Enter amount" control={control} />
          <SelectField
            label="Related to"
            name="record"
            options={relatedToOptions}
            getOptionLabel={(option) => option.title}
            getOptionValue={(option) => option.id}
            control={control}
            isClearable={false}
          />
          <InputField
            label="Amount paid"
            type="number"
            name="amountPaid"
            placeholder="Enter amount paid"
            control={control}
          />

          <SelectField
            label="Status"
            name="status"
            options={STATUS_OPTIONS}
            getOptionLabel={(option) => option.title}
            getOptionValue={(option) => option.id}
            renderOption={(option) => <Status status={option.id} />}
            control={control}
            isClearable={false}
          />
        </FieldsGrid>
      </Body>
      <Footer>
        <Button variant={ButtonVariant.Secondary} onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={isSubmitting} variant={ButtonVariant.Primary} type="submit">
          {isNew ? 'Create' : 'Update'}
        </Button>
      </Footer>
    </Form>
  );
};
