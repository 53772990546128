import React, { useRef, useMemo, useCallback } from 'react';
import { ChevronDown, Loader } from 'react-feather';
import { InputAdornment, PaperProps } from '@material-ui/core';
import { get, noop, slice, filter } from 'lodash';
import { ListSubheader } from '@common/ui/Select/styled';
import { colors } from '@styles';
import {
  useStylesSelect,
  useStylesCheckbox,
  Select,
  MenuItem,
  Checkbox,
  Paper,
  InputWrapper,
  Input,
  MenuLoaderWrapper,
} from './styled';
import { WorkspaceOption } from './WorkspaceOption';

const NO_INPUT_MAX_ITEMS = 7;
const OPTIONS_LIMIT = 50;

const defaultGetTextForMenu = (option: any) => option;
const getOrder = ({ isCollaborator }: { isCollaborator?: boolean }) => isCollaborator ? 3 : 1;
const getOptionValue = get;
const normalizeOptions = (props: Partial<FilterSelectProps>, input: string) => {
  const { options, withTextFilter, getTextForMenu } = props;

  if (withTextFilter && !input) {
    return slice(options, 0, OPTIONS_LIMIT) || [];
  }

  if (!withTextFilter || !input || !getTextForMenu) {
    return options || [];
  }

  const normalizedInput = input.trim().toLowerCase();

  return slice(filter(options, (option) => (
    getTextForMenu(option)?.toLowerCase().includes(normalizedInput)
  )), 0, OPTIONS_LIMIT);
};

export interface FilterSelectProps {
  label?: string;
  selectedValue?: string[] | number[];
  onChange: (event: { target: { value: any } }) => void;
  getTextForMenu?: (value: any) => string;
  options: any[];
  defaultOptions?: any[];
  optionValueKey?: string;
  isWorkspaces?: boolean;
  defaultValue?: any;
  allowAll?: boolean;
  multiple?: boolean;
  style?: React.CSSProperties;
  withTextFilter?: boolean;
  onInputValueChange?: (value: string) => unknown;
  isLoading?: boolean;
  className?: string;
  grouping?: (id: K, option: T) => string | null;
}

export const FilterSelect: React.FC<FilterSelectProps> = (props) => {
  const {
    selectedValue,
    options,
    defaultOptions,
    onChange,
    optionValueKey,
    getTextForMenu = defaultGetTextForMenu,
    grouping,
    defaultValue = -1,
    allowAll,
    isWorkspaces = false,
    multiple = true,
    label = '',
    style = {},
    withTextFilter,
    onInputValueChange,
    isLoading,
    className
  } = props;

  const [filterInputValue, setFilterInputValue] = React.useState('');

  const normalizedSelectedValue = selectedValue ?? [defaultValue];

  // required to maintain "all select"
  const handleOnSelect = (e: any) => {
    const { value } = e.target;

    if (!multiple) {
      return onChange({ target: { value } });
    }

    const wasAllSelected = normalizedSelectedValue[0] === defaultValue;
    const isAllSelected = !wasAllSelected && value.indexOf(defaultValue) !== -1;

    if (!wasAllSelected && !isAllSelected) return onChange(e);
    if (wasAllSelected && !isAllSelected) {
      return onChange({
        target: { value: value.filter((v: any) => v !== defaultValue) }
      });
    }

    return onChange({ target: { value: [defaultValue] } });
  };

  const handleOnClose = () => {
    if (filterInputValue) {
      setFilterInputValue('');
    }
  };

  const classesSelect = useStylesSelect();
  const classesCheckbox = useStylesCheckbox();

  const optionsToRender = normalizeOptions({
    options,
    withTextFilter,
    getTextForMenu
  }, filterInputValue);

  const myRef = useRef();
  const handleOpen = (ref: { current: any }) => {
    setTimeout(() => {
      ref.current?.focus();
    }, 0);
  };

  const memoizedMenuProps = useMemo(() => ({
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'right'
    },
    PaperProps: {
      component: Paper,
      isWorkspaces,
    } as PaperProps
  }), [isWorkspaces]);

  const countSelected = useCallback((value: any[]) => (
    value.filter(Boolean).filter((v) => v !== defaultValue).length
  ), [defaultValue]);

  return (
    <Select
      value={normalizedSelectedValue}
      renderValue={noop}
      selectedCount={countSelected(normalizedSelectedValue)}
      onChange={handleOnSelect}
      onClose={handleOnClose}
      onOpen={() => handleOpen(myRef)}
      multiple={multiple}
      label={label}
      classes={classesSelect}
      variant="outlined"
      style={style}
      className={className}
      MenuProps={memoizedMenuProps}
      IconComponent={ChevronDown}
    >
      {(withTextFilter && (!defaultOptions || defaultOptions.length > NO_INPUT_MAX_ITEMS)) ? (
        <InputWrapper
          key="filter_input"
          onClickCapture={(event) => {
            event.stopPropagation();
            event.nativeEvent.stopImmediatePropagation();
          }}
          onKeyDown={(event) => event.stopPropagation()}
        >
          <Input
            inputRef={myRef}
            onChange={(event) => {
              const { value } = event.target;
              setFilterInputValue(value);
              onInputValueChange?.(value);
            }}
            value={filterInputValue}
            placeholder="Search by name ..."
            size="small"
            InputProps={isLoading ? {
              endAdornment: (
                <InputAdornment position="end">
                  <Loader />
                </InputAdornment>
              )
            } : undefined}
          />
        </InputWrapper>
      ) : null}

      {(allowAll && !isWorkspaces && !filterInputValue) ? (
        <MenuItem value={defaultValue} key="all">
          Any
        </MenuItem>
      ) : null}

      {optionsToRender.map((option: any) => {
        if (!option) {
          return null;
        }

        const value = getOptionValue(option, optionValueKey);
        const groupLabel = !filterInputValue && grouping && grouping(option.id, option);

        return (
          [
            groupLabel ? (
              <ListSubheader
                order={getOrder(option)}
                key={groupLabel}
              >
                {groupLabel}
              </ListSubheader>
            ) : null,
            <MenuItem
              value={value}
              key={option.id}
              order={getOrder(option)}
              onKeyDown={(event) => event.stopPropagation()}
            >
              <div>
                {isWorkspaces
                  ? <WorkspaceOption {...option} />
                  : getTextForMenu(option)}
              </div>
              <Checkbox
                classes={classesCheckbox}
                style={{ color: colors.gray }}
                checked={normalizedSelectedValue.includes(value)}
              />
            </MenuItem>
          ]
        );
      })}
      {isLoading && defaultOptions?.length === 0 ? (
        <MenuLoaderWrapper>
          <Loader />
        </MenuLoaderWrapper>
      ) : null}
    </Select>
  );
};
