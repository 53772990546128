import get from '@services/api/base/get';
import post from '@services/api/base/post';
import put from '@services/api/base/put';
import deleteReq from '@services/api/base/delete';
import { Urls } from '@services/api/base/axios';
import { RoleDTO, RoleSettingsDTO } from '@types';

export const rolesApi = {
  findAll: (companyId: number) => get<RoleDTO[]>(`${Urls.user}/roles`, { companyId }),

  get: (companyId: number, id: number) => get<RoleDTO>(`${Urls.user}/roles/${id}`, { companyId }),

  create: (companyId: number, dto: Partial<RoleDTO>) => post<RoleDTO>(`${Urls.user}/roles`, dto, { companyId }),

  remove: (companyId: number, id: number) => deleteReq<RoleDTO>(`${Urls.user}/roles/${id}`, {}, { companyId }),

  update: (companyId: number, id: number, dto: Partial<RoleDTO>) =>
    put<RoleDTO>(`${Urls.user}/roles/${id}`, dto, { companyId }),

  getAccessRules: (companyId: number) => get<RoleSettingsDTO>(`${Urls.user}/roles/rules`, { companyId }),

  getAllAccessRules: () => get<{ companyId: number; settings: RoleSettingsDTO }[]>(`${Urls.user}/roles/rules/all`)
};

export default rolesApi;
