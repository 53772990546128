import React, { useState, useCallback } from 'react';
import { ChevronDown } from 'react-feather';
import { makeStyles } from '@material-ui/core/styles';
import AutoComplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@common/ui';
import { connect, ConnectedProps } from 'react-redux';
import { has, debounce } from 'lodash';
import { autocomplete } from '@common/ui/Autocomplete/styled';
import { fetchCompanyUsers } from '@services/UserDataServices/userDataService';
import { updateUserDataAction } from '@state/actions/userData/userDataAction';
import { MemberType } from '@types';
import { getFullName } from '@utils';
import { DropDownItem } from './DropDownItem';
import UserPill from './UserPill';
import Skeleton from './Skeleton/Skeleton';
import { Pager, Member, MemberSelectorProps } from './types';

const useStyles = makeStyles(autocomplete({}));

const MemberSelector: React.FC<Props> = (props: Props) => {

  const {
    projectId,
    membersOnly,
    selectedUsers,
    setSelectedUsers,
    onFetchCompanyUsers,
    companyUsers,
    loadingCompanyUsers,
    updateUserDataActionDispatch,
    removeUserHandler,
    hidePills
  } = props;

  const [inputValue, setInputValue] = useState();
  const [isHighlightSelected, setHighlightSelected] = useState(null);

  const classes = useStyles();

  // const wrapper = useRef(null);
  // useOutsideDetector(wrapper, handleClose);

  const onFetchCompanyUsersDebounce = useCallback(
    debounce((pager: Pager, _projectId: number) => {
      onFetchCompanyUsers(pager, membersOnly, _projectId);
    }, 700),
    []
  );

  const startFetchCompanyUsers = (value: string) => {
    updateUserDataActionDispatch({ loadingCompanyUsers: true });
    onFetchCompanyUsersDebounce(
      {
        page: 0,
        limit: 10,
        skip: 0,
        name: value
      },
      projectId
    );
  };

  const dropDownFiller = (value: string) => {
    updateUserDataActionDispatch({ companyUsers: [] });
    startFetchCompanyUsers(value);
    setInputValue(value);
  };

  const handleOpen = () => {
    dropDownFiller('');
  };

  // const handleClose = () => {
  //   //setInputValue('');
  // };

  const handleInputChange = (
    event: React.ChangeEvent<{}>,
    value: string
  ) => {
    dropDownFiller(value);
  };

  const handleFocus = (event: React.FocusEvent<{}>) => {
    //dropDownFiller('');
  };

  const selectUserOption = (option: Member) => {
    const copyUsers = selectedUsers.filter((user) => user.id !== option.id);
    copyUsers.push({ ...option, memberType: MemberType.WORKER });
    setSelectedUsers(copyUsers);
  };

  const handleGetOptionLabel = (option) => {
    if (has(option, 'firstName')) {
      return getFullName(option);
    }

    return option.name;
  };

  return (
    <AutoComplete
      multiple
      onHighlightChange={(event, option) => {
        setHighlightSelected(option);
      }}
      openOnFocus
      renderTags={(tags) => {
        if (hidePills) {
          return [];
        }
        return tags.map((tag, idx) => (
          <UserPill
            key={`user-pill-key-${idx}`}
            id={tag.id}
            avatarUrl={tag.avatarUrl}
            name={handleGetOptionLabel(tag)}
            removeUserHandler={removeUserHandler}
          />
        ));
      }}
      onKeyDown={(event) => {
        if (event.keyCode === 13 && isHighlightSelected !== null) {
          selectUserOption(isHighlightSelected);
        }
      }}
      classes={classes}
      onOpen={handleOpen}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      // onClose={handleClose}
      onFocus={handleFocus}
      options={companyUsers as Member[]}
      value={selectedUsers as Member[]}
      getOptionSelected={(option: Member, value: Member) => (
        membersOnly ? option.member?.id === value.member?.id : option.id === value.id
      )}
      getOptionLabel={(option) => handleGetOptionLabel(option)}
      loading={loadingCompanyUsers}
      loadingText={<Skeleton />}
      noOptionsText={'User not found'}
      renderOption={(option) => (
        <DropDownItem
          avatarUrl={option.avatarUrl}
          name={handleGetOptionLabel(option)}
          onClick={() => selectUserOption(option)}
          data-testid="userSelectOption"
        />
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          name="assignees"
          type="text"
          variant="outlined"
          data-testid="userSelectInput"
        />
      )}
      popupIcon={<ChevronDown />}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    companyUsers: state.userData.companyUsers,

    totalCompanyUsers: state.userData.totalCompanyUsers,

    loadingCompanyUsers: state.userData.loadingCompanyUsers,

    projectMembersList: state.project.projectMembers
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchCompanyUsers: (pager: Pager, membersOnly, projectId: number) =>
      dispatch(fetchCompanyUsers(pager, membersOnly, projectId)),
    updateUserDataActionDispatch: (payload) =>
      dispatch(updateUserDataAction(payload))
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & MemberSelectorProps;

export default connector(MemberSelector);
