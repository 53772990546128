import styled from 'styled-components';

export const Option = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: start;

  strong {
    font-weight: 500;
  }
  small {
    font-weight: 300;
  }
`;
