import { useMemo } from 'react';
import { AnalyticsModule, ShowFilterType } from '../types';
import { MODULE_OPTIONS } from '../constants';

export const useShowFilterOptions = (module: AnalyticsModule): { value: ShowFilterType; label: string }[] => {
  return useMemo(() => {
    const label = MODULE_OPTIONS.find((option) => option.value === module)?.label;

    return [
      {
        value: ShowFilterType.ACTIVE,
        label: `Not archived ${label}`
      },
      {
        value: ShowFilterType.ARCHIVED,
        label: `Archived ${label}`
      },
      {
        value: ShowFilterType.ALL,
        label: `All ${label}`
      }
    ];
  }, [module]);
};
