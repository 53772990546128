import { UserRole, UserCompaniesEntity, User, UserFromApi } from '@state/reducers/authentication/types';
import UserPreferences from '@services/UserPreferences';
import { getMainRole } from '../../utils/utils';

export const userDataAdapterFromService = (data: UserFromApi) => {
  const userData = {
    id: data.id,
    email: data.email,
    username: `${data.firstName} ${data.lastName}`,
    profileImgUrl: data.avatarUrl
  };

  return userData;
};

export const userResponseToAuthUser = (user: UserFromApi): { user: User; companies: UserCompaniesEntity[] } => {
  const fallbackCompany = user.companies[0];

  const companyIdFromUrl = window.location.pathname.match(/^\/(\d+)\/.*/)?.[1];
  const companyIdFromPrefs = UserPreferences.selectedCompanyId.get();

  const companyFromUrl = user.companies.find((c) => c.id === +companyIdFromUrl);
  const companyFromPrefs = user.companies.find((c) => c.id === companyIdFromPrefs);

  const mainCompany = companyFromUrl || companyFromPrefs || fallbackCompany;

  if (mainCompany.id !== companyFromPrefs?.id) {
    UserPreferences.selectedCompanyId.set(mainCompany.id);
  }

  const userData = {
    userId: user.id,
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    name: `${user.firstName} ${user.lastName}`,
    email: user.email,
    avatarUrl: user.avatarUrl,
    roles: mainCompany.roles,
    company: mainCompany,
    companies: user.companies
  };

  const companiesData = user.companies.map((c) => ({
    ...c,
    role: getMainRole(mainCompany.roles) as UserRole
  }));

  return { user: userData, companies: companiesData };
};
