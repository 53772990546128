import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ChevronsUpIcon } from '@kit/ui/icons/ChevronsUp';
import { ChevronsDownIcon } from '@kit/ui/icons/ChevronsDown';
import { convertTextToQuillFormat } from '@utils/quill';
import { CommentCreatedBy } from '@generated/types/graphql';
import { User } from 'react-feather';
import { PortalIcon } from '@kit/ui/icons/Portal';
import { BaseItemTemplate } from './BaseItemTemplate';
import { FeedItem } from './types';
import { CommentTitle, ItemActionButton, FeedItemIconContainer, TruncatedComment } from './styled';

interface Props {
  item: FeedItem;
  context: 'project' | 'client' | 'workOrder' | 'file';
}

const isTextClamped = (element: HTMLDivElement) => element.scrollHeight > element.clientHeight;

export const PortalMessageFeedItem = ({ item, context }: Props) => {
  const [isMoreShown, setIsMoreShown] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = useCallback(() => {
    setIsExpanded((prev) => !prev);
  }, []);

  const nodeRef = useRef();

  useEffect(() => {
    if (nodeRef.current) {
      setIsMoreShown(isTextClamped(nodeRef.current));
    }
  }, []);

  const author =
    item.relatedComment.createdByType === CommentCreatedBy.User
      ? item.createdBy
      : { name: `${item.relatedComment.createdByContact?.name}` };

  const userName = author ? author.name || [author.firstName, author.lastName].join(' ') : 'User';

  const isAuthorClient = item.relatedComment.createdByType === CommentCreatedBy.Contact;

  return (
    <BaseItemTemplate
      context={context}
      id={item.id}
      icon={
        <FeedItemIconContainer color="#FFBD13">
          <PortalIcon color="#FFFFFF" size="16px" />
        </FeedItemIconContainer>
      }
      author={author}
      authorIcon={
        isAuthorClient ? (
          <FeedItemIconContainer color="#F7F7FC">
            <User color="#9c9caa" size="16px" />
          </FeedItemIconContainer>
        ) : undefined
      }
      date={item.createdAt}
      title={
        <CommentTitle>
          <div>
            {!isAuthorClient && (
              <>
                <b>{userName}</b> sent message to Portal
              </>
            )}
            {isAuthorClient && (
              <>
                <b>{userName}</b> sent message from Portal
              </>
            )}
          </div>
          <div>
            {isMoreShown && (
              <ItemActionButton onClick={toggleExpanded}>
                {isExpanded ? <ChevronsUpIcon size="16px" /> : <ChevronsDownIcon size="16px" />}
              </ItemActionButton>
            )}
          </div>
        </CommentTitle>
      }
    >
      <div>
        {isMoreShown && !isExpanded && (
          <TruncatedComment ref={nodeRef}>
            <div dangerouslySetInnerHTML={{ __html: convertTextToQuillFormat(item.relatedComment?.comment) }} />
          </TruncatedComment>
        )}

        {(!isMoreShown || isExpanded) && (
          <div dangerouslySetInnerHTML={{ __html: convertTextToQuillFormat(item.relatedComment?.comment) }} />
        )}
      </div>
    </BaseItemTemplate>
  );
};
