import React from 'react';
import { buttonType } from '@constants/ButtonType';

import styled from 'styled-components';
import { Button, ButtonVariant } from '@kit/ui/Button';

const Wrapper = styled.div<{ isInDrawer: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.isInDrawer ? 'flex-start' : 'flex-end')};

  width: 100%;

  button {
    margin-left: 10px;
  }
`;

/* TODO */
type BoxSubmitType = {
  onClose?: () => void;
  onSubmit?: () => void;
  disabled?: boolean;
  primaryBtnText?: string;
  secondaryBtnText?: string;
  primaryBtnType?: buttonType;
  secondaryBtnType?: buttonType;
  style?: CSSStyleDeclaration;
  isInDrawer?: boolean;
};

const BoxSubmit: React.FC<BoxSubmitType> = (props: BoxSubmitType): JSX.Element => {
  const {
    onClose,
    onSubmit,
    disabled,
    primaryBtnText = 'SAVE',
    secondaryBtnText = 'CANCEL',
    primaryBtnType = buttonType.SUBMIT,
    secondaryBtnType = buttonType.BUTTON,
    style,
    isInDrawer
  } = props;

  return (
    <Wrapper isInDrawer={isInDrawer} style={style}>
      <Button type={secondaryBtnType} variant={ButtonVariant.Secondary} onClick={onClose}>
        {secondaryBtnText}
      </Button>
      <Button type={primaryBtnType} variant={ButtonVariant.Primary} disabled={disabled} onClick={onSubmit} >
        {primaryBtnText}
      </Button>
    </Wrapper>
  );
};

export default BoxSubmit;
