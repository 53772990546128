import React from 'react';
import { Box } from '@material-ui/core';
import styled from 'styled-components';
import { device } from '../../../../styles';

const Wrapper = styled(Box)<{half: boolean}>`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  ${device.sm`
    grid-template-columns: ${(props: { half?: boolean }) => (props.half ? '1fr 1fr' : '1fr')};;
    gap: 30px;
    margin-bottom: 30px;
  `};
`;

type BoxRow = {
  children?: React.ReactNode;
  half?: boolean;
};

const BoxRow: React.FC<BoxRow> = (props: BoxRow) => {
  const { children, half = false } = props;
  return <Wrapper half={half}>{children}</Wrapper>;
};

export default BoxRow;
