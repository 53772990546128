import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Calendar, Clock, Edit3, Plus, Trash2, User } from 'react-feather';
import { useConfirmDeleteModal, useModal } from '@common/PromiseModal';
import { useUserRoleSettings } from '@hooks/useRoles';
import { selectCurrentUserId, selectWorkspaceId } from '@state/selectors';
import { useAppSelector } from '@hooks/store';
import { hasAccess, hasEntityAccessNew } from '@utils/roles';
import { useReminders, useRemindersMutations } from '@hooks/useReminders';
import { REMINDER_TYPE_TO_ICON } from '@common/Reminder/ReminderTypeSelector';
import { ContextMenu, MenuItem } from '@kit/components/ContextMenu';
import { MoreVerticalIcon } from '@kit/ui/icons/MoreVertical';
import { parseUtcDate } from '@utils/dates';
import moment from 'moment';
import { Reminder as TReminder } from '@generated/types/graphql';
import { Switch } from '@kit/ui/Switch';
import { QueryParamsEnum, useQueryParam } from '@hooks/useQueryParam';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { UserAvatar } from '@kit/components/UserAvatar';
import { ReminderView } from '@features/Work/ReminderView';
import { Row, Meta, Assignee, AvatarPlaceholder, Title, List, EmptyText } from './styled';
import { ReminderForm } from '../../CreateEventsPanel/forms/ReminderForm';
import { CreateWork, Widget, WidgetHeader, WidgetIcon, WidgetTitle } from '../styled';

interface Props {
  projectId: number;
}

const formatDate = (date: string) => {
  const format = 'MM/DD/YY h:mma';

  return moment(parseUtcDate(date)).format(format).replace(':00', '');
};

const Reminder = ({ reminder, projectId }: { reminder: TReminder; projectId: number }) => {
  const companyId = useAppSelector(selectWorkspaceId);

  const {
    deleteMutation: { mutate: deleteReminder }
  } = useRemindersMutations(companyId);

  const Icon = REMINDER_TYPE_TO_ICON[reminder.type];

  const canEdit = hasEntityAccessNew(reminder, 'edit');
  const canRemove = hasEntityAccessNew(reminder, 'delete');

  const [reminderFromUrlId, setReminderUrlId] = useQueryParam(QueryParamsEnum.ReminderId);

  const confirmDelete = useConfirmDeleteModal();

  const handleEdit = useCallback(() => {
    setReminderUrlId(reminder.id.toString());
  }, [reminder.id, setReminderUrlId]);

  const menuItems = useMemo<MenuItem[]>(
    () =>
      [
        canEdit && {
          icon: <Edit3 size="16px" color="#98A9BC" />,
          title: 'Edit',
          onClick: () => {
            handleEdit();
          }
        },
        canRemove && {
          icon: <Trash2 color="#D54855" size="16px" />,
          title: 'Delete',
          onClick: async () => {
            if (await confirmDelete('Are you sure you want to delete this Reminder?')) {
              deleteReminder({ id: reminder.id, recordId: projectId });
            }
          }
        }
      ].filter(Boolean),
    [canEdit, canRemove, reminder, projectId, confirmDelete, deleteReminder, handleEdit]
  );

  return (
    <Row isHighlighted={reminder.id.toString() === reminderFromUrlId} onClick={handleEdit}>
      <Assignee>
        {reminder.assignee && <UserAvatar user={reminder.assignee} />}

        {!reminder.assignee && (
          <AvatarPlaceholder>
            <User size="16px" />
          </AvatarPlaceholder>
        )}
      </Assignee>
      <Icon size="16px" color="#9C9CAA" />
      <Title isCompleted={reminder.isCompleted}>{reminder.title}</Title>
      <Meta>
        <Calendar size="12px" color="#9C9CAA" />
        {formatDate(reminder.dueDate)}
      </Meta>

      <div
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        {menuItems.length > 0 && (
          <ContextMenu items={menuItems}>
            <MoreVerticalIcon size="16px" color="#9C9CAA" />
          </ContextMenu>
        )}
      </div>
    </Row>
  );
};

export const Reminders = ({ projectId }: Props) => {
  const { openModal } = useModal();

  const isReminderFromUrlShown = useRef(false);

  const [isCompletedShown, setIsCompletedShown] = useState(false);

  const companyId = useAppSelector(selectWorkspaceId);
  const userId = useAppSelector(selectCurrentUserId);

  const { data: access } = useUserRoleSettings(companyId, userId);

  const [reminderFromUrlId, setReminderUrlId] = useQueryParam(QueryParamsEnum.ReminderId);

  const { data: reminders = [] } = useReminders(companyId, projectId);

  const toggleCompleted = useCallback(() => {
    setIsCompletedShown((prev) => !prev);
  }, []);
  const notCompletedReminders = useMemo(() => reminders.filter(({ isCompleted }) => !isCompleted), [reminders]);

  const remindersToShow = isCompletedShown ? reminders : notCompletedReminders;

  const handleCreateReminderClick = useCallback(() => {
    openModal<void>(({ onClose }) => <ReminderForm recordId={projectId} onClose={onClose} />, {
      title: 'Create Reminder'
    });
  }, [openModal, projectId]);

  useEffect(() => {
    if (!reminderFromUrlId || !reminders?.length || isReminderFromUrlShown.current) {
      return;
    }

    const reminderFromUrl = reminders.find(({ id }) => id === +reminderFromUrlId);

    if (reminderFromUrl) {
      isReminderFromUrlShown.current = true;
      openModal<void>(({ onClose }) => <ReminderView initialValues={reminderFromUrl} onClose={onClose} />, {
        isHeaderShown: false
      }).then(() => {
        setReminderUrlId(undefined);
        isReminderFromUrlShown.current = false;
      });
    }
  }, [reminders, reminderFromUrlId, openModal, projectId, setReminderUrlId]);

  return (
    <Widget isFullWidth size="large">
      <WidgetHeader>
        <WidgetTitle>
          <WidgetIcon backgroundColor="#CDF3DF">
            <Clock size="16px" color="#009A47" />
          </WidgetIcon>
          Reminders
          {hasAccess(access, 'task', 'create') && (
            <CreateWork>
              <Button onClick={handleCreateReminderClick} variant={ButtonVariant.Secondary}>
                <Plus size="16px" />
                Reminder
              </Button>
            </CreateWork>
          )}
        </WidgetTitle>

        <Switch
          labelPosition="left"
          isActive={isCompletedShown}
          id="show_completed_reminders"
          label="Show completed"
          onChange={toggleCompleted}
        />
      </WidgetHeader>

      {reminders.length === 0 && <EmptyText>No reminders yet.</EmptyText>}

      {reminders.length > 0 && !isCompletedShown && notCompletedReminders.length === 0 && (
        <EmptyText>No planned reminders yet.</EmptyText>
      )}

      <List>
        {remindersToShow.length > 0 &&
          remindersToShow.map((reminder) => <Reminder key={reminder.id} reminder={reminder} projectId={projectId} />)}
      </List>
    </Widget>
  );
};
