import React from 'react';

export const FileUpIcon = ({ size = '16px', color = 'currentColor' }: { size?: string; color?: string }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.87868 1.87868C4.44129 1.31607 5.20435 1 6 1H15C15.2652 1 15.5196 1.10536 15.7071 1.29289L20.7071 6.29289C20.8946 6.48043 21 6.73478 21 7V20C21 20.7957 20.6839 21.5587 20.1213 22.1213C19.5587 22.6839 18.7957 23 18 23H6C5.20435 23 4.44129 22.6839 3.87868 22.1213C3.31607 21.5587 3 20.7957 3 20V4C3 3.20435 3.31607 2.44129 3.87868 1.87868ZM6 3C5.73478 3 5.48043 3.10536 5.29289 3.29289C5.10536 3.48043 5 3.73478 5 4V20C5 20.2652 5.10536 20.5196 5.29289 20.7071C5.48043 20.8946 5.73478 21 6 21H18C18.2652 21 18.5196 20.8946 18.7071 20.7071C18.8946 20.5196 19 20.2652 19 20V7.41421L14.5858 3H6Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 1C14.5523 1 15 1.44772 15 2V6C15 6.26522 15.1054 6.51957 15.2929 6.70711C15.4804 6.89464 15.7348 7 16 7H20C20.5523 7 21 7.44772 21 8C21 8.55228 20.5523 9 20 9H16C15.2044 9 14.4413 8.68393 13.8787 8.12132C13.3161 7.55871 13 6.79565 13 6V2C13 1.44772 13.4477 1 14 1ZM11.2929 11.2929C11.6834 10.9024 12.3166 10.9024 12.7071 11.2929L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L13 14.4142V18C13 18.5523 12.5523 19 12 19C11.4477 19 11 18.5523 11 18V14.4142L9.70711 15.7071C9.31658 16.0976 8.68342 16.0976 8.29289 15.7071C7.90237 15.3166 7.90237 14.6834 8.29289 14.2929L11.2929 11.2929Z"
        fill={color}
      />
    </svg>
  );
};
