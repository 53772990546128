import React from 'react';
import { BarChart as RechartsBarChart, ResponsiveContainer, Bar, XAxis } from 'recharts';

import { NoDataContainer, NoDataText } from './styled';

const POINTS = [
  {
    x: 1,
    y: 30
  },
  {
    x: 2,
    y: 30
  },
  {
    x: 3,
    y: 50
  },
  {
    x: 4,
    y: 50
  },
  {
    x: 5,
    y: 65
  },
  {
    x: 6,
    y: 15
  },
  {
    x: 7,
    y: 85
  },
  {
    x: 8,
    y: 65
  },
  {
    x: 9,
    y: 65
  },
  {
    x: 10,
    y: 15
  },
  {
    x: 11,
    y: 65
  },
  {
    x: 12,
    y: 65
  }
];

interface Props {
  isXAxisReversed?: boolean;
}

export const NoData = ({ isXAxisReversed }: Props) => {
  return (
    <NoDataContainer>
      <ResponsiveContainer width="100%" height="100%">
        <RechartsBarChart
          width={500}
          height={300}
          layout="vertical"
          data={POINTS}
          margin={{
            top: 16,
            right: 16,
            left: 16,
            bottom: 16
          }}
        >
          <Bar isAnimationActive={false} maxBarSize={16} dataKey="y" fill="#F7F7FC" />
          <XAxis
            fontSize={12}
            stroke="#828D9A"
            axisLine={false}
            tickLine={false}
            tick={false}
            type="number"
            dataKey="y"
            allowDecimals={false}
            reversed={isXAxisReversed}
          />
        </RechartsBarChart>
      </ResponsiveContainer>

      <NoDataText>No data to visualize yet</NoDataText>
    </NoDataContainer>
  );
};
