const scroll = `
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 4px;
  }
  
  &::-webkit-scrollbar-track {
    background-color: #dfdfe8;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: #9c9caa;
    outline: none;
    border: 1px solid transparent;
    background-clip: content-box;
    border-radius: 4px;
  }
`;

export default scroll;
