import React from 'react';

export const ExportIcon = ({ size = '16px', color = 'currentColor' }: { size?: string | number; color?: string }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.586 2C14.0556 2.00011 14.5101 2.16543 14.87 2.467L15 2.586L19.414 7C19.746 7.33202 19.9506 7.77028 19.992 8.238L20 8.414V12H18V10H13.5C13.1271 9.99998 12.7676 9.86108 12.4916 9.61038C12.2156 9.35968 12.0428 9.01516 12.007 8.644L12 8.5V4H6V20H12V22H6C5.49542 22.0002 5.00943 21.8096 4.63945 21.4665C4.26947 21.1234 4.04284 20.6532 4.005 20.15L4 20V4C3.99984 3.49542 4.19041 3.00943 4.5335 2.63945C4.87659 2.26947 5.34684 2.04284 5.85 2.005L6 2H13.586ZM18.707 14.464L21.535 17.293C21.7225 17.4805 21.8278 17.7348 21.8278 18C21.8278 18.2652 21.7225 18.5195 21.535 18.707L18.707 21.535C18.6148 21.6305 18.5044 21.7067 18.3824 21.7591C18.2604 21.8115 18.1292 21.8391 17.9964 21.8403C17.8636 21.8414 17.7319 21.8161 17.609 21.7658C17.4862 21.7155 17.3745 21.6413 17.2806 21.5474C17.1867 21.4535 17.1125 21.3419 17.0622 21.219C17.0119 21.0961 16.9866 20.9644 16.9877 20.8316C16.9889 20.6988 17.0165 20.5676 17.0689 20.4456C17.1213 20.3236 17.1975 20.2132 17.293 20.121L18.414 19H14C13.7348 19 13.4804 18.8946 13.2929 18.7071C13.1054 18.5196 13 18.2652 13 18C13 17.7348 13.1054 17.4804 13.2929 17.2929C13.4804 17.1054 13.7348 17 14 17H18.414L17.293 15.879C17.1054 15.6915 16.9999 15.4371 16.9998 15.1719C16.9997 14.9066 17.105 14.6521 17.2925 14.4645C17.48 14.2769 17.7344 14.1714 17.9996 14.1713C18.2649 14.1712 18.5194 14.2765 18.707 14.464ZM14 4.414V8H17.586L14 4.414Z"
      fill={color}
    />
  </svg>
);
