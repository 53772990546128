import React, { useCallback, useMemo } from 'react';
import { Bar, LabelList, YAxis } from 'recharts';
import { ProjectStage } from '@generated/types/graphql';
import { Point } from '../types';
import { Container, RightAxisTitles } from './styled';
import { ChartBase } from './ChartBase';
import { percentTickFormatter } from './helpers';
import { StageTick } from './StageTick';
import { NoData } from './NoData';

interface Props {
  points: {
    id: number;
    name: string;
    value: number;
    stage: ProjectStage;
    percentFromPrev: number;
    percentFromStart: number;
  }[];
  onClick: (point: Point) => void;
}

export const FunnelByStage = ({ points, onClick }: Props) => {
  const stagesMap = useMemo(
    () =>
      points.reduce(
        (acc, point) => {
          acc[point.name] = point.stage;

          return acc;
        },
        {} as Record<number, ProjectStage>
      ),
    [points]
  );

  const handleBarClick = useCallback(
    (data: any) => {
      const point = data.payload;

      onClick({
        x: point.name,
        originalX: point.id,
        isEmpty: false,
        y: point.value
      });
    },
    [onClick]
  );

  if (!points.length) {
    return <NoData isXAxisReversed />;
  }

  return (
    <Container>
      <RightAxisTitles>
        <div>Next stage conversion</div>
        <div>Cumulative conversion</div>
      </RightAxisTitles>
      <ChartBase points={points} isXAxisReversed>
        <YAxis
          width={150}
          axisLine={false}
          tickLine={false}
          orientation="left"
          tick={<StageTick stages={stagesMap} />}
          dataKey="name"
          type="category"
          interval={0}
        />
        <YAxis
          width={70}
          stroke="#1D1D35"
          tickFormatter={percentTickFormatter}
          yAxisId="average"
          axisLine={false}
          tickLine={false}
          orientation="right"
          dataKey="percentFromPrev"
          type="category"
          fontSize={12}
          interval={0}
        />
        <YAxis
          width={80}
          yAxisId="accumSum"
          axisLine={false}
          tickLine={false}
          stroke="#828D9A"
          tickFormatter={percentTickFormatter}
          orientation="right"
          dataKey="percentFromStart"
          type="category"
          fontSize={12}
          interval={0}
        />
        <Bar name="Amount" stackId="a" maxBarSize={16} dataKey="value" fill="#71C4C4" onClick={handleBarClick}>
          <LabelList style={{ pointerEvents: 'none' }} fontSize={12} fill="#fff" dataKey="value" position="inside" />
        </Bar>
      </ChartBase>
    </Container>
  );
};
