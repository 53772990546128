import React from 'react';
import { TableContainerProps } from '@material-ui/core';
import { useStylesTableContainer, TableContainer as TableContainerMui } from './styled';

export const TableContainer: React.FC<TableContainerProps> = (props) => {
  const classesTableContainer = useStylesTableContainer();

  return (
    <TableContainerMui
      classes={classesTableContainer}
      {...props}
    />
  );
};
