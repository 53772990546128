import { Action, ActionStatus, File } from '@generated/types/graphql';
import { useActionItem } from '@hooks/actionItems/useActionItem';
import React, { useCallback, useMemo } from 'react';

import {
  FileDropZoneField,
  Form,
  InlineEditableDateTimeField,
  InlineEditableInputField,
  InlineEditableSelectField,
  InlineEditableTextareaField,
  useForm,
  FormValidationRules
} from '@kit/components/Form';
import { FileUpIcon } from '@kit/ui/icons/FileUp';
import { CalendarIcon } from '@kit/ui/icons/Calendar';
import moment from 'moment';
import { ContactAvatar } from '@kit/components/ContactAvatar';
import { useUpdateActionItem } from '@hooks/actionItems/useUpdateActionItem';
import { ContextMenu, MenuItem } from '@kit/components/ContextMenu';
import { MoreVerticalIcon } from '@kit/ui/icons/MoreVertical';
import { Trash2 } from 'react-feather';
import { useDeleteActionItem } from '@hooks/actionItems/useDeleteActionItem';
import { useUploadActionItemDoc } from '@hooks/actionItems/useUploadActionItemDoc';

import { useConfirmDeleteModal } from '@common/PromiseModal';
import { isEqual } from 'lodash';
import { DrawerEntity, useDrawersContext } from '@contexts/DrawersContext';

import { Badge } from '@kit/ui/Badge';
import {
  Container,
  Header,
  Content,
  TitleInputWrapper,
  TitleView,
  FieldBlock,
  FieldLabel,
  SelectValueView,
  DescriptionWrapper,
  DescriptionView,
  Separator,
  PlaceholderValue,
  AvatarAndName,
  AssigneeValueView
} from './styled';
import { StatusButton } from './StatusButton';
import { Tracking } from './Tracking';

const formatDate = (date: Date) => moment(date).format('MM/DD/YY');

const TYPE_OPTIONS = [{ id: 'DOC_UPLOAD', label: 'Document upload', icon: <FileUpIcon size="16px" color="#9C9CAA" /> }];

type FormValues = {
  title: string;
  type: { id: string; label: string; icon: React.ReactNode };
  dueDate: Date | null;
  description: string;
  assignees: { id: string; name: string }[];
  files: File[];
  stage: { id: number; name: string; type: string } | null;
};

interface Props {
  initialValues: Action;
}

const RULES: FormValidationRules<FormValues> = {
  title: {
    isRequired: true
  }
};

const ActionItemForm = ({ initialValues }: Props) => {
  const contactOptions = initialValues.project.projectContacts;

  const { mutateAsync: update } = useUpdateActionItem();
  const { mutateAsync: remove } = useDeleteActionItem();
  const { mutateAsync: uploadFile } = useUploadActionItemDoc();
  const confirmDelete = useConfirmDeleteModal();

  const { openDrawer, closeDrawer } = useDrawersContext();

  const menuItems = useMemo<MenuItem[]>(() => {
    return [
      {
        title: 'Delete forever',
        icon: <Trash2 size="16px" color="#D54855" />,
        onClick: async () => {
          if (await confirmDelete(`Are you sure you want to delete this Action Item?`)) {
            await remove({ id: initialValues.id });
          }

          closeDrawer();
        }
      }
    ];
  }, [initialValues, confirmDelete, remove, closeDrawer]);

  const postForm = async (values: FormValues) => {
    const previousVersionDto = {
      title: initialValues.title,
      description: initialValues.description,
      assigneeContactIds: initialValues.actionAssignees.map((actionAssignee) => actionAssignee.contact.id),
      dueDate: initialValues.dueDate ? new Date(initialValues.dueDate).toISOString() : null,
      fileIds: initialValues.files.map((file) => file.id)
    };

    const currentVersionDto = {
      title: values.title || initialValues.title,
      description: values.description,
      assigneeContactIds: values.assignees.map((assignee) => assignee.id),
      dueDate: values.dueDate ? values.dueDate.toISOString() : null,
      fileIds: values.files.map((file) => file.id)
    };

    if (isEqual(previousVersionDto, currentVersionDto)) {
      return;
    }

    await update({
      id: initialValues.id,
      dto: currentVersionDto
    });
  };

  const { handleSubmit, form } = useForm({
    onSubmit: postForm,
    defaultValues: {
      title: initialValues.title,
      type: TYPE_OPTIONS.find((type) => type.id === initialValues.type) || TYPE_OPTIONS[0],
      dueDate: initialValues.dueDate ? new Date(initialValues.dueDate) : null,
      assignees: initialValues.actionAssignees.map((actionAssignee) => actionAssignee.contact),
      description: initialValues.description,
      files: initialValues.files,
      stage: initialValues.projectStage
    }
  });

  const { control, setValue } = form;

  const triggerSubmit = () => {
    handleSubmit();
  };

  const handleDescriptionUpdated = useCallback(
    (description: string) => {
      setValue('description', description);
    },
    [setValue]
  );

  const handleFileClick = useCallback(
    (fileId: number, fileIds: number[]) => {
      openDrawer(DrawerEntity.DOC, fileId, fileIds);
    },
    [openDrawer]
  );

  const handleFileUpload = useCallback(
    async (file: File) => {
      return uploadFile({ actionId: initialValues.id, file });
    },
    [uploadFile, initialValues.id]
  );

  const isCompleted = initialValues.status === ActionStatus.Completed;

  return (
    <Container>
      <Form rules={RULES} onSubmit={handleSubmit}>
        <Header>
          <StatusButton
            onDescriptionUpdated={handleDescriptionUpdated}
            action={initialValues}
            status={initialValues.status}
          />

          <ContextMenu items={menuItems}>
            <MoreVerticalIcon size="20px" color="#9C9CAA" />
          </ContextMenu>
        </Header>

        <Content>
          <TitleInputWrapper>
            <InlineEditableInputField
              name="title"
              control={control}
              placeholder="Type here a template name"
              onChanged={triggerSubmit}
              renderView={(value, onClick) => (
                <TitleView isDisabled={isCompleted} isPlaceholder={!value} onClick={isCompleted ? undefined : onClick}>
                  {value || 'Type here a template name'}
                </TitleView>
              )}
            />
          </TitleInputWrapper>

          <FieldBlock isCentered>
            <FieldLabel>Type</FieldLabel>
            <InlineEditableSelectField
              name="type"
              control={control}
              disabled
              options={[]}
              renderView={(value) =>
                value ? (
                  <SelectValueView isDisabled>
                    {value.icon}
                    {value.label}
                  </SelectValueView>
                ) : (
                  'Select type'
                )
              }
            />
          </FieldBlock>

          <FieldBlock isCentered>
            <FieldLabel>Project Stage</FieldLabel>
            <InlineEditableSelectField
              name="stage"
              control={control}
              disabled
              options={[]}
              renderView={(value) =>
                value ? (
                  <SelectValueView isDisabled>
                    <Badge maxWidth="200px" color="#1D1D35" bgColor="#DFDFE8">
                      <span>{value.name}</span>
                    </Badge>
                  </SelectValueView>
                ) : null
              }
            />
          </FieldBlock>

          <FieldBlock isCentered>
            <FieldLabel>Due date</FieldLabel>
            <InlineEditableDateTimeField
              name="dueDate"
              control={control}
              isOnlyDate
              isClearable
              onChanged={triggerSubmit}
              placeholder="No date"
              renderView={(value, onClick) =>
                value ? (
                  <SelectValueView isDisabled={isCompleted} onClick={isCompleted ? undefined : onClick}>
                    <CalendarIcon size="16px" color="#9C9CAA" />
                    {formatDate(value)}
                  </SelectValueView>
                ) : (
                  <SelectValueView isDisabled={isCompleted} onClick={isCompleted ? undefined : onClick}>
                    <CalendarIcon size="16px" color="#9C9CAA" />
                    <PlaceholderValue>No date</PlaceholderValue>
                  </SelectValueView>
                )
              }
            />
          </FieldBlock>

          <FieldBlock isCentered>
            <FieldLabel>Assignee</FieldLabel>
            <InlineEditableSelectField
              name="assignees"
              isClearable={false}
              control={control}
              getOptionLabel={(option) => option.name}
              isMulti
              onChanged={triggerSubmit}
              options={contactOptions}
              getOptionSelected={(option, value) => option.id === value.id}
              renderOption={(option) => (
                <AvatarAndName>
                  <ContactAvatar contact={option} size={24} />
                  {option.name}
                </AvatarAndName>
              )}
              renderView={(value, onClick) =>
                value?.length ? (
                  <AssigneeValueView isDisabled={isCompleted} onClick={isCompleted ? undefined : onClick}>
                    {value.map((contact) => (
                      <AvatarAndName key={contact.id}>
                        <ContactAvatar contact={contact} size={24} />
                        {contact.name}
                      </AvatarAndName>
                    ))}
                  </AssigneeValueView>
                ) : (
                  <SelectValueView isDisabled={isCompleted} onClick={isCompleted ? undefined : onClick}>
                    <PlaceholderValue>Unassigned</PlaceholderValue>
                  </SelectValueView>
                )
              }
            />
          </FieldBlock>

          <DescriptionWrapper>
            <InlineEditableTextareaField
              placeholder="Add here a description for this document upload"
              label="Description"
              name="description"
              control={control}
              onChanged={triggerSubmit}
              renderView={(value, onClick) => (
                <DescriptionView
                  isDisabled={isCompleted}
                  onClick={isCompleted ? undefined : onClick}
                  isPlaceholder={!value}
                >
                  {value || 'Add here a description for this document upload'}
                </DescriptionView>
              )}
            />
          </DescriptionWrapper>

          <Separator />

          <FileDropZoneField
            name="files"
            label="After files are submitted, you’ll see them here. You can also upload a file and complete the document upload if needed."
            control={control}
            isDisabled={isCompleted}
            onFileClick={handleFileClick}
            onUpload={handleFileUpload}
            onRemoved={triggerSubmit}
          />

          <Separator />

          <Tracking action={initialValues} />
        </Content>
      </Form>
    </Container>
  );
};

export const ActionItemViewContainer = ({ id }: { id: number }) => {
  const { data: actionItem, isLoading } = useActionItem(id);

  if (isLoading || !actionItem) {
    return null;
  }

  return <ActionItemForm key={id} initialValues={actionItem} />;
};
