import { makeStyles } from '@material-ui/core/styles';
import { defaultTheme } from '@themes';

const tableRow = () => {
  return {
    root: {
      backgroundColor: defaultTheme.colors.white,
      '&:hover, &:focus': {
        backgroundColor: defaultTheme.colors.white2,
        '& td': {
          backgroundColor: `${defaultTheme.colors.white2} !important`,
        }
      }
    },
    selected: {},
    hover: {},
    head: {},
    footer: {}
  };
};

export const useStylesTableRow = makeStyles(tableRow());
