import React from 'react';
import { ProjectEventType } from '@enums';
import { CommentItem } from './CommentItem';
import { FeedItemProps } from './types';

export const SystemFeedItem = ({ item, context, contextEntityId }: FeedItemProps) => {
  if (item.eventType === ProjectEventType.COMMENTED) {
    return <CommentItem item={item} context={context} contextEntityId={contextEntityId} />;
  }

  return null;
};
