import { DependencyList } from 'react';
import { useDeepCompareMemo } from '@react-hookz/web';
import { useDebouncedState } from './useDebouncedState';

export const useDebouncedMemo = <T>(
  factory: () => T,
  deps: DependencyList | undefined,
  delay: number,
  initial?: T
): T => {
  const [, debouncedState, setState] = useDebouncedState(initial ?? factory(), delay);

  useDeepCompareMemo(() => {
    setState(factory());
  }, deps);

  return debouncedState;
};
