import { scroll, device, colors } from '@styles';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  background-color: white;
  border-radius: 8px;
`;

export const Chips = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;

  .infoChipLabel {
    font-weight: 500;
  }

  .infoChipCurrencySymbol {
    color: #009688;
  }
`;

export const EditableChipPlaceholder = styled.span`
  color: #235dff;
  font-weight: 400;
`;

export const InfoChip = styled.div`
  display: inline-flex;
  align-items: center;
  border: 1px solid #dfdfe8;
  border-radius: 20px;
  cursor: pointer;
  padding: 2px 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #1d1d35;
  height: 24px;

  & .infoChipLabel {
    font-weight: 500;
  }

  & .infoChipCurrencySymbol {
    color: #009688;
  }
`;

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ProjectContacts = styled.div`
  display: flex;
  gap: 16px;

  > * {
    flex: 1;
    min-width: 0;
  }
`;

export const ProjectContact = styled.div`
  flex: 1;
  min-width: 0;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ProjectContactLabel = styled.div`
  font-size: 12px;
  line-height: 130%;
  color: #828d9a;
  margin-bottom: 0px;
`;

export const ProjectContactInfo = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 130%;
  color: #1d1d35;
  min-height: 24px;

  & > div {
    margin-right: 5px;
  }
`;

export const FullDescriptionBody = styled.div`
  height: 100%;
  overflow-y: auto;
  font-size: 14px;
  line-height: 130%;
  max-height: 180px;
  color: #1d1d35;
  ${scroll};
`;

export const ProjectAccountInfoChip = styled.div<{ isEditable: boolean; isClickable?: boolean }>`
  border: 1px solid #dfdfe8;
  border-radius: 20px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  cursor: ${({ isClickable, isEditable }) => (isClickable || isEditable ? 'pointer' : 'default')};
  color: ${({ isClickable }) => (isClickable ? '#009688' : '#1d1d35')};
  background-color: ${({ isEditable, isClickable }) => {
    return isEditable || isClickable ? 'transparent' : '#F7F7FC';
  }};

  display: inline-flex;
  align-items: center;
  padding: 2px 8px;
  height: 24px;

  & svg {
    margin-right: 4px;
  }

  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${device.md`
  max-width: 165px;
  `}
`;

export const InfoAddress = styled.a`
  font-weight: 400;
  font-size: 14px;
  white-space: nowrap;
  line-height: 130%;
  color: #235dff;
  gap: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;

  min-width: 0;

  svg {
    flex-shrink: 0;
  }

  > div {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &:link,
  &:visited,
  &:hover,
  &:active,
  &:focus {
    color: #235dff;
    text-decoration: none;
  }
`;

export const AddressAndIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  overflow: hidden;
`;

export const EditableWrapper = styled.div`
  cursor: pointer;
  padding: 4px;
  margin-left: -4px;
  margin-right: 4px;
  border: 1px solid transparent;
  min-width: 40px;
  width: 100%;

  transition: border-color 0.15s ease-in-out;

  &:hover {
    border: 1px solid #c8c8d3;
    border-radius: 4px;
  }

  svg {
    flex-shrink: 0;
  }
`;

export const PrimaryContactBlock = styled.div`
  ${EditableWrapper} {
    flex: 1;
  }
`;

export const CustomPropertiesContainer = styled.div`
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid #dfdfe8;
`;

export const CustomerPropertiesTabsContainer = styled.div`
  ${scroll};
  overflow-x: auto;
`;

export const Copyable = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  > *:last-child {
    opacity: 0;
    color: ${colors.gray2};
    transition: opacity 0.15s ease-in-out;
  }

  &:hover > *:last-child {
    opacity: 1;
  }

  font-size: 14px;
  font-weight: 400;
`;

export const StaticPropertyValue = styled.div`
  font-size: 14px;
  line-height: 130%;
  color: #828d9a;
  font-weight: 400;
  margin-top: 4px;
`;

export const ReferredBy = styled.div`
  padding: 4px 0;
`;

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  margin-top: 4px;
`;
