import styled from 'styled-components';

export const Container = styled.div<{ color?: string }>`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${({ color }) => color ?? 'inherit'};
  font-size: 14px;
  font-weight: 400;
`;
