import { ANALYTICS_MODULE_RECORD_TYPE_MAP } from '@features/Analytics/constants';
import { AnalyticsModule } from '@features/Analytics/types';
import { useCompanyProperties } from '@hooks/useCompanyProperties';
import { Property, RecordType } from '@types';
import { useMemo } from 'react';

export const usePropertiesMap = (module: AnalyticsModule) => {
  const recordType = ANALYTICS_MODULE_RECORD_TYPE_MAP[module] || RecordType.PROJECT;
  const { scopeToAllColumns, allProperties } = useCompanyProperties({ recordType, fullAccess: true });

  const propertiesMap = useMemo(
    () =>
      (scopeToAllColumns[recordType] || []).reduce(
        (acc, property) => {
          acc[property.id as number] = property;

          return acc;
        },
        {} as Record<number, Property>
      ),
    [recordType, scopeToAllColumns]
  );

  return {
    propertiesMap,
    isPropertiesLoading: allProperties.length === 0
  };
};
