import { handleActions, Action } from 'redux-actions';

import * as actionsExport from '@state/actions/companies/actions';
import { mergeToState, makeMergePayloadToStateKey, makeMergePayloadToState } from '@state/reducers/helpers';

import { CompaniesState } from './types';

const actions = actionsExport as unknown as { [key: string]: string };

const initialState: CompaniesState = {
  isLoading: false,
  isFailed: false,
  isUpdating: false,
  isUpdatingFailed: false,
  data: [],
};

export type Payloads = any;

const IS_LOADING = { isLoading: true, isFailed: false };
const IS_FETCHED = { isLoading: false, isFailed: false };
const IS_FAILED = { isLoading: false, isFailed: true };

const IS_UPDATE_LOADING = { isUpdating: true, isUpdatingFailed: false };
const IS_UPDATE_FETCHED = { isUpdating: false, isUpdatingFailed: false };
const IS_UPDATE_FAILED = { isUpdating: false, isUpdatingFailed: true };

export const reducer = handleActions<CompaniesState, Action<Payloads>>(
  {
    [actions.fetchCompaniesStart]: mergeToState(IS_LOADING),
    [actions.fetchCompaniesSuccess]: makeMergePayloadToState(IS_FETCHED),
    [actions.fetchCompaniesFail]: mergeToState(IS_FAILED),
    [actions.updateCompanyStart]: mergeToState(IS_UPDATE_LOADING),
    [actions.updateCompanySuccess]: makeMergePayloadToState(IS_UPDATE_FETCHED),
    [actions.updateCompanyFail]: mergeToState(IS_UPDATE_FAILED),
    [actions.updateCompanies]: makeMergePayloadToStateKey('data'),
  }, initialState
);

export default reducer;
