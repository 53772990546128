import { colors, device, deviceMax } from '@styles';
import styled from 'styled-components';

export const Container = styled.div<{ isFullScreen: boolean; width: string; height: string; zIndexIncrement: number }>`
  position: fixed;
  right: 0;
  top: 48px;
  bottom: 0;
  z-index: ${({ isFullScreen, zIndexIncrement }) => (isFullScreen ? 5 + zIndexIncrement : 5 + zIndexIncrement)};
  background: ${colors.white};
  width: 100%;
  box-shadow: -16px -16px 200px 0px #1d1d3552;
  ${deviceMax.sm`
     height: 100%;
  `}

  ${device.md`
  position: absolute;
  top: initial;
  width: ${({ isFullScreen, width }) => (isFullScreen ? '100%' : width)};
  height: ${({ isFullScreen, height }) => (isFullScreen ? height : height)};

  ${({ isFullScreen }) =>
    isFullScreen &&
    `
  display: flex;
  flex-direction: column;
  `};
  `};
`;
