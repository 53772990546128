import styled from 'styled-components';

export const Body = styled.div`
  padding: 24px;
  background-color: #f7f7fc;
  width: 700px;
  max-width: 60vw;
`;

export const Footer = styled.div`
  padding: 16px 24px;
  display: flex;
  justify-content: flex-end;
  gap: 24px;
  background-color: #fff;
`;

export const Option = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: start;

  strong {
    font-weight: 500;
  }
  small {
    font-weight: 300;
  }
`;
