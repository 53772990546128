import React from 'react';

import { TASK_STATUS_COLORS } from '@styles';
import { TaskStatus } from '@generated/types/graphql';
import { Container } from './styled';

interface Props {
  status: {
    id: TaskStatus;
    label: string;
  };
  className?: string;
  isArchived?: boolean;
  maxWidth?: string;
}

export const WorkOrderStatus = ({ status, className, isArchived = false, maxWidth = '100px' }: Props) => {
  const statusColor = TASK_STATUS_COLORS[status.id];

  if (isArchived) {
    return (
      <Container maxWidth={maxWidth} className={className} color="#778CA2" background="#DFDFE8">
        Archived
      </Container>
    );
  }

  return (
    <Container maxWidth={maxWidth} className={className} color={statusColor.color} background={statusColor.background}>
      {status.label}
    </Container>
  );
};
