import { fonts } from '@styles';
import styled from 'styled-components';

export const Menu = styled.div`
  && {
    .MuiTypography-body2 {
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;
      font-family: ${fonts.primary};
    }

    padding: 8px;
  }
`;
