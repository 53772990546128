import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Radio, RadioProps } from '@material-ui/core';

import { radioMui } from '@styles';

const useStyles = makeStyles(radioMui({}));

export const StyledRadio: React.FC<RadioProps> = (props) => {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
};
