import { scroll } from '@styles';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  overflow: auto;
  ${scroll};

  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;

  > *:last-child {
    margin-left: auto;
  }
`;

export const ListHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 500;
  height: 32px;
`;

export const TableHeaderContainer = styled.table`
  position: sticky;
  top: 0;
  z-index: 3;
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  background-color: #f7f7fc;
  width: 100%;
  table-layout: fixed;

  th {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 40px;
    text-align: left;
    padding-left: 16px;

    font-size: 12px;
    font-weight: 500;
    color: #828d9a;
    text-transform: uppercase;
  }
`;

export const TableHeaderTitleCell = styled.th`
  &&& {
    width: 35%;
    min-width: 35%;
    max-width: 35%;
    position: sticky;
    left: 0px;
    background-color: transparent;
    padding-left: 16px;
  }
`;

export const TableHeaderTitleCellInner = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const GroupContainer = styled.div``;

export const GroupHeaderContainer = styled.table`
  border-spacing: 0;
  width: 100%;
  table-layout: fixed;

  th {
    height: 54px;
    background-color: transparent;
  }
  th:first-child {
    background-color: transparent;
    position: sticky;
    left: 0px;
    z-index: 1;
    width: 35%;
    min-width: 35%;
    max-width: 35%;
  }
`;

export const GroupHeaderTitleCell = styled.div`
  padding: 10px 8px;
  padding-left: 16px;
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const GroupHeaderTitleCellInner = styled.div`
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  user-select: none;
`;

export const GroupCounter = styled.div`
  color: #9c9caa;
`;

export const TableRows = styled.table`
  background-color: #fff;
  border-spacing: 0;
  border-top: 1px solid #dfdfe8;
  width: 100%;
  table-layout: fixed;
  td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #dfdfe8;

    height: 64px;
    font-weight: 400;
    font-size: 12px;
    padding: 8px 16px;
    position: relative;
  }
`;

export const Td = styled.td<{ width: string }>`
  width: ${({ width }) => width};
`;

export const Th = styled.th<{ width: string }>`
  width: ${({ width }) => width};
`;

export const CheckboxContainer = styled.div`
  display: none;
  position: absolute;

  left: 2px;
  top: 2px;
  z-index: 1;
`;

export const Row = styled.tr<{ isSelected: boolean; isArchived?: boolean }>`
  background-color: ${({ isSelected }) => (isSelected ? '#E3F6F4' : '#fff')};
  cursor: pointer;
  transition: background-color 0.15s ease-in-out;
  opacity: ${({ isArchived }) => (isArchived ? 0.5 : 1)};

  &:hover {
    background-color: #f5f5f5;
    ${CheckboxContainer} {
      display: block;
    }

    ${CheckboxContainer} + svg {
      opacity: 0;
    }
  }

  svg {
    flex-shrink: 0;
  }

  font-size: 12px;
`;

export const TitleCell = styled.td<{ isSelected: boolean; color?: string }>`
  &&& {
    width: 35%;
    min-width: 35%;
    max-width: 35%;
    padding: 0;
    padding-left: 10px;
    position: sticky;
    left: 0px;
    z-index: 2;
    border-left: 4px solid ${({ color }) => color || 'transparent'};
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      ${CheckboxContainer} {
        display: block;
      }

      ${CheckboxContainer} + svg {
        opacity: 0;
      }
    `};
`;

export const TitleCellInner = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
`;

export const TitleCellIconOrCheckbox = styled.div`
  position: relative;
  flex-shrink: 0;
  display: flex;
  align-items: center;
`;

export const PriorityChip = styled.div`
  display: inline-flex;
  align-items: center;
  background-color: #f7f7fc;
  border-radius: 12px;
  padding: 2px 8px;
  gap: 4px;
  font-weight: 500;
  font-size: 12px;

  svg {
    flex-shrink: 0;
  }
`;

export const Assignee = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
`;

export const SelectionControl = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  min-height: 34px;
  padding: 0 16px;
  font-size: 14px;
  font-weight: 400;
`;

export const Placeholder = styled.div`
  color: #828d9a;
  font-size: 14px;
  font-weight: 400;
`;

export const TableEmptyPlaceholder = styled.div`
  color: #828d9a;
  font-size: 14px;
  font-weight: 400;

  margin: 24px auto;
  text-align: center;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64.5px;
`;
