import styled, { css } from 'styled-components';

export const Container = styled.div<{ vertical: boolean }>`
  display: flex;
  min-width: 0;
  ${(props) =>
    props.vertical
      ? css`
          flex-direction: column;
          align-items: flex-start;
          gap: 4px;
        `
      : css`
          align-items: center;
          gap: 8px;
        `}
`;

export const Text = styled.div`
  font-size: 14px;
  font-weight: normal;
  color: #1d1d35;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
