import React, { useCallback, useMemo } from 'react';
import { Copy, Sliders } from 'react-feather';
import { ContextMenu, MenuItem } from '@kit/components/ContextMenu';
import { Tooltip } from '@material-ui/core';
import { useModal } from '@common/PromiseModal';
import { TrashIcon } from '@kit/ui/icons/Trash';
import { DashboardListItem, LayoutItem } from '@hooks/analytics/useDashboardList';
import { useUpdateDashboard } from '@hooks/analytics/useUpdateDashboard';
import { WidgetSettings } from '../WidgetSettings';
import { ContainerInner, WidgetAction, WidgetContainer, WidgetHeader, WidgetSubtitle, WidgetTitle } from './styled';
import { WidgetContent } from './WidgetContent';
import { WidgetChips } from './WidgetChips';
import { DuplicateWidget } from '../DuplicateWidget';

interface Props {
  layoutItem: LayoutItem;
  dashboard: DashboardListItem;

  children?: React.ReactNode;

  isEditMode: boolean;

  onMouseDown?: (e: React.MouseEvent) => void;
}

const stopPropagation = (e: React.MouseEvent) => {
  e.stopPropagation();
};

export const Widget = React.forwardRef<HTMLDivElement, Props>(
  ({ dashboard, layoutItem, children, isEditMode, onMouseDown, ...props }, ref) => {
    const { openModal } = useModal();

    const { mutateAsync: update } = useUpdateDashboard();

    const { widgetConfig: info } = layoutItem;

    const handleEditClick = useCallback(() => {
      openModal<void>(
        ({ onClose }) => <WidgetSettings dashboard={dashboard} initialValues={layoutItem} onClose={onClose} />,
        { title: 'Edit Widget' }
      );
    }, [openModal, layoutItem, dashboard]);

    const menuItems = useMemo<MenuItem[]>(
      () => [
        {
          icon: <Copy size="16px" color="#9C9CAA" />,
          title: 'Duplicate',
          onClick: async () => {
            openModal<void>(
              ({ onClose }) => (
                <DuplicateWidget currentDashboardId={dashboard.id} widget={layoutItem} onClose={onClose} />
              ),
              { title: 'Duplicate Widget' }
            );
          }
        },
        {
          icon: <TrashIcon size="16px" color="#D54855" />,
          title: 'Delete',
          onClick: async () => {
            await update({
              id: dashboard.id,
              dto: {
                layout: dashboard.layout.filter((item) => item.i !== layoutItem.i)
              }
            });
          }
        }
      ],
      [dashboard, layoutItem, update, openModal]
    );

    const handleMouseDown = useCallback(
      (e: React.MouseEvent) => {
        if (e.target instanceof SVGElement) {
          return;
        }

        if (onMouseDown) {
          onMouseDown(e);
        }
      },
      [onMouseDown]
    );

    return (
      <WidgetContainer onMouseDown={handleMouseDown} ref={ref} {...props}>
        <ContainerInner>
          <WidgetHeader>
            <Tooltip title={info.title || 'No title'}>
              <WidgetTitle>{info.title || 'No title'}</WidgetTitle>
            </Tooltip>

            {isEditMode && (
              <>
                <WidgetAction onClick={handleEditClick} onMouseDown={stopPropagation}>
                  <Sliders size="16px" />
                </WidgetAction>
                <WidgetAction onMouseDown={stopPropagation}>
                  <ContextMenu items={menuItems} />
                </WidgetAction>
              </>
            )}
          </WidgetHeader>
          {info.subtitle?.length > 0 && (
            <Tooltip title={info.subtitle}>
              <div>
                <WidgetSubtitle>{info.subtitle}</WidgetSubtitle>
              </div>
            </Tooltip>
          )}

          <WidgetChips settings={info} />

          <WidgetContent settings={info} />
        </ContainerInner>
        {children}
      </WidgetContainer>
    );
  }
);
