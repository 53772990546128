import { useRecordDetail } from '@hooks/useRecordDetail';
import { uniq } from 'lodash';
import { useMemo } from 'react';
import { useWorkOrderList } from '@hooks/workOrders/useWorkOrderList';
import { ReactQueryKey } from '@enums';
import { useSystems } from '@hooks/systems';
import { PrivilegedTasksOrderBy } from '@generated/types/graphql';
import { Site } from './types';

export const useSites = (clientId: number) => {
  const { data: record } = useRecordDetail(clientId, { refetchOnMount: false });
  const { data: workOrders } = useWorkOrderList({
    queryKey: ReactQueryKey.TasksInSites,
    filters: {
      projectId: { equalTo: clientId }
    },
    orderBy: [PrivilegedTasksOrderBy.PriorityAsc, PrivilegedTasksOrderBy.TitleAsc]
  });

  const { data: systems } = useSystems({
    filter: {
      projectId: { equalTo: clientId }
    }
  });

  return useMemo<Site[]>(() => {
    if (!record) {
      return [];
    }

    const addresses = uniq([
      ...(record.address ?? []),
      ...(record.projects ?? []).flatMap((child) => child.address ?? []),
      ...(record.deals ?? []).flatMap((child) => child.address ?? []),
      ...(systems ?? []).flatMap((child) => (child.addressForced || child.address) ?? [])
    ]);

    return addresses.map((address) => ({
      address: address || null,
      requests: (record.deals ?? []).filter(
        (deal) => (!deal.address.filter(Boolean).length || deal.address?.includes(address)) && deal.isActive
      ),
      workOrders: workOrders?.filter((workOrder) => workOrder.address === address && !workOrder.isArchived) ?? [],
      systems: systems?.filter((system) => (system.addressForced || system.address) === address) ?? [],
      projects: (record.projects ?? []).filter((project) => project.address?.includes(address) && project.isActive)
    }));
  }, [record, workOrders, systems]);
};
