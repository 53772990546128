import React, { useCallback, useState } from 'react';
import { Copy } from 'react-feather';
import { isArray } from 'lodash';
import { getFullName } from '@utils';
import { Wrapper } from './styled';

interface CopyButtonProps {
  value: any;
  size?: number;
}

const getValueAsString = (value?: any) => {
  if (value?.firstName || value?.lastName) {
    return getFullName(value);
  }

  if (isArray(value)) {
    return value.join(', ');
  }

  return value;
};

export const CopyButton: React.FC<CopyButtonProps> = (props) => {
  const { value, size = 16 } = props;

  const [isMessageVisible, setIsMessageVisible] = useState(false);

  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      navigator.clipboard.writeText(getValueAsString(value));

      setIsMessageVisible(true);

      setTimeout(() => {
        setIsMessageVisible(false);
      }, 1000);
    },
    [value]
  );

  return (
    <Wrapper
      data-tooltip-hidden={!isMessageVisible}
      data-tooltip-id="react-root-tooltip"
      data-tooltip-content="Copied!"
      data-tooltip-delay-hide={1000}
      onClick={handleClick}
      size={size}
    >
      <Copy size={size} />
    </Wrapper>
  );
};
