import { ModalBody, ModalFooter } from '@common/PromiseModal';
import DropDownItem from '@common/Selector/UserSelector/DropDownItem';
import { useCreateRecordMember } from '@hooks/records/useCreateRecordMember';
import { useAllCompaniesUsers } from '@hooks/useCompanyUsers';
import { useDebouncedState } from '@hooks/useDebouncedState';
import { useRecordDetail } from '@hooks/useRecordDetail';
import { Form, FormValidationRules, SelectField, useForm } from '@kit/components/Form';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { getFullName } from '@utils/utils';
import React, { useMemo } from 'react';

interface Props {
  recordId: number;
  onClose: () => void;
}

interface FormValues {
  user: { id: number; firstName: string; lastName: string };
}

export const AddMemberForm = ({ recordId, onClose }: Props) => {
  const { data: record } = useRecordDetail(recordId, { refetchOnMount: false });

  const { mutateAsync: createMember } = useCreateRecordMember();

  const existingMembers = record?.projectMembers.map((projectMember) => projectMember.member);

  const [, debouncedSearch, setInputValue] = useDebouncedState('', 300);

  const { data: users } = useAllCompaniesUsers({ search: debouncedSearch });

  const postForm = async (values: FormValues) => {
    await createMember({ recordId, userId: values.user.id });

    onClose();
  };

  const { handleSubmit, form } = useForm<FormValues>({
    onSubmit: postForm
  });

  const {
    formState: { isSubmitting },
    control
  } = form;

  const rules = useMemo<FormValidationRules<FormValues>>(
    () => ({
      user: {
        isRequired: true
      }
    }),
    []
  );

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <ModalBody>
        <SelectField
          name="user"
          label="User"
          options={users ?? []}
          filterOptions={(options) => options}
          getOptionLabel={getFullName}
          placeholder="Search by name, email or phone"
          onInputChange={(_, value) => setInputValue(value)}
          noOptionsText="User not found"
          getOptionDisabled={(option) => existingMembers?.some((member) => member.id === option.id)}
          renderOption={(option: any) => (
            <DropDownItem id={option.id} avatarUrl={option.avatarUrl} name={getFullName(option)} />
          )}
          control={control}
        />
      </ModalBody>
      <ModalFooter>
        <Button variant={ButtonVariant.Secondary} onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={isSubmitting} type="submit" variant={ButtonVariant.Primary}>
          Add
        </Button>
      </ModalFooter>
    </Form>
  );
};
