import { FormLinkField } from '@common/index';
import { useFormikContext } from 'formik';
import React, { useCallback } from 'react';
import { Form as TForm } from '@components/Project/DataBank/types';
import { PrivilegedTask } from '@generated/types/graphql';
import { DrawerEntity, useDrawersContext } from '@contexts/DrawersContext';

interface Props {
  isReadOnly?: boolean;
  workOrder: PrivilegedTask;
}

export const Forms = ({ isReadOnly = false, workOrder }: Props) => {
  const { values, submitForm } = useFormikContext();

  const { forms } = workOrder;

  const { openDrawer } = useDrawersContext();

  const handleFormClick = useCallback(
    (form: TForm) => {
      if (!form.isTemplate) {
        if (!form.file?.id) {
          return;
        }

        openDrawer(DrawerEntity.DOC, form.file?.id, forms.map((f) => f.file?.id).filter(Boolean));
      }
    },
    [openDrawer, forms]
  );

  return (
    <FormLinkField
      name="forms"
      isDisabled={isReadOnly}
      projectId={values.project?.id}
      companyId={values.companyId}
      onFormClick={handleFormClick}
      onChange={() => {
        setTimeout(() => {
          submitForm();
        }, 0);
      }}
    />
  );
};
