import React from 'react';

export const LabelIcon = ({ size = '16px', color = 'currentColor' }: { size?: string | number; color?: string }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.22423 14.0084L14.0113 9.22003C14.529 8.69907 14.529 7.85771 14.0113 7.33675L8.27616 1.6001H1.59961V8.27839L7.33476 14.0084C7.58523 14.2592 7.92509 14.4001 8.2795 14.4001C8.6339 14.4001 8.97376 14.2592 9.22423 14.0084ZM5.59961 7.2001C6.48326 7.2001 7.19961 6.48375 7.19961 5.6001C7.19961 4.71644 6.48326 4.0001 5.59961 4.0001C4.71595 4.0001 3.99961 4.71644 3.99961 5.6001C3.99961 6.48375 4.71595 7.2001 5.59961 7.2001Z"
      fill={color}
    />
  </svg>
);
