import { LabelColor } from './types';

export const LABEL_COLORS: LabelColor[] = [
  {
    name: 'Gray',
    color: '#98a9bc',
    bgColor: ''
  },
  {
    name: 'Red',
    color: '#ED4947',
    bgColor: ''
  },
  {
    name: 'Pink',
    color: '#E9386F',
    bgColor: ''
  },
  {
    name: 'Purple',
    color: '#A13EB4',
    bgColor: ''
  },
  {
    name: 'Deep Purple',
    color: '#734DBA',
    bgColor: ''
  },
  {
    name: 'Blue',
    color: '#399BF3',
    bgColor: ''
  },
  {
    name: 'Cyan',
    color: '#22BFD5',
    bgColor: ''
  },
  {
    name: 'Green',
    color: '#5BB25F',
    bgColor: ''
  },
  {
    name: 'Yellow',
    color: '#FFC324',
    bgColor: ''
  },
  {
    name: 'Orange',
    color: '#FF9D23',
    bgColor: ''
  },
  {
    name: 'Dark',
    color: '#1A2D4F',
    bgColor: ''
  },
  {
    name: 'Brown',
    color: '#795548',
    bgColor: ''
  },
  {
    name: 'Deep orange',
    color: '#FF5722',
    bgColor: ''
  },
  {
    name: 'Blue gray',
    color: '#607D8B',
    bgColor: ''
  },
  {
    name: 'Indigo',
    color: '#3F51B5',
    bgColor: ''
  },
  {
    name: 'Light green',
    color: '#8BC34A',
    bgColor: ''
  },
  {
    name: 'Lime',
    color: '#CDDC39',
    bgColor: ''
  }
].map((color) => ({
  ...color,
  bgColor: `${color.color}33` // make bgColor 20% transparent
}));
