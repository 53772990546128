import styled from 'styled-components';

export const Body = styled.div`
  padding: 24px;
  max-width: 500px;
`;

export const Footer = styled.div`
  background: #ffffff;
  padding: 24px;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
`;

export const FieldLabel = styled.label`
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #828d9a;
  display: flex;
`;

export const Description = styled.div`
  margin-top: 16px;
  font-size: 14px;
  padding: 16px;
  border: 1px solid #dfdfe8;
  max-width: 100%;
  background: #fff;
  border-radius: 4px;
  color: #828d9a;

  display: flex;
  gap: 16px;

  svg {
    flex-shrink: 0;
  }
`;
