import { Reducer } from './types';

export const mergePayloadToState: Reducer = (state, action) => ({
  ...state,
  ...action?.payload,
});

export const mergeToState = (dataToMerge) => (state) => ({
  ...state,
  ...dataToMerge,
});

// export const identity: Reducer = (state) => {
//   return { ...state };
// };
