import { Control, FieldPath, FieldValues, useController } from 'react-hook-form';
import React from 'react';
import { useFormValidationContext } from './validation';

export const useControllerWithValidation = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  name: TName,
  control: Control<TFieldValues>,
  fieldLabel: React.ReactNode,
  shouldUnregister = true
) => {
  const context = useFormValidationContext();

  return useController({
    name,
    rules: context?.[name]?.(fieldLabel),
    control,
    shouldUnregister
  });
};
