import { ReactQueryKey } from '@enums';
import { errorHandler } from '@services/api/helpers';
import { useMutation, useQueryClient } from 'react-query';
import { useToast } from '@hooks/useToast';
import { selectWorkspaceId } from '@state/selectors';
import { CreateDashboardDTO } from '@types';
import userApi from '@services/api/userApi';
import { useAppSelector } from '..';

export const useCreateDashboard = () => {
  const queryClient = useQueryClient();
  const { showSuccess } = useToast();
  const companyId = useAppSelector(selectWorkspaceId);

  return useMutation<number, Error, CreateDashboardDTO>(
    async (req) => {
      try {
        const {
          data: { id }
        } = await userApi.createDashboard(companyId, req);

        return id;
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: () => {
        showSuccess('Dashboard created successfully');
        queryClient.invalidateQueries(ReactQueryKey.Analytics);
      }
    }
  );
};
