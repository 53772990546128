import { FormLayout, MainUser } from '@generated/types/graphql';
import React, { useCallback } from 'react';
import { FormLayoutColumnType } from '@features/Platform/Templates/FormBuilder/helpers';
import { CheckboxGroupField, InputField, RadioGroupField, TextareaField } from '@kit/components/Form';
import { Control } from 'react-hook-form';
import { getAvatarColors } from '@utils/colors';
import { UserAvatar } from '@kit/components/UserAvatar';
import { Collaborators, FormFieldContainer, FormFieldWithCollaborators } from './styled';
import { FileField, ProjectField } from './fields';
import { FieldLabel } from './FieldLabel';

interface FormFieldControlProps {
  formId: number;
  orderNumber: number;
  field: FormLayout;
  control: Control<any>;
  isMobilePreview?: boolean;
  isFullScreen?: boolean;
  onFileClick?: (fileId: number) => void;
  onBlur?: () => void;
}

const FormFieldControl = ({
  isFullScreen = false,
  formId,
  field,
  control,
  isMobilePreview,
  onFileClick,
  onBlur,
  orderNumber
}: FormFieldControlProps) => {
  const fieldName = `${orderNumber}. ${field.column.name}`;

  switch (field.column.type) {
    case FormLayoutColumnType.Text:
      return (
        <TextareaField
          label={<FieldLabel fieldName={fieldName} notes={field.column.notes} />}
          name={`field_${field.column.id || field.id}`}
          control={control}
          minRows={1}
          disabled={field.column.isReadOnly}
        />
      );
    case FormLayoutColumnType.Numeric:
      return (
        <InputField
          type="number"
          label={<FieldLabel fieldName={fieldName} notes={field.column.notes} />}
          name={`field_${field.column.id || field.id}`}
          control={control}
          disabled={field.column.isReadOnly}
        />
      );

    case FormLayoutColumnType.Dropdown:
      if (field.column.multiple) {
        return (
          <CheckboxGroupField
            label={<FieldLabel fieldName={fieldName} notes={field.column.notes} />}
            name={`field_${field.column.id || field.id}`}
            control={control}
            isDisabled={field.column.isReadOnly}
            options={field.column.additional?.values?.map((value) => ({ label: value, value })) ?? []}
          />
        );
      }

      return (
        <RadioGroupField
          label={<FieldLabel fieldName={fieldName} notes={field.column.notes} />}
          name={`field_${field.column.id || field.id}`}
          control={control}
          disabled={field.column.isReadOnly}
          options={field.column.additional?.values?.map((value) => ({ label: value, value })) ?? []}
        />
      );
    case FormLayoutColumnType.File:
      return (
        <FileField
          label={<FieldLabel fieldName={fieldName} notes={field.column.notes} />}
          name={`field_${field.column.id || field.id}`}
          control={control}
          references={
            field.column.referenceFiles ?? field.column.projectPropertyFileReferencesByColumnId?.map((ref) => ref.file)
          }
          isMobilePreview={isMobilePreview}
          formId={formId}
          isFullScreen={isFullScreen}
          onFileClick={onFileClick}
          disabled={field.column.isReadOnly}
        />
      );

    case FormLayoutColumnType.Project:
      return (
        <ProjectField
          onFileClick={onFileClick}
          isFullScreen={isFullScreen}
          formId={formId}
          field={field}
          control={control}
          onBlur={onBlur}
          fieldName={fieldName}
        />
      );

    default:
      return null;
  }
};

interface Props extends FormFieldControlProps {
  onFocus?: (fieldId: number) => void;
  onBlur?: (fieldId: number) => void;
  activeCollaborator?: MainUser;
  isFullScreen?: boolean;
}

export const FormField = ({
  isFullScreen,
  activeCollaborator,
  onFocus,
  onBlur,
  formId,
  field,
  control,
  isMobilePreview,
  onFileClick,
  orderNumber
}: Props) => {
  const handleFocus = useCallback(() => {
    onFocus?.(field.column.id);
  }, [field.column.id, onFocus]);

  const handleBlur = useCallback(() => {
    onBlur?.(field.column.id);
  }, [field.column.id, onBlur]);

  const borderColor = activeCollaborator
    ? getAvatarColors([activeCollaborator.firstName, activeCollaborator.lastName].filter(Boolean).join(' ')).bg
    : 'transparent';

  return (
    <FormFieldWithCollaborators>
      <FormFieldContainer borderColor={borderColor} onFocus={handleFocus} onBlur={handleBlur}>
        <FormFieldControl
          isFullScreen={isFullScreen}
          formId={formId}
          field={field}
          control={control}
          isMobilePreview={isMobilePreview}
          onFileClick={onFileClick}
          onBlur={handleBlur}
          orderNumber={orderNumber}
        />
      </FormFieldContainer>
      <Collaborators>{activeCollaborator && <UserAvatar user={activeCollaborator} />}</Collaborators>
    </FormFieldWithCollaborators>
  );
};
