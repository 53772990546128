import { ReactQueryKey } from '@enums';
import { useToast } from '@hooks/useToast';
import { errorHandler } from '@services/api/helpers';
import { useMutation, useQueryClient } from 'react-query';
import filterApi from '@services/api/smartViewFilterApi';

export const useDeleteSmartView = () => {
  const { showSuccess } = useToast();
  const queryClient = useQueryClient();

  return useMutation<void, Error, { id: number }>(
    async ({ id }) => {
      try {
        await filterApi.remove(id);
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: async () => {
        showSuccess('Smart View deleted successfully');
        queryClient.refetchQueries(ReactQueryKey.SmartViewList);
      }
    }
  );
};
