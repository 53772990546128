import store, { StoreAPI } from 'store2';
import { Search } from '@components/Project/ProjectView/types';
import { UserSettings } from '@state/reducers/authentication/types';
import { RecordType, SmartViewCondition, PlayModeStorage } from '@types';
import { ProjectViewType } from '@enums';

const makeStoreEntity = <T = any>({ key, storeType }: { key: string; storeType?: 'local' | 'session' }) => {
  const st: StoreAPI = (storeType === 'local' && store.local) || (storeType === 'session' && store.session) || store;

  return {
    get: () => st.get(key),
    set: (payload: T) => st.set(key, payload),
    update: (payload: Partial<T>) => st.add(key, payload),
    remove: () => st.remove(key)
  };
};

const makeStoreEntityWithQuery = <T = any, K = string>({
  key,
  storeType = 'local',
  queryKey = (query) => `${query}`
}: {
  key: string;
  storeType?: 'local' | 'session';
  queryKey?: (query: K) => string;
}) => {
  const st: StoreAPI = (storeType === 'local' && store.local) || (storeType === 'session' && store.session) || store;

  return {
    get: (query: K): T => st.get(`${key}-${queryKey(query)}`),
    set: (query: K, payload: T) => st.set(`${key}-${queryKey(query)}`, payload),
    update: (query: K, payload: Partial<T>) => st.add(`${key}-${queryKey(query)}`, payload),
    remove: (query: K) => st.remove(`${key}-${queryKey(query)}`)
  };
};

export const queryByRecord = ({ companyId, recordType }: { companyId: number; recordType: RecordType }): string =>
  `${companyId}-${recordType}`;

export default {
  searchQuery: makeStoreEntityWithQuery<Search, { companyId: number; recordType: RecordType }>({
    key: 'search',
    storeType: 'session',
    queryKey: queryByRecord
  }),
  propertiesOrder: makeStoreEntityWithQuery<SmartViewCondition['properties']['columnsOrder'], CompanyId | string>({
    key: 'columnsOrder'
  }),
  propertiesShown: makeStoreEntityWithQuery<SmartViewShownProperties, CompanyId>({
    key: 'shownColumns'
  }),
  settings: makeStoreEntityWithQuery<UserSettings, UserId>({
    key: 'settings'
  }),
  selectedCompanyId: makeStoreEntity<number | undefined>({
    key: 'selectedCompanyId',
    storeType: 'local'
  }),
  playMode: makeStoreEntityWithQuery<PlayModeStorage, { companyId: number; recordType: RecordType }>({
    key: 'playMode',
    storeType: 'session',
    queryKey: queryByRecord
  }),
  docsView: makeStoreEntity<string | undefined>({
    key: 'docsView',
    storeType: 'local'
  })
};

type CompanyId = number;

type UserId = number;

export type SmartViewShownProperties = {
  [recordType in RecordType]: {
    [viewType in ProjectViewType]: number[];
  };
};
