import React from 'react';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { ModalBody, ModalFooter } from './styled';

export enum ConfirmClosingResult {
  LeaveAndDiscard,
  KeepEditing,
  SaveChanges
}

interface Props {
  onClose: (result: ConfirmClosingResult | void) => void;
}

export const ConfirmClosing = ({ onClose }: Props) => {
  const handleDiscard = () => {
    onClose(ConfirmClosingResult.LeaveAndDiscard);
  };

  const handleKeepEditing = () => {
    onClose(ConfirmClosingResult.KeepEditing);
  };

  const handleSave = () => {
    onClose(ConfirmClosingResult.SaveChanges);
  };

  return (
    <>
      <ModalBody>
        <p>
          There are <b>unsaved changes</b>. You can save your changes, keep editing, or leave & discard changes.
        </p>
      </ModalBody>
      <ModalFooter>
        <Button variant={ButtonVariant.Secondary} onClick={handleDiscard}>
          Leave & Discard
        </Button>
        <Button variant={ButtonVariant.Secondary} onClick={handleKeepEditing}>
          Keep editing
        </Button>
        <Button variant={ButtonVariant.Primary} onClick={handleSave}>
          Save changes
        </Button>
      </ModalFooter>
    </>
  );
};
