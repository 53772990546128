import React from 'react';
import styled from 'styled-components';
import { InputLabel } from '@common/ui';

import { capitalizeFirstLetter } from '@utils';
import { input, error } from '@styles';

import Props from './types';

const Wrapper = styled.div`
  position: relative;
`;

const Input = styled.input`
  ${input};
  width: 100%;
`;

const ErrorMessage = styled.span`
  ${error};
  position: absolute;
  top: 0;
  right: 0;
`;

/**
 * @deprecated Use common/ui/TextField
 */
const TextField: React.FC<Props> = (props) => {
  const {
    label,
    placeholder,
    value,
    changeHandler,
    keyDownHandler,
    styles,
    error
  } = props;

  return (
    <Wrapper>
      <InputLabel htmlFor={label}>
        {label}
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </InputLabel>
      {/*TODO Add validation, if has error add error property to input*/}
      <Input
        placeholder={placeholder}
        onChange={changeHandler}
        onKeyDown={keyDownHandler || null}
        value={value}
        style={styles}
        error={!!error}
        data-testid={`textField${capitalizeFirstLetter((label || '').replace(/\s/g, ''))}`}
      />
    </Wrapper>
  );
};

export default TextField;
