import { scroll } from '@styles';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  overflow: auto;
  min-height: 0;
  ${scroll};
`;

export const ContainerInner = styled.div``;

export const Row = styled.div`
  border-bottom: 1px solid #e4e8ec;
  height: 48px;
  display: grid;
  grid-template-columns: 60px 3fr 3fr;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  overflow: hidden;

  cursor: pointer;

  transition: background-color 0.15s ease-in-out;

  &:hover {
    background-color: #f5f5f5;
  }

  > * {
    &:first-child {
      text-align: center;
    }
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const NoDataContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  background: linear-gradient(to bottom, #fff, #fff 50%, #f7f7fc 50%, #f7f7fc);
  /* The rectangle in which to repeat. 
      It can be fully wide in this case */
  background-size: 100% 48px;
`;

export const NoDataText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  width: 100%;
`;
