import { ModalBody } from '@common/PromiseModal';
import styled from 'styled-components';

export const Body = styled(ModalBody)`
  width: 750px;
  max-width: 60vw;
`;

export const Row = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;

  > * {
    flex: 1;
  }
`;

export const Info = styled.div`
  font-size: 14px;
  font-weight: 400;
  b {
    font-weight: 500;
  }

  margin-bottom: 24px;
`;
