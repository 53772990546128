import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DateTime } from 'luxon';

import { Button, ButtonVariant } from '@kit/ui/Button';
import { Check, Copy, Edit2, Plus } from 'react-feather';
import { useDashboardList } from '@hooks/analytics/useDashboardList';
import { useConfirmDeleteModal, useModal } from '@common/PromiseModal';
import { ContextMenu, MenuItem } from '@kit/components/ContextMenu';
import { Switch } from '@kit/ui/Switch';
import { MoreVerticalIcon } from '@kit/ui/icons/MoreVertical';
import { Popover } from '@kit/ui/Popover';
import { ChevronDownIcon } from '@kit/ui/icons/ChevronDown';
import { QueryParamsEnum, useQueryParam } from '@hooks/useQueryParam';
import { useDeleteDashboard } from '@hooks/analytics/useDeleteDashboard';
import { TrashIcon } from '@kit/ui/icons/Trash';
import { useAuth } from '@hooks/useAuth';
import { selectWorkspaceId } from '@state/selectors';
import { useAppSelector } from '@hooks/store';
import { useUserRoleSettings } from '@hooks/useRoles';
import { hasAccess } from '@utils/roles';
import { Grid } from './Grid';
import {
  Container,
  CurrentDashboardName,
  DashboardListItem,
  DashboardMenuSeparator,
  Description,
  Header,
  HeaderTopRow,
  LastUpdated,
  PlaceholderContainer,
  PlaceholderSubtitle,
  PlaceholderTitle,
  WidgetPlaceholderContainer
} from './styled';
import { WidgetSettings } from './WidgetSettings';
import { DashboardSettings } from './DashboardSettings';
import placeholderImg from './placeholder.png';
import widgetPlaceholderImg from './widgetPlaceholder.png';

export const Analytics = () => {
  const companyId = useAppSelector(selectWorkspaceId);
  const [isEditMode, setIsEditMode] = useState(false);
  const { user } = useAuth();
  const { data: access } = useUserRoleSettings(companyId, user.userId);

  const [currentDashboardId, setCurrentDashboardId] = useQueryParam(QueryParamsEnum.DashboardId);
  const { data: dashboards = [], isLoading, isFetching } = useDashboardList();

  const { openModal } = useModal();

  const { canCreate, canEdit, canDelete } = useMemo(
    () => ({
      canCreate: hasAccess(access!, 'workspace', 'create', 'analytics'),
      canEdit: hasAccess(access!, 'workspace', 'edit', 'analytics'),
      canDelete: hasAccess(access!, 'workspace', 'delete', 'analytics')
    }),
    [access]
  );

  const confirmDelete = useConfirmDeleteModal();
  const { mutateAsync: deleteDashboard } = useDeleteDashboard();

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    if (isFetching || isLoading || !window.location.href.includes('analytics')) {
      return;
    }

    if (dashboards.length && !currentDashboardId) {
      setCurrentDashboardId(dashboards[0].id);
    } else if (dashboards.length && currentDashboardId) {
      const dashboard = dashboards.find((dashboard) => dashboard.id === parseInt(currentDashboardId.toString(), 10));

      if (!dashboard) {
        setCurrentDashboardId(dashboards[0].id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDashboardId, dashboards, isFetching, isLoading]);

  const currentDashboard = useMemo(
    () =>
      currentDashboardId &&
      dashboards.find((dashboard) => dashboard.id === parseInt(currentDashboardId.toString(), 10)),
    [currentDashboardId, dashboards]
  );

  const handleAddWidgetClick = useCallback(() => {
    openModal<void>(({ onClose }) => <WidgetSettings dashboard={currentDashboard} onClose={onClose} />, {
      title: 'Create Widget'
    });
  }, [openModal, currentDashboard]);

  const handleCreateDashboard = useCallback(async () => {
    const createdId = await openModal<number>(({ onClose }) => <DashboardSettings onClose={onClose} />, {
      title: 'Create Dashboard'
    });

    if (createdId) {
      setCurrentDashboardId(createdId);
    }
  }, [openModal, setCurrentDashboardId]);

  const dashboardContextMenuItems = useMemo<MenuItem[]>(
    () =>
      [
        canCreate && {
          title: 'Duplicate',
          icon: <Copy size="16px" color="#9C9CAA" />,
          onClick: async () => {
            const createdId = await openModal<number>(
              ({ onClose }) => (
                <DashboardSettings
                  initialValues={{ ...currentDashboard, id: undefined, name: `Copy of ${currentDashboard.name}` }}
                  onClose={onClose}
                />
              ),
              {
                title: 'Duplicate Dashboard'
              }
            );

            if (createdId) {
              setCurrentDashboardId(createdId);
            }
          }
        },
        canEdit && {
          title: 'Edit',
          icon: <Edit2 size="16px" color="#9C9CAA" />,
          onClick: () => {
            openModal<number>(
              ({ onClose }) => <DashboardSettings initialValues={currentDashboard} onClose={onClose} />,
              {
                title: 'Edit'
              }
            );
          }
        },
        canDelete && {
          title: 'Delete',
          icon: <TrashIcon size="16px" color="#D54855" />,
          onClick: async () => {
            if (await confirmDelete('Are you sure you want to delete this dashboard?')) {
              await deleteDashboard(currentDashboard.id);

              setCurrentDashboardId(undefined);
            }
          }
        }
      ].filter(Boolean),
    [canDelete, canEdit, currentDashboard, openModal, confirmDelete, deleteDashboard, setCurrentDashboardId, canCreate]
  );

  if (isLoading || (isFetching && !currentDashboard)) {
    return null;
  }

  if (!dashboards.length || !currentDashboard) {
    return (
      <Container>
        <PlaceholderContainer>
          <img src={placeholderImg} alt="" />
          <div>
            <PlaceholderTitle>No Dashboards yet</PlaceholderTitle>
            {canCreate && <PlaceholderSubtitle>Start setting up your first Dashboard</PlaceholderSubtitle>}
          </div>
          {canCreate && (
            <Button onClick={handleCreateDashboard} variant={ButtonVariant.Primary}>
              <Plus size="16px" />
              Dashboard
            </Button>
          )}
        </PlaceholderContainer>
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <HeaderTopRow>
          <Popover
            content={
              <div>
                {dashboards.map((dashboard) => (
                  <DashboardListItem onClick={() => setCurrentDashboardId(dashboard.id)}>
                    <div>{dashboard.name}</div>
                    {dashboard.id === currentDashboard?.id && <Check size="16px" color="#009A47" />}
                  </DashboardListItem>
                ))}
                {canCreate && (
                  <>
                    <DashboardMenuSeparator />
                    <Button isUpperCase={false} variant={ButtonVariant.Flat} onClick={handleCreateDashboard}>
                      <Plus size="16px" />
                      New Dashboard
                    </Button>
                  </>
                )}
              </div>
            }
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
          >
            <CurrentDashboardName>
              {currentDashboard.name}
              <ChevronDownIcon size="24px" />
            </CurrentDashboardName>
          </Popover>

          <LastUpdated>
            Last updated:{' '}
            {DateTime.fromISO(currentDashboard.updatedAt, { zone: 'utc' })
              .toLocal()
              .toLocaleString(DateTime.DATETIME_SHORT)}
          </LastUpdated>
          {isEditMode && (
            <Button onClick={handleAddWidgetClick} variant={ButtonVariant.Primary}>
              <Plus size="16px" />
              Widget
            </Button>
          )}

          {canEdit && <Switch isActive={isEditMode} onChange={setIsEditMode} label="Edit mode" />}
          {canEdit && (
            <ContextMenu items={dashboardContextMenuItems}>
              <MoreVerticalIcon size="24px" color="#9C9CAA" />
            </ContextMenu>
          )}
        </HeaderTopRow>

        {currentDashboard.description && (
          <Description dangerouslySetInnerHTML={{ __html: currentDashboard.description }} />
        )}
      </Header>

      {currentDashboard.layout.length === 0 && (
        <WidgetPlaceholderContainer>
          <img src={widgetPlaceholderImg} alt="" />
          <div>
            <PlaceholderTitle>No Widgets yet</PlaceholderTitle>
            {canEdit && (
              <PlaceholderSubtitle>
                Use “Edit mode” and add one or more widgets to gain insights into data.
              </PlaceholderSubtitle>
            )}
          </div>
          {isEditMode && (
            <Button onClick={handleAddWidgetClick} variant={ButtonVariant.Primary}>
              <Plus size="16px" />
              Widget
            </Button>
          )}
        </WidgetPlaceholderContainer>
      )}

      {currentDashboard.layout.length > 0 && <Grid dashboard={currentDashboard} isEditMode={isEditMode} />}
    </Container>
  );
};
