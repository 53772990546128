import React, { useCallback } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { FilesEditor } from '@components/Property/File/FilesEditor';
import { FormField } from '../../FormField';
import { FormControl, FormInputPropsToOmit } from '../../types';
import { useControllerWithValidation } from '../../useControllerWithValidation';

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends Omit<React.ComponentProps<typeof FilesEditor>, FormInputPropsToOmit>,
    FormControl<TFieldValues, TName> {
  onChanged?: () => void;
}

const FileFieldInner = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  label,
  description,
  name,
  control,
  onChanged,
  clearOnUnmount,
  ...inputProps
}: Props<TFieldValues, TName>) => {
  const {
    field: { onChange, ...controlProps },
    fieldState: { error }
  } = useControllerWithValidation(name, control, label, clearOnUnmount);

  const handleChange = useCallback(
    (value: unknown) => {
      onChange(value);
      onChanged?.();
    },
    [onChange, onChanged]
  );

  return (
    <FormField name={name} label={label} error={error?.message} description={description}>
      <FilesEditor
        {...controlProps}
        {...inputProps}
        onChange={handleChange}
        data-test-id={`field-${controlProps.name}`}
      />
    </FormField>
  );
};

export const FileField = React.memo(FileFieldInner) as typeof FileFieldInner;
