import React from 'react';
import { Popover } from '@material-ui/core';
import { MoreHorizontal } from 'react-feather';
import { filter } from 'lodash';

import { colors } from '@styles';
import { Wrapper, Menu, MenuItem, MenuLink, IconWrapper } from './styled';

interface ContextMenuProps {
  options?: MenuItemProps[];
  disable?: boolean;
  position?: string;
  icon?: React.FC<any>;
  onOpen?: () => void;
}

export interface MenuItemProps {
  name: string;
  icon?: {
    component?: React.FC<any>;
    color?: string;
    size?: string | number;
  };
  color?: string;
  onClick?: () => void;
  testId?: string;
  isHidden?: boolean;
  disable?: boolean;
  url?: string;
}

const defaultIconProps = {
  component: null,
  color: colors.green,
  size: 20
};

const defaultIconDropdown = {
  component: MoreHorizontal,
  colors: [colors.gray, colors.green]
};

export const ContextMenu: React.FC<ContextMenuProps> = (props) => {
  const { options, disable = false, icon = defaultIconDropdown, position = 'right', onOpen } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const popupId = open ? 'menu-popup-id' : undefined;

  const handleClose = (event: React.MouseEvent<SVGElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleOpen = (event: React.MouseEvent<SVGElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    if (onOpen) {
      onOpen();
    }
  };

  const handleClick = (onClick: { (): void; (): void }) => {
    onClick();
    setAnchorEl(null);
  };

  const IconDropdown = icon?.component || defaultIconDropdown.component;

  return (
    <Wrapper onClick={(e) => e.stopPropagation()}>
      {disable ? null : (
        <IconWrapper colors={icon?.colors || defaultIconDropdown.colors}>
          <IconDropdown size={icon?.size ?? 24} aria-describedby={popupId} onClick={(e) => handleOpen(e)} />
        </IconWrapper>
      )}

      <Popover
        id={popupId}
        open={open}
        anchorEl={anchorEl}
        onClose={(e) => handleClose(e)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: position
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: position
        }}
      >
        <Menu>
          {filter(options, ({ isHidden }) => !isHidden).map((item: MenuItemProps) => {
            const {
              component: Icon,
              color: iconColor = defaultIconProps.color,
              size = defaultIconProps.size
            } = item.icon || defaultIconProps;

            const { name, onClick, testId, color = colors.black } = item;

            return (
              <>
                {item.url ? (
                  <MenuLink key={name} to={item.url}>
                    {Icon ? <Icon size={size} color={iconColor} /> : null}
                    {item.name}
                  </MenuLink>
                ) : (
                  <MenuItem key={name} onClick={() => handleClick(onClick)} data-testid={testId} color={color}>
                    {Icon ? <Icon size={size} color={iconColor} /> : null}
                    {item.name}
                  </MenuItem>
                )}
              </>
            );
          })}
        </Menu>
      </Popover>
    </Wrapper>
  );
};
