import { useContext } from 'react';

import { ModalsContext } from './ModalsProvider';

const useModal = () => {
  const context = useContext(ModalsContext);

  if (!context) {
    throw new Error('useModal must be used under ModalsContext');
  }

  return context;
};

export { useModal };
