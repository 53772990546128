import React, { useMemo, useState } from 'react';
import { FileListItemProps, UploadProps } from '@common/AddFileForm/types';
import { AddFileForm } from '@common/AddFileForm';
import { useFileMutation } from '@hooks/useFiles';
import { useToast } from '@hooks';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { useRecordStageList } from '@hooks/records/useRecordStageList';
import { Select } from '@kit/ui/Select';
import { Body, Actions, UploadTo, FieldLabel } from './styled';

interface Props {
  recordId: number;
  onClose: () => void;
}

const OTHER_SECTION = { name: 'Other', id: -1 };

export const UploadFilesForm = ({ recordId, onClose }: Props) => {
  const [fileList, setFileList] = useState<FileListItemProps[]>([]);
  const { uploadFiles } = useFileMutation();
  const { showError } = useToast();

  const [selectedSection, setSelectedSection] = useState<{ id: number; name: string }>(OTHER_SECTION);

  const { data: stages = [] } = useRecordStageList(recordId);

  const sectionOptions = useMemo(() => {
    return [...stages, OTHER_SECTION];
  }, [stages]);

  const onRemove = (file: File) => setFileList(fileList.filter((item) => item !== file));

  const uploadProps: UploadProps = {
    multiple: true,
    showUploadList: false,
    beforeUpload: (file, newfileList) => {
      if (file.size / 1024 / 1024 > 350) {
        // 350mb
        showError('Maximum file size is 350 MB');

        return false;
      }
      setFileList([...fileList, ...newfileList]);

      return true;
    },
    onRemove
  };

  const submitFileHandler = async () => {
    uploadFiles.mutate(
      {
        files: fileList,
        isPrivate: true,
        stageId: selectedSection.id > 0 ? selectedSection.id : undefined,
        projectId: recordId
      },
      { onSuccess: onClose }
    );
  };

  return (
    <div>
      <Body>
        {stages.length > 0 && (
          <UploadTo>
            <FieldLabel>Upload to</FieldLabel>
            <Select
              isClearable={false}
              getOptionLabel={(option) => option.name}
              options={sectionOptions}
              value={selectedSection}
              onChange={(_e, value) => setSelectedSection(value)}
            />
          </UploadTo>
        )}
        <AddFileForm uploadProps={uploadProps} fileList={fileList} onRemove={onRemove} />
      </Body>
      <Actions>
        <Button variant={ButtonVariant.Secondary} onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant={ButtonVariant.Primary}
          disabled={fileList.length === 0 || uploadFiles.isLoading}
          onClick={submitFileHandler}
        >
          {uploadFiles.isLoading ? 'Uploading...' : 'Upload'}
        </Button>
      </Actions>
    </div>
  );
};
