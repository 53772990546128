import { handleActions } from 'redux-actions';

import { mergeToState } from '@state/reducers/helpers';
import { mapValues } from '@utils';
import {
  fetchKanbanTasksRequest,
  fetchKanbanTasksFailure,
  fetchKanbanTasksSuccess,
  taskUpdateRequest,
  taskUpdateFailure,
  taskUpdateSuccess,
  taskCreateRequest,
  taskCreateSuccess,
  taskCreateFailure,
} from './actions';
import {
  Assignee,
  AssigneeMember,
  CalendarEvent,
  CalendarTaskProject,
  Label,
} from '../types';
import { addAdditionalFields } from '../utils';

export interface CalendarReducer {
  events: CalendarEvent[];
  projects: CalendarTaskProject[]; // projects extracted from tasks (all projects represented in month)
  labels: Label[];
  members: AssigneeMember[];
  isFetching: boolean;
  fetchError: any;
  isUpdatingTask: boolean;
  updateTaskError: any;
  isCreatingTask: boolean;
  createTaskError: any;
}

const calendarDefaultReducer: CalendarReducer = {
  events: [],
  projects: [],
  labels: [],
  members: [],
  isFetching: false,
  fetchError: null,
  isUpdatingTask: false,
  updateTaskError: null,
  isCreatingTask: false,
  createTaskError: null
};

const fetchKanbanTasksRequestReducer = (state: CalendarReducer) => ({
  ...state,
  events: [],
  projects: [],
  isFetching: true,
  fetchError: null
});

const fetchKanbanTasksErrorReducer = (
  state: CalendarReducer,
  { payload }: { payload?: { error?: any } }
) => {
  const error = payload?.error;
  return { ...state, isFetching: false, fetchError: error };
};

const extraFilters = {
  members: [
    { id: 'me', label: 'Me' },
    { id: 'unassigned', label: 'Unassigned' },
  ],
  labels: [
    { id: 'unlabelled', label: 'Unlabelled' }
  ],
  projects: [
    { id: 'overdue', title: 'Overdue' }
  ],
};

const injectExtraFilters = (data) => (
  mapValues(data, (value, key) => {
    if (key in extraFilters) {
      return [...extraFilters[key], ...value];
    }

    return value;
  })
);

const refreshFilters = (tasks: CalendarEvent[]) => {
  const projects = tasks
    .map((task) => task.project)
    .filter(
      (project, index, self) =>
        self.findIndex((p) => p.id === project.id) === index
    );


  const uniqLabels = tasks
    .reduce((accumulator, task) => {
      if (task.projectStage) {
        accumulator.push({
          id: (-task.projectStage.id),
          label: task.projectStage.name
        });
      }

      return accumulator.concat(task.labels);
    }, [] as CalendarEvent['labels'])
    .filter(
      (label, index, self) => self.findIndex((l) => l.id === label.id) === index
    );
  const uniqMembers = tasks
    .map((task) => task.assignees)
    .reduce(
      (accumulator: AssigneeMember[], assignees: Assignee[]) =>
        accumulator.concat(
          assignees.map(
            (assignee: Assignee) => assignee.member
          ) as AssigneeMember[]
        ),
      [] as AssigneeMember[]
    )
    .filter(
      (assignee, index, self) =>
        self.findIndex((a) => a?.id === assignee?.id) === index
    );

  return injectExtraFilters({
    projects, // .title
    labels: uniqLabels, // .label
    members: uniqMembers // .member.firstName + .member.lastName
  });
};

const fetchKanbanTasksSuccessReducer = (
  state: CalendarReducer,
  { payload }: { payload: { tasks: CalendarEvent[] } }
) => {
  const { tasks } = payload;
  const events = tasks.map(addAdditionalFields);
  const filters = refreshFilters(tasks);

  return {
    ...state,
    ...filters,
    events, // .title
    isFetching: false,
    fetchError: null
  };
};

const taskUpdateRequestReduce = (state: CalendarReducer) => ({
  ...state,
  isUpdatingTask: true,
  updateTaskError: null
});

const taskUpdateErrorReducer = (
  state: CalendarReducer,
  { payload }: { payload: { error: any } }
) => {
  const { error } = payload;
  return { ...state, isUpdatingTask: false, updateTaskError: error };
};

const taskCreateErrorReducer = (
  state: CalendarReducer,
  { payload }: { payload: { error: any } }
) => {
  const { error } = payload;
  return { ...state, isCreatingTask: false, createTaskError: error };
};

const taskUpdateSuccessReducer = (
  state: CalendarReducer,
  { payload }: { payload: { task: CalendarEvent } }
) => {
  const { task } = payload;
  const { events: oldEvents } = state;
  let taskFound = false;
  const events = oldEvents.map((e) => {
    if (e.id === task.id) {
      taskFound = true;

      return addAdditionalFields({ ...e, ...task });
    }

    return e;
  });

  if (!taskFound) {
    events.push(task);
  }

  const filters = refreshFilters(events);

  return {
    ...state,
    ...filters,
    events,
    isUpdatingTask: false,
    updateTaskError: null
  };
};

const taskCreateSuccessReducer = (
  state: CalendarReducer,
  { payload }: { payload: { task: CalendarEvent } }
) => {
  const { task } = payload;
  const { events: oldEvents } = state;
  const events = [...oldEvents, addAdditionalFields(task)];

  const filters = refreshFilters(events);

  return {
    ...state,
    ...filters,
    events,
    isUpdatingTask: false,
    updateTaskError: null
  };
};

const calendarReducer = handleActions(
  {
    [fetchKanbanTasksRequest]: fetchKanbanTasksRequestReducer,
    [fetchKanbanTasksSuccess]: fetchKanbanTasksSuccessReducer,
    [fetchKanbanTasksFailure]: fetchKanbanTasksErrorReducer,
    [taskUpdateRequest]: taskUpdateRequestReduce,
    [taskUpdateSuccess]: taskUpdateSuccessReducer,
    [taskUpdateFailure]: taskUpdateErrorReducer,
    [taskCreateRequest]: mergeToState({
      isCreating: true,
      createTaskError: null
    }),
    [taskCreateSuccess]: taskCreateSuccessReducer,
    [taskCreateFailure]: taskCreateErrorReducer
  },
  calendarDefaultReducer
);
export default calendarReducer;
