import { colors, scroll } from '@styles';
import styled from 'styled-components';

export const FormField = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;

  > *:last-child {
    width: 100%;
  }
`;

export const FieldLabel = styled.div`
  color: #828d9a;
  font-size: 12px;
  font-weight: 400;
  width: 48px;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`;

export const BodyWithActionButtons = styled.div`
  position: relative;
`;

export const ActionButtons = styled.div<{ isBodyWithError: boolean }>`
  position: absolute;
  z-index: 1;
  bottom: ${({ isBodyWithError }) => (isBodyWithError ? '26px' : '8px')};
  right: 12px;

  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ExpandButton = styled.div`
  color: #9c9caa;
  transition: color 0.2s ease-in-out;
  cursor: pointer;

  margin-right: 16px;

  display: flex;
  align-items: center;
  gap: 8px;

  font-size: 12px;
  font-weight: 400;

  &:hover {
    color: ${colors.black};
  }
`;

export const ModalBody = styled.div`
  padding: 24px;
  max-height: 90vh;
  width: 75vw;
  overflow-y: auto;

  ${scroll};

  .ql-editor {
    max-height: none;
  }
`;
