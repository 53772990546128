import styled from 'styled-components';
import { TableCell, TableCellProps } from '@material-ui/core';

const borderWidth = 1;
const borderColor = '#e2e2e2';
const borderRadius = 4;
const padding = 10;

interface Props extends TableCellProps {
  colspan?: number;
  width?: number;
  isSticky?: boolean;
  stickyPosition?: number;
  noBorder?: boolean;
  styles?: any;
  reportWidth?: boolean;
  isEditing?: boolean;
}

export const StyledTableCell = styled(TableCell)<Props>`
  && {
    &.MuiTableCell-root {
      width: ${({ width }) => (typeof width !== 'undefined' ? `${width}px` : 'auto')};
      min-width: ${({ width }) => (typeof width !== 'undefined' ? `${width}px` : 'auto')};
      max-width: ${({ width }) => (typeof width !== 'undefined' ? `${width}px` : 'auto')};
      height: 44px;
      padding: 0 ${padding}px;
      background-color: ${({ theme, isSticky }) => (isSticky ? theme.colors.white : 'transparent')};
      border-left: ${({ noBorder }) => (noBorder ? 'none' : `${borderWidth}px solid ${borderColor}`)};
      border-bottom: ${borderWidth}px solid ${borderColor};
      box-shadow: ${({ noBorder }) => (noBorder ? 'none' : 'rgb(216 216 216) 1px 0px 0px')};
      font: ${({ theme }) => theme.typefaces.text};
      position: ${({ isSticky }) => (isSticky ? 'sticky' : 'relative')};
      left: ${({ isSticky, stickyPosition }) => (isSticky ? `${stickyPosition}px` : 'auto')};
      z-index: ${({ isSticky }) => (isSticky ? '1090' : 'auto')};
      overflow: ${({ isEditing }) => (isEditing ? 'visible' : 'hidden')};
      text-overflow: ellipsis;
      white-space: nowrap;

      &:last-child {
        border-right: none;
      }
    }

    &.MuiTableCell-head {
      background-color: ${(props) => props.theme.colors.gray4};
      color: ${({ theme }) => theme.colors.black};
      border-top: 1px solid ${borderColor};
      font-size: 16px;
      line-height: 1.25;

      &:first-child {
        border-top-left-radius: ${borderRadius}px;
        border-top: 1px solid ${borderColor};
        border-left: 1px solid ${borderColor};
      }

      &:last-child {
        border-top-right-radius: ${borderRadius}px;
        border-top: 1px solid ${borderColor};
        border-right: 1px solid ${borderColor};
      }

      &:nth-child(2) {
        overflow: visible;
      }
    }

    &.MuiTableCell-body {
      color: ${({ theme }) => theme.colors.black};

      &:first-child {
        border-left: 1px solid ${borderColor};
      }

      &:last-child {
        border-right: 1px solid ${borderColor};
      }
    }

    &.MuiTableCell-footer {
      background-color: ${(props) => props.theme.colors.gray4};
      padding: 0 10px;
      z-index: 0;

      &:first-child {
        border-bottom-left-radius: ${borderRadius}px;
        border-left: 1px solid ${borderColor};
      }

      &:last-child {
        border-bottom-right-radius: ${borderRadius}px;
        border-right: 1px solid ${borderColor};
      }
    }
  }
`;
