import { WidgetFilter, WidgetFilters } from '@features/Analytics/types';
import { isDate } from 'lodash';
import { FilterField, FilterTree } from './types';
import { OPERATORS } from './useFilterOperatorOptions';

const mapValue = (value: unknown) => {
  if (Array.isArray(value)) {
    return value.map((val) => mapValue(val));
  }

  if (isDate(value)) {
    return value.toISOString();
  }

  if (value instanceof Object) {
    return 'id' in value ? value.id : value;
  }

  return value;
};

export const mapFormFilterTreeToWidgetFilters = (filterTree: FilterTree): WidgetFilters => {
  const mapFilterTree = (node: FilterTree): WidgetFilters => {
    const children = node.children
      .map((child) => {
        if ('children' in child) {
          return mapFilterTree(child);
        } else {
          if (!child.field || !child.operator) {
            return null;
          }

          return {
            id: child.id,
            fieldId: child.field.id,
            operator: child.operator.op,
            value: mapValue(child.value)
          };
        }
      })
      .filter((child) => child && ('fieldId' in child ? child.fieldId && child.operator : true));

    if (!children.length) {
      return null;
    }

    return {
      id: node.id,
      operator: node.operator,
      children
    };
  };

  const tree = mapFilterTree(filterTree);

  if (!tree || !tree.children.length) {
    return null;
  }

  return tree;
};

export const mapWidgetFiltersToFormFilterTree = (filters: WidgetFilters, options: FilterField[]): FilterTree => {
  const mapFilters = (node: WidgetFilters): FilterTree => {
    return {
      id: node.id,
      operator: node.operator,
      children: node.children.map((child) => {
        if ('children' in child) {
          return mapFilters(child);
        } else {
          return {
            id: child.id,
            field: options.find((option) => option.id === child.fieldId) ?? null,
            operator: OPERATORS.find((operator) => operator.op === child.operator) ?? null,
            value: child.value
          };
        }
      })
    };
  };

  return mapFilters(filters);
};

export const countSelectedFilters = (filters?: (WidgetFilters | WidgetFilter)[]): number => {
  if (!filters) {
    return 0;
  }

  return filters.reduce((acc, filter) => {
    if (!filter) {
      return acc;
    }

    if ('children' in filter) {
      return acc + countSelectedFilters(filter.children);
    }

    return acc + 1;
  }, 0);
};
