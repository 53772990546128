import { scroll, colors } from '@styles';
import styled, { css } from 'styled-components';

export const Activity = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  ${scroll};

  & > div:first-of-type > * {
    border-top: 0;
  }
`;

export const TitleIcon = styled.div`
  background-color: #ecd4f0;
  border-radius: 50%;
  padding: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 16px 16px 0 16px;
`;

export const Toggle = styled.div<{ isCollapsed: boolean }>`
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  margin-left: ${(props) => (props.isCollapsed ? '-42px' : '')};
`;

export const Title = styled.div<{ isRotated: boolean }>`
  color: var(--text-colors-text-dark-1, ${colors.black});
  font-size: 18px;
  font-style: normal;
  font-weight: 500;

  display: flex;
  align-items: center;
  gap: 8px;

  ${(props) =>
    props.isRotated
      ? css`
          font-size: 14px;
          font-weight: 400;
          transform: rotate(-90deg);
          transform-origin: center;
          position: absolute;
          right: calc(100% + 3px);
          top: 50px;
          white-space: nowrap;
          height: 100%;

          ${TitleIcon} {
            display: none;
          }
        `
      : ''}
`;

export const HistoryContainer = styled.div`
  display: flex;
  height: auto;
  flex-direction: column;

  .ql-mention-list-container {
    width: 100%;
  }
`;

export const Container = styled.div`
  display: flex;
  overflow: auto;
  height: auto;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 0 16px;
`;
