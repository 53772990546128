import React, { useCallback } from 'react';

import { AlertTriangle } from 'react-feather';
import { colors } from '@styles';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { useModal } from './useModal';

import { ConfirmButtons, ConfirmModalBody, WarningIconWrapper } from './styled';

interface ConfirmProps {
  confirmButtonVariant?: ButtonVariant;
  confirmButtonText?: string;
  cancelButtonText?: string;
  confirmText: React.ReactNode;
}

interface ConfirmModalProps extends ConfirmProps {
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmModal = ({
  onConfirm,
  onCancel,
  confirmButtonVariant = ButtonVariant.Primary,
  confirmText,
  confirmButtonText = 'Confirm',
  cancelButtonText = 'Cancel'
}: ConfirmModalProps) => (
  <div>
    <ConfirmModalBody>
      <WarningIconWrapper>
        <AlertTriangle size={60} color={colors.green} />
      </WarningIconWrapper>
      {confirmText}
    </ConfirmModalBody>
    <ConfirmButtons>
      <Button variant={ButtonVariant.Secondary} data-test-id="confirm-modal-cancel-button" onClick={onCancel}>
        {cancelButtonText}
      </Button>
      <Button variant={confirmButtonVariant} data-test-id="confirm-modal-confirm-button" onClick={onConfirm}>
        {confirmButtonText}
      </Button>
    </ConfirmButtons>
  </div>
);

export const useConfirmModal = () => {
  const { openModal } = useModal();

  return useCallback(
    async (props: ConfirmProps) =>
      openModal(
        ({ onClose }: { onClose: (value: boolean | undefined) => void }) => (
          <ConfirmModal {...props} onConfirm={() => onClose(true)} onCancel={() => onClose(false)} />
        ),
        { title: 'Confirm' }
      ),
    [openModal]
  );
};

export const useConfirmDeleteModal = () => {
  const confirm = useConfirmModal();

  return useCallback(
    (confirmText: React.ReactNode, confirmButtonText: string = 'Delete') =>
      confirm({
        confirmButtonText,
        confirmButtonVariant: ButtonVariant.Danger,
        confirmText
      }),
    [confirm]
  );
};
