import { DateTime, Interval } from 'luxon';
import { ChartPeriod, chartPeriodConfig } from '@hooks/systems';

export const formatHits = (hits: number) => (hits != null ? hits.toLocaleString('en-US', { useGrouping: true }) : '');

const powerToLocaleString = (watts: number) => watts.toLocaleString('en-US', { maximumFractionDigits: 2 });

export const formatPower = (watts: number) => {
  if (watts == null) {
    return '';
  }

  const abs = Math.abs(watts);

  if (abs < 1000) {
    return powerToLocaleString(watts) + ' W';
  } else if (abs < 1_000_000) {
    return powerToLocaleString(watts / 1000.0) + ' kW';
  } else if (abs < 1_000_000_000) {
    return powerToLocaleString(watts / 1_000_000) + ' MW';
  } else {
    return powerToLocaleString(watts / 1_000_000_000) + ' GW';
  }
};

const energyToLocaleString = (watthours: number) => watthours.toLocaleString('en-US', { maximumFractionDigits: 2 });

export const formatEnergy = (watthours: number) => {
  if (watthours == null) {
    return '';
  }

  const abs = Math.abs(watthours);

  if (abs < 1000) {
    return energyToLocaleString(watthours) + ' Wh';
  } else if (abs < 1_000_000) {
    return energyToLocaleString(watthours / 1000.0) + ' kWh';
  } else if (abs < 1_000_000_000) {
    return energyToLocaleString(watthours / 1_000_000) + ' MWh';
  } else {
    return energyToLocaleString(watthours / 1_000_000_000) + ' GWh';
  }
};

export const gapfillPoints = <T extends { time: DateTime }>(
  points: T[],
  period: ChartPeriod,
  kind: 'power' | 'energy',
  observedInterval: Interval | null
): T[] => {
  const periodConfig = chartPeriodConfig[period];
  const periodAlign = periodConfig.pointAlignment;

  const gapfillStart = observedInterval?.start || points[0]?.time.startOf(periodAlign) || null;
  const gapfillEnd = observedInterval?.end || points[points.length - 1]?.time.startOf(periodAlign) || null;
  if (!gapfillStart || !gapfillEnd) {
    return points;
  }

  const fillInterval = Interval.fromDateTimes(gapfillStart, gapfillEnd);
  if (!fillInterval.isValid || fillInterval.start.equals(fillInterval.end)) {
    return points;
  }

  const subintervals = fillInterval.splitBy(periodConfig.pointGranularity);

  let pointsIndex = 0;

  return subintervals.map((interval) => {
    const point = points[pointsIndex];

    switch (kind) {
      case 'power': {
        if (point && +point.time.startOf(periodAlign) === +interval.start.startOf(periodAlign)) {
          pointsIndex += 1;

          return point;
        }

        return { time: interval.start } as T;
      }

      case 'energy': {
        if (point && +point.time.startOf(periodAlign) === +interval.end.startOf(periodAlign)) {
          pointsIndex += 1;

          return point;
        }

        return { time: interval.end } as T;
      }

      default:
        throw new Error(`Unrecognized points kind ${kind satisfies never}`);
    }
  });
};
