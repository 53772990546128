import React from 'react';
import { Upload as UploadIcon } from 'react-feather';
import { Upload } from 'antd';
import { colors } from '@styles';
import { AddFileFormProps } from './types';
import { Wrapper, Header, FileList } from './styled';
import FileListItem from './FileListItem';

const { Dragger } = Upload;

export const AddFileForm: React.FC<AddFileFormProps> = (props) => {
  const { onRemove, fileList, uploadProps, noFileList } = props;

  return (
    <>
      <Wrapper data-testid="addFileModal">
        <Dragger {...uploadProps}>
          <Header>
            <UploadIcon size={16} color={colors.green} />
            <span data-testid="addFileChooseFile">
              drag & drop a file here or <strong>browse</strong>
            </span>
          </Header>
          {/* <Button> */}
          {/*  <img src={googleDriveLogo} /> */}
          {/*  CONNECT TO GOOGLE DRIVE */}
          {/* </Button> */}
          {/* <Button> */}
          {/*  <img src={dropboxLogo} /> */}
          {/*  CONNECT TO DROPBOX */}
          {/* </Button> */}
        </Dragger>
      </Wrapper>
      {noFileList
        ? null
        : fileList?.length > 0 && (
            <FileList>
              {fileList.map((file) => (
                <FileListItem
                  lastModified={file.lastModified}
                  name={file.name}
                  size={file.size}
                  uid={file.uid}
                  lastModifiedDate={file.lastModifiedDate}
                  onRemove={() => onRemove?.(file)}
                  key={file.uid}
                />
              ))}
            </FileList>
          )}
    </>
  );
};
