import { useCallback } from 'react';
import { useAppDispatch } from '@hooks/store';
import { alertShow } from '@state/actions/alert/alertAction';

export const useToast = () => {
  const dispatch = useAppDispatch();

  const showSuccess = useCallback((message) => (
    dispatch(alertShow([message], 'success'))
  ), [dispatch]);

  const showInfo = useCallback((message) => (
    dispatch(alertShow([message], 'info'))
  ), [dispatch]);

  const showWarning = useCallback((message) => (
    dispatch(alertShow([message], 'warning'))
  ), [dispatch]);

  const showError = useCallback((message) => (
    dispatch(alertShow([message], 'error'))
  ), [dispatch]);

  const showMessage = useCallback((message: string, type: 'success' | 'info' | 'warning' | 'error') => (
    dispatch(alertShow([message], type))
  ), [dispatch]);

  return {
    showSuccess,
    showInfo,
    showWarning,
    showError,
    showMessage
  };
};
