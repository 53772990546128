import React, { ChangeEventHandler, useCallback, useState } from 'react';
import { SearchBar } from '@common/SearchBar';
import { Switch } from '@kit/ui/Switch';
import { Container, Header } from './styled';
import { SortBy } from './SortBy';
import { SortByDirection, SortByField } from './types';
import { WorkOrders } from './WorkOrders';
import { ActionItems } from './ActionItems';

interface Props {
  recordId: number;
  system?: { id: number; name: string };
}

export const FullWork = ({ recordId, system }: Props) => {
  const [isArchivedShown, setIsArchivedShown] = useState(false);
  const [search, setSearch] = useState('');

  const [sortBy, setSortBy] = useState<{ field: SortByField; direction: SortByDirection }>({
    field: 'priority',
    direction: 'DESC'
  });

  const handleSearchChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setSearch(event.target.value);
  }, []);

  return (
    <Container>
      <Header>
        <SearchBar placeholder="Search..." onChange={handleSearchChange} />
        <SortBy field={sortBy.field} direction={sortBy.direction} onChange={setSortBy} />
        <Switch labelPosition="left" label="Show archived" isActive={isArchivedShown} onChange={setIsArchivedShown} />
      </Header>

      <WorkOrders
        system={system}
        recordId={recordId}
        search={search}
        sortBy={sortBy}
        isArchivedShown={isArchivedShown}
      />
      {!system && <ActionItems recordId={recordId} search={search} sortBy={sortBy} isArchivedShown={isArchivedShown} />}
    </Container>
  );
};
