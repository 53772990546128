import React from 'react';
import { FileText } from 'react-feather';
import { formSVG } from '@assets/svg';
import { File } from '@generated/types/graphql';
import { FileImageThumbnail, Icon } from './styled';

interface Props {
  file: File;
}

export const FileThumbnail = ({ file }: Props) => {
  if (file.metaData?.thumbnailUrl) {
    return <FileImageThumbnail src={file.metaData.thumbnailUrl} size={20} />;
  }

  return (
    <Icon>
      {file.form && <img src={formSVG} width={20} height={20} alt="" />}
      {!file.form && <FileText size={20} color="#98A9BC" />}
    </Icon>
  );
};
