import React, { useEffect, useMemo, useState } from 'react';
import { DEFAULT_GROUP_ID, usePropertyGroups } from '@hooks/usePropertyGroup';
import { Tabs } from '@kit/ui/Tabs';
import { getPropertyValueById, isEmptyValue } from '@utils/properties';
import { RecordType } from '@types';
import { PropertiesGrid } from './FullProperties/styled';
import { CustomPropertiesContainer, CustomerPropertiesTabsContainer } from './styled';
import { Property, useRecordWithProperties } from './FullProperties/FullProperties';

interface Props {
  recordId: number;
}

export const CustomProperties = React.memo(({ recordId }: Props) => {
  const [tab, setTab] = useState(null);

  const record = useRecordWithProperties(recordId);

  const { data: groups } = usePropertyGroups({ scope: record.projectDetail.type as RecordType });

  const tabs = useMemo(() => {
    if (!groups || !record) {
      return [];
    }

    return groups
      .filter((group) => group.id !== DEFAULT_GROUP_ID && group.properties.length > 0)
      .filter(
        (group) =>
          group.properties.length > 0 &&
          group.properties.some((property) => !isEmptyValue(getPropertyValueById(record, property)))
      )
      .map((group) => ({
        id: group.id,
        title: group.name,
        ...group
      }));
  }, [groups, record]);

  useEffect(() => {
    if (tab || !tabs.length) {
      return;
    }

    if (tabs && tabs.length > 0) {
      setTab(tabs[0]);
    }
  }, [tabs, tab]);

  if (!tab || !record) {
    return null;
  }

  return (
    <CustomPropertiesContainer>
      <CustomerPropertiesTabsContainer>
        <Tabs variant="outline" tabs={tabs} selected={tab.id} onChange={setTab} />
      </CustomerPropertiesTabsContainer>
      <PropertiesGrid maxHeight="220px">
        {tab.properties
          .filter((property) => !isEmptyValue(getPropertyValueById(record, property)))
          .map((property) => (
            <Property key={property.id} projectId={recordId} property={property} />
          ))}
      </PropertiesGrid>
    </CustomPropertiesContainer>
  );
});
