import React from 'react';

interface Props {
  size?: string | number;
  color?: string;
}

export const PinFilledIcon = React.forwardRef<SVGSVGElement, Props>(
  ({ size = '16px', color = 'currentColor', ...props }, ref) => (
    <svg
      ref={ref}
      {...props}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0772 2.1398L10.1399 2.19514L13.8065 5.8618C13.9189 5.97479 13.9871 6.12429 13.9988 6.28322C14.0104 6.44215 13.9647 6.6 13.87 6.72816C13.7753 6.85633 13.6378 6.94632 13.4825 6.98184C13.3271 7.01737 13.1642 6.99607 13.0232 6.9218L10.9085 9.0358L9.95921 11.5671C9.9342 11.6339 9.89864 11.6963 9.85388 11.7518L9.80721 11.8051L8.80721 12.8051C8.69233 12.9198 8.53955 12.9887 8.37754 12.9987C8.21552 13.0088 8.0554 12.9594 7.92721 12.8598L7.86388 12.8045L6.00188 10.9431L3.47321 13.4711C3.35324 13.5907 3.19225 13.6601 3.02295 13.6653C2.85365 13.6705 2.68873 13.611 2.56169 13.499C2.43465 13.3869 2.35501 13.2308 2.33894 13.0622C2.32288 12.8935 2.3716 12.7251 2.47521 12.5911L2.53054 12.5285L5.05854 9.9998L3.19721 8.1378C3.08243 8.02301 3.01347 7.87027 3.00328 7.70826C2.9931 7.54624 3.04238 7.38607 3.14188 7.2578L3.19721 7.19514L4.19721 6.19514C4.24751 6.14465 4.30558 6.10256 4.36921 6.07047L4.43454 6.04247L6.96521 5.09247L9.07921 2.97914C9.00712 2.84436 8.98354 2.6889 9.0124 2.5388C9.04127 2.38871 9.12084 2.25309 9.23778 2.15467C9.35471 2.05624 9.50192 2.00099 9.65474 1.99816C9.80756 1.99533 9.95671 2.04577 10.0772 2.1398Z"
        fill={color}
      />
    </svg>
  )
);
