import React from 'react';
import { Skeleton } from '@material-ui/lab';

import { Container, Info } from './styled';

export const HeaderSkeleton = () => {
  return (
    <Container>
      <Info>
        <Skeleton animation="wave" width={250} height={32} />

        <Skeleton animation="wave" height={24} width={80} />
        <Skeleton animation="wave" height={26} />
      </Info>
    </Container>
  );
};
