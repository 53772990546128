import { PredefinedWidgetFilterFieldId } from '../types';
import { FilterFieldType, WorkOrderFilterField } from './types';

export const WORK_ORDERS_FILTER_OPTIONS: WorkOrderFilterField[] = [
  {
    id: PredefinedWidgetFilterFieldId.WORK_ORDER_TYPE,
    name: 'Type',
    type: FilterFieldType.WORK_ORDER_ATTRIBUTE
  },
  {
    id: PredefinedWidgetFilterFieldId.WORK_ORDER_TEMPLATE,
    name: 'Template',
    type: FilterFieldType.WORK_ORDER_ATTRIBUTE
  },
  {
    id: PredefinedWidgetFilterFieldId.WORK_ORDER_TITLE,
    name: 'Title',
    type: FilterFieldType.WORK_ORDER_ATTRIBUTE
  },
  {
    id: PredefinedWidgetFilterFieldId.WORK_ORDER_STATUS,
    name: 'Status',
    type: FilterFieldType.WORK_ORDER_ATTRIBUTE
  },
  {
    id: PredefinedWidgetFilterFieldId.WORK_ORDER_PRIORITY,
    name: 'Priority',
    type: FilterFieldType.WORK_ORDER_ATTRIBUTE
  },
  {
    id: PredefinedWidgetFilterFieldId.WORK_ORDER_LABEL,
    name: 'Label',
    type: FilterFieldType.WORK_ORDER_ATTRIBUTE
  },
  {
    id: PredefinedWidgetFilterFieldId.WORK_ORDER_ASSIGNEE,
    name: 'Assignee',
    type: FilterFieldType.WORK_ORDER_ATTRIBUTE
  },
  {
    id: PredefinedWidgetFilterFieldId.WORK_ORDER_CREATED_AT,
    name: 'Created at',
    type: FilterFieldType.WORK_ORDER_ATTRIBUTE
  },
  {
    id: PredefinedWidgetFilterFieldId.WORK_ORDER_DESCRIPTION,
    name: 'Description',
    type: FilterFieldType.WORK_ORDER_ATTRIBUTE
  },
  {
    id: PredefinedWidgetFilterFieldId.WORK_ORDER_COMPLETED_AT,
    name: 'Completed at',
    type: FilterFieldType.WORK_ORDER_ATTRIBUTE
  },
  {
    id: PredefinedWidgetFilterFieldId.WORK_ORDER_START_DATE,
    name: 'Start date',
    type: FilterFieldType.WORK_ORDER_ATTRIBUTE
  },
  {
    id: PredefinedWidgetFilterFieldId.WORK_ORDER_END_DATE,
    name: 'End date',
    type: FilterFieldType.WORK_ORDER_ATTRIBUTE
  },
  {
    id: PredefinedWidgetFilterFieldId.WORK_ORDER_NEXT_VISIT_DATE,
    name: 'Next visit date',
    type: FilterFieldType.WORK_ORDER_ATTRIBUTE
  }
];
