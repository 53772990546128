import styled, { css } from 'styled-components';
import { X } from 'react-feather';
import { Badge } from '@components/common/Badge';

export const FileListInput = styled.div`
  ${({ theme: { colors } }) => css`
    background-color: ${colors.white};
    padding: 7px;
    border: 1px solid ${colors.gray3};
    border-radius: 4px;
    margin-bottom: 5px;
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  width: 100%;
`;

export const FileImageThumbnail = styled.img`
  object-fit: fill;
  width: 24px;
  height: 24px;
  border-radius: 4px;
`;

export const FileX = styled(X)`
  ${({ theme: { colors } }) => css`
    margin: 0 2px 0 -2px;
    color: ${colors.gray};
    transition: 0.3s ease-out;
    cursor: pointer;

    &:hover, &:focus {
      color: ${colors.gray2};
    }
  `}
`;

export const FileImageStyledBadge = styled(Badge)<{ clickable: boolean; }>`
  padding: 0;
  
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
`;
