import { File } from '@generated/types/graphql';

export const MaxFileSize = 1024 * 1024 * 100;

export const isImageMimeType = (mimeType: string) => mimeType.startsWith('image/');

export const isImageFile = (file: File) => isImageMimeType(file.metaData?.mimeType ?? '');

export const isPdfFile = (file: File) => file.metaData?.mimeType?.startsWith('application/pdf');

const officeMimeTypes = [
  // Word mime types
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',

  // Excel mime types
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',

  // PowerPoint mime types
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation'
];
export const isOfficeFile = (file: File) => officeMimeTypes.includes(file.metaData?.mimeType);
export const isForm = (file: File) => file?.isForm || file?.form != null || file?.formId != null;
export const isVideo = (file: Pick<File, 'metaData'>) => file.metaData?.mimeType?.startsWith('video/');

export const isPreviewableFile = (item: { file: File }) =>
  item.file &&
  (isPdfFile(item.file) ||
    isImageFile(item.file) ||
    isForm(item.file) ||
    isOfficeFile(item.file) ||
    isVideo(item.file));

export const getFixedFilename = (filename: string) =>
  filename.length > 15 ? `${filename.slice(0, 5)}...${filename.slice(-6)}` : filename;

export const fileSizeInMB = (sizeInBytes: number): number =>
  sizeInBytes && sizeInBytes > 0 ? sizeInBytes / 1024 / 1024 : 0;

const getFileNameAndExtension = (name: string) => {
  const structure = name.split('.');

  if (structure.length <= 1) {
    return structure[0];
  }

  return [structure.slice(0, name.length - 1).join(''), structure[structure.length - 1]];
};

const getFileNameDuplicateCounter = (onlyName: string) => {
  const m = /\s\((\d+)\)$/.exec(onlyName);

  return m ? [m[0], +m[1]] : [];
};

const incrementFileName = (name: string) => {
  const [fileName, ext] = getFileNameAndExtension(name);
  const [counterText, currentCounter] = getFileNameDuplicateCounter(fileName);
  const fileNameWithoutCounter = counterText ? fileName.replace(counterText as string, '') : fileName;

  return `${fileNameWithoutCounter} (${(+currentCounter ?? 0) + 1}.${ext}`;
};

export const getUniqueIncrementedName = (name: string, existingNames: string[]): string => {
  const sameNameFound = existingNames.some((existingName) => existingName === name);

  if (sameNameFound) {
    return getUniqueIncrementedName(incrementFileName(name), existingNames);
  }

  return name;
};

export const formatVideoDuration = (durationInSeconds: number) => {
  const seconds = Math.ceil(durationInSeconds);

  if (seconds < 60) {
    return `${seconds}s`;
  }

  if (seconds < 3600) {
    return `${Math.round(seconds / 60)}m`;
  }

  return `${Math.round(seconds / 3600)}h`;
};
