import React from 'react';
import { FormControlLabel, RadioGroup as MuiRadioGroup } from '@material-ui/core';
import { useRadioGroupStyles, useRadioStyles } from './styled';
import { Radio } from './Radio';

interface Props<T> {
  value: T;
  name: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  options: { value: string; label: string }[];
  layout?: 'row' | 'column';
  renderOptionLabel?: (option: { value: string; label: string }, isSelected: boolean) => React.ReactNode;
}

export const RadioGroup = <T,>({ value, name, options, onChange, layout = 'column', renderOptionLabel }: Props<T>) => {
  const radioGroupClasses = useRadioGroupStyles({ layout })();
  const classes = useRadioStyles();

  return (
    <MuiRadioGroup
      classes={radioGroupClasses}
      className="radio-group"
      aria-label={name}
      name={name}
      value={value}
      onChange={onChange}
      row={layout === 'row'}
    >
      {options.map((option) => (
        <FormControlLabel
          classes={{ label: classes.label }}
          className={option.value === value ? 'selected' : ''}
          key={option.value}
          value={option.value}
          control={<Radio />}
          label={renderOptionLabel ? renderOptionLabel(option, option.value === value) : option.label}
        />
      ))}
    </MuiRadioGroup>
  );
};
