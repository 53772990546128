import React from 'react';

export const FunnelChartIcon = ({
  size = '16px',
  color = 'currentColor'
}: {
  size?: string | number;
  color?: string;
}) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 1.5C21.5523 1.5 22 1.94772 22 2.5L22 6.5C22 7.05229 21.5523 7.5 21 7.5L3 7.5C2.44772 7.5 2 7.05228 2 6.5L2 2.5C2 1.94771 2.44772 1.5 3 1.5L21 1.5ZM20 3.5L4 3.5L4 5.5L20 5.5L20 3.5Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5 9C19.0523 9 19.5 9.44772 19.5 10L19.5 14C19.5 14.5523 19.0523 15 18.5 15L5.5 15C4.94771 15 4.5 14.5523 4.5 14L4.5 10C4.5 9.44771 4.94771 9 5.5 9L18.5 9ZM17.5 11L6.5 11L6.5 13L17.5 13L17.5 11Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 16.5C16.5523 16.5 17 16.9477 17 17.5L17 21.5C17 22.0523 16.5523 22.5 16 22.5L8 22.5C7.44771 22.5 7 22.0523 7 21.5L7 17.5C7 16.9477 7.44771 16.5 8 16.5L16 16.5ZM15 18.5L9 18.5L9 20.5L15 20.5L15 18.5Z"
      fill={color}
    />
  </svg>
);
