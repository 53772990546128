import { Input } from '@kit/ui/Input';
import { colors } from '@styles';
import styled from 'styled-components';
import { Link } from '@reach/router';

export const Container = styled.div`
  display: flex;
  padding: 16px;
  border-bottom: 1px solid #dfdfe8;
  gap: 16px;
  height: 120px;

  svg {
    flex-shrink: 0;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  overflow: hidden;
`;

export const IconInsideImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 2;
  translate: translate(-50%, -50%);
`;

export const ImageContainer = styled.div<{ isUpdating: boolean }>`
  height: 88px;
  width: 88px;
  position: relative;
  border-radius: 8px;

  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 88px;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dfdfe8;

  svg {
    opacity: ${({ isUpdating }) => (isUpdating ? 1 : 0)};
    transition: opacity 0.15s ease-in-out;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-radius: 8px;
    transition: background-color 0.15s ease-in-out;
  }

  :hover {
    cursor: pointer;

    &:after {
      background-color: rgba(29, 29, 53, 0.5);
    }

    svg {
      opacity: 1;
      z-index: 1;
    }
  }
`;

export const Image = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 88px;
  width: 88px;
  border-radius: 8px;
`;

export const FirstRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
`;

export const CardInfoBlockHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;

  .moreMenu {
    color: #98a9bc;
    transition: color 0.15s ease-in-out;

    &:hover {
      color: ${colors.green};
    }
  }
`;

export const TitleAndStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 0;

  svg {
    flex-shrink: 0;
  }
`;

export const InfoTitle = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #1d1d35;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const PreTitle = styled.p`
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #1d1d35;
  white-space: nowrap;
`;

export const Workflow = styled.div`
  min-height: 46px;
`;

export const NoWorkflowName = styled.div`
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 400;
  height: 14px;
  color: #235dff;
  cursor: pointer;
`;

export const WorkflowNameAndEdit = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;

  svg {
    cursor: pointer;
  }
`;

export const WorkflowNameReadOnly = styled.div`
  font-size: 12px;
  font-weight: 400;
  display: block;
  color: #828d9a;
`;

export const WorkflowName = styled(Link)`
  font-size: 12px;
  font-weight: 400;

  text-decoration: none;
  display: block;
  color: #235dff;
  cursor: pointer;
  &:hover,
  &:active,
  &:link,
  &:visited,
  &:focus {
    color: #235dff;
  }
`;

export const TimelineWrapper = styled.div`
  display: flex;
`;

export const TimelineItem = styled.div<{ isStarted: boolean; isFinished: boolean; width?: string }>`
  height: 24px;
  padding: 0px 8px;
  position: relative;
  white-space: nowrap;
  width: ${({ width }) => width || 'auto'};
  background-color: #f7f7fc;
  color: rgb(119, 140, 162);
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  min-width: ${({ isStarted, isFinished }) => (isStarted && !isFinished ? 'auto' : '0px')};
  text-overflow: ellipsis;
  border: 1px solid;

  display: flex;
  align-items: center;

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &:not(:last-child) {
    border-right: none;
  }

  &:not(:first-child) {
    span {
      padding-left: 8px;
    }
  }

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }

  .arrow {
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 12px solid transparent;
    position: relative;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    border-top: 13px solid transparent;
    border-bottom: 13px solid transparent;
    border-left: 13px solid transparent;
  }

  .arrow:after {
    content: '';
    width: 0;
    height: 0;
    border-top: 11px solid transparent;
    border-bottom: 11px solid transparent;
    border-left: 11px solid transparent;
    position: absolute;
    top: -11px;
    left: -12px;
    z-index: -1;
  }

  .right {
    position: absolute;
    right: -12px;
    z-index: 1;
  }

  ${({ isStarted, isFinished }) => {
    if (isStarted && isFinished) {
      return `
        border-color: #00635A;
        background-color: #009A47;
        span {
          color: #FFF;
          
        }

        .right {
          border-left-color: #00635A;
        
          &:after {
            border-left-color: #009A47;
          }
        }
      `;
    }
    if (isStarted && !isFinished) {
      return `
        border-color: #00635A;
        background-color: #CDF3DF;

        span {
          color: #009A47;
        }

        .right {
          border-left-color: #00635A;

          &:after {
            border-left-color: #CDF3DF;
          }
        }
      `;
    }
    if (!isStarted && !isFinished) {
      return `
      border: 1px solid #778CA2;

    
      background-color: #fff;

      span {
        color: #778CA2;
      }

      .right {
        border-left-color: #778CA2;

        &:after {
          border-left-color: #fff;
        }
      }
      `;
    }

    return '';
  }};
`;

export const EditableWrapper = styled.div`
  cursor: pointer;
  padding: 4px 4px;
  min-width: 100px;
  border: 1px solid transparent;
  width: 100%;
  transition:
    border-color 0.15s ease-in-out,
    background-color 0.15s ease-in-out;

  border-radius: 4px;

  &:hover {
    border: 1px solid #c8c8d3;

    background-color: ${colors.white};
  }

  svg {
    flex-shrink: 0;
  }
`;

export const NameInput = styled(Input)`

&& {

.MuiOutlinedInput-input {
    font-size: 20px;
    line-height: 24px;

    font-weight: 500;
    padding: 5px;
    height: auto;
    letter-spacing normal;
    min-width: 100px;
    field-sizing: content;
    max-width: 100%;
}
}
`;

export const ProjectAccountInfoChip = styled.div<{ isEditable: boolean; isClickable?: boolean }>`
  border: 1px solid #dfdfe8;
  border-radius: 20px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  cursor: ${({ isClickable, isEditable }) => (isClickable || isEditable ? 'pointer' : 'default')};
  color: ${({ isClickable }) => (isClickable ? '#009688' : '#1d1d35')};
  background-color: ${({ isEditable, isClickable }) => {
    return isEditable || isClickable ? 'transparent' : '#F7F7FC';
  }};

  display: inline-flex;
  align-items: center;
  padding: 2px 8px;
  height: 24px;
  max-width: 165px;

  & svg {
    margin-right: 4px;
  }

  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
