import { DateTime } from 'luxon';
import { v4 as uuid } from '@lukeed/uuid';

import { FilterOperators } from '@types';
import {
  AnalyticsMeasureEntityType,
  AnalyticsModule,
  AnalyticsSubmeasureType,
  AnalyticsWidgetAggregateFunction,
  AnalyticsWidgetDateRangeType,
  DimensionType,
  PositiveTrendType,
  StandardPropertyId,
  TimeGranularity,
  UserSubdimentionType,
  WidgetFilters,
  WidgetSettings
} from './types';
import { isWorkflowRequired } from './hooks';

export const migrateOldFormatToNew = (widgetData: any): WidgetSettings => {
  return {
    title: widgetData.title || 'No title',
    positiveTrendType: PositiveTrendType.UP,
    subtitle: widgetData.subtitle || '',
    module: widgetData.args?.recordType === 'DEAL' ? AnalyticsModule.REQUESTS : AnalyticsModule.PROJECTS,
    widgetType: widgetData.args?.chartType || 'bar',
    measure: convertMeasure(widgetData),
    workflowId: widgetData.args?.blueprintId ? widgetData.args?.blueprintId : undefined,
    aggregateFunctionId:
      widgetData.args?.measure?.type === 'record-count' || widgetData.args?.measure?.propertyId === 0
        ? AnalyticsWidgetAggregateFunction.COUNT
        : AnalyticsWidgetAggregateFunction.SUM,
    ...covertSubmeasure(widgetData),
    ...covertDimension(widgetData),
    ...covertDateRange(widgetData),
    ...convertFilters(widgetData)
  };
};

const convertMeasure = (widgetData: any): WidgetSettings['measure'] => {
  if (isWorkflowRequired(widgetData.args?.chartType)) {
    return null;
  }

  if (widgetData.args?.measure?.type === 'record-count') {
    return {
      id: StandardPropertyId.ID,
      entityType:
        widgetData.args?.recordType === 'DEAL' ? AnalyticsMeasureEntityType.REQUEST : AnalyticsMeasureEntityType.PROJECT
    };
  }

  return {
    id: widgetData.args?.measure?.propertyId,
    entityType:
      widgetData.args?.recordType === 'DEAL' ? AnalyticsMeasureEntityType.REQUEST : AnalyticsMeasureEntityType.PROJECT
  };
};

const covertSubmeasure = (widgetData: any) => {
  if (!widgetData?.args?.byHistoricalStage) {
    return {};
  }

  return {
    submeasureTypeId: AnalyticsSubmeasureType.ENTERED,
    submeasureId: `stage_${widgetData.args?.byHistoricalStage}`
  };
};

const covertDimension = (widgetData: any): Pick<WidgetSettings, 'dimensionId' | 'subdimensionId'> => {
  const dimension = widgetData.args?.measure?.dimension;

  const DEFAULT_DIMENSION = {
    dimensionId: DimensionType.TIME,
    subdimensionId: TimeGranularity.DAY
  };
  if (!dimension) {
    return DEFAULT_DIMENSION;
  }

  if (dimension.type === 'time') {
    return {
      dimensionId: DimensionType.TIME,
      subdimensionId: dimension.granularity
    };
  }

  if (dimension.type === 'property') {
    if (dimension.propertyId === StandardPropertyId.CREATED_BY) {
      return {
        dimensionId: DimensionType.CREATOR,
        subdimensionId: UserSubdimentionType.USER
      };
    }

    if (dimension.propertyId === StandardPropertyId.PROJECT_MANAGER) {
      return {
        dimensionId: DimensionType.PROJECT_MANAGER,
        subdimensionId: UserSubdimentionType.USER
      };
    }

    if (dimension.propertyId === StandardPropertyId.SALES_REP) {
      return {
        dimensionId: DimensionType.SALES_REP,
        subdimensionId: UserSubdimentionType.USER
      };
    }

    if (dimension.propertyId === StandardPropertyId.OWNER) {
      return {
        dimensionId: DimensionType.OWNER,
        subdimensionId: UserSubdimentionType.USER
      };
    }

    return {
      dimensionId: DimensionType.DROPDOWN_PROPERTY,
      subdimensionId: `property_${dimension.propertyId}`
    };
  }

  if (dimension.type === 'team') {
    return {
      dimensionId: DimensionType.CREATOR,
      subdimensionId: UserSubdimentionType.TEAM
    };
  }

  return DEFAULT_DIMENSION;
};

const covertDateRange = (
  widgetData: any
): Pick<WidgetSettings, 'dateRangeType' | 'dateRangeStart' | 'dateRangeEnd'> => {
  const dateRange = widgetData.args?.dateRange;

  const DEFAULT_DATE_RANGE = {
    dateRangeType: AnalyticsWidgetDateRangeType.LAST_30_DAYS
  };

  if (!dateRange || !dateRange.type) {
    return DEFAULT_DATE_RANGE;
  }

  const { type } = dateRange;

  if (type === 'FIXED') {
    if (!dateRange.startDate || !dateRange.endDate) {
      return {
        dateRangeType: AnalyticsWidgetDateRangeType.ALL_TIME,
        dateRangeStart: null,
        dateRangeEnd: null
      };
    }

    return {
      dateRangeType: AnalyticsWidgetDateRangeType.CUSTOM,
      dateRangeStart: DateTime.fromFormat(dateRange.startDate, 'M/dd/yyyy').toISO(),
      dateRangeEnd: DateTime.fromFormat(dateRange.endDate, 'M/dd/yyyy').toISO()
    };
  }

  if (dateRange.subtract === 'CUSTOM_TO_NOW') {
    return {
      dateRangeType: AnalyticsWidgetDateRangeType.CUSTOM_TO_DATE,
      dateRangeStart: DateTime.fromFormat(dateRange.startDate, 'M/dd/yyyy').toISO()
    };
  }

  if (dateRange.subtract === 'YEAR_TO_NOW') {
    return {
      dateRangeType: AnalyticsWidgetDateRangeType.THIS_YEAR_TO_DATE
    };
  }

  if (dateRange.subtractAmount === 365) {
    return {
      dateRangeType: AnalyticsWidgetDateRangeType.LAST_365_DAYS
    };
  }

  if (dateRange.subtractAmount === 180) {
    return {
      dateRangeType: AnalyticsWidgetDateRangeType.LAST_180_DAYS
    };
  }

  if (dateRange.subtract === 'QUARTER_TO_NOW') {
    return {
      dateRangeType: AnalyticsWidgetDateRangeType.THIS_QUARTER_TO_DATE
    };
  }

  if (dateRange.subtractAmount === 90) {
    return {
      dateRangeType: AnalyticsWidgetDateRangeType.LAST_90_DAYS
    };
  }

  if (dateRange.subtract === 'MONTH_TO_NOW') {
    return {
      dateRangeType: AnalyticsWidgetDateRangeType.THIS_MONTH_TO_DATE
    };
  }

  if (dateRange.subtractAmount === 30) {
    return {
      dateRangeType: AnalyticsWidgetDateRangeType.LAST_30_DAYS
    };
  }

  if (dateRange.subtract === 'WEEK_TO_NOW') {
    return {
      dateRangeType: AnalyticsWidgetDateRangeType.THIS_WEEK_TO_DATE
    };
  }

  if (dateRange.subtractAmount === 7) {
    return {
      dateRangeType: AnalyticsWidgetDateRangeType.LAST_7_DAYS
    };
  }

  if (dateRange.subtract === 'HOUR') {
    return {
      dateRangeType: AnalyticsWidgetDateRangeType.TODAY
    };
  }

  return DEFAULT_DATE_RANGE;
};

const convertFilters = (widgetData: any): Pick<WidgetSettings, 'filters'> => {
  if (!widgetData?.args?.filters || !widgetData?.args?.filters?.children?.length) {
    return {};
  }

  return {
    filters: covertFilterRecursive(widgetData.args.filters)
  };
};

export const covertFilterRecursive = (filter: any): WidgetFilters => {
  if (!filter.children) {
    return null;
  }

  return {
    id: uuid(),
    operator: filter.logicOp,
    children: filter.children.map((child: any) => {
      if ('children' in child) {
        return covertFilterRecursive(child);
      }

      return {
        id: uuid(),
        fieldId: child.property.id,
        operator: child.op,
        value:
          child.op === FilterOperators.Within || child.op === FilterOperators.NotWithin
            ? convertOldDateRangeTypeToNew(child.value)
            : child.value
      };
    })
  };
};

const convertOldDateRangeTypeToNew = (oldDateRangeType: string): AnalyticsWidgetDateRangeType => {
  switch (oldDateRangeType) {
    case 'week_to_date':
      return AnalyticsWidgetDateRangeType.THIS_WEEK_TO_DATE;
    case 'last_30_days':
      return AnalyticsWidgetDateRangeType.LAST_30_DAYS;
    case 'month_to_date':
      return AnalyticsWidgetDateRangeType.THIS_MONTH_TO_DATE;
    case 'last_90_days':
      return AnalyticsWidgetDateRangeType.LAST_90_DAYS;
    case 'quarter_to_date':
      return AnalyticsWidgetDateRangeType.THIS_QUARTER_TO_DATE;
    case 'last_7_days':
      return AnalyticsWidgetDateRangeType.LAST_7_DAYS;
    case 'last_365_days':
      return AnalyticsWidgetDateRangeType.LAST_365_DAYS;
    case 'year_to_date':
      return AnalyticsWidgetDateRangeType.THIS_YEAR_TO_DATE;
    default:
      return oldDateRangeType;
  }
};
