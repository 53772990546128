import React from 'react';
import { DrawerEntity, useDrawersContext } from '@contexts/DrawersContext';
import { StatusMark, SystemStatus } from '@features/SystemPortfolio/components';
import { SYSTEM_STATUS_COLORS } from '@styles';
import { SystemWithStats } from '@hooks/systems';
import { formatEnergy, formatPower } from '@features/SystemPortfolio/utils';
import { CenteredRow, EnergyLabel, EnergyValue, Title, TitleCell } from './styled';
import { Placeholder, RowContainer } from '../styled';

interface Props {
  systems: SystemWithStats[];
}

export const SystemList = ({ systems }: Props) => {
  const { openDrawer } = useDrawersContext();

  const handleRowClick = (id: number) => {
    openDrawer(
      DrawerEntity.SYSTEM,
      id,
      systems.map((systems) => systems.id)
    );
  };

  return (
    <div>
      {systems.length === 0 && <Placeholder>No system here yet.</Placeholder>}

      {systems.map((system) => (
        <RowContainer key={system.id} onClick={() => handleRowClick(system.id)}>
          <CenteredRow>
            <TitleCell>
              <Title>
                <StatusMark color={SYSTEM_STATUS_COLORS[system.status].color} />
                <div>
                  <span>#{system.number}:</span> {system.name}
                </div>
              </Title>
              <div>
                <SystemStatus system={system} />
              </div>
            </TitleCell>
            <div>
              <EnergyValue>{formatPower(system.peakPower) || '-'}</EnergyValue>
              <EnergyLabel>Peak power</EnergyLabel>
            </div>
            <div>
              <EnergyValue>{formatEnergy(system.productionYesterday) || '-'}</EnergyValue>
              <EnergyLabel>Energy yesterday</EnergyLabel>
            </div>
          </CenteredRow>
        </RowContainer>
      ))}
    </div>
  );
};
