import { Form, FormLayout, MainUser } from '@generated/types/graphql';
import React from 'react';
import { Control } from 'react-hook-form';
import { Container, FormName, GroupName, Spacer } from './styled';
import { FormField } from './FormField';

const groupFieldByGroup = (fields: FormLayout[]) => {
  const groups = fields.filter((field) => field.type === 'GROUP');
  const notGroupedFields = fields.filter((field) => field.type !== 'GROUP');

  const defaultGroup = {
    id: 'default',
    name: '',
    childFormLayouts: notGroupedFields
  };

  return [defaultGroup, ...groups].filter((group) => group.childFormLayouts.length);
};
interface Props {
  control: Control<any>;
  form: Form;
  outsidePadding?: number;
  isMobilePreview?: boolean;
  onFieldFocus?: (columnId: number) => void;
  onFieldBlur?: (columnId: number) => void;
  collaborators?: { [key: number]: MainUser };
  isFullScreen?: boolean;
  onFileClick?: (fileId: number) => void;
}

export const FormRenderer = ({
  collaborators = [],
  onFieldFocus,
  onFieldBlur,
  control,
  form,
  outsidePadding = 16,
  isMobilePreview,
  isFullScreen = false,
  onFileClick
}: Props) => {
  const groupedFields = groupFieldByGroup(form.formLayouts);

  if (!isMobilePreview && form.formLayouts.length === 0) {
    return <div>There is no fields on this form</div>;
  }

  let fieldOrderNumber = 0;

  return (
    <Container outsidePadding={outsidePadding}>
      {isMobilePreview && <FormName>{form.name}</FormName>}

      {groupedFields.map((group) => (
        <div key={group.id}>
          {group.name && <GroupName>{group.name}</GroupName>}
          {!group.name && <Spacer />}

          {group.childFormLayouts.map((field) => {
            fieldOrderNumber += 1;

            return (
              <FormField
                onFocus={onFieldFocus}
                onBlur={onFieldBlur}
                formId={form.id}
                key={field.id}
                field={field}
                control={control}
                isMobilePreview={isMobilePreview}
                activeCollaborator={collaborators[field.column.id]}
                isFullScreen={isFullScreen}
                onFileClick={onFileClick}
                orderNumber={fieldOrderNumber}
              />
            );
          })}
        </div>
      ))}

      <div id="formPortal" />
    </Container>
  );
};
