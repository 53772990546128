import React from 'react';
import { filter } from 'lodash';

import { BlueprintDTO, RecordType } from '@types';
import { useBlueprints } from '@hooks';
import { SelectFormik, SelectProps } from '@common/ui';

interface WorkflowSelectProps extends Omit<SelectProps, 'options'> {
  recordType: RecordType;
}

export const WorkflowSelect: React.FC<WorkflowSelectProps> = (props) => {
  const {
    fetchAll: { data: blueprintsPage }
  } = useBlueprints();
  const blueprints: BlueprintDTO[] = blueprintsPage?.results || [];

  return (
    <SelectFormik
      options={filter(blueprints, ($b) => $b.type === props.recordType)}
      {...props}
    />
  );
};
