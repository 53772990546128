import React, { useCallback, useState } from 'react';
import { List, Plus } from 'react-feather';
import { RecordType } from '@types';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { EventButtons, FormContainer, Header, WidgetTitle, WidgetIcon, Container } from './styled';
import { EmailForm } from './forms/EmailForm';
import { SmsForm } from './forms/SmsForm';

import { CommentForm } from './forms/CommentForm';
import { CallForm } from './forms/CallForm';

interface Props {
  recordId: number;
  recordType: RecordType;
}

const ACTIONS = [
  {
    name: 'call',
    text: 'Call',
    formComponent: CallForm
  },
  {
    name: 'sms',
    text: 'SMS',
    formComponent: SmsForm
  },
  {
    name: 'email',
    text: 'Email',
    formComponent: EmailForm
  },
  {
    name: 'comment',
    text: 'Comment',
    formComponent: CommentForm
  }
];

export const CreateEventsPanel = ({ recordId, recordType }: Props) => {
  const [selectedEvent, setSelectedEvent] = useState(null);

  const clearEvent = useCallback(() => {
    setSelectedEvent(null);
  }, []);

  const handleEventButtonClick = useCallback(
    (event: (typeof ACTIONS)[number]) => () => {
      if (selectedEvent === event) {
        setSelectedEvent(null);

        return;
      }

      setSelectedEvent(event);
    },
    [selectedEvent]
  );

  return (
    <Container>
      <Header>
        <WidgetTitle>
          <WidgetIcon backgroundColor="#ECD4F0">
            <List size="16px" color="#B04EC2" />
          </WidgetIcon>
          Feed
        </WidgetTitle>
        <EventButtons>
          {ACTIONS.map((action) => (
            <Button variant={ButtonVariant.Secondary} key={action.name} onClick={handleEventButtonClick(action)}>
              <Plus size="16px" />
              {action.text}
            </Button>
          ))}
        </EventButtons>
      </Header>
      {Boolean(selectedEvent?.formComponent) && (
        <FormContainer>
          {React.createElement(selectedEvent.formComponent, { recordId, recordType, onClose: clearEvent })}
        </FormContainer>
      )}
    </Container>
  );
};
