import styled from 'styled-components';

export const IconButton = styled.div`
  padding: 2px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    flex-shrink: 0;
  }
`;

export const Container = styled.div<{ isOpen: boolean }>`
  transition: width 0.15s ease-in-out;
  min-height: 34px;
  width: ${({ isOpen }) => (isOpen ? '100%' : '30px')};
  overflow: hidden;

  > * {
    transition: border-color 0.15s ease-in-out;
  }

  * {
    transition: width 0.15s ease-in-out;
  }

  ${({ isOpen }) =>
    isOpen
      ? `
  

    `
      : `
        > * { border-color: transparent;
            > *:first-child {
                width: 0;
                > * {
                    width: 0;
                }
            }
        }

        input, fieldset {
            padding: 0 !important;
            width: 0;
        }
    `};
`;
