import { device } from '@styles';
import styled from 'styled-components';

export const Buttons = styled.div`
  margin-left: 16px;
  display: flex;
  align-items: center;
  gap: 24px;

  .MuiButton-startIcon {
    width: initial;
    height: initial;
  }
`;

export const Totals = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
  margin-bottom: 24px;
  justify-content: space-between;

  > * {
    flex: 1;
  }
`;

export const Label = styled.div`
  color: #828d9a;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 8px;
`;

export const Amounts = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const AmountDue = styled.div<{ isRed?: boolean }>`
  font-size: 24px;
  font-weight: 500;
  white-space: nowrap;
  color: ${({ isRed }) => (isRed ? '#D54855' : '#1D1D35')};
`;

export const AmountTotal = styled.div`
  font-size: 18px;
  font-weight: 500;
  white-space: nowrap;
  margin-left: 4px;
  padding-bottom: 3px;
`;

export const Table = styled.table`
  margin-top: 8px;
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 24px;

  table-layout: fixed;

  th:first-child {
    width: 28%;
  }

  th:last-child {
    width: 16px;
  }

  th + th {
    width: auto;
  }

  tr {
    background-color: #fff;
    transition: background-color 0.15s ease-in-out;
    &:hover {
      background-color: #f9fafc;
      cursor: pointer;
    }
  }

  th {
    color: #828d9a;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.6px;
    height: 40px;
    padding: 0 16px;

    text-align: left;

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }

  td {
    font-size: 14px;
    font-weight: 400;
    padding: 0 16px;
    height: 48px;
    overflow: hidden;
    text-overflow: ellipsis;

    vertical-align: middle;
    border-bottom: 1px solid #dfdfe8;

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .date {
    color: #828d9a;
  }

  td.overdue,
  td.overdue .date {
    color: #d54855;
  }

  .status {
    white-space: nowrap;
  }

  .title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    font-weight: 500;
    span {
      font-weight: 400;
    }
  }

  a {
    color: #235dff;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover,
    &:focus,
    &:active,
    &:visited,
    &:link {
      color: #235dff;
    }
  }
`;

export const OuterButtons = styled.div`
  position: absolute;
  top: 48px;
  width: 100%;
  display: flex;

  > * {
    flex: 1;
  }

  .close {
    order: 2;
  }

  .prev {
    order: 1;
  }

  .next {
    order: 3;
  }

  ${device.md`
  right: 100%;
  top: 72px;
  width: auto;
        position: absolute;
        z-index: 5;
        gap: 8px;
        flex-direction: column;

        > * {
            flex: auto;
        }

        .close,
        .next,
        .prev {
            order: initial;
        }

    `};
`;

const OuterButton = styled.div<{ isDisabled?: boolean }>`
  background-color: #f7f7fc;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};

  ${device.md`
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    `}
`;

export const CloseButton = styled(OuterButton)`
  height: 40px;
  color: #9c9caa;
  transition: color 0.15s ease-in-out;

  &:hover {
    color: #1d1d35;
  }

  ${device.md`
     width: 40px;
     margin-bottom: 8px;
    `}
`;

export const Placeholder = styled.div`
  color: #9c9caa;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 16px;
`;
