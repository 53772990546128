import { isBrowser } from '@utils';
import { useLocation } from '@reach/router';

const NO_LAYOUT_PATTERNS = [/\/authentication\/?.*/, /\/invite\/?.*/, /\/forgot-password\/?.*/];
export const useLayout = () => {
  const location = useLocation();

  return {
    isHidden: !isBrowser || NO_LAYOUT_PATTERNS.some((pattern) => location.pathname.match(pattern))
  };
};
