import { Avatar } from '@kit/ui/Avatar';
import React, { useMemo } from 'react';
import { Tooltip, withStyles } from '@material-ui/core';
import { Shield, Users } from 'react-feather';
import { CopyButton } from '@common/ui';
import { ExtendedUser, useCompanyUser } from '@hooks/useCompanyUsers';
import { Skeleton } from '@material-ui/lab';
import { normalizeRoleName } from '@utils/roles';
import { PhoneNumber } from '@kit/ui/anchors/PhoneNumber';
import { EmailAddress } from '@kit/ui/anchors/EmailAddress';
import { Info, Row } from './styled';

interface Props {
  size?: number;
  user: {
    id: number;
    firstName: string;
    lastName: string;
    avatarUrl: string | null;
    email?: string;
    phone?: string;
  };
}

const UserInfo = ({ user }: Pick<Props, 'user'>) => {
  const { data: extendedUser, isLoading } = useCompanyUser(user.id);

  const fullUserInfo = useMemo<ExtendedUser>(() => {
    if (isLoading || !extendedUser) {
      return {
        id: user.id,
        avatarUrl: user.avatarUrl,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.phone,
        roles: [],
        teams: []
      };
    }

    return extendedUser;
  }, [isLoading, extendedUser, user]);

  return (
    <Info>
      <Row>
        <Avatar user={user} />
        {user.firstName} {user.lastName}
      </Row>
      {fullUserInfo.email && (
        <Row>
          <EmailAddress value={fullUserInfo.email} iconProps={{ color: '#98a9bc' }} />
          <CopyButton value={fullUserInfo.email} size={12} />
        </Row>
      )}
      {fullUserInfo.phone && (
        <Row>
          <PhoneNumber value={fullUserInfo.phone} iconProps={{ color: '#98a9bc' }} />
          <CopyButton value={fullUserInfo.phone} size={12} />
        </Row>
      )}

      {isLoading && (
        <>
          <Row>
            <Shield size="12px" />
            <Skeleton variant="text" width={100} />
          </Row>
          <Row>
            <Users size="12px" />
            <Skeleton variant="text" width={100} />
          </Row>
        </>
      )}
      {fullUserInfo.roles.length > 0 && (
        <Row>
          <Shield size="12px" />
          {fullUserInfo.roles.map((role) => normalizeRoleName(role.name)).join(', ')}
        </Row>
      )}
      {fullUserInfo.teams.length > 0 && (
        <Row>
          <Users size="12px" />
          {fullUserInfo.teams.map((team) => team.name).join(', ')}
        </Row>
      )}
    </Info>
  );
};

const StyledTooltip = withStyles(() => ({
  tooltip: {
    margin: 0,
    padding: 4,
    backgroundColor: 'transparent'
  }
}))(Tooltip);

export const UserAvatar = ({ user, size = 24 }: Props) => {
  return (
    <StyledTooltip title={<UserInfo user={user} />} interactive>
      <div>
        <Avatar user={user} size={size} />
      </div>
    </StyledTooltip>
  );
};
