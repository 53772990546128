import { useMutation, useQueryClient } from 'react-query';
import { ReactQueryKey } from '@enums';
import { errorHandler } from '@services/api/helpers';
import databankApi from '@services/api/databankApi';
import { useToast } from '..';
import { RecordDocListItem } from './useRecordDocList';

type Payload = {
  recordId: number;
  docId: number;
  sharedWithPortal: boolean;
  isPropertyFile?: boolean;
};

export const useShareDocWithPortal = () => {
  const queryClient = useQueryClient();
  const { showError } = useToast();

  return useMutation<void, Error, Payload>(
    async (dto) => {
      try {
        if (!dto.isPropertyFile) {
          await databankApi.updateRecordFile({
            recordId: dto.recordId,
            fileId: dto.docId,
            sharedWithPortal: dto.sharedWithPortal
          });

          return;
        }

        await databankApi.updateFile(dto.docId, {
          sharedWithPortal: dto.sharedWithPortal
        });
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onMutate: async ({ recordId, docId, sharedWithPortal }) => {
        await queryClient.cancelQueries([ReactQueryKey.RecordDocList]);

        queryClient.setQueriesData<RecordDocListItem[] | undefined>([ReactQueryKey.RecordDocList, recordId], (old) => {
          if (!old) {
            return old;
          }

          return old.map((doc) => {
            if (doc.id === docId) {
              return {
                ...doc,
                sharedWithPortal
              };
            }

            return doc;
          });
        });
      },
      onSuccess: (_result, { docId }) => {
        queryClient.invalidateQueries([ReactQueryKey.Files, ReactQueryKey.FileDetails, docId]);
        queryClient.invalidateQueries([ReactQueryKey.RecordDocList]);
      },
      onError: (error: Error) => {
        showError(error.message);
      }
    }
  );
};
