import React from 'react';
import { Skeleton } from '@material-ui/lab';

import { Container, Info, FirstRow } from './styled';

export const HeaderSkeleton = () => {
  return (
    <Container>
      <Info>
        <FirstRow>
          <Skeleton animation="wave" width={100} height={34} />
          <Skeleton animation="wave" width={200} height={34} />
        </FirstRow>
      </Info>
    </Container>
  );
};
