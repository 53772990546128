import { Search, SmartViewFilter } from '@types';

import { post, put, get, deleteRequest, patch } from './base';
import { generateParams } from './helpers';
import { Urls } from './base/axios';
import { SmartViewDTO } from './types';

export default {
  find: (search: Partial<Search>) => {
    return get<SmartViewFilter[]>(`${Urls.user}/filters/user`, generateParams({ search }));
  },

  create: (req: SmartViewDTO, params: Parameters<typeof generateParams>[0]) =>
    post<SmartViewFilter>(`${Urls.user}/filters`, req, generateParams(params)),

  update: (id: number, req: Partial<SmartViewDTO>) => put<SmartViewFilter>(`${Urls.user}/filters/${id}`, req),

  remove: (id: number) => deleteRequest(`${Urls.user}/filters/${id}`),

  createFolder: ({ name, companyId }: { name: string; companyId: number }) =>
    post(`${Urls.user}/filter-folder?companyId=${companyId}`, { name }),

  updateFolder: ({ id, name, companyId }: { id: number; name: string; companyId: number }) =>
    patch(`${Urls.user}/filter-folder/${id}?companyId=${companyId}`, { name }),

  deleteFolder: ({ id, companyId }: { id: number; companyId: number }) =>
    deleteRequest(`${Urls.user}/filter-folder/${id}?companyId=${companyId}`)
};
