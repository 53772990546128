import { useCallback } from 'react';
import { EventType, FeedInbox } from '@generated/types/graphql';
import { DrawerEntity, useDrawersContext } from '@contexts/DrawersContext';
import { useNavigate } from '@reach/router';
import { useAppSelector } from '@hooks/index';
import { selectWorkspaceId } from '@state/selectors';
import { useNotifications } from './List/useNotifications';

export const useNav = () => {
  const { notifications } = useNotifications();

  const companyId = useAppSelector(selectWorkspaceId);

  const navigate = useNavigate();

  const { openDrawer } = useDrawersContext();

  const handleClick = useCallback(
    async (notification: FeedInbox) => {
      if (notification.eventType === EventType.BudgetExceeded) {
        navigate(`/${companyId}/workspace/systems#budget`);

        return;
      }

      openDrawer(
        DrawerEntity.NOTIFICATION,
        notification.id,
        notifications.map((item) => item.id)
      );
    },
    [openDrawer, notifications, companyId, navigate]
  );

  return {
    onNotificationClick: handleClick
  };
};
