import React, { useCallback, useState } from 'react';
import { useModal } from '@common/PromiseModal';
import { ReactQueryKey } from '@enums';
import { PrivilegedTaskFilter, PrivilegedTasksConnection, TaskLabel, Form, FormFilter } from '@generated/types/graphql';
import { QueryParamsEnum, useQueryParam } from '@hooks/useQueryParam';
import { postGraphql } from '@services/api/base/graphql';
import { parseUtcDate } from '@utils/dates';
import { gql } from 'graphql-request';
import { useQuery } from 'react-query';
import { useDeepCompareEffect } from 'react-use';
import { DeepPartial } from 'redux';
import { DrawerEntity, useDrawersContext } from '@contexts/DrawersContext';
import { useAppSelector } from '@hooks/index';
import { selectWorkspaceId } from '@state/selectors';
import { CreateForm } from './components/WorkOrderView/CreateForm';

const fetchTask = async ({ companyId, taskId }: { companyId: number; taskId: number }) => {
  const filter: DeepPartial<PrivilegedTaskFilter> = {
    id: { equalTo: taskId },
    companyId: { equalTo: companyId },
    projectExists: true
  };
  const {
    tasks: { nodes }
  } = await postGraphql<{ tasks: PrivilegedTasksConnection }>(
    gql`
      query SINGLE_TASK_QUERY($filter: PrivilegedTaskFilter) {
        tasks: privilegedTasksConnection(filter: $filter) {
          nodes {
            id
            uid
            description
            title
            address
            forms
            createdAt
            endDate
            endDateAllDay
            startDate
            startDateAllDay
            isColored
            privilege
            privilegeAll
            privilegeOwn
            privilegeTeam
            completionDate
            isArchived
            isCompleted
            isField
            companyId
            projectId
            priority
            status
            assignee {
              id
              avatarUrl
              firstName
              lastName
            }

            assigneesByTaskId {
              user {
                id
                avatarUrl
                firstName
                lastName
              }
            }

            privilegedTaskLabels {
              label {
                id
                color
                label
              }
            }
            firstLabel {
              id
              color
              label
            }
            subTasks: privilegedTaskSubtasks {
              position
              id
              date
              detail
              isCompleted
            }
            project {
              id
              title
              type
              geoLocation
              address
              status
              requestStatus
              accountStatus
              projectContacts {
                id
                phones
              }
              stage {
                name
                type
              }
            }
            taskStatus {
              id
              label
              isAutomatic
              fieldOnly
              officeOnly
              possibleTransitions {
                id
                label
                fieldOnly
                officeOnly
              }
            }
            company {
              id
              name
            }
            templateTask {
              id
              title
            }

            taskVisitsByTaskIdConnection(orderBy: START_DATE_ASC) {
              nodes {
                id
                startDate
                startDateAllDay
                endDate
                endDateAllDay
                isCompleted
              }
            }
          }
        }
      }
    `,
    { filter }
  );

  if (nodes[0].forms.length) {
    const formsFilter: DeepPartial<FormFilter> = {
      id: { in: nodes[0].forms }
    };

    const { forms } = await postGraphql<{ forms: Form[] }>(
      gql`
        query SINGLE_TASK_FORMS_QUERY($filter: FormFilter) {
          forms(filter: $filter, orderBy: CREATED_AT_DESC) {
            id
            name
            createdAt
            isTemplate
            file {
              id
            }
            templateId
          }
        }
      `,
      { filter: formsFilter }
    );

    nodes[0].forms = forms;
  }

  return {
    ...nodes[0],
    labels: nodes[0].privilegedTaskLabels.map((label: TaskLabel) => label.label),
    startDate: nodes[0].startDate ? parseUtcDate(nodes[0].startDate) : nodes[0].startDate,
    endDate: nodes[0].endDate ? parseUtcDate(nodes[0].endDate) : nodes[0].endDate,
    taskStatus: {
      ...nodes[0].taskStatus,
      possibleTransitions: nodes[0].taskStatus.possibleTransitions.filter((transition) =>
        nodes[0].isField
          ? transition.fieldOnly || !transition.officeOnly
          : transition.officeOnly || !transition.fieldOnly
      )
    }
  };
};

export const useTask = (taskId?: number) => {
  const companyId = useAppSelector(selectWorkspaceId);

  const { data, refetch, isLoading, isFetching } = useQuery(
    [ReactQueryKey.Tasks, ReactQueryKey.TasksDetails, taskId, companyId],
    () => fetchTask({ companyId, taskId }),
    {
      enabled: Boolean(taskId)
    }
  );

  return {
    task: data,
    refetch,
    isLoading,
    isFetching
  };
};

export const useTaskDrawer = () => {
  const [openedTask, setOpenedTask] = useState(null);
  const [isViewMode, setIsViewMode] = useState(false);
  const { openDrawer } = useDrawersContext();

  const [taskId, setTaskId] = useQueryParam(QueryParamsEnum.WorkOrderId);
  const {
    task: loadedTask,
    isLoading,
    isFetching
  } = useTask(typeof taskId === 'string' ? parseInt(taskId, 10) : undefined);

  const { openModal } = useModal();

  const handleCloseTask = useCallback(() => {
    setOpenedTask(null);
    setTaskId(undefined);
  }, [setTaskId]);

  const handleAddNewTask = useCallback(
    (intialValues?: any) => {
      openModal<void>(
        ({ onClose }) => (
          <CreateForm
            initialValues={intialValues}
            onCreated={(newTaskId) => {
              openDrawer(DrawerEntity.WORK_ORDER, newTaskId, []);
            }}
            onClose={onClose}
          />
        ),
        { title: 'Create Work Order' }
      );
    },
    [openModal, openDrawer]
  );

  useDeepCompareEffect(() => {
    setIsViewMode(true);
    setOpenedTask(loadedTask);
  }, [loadedTask]);

  const toggleViewMode = useCallback(() => {
    setIsViewMode((prev) => !prev);
  }, []);

  return {
    task: openedTask,
    onAddClick: handleAddNewTask,
    onClose: handleCloseTask,
    setTaskIdToLoad: (id: number) => openDrawer(DrawerEntity.WORK_ORDER, id, []),
    isViewMode,
    toggleViewMode,
    isLoading,
    isFetching
  };
};
