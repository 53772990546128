import { ProjectEventType, ReactQueryKey } from '@enums';
import { useQuery } from 'react-query';
import { postGraphql } from '@services/api/base/graphql';
import { FeedFilter, FeedsConnection } from '@generated/types/graphql';
import { gql } from 'graphql-request';
import { DeepPartial } from 'redux';
import { apiErrorHandler } from '@utils/api';

const fetchPinnedComments = async ({ clientId, projectId, workOrderId, fileId, systemId }: Args) => {
  const filter: DeepPartial<FeedFilter> = {
    eventType: {
      equalTo: ProjectEventType.COMMENTED
    },
    relatedComment: {
      isPinned: {
        equalTo: true
      }
    }
  };

  if (clientId) {
    filter.projectId = { equalTo: clientId };
  }

  if (projectId) {
    filter.projectId = { equalTo: projectId };
  }

  if (workOrderId) {
    filter.taskId = { equalTo: workOrderId };
    filter.event = {
      equalTo: 'TASK'
    };
  }

  if (fileId) {
    filter.fileId = { equalTo: fileId };
  }

  if (systemId) {
    filter.systemId = { equalTo: systemId };
  }

  const { feedsConnection } = await postGraphql<{ feedsConnection: FeedsConnection }>(
    gql`
      query PINNED_COMMENTS($filter: FeedFilter) {
        feedsConnection(filter: $filter) {
          nodes {
            id
            createdAt
            virtualCreatedAt
            createdBy: createdByUser {
              id
              firstName
              lastName
              avatarUrl
              phone
            }
            parentId
            event
            eventType
            id
            payload
            relatedComment {
              id
              scope
              comment
              createdAt
              updatedAt
              createdByType
              type
              isPinned
              pinnedAt
              file {
                metaData
                annotations
              }

              files {
                id
                name
                metaData
                downloadUrl
                type
                projectId
              }
            }

            childFeedsConnection(filter: { eventType: { in: ["COMMENTED"] } }, orderBy: CREATED_AT_ASC) {
              nodes {
                parentId
                createdAt
                createdBy: createdByUser {
                  id
                  firstName
                  lastName
                  avatarUrl
                  phone
                }
                event
                eventType
                id
                payload
                relatedComment {
                  id
                  comment
                  createdAt
                  updatedAt
                  type
                  file {
                    metaData
                    annotations
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      filter
    }
  );

  return feedsConnection.nodes;
};

interface Args {
  clientId?: number;
  projectId?: number;
  workOrderId?: number;
  fileId?: number;
  systemId?: number;
}
export const usePinnedComemnts = (args: Args) => {
  return useQuery([ReactQueryKey.PinnedComments, args], async () => {
    try {
      return await fetchPinnedComments(args);
    } catch (error) {
      throw apiErrorHandler('Error fetching pinned comments', error);
    }
  });
};
