import { ModalBody, ModalFooter, useConfirmDeleteModal } from '@common/PromiseModal';
import { FilterFolder } from '@generated/types/graphql';
import { useCreateSmartViewFolder } from '@hooks/smartViews/useCreateSmartViewFolder';
import { useDeleteSmartViewFolder } from '@hooks/smartViews/useDeleteSmartViewFolder';
import { useUpdateSmartViewFolder } from '@hooks/smartViews/useUpdateSmartViewFolder';
import { useAppSelector } from '@hooks/store';
import { useAuth } from '@hooks/useAuth';
import { useUserRoleSettings } from '@hooks/useRoles';
import { Form, FormValidationRules, InputField, useForm } from '@kit/components/Form';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { selectWorkspaceId } from '@state/selectors';
import { hasAccess } from '@utils/roles';
import React, { useCallback } from 'react';

type FormValues = {
  name: string;
};

interface Props {
  initialValues?: FilterFolder;
  onClose: () => void;
}

const RULES: FormValidationRules<FormValues> = {
  name: {
    isRequired: true
  }
};

export const FolderForm = ({ initialValues, onClose }: Props) => {
  const isNew = !initialValues?.id;

  const companyId = useAppSelector(selectWorkspaceId);
  const { user } = useAuth();
  const { data: access } = useUserRoleSettings(companyId, user.userId);

  const { mutateAsync: create } = useCreateSmartViewFolder();
  const { mutateAsync: update } = useUpdateSmartViewFolder();
  const { mutateAsync: remove } = useDeleteSmartViewFolder();

  const confirmDelete = useConfirmDeleteModal();

  const postForm = async (values: FormValues) => {
    if (isNew) {
      await create(values);
    } else {
      await update({ id: initialValues!.id, name: values.name });
    }

    onClose();
  };

  const { handleSubmit, form } = useForm({
    onSubmit: postForm,
    defaultValues: initialValues
      ? {
          name: initialValues.name
        }
      : { name: '' }
  });

  const handleRemove = useCallback(async () => {
    if (
      await confirmDelete(`Are you sure you want to delete “${initialValues.name}” folder? 
        All Smart Views from this folder will be moved to “Other” folder. \n\nThis action cannot be undone.`)
    ) {
      await remove({ id: initialValues.id });

      onClose();
    }
  }, [initialValues, confirmDelete, remove, onClose]);

  const {
    control,
    formState: { isSubmitting }
  } = form;

  return (
    <Form rules={RULES} onSubmit={handleSubmit}>
      <ModalBody>
        <InputField label="Hub name" name="name" control={control} autoFocus />
      </ModalBody>

      <ModalFooter>
        {!isNew && hasAccess(access!, 'workspace', 'delete', 'smartviewFolder') && (
          <Button onClick={handleRemove} variant={ButtonVariant.Danger}>
            Delete
          </Button>
        )}
        <Button variant={ButtonVariant.Secondary} onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={isSubmitting} type="submit" variant={ButtonVariant.Primary}>
          {isNew ? 'Create' : 'Update'}
        </Button>
      </ModalFooter>
    </Form>
  );
};
