import React from 'react';

export const BarChartIcon = ({ size = '16px', color = 'currentColor' }: { size?: string | number; color?: string }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 3C9 2.44772 9.44772 2 10 2H14C14.5523 2 15 2.44772 15 3V21C15 21.5523 14.5523 22 14 22H10C9.44772 22 9 21.5523 9 21V3ZM11 4V20H13V4H11Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 8C17 7.44772 17.4477 7 18 7H22C22.5523 7 23 7.44772 23 8V21C23 21.5523 22.5523 22 22 22H18C17.4477 22 17 21.5523 17 21V8ZM19 9V20H21V9H19Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 13C1 12.4477 1.44772 12 2 12H6C6.55228 12 7 12.4477 7 13V21C7 21.5523 6.55228 22 6 22H2C1.44772 22 1 21.5523 1 21V13ZM3 14V20H5V14H3Z"
      fill={color}
    />
  </svg>
);
