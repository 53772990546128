import { CompanyUser } from '@generated/types/graphql';
import { FormValidationRules, useForm, Form, InputField, SelectField } from '@kit/components/Form';
import React, { useMemo } from 'react';
import { useInviteUser } from '@hooks/users/useInviteUser';
import { normalizeRoleName } from '@utils/roles';
import { useUpdateUser } from '@hooks/users/useUpdateUser';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { useRoleList } from '@hooks/workspace/roles/useRoleList';
import { Body, Footer } from './styled';

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  role: { id: number; name: string };
};
interface Props {
  initialValues?: CompanyUser;
  onClose: () => void;
}

const mapCompanyUserToFormValues = (companyUser: CompanyUser): FormValues => {
  return {
    firstName: companyUser.user.firstName,
    lastName: companyUser.user.lastName,
    email: companyUser.user.email,
    phone: companyUser.user.phone,
    role: { id: companyUser.companyUserRoles[0].roles.id, name: companyUser.companyUserRoles[0].roles.name }
  };
};

export const UserForm = ({ initialValues, onClose }: Props) => {
  const isNew = !initialValues;

  const { data: roles = [] } = useRoleList();

  const { mutateAsync: invite } = useInviteUser();
  const { mutateAsync: update } = useUpdateUser();

  const postForm = async (values: FormValues) => {
    const payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email.trim().toLowerCase(),
      phone: values.phone,
      roleId: values.role.id
    };

    if (isNew) {
      await invite(payload);
    } else {
      await update({
        id: initialValues!.user.id,
        ...payload
      });
    }

    onClose();
  };

  const { form, handleSubmit } = useForm({
    onSubmit: postForm,
    mode: 'onBlur',
    defaultValues: initialValues ? mapCompanyUserToFormValues(initialValues) : { status: 'member' }
  });

  const {
    formState: { isSubmitting },
    control
  } = form;

  const rules = useMemo<FormValidationRules<FormValues>>(
    () => ({
      firstName: {
        isRequired: true
      },
      lastName: {
        isRequired: true
      },
      email: {
        isRequired: true
      },
      role: {
        isRequired: true
      }
    }),
    []
  );

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <Body>
        <InputField label="First name" name="firstName" control={control} />
        <InputField label="Last name" name="lastName" control={control} />
        <InputField type="email" label="Email" name="email" control={control} />
        <InputField label="Phone" name="phone" control={control} />
        <SelectField
          label="Role"
          name="role"
          getOptionLabel={(option) => normalizeRoleName(option.name)}
          options={roles}
          control={control}
        />
      </Body>
      <Footer>
        <Button variant={ButtonVariant.Secondary} onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={isSubmitting} variant={ButtonVariant.Primary} type="submit">
          {isNew ? 'Invite' : 'Update'}
        </Button>
      </Footer>
    </Form>
  );
};
