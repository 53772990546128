import React, { useCallback, useEffect, useRef, useState } from 'react';
import { GroupNavItem, GroupsNav } from './styled';

interface Props {
  tabs: {
    id: number;
    title: string;
  }[];
}

export const NavSidebar = ({ tabs }: Props) => {
  const observer = useRef(null);
  const [activeGroup, setActiveGroup] = useState<number | null>(null);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        const visibleSection = entries.find((entry) => entry.isIntersecting)?.target;
        if (visibleSection) {
          requestAnimationFrame(() => {
            setActiveGroup(visibleSection.id);
          });
        }
      },
      { threshold: [0, 0.25, 0.5, 0.75, 1] }
    );

    const sections = document.querySelectorAll('[data-properties-group]');

    sections.forEach((section) => {
      observer.current.observe(section);
    });

    return () => {
      sections.forEach((section) => {
        observer.current.unobserve(section);
      });
    };
  }, []);

  const handleNavItemClick = useCallback(
    (id: number) => (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();

      const group = document.getElementById(`properties_group_${id}`);

      if (!group) {
        return;
      }

      group.scrollIntoView({
        block: 'start'
      });

      requestAnimationFrame(() => {
        setActiveGroup(id);
      });
    },
    []
  );

  return (
    <GroupsNav>
      {tabs.map((tab) => (
        <GroupNavItem key={tab.id} isActive={activeGroup === `properties_group_${tab.id}`}>
          <a href={`#properties_group_${tab.id}`} onClick={handleNavItemClick(tab.id)}>
            {tab.title}
          </a>
        </GroupNavItem>
      ))}
    </GroupsNav>
  );
};
