import React from 'react';
import moment from 'moment';
import { EntityType } from '@enums';
import { BriefcaseIcon } from '@kit/ui/icons/Briefcase';
import { CheckSquareIcon } from '@kit/ui/icons/CheckSquare';
import { CalendarIcon } from '@kit/ui/icons/Calendar';
import { ZapIcon } from '@kit/ui/icons/Zap';
import { MailIcon } from '@kit/ui/icons/Mail';
import { File } from 'react-feather';
import { QueryParamsEnum, useQueryParam } from '@hooks/useQueryParam';
import { Container, Content, Title, FeedItemIconContainer, CreatedAt, TitleLeft, BorderContainer } from './styled';
import { AuthorAvatar } from './AuthorAvatar';

interface Props {
  id: number;
  date: string;
  title: React.ReactNode;
  children: React.ReactNode;
  icon: React.ReactNode;
  author?: { firstName?: string; lastName?: string; name?: string; id?: number; avatarUrl?: string };
  authorIcon?: React.ReactNode;
  context: 'project' | 'workOrder' | 'file' | 'client';
}

export const getHumanFriendlyTimeAgo = (date: moment.MomentInput) => {
  return moment.utc(date).local().format('MM/DD/yyyy[,] hh:mma');
};

export const EVENT_SOURCE_ICON_MAP: { [key in EntityType]?: React.ReactNode } = {
  [EntityType.PROJECT]: (
    <FeedItemIconContainer color="#F4E9EF">
      <BriefcaseIcon color="#9A6784" size="12px" />
    </FeedItemIconContainer>
  ),
  [EntityType.TASK]: <CheckSquareIcon size="12px" />,
  [EntityType.REMINDER]: <CalendarIcon size="12px" />,
  [EntityType.WORKFLOW]: <ZapIcon size="12px" />,
  [EntityType.EMAIL]: <MailIcon size="12px" />,
  [EntityType.FILE]: <File size="12px" />
};

export const BaseItemTemplate = ({ id, context, author, authorIcon, icon, date, title, children }: Props) => {
  const isSmallView = context !== 'project' && context !== 'client';
  const [feedId] = useQueryParam(QueryParamsEnum.FeedId);

  return (
    <Container id={`feed_${id}`} isHighlighted={feedId && +feedId === id}>
      <BorderContainer>
        {icon}
        <Content>
          <Title>
            <TitleLeft>
              {!authorIcon && author && <AuthorAvatar author={author} />}
              {Boolean(authorIcon) && authorIcon}
              <div>
                {title}

                {isSmallView && <CreatedAt>{getHumanFriendlyTimeAgo(date)}</CreatedAt>}
              </div>
            </TitleLeft>
            {!isSmallView && <CreatedAt>{getHumanFriendlyTimeAgo(date)}</CreatedAt>}
          </Title>

          {React.isValidElement(children) && <div>{children}</div>}
        </Content>
      </BorderContainer>
    </Container>
  );
};
