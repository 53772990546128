import { colors } from '@styles';
import styled from 'styled-components';

export const Container = styled.div<{ variant: 'chip' | 'link'; maxWidth: string }>`
  display: ${({ variant }) => (variant === 'link' ? 'inline-flex' : 'block')};
  a {
    border-radius: 4px;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 12px;
    gap: 4px;
    > div {
      max-width: ${({ maxWidth }) => maxWidth};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    svg {
      flex-shrink: 0;
    }

    ${({ variant }) =>
      variant === 'chip'
        ? `
      padding: 5px 8px;
      color: ${colors.green};
      background-color: #f8fafb;
    `
        : `
        display: inline-flex;
      color: #235dff;
      font-size: 14px;
    `};
  }
`;
