import { useState, useEffect } from 'react';
import { isBrowser } from '@utils';

export const useOffline = () => {
  const [isOffline, setIsOffline] = useState(isBrowser ? !window.navigator.onLine : false);

  const onOffline = () => {
    setIsOffline(true);
  };

  const onOnline = () => {
    setIsOffline(false);
  };

  useEffect(() => {
    window.addEventListener('offline', onOffline);
    window.addEventListener('online', onOnline);

    return () => {
      window.removeEventListener('offline', onOffline);
      window.removeEventListener('online', onOnline);
    };
  }, []);

  return isOffline;
};
