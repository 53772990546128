import React from 'react';

interface Props {
  size?: string | number;
}

export const GoldMedalIcon = React.forwardRef<SVGSVGElement, Props>(({ size = '16px', ...props }, ref) => (
  <svg
    ref={ref}
    width={size}
    height={size}
    {...props}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.85718 15.1233H12.0235V21.6282L6.85718 23.6712V15.1233Z" fill="#E24D5B" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.62231 14.8885H12.2583V21.7878L6.62231 24.0166V14.8885ZM7.09198 15.3581V23.3258L11.7887 21.4685V15.3581H7.09198Z"
      fill="#B63D48"
    />
    <path d="M17.1898 15.1233H12.0235V21.6282L17.1898 23.6712V15.1233Z" fill="#D54855" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.4247 14.8885H11.7887V21.7878L17.4247 24.0166V14.8885ZM16.955 15.3581V23.3258L12.2583 21.4685V15.3581H16.955Z"
      fill="#B63D48"
    />
    <path
      d="M20.7123 8.68885C20.7123 13.4876 16.8222 17.3777 12.0234 17.3777C7.22472 17.3777 3.33459 13.4876 3.33459 8.68885C3.33459 3.89013 7.22472 0 12.0234 0C16.8222 0 20.7123 3.89013 20.7123 8.68885Z"
      fill="#FEC552"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0234 16.908C16.5628 16.908 20.2426 13.2282 20.2426 8.68885C20.2426 4.14952 16.5628 0.469667 12.0234 0.469667C7.48411 0.469667 3.80426 4.14952 3.80426 8.68885C3.80426 13.2282 7.48411 16.908 12.0234 16.908ZM12.0234 17.3777C16.8222 17.3777 20.7123 13.4876 20.7123 8.68885C20.7123 3.89013 16.8222 0 12.0234 0C7.22472 0 3.33459 3.89013 3.33459 8.68885C3.33459 13.4876 7.22472 17.3777 12.0234 17.3777Z"
      fill="#F49F26"
    />
    <path
      d="M17.9412 8.68883C17.9412 11.9571 15.2917 14.6066 12.0234 14.6066C8.75508 14.6066 6.10559 11.9571 6.10559 8.68883C6.10559 5.42052 8.75508 2.77103 12.0234 2.77103C15.2917 2.77103 17.9412 5.42052 17.9412 8.68883Z"
      fill="#FFEEA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.4109 8.68884C18.4109 12.2165 15.5512 15.0763 12.0235 15.0763C8.49576 15.0763 5.63599 12.2165 5.63599 8.68884C5.63599 5.16113 8.49576 2.30136 12.0235 2.30136C15.5512 2.30136 18.4109 5.16113 18.4109 8.68884ZM12.0235 14.6066C15.2918 14.6066 17.9413 11.9572 17.9413 8.68884C17.9413 5.42052 15.2918 2.77103 12.0235 2.77103C8.75515 2.77103 6.10565 5.42052 6.10565 8.68884C6.10565 11.9572 8.75515 14.6066 12.0235 14.6066Z"
      fill="#F49F26"
    />
    <path
      d="M12.1149 11.8C12.0498 11.8 11.9964 11.7792 11.9549 11.7378C11.9194 11.6963 11.9016 11.6459 11.9016 11.5866V6.9822L10.5505 8.0222C10.4972 8.05776 10.4438 8.07257 10.3905 8.06665C10.3372 8.0548 10.2898 8.0222 10.2483 7.96887L9.87494 7.48887C9.83938 7.43554 9.82457 7.37924 9.83049 7.31998C9.84235 7.26072 9.87494 7.21331 9.92827 7.17776L11.9194 5.63998C11.9549 5.61035 11.9905 5.59257 12.026 5.58665C12.0616 5.58072 12.1001 5.57776 12.1416 5.57776H12.9238C12.9831 5.57776 13.0335 5.5985 13.0749 5.63998C13.1164 5.68146 13.1372 5.73183 13.1372 5.79109V11.5866C13.1372 11.6459 13.1164 11.6963 13.0749 11.7378C13.0335 11.7792 12.9831 11.8 12.9238 11.8H12.1149Z"
      fill="#FFB54B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6694 12.0022L11.6597 11.9908C11.5584 11.8727 11.5127 11.7302 11.5127 11.5866V7.77227L10.7772 8.33841L10.7662 8.34575C10.6522 8.42175 10.5087 8.47104 10.3475 8.45313L10.3266 8.45081L10.3061 8.44625C10.1451 8.41046 10.0248 8.31492 9.94129 8.2076L9.55923 7.71638L9.55135 7.70456C9.46994 7.58246 9.42793 7.43718 9.44352 7.28126L9.44541 7.26234L9.44914 7.24369C9.4814 7.0824 9.57262 6.95227 9.69896 6.86345L11.677 5.33575C11.75 5.27636 11.8452 5.2225 11.9621 5.20302C12.0217 5.1931 12.0818 5.18884 12.1416 5.18884H12.9238C13.085 5.18884 13.2347 5.24973 13.3499 5.36497C13.4651 5.48021 13.526 5.62984 13.526 5.79106V11.5866C13.526 11.7478 13.4651 11.8975 13.3499 12.0127C13.2347 12.128 13.085 12.1888 12.9238 12.1888H12.1149C11.9592 12.1888 11.8028 12.1356 11.6799 12.0127L11.6694 12.0022ZM11.9194 5.63995L9.92826 7.17773C9.87492 7.21329 9.84233 7.26069 9.83048 7.31995C9.82455 7.37921 9.83937 7.43551 9.87492 7.48884L10.2483 7.96884C10.2897 8.02218 10.3371 8.05477 10.3905 8.06662C10.4438 8.07255 10.4971 8.05773 10.5505 8.02218L11.9016 6.98218V11.5866C11.9016 11.6459 11.9194 11.6963 11.9549 11.7377C11.9964 11.7792 12.0497 11.8 12.1149 11.8H12.9238C12.9831 11.8 13.0334 11.7792 13.0749 11.7377C13.1164 11.6963 13.1371 11.6459 13.1371 11.5866V5.79106C13.1371 5.73181 13.1164 5.68144 13.0749 5.63995C13.0334 5.59847 12.9831 5.57773 12.9238 5.57773H12.1416C12.1001 5.57773 12.0616 5.58069 12.026 5.58662C11.9905 5.59255 11.9549 5.61032 11.9194 5.63995Z"
      fill="#F49F26"
    />
  </svg>
));
