import { RootState } from '@state/configureStore';

const selectAuthSlice = (state: RootState) =>
  state.authentication;

export const selectCurrentUserId = (state: RootState) =>
  selectAuthSlice(state).user?.userId;

export const selectCurrentUser = (state: RootState) =>
  selectAuthSlice(state).user;
