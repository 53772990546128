import { Link } from '@reach/router';
import { colors } from '@styles';
import styled from 'styled-components';

export const Actions = styled.div`
  background-color: ${colors.white};
  padding: 16px 24px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
`;

export const Body = styled.div`
  padding: 24px;
  background-color: #f7f7fc;
`;

export const FieldsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;

  & > *:only-child {
    grid-column: 1 / -1;
  }
`;

export const Fields = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;

  > * {
    flex: 1;
    min-width: 250px;
  }
`;

export const WarningWrapper = styled.div`
  margin-top: 20px;
`;

export const WarningText = styled.div`
  font-weight: 400;
  font-size: 12px;
`;

export const TaskLink = styled(Link)`
  color: #235dff;
  font-weight: 400;
  font-size: 14px;
  margin-top: 4px;
  cursor: pointer;
  display: block;
  text-decoration: none;
`;

export const DropdownItemWithIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    flex-shrink: 0;
  }
`;
