import styled from 'styled-components';

export const TooltipProperty = styled.div`
  font-size: 12px;
  line-height: 1.25;
  background-color: #57575f;
  color: ${({ theme }) => theme.colors.white};
  padding: 8px;
  max-width: 250px;
  line-break: anywhere;

  > p + p {
    &:empty {
      display: none;
    }

    margin-top: 10px;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  position: absolute;
  right: 2px;
  top: 2px;
  z-index: 1;

  svg {
    margin: 0 3px;
    cursor: pointer;
    color: ${(props) => props.theme.colors.gray};

    &:hover,
    &:focus {
      color: ${(props) => props.theme.colors.green};
    }
  }
`;

export const WrapperStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const LinkValue = styled.a`
  color: #235dff;
  min-width: 0;
  div {
    text-overflow: ellipsis;
    overflow: hidden;
  }
  display: flex;
  align-items: center;
  gap: 4px;
  white-space: nowrap;
  cursor: pointer;

  &:link,
  &:visited,
  &:hover,
  &:active,
  &:focus {
    color: #235dff;
    text-decoration: none;
  }

  i {
    flex-shrink: 0;
  }
`;

export const LinkButton = styled.a`
  display: flex;
  align-items: center;
  gap: 4px;
  min-width: 0;
  cursor: pointer;
  background-color: #dfdfe8;
  color: #1d1d35;
  text-transform: uppercase;
  font-size: 12px;
  font-style: normal;
  padding: 0px 8px;
  height: 32px;
  border-radius: 4px;
  text-decoration: none;
  white-space: nowrap;

  font-weight: 500;
  letter-spacing: 0.6px;
  text-transform: uppercase;

  &:hover {
    background-color: #c8c8d3;
    color: #1d1d35;
    text-decoration: none;
  }

  &:active {
    background-color: #c8c8d3;
    color: #1d1d35;
    text-decoration: none;
  }

  &:focus {
    color: #1d1d35;
    border: 1px solid #1d1d35;
    text-decoration: none;
  }

  &:link,
  &:visited {
    color: #1d1d35;
  }

  div {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    opacity: 1 !important;
    flex-shrink: 0;
  }

  i {
    flex-shrink: 0;
  }
`;
