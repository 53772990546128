import React from 'react';
import { Form } from '@components/Project/DataBank/types';
import styled from 'styled-components';
import { AddFormLink } from './AddFormLink';
import { FormLinkItem } from './FormLinkItem';

const Wrapper = styled.div`
  width: 100%;
`;

interface FormLinkProps {
  onAddLink: (form: Form) => void;
  onRemoveLink: (formId: number) => void;
  onUpdateLink: (formId: number, form: Form) => void;
  forms: Form[];
  projectId: number;
  companyId: number;
  onFormClick?: (form: Form) => void;
  isDisabled?: boolean;
  onCreateNewForm?: (formIdToReplace?: number) => void;
}

export const FormLink: React.FC<FormLinkProps> = ({
  forms,
  onAddLink,
  onRemoveLink,
  onUpdateLink,
  projectId,
  companyId,
  onFormClick,
  isDisabled = false,
  onCreateNewForm
}: FormLinkProps) => {
  const onUpdateHandler = (formId: number) => (updatedForm: Form) => {
    onUpdateLink(formId, updatedForm);
  };

  const hideForms = forms?.map((f) => f.id);

  return (
    <Wrapper>
      {forms?.length > 0 && (
        <>
          {forms.map((form) => (
            <FormLinkItem
              key={form.id}
              hideForms={hideForms}
              onSelect={onUpdateHandler(form.id)}
              onRemove={onRemoveLink}
              form={form}
              projectId={projectId}
              companyId={companyId}
              onFormClick={onFormClick}
              isReadOnly={isDisabled}
              onCreateNewForm={onCreateNewForm}
            />
          ))}
        </>
      )}

      {!isDisabled && (
        <AddFormLink
          onCreateNewForm={onCreateNewForm}
          onAddLink={onAddLink}
          hideForms={hideForms}
          projectId={projectId}
          companyId={companyId}
        />
      )}
    </Wrapper>
  );
};
