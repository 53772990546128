import moment from 'moment';

import { stringToColor } from '@utils';
import { IsMe } from '@hooks';
import { colors } from '@styles';
import {
  Assignee,
  CalendarEvent,
  CalendarTaskProject,
  Filter,
  ResourceEnum,
} from './types';

export const addAdditionalFields = (task: CalendarEvent) => {
  const startDate = task.startDate && new Date(task.startDate);
  let endDate = task.endDate && new Date(task.endDate);
  if (
    startDate &&
    endDate &&
    startDate.toISOString() === endDate.toISOString()
  ) {
    // if dates are equal - they wont be displayed on monthview
    // in that case we have ot hack it with forcing unequality, since theres no other way around
    endDate = moment(endDate).add({ minutes: 1 }).toDate();
  }

  return {
    ...task,
    endDate,
    startDate,
    projectName: task.project.title,
    stageName: task.stage?.name
  };
};

export const getPriority = (priority: number) => {
  let value;
  switch (priority) {
    case 1:
      value = 'High';
      break;
    case 2:
      value = 'Medium';
      break;
    case 3:
      value = 'Low';
      break;
    default: break;
  }
  return value;
};

export const randomColors = () => [
  Math.ceil(Math.random() * 40),
  Math.ceil(Math.random() * 40),
  Math.ceil(Math.random() * 40),
  Math.ceil(Math.random() * 40),
  Math.ceil(Math.random() * 40)
];

const isFilterBy = (
  filterValue: any,
  isMultiple: boolean = true,
  anyKey: any = -1
) => {
  const isFilterSpecified = isMultiple ? filterValue?.length : filterValue;

  if (!isFilterSpecified) return false;
  if (!isMultiple) return true;
  return !filterValue.some((value) => value === anyKey);
};

export const filterEvents = (
  isMe: IsMe,
  newFilter: Filter,
  events: CalendarEvent[]
) => {
  if (!newFilter) {
    return events;
  }

  let newFilteredEvents = [...events];

  if (isFilterBy(newFilter.search, false)) {
    const keywords = newFilter.search.trim().toLowerCase().split(/\s/);

    newFilteredEvents = newFilteredEvents.filter((nfe) => {
      return keywords.every((keyword) => nfe.title?.toLowerCase().includes(keyword)
        || nfe.project.title.toLowerCase().includes(keyword)
        || nfe.assignees?.some((assignee: Assignee) => assignee.member?.firstName?.toLowerCase().includes(keyword)
          || assignee.member?.lastName?.toLowerCase().includes(keyword))
        || nfe.labels.some((label) => label.label?.toLowerCase().includes(keyword)));
    });
  }
  if (isFilterBy(newFilter.members)) {
    newFilteredEvents = newFilteredEvents.filter(
      ({ assignees: $assignees }) => {
        if (!$assignees) {
          return false;
        }

        const assignees = $assignees as Assignee[];
        const pickAsUnassigned =
          newFilter.members.includes('unassigned') && !assignees.length;
        const pickAsAssignedToMe =
          newFilter.members.includes('me') &&
          assignees.find(({ member }) => member && isMe(member));

        return (
          pickAsUnassigned ||
          pickAsAssignedToMe ||
          assignees.find((nfea) => newFilter.members.includes(nfea.member?.id))
        );
      }
    );
  }

  if (isFilterBy(newFilter.projects)) {
    newFilteredEvents = newFilteredEvents.filter(({ project }) => {
      const isPickedAsOverdue =
        newFilter.projects.includes('overdue') && project.isOverdue;

      return isPickedAsOverdue || newFilter.projects.includes(project.id);
    });
  }

  // label
  if (isFilterBy(newFilter.tags, true, null)) {
    // todo: array of tags for filter (reducer.ts)
    // TODO: typing problems - [1,2,3,4].includes('1') => false
    newFilteredEvents = newFilteredEvents.filter(({ labels, projectStage }) => {
      const $labels = [...labels];
      if (projectStage) {
        $labels.push({
          id: -projectStage.id,
          label: projectStage.name
        });
      }

      const isUnlabelled = !$labels.length;
      const pickAsUnlabelled =
        isUnlabelled && newFilter.tags.includes('unlabelled');

      return (
        pickAsUnlabelled ||
        !!$labels.find(({ id }) => newFilter?.tags?.includes(id))
      );
    });
  }

  // stage
  if (isFilterBy(newFilter.columns)) {
    newFilteredEvents = newFilteredEvents.filter((nfe) =>
      newFilter?.columns?.includes(nfe.stage?.name)
    );
  }

  if (isFilterBy(newFilter.priorities)) {
    newFilteredEvents = newFilteredEvents.filter((nfe) =>
      newFilter.priorities?.includes(nfe.priority)
    );
  }

  if (isFilterBy(newFilter.workspaces)) {
    newFilteredEvents = newFilteredEvents.filter((nfe) =>
      newFilter.workspaces?.includes(nfe.companyId)
    );
  }

  return newFilteredEvents;
};
