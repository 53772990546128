import { useSystemSyncLogs } from '@hooks/systems/useSystemSyncLogs';
import { useIntersectionObserver } from '@react-hookz/web';
import React, { useEffect, useRef } from 'react';
import { StateMessege } from '@common/index';
import { SyncLogItem } from './SyncLogItem';
import { SyncsContainer } from './styled';

interface Props {
  systemId: number;
}

export const Syncs = ({ systemId }: Props) => {
  const loadingNodeRef = useRef<HTMLDivElement>();
  const loadingRef = useRef<boolean>(false);

  const entry = useIntersectionObserver(loadingNodeRef.current);

  const { logs, total, fetchNextPage, isLoading } = useSystemSyncLogs({ systemId });

  useEffect(() => {
    if (entry?.isIntersecting && logs.length < total) {
      if (!loadingRef.current) {
        loadingRef.current = true;
        fetchNextPage().finally(() => {
          loadingRef.current = false;
        });
      }
    }
  }, [entry, logs.length, total, fetchNextPage]);

  return (
    <SyncsContainer>
      {!isLoading && total === 0 && (
        <div>
          <StateMessege subTitle="Looks like there's no activity here yet." />
        </div>
      )}

      {logs.map((log) => (
        <SyncLogItem key={log.thread} logItem={log} />
      ))}
      <div ref={loadingNodeRef} />
    </SyncsContainer>
  );
};
