import { ReactQueryKey } from '@enums';
import { errorHandler } from '@services/api/helpers';
import { useMutation, useQueryClient } from 'react-query';
import projectApi from '@services/api/projectApi';
import { Invoice } from '@generated/types/graphql';
import { useToast } from '..';

type CreateInvoicePayload = {
  title: string;
  description: string;
  dueDate: Date;
  amount: number;
  amountPaid: number;
  relatedToRecordId: number;
  status: string;
  isBill: boolean;
};

export const useCreateInvoice = () => {
  const queryClient = useQueryClient();
  const { showSuccess } = useToast();

  return useMutation<Invoice, Error, CreateInvoicePayload>(
    async (dto) => {
      try {
        const { data: invoice } = await projectApi.createInvoice({
          title: dto.title,
          description: dto.description,
          dueDate: dto.dueDate,
          amount: dto.amount,
          projectId: dto.relatedToRecordId,
          status: dto.status,
          isBill: dto.isBill,
          amountPaid: dto.amountPaid || 0
        });

        return invoice;
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: (invoice) => {
        showSuccess(`${invoice.isBill ? 'Bill' : 'Invoice'} created successfully`);
        queryClient.invalidateQueries([ReactQueryKey.InvoiceList]);
      }
    }
  );
};
