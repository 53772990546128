import { toNumber } from 'lodash';
import { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { RecordDocListItem } from '@hooks/documents/useRecordDocList';
import { useDocListGroupedByStage } from './useDocListGroupedByStage';

export type SelectionType = {
  withShift: boolean;
  withCtrl: boolean;
};

const mapGroupsToState = (
  groups: ReturnType<typeof useDocListGroupedByStage>['groupedDocs']
): Record<number, RecordDocListItem[]> => {
  return groups.reduce(
    (acc, group) => {
      acc[group.stage.id] = group.docs;

      return acc;
    },
    {} as Record<number, RecordDocListItem[]>
  );
};

export const useSelection = () => {
  const [selection, setSelection] = useState<Record<number, Record<number, RecordDocListItem>>>(
    {} as Record<number, Record<number, RecordDocListItem>>
  );

  const lastSelected = useRef<{ groupId: number; id?: number } | null>(null);

  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isAllInGroupSelected, setIsAllInGroupSelected] = useState<Record<number, boolean>>({});

  const [dataByGroup, setDataByGroup] = useState<Record<number, RecordDocListItem[]>>({});

  const groups = useMemo(() => {
    return Object.keys(dataByGroup).map((key) => toNumber(key));
  }, [dataByGroup]);

  const handleShiftItemSelect = useCallback(
    (itemGroup: number, value: RecordDocListItem) => {
      const lastSelectedGroup = lastSelected.current?.groupId;
      let lastSelectedId = lastSelected.current?.id;

      if (!lastSelectedId && lastSelectedGroup && selection[lastSelectedGroup]) {
        const [lastSelectedIdStr] = Object.keys(selection[lastSelectedGroup]);

        if (lastSelectedIdStr) {
          lastSelectedId = toNumber(lastSelectedIdStr);
        }
      }

      if (!lastSelectedId) {
        return;
      }

      const flattenDocs = groups.reduce(
        (acc, group) => {
          return [...acc, ...dataByGroup[group].map((item) => ({ ...item, group }))];
        },
        [] as (RecordDocListItem & { group: number })[]
      );

      const currentIndex = flattenDocs.findIndex((item) => item.id === value.id);
      const lastSelectedIndex = flattenDocs.findIndex((item) => item.id === lastSelectedId);

      const start = Math.min(lastSelectedIndex, currentIndex);
      const end = Math.max(lastSelectedIndex, currentIndex);

      const isItemAlreadySelected = Boolean(selection[itemGroup]?.[value.id]);
      const itemsToProcess = flattenDocs.slice(start, end + 1);

      setSelection((previous) => {
        const copy = { ...previous };

        itemsToProcess.forEach((item) => {
          if (isItemAlreadySelected) {
            if (copy[item.group] && copy[item.group][item.id]) {
              delete copy[item.group][item.id];
            }
          } else {
            copy[item.group] = {
              ...copy[item.group],
              [item.id]: item
            };
          }
        });

        return copy;
      });
    },
    [dataByGroup, selection, groups]
  );

  const handleShiftGroupSelect = useCallback(
    (selectedGroup: number) => {
      const lastSelectedGroup = lastSelected.current?.groupId;
      let lastSelectedId = lastSelected.current?.id;

      if (!lastSelectedId) {
        const indexOfLastSelectedGroup = groups.indexOf(lastSelectedGroup);
        const indexOfCurrentGroup = groups.indexOf(selectedGroup);

        if (indexOfLastSelectedGroup < indexOfCurrentGroup) {
          const lastElementOfSelectedGroup = dataByGroup[lastSelectedGroup][dataByGroup[lastSelectedGroup].length - 1];
          lastSelectedId = lastElementOfSelectedGroup.id;
        } else {
          const firstElementOfSelectedGroup = dataByGroup[lastSelectedGroup][0];
          lastSelectedId = firstElementOfSelectedGroup.id;
        }
      }

      if (!lastSelectedId) {
        return;
      }

      const flattenDocs = groups.reduce(
        (acc, group) => {
          return [...acc, ...dataByGroup[group].map((item) => ({ ...item, group }))];
        },
        [] as (RecordDocListItem & { group: number })[]
      );

      const indexOfLastSelectedGroup = groups.indexOf(lastSelectedGroup);
      const indexOfCurrentGroup = groups.indexOf(selectedGroup);

      let start;
      let end;

      if (indexOfLastSelectedGroup < indexOfCurrentGroup) {
        start = flattenDocs.findIndex((item) => item.id === lastSelectedId);
        const lastElementOfSelectedGroup = dataByGroup[selectedGroup][dataByGroup[selectedGroup].length - 1];
        end = flattenDocs.findIndex((item) => item.id === lastElementOfSelectedGroup.id);
      } else {
        const firstElementOfSelectedGroup = dataByGroup[selectedGroup][0];
        start = flattenDocs.findIndex((item) => item.id === firstElementOfSelectedGroup.id);
        end = flattenDocs.findIndex((item) => item.id === lastSelectedId);
      }

      if (start === -1 || end === -1) {
        return;
      }

      const isAlreadySelected = isAllInGroupSelected[selectedGroup];

      const itemsToProcess = flattenDocs.slice(start, end + 1);

      setSelection((previous) => {
        const copy = { ...previous };

        itemsToProcess.forEach((item) => {
          if (isAlreadySelected) {
            if (copy[item.group] && copy[item.group][item.id]) {
              delete copy[item.group][item.id];
            }
          } else {
            copy[item.group] = {
              ...copy[item.group],
              [item.id]: item
            };
          }
        });

        return copy;
      });
    },
    [isAllInGroupSelected, dataByGroup, groups]
  );

  const toggleItem = useCallback(
    (group: number, value: RecordDocListItem, meta?: SelectionType) => {
      if (meta?.withShift && lastSelected.current) {
        handleShiftItemSelect(group, value);
      } else {
        setSelection((previous) => {
          const copy = { ...previous };

          if (copy[group] && copy[group][value.id]) {
            delete copy[group][value.id];
          } else {
            copy[group] = {
              ...copy[group],
              [value.id]: value
            };
          }

          return copy;
        });
      }

      lastSelected.current = {
        groupId: group,
        id: value.id
      };
    },
    [handleShiftItemSelect]
  );

  const checkIsAllInGroupSelected = useCallback(
    (group: number) => {
      const availableIdsLength = dataByGroup[group]?.length || 0;

      return availableIdsLength > 0 && availableIdsLength === Object.keys(selection[group] || {}).length;
    },
    [selection, dataByGroup]
  );

  const checkIsAllSelected = useCallback(() => {
    const notEmptyGroups = groups.filter((group) => (dataByGroup[group]?.length || 0) > 0);

    if (notEmptyGroups.length === 0) {
      return false;
    }

    return notEmptyGroups.every((group) => checkIsAllInGroupSelected(group));
  }, [groups, checkIsAllInGroupSelected, dataByGroup]);

  const checkIsItemSelected = useCallback(
    (group: number, value: RecordDocListItem) => {
      return Boolean(selection[group]?.[value.id]);
    },
    [selection]
  );

  const toggleGroup = useCallback(
    (group: number, meta?: SelectionType) => {
      if (meta?.withShift && lastSelected.current && lastSelected.current.groupId !== group) {
        handleShiftGroupSelect(group);
      } else {
        setSelection((previous) => {
          const copy = { ...previous };

          if (checkIsAllInGroupSelected(group)) {
            delete copy[group];
          } else {
            const data = dataByGroup[group] || [];
            copy[group] = data.reduce(
              (acc, item) => {
                acc[item.id] = item;

                return acc;
              },
              {} as Record<number, RecordDocListItem>
            );
          }

          return copy;
        });
      }

      lastSelected.current = {
        groupId: group
      };
    },
    [checkIsAllInGroupSelected, dataByGroup, handleShiftGroupSelect]
  );

  useEffect(() => {
    if (groups.length === 0) {
      return;
    }
    // update isAllSelected
    setIsAllSelected(checkIsAllSelected());
  }, [selection, checkIsAllSelected, groups]);

  useEffect(() => {
    if (groups.length === 0) {
      return;
    }

    // update isAllInGroupSelected
    setIsAllInGroupSelected(
      groups.reduce(
        (acc, group) => {
          acc[group] = checkIsAllInGroupSelected(group);

          return acc;
        },
        {} as Record<number, boolean>
      )
    );
  }, [groups, checkIsAllInGroupSelected]);

  const setDataForGroup = useCallback((group: number, data: RecordDocListItem[]) => {
    setDataByGroup((previous) => ({
      ...previous,
      [group]: data
    }));
  }, []);

  const resetDataForGroups = useCallback(
    (groupsToReset: ReturnType<typeof useDocListGroupedByStage>['groupedDocs']) => {
      setDataByGroup(mapGroupsToState(groupsToReset));
      setSelection({});
      lastSelected.current = null;
    },
    []
  );

  const toggleAllSelected = useCallback(() => {
    if (checkIsAllSelected()) {
      setSelection({});
    } else {
      setSelection(
        groups.reduce(
          (acc, group) => {
            const data = dataByGroup[group] || [];

            acc[group] = data.reduce(
              (acc2, item) => {
                return {
                  ...acc2,
                  [item.id]: item
                };
              },
              {} as Record<number, RecordDocListItem>
            );

            return acc;
          },
          {} as Record<number, Record<number, RecordDocListItem>>
        )
      );
    }
  }, [groups, checkIsAllSelected, dataByGroup]);

  const clearSelection = useCallback(() => {
    setSelection({});
  }, []);

  const selectedIds = useMemo(() => {
    return Object.keys(selection).reduce((acc, group) => {
      const ids = Object.keys(selection[toNumber(group)]).map(toNumber);

      return [...acc, ...ids];
    }, [] as number[]);
  }, [selection]);

  const isAllSelectedItemsArchived = useMemo(() => {
    return Object.keys(selection).every((group) => {
      const groupNum = toNumber(group);

      return Object.keys(selection[groupNum]).every((id) => {
        return selection[groupNum][toNumber(id)].isArchived;
      });
    });
  }, [selection]);

  const isAllSelectedFilesSharedWithPortal = useMemo(() => {
    return !Object.keys(selection).some((group) => {
      const groupNum = toNumber(group);

      return Object.keys(selection[groupNum]).some((id) => {
        return !selection[groupNum][toNumber(id)].sharedWithPortal;
      });
    });
  }, [selection]);

  const isAllSelectedFilesNotSharedWithPortal = useMemo(() => {
    return !Object.keys(selection).some((group) => {
      const groupNum = toNumber(group);

      return Object.keys(selection[groupNum]).some((id) => {
        return selection[groupNum][toNumber(id)].sharedWithPortal;
      });
    });
  }, [selection]);

  const bulkInfo = useMemo(() => {
    const fullySelectedGroups = Object.keys(isAllInGroupSelected)
      .filter((group) => group !== '-1' && isAllInGroupSelected[group])
      .map(toNumber);
    const notFullySelectedGroups = Object.keys(selection)
      .filter((group) => !fullySelectedGroups.includes(toNumber(group)))
      .map(toNumber);

    const itemIdsFromNotFullySelectedGroups = notFullySelectedGroups.reduce((acc, group) => {
      return [...acc, ...Object.keys(selection[group]).map(toNumber)];
    }, [] as number[]);

    return {
      selectedGroups: fullySelectedGroups,
      selectedIdsOutsideGroups: itemIdsFromNotFullySelectedGroups,
      selectedIds,
      isAllSelectedItemsArchived,
      isAllSelectedFilesSharedWithPortal,
      isAllSelectedFilesNotSharedWithPortal
    };
  }, [
    isAllSelectedItemsArchived,
    selectedIds,
    selection,
    isAllInGroupSelected,
    isAllSelectedFilesSharedWithPortal,
    isAllSelectedFilesNotSharedWithPortal
  ]);

  return {
    selectedIds,
    toggleItem,
    toggleGroup,
    toggleAllSelected,
    clearSelection,
    checkIsItemSelected,
    isAllSelected,
    isAllInGroupSelected,
    setDataForGroup,
    resetDataForGroups,
    isAllSelectedItemsArchived,
    bulkInfo
  };
};
