import { makeStyles } from '@material-ui/core/styles';
import { defaultTheme } from '@themes';

const pagination = () => {
  return {
    root: {},
    ul: {
      '& li': {
        marginBottom: 0
      }
    }
  };
};

export const useStylesPagination = makeStyles(pagination());

const paginationItem = () => {
  return {
    root: {
      marginBottom: '0',
      fontSize: '12px',
      color: defaultTheme.colors.gray2
    },
    page: {
      backgroundColor: defaultTheme.colors.gray4,
      padding: 0,
      cursor: 'pointer',

      '&:hover, &:focus': {
        backgroundColor: '#e8ecec'
      },
      '&$selected': {}
    },
    sizeSmall: {
      fontSize: '10px'
    },
    sizeLarge: {
      fontSize: '14px'
    },
    textPrimary: {},
    textSecondary: {},
    outlined: {},
    outlinedPrimary: {},
    outlinedSecondary: {},
    rounded: {},
    ellipsis: {},
    focusVisible: {},
    disabled: {},
    selected: {
      color: defaultTheme.colors.black,
      backgroundColor: `${defaultTheme.colors.gray4} !important`
    },
    icon: {}
  };
};

export const useStylesPaginationItem = makeStyles(paginationItem());
