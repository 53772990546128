import { createAction } from 'redux-actions';
import { ActionReturn } from '@state/reducers/types';
import { navigate } from '@reach/router';

import { getPathnameByRecordType } from '@utils/routes';
import { RecordType } from '@types';

export const setSelectedCompanyAction = createAction('@ui/setSelectedCompany');

export const setSelectedCompany = (id: number | undefined): ActionReturn => async (
  dispatch
) => {
  dispatch(setSelectedCompanyAction(id));
};

export const navigateToRecordListing =
  (
    id: number,
    recordType: RecordType
  ): ActionReturn =>
    async (dispatch) => {
      dispatch(setSelectedCompany(id));

      navigate(`/${id}/${getPathnameByRecordType(recordType)}`);
    };
