import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Calendar, ChevronLeft, ChevronRight } from 'react-feather';
import { InputAdornment } from '@material-ui/core';

import moment from 'moment';
import { TextField } from '@common/ui';
import { Container, DatePickerFooter, DatePickerWrapper, TimePickerWrapper, Dash } from './styled';

interface TimeSelectProps {
  value: Date;
  onChange: (value: Date) => void;
  excludeTimes?: Date[];
}

const MIDNIGHT = new Date();
MIDNIGHT.setDate(0);
MIDNIGHT.setHours(0);
MIDNIGHT.setMinutes(0);

const TimeSelect = ({ value, onChange, excludeTimes }: TimeSelectProps) => {
  return (
    <DatePicker
      selected={value}
      onChange={onChange}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={30}
      timeCaption="Time"
      dateFormat="h:mm aa"
      excludeTimes={excludeTimes}
      calendarContainer={TimePickerWrapper}
    />
  );
};

interface Props {
  initialIsOpen?: boolean;
  placeholder?: string;
  start: Date;
  end: Date;
  onClose: (start: Date, end: Date) => void;
  isDisabled?: boolean;
}

const formatRange = (date: Date, startTime: Date, endTime: Date) => {
  return `${moment(date).format('MM/DD/YYYY')} ${moment(startTime).format('h:mmA')} - ${moment(endTime).format(
    'h:mmA'
  )}`;
};

export const DateTimeRangePicker = ({
  initialIsOpen = false,
  start,
  end,
  placeholder = 'Select date and time',
  onClose,
  isDisabled = false
}: Props) => {
  const [isOpen, setIsOpen] = useState(initialIsOpen);
  const [date, setDate] = useState(start);
  const [startTime, setStartTime] = useState(start);
  const [endTime, setEndTime] = useState(end);

  useEffect(() => {
    setDate(start);
    setStartTime(start);
    setEndTime(end);
  }, [start, end]);

  const handleChange = useCallback((newValue: Date) => {
    setDate(newValue);
  }, []);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleStartTimeChange = useCallback(
    (newTime: Date) => {
      setStartTime(newTime);
      if (newTime >= endTime) {
        const newEnd = new Date(newTime);
        newEnd.setHours(newEnd.getHours() + 1);
        setEndTime(newEnd);
      }
    },
    [endTime]
  );

  const handleEndTimeChange = useCallback(
    (newTime: Date) => {
      setEndTime(newTime);
      if (newTime <= startTime) {
        let newStart = new Date(newTime);
        newStart.setHours(newStart.getHours() - 1);
        if (newStart.getHours() > newTime.getHours()) {
          // handle case when 00:30 selected - set start time to 00:00
          newStart = new Date(newTime);
          newStart.setHours(0);
          newStart.setMinutes(0);
        }
        setStartTime(newStart);
      }
    },
    [startTime]
  );

  const handleClose = useCallback(() => {
    const start = new Date(date);
    start.setHours(startTime.getHours());
    start.setMinutes(startTime.getMinutes());

    const end = new Date(date);
    end.setHours(endTime.getHours());
    end.setMinutes(endTime.getMinutes());

    setIsOpen(false);
    onClose(start, end);
  }, [onClose, date, startTime, endTime]);

  const CalendarContainer = useMemo(
    () =>
      ({ children }: { children: React.ReactNode }) => {
        return (
          <DatePickerWrapper>
            {children}
            <DatePickerFooter>
              <TimeSelect value={startTime} onChange={handleStartTimeChange} />
              <Dash>{' - '}</Dash>
              <TimeSelect excludeTimes={[MIDNIGHT]} value={endTime} onChange={handleEndTimeChange} />
            </DatePickerFooter>
          </DatePickerWrapper>
        );
      },
    [startTime, endTime, handleStartTimeChange, handleEndTimeChange]
  );

  const InputComponent = useMemo(
    () =>
      forwardRef<
        HTMLInputElement,
        {
          onClick: React.MouseEventHandler;
        }
      >(({ onClick }, ref) => {
        return (
          <TextField
            className="date-field-input"
            onClick={onClick}
            ref={ref}
            value={formatRange(date, startTime, endTime)}
            variant="outlined"
            placeholder={placeholder}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Calendar />
                </InputAdornment>
              )
            }}
          />
        );
      }),
    [placeholder, date, startTime, endTime]
  );

  return (
    <Container>
      <DatePicker
        selected={date}
        onChange={handleChange}
        customInput={<InputComponent />}
        shouldCloseOnSelect={false}
        open={isOpen}
        disabled={isDisabled}
        // name={name}
        // selected={(!disabled && isValidDate(value) && new Date(value)) || null}
        // dateFormat={dateFormat}
        popperPlacement="top"
        // showTimeSelect={showTimeSelect}
        // timeFormat="hh:mm a"
        calendarContainer={CalendarContainer}
        // onChange={handleOnChange}
        onCalendarClose={handleClose}
        onCalendarOpen={handleOpen}
        onClickOutside={handleClose}
        /* onClickOutside={(e) => {
                    // prevent calling closing on clicking "Clear" value
                    // otherwise it calles onclose and afterthat onChange is called
                    if (!e.target.closest('.clear-calendar-value')) {
                        onClose(e)
                    }
                }} */
        // portalId={withPortal ? 'portal_id' : undefined}
        // disabled={disabled}
        // customInput={CustomInputRendered}
        previousMonthButtonLabel={<ChevronLeft />}
        nextMonthButtonLabel={<ChevronRight />}
        // selectsRange={selectsRange}
        // {...(overwrites || {})}
      />
    </Container>
  );
};
