import { SmartViewFilter } from '@types';
import React, { useCallback, useState } from 'react';
import { Collapse, Tooltip } from '@material-ui/core';
import { Edit2 } from 'react-feather';
import { useAppSelector } from '@hooks/store';
import { selectWorkspaceId } from '@state/selectors';
import { useModal } from '@common/PromiseModal';
import { ChevronUpIcon } from '@kit/ui/icons/ChevronUp';
import { ChevronDownIcon } from '@kit/ui/icons/ChevronDown';
import { useAuth } from '@hooks/useAuth';
import { useUserRoleSettings } from '@hooks/useRoles';
import { hasAccess } from '@utils/roles';
import { DashboardListItem } from '@hooks/analytics/useDashboardList';
import { SmartViewFolderForm } from '../FolderForm';
import { Item } from './Item';
import { EditButton, FolderItem, FolderName } from './styled';

interface Props {
  folder: { id: number; name: string };
  views: (SmartViewFilter | DashboardListItem)[];
}

export const Folder = ({ folder, views }: Props) => {
  const { openModal } = useModal();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const companyId = useAppSelector(selectWorkspaceId);
  const { user } = useAuth();
  const { data: access } = useUserRoleSettings(companyId, user.userId);

  const toggle = useCallback(() => {
    setIsCollapsed((prev) => !prev);
  }, []);

  const handleEditClick = useCallback(
    (e) => {
      e.stopPropagation();
      openModal(({ onClose }) => <SmartViewFolderForm initialValues={folder} onClose={onClose} />, {
        title: 'Edit Folder'
      });
    },
    [folder, openModal]
  );

  return (
    <div>
      <FolderItem onClick={toggle}>
        {isCollapsed ? <ChevronDownIcon size="16px" /> : <ChevronUpIcon size="16px" />}
        <FolderName>{folder.name}</FolderName>
        {hasAccess(access!, 'workspace', 'edit', 'smartviewFolder') && (
          <EditButton onClick={handleEditClick}>
            <Tooltip title="Edit">
              <Edit2 size="12px" />
            </Tooltip>
          </EditButton>
        )}
      </FolderItem>

      <Collapse in={!isCollapsed} timeout="auto">
        {views.map((item) => (
          <Item key={item.id} item={item} />
        ))}
      </Collapse>
    </div>
  );
};
