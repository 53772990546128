import React, { useState } from 'react';
import { Plus } from 'react-feather';
import styled from 'styled-components';
import { colors, checkboxMui, input } from '@styles';
import { makeStyles, Checkbox } from '@material-ui/core';

import { Button, ButtonSize, ButtonVariant } from '@kit/ui/Button';
import IProps from './types';

const useStyles = makeStyles(checkboxMui({}));

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  height: 40px;

  background: ${colors.white};
  border: 1px solid ${colors.gray3};
  border-radius: 4px;

  color: ${colors.black};

  padding: 0 16px 0 0;
  margin-bottom: 8px;

  overflow: hidden;

  &:hover,
  &:focus {
    background-color: ${colors.white3};
  }
`;

const Input = styled.input`
  ${input};
`;

const AddSubTask: React.FC<IProps> = ({ uniqueId, onSubTaskAdded }) => {
  const [isAddingSubTask, setIsAddingSubTask] = useState(false);
  const [newSubTaskName, setNewSubTaskName] = useState('New item');

  const onToggleAddColumn = () => {
    setIsAddingSubTask(!isAddingSubTask);
  };

  const onInputValueChanged = (event) => {
    setNewSubTaskName(event.target.value);
  };

  const onAddColumn = () => {
    if (!newSubTaskName) {
      setIsAddingSubTask(false);

      return;
    }
    onSubTaskAdded({
      id: uniqueId,
      detail: newSubTaskName,
      isCompleted: false
    });
    setIsAddingSubTask(false);
  };

  const onInputKeyDown = (event) => {
    if (event.key === 'Enter') {
      onAddColumn();
    }
    if (event.key === 'Escape' || event.key === 'Esc') {
      setIsAddingSubTask(false);
    }
  };

  const onInputFocus = (event) => {
    event.target.select();
  };

  const onChangeInput = () => {};

  const classes = useStyles();

  return isAddingSubTask ? (
    <Wrapper>
      <Checkbox
        classes={classes}
        disabled
        onClick={onChangeInput}
        style={{ color: colors.gray }}
        data-testid="subTasksCheckboxDone"
      />
      <Input
        autoFocus
        type="text"
        value={newSubTaskName}
        onChange={onInputValueChanged}
        onKeyDown={onInputKeyDown}
        onFocus={onInputFocus}
        onBlur={onAddColumn}
        tableInput
        data-testid="subTasksInput"
      />
      {/* <Button onClick={onAddColumn}>
            <Plus size={20} />
          </Button> */}
    </Wrapper>
  ) : (
    <Button
      onClick={() => {
        onToggleAddColumn();
      }}
      data-testid="subTasksAddSubTask"
      size={ButtonSize.Small}
      isUpperCase={false}
      variant={ButtonVariant.Flat}
    >
      <Plus size="16px" />
      Add Checklist Item
    </Button>
  );
};

export default AddSubTask;
