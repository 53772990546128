import { useUpdateEffect } from '@react-hookz/web';
import { useCallback, useState } from 'react';

export const useInlineEditableField = (onChanged?: () => void) => {
  const [isEditMode, setIsEditMode] = useState(false);

  const handleEnterEditMode = useCallback(() => {
    setIsEditMode(true);
  }, []);

  const handleExitEditMode = useCallback(() => {
    setIsEditMode(false);
  }, []);

  useUpdateEffect(() => {
    if (!isEditMode) {
      onChanged?.();
    }
  }, [isEditMode]);

  return {
    isEditMode,
    onEnterEditMode: handleEnterEditMode,
    onExitEditMode: handleExitEditMode
  };
};
