import { FilterOperators } from '@types';
import {
  AnalyticsWidgetDateRangeType,
  PredefinedWidgetFilterFieldId,
  WorkOrderFilterFieldId
} from '@features/Analytics/types';
import { InternalConfigutationError } from '@features/Analytics/InternalConfigurationError';
import { DatetimeFilter, StringFilter, TaskReportFilter } from '@generated/types/graphql';
import { DeepPartial } from 'redux';
import {
  isWorkOrderDateFilterField,
  isWorkOrderNextVisitDateFilterField,
  isWorkOrderSingleSelectFilterField,
  isWorkOrderTextFilterField,
  isWorkOrderTypeFilterField
} from '@features/Analytics/helpers';
import { DateTime } from 'luxon';
import { dateRangeConfig } from '@features/Analytics/dateRangeConfig';
import { FilterHandlerFn } from './types';

const FILTER_FIELD_ID_TO_TASK_REPORT_FIELD_ID: { [key in WorkOrderFilterFieldId]?: keyof TaskReportFilter } = {
  [PredefinedWidgetFilterFieldId.WORK_ORDER_TITLE]: 'title',
  [PredefinedWidgetFilterFieldId.WORK_ORDER_DESCRIPTION]: 'description',
  [PredefinedWidgetFilterFieldId.WORK_ORDER_TYPE]: 'isField',
  [PredefinedWidgetFilterFieldId.WORK_ORDER_ASSIGNEE]: 'assigneeId',
  [PredefinedWidgetFilterFieldId.WORK_ORDER_STATUS]: 'status',
  [PredefinedWidgetFilterFieldId.WORK_ORDER_PRIORITY]: 'priority',
  [PredefinedWidgetFilterFieldId.WORK_ORDER_LABEL]: 'taskLabelsByTaskId',
  [PredefinedWidgetFilterFieldId.WORK_ORDER_TEMPLATE]: 'templateTaskId',
  [PredefinedWidgetFilterFieldId.WORK_ORDER_CREATED_AT]: 'createdAt',
  [PredefinedWidgetFilterFieldId.WORK_ORDER_COMPLETED_AT]: 'completionDate',
  [PredefinedWidgetFilterFieldId.WORK_ORDER_START_DATE]: 'startDate',
  [PredefinedWidgetFilterFieldId.WORK_ORDER_END_DATE]: 'endDate',
  [PredefinedWidgetFilterFieldId.WORK_ORDER_NEXT_VISIT_DATE]: 'visitStartDate'
};

const buildLikeFilter: FilterHandlerFn = ({ filter }) => {
  if (!isWorkOrderTextFilterField(filter.fieldId)) {
    throw new InternalConfigutationError(`Unsupported filter operator for ${filter.fieldId}`);
  }

  const attributeName = FILTER_FIELD_ID_TO_TASK_REPORT_FIELD_ID[filter.fieldId as WorkOrderFilterFieldId];

  if (!attributeName) {
    throw new InternalConfigutationError(`Unsupported filter field for ${filter.fieldId}`);
  }

  if (!filter.value) {
    return {
      or: [
        {
          [attributeName]: {
            isNull: true
          }
        },
        {
          [attributeName]: {
            equalTo: ''
          }
        }
      ]
    };
  }

  const stringFilter: DeepPartial<StringFilter> = {
    includesInsensitive: filter.value as string
  };

  return {
    [attributeName]: stringFilter
  };
};

const buildNotEqualToFilter: FilterHandlerFn = ({ filter }) => {
  if (!isWorkOrderTextFilterField(filter.fieldId) && !isWorkOrderDateFilterField(filter.fieldId)) {
    throw new InternalConfigutationError(`Unsupported filter operator for ${filter.fieldId}`);
  }

  const attributeName = FILTER_FIELD_ID_TO_TASK_REPORT_FIELD_ID[filter.fieldId as WorkOrderFilterFieldId];

  if (!attributeName) {
    throw new InternalConfigutationError(`Unsupported filter field for ${filter.fieldId}`);
  }

  if (!filter.value) {
    const isNullFilter: DeepPartial<StringFilter> = {
      isNull: true
    };

    const isEmptyFilter: DeepPartial<StringFilter> = {
      equalTo: ''
    };

    if (isWorkOrderDateFilterField(filter.fieldId)) {
      if (isWorkOrderNextVisitDateFilterField(filter.fieldId)) {
        return {
          isField: {
            equalTo: true
          },
          taskVisitsByTaskId: {
            some: {
              isCompleted: {
                equalTo: false
              }
            }
          }
        };
      }

      return {
        [attributeName]: {
          isNull: false
        }
      };
    }

    return {
      not: {
        or: [
          {
            [attributeName]: isNullFilter
          },
          {
            [attributeName]: isEmptyFilter
          }
        ]
      }
    };
  }

  if (isWorkOrderDateFilterField(filter.fieldId)) {
    const dateTime = DateTime.fromISO(filter.value as string);

    const datetimeFilter: DeepPartial<DatetimeFilter> = {
      greaterThanOrEqualTo: dateTime.startOf('day').toISO(),
      lessThanOrEqualTo: dateTime.endOf('day').toISO()
    };

    if (isWorkOrderNextVisitDateFilterField(filter.fieldId)) {
      return {
        isField: {
          equalTo: true
        },
        not: {
          taskVisitsByTaskId: {
            some: {
              isCompleted: {
                equalTo: false
              },
              startDate: datetimeFilter
            }
          }
        }
      };
    }

    return {
      not: {
        [attributeName]: datetimeFilter
      }
    };
  }

  const stringFilter: DeepPartial<StringFilter> = {
    notEqualTo: filter.value as string
  };

  return {
    [attributeName]: stringFilter
  };
};

const buildEqualToFilter: FilterHandlerFn = ({ filter }) => {
  if (!isWorkOrderTypeFilterField(filter.fieldId) && !isWorkOrderDateFilterField(filter.fieldId)) {
    throw new InternalConfigutationError(`Unsupported filter operator for ${filter.fieldId}`);
  }

  const attributeName = FILTER_FIELD_ID_TO_TASK_REPORT_FIELD_ID[filter.fieldId as WorkOrderFilterFieldId];

  if (!attributeName) {
    throw new InternalConfigutationError(`Unsupported filter field for ${filter.fieldId}`);
  }

  if (!filter.value) {
    if (isWorkOrderTypeFilterField(filter.fieldId)) {
      return {};
    }

    if (isWorkOrderNextVisitDateFilterField(filter.fieldId)) {
      return {
        isField: {
          equalTo: true
        },
        or: [
          {
            taskVisitsByTaskIdExist: false
          },
          {
            not: {
              taskVisitsByTaskId: {
                some: {
                  isCompleted: {
                    equalTo: false
                  }
                }
              }
            }
          }
        ]
      };
    }

    const nullFilter: DeepPartial<DatetimeFilter> = {
      isNull: true
    };

    return {
      [attributeName]: nullFilter
    };
  }

  if (isWorkOrderTypeFilterField(filter.fieldId)) {
    return {
      isField: {
        equalTo: filter.value === 'field'
      }
    };
  }

  const dateTime = DateTime.fromISO(filter.value as string);

  const datetimeFilter: DeepPartial<DatetimeFilter> = {
    greaterThanOrEqualTo: dateTime.startOf('day').toISO(),
    lessThanOrEqualTo: dateTime.endOf('day').toISO()
  };

  if (isWorkOrderNextVisitDateFilterField(filter.fieldId)) {
    return {
      isField: {
        equalTo: true
      },
      taskVisitsByTaskId: {
        some: {
          isCompleted: {
            equalTo: false
          },
          startDate: datetimeFilter
        }
      }
    };
  }

  return {
    [attributeName]: datetimeFilter
  };
};

const buildInFilter: FilterHandlerFn = ({ filter }) => {
  if (!isWorkOrderSingleSelectFilterField(filter.fieldId)) {
    throw new InternalConfigutationError(`Unsupported filter operator for ${filter.fieldId}`);
  }

  const attributeName = FILTER_FIELD_ID_TO_TASK_REPORT_FIELD_ID[filter.fieldId as WorkOrderFilterFieldId];

  if (!attributeName) {
    throw new InternalConfigutationError(`Unsupported filter field for ${filter.fieldId}`);
  }

  if (!filter.value || !filter.value.length) {
    if (filter.fieldId === PredefinedWidgetFilterFieldId.WORK_ORDER_LABEL) {
      return {
        taskLabelsByTaskIdExist: false
      };
    }

    return {
      [attributeName]: {
        isNull: true
      }
    };
  }

  if (filter.fieldId === PredefinedWidgetFilterFieldId.WORK_ORDER_LABEL) {
    return {
      taskLabelsByTaskId: {
        some: {
          labelId: {
            in: filter.value
          }
        }
      }
    };
  }

  return {
    [attributeName]: {
      in: filter.value
    }
  };
};

const buildNotInFilter: FilterHandlerFn = ({ filter }) => {
  if (!isWorkOrderSingleSelectFilterField(filter.fieldId)) {
    throw new InternalConfigutationError(`Unsupported filter operator for ${filter.fieldId}`);
  }

  const attributeName = FILTER_FIELD_ID_TO_TASK_REPORT_FIELD_ID[filter.fieldId as WorkOrderFilterFieldId];

  if (!attributeName) {
    throw new InternalConfigutationError(`Unsupported filter field for ${filter.fieldId}`);
  }

  if (!filter.value || !filter.value.length) {
    if (filter.fieldId === PredefinedWidgetFilterFieldId.WORK_ORDER_LABEL) {
      return {
        taskLabelsByTaskIdExist: true
      };
    }

    return {
      [attributeName]: {
        isNull: false
      }
    };
  }

  if (filter.fieldId === PredefinedWidgetFilterFieldId.WORK_ORDER_LABEL) {
    return {
      not: {
        taskLabelsByTaskId: {
          some: {
            labelId: {
              in: filter.value as string[]
            }
          }
        }
      }
    };
  }

  return {
    not: {
      [attributeName]: {
        in: filter.value
      }
    }
  };
};

const buildBeforeFilter: FilterHandlerFn = ({ filter }) => {
  if (!isWorkOrderDateFilterField(filter.fieldId)) {
    throw new InternalConfigutationError(`Unsupported filter operator for ${filter.fieldId}`);
  }

  const attributeName = FILTER_FIELD_ID_TO_TASK_REPORT_FIELD_ID[filter.fieldId as WorkOrderFilterFieldId];

  if (!attributeName) {
    throw new InternalConfigutationError(`Unsupported filter field for ${filter.fieldId}`);
  }

  if (!filter.value) {
    return {};
  }

  const dateTime = DateTime.fromISO(filter.value as string);

  const datetimeFilter: DeepPartial<DatetimeFilter> = {
    lessThan: dateTime.startOf('day').toISO()
  };

  if (isWorkOrderNextVisitDateFilterField(filter.fieldId)) {
    return {
      isField: {
        equalTo: true
      },
      taskVisitsByTaskId: {
        some: {
          isCompleted: {
            equalTo: false
          },
          startDate: datetimeFilter
        }
      }
    };
  }

  return {
    [attributeName]: datetimeFilter
  };
};

const buildAfterFilter: FilterHandlerFn = ({ filter }) => {
  if (!isWorkOrderDateFilterField(filter.fieldId)) {
    throw new InternalConfigutationError(`Unsupported filter operator for ${filter.fieldId}`);
  }

  const attributeName = FILTER_FIELD_ID_TO_TASK_REPORT_FIELD_ID[filter.fieldId as WorkOrderFilterFieldId];

  if (!attributeName) {
    throw new InternalConfigutationError(`Unsupported filter field for ${filter.fieldId}`);
  }

  if (!filter.value) {
    return {};
  }

  const dateTime = DateTime.fromISO(filter.value as string);

  const datetimeFilter: DeepPartial<DatetimeFilter> = {
    greaterThan: dateTime.endOf('day').toISO()
  };

  if (isWorkOrderNextVisitDateFilterField(filter.fieldId)) {
    return {
      isField: {
        equalTo: true
      },
      taskVisitsByTaskId: {
        some: {
          isCompleted: {
            equalTo: false
          },
          startDate: datetimeFilter
        }
      }
    };
  }

  return {
    [attributeName]: datetimeFilter
  };
};

const buildWithinFilter: FilterHandlerFn = ({ filter }) => {
  if (!isWorkOrderDateFilterField(filter.fieldId)) {
    throw new InternalConfigutationError(`Unsupported filter operator for ${filter.fieldId}`);
  }

  const attributeName = FILTER_FIELD_ID_TO_TASK_REPORT_FIELD_ID[filter.fieldId as WorkOrderFilterFieldId];

  if (!attributeName) {
    throw new InternalConfigutationError(`Unsupported filter field for ${filter.fieldId}`);
  }

  if (!filter.value) {
    return {};
  }

  const dateRangeOption = dateRangeConfig[filter.value as AnalyticsWidgetDateRangeType];

  if (!dateRangeOption) {
    throw new InternalConfigutationError(`Unsupported date range type ${filter.value}`);
  }

  const datetimeFilter: DeepPartial<DatetimeFilter> = {
    greaterThanOrEqualTo: dateRangeOption.startDate().toISO(),
    lessThanOrEqualTo: dateRangeOption.endDate().toISO()
  };

  if (isWorkOrderNextVisitDateFilterField(filter.fieldId)) {
    return {
      isField: {
        equalTo: true
      },
      taskVisitsByTaskId: {
        some: {
          isCompleted: {
            equalTo: false
          },
          startDate: datetimeFilter
        }
      }
    };
  }

  return {
    [attributeName]: datetimeFilter
  };
};

const buildNotWithinFilter: FilterHandlerFn = ({ filter }) => {
  if (!isWorkOrderDateFilterField(filter.fieldId)) {
    throw new InternalConfigutationError(`Unsupported filter operator for ${filter.fieldId}`);
  }

  const attributeName = FILTER_FIELD_ID_TO_TASK_REPORT_FIELD_ID[filter.fieldId as WorkOrderFilterFieldId];

  if (!attributeName) {
    throw new InternalConfigutationError(`Unsupported filter field for ${filter.fieldId}`);
  }

  if (!filter.value) {
    return {};
  }

  const dateRangeOption = dateRangeConfig[filter.value as AnalyticsWidgetDateRangeType];

  if (!dateRangeOption) {
    throw new InternalConfigutationError(`Unsupported date range type ${filter.value}`);
  }

  const datetimeFilter: DeepPartial<DatetimeFilter> = {
    greaterThanOrEqualTo: dateRangeOption.startDate().toISO(),
    lessThanOrEqualTo: dateRangeOption.endDate().toISO()
  };

  if (isWorkOrderNextVisitDateFilterField(filter.fieldId)) {
    return {
      isField: {
        equalTo: true
      },
      or: [
        {
          taskVisitsByTaskIdExist: false
        },
        {
          not: {
            taskVisitsByTaskId: {
              some: {
                isCompleted: {
                  equalTo: false
                },
                startDate: datetimeFilter
              }
            }
          }
        }
      ]
    };
  }

  return {
    not: {
      [attributeName]: datetimeFilter
    }
  };
};

export const standardAttributesFilterHandlers: { [key in FilterOperators]?: FilterHandlerFn } = {
  [FilterOperators.Like]: buildLikeFilter,
  [FilterOperators.NotEqualTo]: buildNotEqualToFilter,
  [FilterOperators.EqualTo]: buildEqualToFilter,
  [FilterOperators.In]: buildInFilter,
  [FilterOperators.NotIn]: buildNotInFilter,
  [FilterOperators.Before]: buildBeforeFilter,
  [FilterOperators.After]: buildAfterFilter,
  [FilterOperators.Within]: buildWithinFilter,
  [FilterOperators.NotWithin]: buildNotWithinFilter
};
