import styled from 'styled-components';

export const Section = styled.div<{ isVisible: boolean; notReverse?: boolean }>`
  display: flex;

  flex-direction: ${(props) => (props.notReverse ? 'column' : 'column-reverse')};
  position: relative;

  > span {
    top: 0;
    z-index: 11;
  }

  ${(props) =>
    props.isVisible
      ? `
    > span {
      position: sticky;
    }
  `
      : `
    > span {
      position: relative;
    }
  `};

  > div:first-child {
    > div:first-child {
      &:after {
        display: none;
      }
    }
  }
`;
