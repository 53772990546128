import { useMutation } from 'react-query';

import taskApi from '@services/api/tasksApi';
import { errorHandler } from '@services/api/helpers';
import { alertShow } from '@state/actions/alert/alertAction';
import { useAppDispatch, useAppSelector } from '@hooks/store';
import { selectCompanyIdBySelectedPage } from '@state/selectors';
import { BulkUpdateTaskDto } from '@types';

export const useTaskBulk = () => {
  const dispatch = useAppDispatch();
  const companyId = useAppSelector(selectCompanyIdBySelectedPage);

  const updateMutation = useMutation<{ isAsync: boolean }, Error, BulkUpdateTaskDto>(
    async (dto: BulkUpdateTaskDto) => {
      try {
        return (await taskApi.bulkUpdate(dto, { companyId })).data;
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: (result) => {
        if (!result.isAsync) {
          dispatch(alertShow(['Successfully updated Work Orders'], 'success'));
        } else {
          dispatch(alertShow(['Bulk edit started, might take a minute for changes to apply.'], 'warning'));
        }
      },
      onError: (error: Error) => {
        dispatch(alertShow([error.message], 'error'));
      }
    }
  );

  return {
    update: updateMutation
  };
};
