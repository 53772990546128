import React from 'react';
import { BarChart as RechartsBarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import { ChartBasePoint } from './types';

interface Props {
  children: React.ReactNode;
  points: ChartBasePoint[];
  isXAxisReversed?: boolean;
  xAxisTickFormatter?: (value: number) => string;
}

export const ChartBase = ({ points, children, isXAxisReversed, xAxisTickFormatter }: Props) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <RechartsBarChart
        width={500}
        height={300}
        data={points}
        layout="vertical"
        margin={{
          top: 16,
          right: 0,
          left: 0,
          bottom: 16
        }}
      >
        <CartesianGrid vertical horizontal={false} strokeDasharray={4} />
        <XAxis
          fontSize={12}
          stroke="#828D9A"
          axisLine={false}
          tickLine={false}
          type="number"
          dataKey="value"
          reversed={isXAxisReversed}
          allowDecimals={false}
          tickFormatter={xAxisTickFormatter}
        />
        <Tooltip formatter={xAxisTickFormatter} />
        {children}
      </RechartsBarChart>
    </ResponsiveContainer>
  );
};
