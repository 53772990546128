import React, { useMemo } from 'react';
import { colors } from '@styles';
import { STATUS_CONFIG } from '@features/ClientPortfolio/constants';
import { AccountStatus } from '@types';
import { formatNumber } from '@utils/utils';
import { Container, Tile, TileNumber } from './styled';
import { useClientFilterState } from '../useClientFilterState';
import { useTotalsByStatus } from '../Table/useTotalsByStatus';

export const StatusTiles = () => {
  const { totals } = useTotalsByStatus();
  const { clientFilters, updateFilters } = useClientFilterState();
  const tiles = useMemo(() => {
    return [
      {
        label: 'All',
        value: totals.all,
        isSelected: !clientFilters.status,
        color: colors.black,
        status: null
      },
      {
        label: 'Leads',
        value: totals[AccountStatus.LEAD],
        isSelected: clientFilters.status === AccountStatus.LEAD,
        color: STATUS_CONFIG[AccountStatus.LEAD].color,
        status: AccountStatus.LEAD
      },
      {
        label: 'Prospects',
        value: totals[AccountStatus.PROSPECT],
        isSelected: clientFilters.status === AccountStatus.PROSPECT,
        color: STATUS_CONFIG[AccountStatus.PROSPECT].color,
        status: AccountStatus.PROSPECT
      },
      {
        label: 'Customers',
        value: totals[AccountStatus.CUSTOMER],
        isSelected: clientFilters.status === AccountStatus.CUSTOMER,
        color: STATUS_CONFIG[AccountStatus.CUSTOMER].color,
        status: AccountStatus.CUSTOMER
      }
    ];
  }, [clientFilters.status, totals]);

  return (
    <Container>
      {tiles.map((tile) => (
        <Tile onClick={() => updateFilters({ status: tile.status })} key={tile.label} isSelected={tile.isSelected}>
          <TileNumber color={tile.color}>{formatNumber(tile.value)}</TileNumber>
          <div>{tile.label}</div>
        </Tile>
      ))}
    </Container>
  );
};
