import styled from 'styled-components';

export const ButtonContainer = styled.div<{ isHidden?: boolean }>`
  visibility: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};
  cursor: ${({ isHidden }) => (isHidden ? 'default' : 'pointer')};
`;

export const OptionList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 160px;
`;

export const Option = styled.div`
  padding: 4px;

  transition: background-color 0.15s ease-in-out;

  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

export const OptionTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
`;

export const OptionDescription = styled.div`
  font-size: 12px;
  color: #828d9a;
  font-weight: 400;
`;

export const Asterisk = styled.span`
  color: #d54855;
`;
