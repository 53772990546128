import React from 'react';
import { voidSvg } from '@assets/svg';
import { Image, Text, Wrapper } from './styled';

type Props = {
  subject?: string;
};

export const NoResult = ({ subject = 'item' }: Props) => {
  return (
    <Wrapper>
      <Image src={voidSvg} />
      <Text>Sorry, the {subject.toLowerCase()} is deleted, or you have no access to it.</Text>
    </Wrapper>
  );
};
