import styled from 'styled-components';
import { Row } from '../styled';

const TITLE_CELL_WIDTH = `calc(100% - 32px - 32px - 64px - 100px)`;

export const TitleCell = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  min-width: ${TITLE_CELL_WIDTH};
  flex: 0 0 ${TITLE_CELL_WIDTH};
  max-width: ${TITLE_CELL_WIDTH};
  width: ${TITLE_CELL_WIDTH};
  margin-right: auto;
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  white-space: nowrap;

  gap: 4px;

  span {
    font-weight: 400;
  }

  div {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const CenteredRow = styled(Row)`
  align-items: center;
  gap: 32px;
`;

export const EnergyValue = styled.div`
  font-weight: 500;
  font-size: 14px;
`;

export const EnergyLabel = styled.div`
  color: #828d9a;
  font-size: 12px;
  font-weight: 400;
`;
