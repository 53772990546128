import React from 'react';
import { useActionTemplate } from '@hooks/templates/actions/useActionTemplate';
import { Button, ButtonVariant } from '@kit/ui/Button';
import {
  FileDropZoneField,
  Form,
  FormValidationRules,
  InlineEditableInputField,
  InlineEditableSelectField,
  InlineEditableTextareaField,
  useForm
} from '@kit/components/Form';
import { Action } from '@generated/types/graphql';
import { useCreateActionTemplate } from '@hooks/templates/actions/useCreateActionTemplate';
import { useUpdateActionTemplate } from '@hooks/templates/actions/useUpdateActionTemplate';
import { useDrawersContext } from '@contexts/DrawersContext';
import { FileUpIcon } from '@kit/ui/icons/FileUp';
import {
  Container,
  Header,
  Content,
  Footer,
  TitleView,
  TitleInputWrapper,
  FieldBlock,
  FieldLabel,
  SelectValueView,
  DescriptionView,
  DescriptionWrapper,
  Separator
} from './styled';

const TYPE_OPTIONS = [{ id: 'DOC_UPLOAD', label: 'Document upload', icon: <FileUpIcon size="16px" color="#9C9CAA" /> }];

enum AssigneeType {
  PRIMARY = 'primary',
  ALL = 'all'
}

const ASSIGNEE_OPTIONS = [
  { id: AssigneeType.PRIMARY, label: 'Primary contact' },
  { id: AssigneeType.ALL, label: 'All contacts' }
];

type FormValues = {
  title: string;
  description: string;
  type: { id: string; label: string; icon: React.ReactNode };
  assignee: { id: string; label: string };
};

interface Props {
  initialValues?: Action;
}

const RULES: FormValidationRules<FormValues> = {
  title: {
    isRequired: true
  }
};

export const ActionTemplateForm = ({ initialValues }: Props) => {
  const isNew = !initialValues?.id;

  const { mutateAsync: create } = useCreateActionTemplate();
  const { mutateAsync: update } = useUpdateActionTemplate();

  const { closeDrawer } = useDrawersContext();

  const postForm = async (values: FormValues) => {
    if (isNew) {
      await create({
        title: values.title,
        description: values.description,
        assignAllContacts: values.assignee.id === AssigneeType.ALL,
        type: values.type?.id
      });

      closeDrawer(0);
    } else {
      await update({
        id: initialValues.id,
        dto: {
          title: values.title || initialValues.title,
          description: values.description,
          assignAllContacts: values.assignee.id === AssigneeType.ALL,
          type: values.type?.id
        }
      });
    }
  };

  const { handleSubmit, form } = useForm({
    onSubmit: postForm,
    defaultValues: initialValues
      ? {
          title: initialValues.title,
          description: initialValues.description,
          type: TYPE_OPTIONS.find((type) => type.id === initialValues.type) || TYPE_OPTIONS[0],
          assignee: initialValues.assignAllContacts ? ASSIGNEE_OPTIONS[1] : ASSIGNEE_OPTIONS[0]
        }
      : {
          title: '',
          description: '',
          type: TYPE_OPTIONS[0],
          assignee: ASSIGNEE_OPTIONS[0]
        }
  });

  const {
    formState: { isSubmitting },
    control
  } = form;

  const triggerSubmit = () => {
    handleSubmit();
  };

  const handleCancel = () => {
    closeDrawer(0);
  };

  return (
    <Container>
      <Form rules={RULES} onSubmit={handleSubmit}>
        <Header>{isNew ? 'Create Action Item Template' : 'Edit Action Item Template'}</Header>

        <Content>
          <TitleInputWrapper>
            <InlineEditableInputField
              name="title"
              control={control}
              placeholder="Type here a template name"
              onChanged={isNew ? undefined : triggerSubmit}
              renderView={(value, onClick) => (
                <TitleView isPlaceholder={!value} onClick={onClick}>
                  {value || 'Type here a template name'}
                </TitleView>
              )}
            />
          </TitleInputWrapper>

          <FieldBlock isCentered>
            <FieldLabel>Type</FieldLabel>
            <InlineEditableSelectField
              name="type"
              control={control}
              disabled
              renderView={(value) =>
                value ? (
                  <SelectValueView isDisabled>
                    {value.icon}
                    {value.label}
                  </SelectValueView>
                ) : (
                  'Select type'
                )
              }
            />
          </FieldBlock>

          <FieldBlock isCentered>
            <FieldLabel>Assignee</FieldLabel>
            <InlineEditableSelectField
              name="assignee"
              control={control}
              isClearable={false}
              options={ASSIGNEE_OPTIONS}
              getOptionLabel={(option) => option.label}
              onChanged={isNew ? undefined : triggerSubmit}
              renderView={(value, onClick) =>
                value ? <SelectValueView onClick={onClick}>{value.label}</SelectValueView> : 'Select assignee'
              }
            />
          </FieldBlock>

          <DescriptionWrapper>
            <InlineEditableTextareaField
              placeholder="Add here a description for this document upload"
              label="Description"
              name="description"
              control={control}
              onChanged={isNew ? undefined : triggerSubmit}
              renderView={(value, onClick) => (
                <DescriptionView onClick={onClick} isPlaceholder={!value}>
                  {value || 'Add here a description for this document upload'}
                </DescriptionView>
              )}
            />
          </DescriptionWrapper>

          <Separator />

          <FileDropZoneField
            dropZoneHeight="150px"
            isDisabled
            name="files"
            label="After files are submitted, you’ll see them here. You can also upload a file and complete the document upload if needed."
            control={control}
          />
        </Content>
        {isNew && (
          <Footer>
            <Button type="submit" disabled={isSubmitting} variant={ButtonVariant.Primary}>
              Save
            </Button>
            <Button onClick={handleCancel} disabled={isSubmitting} variant={ButtonVariant.Secondary}>
              Cancel
            </Button>
          </Footer>
        )}
      </Form>
    </Container>
  );
};

export const ActionTemplateFormContainer = ({ id }: { id: number }) => {
  const { data: template, isLoading } = useActionTemplate(id > 0 ? id : undefined);

  if (Number.isNaN(id) || isLoading) {
    return null;
  }

  return <ActionTemplateForm initialValues={template} />;
};
