import { Action } from '@generated/types/graphql';
import React from 'react';
import { ActionItemStatus } from '@kit/components/ActionItemStatus';
import { parseUtcDate } from '@utils/dates';
import moment from 'moment';
import { TrackingLabel, TrackingRow } from './styled';

const formatDate = (date: string) => moment(parseUtcDate(date)).format('MM/DD/YY');

interface Props {
  action: Action;
}

export const Tracking = ({ action }: Props) => {
  return (
    <div>
      <TrackingLabel>Tracking</TrackingLabel>

      <div>
        <TrackingRow>
          <TrackingLabel>Status</TrackingLabel>
          <TrackingLabel>Date</TrackingLabel>
        </TrackingRow>
        {action.actionStatusUpdates.map((update) => (
          <TrackingRow key={update.id}>
            <ActionItemStatus status={update.status} />
            <div>{formatDate(update.createdAt)}</div>
          </TrackingRow>
        ))}
      </div>
    </div>
  );
};
