import React from 'react';
import { Checkbox } from '@kit/ui/Checkbox';
import { TableHeaderContainer, TableHeaderTitleCell, TableHeaderTitleCellInner, Th } from './styled';

interface Props {
  isAllSelected: boolean;
  onToggleAllSelected: (isChecked: boolean) => void;
  columns: { id: string; name: string; width: string }[];
  canBulkEdit: boolean;
}

export const TableHeader = ({ isAllSelected, onToggleAllSelected, columns, canBulkEdit }: Props) => {
  return (
    <TableHeaderContainer>
      <thead>
        <tr>
          <TableHeaderTitleCell>
            <TableHeaderTitleCellInner>
              {canBulkEdit && <Checkbox isChecked={isAllSelected} onChange={onToggleAllSelected} />}
              Name
            </TableHeaderTitleCellInner>
          </TableHeaderTitleCell>
          {columns.map((column) => (
            <Th key={column.id} width={column.width}>
              {column.name}
            </Th>
          ))}
          <Th width="5%" />
        </tr>
      </thead>
    </TableHeaderContainer>
  );
};
