import { handleActions, Action } from 'redux-actions';
import * as actionsExport from '@components/Project/DataBank/redux/actions';
import { ReduxStateDatabank, Reducer, Payloads } from './types';

import { mergePayloadToState, mergeToState } from './helpers';

const actions = actionsExport as unknown as { [key: string]: string };

export const initialState: ReduxStateDatabank = {
  formLabels: [],
  labelPager: {
    limit: 10,
    skip: 0,
    page: 0,
    name: ''
  },
  totalLabels: 0,
  loadingFetchLabels: false
};

const fetchLabelsSuccess: Reducer = (state, action) => {
  const { newLabels, pager, total } = action.payload;

  return {
    ...state,
    formLabels: [...state.formLabels, ...newLabels],
    totalLabels: total,
    labelPager: pager,
    loadingFetchLabels: false
  };
};

const removeFormLabel: Reducer = (state, action) => {
  return {
    ...state,
    formLabels: state.formLabels.filter((label) => label.id !== action.payload.id)
  };
};

const databankReducer = handleActions<ReduxStateDatabank, Action<Payloads>>(
  {
    [actions.fetchLabelsPaginationStart]: mergeToState({
      loadingFetchLabels: true
    }),
    [actions.fetchLabelsPaginationSuccess]: fetchLabelsSuccess,
    [actions.fetchLabelsPaginationFail]: mergeToState({
      loadingFetchLabels: false
    }),
    [actions.updateLabels]: mergePayloadToState,
    [actions.removeFormLabel]: removeFormLabel
  },
  initialState
);

export default databankReducer;
