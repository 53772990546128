import { useCallback, useMemo, useState } from 'react';

export const useSelection = () => {
  const [selection, setSelection] = useState<Record<number, boolean>>({} as Record<number, boolean>);

  const toggleItem = useCallback((id: number) => {
    setSelection((previous) => {
      const copy = { ...previous };

      if (copy[id]) {
        delete copy[id];
      } else {
        copy[id] = true;
      }

      return copy;
    });
  }, []);

  const toggleGroup = useCallback((ids: number[], isChecked: boolean) => {
    setSelection((previous) => {
      const copy = { ...previous };

      ids.forEach((id) => {
        if (isChecked) {
          copy[id] = true;
        } else {
          delete copy[id];
        }
      });

      return copy;
    });
  }, []);

  const selectedIds = useMemo(() => {
    return Object.keys(selection).map((key) => parseInt(key, 10));
  }, [selection]);

  const isItemSelected = useCallback(
    (value: number) => {
      return Boolean(selection[value]);
    },
    [selection]
  );

  const isGroupSelected = useCallback(
    (ids: number[]) => {
      return ids.every((id) => selection[id]);
    },
    [selection]
  );

  const clearSelection = useCallback(() => {
    setSelection({});
  }, []);

  return {
    isItemSelected,
    toggleItem,
    toggleGroup,
    selectedIds,
    isGroupSelected,
    clearSelection
  };
};
