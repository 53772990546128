import { useFileMutation } from '@hooks/useFiles';
import { Form, FormValidationRules, InputField, useForm } from '@kit/components/Form';
import { Button, ButtonVariant } from '@kit/ui/Button';
import React from 'react';
import { useFormMutations } from '@hooks/useForms';
import { Body, Footer } from './styled';

type Doc = {
  id: number;
  name: string;
  form: {
    id: number;
  };
  type: string;
};

interface Props {
  doc: Doc;
  onClose: () => void;
}

type FormValues = {
  name: string;
};

const rules: FormValidationRules<FormValues> = {
  name: {
    isRequired: true
  }
};

export const RenameDoc = ({ doc, onClose }: Props) => {
  const {
    update: { mutateAsync: updateFile }
  } = useFileMutation();
  const {
    update: { mutateAsync: updateForm }
  } = useFormMutations();

  const postForm = async ({ name }: FormValues) => {
    if (doc.type === 'form') {
      await updateForm({
        formId: doc.form.id,
        dto: {
          name
        }
      });
    } else {
      await updateFile({
        fileId: doc.id,
        name
      });
    }

    onClose();
  };

  const { form, handleSubmit } = useForm({
    onSubmit: postForm,
    defaultValues: { name: doc.name }
  });

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <Body>
        <InputField control={form.control} name="name" label="Name" />
      </Body>
      <Footer>
        <Button variant={ButtonVariant.Secondary} onClick={onClose}>
          Cancel
        </Button>
        <Button variant={ButtonVariant.Primary} type="submit">
          Save
        </Button>
      </Footer>
    </Form>
  );
};
