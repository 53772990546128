import React from 'react';
import { Checkbox } from '@kit/ui/Checkbox';
import { isCustomerPortalEnabled } from '@state/selectors';
import { useAppSelector } from '@hooks/store';
import { Row, Cell, FileNameCell, NameAndCheckbox } from './styled';

interface Props {
  isAllSelected: boolean;
  onToggleAllSelected: () => void;
}

export const ListHeader = ({ isAllSelected, onToggleAllSelected }: Props) => {
  const isPortalEnabled = useAppSelector(isCustomerPortalEnabled);

  return (
    <Row>
      <Cell width={56}>
        <NameAndCheckbox>
          <Checkbox isChecked={isAllSelected} onChange={onToggleAllSelected} />
          Name
        </NameAndCheckbox>
      </Cell>

      <FileNameCell />
      {isPortalEnabled && <Cell width={120} />}
      <Cell width={80}>Commented</Cell>
      <Cell width={150}>Last modified</Cell>
      <Cell width={170}>Created by</Cell>

      <Cell width={60}>Size</Cell>
      <Cell width={24} />
    </Row>
  );
};
