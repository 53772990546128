export enum DrawerEntity {
  CLIENT = 'client',
  REQUEST = 'request',
  PROJECT = 'project',
  WORK_ORDER = 'workOrder',
  /** For files stored inside forms - photos, etc */
  FILE = 'file',
  /** For docs and forms */
  DOC = 'doc',
  NOTIFICATION = 'notification',
  SYSTEM = 'system',
  ACTION_ITEM_TEMPLATE = 'actionItemTemplate',
  ACTION_ITEM = 'actionItem'
}

export type DrawerState = {
  type: DrawerEntity;
  currentId: number;
  listItemIds: number[];
  entityData?: any;
};
