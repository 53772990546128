import { BulkUpdateTaskDto } from '@services/api/types';

import { PaginationResult } from '@types'
import { Task } from '../../components/Calendar/types';
import { get, post, put, deleteRequest } from './base';
import { Urls } from './base/axios';
import { getHeaders, appendHeaderForCalendar } from '../service.utils';
import { CreateTaskCommentRequest, ListParams } from './types';
import { generateParams, generateQueryParams } from './helpers';

interface GetTasksQuery {
  startDate: Date | '';
  endDate: Date | '';
  project?: number;
  isAssignee?: number;
}

export default {
  getKanbanTasks: (query: GetTasksQuery, params: ListParams = {}) =>
    get<PaginationResult<Task>>(`${Urls.project}/tasks`, {
      ...query,
      ...generateQueryParams(params, undefined, true)
    }),
  updateTask: (task: Task) =>
    put(`${Urls.project}/projects/${task.projectId}/tasks/${task.id}`, task),
  createTask: (task: Task) =>
    post(`${Urls.project}/projects/${task.projectId}/tasks`, task),
  deleteTask: (task: Task) =>
    deleteRequest(`${Urls.project}/projects/${task.projectId ?? task?.project?.id}/tasks/${task.id}`),
  syncTaskHandler: (task: Task, sync: boolean) => {
    const calendarToken = appendHeaderForCalendar(getHeaders()).get(
      'calendarToken'
    );

    return put(
      `${Urls.project}/projects/${task.projectId}/tasks/${task.id}/${
        sync ? 'sync' : 'unsync'
      }`,
      {},
      { headers: { calendarToken } }
    );
  },
  getTaskComments: (taskId: number, projectId: number) =>
    get(`${Urls.project}/projects/${projectId}/tasks/${taskId}/comments`, {
      fetchAll: true,
      ...generateQueryParams({
        orders: [{ col: 'createdAt', desc: false }]
      })
    }),
  createTaskComment: (
    taskId: number,
    projectId: number,
    body: CreateTaskCommentRequest
  ) =>
    post(
      `${Urls.project}/projects/${projectId}/tasks/${taskId}/comments`,
      body
    ),
  updateTaskComment: (
    taskId: number,
    projectId: number,
    commentId: number,
    body: CreateTaskCommentRequest
  ) =>
    put(
      `${Urls.project}/projects/${projectId}/tasks/${taskId}/comments/${commentId}`,
      body
    ),
  removeTaskComment: (taskId: number, projectId: number, commentId: number) =>
    deleteRequest(
      `${Urls.project}/projects/${projectId}/tasks/${taskId}/comments/${commentId}`
    ),
  getTaskTimeTracks: (taskId: number, projectId: number) =>
    get(
      `${Urls.project}/projects/${projectId}/tasks/${taskId}/stage-time-tracks`
    ),
  bulkUpdate: (dto: BulkUpdateTaskDto, search: { companyId: number }) => (
    put(
      `${Urls.project}/tasks/bulk`,
      dto,
      generateParams({ search })
    )
  ),
};
