import { AssigneesField } from '@common/index';
import { useFormikContext } from 'formik';
import React from 'react';
import { User } from 'react-feather';
import { UserAvatar } from '@kit/components/UserAvatar';
import { AssigneesContainer, Placeholder, UserAvatarAndName } from './styled';
import { useInlineField } from '../useInlineField';

interface MemberProps {
  assignee: {
    id: number;
    firstName: string;
    lastName: string;
    avatarUrl: string;
  };
}

const Member = ({ assignee }: MemberProps) => {
  return (
    <UserAvatarAndName>
      <UserAvatar user={assignee} />
      <div>
        {assignee.firstName} {assignee.lastName}
      </div>
    </UserAvatarAndName>
  );
};

interface Props {
  isReadOnly?: boolean;
}

export const CollaboratorsField = ({ isReadOnly = false }: Props) => {
  const { isEdit, setIsEdit, setIsFocused, nodeRef } = useInlineField();

  const context = useFormikContext();

  if (isEdit) {
    return (
      <div ref={nodeRef}>
        <AssigneesField
          name="assignees"
          // projectID={project?.id}
          // companyId={companyId}
          allowTeams
          // allowUserProperties={isTemplate}
          membersOnly={false}
          excludeIds={context.values.assignee ? [context.values.assignee.id] : []}
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            setIsFocused(false);
          }}
        />
      </div>
    );
  }

  return (
    <AssigneesContainer isReadOnly={isReadOnly} onClick={isReadOnly ? undefined : () => setIsEdit(true)}>
      {context.values.assignees.map((member) => (
        <Member key={member.id} assignee={member} />
      ))}

      {context.values.assignees.length === 0 && (
        <Placeholder>
          <User size="16px" /> No collaborators
        </Placeholder>
      )}
    </AssigneesContainer>
  );
};
