import React, { useMemo } from 'react';
import { isStatusLifecycleWorkflow } from '@features/Analytics/helpers';
import { useWorkOrderStatuses } from '@hooks/workOrders/useWorkOrderStatuses';
import { AnalyticsModule, AnalyticsWidgetType } from '@features/Analytics/types';
import { CLIENT_STATUS_OPTIONS, PROJECT_STATUS_OPTIONS, REQUEST_STATUS_OPTIONS } from '@features/Analytics/constants';
import { useWorkflowList } from '@hooks/workflows/useWorkflowList';
import { TimelineByStatus } from './TimelineByStatus';
import { FunnelProps } from '../types';
import { TimelineByStage } from './TimelineByStage';
import { daysToSeconds, roundPercentValue } from './helpers';
import { PipelineByStatus } from './PipelineByStatus';
import { PipelineByStage } from './PipelineByStage';
import { FunnelByStage } from './FunnelByStage';
import { FunnelByStatus } from './FunnelByStatus';

export const Funnel = ({ module, widgetType, workflowId, pointsMap, onClick }: FunnelProps) => {
  const isStatus = isStatusLifecycleWorkflow(workflowId);

  if (isStatus) {
    return <FunnelWidgetByStatus module={module} widgetType={widgetType} pointsMap={pointsMap} onClick={onClick} />;
  }

  return (
    <FunnelWidgetByStage
      module={module}
      widgetType={widgetType}
      pointsMap={pointsMap}
      workflowId={workflowId}
      onClick={onClick}
    />
  );
};

const FunnelWidgetByStatus = ({ module, widgetType, pointsMap, onClick }: Omit<FunnelProps, 'workflowId'>) => {
  const { data: workOrderStatuses = [], isLoading } = useWorkOrderStatuses();

  const statusOptions = useMemo(() => {
    switch (module) {
      case AnalyticsModule.CLIENTS:
        return CLIENT_STATUS_OPTIONS;
      case AnalyticsModule.REQUESTS:
        return REQUEST_STATUS_OPTIONS;
      case AnalyticsModule.PROJECTS:
        return PROJECT_STATUS_OPTIONS;
      default:
        return workOrderStatuses.map((status) => ({ ...status, name: status.label }));
    }
  }, [module, workOrderStatuses]);

  const points = useMemo(() => {
    if (isLoading) {
      return [];
    }

    let accumSum = 0;

    let startValue: number;
    let prevValue: number;

    if (Object.keys(pointsMap).length === 0) {
      return [];
    }

    return statusOptions.map(({ id, name }, index) => {
      const rawValue = parseFloat(pointsMap[id] ?? 0);
      const hasValue = Boolean(pointsMap[id]);

      startValue = index === 0 ? rawValue : startValue;

      const percentFromPrev = hasValue ? roundPercentValue(prevValue ? (rawValue / prevValue) * 100 : 100) : null;
      const percentFromStart = hasValue ? roundPercentValue((rawValue / startValue) * 100) : null;

      if (hasValue) {
        prevValue = rawValue;
      }

      const value = rawValue;

      accumSum += value;

      return {
        id,
        name,
        value,
        accumSum,
        percentFromPrev,
        percentFromStart
      };
    });
  }, [pointsMap, statusOptions, isLoading]);

  switch (widgetType) {
    case AnalyticsWidgetType.TIMELINE:
      return <TimelineByStatus module={module} onClick={onClick} points={points} statuses={statusOptions} />;
    case AnalyticsWidgetType.PIPELINE:
      return <PipelineByStatus module={module} onClick={onClick} points={points} statuses={statusOptions} />;

    default:
      return <FunnelByStatus module={module} onClick={onClick} points={points} statuses={statusOptions} />;
  }
};

const FunnelWidgetByStage = ({ module, widgetType, pointsMap, workflowId, onClick }: FunnelProps) => {
  const { data: workflows = [], isLoading } = useWorkflowList();

  const points = useMemo(() => {
    if (!workflowId || isLoading) {
      return [];
    }

    if (Object.keys(pointsMap).length === 0) {
      return [];
    }

    const workflow = workflows.find((workflow) => workflow.id === workflowId);

    if (!workflow) {
      return [];
    }

    let accumSum = 0;

    let startValue: number;
    let prevValue: number;

    return [...workflow.blueprintProjectStages]
      .sort((a, b) => a.position - b.position)
      .map((workflowStage, index) => {
        const stage = workflowStage.projectStage;
        const rawValue = parseFloat(pointsMap[stage.id] ?? 0);

        const hasValue = Boolean(pointsMap[stage.id]);

        startValue = index === 0 ? rawValue : startValue;

        const percentFromPrev = hasValue ? roundPercentValue(prevValue ? (rawValue / prevValue) * 100 : 100) : null;
        const percentFromStart = hasValue ? roundPercentValue((rawValue / startValue) * 100) : null;

        if (hasValue) {
          prevValue = rawValue;
        }

        const value = rawValue;

        accumSum += value;

        let green = value;
        let yellow = stage.yellowSla ? 0 : null;
        let red = stage.redSla ? 0 : null;

        if (stage.redSla) {
          const redSlaInSeconds = daysToSeconds(stage.redSla);

          if (value > redSlaInSeconds) {
            red = value - redSlaInSeconds;
            green -= red;
          }
        }

        if (stage.yellowSla) {
          const yellowSlaInSeconds = daysToSeconds(stage.yellowSla);

          if (value > yellowSlaInSeconds) {
            yellow = value - yellowSlaInSeconds - red;
            green -= yellow;
          }
        }

        return {
          id: stage.id,
          name: stage.name,
          value,
          accumSum,
          green,
          yellow,
          red,
          stage,
          percentFromPrev,
          percentFromStart
        };
      });
  }, [pointsMap, workflows, isLoading, workflowId]);

  switch (widgetType) {
    case AnalyticsWidgetType.TIMELINE:
      return <TimelineByStage onClick={onClick} points={points} />;
    case AnalyticsWidgetType.PIPELINE:
      return <PipelineByStage module={module} onClick={onClick} points={points} />;
    default:
      return <FunnelByStage points={points} onClick={onClick} />;
  }
};
