import styled from 'styled-components';
import { WidgetIcon, WidgetLeft, WidgetTitle } from '../styled';
import { colors } from '@styles';

export const Legend = styled(WidgetLeft)`
  min-width: 185px;
`;

export const Title = styled(WidgetTitle)`
  font-size: 18px;
  font-weight: 500;
`;

export const PowerIcon = styled(WidgetIcon)`
  background-color: #F1AA12;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const SelectWithArrow = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  color: ${colors.green};
`;

export const PointTooltip = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  background-color: #FFFFFF;
  border: #F7F7FC solid 1px;
  border-radius: 8px;
  padding: 8px 12px 8px 12px;
`;

export const TooltipLabel = styled.div`
  color: #828D9A;
  font-size: 12px;
  font-weight: 400;
`;

export const TooltipValue = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
`;