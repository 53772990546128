import { Button } from '@kit/ui/Button';
import styled from 'styled-components';

export const Separator = styled.div`
  height: 1px;
  background-color: #dfdfe8;
  margin: 16px 0;
`;

export const FilterRowContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 24px 24px;
  column-gap: 24px;
  align-items: center;
`;

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const SubGroup = styled(Group)`
  padding: 16px;
  border: 1px solid #dfdfe8;
  border-radius: 4px;
`;

export const AddButton = styled(Button)`
  margin-top: 16px;
`;
