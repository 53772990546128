import React from 'react';
import { useFormikContext } from 'formik';
import styled from 'styled-components';

import { error } from '@styles';

export const FieldError = styled.span`
  ${error};
  position: absolute;
  right: 0;
`;

export const FieldErrorFormik: React.FC<{ name: string }> = ({ name }) => {
  const { getFieldMeta } = useFormikContext();
  const meta = getFieldMeta(name);

  return (meta.error && meta.touched)
    ? <FieldError>{meta.error}</FieldError>
    : null;
};
