import React, { useMemo } from 'react';
import { FileEventType } from '@enums';
import { File } from 'react-feather';
import { Tooltip } from '@material-ui/core';
import { FeedItemIconContainer } from './styled';
import { CommentItem } from './CommentItem';
import { BaseItemTemplate } from './BaseItemTemplate';
import { FeedItemProps } from './types';

export const FileName = ({ name }: { name: string }) => {
  if (name.length > 30) {
    return (
      <Tooltip title={name}>
        <b>
          {name.slice(0, 20)}...{name.slice(name.length - 4)}
        </b>
      </Tooltip>
    );
  }

  return <b>{name}</b>;
};

export const FileFeedItem = ({ item, context, contextEntityId }: FeedItemProps) => {
  const { title, children } = useMemo(() => {
    switch (item.eventType) {
      default:
        return {
          title: null,
          children: null
        };
    }
  }, [item]);

  if (item.eventType === FileEventType.COMMENTED) {
    return <CommentItem item={item} context={context} contextEntityId={contextEntityId} />;
  }

  if (!title && !children) {
    return null;
  }

  return (
    <BaseItemTemplate
      id={item.id}
      context={context}
      icon={
        <FeedItemIconContainer color="#DFDFE8">
          <File color="#737382" size="12px" />
        </FeedItemIconContainer>
      }
      author={item.createdBy}
      date={item.createdAt}
      title={title}
    >
      {children}
    </BaseItemTemplate>
  );
};
