import * as actionTypes from '../../actions/actionTypes/actionTypes';
import UserDataInterface from './types';

const initialState: UserDataInterface = {
  users: {},
  loading: false,
  error: null,
  companyUsers: [],
  pager: { page: 0, limit: 10, skip: 0, name: '' },
  loadingCompanyUsers: false,
  totalCompanyUsers: 0
};

export const updateUserDataReducer = (state, action) => {
  return { ...state, ...action.payload };
};

export const updateUsersCache = (state, action) => {
  return { ...state, users: { ...state.users, ...action.payload } };
};

const fetchCompanyUsersStart = (state, action) => {
  const { companyUsers: oldCompanyUsers } = state;
  const { pager } = action.payload;
  const newCompanyUsers = pager.page === 0 ? [] : oldCompanyUsers;
  return {
    ...state,
    pager,
    companyUsers: newCompanyUsers,
    loadingCompanyUsers: true
  };
};

const fetchCompanyUsersSuccess = (state, action) => {
  const { companyUsers: oldCompanyUsers, pager } = state;
  const { companyUsers, totalCompanyUsers } = action.payload;
  const newCompanyUsers = [...oldCompanyUsers, ...companyUsers];
  const newPager = {
    ...pager,
    page: pager.page + 1,
    skip: (pager.page + 1) * pager.limit
  };
  return {
    ...state,
    companyUsers: newCompanyUsers,
    pager: newPager,
    totalCompanyUsers,
    loadingCompanyUsers: false
  };
};

const fetchCompanyUsersFailed = (state, action) => {
  return { ...state, loadingCompanyUsers: false };
};

const userDataReducer = (state = initialState, action): UserDataInterface => {
  switch (action.type) {
    case actionTypes.USER_DATA_UPDATE:
      return updateUserDataReducer(state, action);
    case actionTypes.USER_DATA_UPDATE_CACHE:
      return updateUsersCache(state, action);
    case actionTypes.FETCH_COMPANY_USERS_START:
      return fetchCompanyUsersStart(state, action);
    case actionTypes.FETCH_COMPANY_USERS_SUCCESS:
      return fetchCompanyUsersSuccess(state, action);
    case actionTypes.FETCH_COMPANY_USERS_FAILED:
      return fetchCompanyUsersFailed(state, action);
    default:
      return state;
  }
};

export default userDataReducer;
