import { useMemo } from 'react';
import { AnalyticsModule, AnalyticsWidgetType } from '../types';
import { DIMENSION_TYPE_LABELS, DIMENSION_TYPE_OPTIONS_BY_MODULE_WIDGET } from '../constants';

export const useDimensionOptions = (module: AnalyticsModule, widget: AnalyticsWidgetType) => {
  return useMemo(
    () =>
      DIMENSION_TYPE_OPTIONS_BY_MODULE_WIDGET[module][widget].map((dimensionType) => ({
        id: dimensionType,
        name: DIMENSION_TYPE_LABELS[dimensionType]
      })),
    [module, widget]
  );
};
