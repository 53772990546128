import React, { useMemo } from 'react';
import { useAppSelector } from '@hooks/store';
import { selectCompanyTimezone } from '@state/selectors';
import { WidgetSettings } from '../types';
import { DataTable } from '../DataTable';
import { buildFilterForDrilldown } from './buildFilterForDrilldown';
import { usePropertiesMap } from '../hooks';
import { Container } from './styled';

interface Props {
  settings: WidgetSettings;
  segment: string | number | null;
}

export const Drilldown = ({ settings, segment }: Props) => {
  const { propertiesMap, isPropertiesLoading } = usePropertiesMap(settings.module);
  const timezone = useAppSelector(selectCompanyTimezone);
  const drilldownFilter = useMemo(
    () =>
      typeof segment !== 'undefined' ? buildFilterForDrilldown(settings, segment, propertiesMap, timezone) : undefined,
    [settings, segment, propertiesMap, timezone]
  );

  if (isPropertiesLoading) {
    return null;
  }

  return (
    <Container>
      <DataTable settings={settings} drilldownFilter={drilldownFilter} />
    </Container>
  );
};
