import React, { useMemo } from 'react';
import { useAppSelector } from '@hooks/store';
import { selectCompanyById, selectWorkspaceId } from '@state/selectors';
import { Warning } from '@common/Warning';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { Form, FormValidationRules, SelectField, useForm } from '@kit/components/Form';
import { ModalBody, ModalFooter } from '@common/PromiseModal';
import { useDebouncedState } from '@hooks/useDebouncedState';
import { useRecordsSearch } from '@hooks/useRecords';
import { RecordType } from '@types';
import {
  useWorkOrderTemplateList,
  WorkOrderTemplateListItem
} from '@hooks/templates/workOrders/useWorkOrderTemplateList';
import { WorkOrderTypeIcon } from '@common/WorkOrderTypeIcon';
import { useCreateWorkOrder } from '@hooks/workOrders/useCreateWorkOrder';
import { useClientSystemList } from '@hooks/systems/useClientSystemList';
import { RecordTypeIcon } from '@common/RecordTypeIcon';
import { RECORD_TYPE_TITLE_MAP } from '@features/Analytics/constants';
import { useClientSites } from '@features/SystemPortfolio/components/MatchToClient/useClientSites';
import { WarningWrapper, WarningText, TaskLink, DropdownItemWithIcon, FieldsGrid } from './styled';
import { useTaskCreatingChecks } from './useTaskCreatingChecks';

interface Props {
  onClose: () => void;
  onCreated: (newTaskId: number) => void;
  initialValues?: {
    project?: { id: number; title: string; type: RecordType; stageId: number | null; blueprintId: number | null };
    address: string;
    system?: { id: number; name: string; addressForced: string };
  };
}

interface FormValues {
  project: {
    id: number;
    title: string;
    type: RecordType;
    blueprintId: number | null;
    stage: { id: number; name: string } | null;
  };
  template: { id: number; title: string };
  system?: { id: number; name: string; addressForced: string };
  site: string;
}

const useRecordOptions = (search = '') => {
  const [, debouncedSearch, setSearch] = useDebouncedState(search, 300);

  const { data: records = [] } = useRecordsSearch(debouncedSearch, [RecordType.ACCOUNT, RecordType.PROJECT]);

  return {
    records,
    onSearch: setSearch
  };
};

export const CreateForm = ({ initialValues, onCreated, onClose }: Props) => {
  const { mutateAsync: create } = useCreateWorkOrder();

  const companyId = useAppSelector(selectWorkspaceId);
  const company = useAppSelector((state) => selectCompanyById(state, companyId));
  const isSystemsEnabled = !!company?.settings?.features?.fleet;

  const { records, onSearch } = useRecordOptions();

  const { data: templates = [] } = useWorkOrderTemplateList();

  const postForm = async ({ project, template, system, site }: FormValues) => {
    const newId = await create({
      projectId: project.id,
      templateTaskId: template.id,
      address: site ?? initialValues?.system?.addressForced,
      systemId: system?.id
    });

    onCreated(newId);
    onClose();
  };

  const { form, handleSubmit } = useForm<FormValues>({
    onSubmit: postForm,
    defaultValues: {
      project: initialValues?.project ?? null,
      template: null,
      system: initialValues?.system ?? null,
      site: initialValues?.address ?? null
    }
  });

  const {
    control,
    watch,
    formState: { isSubmitting }
  } = form;

  const rules = useMemo<FormValidationRules<FormValues>>(
    () => ({
      project: {
        isRequired: true
      },
      template: {
        isRequired: true
      },
      site: {
        isRequired: true
      }
    }),
    []
  );

  const [project, template, site] = watch(['project', 'template', 'site']);

  const { data: systems = [], isLoading: isSystemsLoading } = useClientSystemList(
    {
      filter: {
        projectId: {
          equalTo: project?.id
        },
        addressForced: {
          equalTo: site
        }
      }
    },
    { enabled: Boolean(isSystemsEnabled && site && !!project?.id && project.type === RecordType.ACCOUNT) }
  );

  const checks = useTaskCreatingChecks(project, template);

  const isClient = project?.type === RecordType.ACCOUNT;

  const showAdressField = !initialValues?.system && isClient;

  const clientSites = useClientSites(showAdressField ? project?.id : undefined);

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <ModalBody width="752px">
        <FieldsGrid>
          {!initialValues.project && (
            <SelectField
              name="project"
              control={control}
              options={records}
              label="Client or Project"
              filterOptions={(options) => options}
              getOptionLabel={(project) => project.title}
              groupBy={(record) => `${RECORD_TYPE_TITLE_MAP[record.type]}s`}
              placeholder="Search by number, name, email or phone"
              onInputChange={(_, value) => onSearch(value)}
              renderOption={(option) => (
                <DropdownItemWithIcon>
                  <RecordTypeIcon size="12px" color="#9C9CAA" recordType={option.type} />
                  {option.title}
                </DropdownItemWithIcon>
              )}
            />
          )}

          {showAdressField && (
            <SelectField
              name="site"
              control={control}
              options={clientSites}
              label="Site"
              placeholder="Select Site"
              getOptionLabel={(site) => site}
            />
          )}

          {!initialValues.system && isSystemsEnabled && project?.type === RecordType.ACCOUNT && (
            <SelectField
              name="system"
              control={control}
              options={systems}
              label="System"
              disabled={systems.length === 0}
              placeholder={
                !isSystemsLoading && systems.length === 0 ? 'No systems found for this Site' : 'Select System'
              }
              getOptionLabel={(system) => system.name}
            />
          )}

          <SelectField
            name="template"
            control={control}
            options={templates}
            label="Work Order Template"
            placeholder="Select Work Order Template"
            getOptionLabel={(template) => template.title}
            renderOption={(option: WorkOrderTemplateListItem) => (
              <DropdownItemWithIcon>
                <WorkOrderTypeIcon isField={option.isField} />
                {option.title}
              </DropdownItemWithIcon>
            )}
          />
        </FieldsGrid>

        {checks?.existingsTasks.length > 0 && (
          <WarningWrapper>
            <Warning title={`Looks like similar Work Order already exists in this ${isClient ? 'Client' : 'Project'}.`}>
              <WarningText>
                Click below to open it
                {checks.existingsTasks.map((task) => (
                  <TaskLink
                    key={task.id}
                    onClick={onClose}
                    to={`/${companyId}/${isClient ? 'clients' : 'projects'}/${project.id}/workOrders/${task.id}`}
                  >
                    {`#${task.uid} · `}
                    {task.title}
                  </TaskLink>
                ))}
              </WarningText>
            </Warning>
          </WarningWrapper>
        )}

        {checks?.taskWillBeCreatedInTheFuture && checks.plannedStageForTemplate && (
          <WarningWrapper>
            <Warning
              title={`Looks like this Work Order will be created on the next stage (${checks.plannedStageForTemplate.name}).`}
            >
              <WarningText>
                Do you want to create it now on the current Project Stage ({project.stage.name})?
              </WarningText>
            </Warning>
          </WarningWrapper>
        )}

        {checks?.notBelongsToWorkflow && (
          <WarningWrapper>
            <Warning title="Looks like this Work Order doesn’t exist in the Project Workflow.">
              <WarningText>
                Do you want to add this Work Order to the current Project Stage ({project.stage.name})?
              </WarningText>
            </Warning>
          </WarningWrapper>
        )}
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose} variant={ButtonVariant.Secondary}>
          Cancel
        </Button>
        <Button type="submit" disabled={isSubmitting} variant={ButtonVariant.Primary}>
          Create
        </Button>
      </ModalFooter>
    </Form>
  );
};
