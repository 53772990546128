import React, { useMemo } from 'react';
import { RecordType } from '@types';
import { Link } from '@reach/router';
import { ArrowDownLeft, ArrowUpRight, Edit2, PhoneIncoming, PhoneMissed, PhoneOutgoing } from 'react-feather';
import { Call, CallOutcome } from '@generated/types/graphql';
import { capitalize } from 'lodash';
import { ContextMenu, MenuItem } from '@kit/components/ContextMenu';
import { TrashIcon } from '@kit/ui/icons/Trash';
import { UserAvatar } from '@kit/components/UserAvatar';
import { useConfirmDeleteModal, useModal } from '@common/PromiseModal';
import { useDeleteCall } from '@hooks/calls/useDeleteCall';
import { ENTITY_NAME_BY_RECORD_TYPE } from './constants';
import { FeedItem } from './types';
import {
  FeedItemIconContainer,
  CallMetaData,
  SecondaryText,
  CommentFeedItem,
  BorderContainer,
  CommentContent,
  CommentHeader,
  CommentHeaderPart,
  CommentHeaderTitle,
  CreatedAt
} from './styled';
import { getHumanFriendlyTimeAgo } from './BaseItemTemplate';
import { EditCallForm } from './EditCallForm';

interface Props {
  item: FeedItem;
  context: 'project' | 'client' | 'workOrder' | 'file';
  contextEntityId?: number;
}

const getIconForCall = (call?: Call) => {
  if (!call) {
    return null;
  }

  if (call.outcome === CallOutcome.Missed) {
    return (
      <FeedItemIconContainer color="#F7DADD">
        <PhoneMissed color="#D54855" size="16px" />
      </FeedItemIconContainer>
    );
  }

  if (!call.isInbound) {
    return (
      <FeedItemIconContainer color="#CDF3DF">
        <PhoneOutgoing color="#009688" size="16px" />
      </FeedItemIconContainer>
    );
  }

  return (
    <FeedItemIconContainer color="#CDF3DF">
      <PhoneIncoming color="#009688" size="16px" />
    </FeedItemIconContainer>
  );
};

const svgStyle = {
  verticalAlign: 'bottom'
};

const formatEnumValue = (value: string) => (value ? capitalize(value).replace(/_/g, ' ') : '');

const formatDuration = (durationInMs: number) => {
  const minutes = Math.floor(durationInMs / 60000);
  const seconds = Math.round((durationInMs % 60000) / 1000);

  if (minutes === 0) {
    return `${seconds} sec${seconds === '1' ? '' : 's'}`;
  }

  return `${minutes} min${minutes === 1 ? '' : 's'} ${seconds > 0 ? `${seconds} sec${seconds === 1 ? '' : 's'}` : ''}`;
};

export const CallFeedItem = ({ item, context, contextEntityId }: Props) => {
  const { call, createdBy: author = { name: '' } } = item;

  const { openModal } = useModal();
  const confirmDelete = useConfirmDeleteModal();

  const { mutateAsync: deleteCall } = useDeleteCall();

  const icon = getIconForCall(call);

  const userName = author.name || [author.firstName, author.lastName].join(' ');

  const showProjectLink = context === 'client' && contextEntityId !== item.payload.project?.id;

  const menuItems = useMemo<MenuItem[]>(() => {
    return [
      {
        icon: <Edit2 size="16px" color="#9C9CAA" />,
        title: 'Edit',
        onClick: () => {
          openModal<void>(({ onClose }) => <EditCallForm feedItem={item} call={call} onClose={onClose} />, {
            title: `Edit Call`
          });
        }
      },
      {
        icon: <TrashIcon size="16px" color="#D54855" />,
        title: 'Delete',
        onClick: async () => {
          if (await confirmDelete(`Are you sure you want to delete this call?`)) {
            await deleteCall(call.id);
          }
        }
      }
    ];
  }, [confirmDelete, deleteCall, call, item, openModal]);

  if (!call) {
    return null;
  }

  return (
    <CommentFeedItem>
      <BorderContainer>
        {icon}
        <CommentContent>
          <CommentHeader>
            <CommentHeaderPart verticalAlign="flex-start">
              {author && <UserAvatar user={author} />}
              <CommentHeaderTitle>
                {!call.isInbound && (
                  <>
                    <b>{userName}</b> made an outbound call{' '}
                    <ArrowUpRight
                      style={svgStyle}
                      size="16px"
                      color={call.outcome === CallOutcome.Missed ? '#D54855' : '#009688'}
                    />
                  </>
                )}
                {call.isInbound && call.outcome === CallOutcome.Answered && (
                  <>
                    <b>{userName}</b> answered an inbound call{' '}
                    <ArrowDownLeft style={svgStyle} size="16px" color="#009688" />
                  </>
                )}
                {call.isInbound && call.outcome === CallOutcome.Missed && (
                  <>
                    <b>{userName}</b> missed an inbound call{' '}
                    <ArrowDownLeft style={svgStyle} size="16px" color="#D54855" />
                  </>
                )}

                {showProjectLink && (
                  <>
                    {` under ${ENTITY_NAME_BY_RECORD_TYPE[item.payload.project?.type ?? RecordType.PROJECT]} `}
                    <Link
                      to={`${window.location.pathname}/projects/${item.payload.project?.id}${window.location.search}`}
                    >
                      {item.payload.project?.title}
                    </Link>
                  </>
                )}
              </CommentHeaderTitle>
            </CommentHeaderPart>

            <CommentHeaderPart verticalAlign="center">
              <CreatedAt>{getHumanFriendlyTimeAgo(item.createdAt)}</CreatedAt>
              <ContextMenu items={menuItems} />
            </CommentHeaderPart>
          </CommentHeader>

          <div>
            <CallMetaData>
              <SecondaryText>From:</SecondaryText>
              <div>
                {call.fromContact ? `${call.fromContact.name}, ` : ''}
                <a href={`tel:${call.fromNumber}`}>{call.fromNumber}</a>
              </div>
              <SecondaryText>To:</SecondaryText>
              <div>
                {call.toContact ? `${call.toContact.name}, ` : ''}
                <a href={`tel:${call.toNumber}`}>{call.toNumber}</a>
              </div>
              <SecondaryText>Duration:</SecondaryText>
              <div>{call.durationInMs ? formatDuration(call.durationInMs) : '-'}</div>
              <SecondaryText>Reason:</SecondaryText>
              <div>{formatEnumValue(call.reason) || '-'}</div>
              <SecondaryText>Disposition:</SecondaryText>
              <div>{formatEnumValue(call.disposition) || '-'}</div>
              <SecondaryText>Note:</SecondaryText>
              {call.note ? <div dangerouslySetInnerHTML={{ __html: call.note }} /> : <div>-</div>}
            </CallMetaData>
          </div>
        </CommentContent>
      </BorderContainer>
    </CommentFeedItem>
  );
};
