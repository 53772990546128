import styled from 'styled-components';

export const AnchorContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  min-width: 0; // for ellipsis in children

  color: #235dff;

  &:link,
  &:visited,
  &:hover,
  &:active,
  &:focus {
    color: #235dff;
  }

  svg {
    flex-shrink: 0;
  }
`;

export const Anchor = styled.a`
  overflow: hidden;
  min-width: 0;
  text-overflow: ellipsis;
  white-space: nowrap;

  color: #235dff;
  cursor: pointer;
  text-decoration: none;
`;
