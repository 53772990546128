import { ReactQueryKey } from '@enums';
import { errorHandler } from '@services/api/helpers';
import { useMutation, useQueryClient } from 'react-query';
import { useToast } from '@hooks/useToast';
import { selectWorkspaceId } from '@state/selectors';
import filterApi from '@services/api/smartViewFilterApi';
import { useAppSelector } from '..';

export const useUpdateSmartViewFolder = () => {
  const queryClient = useQueryClient();
  const { showSuccess } = useToast();

  const companyId = useAppSelector(selectWorkspaceId);

  return useMutation<void, Error, { id: number; name: string }>(
    async ({ id, name }) => {
      try {
        await filterApi.updateFolder({ id, name, companyId });
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: () => {
        showSuccess('Hub updated successfully');
        queryClient.invalidateQueries(ReactQueryKey.SmartViewFolderList);
      }
    }
  );
};
