import { ReactQueryKey } from '@enums';
import { Invoice } from '@generated/types/graphql';
import { useToast } from '@hooks/useToast';
import { errorHandler } from '@services/api/helpers';
import projectApi from '@services/api/projectApi';
import { useMutation, useQueryClient } from 'react-query';

export const useDeleteInvoice = () => {
  const { showError, showSuccess } = useToast();
  const queryClient = useQueryClient();

  return useMutation<void, Error, { id: number }>(
    async ({ id }) => {
      try {
        await projectApi.deleteInvoice(id);

        return { id };
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: async (_, { id }) => {
        showSuccess('Successfully deleted');
        queryClient.setQueriesData<Invoice[] | undefined>([ReactQueryKey.InvoiceList], (old) => {
          if (!old) {
            return old;
          }

          return old.filter((invoice) => invoice.id !== id);
        });
      }
    }
  );
};
