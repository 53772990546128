import { dateRangeConfig } from '@features/Analytics/dateRangeConfig';
import { useDimensionOptions, useSubdimensionOptions, useSubmeasureOptions } from '@features/Analytics/hooks';
import { AnalyticsWidgetDateRangeType, WidgetSettings } from '@features/Analytics/types';
import { DateTime } from 'luxon';
import { useMemo } from 'react';

export const useChips = (settings: WidgetSettings) => {
  const submeasureChip = useSubmeasureChip(settings);
  const dimensionChip = useDimensionChip(settings);

  return useMemo(() => {
    const result: string[] = [];

    const { dateRangeType, dateRangeStart, dateRangeEnd } = settings;

    const dateRangeChip = getDateRangeChip(dateRangeType, dateRangeStart, dateRangeEnd);

    if (dateRangeChip) {
      result.push(dateRangeChip);
    }

    if (submeasureChip) {
      result.push(submeasureChip);
    }

    if (dimensionChip) {
      result.push(dimensionChip);
    }

    return result;
  }, [settings, submeasureChip, dimensionChip]);
};

const getDateRangeChip = (
  dateRangeType: AnalyticsWidgetDateRangeType,
  dateRangeStart: string,
  dateRangeEnd: string
) => {
  const dateRangeOption = dateRangeConfig[dateRangeType];

  if (!dateRangeOption) {
    return '';
  }

  if (dateRangeType === AnalyticsWidgetDateRangeType.CUSTOM) {
    if (!dateRangeStart || !dateRangeEnd) {
      return '';
    }

    const startDate = DateTime.fromISO(dateRangeStart);
    const endDate = DateTime.fromISO(dateRangeEnd);

    return `${startDate.toLocaleString(DateTime.DATE_SHORT)} - ${endDate.toLocaleString(DateTime.DATE_SHORT)}`;
  }

  if (dateRangeType === AnalyticsWidgetDateRangeType.CUSTOM_TO_DATE) {
    if (!dateRangeStart) {
      return '';
    }

    const startDate = DateTime.fromISO(dateRangeStart);

    return `${startDate.toLocaleString(DateTime.DATE_SHORT)}-to-date`;
  }

  return dateRangeOption.name;
};

const useSubmeasureChip = (settings: WidgetSettings) => {
  const options = useSubmeasureOptions(settings.module);

  return useMemo(() => {
    if (!settings.submeasureId) {
      return '';
    }

    const selectedOption = options.find((option) => option.id === settings.submeasureId);

    if (!selectedOption) {
      return '';
    }

    if ('label' in selectedOption) {
      return selectedOption.label;
    }

    return selectedOption.name;
  }, [options, settings]);
};

const useDimensionChip = (settings: WidgetSettings) => {
  const dimensionOptions = useDimensionOptions(settings.module, settings.widgetType);
  const subdimensionOptions = useSubdimensionOptions(settings.module, settings.dimensionId);

  return useMemo(() => {
    if (dimensionOptions.length === 0 || !settings.dimensionId) {
      return '';
    }

    if (!settings.subdimensionId) {
      return '';
    }

    const selectedDimension = dimensionOptions.find((option) => option.id === settings.dimensionId);
    const selectedSubdimension = subdimensionOptions.find((option) => option.id === settings.subdimensionId);

    if (!selectedDimension || !selectedSubdimension) {
      return '';
    }

    return `${selectedDimension.name}, ${selectedSubdimension.name}`;
  }, [dimensionOptions, settings, subdimensionOptions]);
};
