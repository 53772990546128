import * as actionTypes from '../../actions/actionTypes/actionTypes';
import { UserReducerTypes } from './types';

const initialState: UserReducerTypes = {
  newUser: {
    firstName: '',
    lastName: '',
    email: ''
  },
  newTeam: {
    teamName: '',
    description: '',
    imageUrl: '',
    workers: []
  },
  selectedUsers: [],
  pushedUsers: [],
  loadingSendInvitation: false
};

const fetchUsersStart = (state, action) => {
  return { ...state };
};

const fetchUsersSuccess = (state, action) => {
  return { ...state, ...action.payload };
};

const setSelectedUser = (state, action) => {
  return {
    ...state,
    selectedUsers: state.selectedUsers.concat(action.payload.selectedUsers)
  };
};

const setPushedUsers = (state, action) => {
  return {
    ...state,
    pushedUsers: state.pushedUsers.concat(action.payload.pushedUsers)
  };
};

const clearSelectedMembers = (state, action) => {
  return {
    ...state,
    selectedUsers: []
  };
};

const removeFromPushed = (state, action) => {
  return {
    ...state,
    pushedUsers: state.pushedUsers.filter(function (item) {
      return item.id !== action.payload.id;
    })
  };
};

const removeFromSelected = (state, action) => {
  return {
    ...state,
    selectedUsers: state.selectedUsers.filter(function (item) {
      return item.id !== action.payload.id;
    })
  };
};

const addUser = (state, action) => {
  return { ...state, ...action.payload };
};


export const sendNewUserInvitationStart = (state, action) => {
  return { ...state, loadingSendInvitation: true };
};

export const sendNewUserInvitationEnd = (state, action) => {
  return { ...state, loadingSendInvitation: false };
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USERS_START:
      return fetchUsersStart(state, action);
    case actionTypes.FETCH_USERS_FAIL:
      return state;
    case actionTypes.FETCH_USERS_SUCCESS:
      return fetchUsersSuccess(state, action);
    case actionTypes.ADD_USER:
      return addUser(state, action);
    case actionTypes.SET_SELECTED_USER_NEW_TEAM:
      return setSelectedUser(state, action);
    case actionTypes.SET_PUSHED_USER_NEW_TEAM:
      return setPushedUsers(state, action);
    case actionTypes.CLEAR_SELECTED_MEMBERS_NEW_TEAM:
      return clearSelectedMembers(state, action);
    case actionTypes.REMOVE_FROM_PUSHED_NEW_TEAM:
      return removeFromPushed(state, action);
    case actionTypes.REMOVE_FROM_SELECTED_NEW_TEAM:
      return removeFromSelected(state, action);
    case actionTypes.SEND_NEW_USER_INVITATION_START:
        return sendNewUserInvitationStart(state, action);
    case actionTypes.SEND_NEW_USER_INVITATION_END:
        return sendNewUserInvitationEnd(state, action);
    default:
      return state;
  }
};

export default userReducer;
