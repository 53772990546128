import { MainUser, Team, Role } from '@generated/types/graphql';
import { RoleDTO } from '@types';

export enum AccessEntityType {
  USER = 'USER',
  TEAM = 'TEAM',
  ROLE = 'ROLE'
}

interface AccessEntityOption<TValue> {
  isNew?: boolean;
  uniqueId: string;
  value: TValue | null;
  type: AccessEntityType;
}

type UserValue = Pick<MainUser, 'id' | 'firstName' | 'lastName' | 'avatarUrl'> & {
  role?: Pick<Role, 'id' | 'name' | 'settings'>;
};

export interface UserAccessEntityOption extends AccessEntityOption<UserValue> {
  type: AccessEntityType.USER;
}

export interface TeamAccessEntityOption extends AccessEntityOption<Team> {
  type: AccessEntityType.TEAM;
}

export interface RoleAccessEntityOption extends AccessEntityOption<RoleDTO> {
  type: AccessEntityType.ROLE;
}

export type AccessOption = UserAccessEntityOption | TeamAccessEntityOption | RoleAccessEntityOption;

export type TAccessList = AccessOption[];

export type CheckAccessFn = (role: Pick<Role, 'settings'>) => boolean;
