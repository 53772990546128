import React, { useCallback, useMemo, useRef, useState } from 'react';
import { TabItem, Tabs } from '@kit/ui/Tabs';
import { ChevronRightIcon } from '@kit/ui/icons/ChevronRight';
import { ChevronLeftIcon } from '@kit/ui/icons/ChevronLeft';
import { useResizeObserver } from '@react-hookz/web';
import { TabContent, TabsContainer, TabsScrollButton, TabsWithButtonsContainer } from './styled';
import { Site, SiteTab } from './types';
import { RequestList } from './RequestList';
import { ProjectList } from './ProjectList';
import { Service } from './Service';
import { SystemList } from './SystemList';

interface Props {
  site: Site;
}

export const SiteTabs = ({ site }: Props) => {
  const [selectedTab, setSelectedTab] = useState<TabItem['id']>('requests');

  const tabs = useMemo<TabItem[]>(() => {
    return [
      {
        id: SiteTab.Requests,
        title: `Requests (${site.requests.length})`
      },
      {
        id: SiteTab.Projects,
        title: `Projects (${site.projects.length})`
      },
      {
        id: SiteTab.Systems,
        title: `Systems (${site.systems.length})`
      },
      {
        id: SiteTab.Service,
        title: `Service (${site.workOrders.length})`
      }
    ];
  }, [site]);

  const tabsContainerNode = useRef<HTMLDivElement>(null);
  const [isTabScrollButtonVisible, setIsTabScrollButtonVisible] = useState(false);
  const [tabsScrollPosition, setTabsScrollPosition] = useState(0);

  const handleTabsScrollEvent = useCallback((event: React.UIEvent<HTMLDivElement>) => {
    const { scrollLeft } = event.currentTarget;
    setTabsScrollPosition(scrollLeft);
  }, []);

  const isScrolledToStart = tabsScrollPosition === 0;

  const isScrolledToEnd = useMemo(() => {
    if (!tabsContainerNode.current) {
      return false;
    }

    const { scrollWidth, clientWidth, scrollLeft } = tabsContainerNode.current;

    return Math.ceil(scrollLeft + clientWidth) >= scrollWidth;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabsScrollPosition]);

  useResizeObserver(tabsContainerNode, () => {
    if (!tabsContainerNode.current) {
      return;
    }

    const { scrollWidth, clientWidth } = tabsContainerNode.current;

    setIsTabScrollButtonVisible(scrollWidth > clientWidth);
  });

  const handleTabChange = useCallback((tab: TabItem) => {
    setSelectedTab(tab.id);
  }, []);

  const content = useMemo(() => {
    switch (selectedTab) {
      case SiteTab.Requests:
        return <RequestList requests={site.requests} />;
      case SiteTab.Estimates:
        return null;
      case SiteTab.Projects:
        return <ProjectList projects={site.projects} />;
      case SiteTab.Systems:
        return <SystemList systems={site.systems} />;
      case SiteTab.Service:
        return <Service workOrders={site.workOrders} />;
      default:
        return null;
    }
  }, [selectedTab, site]);

  const handleScrollRight = useCallback(() => {
    if (!tabsContainerNode.current) {
      return;
    }

    const { scrollWidth, clientWidth } = tabsContainerNode.current;

    tabsContainerNode.current.scrollLeft = Math.min(tabsContainerNode.current.scrollLeft + clientWidth, scrollWidth);
  }, []);

  const handleScrollLeft = useCallback(() => {
    if (!tabsContainerNode.current) {
      return;
    }

    const { clientWidth } = tabsContainerNode.current;

    tabsContainerNode.current.scrollLeft = Math.max(tabsContainerNode.current.scrollLeft - clientWidth, 0);
  }, []);

  return (
    <>
      <TabsWithButtonsContainer>
        {isTabScrollButtonVisible && (
          <TabsScrollButton onClick={handleScrollLeft} isVisible={!isScrolledToStart} direction="left">
            <ChevronLeftIcon size="16px" />
          </TabsScrollButton>
        )}
        <TabsContainer onScroll={handleTabsScrollEvent} ref={tabsContainerNode}>
          <Tabs variant="outline" tabs={tabs} selected={selectedTab} onChange={handleTabChange} />
        </TabsContainer>
        {isTabScrollButtonVisible && (
          <TabsScrollButton onClick={handleScrollRight} isVisible={!isScrolledToEnd} direction="right">
            <ChevronRightIcon size="16px" />
          </TabsScrollButton>
        )}
      </TabsWithButtonsContainer>
      <TabContent>{content}</TabContent>
    </>
  );
};
