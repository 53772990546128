import React from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { Checkbox } from '@kit/ui/Checkbox';
import { FormControlLabel, Switcher } from '@common/ui';
import { SwitcherVariant } from '@common/ui/Switcher/type';
import { FormField } from '../FormField';
import { FormControl, FormInputPropsToOmit } from '../types';
import { useControllerWithValidation } from '../useControllerWithValidation';

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends Omit<React.ComponentProps<typeof Checkbox>, FormInputPropsToOmit | 'isChecked'>,
    FormControl<TFieldValues, TName> {
  label: string;
  variant?: 'default' | 'switch';
}

export const CheckboxField = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  label,
  description,
  name,
  control,
  variant = 'default',
  clearOnUnmount,
  ...inputProps
}: Props<TFieldValues, TName>) => {
  const {
    field: { value, ...controlProps },
    fieldState: { error }
  } = useControllerWithValidation(name, control, label, clearOnUnmount);

  return (
    <FormField error={error?.message} description={description}>
      {variant === 'switch' && (
        <FormControlLabel
          label={label}
          labelPlacement="start"
          control={
            <Switcher
              variant={SwitcherVariant.DEFAULT}
              checked={!!value}
              onClick={(event) => {
                controlProps.onChange(event.target.checked);
              }}
            />
          }
        />
      )}
      {variant === 'default' && (
        <Checkbox
          label={label}
          isChecked={value}
          {...controlProps}
          {...inputProps}
          data-test-id={`field-${controlProps.name}`}
        />
      )}
    </FormField>
  );
};
