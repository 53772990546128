import React from 'react';
import styled from 'styled-components';
import Props from './type';

import { colors } from '../../../styles';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
`;

const Title = styled.span`
  margin: 10px;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.25;
  color: ${colors.black};
  text-align: center;
`;

const Subtitle = styled.span`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  color: ${colors.gray};

  strong {
    color: ${colors.green};
    padding: 0 5px;
  }
`;

const StateMessege: React.FC<Props> = (props) => {
  const { title, subTitle, className } = props;

  return (
    <Wrapper className={className}>
      <Title>{title}</Title>
      <Subtitle>{subTitle}</Subtitle>
    </Wrapper>
  );
};

export default StateMessege;
