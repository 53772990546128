import post from '@services/api/base/post';
import deleteReq from '@services/api/base/delete';
import { Urls } from '@services/api/base/axios';
import { CatalogItem, CatalogItemCreateDto, CatalogItemUpdateDto, CatalogItemFromMasterDto } from '@types';
import { patch } from '@services/api/base';

const BASE_URL = `${Urls.project}/catalog-items`;

export default {
  create: (companyId: number, dto: CatalogItemCreateDto) => post<CatalogItem>(BASE_URL, dto, { companyId }),

  createFromMaster: (companyId: number, dto: CatalogItemFromMasterDto) =>
    post<CatalogItem>(`${BASE_URL}/from-master`, dto, { companyId }),

  update: (companyId: number, id: number, dto: CatalogItemUpdateDto) => patch(`${BASE_URL}/${id}`, dto, { companyId }),
};
