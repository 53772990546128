import { PrivilegedTask } from '@generated/types/graphql';
import React, { useCallback } from 'react';
import { WorkOrderTypeIcon } from '@common/WorkOrderTypeIcon';
import moment from 'moment';
import { parseUtcDate } from '@utils/dates';
import { WorkOrderStatus } from '@components/WorkOrder/WorkOrderStatus';
import { ArrowRight, Calendar, User } from 'react-feather';
import { DrawerEntity, useDrawersContext } from '@contexts/DrawersContext';
import { UserAvatar } from '@kit/components/UserAvatar';
import { ActionItemListItem } from '@hooks/actionItems/useActionItemList';
import { ActionItemStatus } from '@kit/components/ActionItemStatus';
import { FileUpIcon } from '@kit/ui/icons/FileUp';
import { ContactAvatar } from '@kit/components/ContactAvatar';
import {
  Meta,
  Row,
  StatusWrapper,
  Title,
  ListHeading,
  ListBody,
  SeeMore,
  Assignee,
  Placeholder,
  AvatarPlaceholder
} from './styled';

interface Props {
  totalCount: number;
  workOrders: PrivilegedTask[];
  actionItems?: ActionItemListItem[];
  title: React.ReactNode;
  onExpandDetails: () => void;
}

const formatDate = (date: string, isAllDay: boolean) => {
  const format = isAllDay ? 'MM/DD/YY' : 'MM/DD/YY h:mma';

  return moment(parseUtcDate(date)).format(format).replace(':00', '');
};

export const List = ({ totalCount, workOrders, actionItems = [], title, onExpandDetails }: Props) => {
  const { openDrawer } = useDrawersContext();

  const handleSeeMore = useCallback(() => {
    onExpandDetails();
  }, [onExpandDetails]);

  return (
    <div>
      <ListHeading>{title}</ListHeading>
      <ListBody>
        {actionItems.map((actionItem) => (
          <Row
            key={`ai-${actionItem.id}`}
            onClick={() =>
              openDrawer(
                DrawerEntity.ACTION_ITEM,
                actionItem.id,
                actionItems.map((item) => item.id)
              )
            }
          >
            <Assignee>
              {actionItem.actionAssignees[0]?.contact && (
                <ContactAvatar contact={actionItem.actionAssignees[0].contact} />
              )}
            </Assignee>
            <FileUpIcon size="16px" color="#9C9CAA" />

            <Title>{actionItem.title}</Title>

            <Meta>
              <Calendar size="12px" color="#9C9CAA" />
              {actionItem.dueDate ? formatDate(actionItem.dueDate, true) : 'No due date'}
            </Meta>
            <StatusWrapper>
              <ActionItemStatus status={actionItem.status} isArchived={actionItem.isArchived} />
            </StatusWrapper>
          </Row>
        ))}
        {workOrders.map((task) => (
          <Row
            key={`wo-${task.id}`}
            onClick={() =>
              openDrawer(
                DrawerEntity.WORK_ORDER,
                task.id,
                workOrders.map((workOrder) => workOrder.id)
              )
            }
          >
            <Assignee>
              {task.assignee && <UserAvatar user={task.assignee} />}

              {!task.assignee && (
                <AvatarPlaceholder>
                  <User size="16px" />
                </AvatarPlaceholder>
              )}
            </Assignee>
            <WorkOrderTypeIcon size="16px" isField={task.isField} />

            <Title>
              #{task.uid}
              {' · '}
              {task.title}
            </Title>

            <Meta>
              {task.isField && task.taskVisitsByTaskIdConnection?.nodes?.length > 0 && (
                <>
                  <Calendar size="12px" color="#9C9CAA" />
                  {formatDate(
                    task.taskVisitsByTaskIdConnection.nodes[0].startDate,
                    task.taskVisitsByTaskIdConnection.nodes[0].startDateAllDay
                  )}
                </>
              )}

              {!task.isField && task.endDate && (
                <>
                  <Calendar size="12px" color="#9C9CAA" />
                  {formatDate(task.endDate, task.endDateAllDay)}
                </>
              )}
            </Meta>
            <StatusWrapper>
              <WorkOrderStatus status={task.taskStatus} isArchived={task.isArchived} />
            </StatusWrapper>
          </Row>
        ))}

        {totalCount > 10 && (
          <SeeMore onClick={handleSeeMore}>
            See more ({totalCount - 10}) <ArrowRight size="16px" color="#4D7CFE" />
          </SeeMore>
        )}

        {totalCount === 0 && <Placeholder>No work yet</Placeholder>}
      </ListBody>
    </div>
  );
};
