import { handleActions } from 'redux-actions';

import { ProjectDetail } from '../../ProjectView/types';
import { ProjectViewReducer, SelectedProjectProp } from './types';
import * as actions from '../actions';

import { projectViewInitialState } from './defaultValues';

// archive project
const archiveProjectSuccessReducer = (state: ProjectViewReducer): ProjectViewReducer => ({
  ...state,
  isLoading: false
});

const archiveProjectRequestReducer = (state: ProjectViewReducer): ProjectViewReducer => ({
  ...state,
  isLoading: true
});

const archiveProjectFailureReducer = (state: ProjectViewReducer): ProjectViewReducer => ({
  ...state,
  isLoading: false
});

const deleteProjectSuccessReducer = (state: ProjectViewReducer): ProjectViewReducer => {
  return {
    ...state,
    isLoading: false
  };
};

const deleteProjectRequestReducer = (state: ProjectViewReducer): ProjectViewReducer => ({
  ...state,
  isLoading: true
});

const deleteProjectFailureReducer = (state: ProjectViewReducer): ProjectViewReducer => ({
  ...state,
  isLoading: false
});

type UpdateProjectSuccess = { payload: ProjectDetail };
const updateSelectedProjectSuccessReducer = (
  state: ProjectViewReducer,
  { payload }: UpdateProjectSuccess
): ProjectViewReducer => {
  return {
    ...state,
    selectedProject: payload
  };
};

type DeleteSelectedProjectSuccess = { payload: { projectId: number } };
const deleteSelectedProjectSuccessReducer = (
  state: ProjectViewReducer,
  { payload }: DeleteSelectedProjectSuccess
): ProjectViewReducer => {
  const { groups: currentGroups } = state;
  const { projectId } = payload;
  // remove project from group
  const groups = currentGroups.map((group) => {
    const groupCopy = { ...group };
    const newList = group.group?.list.filter((project) => project.projectId !== projectId) ?? [];
    groupCopy.group.list = newList;
    groupCopy.total = newList.length ?? 0;

    return groupCopy;
  });

  return {
    ...state,
    groups
  };
};

// fetch single project
const fetchSingleProjectRequestReducer = (state: ProjectViewReducer): ProjectViewReducer => ({
  ...state,
  fetchingOne: true
});

type FetchSingleProjectResponse = { payload: SelectedProjectProp };
const fetchSingleProjectSuccessReducer = (
  state: ProjectViewReducer,
  { payload }: FetchSingleProjectResponse
): ProjectViewReducer => {
  return {
    ...state,
    selectedProject: payload.project as unknown as SelectedProjectProp,
    fetchingOne: false
  };
};

const fetchSingleProjectFailureReducer = (state: ProjectViewReducer): ProjectViewReducer => ({
  ...state,
  fetchingOne: false,
  selectedProject: {}
});

const projectsViewReducer = handleActions(
  {
    [actions.archiveProjectSuccess]: archiveProjectSuccessReducer,
    [actions.archiveProjectRequest]: archiveProjectRequestReducer,
    [actions.archiveProjectFailure]: archiveProjectFailureReducer,
    [actions.deleteProjectSuccess]: deleteProjectSuccessReducer,
    [actions.deleteProjectRequest]: deleteProjectRequestReducer,
    [actions.deleteProjectFailure]: deleteProjectFailureReducer,
    [actions.fetchSingleProjectRequest]: fetchSingleProjectRequestReducer,
    [actions.fetchSingleProjectSuccess]: fetchSingleProjectSuccessReducer,
    [actions.fetchSingleProjectFailure]: fetchSingleProjectFailureReducer,
    [actions.updateSelectedProjectSuccess]: updateSelectedProjectSuccessReducer,
    [actions.deleteSelectedProjectSuccess]: deleteSelectedProjectSuccessReducer
  },
  projectViewInitialState
);

export default projectsViewReducer;
