import React from 'react';
import { AlertTriangle } from 'react-feather';
import styled from 'styled-components';
import { modal, colors } from '../../../../styles';
import { Button, ButtonVariant } from '@kit/ui/Button';

const Wrapper = styled.div`
  ${modal.wrapper};
  && {
    max-width: 600px;
  }

  /* Temp styles, remove after refactor to Mui modal */
  z-index: 140;
`;

const Header = styled.div`
  ${modal.header};
`;

const Title = styled.div`
  ${modal.title};
`;

const Body = styled.div`
  ${modal.body};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 30px 0;
  color: #1d1d35;

  white-space: normal;
  svg {
    margin-bottom: 20px;
  }
`;

const Footer = styled.div`
  ${modal.footer};
`;

interface IConfirmationBody {
  onCanceled: () => void;
  onConfirmed: () => void;
  messege?: string;
  confirmMessege?: string;
  cancelMessege?: string;
}

const ConfirmationBody = ({
  onCanceled,
  onConfirmed,
  messege = 'Are you sure you want to perform this action?',
  confirmMessege = 'CONFIRM',
  cancelMessege = 'CANCEL'
}) => {
  return (
    <Wrapper>
      <Header>
        <Title>Confirm</Title>
      </Header>
      <Body>
        <Content>
          <AlertTriangle size={60} color={colors.green} />
          <div dangerouslySetInnerHTML={{ __html: messege }} />
        </Content>
      </Body>
      <Footer>
        <Button variant={ButtonVariant.Secondary} onClick={onCanceled}>
          {cancelMessege}
        </Button>
        <Button variant={ButtonVariant.Primary}  onClick={onConfirmed}>
          {confirmMessege}
        </Button>
      </Footer>
    </Wrapper>
  );
};

export default ConfirmationBody;
