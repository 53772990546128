import React, { useMemo } from 'react';
import { find, isNull, map, findIndex, filter } from 'lodash/fp';
import { FilterSelectWithSearch } from '@common/FilterSelect';
import { getTypeOptions } from '@components/Project/Activity/ActivityHeader/helpers';
import styled from 'styled-components';
import { ProjectStage } from '@generated/types/graphql';
import { Filters as TFilters, UsersOption } from '@components/Project/Activity/types';
import { useProjectStages } from '@hooks/useProjectStages';
import { AUTOMATION_USER_ID, useCompanyProperties, useCompanyUsers, useRoutes } from '@hooks';
import { Property } from '@types';
import { useTeams } from '@hooks/useTeams';
import { DateFieldRange } from '@common/Formik/Form/DateField';

const TYPE_OPTIONS = getTypeOptions();

interface IFiltersProps {
  setFilter: (key: keyof TFilters, value: any) => unknown;
  filters: TFilters;
}

export const Filters: React.FC<IFiltersProps> = ({ filters, setFilter }) => {
  const { companyId, recordType } = useRoutes();
  const { data: stages } = useProjectStages(recordType);
  const {
    data: {
      data: { results: companyUsers }
    }
  } = useCompanyUsers(companyId);
  const { data: teams } = useTeams(companyId);
  const { scopeToAllColumns } = useCompanyProperties({ recordType });
  const properties = scopeToAllColumns[recordType];

  const usersOptions = useMemo(
    () =>
      [
        ...map((user) => {
          return {
            isUser: true,
            rawId: user.id,
            id: `user-${user.id}`,
            user
          };
        }, companyUsers),
        {
          isUser: true,
          id: `user-${AUTOMATION_USER_ID}`,
          rawId: AUTOMATION_USER_ID,
          user: { firstName: 'Coperniq', lastName: 'Automation' }
        },
        ...map((team) => {
          return {
            isTeam: true,
            team,
            rawId: team.id,
            id: `team-${team.id}`
          };
        }, teams)
      ] as UsersOption[],
    [companyUsers, teams]
  );

  return (
    <Wrapper>
      {false && (
        <FilterSelectWithSearch<any>
          label="Types"
          selectedOptions={filters?.types?.map(
            ({ event, entity }) => find({ value: { event, entity } }, TYPE_OPTIONS)?.id
          )}
          options={TYPE_OPTIONS}
          onSelect={(_, rawValue: string[]) => {
            const newValue = rawValue
              .map((id) => {
                return find({ id }, TYPE_OPTIONS)?.value;
              })
              .filter(Boolean);
            setFilter('types', newValue);
          }}
          className="activity-header-select--types"
          allowAll
        />
      )}
      <FilterSelectWithSearch<ProjectStage>
        label="Stages"
        selectedOptions={filters?.stageIds}
        options={stages}
        onSelect={(_, rawValue) => {
          setFilter(
            'stageIds',
            filter((value) => value !== -1, rawValue)
          );
        }}
        className="activity-header-select--stages"
        allowAll
      />
      <FilterSelectWithSearch<Property>
        label="Properties"
        selectedOptions={filters?.propertyIds}
        options={properties}
        onSelect={(_, rawValue) => {
          setFilter(
            'propertyIds',
            filter((value) => value !== Infinity, rawValue)
          );
        }}
        className="activity-header-select--property"
        defaultValue={Infinity}
        allowAll
      />
      <FilterSelectWithSearch<UsersOption>
        label="Users"
        selectedOptions={filters?.userIds.map(({ id }) => id)}
        options={usersOptions}
        grouping={(id, option) => {
          if (option.isTeam && findIndex({ id: option.rawId }, teams) === 0) {
            return 'Teams';
          }
          if (option.isUser && findIndex({ id: option.rawId }, companyUsers) === 0) {
            return 'Users';
          }

          return undefined;
        }}
        onSelect={(_, rawValue) => {
          const populatedValue = usersOptions.filter(({ id }) => rawValue.includes(id));
          setFilter('userIds', populatedValue);
        }}
        getTextForMenu={(option) => {
          return 'user' in option ? `${option.user.firstName} ${option.user.lastName}` : option.team.name;
        }}
        className="activity-header-select--users"
        allowAll
      />
      <DateFieldRange
        onChange={(values) => {
          if (isNull(values)) {
            setFilter('startDate', null);
            setFilter('endDate', null);

            return;
          }

          if (values[0] && values[1]) {
            setFilter('startDate', values[0]);
            setFilter('endDate', values[1]);
          }
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;

  &&& {
    .MuiOutlinedInput-notchedOutline {
      border-color: #e8ecef;
    }
    > * {
      margin: 0;
      height: 32px;
    }

    .react-datepicker-wrapper {
      .MuiInputBase-adornedStart {
        padding-left: 8px;
        padding-right: 0 !important;
      }

      .MuiInputAdornment-positionStart {
        margin-right: 4px;
      }

      .MuiInputAdornment-root > svg {
        padding: 0;
        width: 16px;
        height: 16px;
        min-width: 16px;
      }

      .MuiOutlinedInput-input {
        padding: 0;
        height: 32px;
      }
    }
  }

  & > .MuiInputBase-root {
    flex-basis: content;
    border: 1px solid ${({ theme }) => theme.colors.gray3};
  }
`;
