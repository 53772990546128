import { IntFilter, ProjectReportFilter, TaskReportFilter } from '@generated/types/graphql';
import { DateTime } from 'luxon';
import { TimeGranularity, UserSubdimentionType, WidgetSettings } from '../types';
import { InternalConfigutationError } from '../InternalConfigurationError';

type FilterType = ProjectReportFilter | TaskReportFilter;

export const buildUserDrilldownFilter = <
  T extends FilterType,
  K extends { [P in keyof T]: T[P] extends IntFilter ? P : never }[keyof T]
>(
  segment: string | number | number[] | null,
  settings: WidgetSettings,
  userField: K
): Pick<T, K> => {
  if (segment === null) {
    return {
      [userField]: {
        isNull: true
      }
    } as Pick<T, K>;
  }

  if (settings.subdimensionId === UserSubdimentionType.TEAM) {
    const ids = (segment as number[]).filter((id) => id !== null);
    const hasNoUser = ids.length !== (segment as number[]).length;

    if (hasNoUser) {
      return {
        or: [
          {
            [userField]: {
              isNull: true
            }
          },
          {
            [userField]: {
              in: ids
            }
          }
        ]
      } as unknown as Pick<T, K>;
    }

    return {
      [userField]: {
        in: ids
      }
    } as Pick<T, K>;
  }

  return {
    [userField]: {
      equalTo: segment as number
    }
  } as Pick<T, K>;
};

export const buildDateRangeFilterByTimeGranularity = (
  periodStartDate: string,
  granularity: TimeGranularity,
  timezone: string
) => {
  const start = DateTime.fromISO(periodStartDate, { zone: timezone });

  switch (granularity) {
    case TimeGranularity.DAY: {
      return {
        greaterThanOrEqualTo: start.toISO(),
        lessThanOrEqualTo: start.endOf('day').toISO()
      };
    }
    case TimeGranularity.WEEK: {
      return {
        greaterThanOrEqualTo: start.toISO(),
        lessThanOrEqualTo: start.endOf('week').toISO()
      };
    }
    case TimeGranularity.MONTH: {
      return {
        greaterThanOrEqualTo: start.toISO(),
        lessThanOrEqualTo: start.endOf('month').toISO()
      };
    }
    case TimeGranularity.QUARTER: {
      return {
        greaterThanOrEqualTo: start.toISO(),
        lessThanOrEqualTo: start.endOf('quarter').toISO()
      };
    }
    case TimeGranularity.YEAR: {
      return {
        greaterThanOrEqualTo: start.toISO(),
        lessThanOrEqualTo: start.endOf('year').toISO()
      };
    }
    default:
      throw new InternalConfigutationError(`Unknown time granularity: ${granularity}`);
  }
};
