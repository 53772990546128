import { post, deleteRequest, patch } from './base';
import { Urls } from './base/axios';

export type ActionTemplatePayload = {
  title: string;
  type: string;
  description?: string;
  assignAllContacts: boolean;
};

export const actionTemplateApi = {
  create: (payload: ActionTemplatePayload) =>
    post<ActionTemplatePayload, { id: number }>(`${Urls.project}/action/template`, { ...payload, isTemplate: true }),

  update: (id: number, payload: ActionTemplatePayload) => patch(`${Urls.project}/action/template/${id}`, payload),

  remove: (id: number) => deleteRequest(`${Urls.project}/action/template/${id}`)
};
