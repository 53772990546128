import styled, { css } from 'styled-components';
import { makeStyles } from '@material-ui/core';
import { checkboxMui, datepicker } from '@styles';

export const useStylesCheckbox = makeStyles(checkboxMui({ size: '30px' }));

export const Wrapper = styled.div<{ disabled?: boolean }>`
  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker-popper {
    z-index: 1000;
  }

  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.6;
          pointer-events: none;
          cursor: not-allowed;
        `
      : ''}
`;

export const Row = styled.div`
  display: flex;
`;

export const DatePickerWrapper = styled.div<{ visibleTimeList?: boolean }>`
  box-shadow:
    0px 5px 5px -3px #00000033,
    0px 8px 10px 1px #00000024,
    0px 3px 14px 2px #0000001f;

  ${datepicker};
`;

export const TimeSelector = styled.div<{ isVisibleTimeSelect: boolean; isVisibleTimeList: boolean }>`
  display: ${({ isVisibleTimeSelect }) => (isVisibleTimeSelect ? 'flex' : 'none')};
  align-items: center;
  width: 84px;
  border: 1px solid;
  border-color: ${({ isVisibleTimeList, theme }) => (isVisibleTimeList ? theme.colors.green : theme.colors.gray3)};
  padding: 5px 4px 5px 2px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.25;

  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.colors.green};
  }

  svg {
    margin-right: 4px;
  }
`;

export const DatePickerFooter = styled.div<{ show: boolean }>`
  width: 100%;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  align-items: center;
  margin-top: 10px;

  label {
    margin-left: 0 !important;
    margin-right: auto;
  }
`;

export const ErrorMsg = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 1.25;
  color: ${({ theme }) => theme.colors.red};
  width: 100%;
  text-align: end;
`;
