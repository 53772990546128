import React from 'react';
import { ProjectStage } from '@generated/types/graphql';
import { ProjectStageBadge } from '@components/ProjectStages';

interface Props {
  x?: number;
  y?: number;
  stages: Record<number, ProjectStage>;
  payload?: any;
}

export const StageTick = ({ x, y, stages, payload }: Props) => {
  const stage = stages[payload.value];

  return (
    <foreignObject className="stage-wrapper" x={x - 140} y={y - 12}>
      {stage && <ProjectStageBadge stage={stage} />}
    </foreignObject>
  );
};
