import { useMutation, useQueryClient } from 'react-query';
import { Comment, Feed } from '@generated/types/graphql';
import { CommentCreateDto, CommentPinDto, CommentUpdateDto } from '@types';
import api from '@services/api/commentsApi';
import { errorHandler } from '@services/api/helpers';
import { ReactQueryKey } from '@enums';
import { useToast } from '@hooks/useToast';
import { useSelector } from 'react-redux';
import { selectWorkspaceId } from '@state/selectors';

export const useComments = () => {
  const { showError, showSuccess } = useToast();
  const companyId = useSelector(selectWorkspaceId);
  const queryClient = useQueryClient();

  const create = useMutation<Comment, Error, CommentCreateDto>(
    async (dto) => {
      try {
        return (await api.create(companyId, dto)).data;
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(ReactQueryKey.ProjectActivity);
        showSuccess('Successfully created the comment');
      }
    }
  );

  const update = useMutation<void, Error, { dto: CommentUpdateDto; id: number }>(
    async ({ dto, id }) => {
      try {
        await api.update(companyId, id, dto);
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onMutate: async ({ id, dto }) => {
        queryClient.setQueriesData<Feed[]>([ReactQueryKey.PinnedComments], (old) => {
          if (!old) {
            return old;
          }

          return old.map((item) => {
            if (item.relatedComment?.id === id) {
              return {
                ...item,
                relatedComment: {
                  ...item.relatedComment,
                  comment: dto.comment
                }
              };
            }

            return item;
          });
        });
      },
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(ReactQueryKey.ProjectActivity);

        showSuccess('Successfully updated the comment');
      }
    }
  );

  const remove = useMutation<void, Error, { id: number }>(
    async ({ id }) => {
      try {
        await api.remove(id);
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(ReactQueryKey.ProjectActivity);
        await queryClient.invalidateQueries([ReactQueryKey.PinnedComments]);
        showSuccess('Successfully deleted the comment');
      }
    }
  );

  const pin = useMutation<void, Error, { dto: CommentPinDto; id: number }>(
    async ({ dto, id }) => {
      try {
        await api.pin(id, dto);
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onMutate: async ({ id, dto }) => {
        if (dto.isPinned === true) {
          return;
        }

        queryClient.setQueriesData<Feed[]>([ReactQueryKey.PinnedComments], (old) => {
          if (!old) {
            return old;
          }

          if (dto.isPinned === false) {
            return old.filter((item) => item.relatedComment?.id !== id);
          }
        });
      },
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: async (_data, { dto }) => {
        await queryClient.invalidateQueries(ReactQueryKey.ProjectActivity);

        if (dto.isPinned === true) {
          await queryClient.invalidateQueries([ReactQueryKey.PinnedComments]);
        }

        if (typeof dto.isPinned !== 'undefined') {
          queryClient.invalidateQueries([ReactQueryKey.ProjectsListInitialGroupData]);
          queryClient.invalidateQueries([ReactQueryKey.ProjectsByIds]);
          queryClient.invalidateQueries([ReactQueryKey.ClientsListInitialGroupData]);
          queryClient.invalidateQueries([ReactQueryKey.ClientsByIds]);
          queryClient.invalidateQueries([ReactQueryKey.RequestsListInitialGroupData]);
          queryClient.invalidateQueries([ReactQueryKey.RequestsByIds]);
        }

        showSuccess('Successfully updated the comment');
      }
    }
  );

  return {
    create,
    update,
    remove,
    pin
  };
};
