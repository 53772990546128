import React, { useCallback } from 'react';
import { makeStyles, TextField, TextFieldProps } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { inputStyles } from './styled';

interface Props extends Omit<TextFieldProps, 'variant'> {}

const useStyles = makeStyles(inputStyles());

export const Input = React.forwardRef(({ type, onKeyDown, ...props }: Props, ref) => {
  const classes = useStyles();

  const handleWheel = useCallback((e: React.WheelEvent<HTMLInputElement>) => {
    (e.target as HTMLInputElement).blur();
  }, []);

  const handleKeyDown = useCallback<React.KeyboardEventHandler<HTMLDivElement>>(
    (e) => {
      if (type === 'number' && ['e', 'E', '+'].includes(e.key)) {
        e.preventDefault();
      }
      onKeyDown?.(e);
    },
    [type, onKeyDown]
  );

  return (
    <TextField
      inputRef={ref}
      variant="outlined"
      classes={classes}
      fullWidth
      {...props}
      onWheel={handleWheel}
      type={type}
      onKeyDown={handleKeyDown}
    />
  );
});

export { InputAdornment };
