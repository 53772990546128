import { useAppSelector } from '@hooks/store';
import { selectWorkspaceId } from '@state/selectors';
import { useRecordsGroups } from '@hooks/useRecords';
import { AccountIsActiveStatus, SearchFrom } from '@components/Project/ProjectView/types';
import { AccountStatus, RecordType } from '@types';
import { useMemo } from 'react';
import { useClientFilterState } from '../useClientFilterState';

const getIsActiveFilter = (isArchivedShown: boolean): AccountIsActiveStatus => {
  return isArchivedShown ? AccountIsActiveStatus.all : AccountIsActiveStatus.active;
};

export const useTotalsByStatus = () => {
  const { clientFilters } = useClientFilterState();

  const companyId = useAppSelector(selectWorkspaceId);

  const { data, isLoading } = useRecordsGroups(companyId, RecordType.ACCOUNT, {
    groupBy: -35, // AccountStatus property
    status: [],
    type: getIsActiveFilter(clientFilters.isArchivedShown),
    search: clientFilters.query,
    filters: clientFilters.filters,
    // seems to be not used:
    page: 1,
    perPage: 10,
    from: SearchFrom.grid,
    groupFilter: ''
  });

  const totals = useMemo(() => {
    const result: Record<AccountStatus | 'all', number> = {
      all: 0,
      [AccountStatus.LEAD]: 0,
      [AccountStatus.PROSPECT]: 0,
      [AccountStatus.CUSTOMER]: 0
    };

    data?.groups.forEach((group) => {
      result[group.label as AccountStatus] = group.ids.length;
      result.all += group.ids.length;
    });

    return result;
  }, [data]);

  return {
    totals,
    isLoading
  };
};
