import post from '@services/api/base/post';
import deleteReq from '@services/api/base/delete';
import { Urls } from '@services/api/base/axios';
import { PropertyGroupCreateDto, PropertyGroupUpdateDto, PropertyLayoutDto, PropertyGroupLayoutDto } from '@services/api/types';
import { PropertyGroup } from '@hooks/usePropertyGroup';
import { patch } from '@services/api/base';

export default {
  create: (companyId: number, dto: PropertyGroupCreateDto) =>
    post<PropertyGroup>(`${Urls.project}/column-groups`, dto, { companyId }),

  update: (companyId: number, id: number, dto: PropertyGroupUpdateDto) =>
    patch(`${Urls.project}/column-groups/${id}`, dto, { companyId }),

  remove: (companyId: number, id: number) => deleteReq(`${Urls.project}/column-groups/${id}`, {}, { companyId }),

  updateLayout: (companyId: number, dto: PropertyGroupLayoutDto) =>
    patch(`${Urls.project}/column-groups/layout`, dto, { companyId }),

  updateColumnLayout: (companyId: number, dto: PropertyLayoutDto[]) =>
    patch(`${Urls.project}/column-groups/column-layout`, dto, { companyId })
};
