import React from 'react';

export const LeaderboardIcon = ({
  size = '16px',
  color = 'currentColor'
}: {
  size?: string | number;
  color?: string;
}) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 6C9 5.44772 9.38802 5 9.86667 5H21.1333C21.612 5 22 5.44772 22 6C22 6.55228 21.612 7 21.1333 7H9.86667C9.38802 7 9 6.55228 9 6Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 12C7 11.4477 7.44772 11 8 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H8C7.44772 13 7 12.5523 7 12Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 18C7 17.4477 7.44772 17 8 17H21C21.5523 17 22 17.4477 22 18C22 18.5523 21.5523 19 21 19H8C7.44772 19 7 18.5523 7 18Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 12C5 11.4477 4.55228 11 4 11H3C2.44772 11 2 11.4477 2 12C2 12.5523 2.44772 13 3 13H4C4.55228 13 5 12.5523 5 12Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 18C5 17.4477 4.55228 17 4 17H3C2.44772 17 2 17.4477 2 18C2 18.5523 2.44772 19 3 19H4C4.55228 19 5 18.5523 5 18Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.33464 2.73076C3.54419 2.08584 4.45658 2.08585 4.66612 2.73076L5.26171 4.56379H7.18907C7.86719 4.56379 8.14912 5.43152 7.60052 5.8301L6.04125 6.96298L6.63684 8.796C6.84639 9.44092 6.10826 9.97721 5.55965 9.57863L4.00038 8.44575L2.44111 9.57863C1.89251 9.97721 1.15438 9.44092 1.36393 8.79601L1.95951 6.96298L0.400244 5.8301C-0.148359 5.43152 0.133593 4.56379 0.811694 4.56379H2.73906L3.33464 2.73076ZM4.00038 3.91789L3.62276 5.0801C3.52905 5.36852 3.26028 5.56379 2.95702 5.56379H1.735L2.72363 6.28207C2.96898 6.46033 3.07164 6.77628 2.97792 7.0647L2.6003 8.22691L3.58893 7.50862C3.83427 7.33037 4.16649 7.33037 4.41183 7.50862L5.40047 8.22691L5.02284 7.0647C4.92913 6.77628 5.03179 6.46033 5.27713 6.28207L6.26577 5.56379H5.04375C4.74049 5.56379 4.47172 5.36852 4.37801 5.0801L4.00038 3.91789Z"
      fill={color}
    />
  </svg>
);
