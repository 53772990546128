import { useDebouncedState } from '@hooks/useDebouncedState';
import { useUpdateEffect } from '@react-hookz/web';
import React, { useCallback, useMemo, useState } from 'react';
import { SearchBar } from '@common/SearchBar';
import { Switch } from '@kit/ui/Switch';
import { ContextMenu } from '@kit/components/ContextMenu';
import { Button, ButtonSize, ButtonVariant, IconButton } from '@kit/ui/Button';
import { Archive, Download, Eye, EyeOff, Plus, Trash2 } from 'react-feather';
import { XIcon } from '@kit/ui/icons/X';
import { downloadMultiple } from '@components/Project/DataBank/redux/actions';
import { Tooltip } from '@material-ui/core';
import { useConfirmDeleteModal, useConfirmModal } from '@common/PromiseModal';
import { useRecordStageList } from '@hooks/records/useRecordStageList';
import { useFileMutation } from '@hooks/useFiles';
import { Popover } from '@kit/ui/Popover';
import { MoveToFolderIcon } from '@kit/ui/icons/MoveToFolder';
import { UnarchiveIcon } from '@kit/ui/icons/Unarchive';
import { useUserRoleSettings } from '@hooks/useRoles';
import { isCustomerPortalEnabled, selectCurrentUserId, selectWorkspaceId } from '@state/selectors';
import { useAppSelector } from '@hooks/store';
import { hasAccess } from '@utils/roles';
import { useCreateDocMenuItems } from '../../useCreateDocMenuItems';
import { useDocsAccess } from '../../useDocsAccess';
import { Container, PopoverMenuItem, Right, SelectionControl } from './styled';
import { DocFilters } from '../../useFilters';

interface Props {
  recordId: number;
  filters: DocFilters;
  onFiltersChange: (filters: Partial<DocFilters>) => void;

  selectedGroups: number[];
  selectedIdsOutsideGroups: number[];
  selectedIds: number[];
  clearSelection: () => void;
  isAllSelectedItemsArchived: boolean;
  isAllSelectedFilesSharedWithPortal: boolean;
  isAllSelectedFilesNotSharedWithPortal: boolean;
}

export const Header = ({
  recordId,
  filters,
  onFiltersChange,
  selectedGroups,
  selectedIds,
  selectedIdsOutsideGroups,
  clearSelection,
  isAllSelectedItemsArchived,
  isAllSelectedFilesSharedWithPortal,
  isAllSelectedFilesNotSharedWithPortal
}: Props) => {
  const { canCreateFile, canCreateForm } = useDocsAccess();
  const createDocMenuItems = useCreateDocMenuItems(recordId);
  const { data: stages = [] } = useRecordStageList(recordId);

  const [, debouncedSearch, setSearch] = useDebouncedState(filters.query, 300);
  const confirm = useConfirmModal();
  const confirmRemove = useConfirmDeleteModal();
  const {
    bulkUpdate: { mutateAsync: bulkUpdate },
    bulkRemove: { mutateAsync: bulkRemove },
    bulkUpdateFiles: { mutateAsync: bulkUpdateFiles }
  } = useFileMutation();

  const companyId = useAppSelector(selectWorkspaceId);
  const isPortalEnabled = useAppSelector(isCustomerPortalEnabled);

  const userId = useAppSelector(selectCurrentUserId);

  const { data: access } = useUserRoleSettings(companyId, userId);

  const canEdit = useMemo(() => hasAccess(access, 'file', 'edit') || hasAccess(access, 'form', 'edit'), [access]);
  const canDelete = useMemo(() => hasAccess(access, 'file', 'delete') || hasAccess(access, 'form', 'delete'), [access]);

  useUpdateEffect(() => {
    onFiltersChange({
      query: debouncedSearch
    });
  }, [debouncedSearch, onFiltersChange]);

  const handleArchivedToggle = useCallback(() => {
    onFiltersChange({
      isArchivedShown: !filters.isArchivedShown
    });
  }, [filters.isArchivedShown, onFiltersChange]);

  const [isDownloading, setIsDownloading] = useState(false);
  const [isMovingFiles, setIsMovingFiles] = useState(false);

  const handleDownload = useCallback(async () => {
    setIsDownloading(true);

    await downloadMultiple({
      stageIds: selectedGroups,
      files: selectedIdsOutsideGroups,
      projectId: recordId
    });

    setIsDownloading(false);
  }, [selectedGroups, selectedIdsOutsideGroups, recordId]);

  const handleMoveToFolder = useCallback(
    async (targetId: number) => {
      setIsMovingFiles(true);

      await bulkUpdate({
        fileIds: selectedIds,
        projectId: recordId,
        stageId: targetId > 0 ? targetId : null
      });

      setIsMovingFiles(false);
    },
    [recordId, selectedIds, bulkUpdate]
  );

  const handleShareWithPortal = useCallback(async () => {
    await bulkUpdateFiles({
      projectId: recordId,
      fileIds: selectedIds,
      sharedWithPortal: true
    });

    clearSelection();
  }, [bulkUpdateFiles, selectedIds, clearSelection, recordId]);

  const handleHideFromPortal = useCallback(async () => {
    await bulkUpdateFiles({
      projectId: recordId,
      fileIds: selectedIds,
      sharedWithPortal: false
    });

    clearSelection();
  }, [bulkUpdateFiles, selectedIds, clearSelection, recordId]);

  const handleArchive = useCallback(async () => {
    if (
      await confirm({ confirmText: 'Are you sure you want to archive selected files?', confirmButtonText: 'Archive' })
    ) {
      await bulkUpdate({
        fileIds: selectedIds,
        projectId: recordId,
        isArchived: true
      });

      clearSelection();
    }
  }, [confirm, bulkUpdate, selectedIds, recordId, clearSelection]);

  const handleRestore = useCallback(async () => {
    await bulkUpdate({
      fileIds: selectedIds,
      projectId: recordId,
      isArchived: false
    });

    clearSelection();
  }, [bulkUpdate, selectedIds, recordId, clearSelection]);

  const handleRemove = useCallback(async () => {
    if (await confirmRemove('Are you sure you want to delete selected files?')) {
      await bulkRemove({
        fileIds: selectedIds,
        projectId: recordId
      });

      clearSelection();
    }
  }, [confirmRemove, bulkRemove, selectedIds, recordId, clearSelection]);

  const moveTargets = useMemo(() => {
    if (!stages.length) {
      return [];
    }

    return [
      {
        name: 'Other',
        id: -1
      },
      ...stages.map((stage) => ({
        id: stage.id,
        name: stage.name
      }))
    ];
  }, [stages]);

  const hasSelected = selectedIds.length > 0;

  return (
    <Container>
      <SearchBar placeholder="Search..." onValueChange={setSearch} />

      {!hasSelected && (canCreateFile || canCreateForm) && (
        <ContextMenu items={createDocMenuItems}>
          <Button variant={ButtonVariant.Primary}>
            <Plus size="16px" />
            File
          </Button>
        </ContextMenu>
      )}

      {hasSelected && (
        <SelectionControl>
          <IconButton onClick={clearSelection} variant={ButtonVariant.Secondary} size={ButtonSize.Small}>
            <XIcon size="16px" />
          </IconButton>

          <div>{selectedIds.length} selected:</div>

          <Tooltip title="Download">
            <IconButton
              disabled={isDownloading}
              onClick={handleDownload}
              variant={ButtonVariant.Flat}
              size={ButtonSize.Small}
            >
              <Download size="16px" />
            </IconButton>
          </Tooltip>

          {isPortalEnabled && !isAllSelectedFilesSharedWithPortal && (
            <Tooltip title="Share with Portal">
              <IconButton
                disabled={isDownloading}
                onClick={handleShareWithPortal}
                variant={ButtonVariant.Flat}
                size={ButtonSize.Small}
              >
                <Eye size="16px" />
              </IconButton>
            </Tooltip>
          )}

          {isPortalEnabled && !isAllSelectedFilesNotSharedWithPortal && (
            <Tooltip title="Hide from Portal">
              <IconButton
                disabled={isDownloading}
                onClick={handleHideFromPortal}
                variant={ButtonVariant.Flat}
                size={ButtonSize.Small}
              >
                <EyeOff size="16px" />
              </IconButton>
            </Tooltip>
          )}

          {canEdit && moveTargets.length > 0 && (
            <Popover
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              content={
                <div>
                  {moveTargets.map((target) => (
                    <PopoverMenuItem
                      key={target.id}
                      onClick={isMovingFiles ? undefined : () => handleMoveToFolder(target.id)}
                    >
                      {target.name}
                    </PopoverMenuItem>
                  ))}
                </div>
              }
            >
              <Tooltip title="Move">
                <IconButton disabled={isMovingFiles} variant={ButtonVariant.Flat} size={ButtonSize.Small}>
                  <MoveToFolderIcon size="16px" />
                </IconButton>
              </Tooltip>
            </Popover>
          )}

          {canDelete && !isAllSelectedItemsArchived && (
            <Tooltip title="Archive">
              <IconButton onClick={handleArchive} variant={ButtonVariant.Flat} size={ButtonSize.Small}>
                <Archive size="16px" />
              </IconButton>
            </Tooltip>
          )}

          {isAllSelectedItemsArchived && (
            <>
              <Tooltip title="Restore">
                <IconButton onClick={handleRestore} variant={ButtonVariant.Flat} size={ButtonSize.Small}>
                  <UnarchiveIcon size="16px" />
                </IconButton>
              </Tooltip>

              {canDelete && (
                <Tooltip title="Delete">
                  <IconButton onClick={handleRemove} variant={ButtonVariant.Flat} size={ButtonSize.Small}>
                    <Trash2 size="16px" />
                  </IconButton>
                </Tooltip>
              )}
            </>
          )}
        </SelectionControl>
      )}

      <Right>
        <Switch
          isActive={filters.isArchivedShown}
          id="docs_archived_toggle"
          label="Show archived"
          onChange={handleArchivedToggle}
        />
      </Right>
    </Container>
  );
};
