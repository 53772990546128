import { useClickOutside, usePrevious } from '@react-hookz/web';
import { useFormikContext } from 'formik';
import { useEffect, useRef, useState } from 'react';

export const useInlineField = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const ref = useRef();
  const prevIsEdit = usePrevious(isEdit);

  const { values, submitForm } = useFormikContext();

  useEffect(() => {
    if (!isFocused) {
      setIsEdit(false);
    }
  }, [isFocused]);

  useClickOutside(ref, () => {
    if (!isFocused) {
      setIsEdit(false);
    }
  });

  useEffect(() => {
    if (prevIsEdit && !isEdit) {
      submitForm();
    }
  }, [isEdit, prevIsEdit, values, submitForm]);

  return {
    isEdit,
    setIsEdit,
    isFocused,
    setIsFocused,
    nodeRef: ref
  };
};
