import styled from 'styled-components';
import { colors } from '@styles';

export const Wrapper = styled.div<{ type: string; size: string }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  margin: 0 3px;
  padding: 4px;
  font-weight: 500;
  font-size: 10px;
  line-height: 1.25;
  border-radius: 4px;
  background-color: ${(props) => {
    switch (props.type) {
      case 'info':
        return colors.yellow;
      case 'success':
        return colors.blue;
      case 'error':
        return colors.red;
      case 'smartViewsCount':
        return colors.red;
      case 'won':
        return colors.green;
      default:
        return colors.gray2;
    }
  }};

  min-width: ${(props) => {
    switch (props.size) {
      case 'small':
        return '14px';
      case 'big':
        return '20px';
      default:
        return '20px';
    }
  }};
  height: ${(props) => {
    switch (props.size) {
      case 'small':
        return '14px';
      case 'big':
        return '20px';
      default:
        return '20px';
    }
  }};
`;
