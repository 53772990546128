import { AccountStatus } from '@types';

export const STATUS_CONFIG: Record<AccountStatus, { label: string; color: string }> = {
  [AccountStatus.LEAD]: {
    label: 'Lead',
    color: '#235DFF'
  },
  [AccountStatus.PROSPECT]: {
    label: 'Prospect',
    color: '#FFBD13'
  },
  [AccountStatus.CUSTOMER]: {
    label: 'Customer',
    color: '#009688'
  }
};

export const PER_PAGE_OPTIONS = [10, 20, 50].map((value) => ({ label: value.toString(), value }));
