import { building, gmail, outlook, unlink } from '@assets/svg';
import React from 'react';

export const Building = ({ size = 24 }) => <img src={building} width={size} height={size} alt="building" />;

export const Gmail = ({ size = 24 }) => <img src={gmail} width={size} height={size} alt="gmail" />;

export const Outlook = ({ size = 24 }) => <img src={outlook} width={size} height={size} alt="outlook" />;
export const Unlink = ({ size = 24, ...props }: React.ImgHTMLAttributes<HTMLImageElement> & { size: number }) => (
  <img src={unlink} width={size} height={size} alt="unlink" {...props} />
);
