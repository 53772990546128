import * as actionTypes from '../../actions/actionTypes/actionTypes';
import IAlertReducerTypes from './types';

export const initialState: IAlertReducerTypes = {
  type: 'success',
  message: [],
  show: false
};

const alertShow = (state, action) => {
  return { ...state, ...action.payload, show: true };
};

const alertClose = (state, action) => {
  return { ...state, type: '', message: [], show: false };
};

const alertClear = (state, action) => {
  return { ...state, type: '', message: [], show: false };
};

const activityFeedReducer = (
  state = initialState,
  action
): IAlertReducerTypes => {
  switch (action.type) {
    case actionTypes.MESSAGE_ALERT_SHOW:
      return alertShow(state, action);
    case actionTypes.MESSAGE_ALERT_CLOSE:
      return alertClose(state, action);
    case actionTypes.MESSAGE_ALERT_CLEAR:
      return alertClear(state, action);
    default:
      return state;
  }
};

export default activityFeedReducer;
