import { Badge } from '@kit/ui/Badge';
import styled, { css } from 'styled-components';

export const ImageAndCheckboContainer = styled.div`
  position: relative;
  flex-shrink: 0;
  height: 40px;
`;

export const CheckboxContainer = styled.div`
  display: none;
  position: absolute;

  left: 2px;
  top: 2px;
  z-index: 1;
`;

export const VideoBadge = styled(Badge)`
  position: absolute;
  bottom: 4px;
  left: 4px;
  z-index: 1;
  width: 20px;
  padding: 0;
`;

export const DocRow = styled.div<{ isSelected: boolean; isArchived: boolean }>`
  height: 64px;
  padding: 12px 16px;
  transition: background-color 0.2s ease;
  background: ${({ isSelected }) => (isSelected ? '#E3F6F4' : '#FFFFFF')};
  border-bottom: 1px solid #dfdfe8;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;

  opacity: ${({ isArchived }) => (isArchived ? 0.5 : 1)};

  &:hover {
    ${CheckboxContainer} {
      display: block;
    }

    ${VideoBadge} {
      display: none;
    }
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      ${CheckboxContainer} {
        display: block;
      }

      ${VideoBadge} {
        display: none;
      }
    `};
`;

export const CommentsCount = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const User = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const FileImageThumbnail = styled.img<{ size: number }>`
  object-fit: fill;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 4px;
`;

export const FileIconThumbnail = styled.div<{ size: number }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dfdfe8;
`;

export const Cell = styled.div<{ width: number; isCentered?: boolean }>`
  width: ${({ width }) => width}px;
  min-width: ${({ width }) => width}px;
  flex: 0 0 ${({ width }) => width}px;
  display: flex;
  justify-content: ${({ isCentered }) => (isCentered ? 'center' : 'flex-start')};
`;

export const FileNameCell = styled.div`
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const FileNameAndIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const CommentStats = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const Badges = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  align-items: center;
`;
