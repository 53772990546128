import {
  ANALYTICS_MODULE_RECORD_TYPE_MAP,
  STATUS_LIFECYCLE_WORKFLOWS,
  TIME_TO_COMPLETE_PROPERTY_ID
} from '@features/Analytics/constants';
import { dateRangeConfig } from '@features/Analytics/dateRangeConfig';
import {
  AnalyticsModule,
  AnalyticsSubmeasureType,
  AnalyticsWidgetAggregateFunction,
  AnalyticsWidgetDateRangeType,
  AnalyticsWidgetType,
  DimensionType,
  PredefinedWidgetFilterFieldId,
  RecordDimensionType,
  ShowFilterType,
  StandardPropertyId,
  TimeGranularity,
  WidgetFilter,
  WidgetSettings
} from '@features/Analytics/types';
import {
  ProjectReportAggregates,
  ProjectReportCondition,
  ProjectReportFilter,
  ProjectReportGroupBy,
  ProjectStatus,
  StringFilter
} from '@generated/types/graphql';
import { DeepPartial } from 'redux';
import { FilterOperators, ProjectStageType, Property } from '@types';
import { isClientStatus, isRequestStatus, isStage, isStatus } from '@utils/properties';
import { DateTime } from 'luxon';
import { WidgetSettingsError } from '@features/Analytics/WidgetSettingsError';
import { isMeasureRequired, isStatusLifecycleWorkflow } from '@features/Analytics/helpers';
import {
  STANDARD_DROPDOWN_PROPERTY_ID_TO_GROUP_BY,
  TIME_GRANULARITY_TO_CREATED_AT_GROUP_BY,
  TIME_GRANULARITY_TO_TIMELINE_STAGE_ENDED_AT_GROUP_BY,
  TIME_GRANULARITY_TO_TIMELINE_STAGE_STARTED_AT_GROUP_BY,
  TIME_GRANULARITY_TO_TIMELINE_STATUS_ENDED_AT_GROUP_BY,
  TIME_GRANULARITY_TO_TIMELINE_STATUS_STARTED_AT_GROUP_BY
} from './constants';
import { RequestParams } from '../types';
import { applyFiltersGroup, buildCreateAtDateRangeFilter } from '../helpers';
import { InternalConfigutationError } from '../../InternalConfigurationError';
import { customPropertiesFilterHandlers, standardPropertiesFilterHandlers } from './filtering';
import { BuildRequestArgs } from './types';

type RecordRequestParams = RequestParams<ProjectReportFilter, ProjectReportCondition, ProjectReportGroupBy>;

const buildAggregationResponseValueField = (settings: WidgetSettings, propertiesMap: Record<number, Property>) => {
  if (settings.aggregateFunctionId === AnalyticsWidgetAggregateFunction.COUNT) {
    return 'id';
  }

  if (settings.measure.id.toString() === TIME_TO_COMPLETE_PROPERTY_ID) {
    return 'completionTimeInS';
  }

  if (settings.measure.id > 0) {
    return 'numericValue';
  }

  const property = propertiesMap[settings.measure.id];

  if (!property) {
    throw new WidgetSettingsError('Measure property is not found');
  }

  return property.mappedName;
};

export const buildAggregationResponseFields = (settings: WidgetSettings, propertiesMap: Record<number, Property>) => {
  const valueField = buildAggregationResponseValueField(settings, propertiesMap);

  switch (settings.aggregateFunctionId) {
    case AnalyticsWidgetAggregateFunction.COUNT:
      return `
                distinctCount {
                    ${valueField}
                }
            `;
    case AnalyticsWidgetAggregateFunction.SUM:
      return `
                sum {
                    ${valueField}
                }
            `;
    case AnalyticsWidgetAggregateFunction.AVG:
      return `
                average {
                    ${valueField}
                }
            `;
    case AnalyticsWidgetAggregateFunction.MIN:
      return `
                min {
                    ${valueField}
                }
            `;
    case AnalyticsWidgetAggregateFunction.MAX:
      return `
                max {
                    ${valueField}
                }
            `;
    default:
      throw new WidgetSettingsError('Unsupported aggregate function');
  }
};

export const extractGroupAggregationValue = (
  settings: WidgetSettings,
  aggregate: ProjectReportAggregates,
  propertiesMap: Record<number, Property>
) => {
  const valueField = buildAggregationResponseValueField(settings, propertiesMap);

  switch (settings.aggregateFunctionId) {
    case AnalyticsWidgetAggregateFunction.COUNT:
      return aggregate.distinctCount[valueField as keyof typeof aggregate.distinctCount];
    case AnalyticsWidgetAggregateFunction.SUM:
      return aggregate.sum[valueField as keyof typeof aggregate.sum];
    case AnalyticsWidgetAggregateFunction.AVG:
      return aggregate.average[valueField as keyof typeof aggregate.average];
    case AnalyticsWidgetAggregateFunction.MIN:
      return aggregate.min[valueField as keyof typeof aggregate.min];
    case AnalyticsWidgetAggregateFunction.MAX:
      return aggregate.max[valueField as keyof typeof aggregate.max];
    default:
      throw new WidgetSettingsError('Unsupported aggregate function');
  }
};

// filtering logic is used both in analytics and in records portfolio
// "timelineStageId" and "timelineStatus" only used in analytics
// so we need to patch the property in order to use applySingleFilter in records portfolio
const patchedApplySingleFilter =
  (settings: WidgetSettings, propertiesMap: Record<number, Property>) =>
  (filter: WidgetFilter): DeepPartial<ProjectReportFilter> => {
    if (typeof filter.fieldId === 'number' && filter.fieldId < 0) {
      const originalProperty = propertiesMap[filter.fieldId];

      if (originalProperty) {
        let patchedProperty;

        if (
          isStage(originalProperty) &&
          settings.widgetType === AnalyticsWidgetType.TIMELINE &&
          !isStatusLifecycleWorkflow(settings.workflowId)
        ) {
          patchedProperty = {
            ...originalProperty,
            mappedName: 'timelineStageId'
          };
        }

        if (settings.widgetType === AnalyticsWidgetType.TIMELINE && isStatusLifecycleWorkflow(settings.workflowId)) {
          if (isStatus(originalProperty) || isRequestStatus(originalProperty) || isClientStatus(originalProperty)) {
            patchedProperty = {
              ...originalProperty,
              mappedName: 'timelineStatus'
            };
          }
        }

        if (patchedProperty) {
          return applySingleFilter({
            ...propertiesMap,
            [patchedProperty.id]: patchedProperty
          })(filter);
        }
      }
    }

    return applySingleFilter(propertiesMap)(filter);
  };

export const applySingleFilter =
  (propertiesMap: Record<number, Property>) =>
  (filter: WidgetFilter): DeepPartial<ProjectReportFilter> => {
    if (filter.fieldId === PredefinedWidgetFilterFieldId.WORKFLOW) {
      if (filter.operator === FilterOperators.In) {
        if (!filter.value) {
          return {
            blueprintId: {
              isNull: false
            }
          };
        }

        return {
          blueprintId: {
            in: filter.value as number[]
          }
        };
      }

      if (!filter.value) {
        return {
          blueprintId: {
            isNull: true
          }
        };
      }

      return {
        blueprintId: {
          notIn: filter.value as number[]
        }
      };
    }

    if (filter.fieldId === PredefinedWidgetFilterFieldId.WORK_ORDER_WITH_TITLE) {
      const operator: keyof StringFilter =
        filter.operator === FilterOperators.Like ? 'includesInsensitive' : 'notIncludesInsensitive';

      return {
        project: {
          tasks: {
            some: {
              isArchived: { equalTo: false },
              title: {
                [operator]: filter.value
              }
            }
          }
        }
      };
    }

    if (typeof filter.fieldId !== 'number') {
      throw new InternalConfigutationError('Unsupported filter field');
    }

    const property = propertiesMap[filter.fieldId];

    if (!property) {
      throw new InternalConfigutationError('Filter is using non-existing property');
    }

    // standard properties
    if (typeof filter.fieldId === 'number' && filter.fieldId < 0) {
      const handler = standardPropertiesFilterHandlers[filter.operator];

      if (!handler) {
        throw new InternalConfigutationError(`Unsupported filter operator ${filter.operator} for standard property`);
      }

      return handler({ property, filter });
    }

    // custom properties
    if (typeof filter.fieldId === 'number' && filter.fieldId > 0) {
      const handler = customPropertiesFilterHandlers[filter.operator];

      if (!handler) {
        throw new InternalConfigutationError(`Unsupported filter operator ${filter.operator} for custom property`);
      }

      return handler({ property, filter });
    }

    return {};
  };

const buildExludeNullNumericValuesFilter = (
  settings: WidgetSettings,
  propertiesMap: Record<number, Property>
): DeepPartial<ProjectReportFilter> => {
  if (!isMeasureRequired(settings.widgetType)) {
    return {};
  }

  if (!settings.measure) {
    throw new WidgetSettingsError('Measure is not defined');
  }

  if (settings.measure.id === StandardPropertyId.ID || settings.measure.id === TIME_TO_COMPLETE_PROPERTY_ID) {
    // record count - dont need to exclude null values
    return {};
  }

  const property = propertiesMap[settings.measure.id];

  if (!property) {
    throw new WidgetSettingsError('Measure property is not found');
  }

  if (settings.measure.id < 0) {
    // standard property
    return {
      [property.mappedName]: {
        isNull: false
      }
    };
  }

  return {
    projectPropertiesValues: {
      some: {
        columnId: {
          equalTo: settings.measure.id
        },
        numericValue: {
          isNull: false
        }
      }
    }
  };
};

export const buildCommonFilters = (
  companyId: number,
  settings: WidgetSettings,
  propertiesMap: Record<number, Property>
): DeepPartial<ProjectReportFilter> => {
  const recordType = ANALYTICS_MODULE_RECORD_TYPE_MAP[settings.module];

  const result: DeepPartial<ProjectReportFilter> = {
    companyId: {
      equalTo: companyId
    },
    type: {
      equalTo: recordType
    },
    ...buildExludeNullNumericValuesFilter(settings, propertiesMap)
  };

  const isStageWorkflow = settings.workflowId && !isStatusLifecycleWorkflow(settings.workflowId);

  if (isStageWorkflow) {
    result.blueprintId = {
      equalTo: settings.workflowId
    };
  }

  if (settings.show === ShowFilterType.ACTIVE) {
    result.isActive = {
      equalTo: true
    };
  } else if (settings.show === ShowFilterType.ARCHIVED) {
    result.isActive = {
      equalTo: false
    };
  }

  if (settings.measure?.id.toString() === TIME_TO_COMPLETE_PROPERTY_ID) {
    if (settings.module === AnalyticsModule.PROJECTS) {
      result.status = {
        equalTo: ProjectStatus.Completed
      };
    }

    if (settings.module === AnalyticsModule.REQUESTS) {
      result.stage = {
        type: {
          in: [ProjectStageType.won, ProjectStageType.lost]
        }
      };
    }
  }

  if (settings.filters && settings.filters.children.length) {
    const groupResult = applyFiltersGroup(
      settings.filters,
      settings.filters.operator,
      patchedApplySingleFilter(settings, propertiesMap)
    );

    result.and = groupResult.and;
    result.or = groupResult.or;
  }

  return result;
};

const buildSubmeasureDateRangeCondition = (
  settings: WidgetSettings,
  calculatePreviousPeriod = false
): DeepPartial<ProjectReportCondition> => {
  const dateRangeOption = dateRangeConfig[settings.dateRangeType];

  if (settings.dateRangeType === AnalyticsWidgetDateRangeType.ALL_TIME) {
    return {};
  }

  if (!settings.submeasureId) {
    return {};
  }

  const isEntered = settings.submeasureTypeId === AnalyticsSubmeasureType.ENTERED;

  let greaterThanOrEqualTo;
  let lessThanOrEqualTo;

  greaterThanOrEqualTo = dateRangeOption.startDate ? dateRangeOption.startDate().toISO() : settings.dateRangeStart;

  lessThanOrEqualTo = dateRangeOption.endDate ? dateRangeOption.endDate().toISO() : settings.dateRangeEnd;

  if (!greaterThanOrEqualTo && !lessThanOrEqualTo) {
    return {};
  }

  if (calculatePreviousPeriod) {
    const startDateDateTime = greaterThanOrEqualTo ? DateTime.fromISO(greaterThanOrEqualTo) : undefined;
    const endDateDateTime = lessThanOrEqualTo ? DateTime.fromISO(lessThanOrEqualTo) : undefined;

    const diff = endDateDateTime.diff(startDateDateTime, 'days').days;

    greaterThanOrEqualTo = DateTime.fromISO(greaterThanOrEqualTo).minus({ days: diff }).toISO();
    lessThanOrEqualTo = DateTime.fromISO(lessThanOrEqualTo).minus({ days: diff }).toISO();
  }

  if (isEntered) {
    return {
      timelineStartedStartDate: greaterThanOrEqualTo,
      timelineStartedEndDate: lessThanOrEqualTo
    };
  } else {
    return {
      timelineEndedStartDate: greaterThanOrEqualTo,
      timelineEndedEndDate: lessThanOrEqualTo
    };
  }
};

export const buildSubmeasureCondition = (
  settings: WidgetSettings,
  calculatePreviousPeriod = false
): DeepPartial<ProjectReportCondition> => {
  if (!settings.submeasureId) {
    return {};
  }

  const isStatus = settings.submeasureId.toString().startsWith('status_');

  const statusOrStageId = isStatus
    ? settings.submeasureId.toString().replace('status_', '')
    : parseInt(settings.submeasureId.toString().replace('stage_', ''), 10);

  if (isStatus) {
    return {
      withStatusTimeline: true,
      timelineStatus: statusOrStageId as string,
      ...buildSubmeasureDateRangeCondition(settings, calculatePreviousPeriod)
    };
  }

  return {
    withStageTimeline: true,
    timelineStageId: statusOrStageId as number,
    ...buildSubmeasureDateRangeCondition(settings, calculatePreviousPeriod)
  };
};

const buildGroupByDropdownPropertyCondition = (settings: WidgetSettings): DeepPartial<ProjectReportCondition> => {
  if (settings.dimensionId !== DimensionType.DROPDOWN_PROPERTY) {
    return {};
  }

  const propertyId = parseInt(settings.subdimensionId.split('_')[1], 10);

  const isCustomProperty = propertyId > 0;

  if (!isCustomProperty) {
    return {
      // this will be overriden for data query request so there are no record duplicates
      tradesAsSeparate: propertyId === StandardPropertyId.TRADES
    };
  }

  return {
    selectDropdownProperty: propertyId
  };
};

export const buildGroupByProductCondition = (settings: WidgetSettings): DeepPartial<ProjectReportCondition> => {
  if (settings.dimensionId !== DimensionType.PRODUCT_CATEGORY) {
    return {};
  }

  return {
    selectCategory: parseInt(settings.subdimensionId.split('_')[2], 10)
  };
};

const buildNumericPropertyMeasureCondition = (settings: WidgetSettings): DeepPartial<ProjectReportCondition> => {
  if (!settings.measure || settings.measure.id <= 0 || typeof settings.measure.id === 'string') {
    return {};
  }

  return {
    selectNumericProperty: settings.measure.id
  };
};

const buildCommonCondition = (settings: WidgetSettings): DeepPartial<ProjectReportCondition> => {
  const condition: DeepPartial<ProjectReportCondition> = {
    ...buildGroupByDropdownPropertyCondition(settings),
    ...buildGroupByProductCondition(settings),
    ...buildNumericPropertyMeasureCondition(settings)
  };

  return condition;
};

export const buildTimelineFilter = (
  settings: WidgetSettings,
  stageIdsByWorkflowMap: Record<number, number[]>
): DeepPartial<ProjectReportFilter> => {
  if (
    ![AnalyticsWidgetType.TIMELINE, AnalyticsWidgetType.PIPELINE, AnalyticsWidgetType.FUNNEL].includes(
      settings.widgetType
    )
  ) {
    return {};
  }

  const isStatus = isStatusLifecycleWorkflow(settings.workflowId);

  if (isStatus) {
    return {
      timelineStatus: {
        isNull: false
      }
    };
  }

  return {
    timelineStageExists: true,
    timelineStageId: {
      in: stageIdsByWorkflowMap[settings.workflowId]
    }
  };
};

export const buildTimelineDateRangeCondition = (settings: WidgetSettings): DeepPartial<ProjectReportCondition> => {
  if (settings.widgetType !== AnalyticsWidgetType.TIMELINE) {
    return {};
  }

  if (settings.dateRangeType === AnalyticsWidgetDateRangeType.ALL_TIME) {
    const isStatus = STATUS_LIFECYCLE_WORKFLOWS.includes(settings.workflowId.toString());

    return isStatus ? { timelineStatusIsEnded: true } : { timelineStageIsEnded: true };
  }

  const dateRangeOption = dateRangeConfig[settings.dateRangeType];

  if (!dateRangeOption) {
    throw new InternalConfigutationError(`Date range type ${settings.dateRangeType} is not exist`);
  }

  const result = {} as DeepPartial<ProjectReportCondition>;

  const startDate = dateRangeOption.startDate ? dateRangeOption.startDate().toISO() : settings.dateRangeStart;
  const endDate = dateRangeOption.endDate ? dateRangeOption.endDate().toISO() : settings.dateRangeEnd;

  if (!startDate && !endDate) {
    return {};
  }

  result.timelineStartedStartDate = startDate;
  result.timelineStartedEndDate = endDate;
  result.timelineEndedStartDate = startDate;
  result.timelineEndedEndDate = endDate;

  return result;
};

export const buildTimelineCondition = (settings: WidgetSettings): DeepPartial<ProjectReportCondition> => {
  if (
    ![AnalyticsWidgetType.TIMELINE, AnalyticsWidgetType.PIPELINE, AnalyticsWidgetType.FUNNEL].includes(
      settings.widgetType
    )
  ) {
    return {};
  }

  const result: DeepPartial<ProjectReportCondition> = {};

  if (settings.module === AnalyticsModule.PROJECTS && settings.widgetType === AnalyticsWidgetType.TIMELINE) {
    result.includedTimelineStatuses = settings.includedTimelineStatuses;
  }

  const isStatus = STATUS_LIFECYCLE_WORKFLOWS.includes(settings.workflowId.toString());

  result.withStatusTimeline = isStatus;
  result.withStageTimeline = !isStatus;

  if (settings.widgetType !== AnalyticsWidgetType.TIMELINE) {
    return result;
  }

  return {
    ...result,
    ...buildTimelineDateRangeCondition(settings)
  };
};

const buildTimeGroupBy = (settings: WidgetSettings): ProjectReportGroupBy => {
  if (!settings.submeasureId) {
    return TIME_GRANULARITY_TO_CREATED_AT_GROUP_BY[settings.subdimensionId as TimeGranularity];
  }

  const isStatus = settings.submeasureId.toString().startsWith('status_');
  const isEntered = settings.submeasureTypeId === AnalyticsSubmeasureType.ENTERED;

  if (isStatus) {
    return isEntered
      ? TIME_GRANULARITY_TO_TIMELINE_STATUS_STARTED_AT_GROUP_BY[settings.subdimensionId as TimeGranularity]
      : TIME_GRANULARITY_TO_TIMELINE_STATUS_ENDED_AT_GROUP_BY[settings.subdimensionId as TimeGranularity];
  }

  return isEntered
    ? TIME_GRANULARITY_TO_TIMELINE_STAGE_STARTED_AT_GROUP_BY[settings.subdimensionId as TimeGranularity]
    : TIME_GRANULARITY_TO_TIMELINE_STAGE_ENDED_AT_GROUP_BY[settings.subdimensionId as TimeGranularity];
};

const buildDropdownPropertyGroupBy = (settings: WidgetSettings): ProjectReportGroupBy => {
  const propertyId = parseInt(settings.subdimensionId.split('_')[1], 10); // property_{id}

  if (propertyId < 0) {
    return STANDARD_DROPDOWN_PROPERTY_ID_TO_GROUP_BY[propertyId];
  }

  return ProjectReportGroupBy.TextValue;
};

const groupByMap: Record<
  RecordDimensionType,
  ProjectReportGroupBy | ((settings: WidgetSettings) => ProjectReportGroupBy)
> = {
  [DimensionType.TIME]: buildTimeGroupBy,
  [DimensionType.DROPDOWN_PROPERTY]: buildDropdownPropertyGroupBy,
  [DimensionType.PRODUCT_CATEGORY]: ProjectReportGroupBy.ProductName,
  [DimensionType.CREATOR]: ProjectReportGroupBy.CreatedBy,
  [DimensionType.OWNER]: ProjectReportGroupBy.OwnerId,
  [DimensionType.PROJECT_MANAGER]: ProjectReportGroupBy.ProjectManagerId,
  [DimensionType.SALES_REP]: ProjectReportGroupBy.SalesRepId
};

const buildGroupBy = (settings: WidgetSettings): ProjectReportGroupBy => {
  const groupBy = groupByMap[settings.dimensionId as RecordDimensionType];

  if (typeof groupBy === 'function') {
    return groupBy(settings);
  }

  return groupBy;
};

const buildWithSubmeasureRequestParams = ({
  companyId,
  settings,
  propertiesMap,
  calculatePreviousPeriod
}: BuildRequestArgs): RecordRequestParams => {
  const filter = {
    ...buildCommonFilters(companyId, settings, propertiesMap)
  };

  const condition = {
    ...buildSubmeasureCondition(settings, calculatePreviousPeriod),
    ...buildCommonCondition(settings)
  };

  return {
    filter,
    condition,
    groupBy: buildGroupBy(settings)
  };
};

const buildWithoutSubmeasureRequestParams = ({
  companyId,
  settings,
  propertiesMap,
  calculatePreviousPeriod
}: BuildRequestArgs): RecordRequestParams => {
  const filter = {
    ...buildCommonFilters(companyId, settings, propertiesMap),
    ...buildCreateAtDateRangeFilter(settings, calculatePreviousPeriod)
  };

  const condition = {
    ...buildCommonCondition(settings)
  };

  return {
    filter,
    condition,
    groupBy: buildGroupBy(settings)
  };
};

export const buildRequestParams = ({
  companyId,
  settings,
  propertiesMap,
  calculatePreviousPeriod
}: BuildRequestArgs) => {
  if (settings.submeasureId) {
    return buildWithSubmeasureRequestParams({ companyId, settings, propertiesMap, calculatePreviousPeriod });
  }

  return buildWithoutSubmeasureRequestParams({ companyId, settings, propertiesMap, calculatePreviousPeriod });
};
