import React, { useMemo, useState, forwardRef, useCallback } from 'react';
import DatePicker from 'react-datepicker';
import { Clock, Calendar, X, ChevronLeft, ChevronRight } from 'react-feather';
import { useField, useFormikContext, FieldProps } from 'formik';
import { TextFieldProps } from 'formik-material-ui';
import { FormControlLabel, Checkbox, InputAdornment } from '@material-ui/core';
import { noop, isNull } from 'lodash';
import { isValidDate, formatDate, setHoursToNoon, isNoon } from '@utils';
import { colors } from '@styles';
import { InputLabel, IconButton, TextField } from '@common/ui';
import { Wrapper, Row, DatePickerWrapper, DatePickerFooter, useStylesCheckbox, TimeSelector, ErrorMsg } from './styled';
import moment from 'moment';

type FormikSpecificProps = {
  name: string;
};

type DateFieldType = {
  label?: string;
  className?: string;
  showTimeSelect?: boolean;
  showClearButton?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  value?: any;
  values?: any;
  isOpen?: boolean;
  onOpen?: () => unknown;
  onClose?: () => unknown;
  withPortal?: boolean;
  placeholder?: string;
  selectsRange?: boolean;
  overwrites: Omit<DateFieldType, 'overwrites'>;
  defaultNoon?: boolean;
  allDayFieldName?: string;
};

type DateFieldValue = Date & {
  isHoursDirty?: boolean;
};

const DATE_WITHOUT_TIME = 'MM/dd/yy';
const DATE_WITHIN_TIME = 'MM/dd/yy, hh:mm a';

type DateFieldBaseProps = Partial<TextFieldProps> & DateFieldType & Partial<FormikSpecificProps>;

export const DateFieldBase: React.FC<DateFieldBaseProps> = (props) => {
  const {
    isOpen,
    name,
    label,
    className,
    showTimeSelect,
    showClearButton = true,
    closeOnSelect = false,
    onChange,
    setFieldValue = noop,
    setFieldTouched = noop,
    value,
    values = {},
    onOpen,
    onClose,
    meta = {},
    withPortal,
    disabled,
    placeholder = 'Select due date…',
    selectsRange,
    overwrites,
    defaultNoon = false,
    allDayFieldName,
  } = props;

  const [visibleTimeSelect, setVisibleTimeSelect] = useState(value && !values[allDayFieldName]);
  const [visibleTimeList, setVisibleTimeList] = useState(false);

  const handleClose = () => {
    setVisibleTimeList(false);
    onClose?.();
  };

  const classesCheckbox = useStylesCheckbox();

  const dateFormat =
    (visibleTimeSelect || (value && value.isHoursDirty)) && showTimeSelect ? DATE_WITHIN_TIME : DATE_WITHOUT_TIME;

  const handleOnChange = ($value: Date, event: any = { target: {} }) => {
    const date = defaultNoon && !visibleTimeSelect && $value && !isNoon($value) ? setHoursToNoon($value) : $value;

    const clonedValue: DateFieldValue | null = isValidDate(date) ? new Date(date) : null;

    if (!selectsRange && date && visibleTimeSelect && date.getHours() === 12 && clonedValue) {
      clonedValue.isHoursDirty = true;
    }

    setVisibleTimeList(false);
    setFieldValue(name, selectsRange ? date : clonedValue);
    if (allDayFieldName) {
      setFieldValue(allDayFieldName, !visibleTimeSelect)
    }
    onChange?.({
      ...event,
      target: {
        ...event.target,
        name,
        value: date
      }
    });

    if (onClose && closeOnSelect) {
      onClose();
    }

    // TODO: error message will appears for any value on the first change
    //   setFieldTouched(name, true);
  };

  const Container = ({ children }: { children: React.ReactNode }) => {
    return (
      <DatePickerWrapper visibleTimeList={visibleTimeList}>
        {children}
        <DatePickerFooter show={showTimeSelect}>
          <FormControlLabel
            label="Add time"
            control={
              <Checkbox
                classes={classesCheckbox}
                checked={visibleTimeSelect}
                onChange={() => {
                  if (allDayFieldName) {
                    setFieldValue(allDayFieldName, visibleTimeSelect)
                  }
                  if (value) {
                  setFieldValue(
                    name,
                    moment(value)
                      .set({
                        hour: 12,
                        minute: 0
                      })
                      .toDate()
                  );
                }
                  setVisibleTimeSelect(!visibleTimeSelect);
                }}
                style={{ color: colors.gray }}
              />
            }
          />
          <TimeSelector
            isVisibleTimeSelect={visibleTimeSelect}
            isVisibleTimeList={visibleTimeList}
            onClick={() => setVisibleTimeList(!visibleTimeList)}
          >
            <Clock size={16} color={colors.gray} />
            {formatDate(value, 'hh:mm a')}
          </TimeSelector>
        </DatePickerFooter>
      </DatePickerWrapper>
    );
  };

  const CustomInput = useMemo(
    () =>
      forwardRef<
        HTMLInputElement,
        {
          value: Date;
          onClick: React.MouseEventHandler;
        }
      >(({ value, onClick }, ref) => {
        const formattedValue = selectsRange
          ? [props.overwrites.startDate, props.overwrites.endDate]
              .map((value) => formatDate(value, 'MM/DD'))
              .filter(Boolean)
              .join(' — ')
          : value;

        return (
          <TextField
            className="date-field-input"
            onClick={onClick}
            ref={ref}
            value={formattedValue}
            variant="outlined"
            placeholder={placeholder}
            InputProps={
              selectsRange
                ? {
                    startAdornment: (
                      <InputAdornment position="start">
                        <Calendar />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        {showClearButton && value && (
                          <IconButton
                            size="small"
                            onClick={(event) => {
                              event.stopPropagation();
                              overwrites.onChange(null);
                            }}
                          >
                            <X />
                          </IconButton>
                        )}
                      </InputAdornment>
                    )
                  }
                : {
                    endAdornment: (
                      <InputAdornment position="end">
                        {showClearButton && value && (
                          <IconButton className="clear-calendar-value" size="small" onClick={(e) => {
                              handleOnChange(null);
                              onClose?.();
                            }
                          }>
                            <X />{' '}
                          </IconButton>
                        )}
                        <Calendar />
                      </InputAdornment>
                    )
                  }
            }
          />
        );
      }),
    [selectsRange, value, props.overwrites]
  );

  const CustomInputRendered = useMemo(() => <CustomInput />, [CustomInput]);

  return (
    <Wrapper className={className} disabled={disabled}>
      {label && (
        <Row>
          <InputLabel htmlFor={name}>{label}</InputLabel>
          {name === 'endDate' && values && (
            <FormControlLabel
              control={
                <Checkbox
                  classes={classesCheckbox}
                  checked={values.startDate !== null}
                  data-analyticsid="addStartDateCheckbox"
                  onChange={() => {
                    if (values.startDate === null) {
                      setFieldValue('startDate', setHoursToNoon(new Date()));
                    } else {
                      setFieldValue('startDate', null);
                    }
                  }}
                  style={{ color: colors.gray }}
                />
              }
              label="Add start date"
              style={{ margin: '-10px 0 0 auto' }}
            />
          )}
        </Row>
      )}
      <DatePicker
        open={isOpen}
        name={name}
        selected={(!disabled && isValidDate(value) && new Date(value)) || null}
        dateFormat={dateFormat}
        popperPlacement="top-end"
        showTimeSelect={showTimeSelect}
        timeFormat="hh:mm a"
        calendarContainer={Container}
        onChange={handleOnChange}
        onCalendarClose={handleClose}
        onCalendarOpen={onOpen}
        onClickOutside={(e) => {
          // prevent calling closing on clicking "Clear" value
          // otherwise it calles onclose and afterthat onChange is called
          if (!e.target.closest('.clear-calendar-value')) {
            onClose?.(e)
          }
        }}
        portalId={withPortal ? 'portal_id' : undefined}
        disabled={disabled}
        customInput={CustomInputRendered}
        previousMonthButtonLabel={<ChevronLeft />}
        nextMonthButtonLabel={<ChevronRight />}
        selectsRange={selectsRange}
        {...(overwrites || {})}
      />
      {meta.error && meta.touched && <ErrorMsg>{(meta.touched && meta.error) || ''}</ErrorMsg>}
    </Wrapper>
  );
};

export type DateFieldProps = Omit<TextFieldProps, keyof FieldProps> & DateFieldType & FormikSpecificProps;
export const DateField: React.FC<DateFieldProps> = (props) => {
  const { setFieldValue, values, setFieldTouched } = useFormikContext<{
    startDate: DateFieldValue | undefined | null;
  }>();
  const [{ value }, meta] = useField(props);

  return (
    <DateFieldBase
      {...props}
      setFieldValue={setFieldValue}
      setFieldTouched={setFieldTouched}
      values={values}
      value={value}
      meta={meta}
    />
  );
};

interface DateFieldRangeProps {
  onChange: (values: [Date | null, Date | null]) => unknown;
}

export const DateFieldRange: React.FC<DateFieldRangeProps> = (props) => {
  const { onChange } = props;
  const [dates, setDates] = useState([null, null]);

  const handleOnChange = useCallback(
    (values) => {
      if (isNull(values)) {
        setDates([null, null]);
      } else {
        setDates(values);
      }

      onChange(values);
    },
    [onChange]
  );

  const onEndDateSelectionState = dates[0] && !dates[1];

  return (
    <DateFieldBase
      isOpen={onEndDateSelectionState ? true : undefined}
      placeholder="Select dates"
      overwrites={{
        selected: dates[0],
        startDate: dates[0],
        endDate: dates[1],
        onChange: handleOnChange
      }}
      selectsRange
      withPortal
    />
  );
};
