import { ModalBody } from '@common/PromiseModal';
import React from 'react';
import styled from 'styled-components';

export const Container = styled(ModalBody)`
  width: 750px;
  max-width: 60vw;
`;

export const EmojiAndName = styled.div`
  display: flex;
  gap: 40px;

  > * {
    &:first-child {
      width: 80px;
      min-width: 80px;
      flex-shrink: 0;

      input {
        font-size: 20px;
      }
    }
    &:last-child {
      flex: 1;
    }
  }
`;

const Menu = styled.div`
  && {
    box-shadow: 0px 0px 16px 0px rgba(0, 150, 136, 0.12);

    width: 256px;
    height: 176px;
    background-color: #fff;
    border-radius: 4px;

    ul {
      display: flex;
      flex-wrap: wrap;
      padding: 8px;

      li {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        margin: 0;
      }
    }
  }
`;

export const EmojiList = React.forwardRef((props, ref) => (
  <Menu {...props} ref={ref as React.RefObject<HTMLDivElement>} />
));

export const AccessFieldContainer = styled.div`
  .MuiFormControlLabel-root {
    align-items: flex-start;
  }
  .MuiFormControlLabel-label {
    flex: 1;
  }
`;

export const RadioLabel = styled.div`
  padding-top: 12px;
`;

export const SharedRadioLabel = styled(RadioLabel)`
  padding-bottom: 8px;
`;

export const SharedWithGroupTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
  padding: 0 12px;
  color: #828d9a;
`;

export const TeamOrRoleChip = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 1.25;
  color: #1d1d35;
  height: 24px;
  margin: 4px 2px;
  padding: 4px;
  border-radius: 4px;
  background-color: #f2f4f6;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const TeamOrRoleChipRemove = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #98a9bc;
  color: #f2f4f6;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
`;
