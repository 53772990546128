import React from 'react';
import { AlertTriangle } from 'react-feather';

import { Container, Title, Description } from './styled';

interface Props {
  title: string;
  children?: React.ReactNode;
}

export const Warning = ({ title, children }: Props) => {
  return (
    <Container isCenterAligned={!children}>
      <AlertTriangle size="20px" color="#FFBD13" />

      <Description>
        <Title>{title}</Title>

        {Boolean(children) && <div>{children}</div>}
      </Description>
    </Container>
  );
};
