// fill all emoji here
export const SMART_VIEW_EMOJIES = [
  '🎯',
  '🌞',
  '💸',
  '👀',
  '⏳',
  '📞',
  '📫',
  '🧡',
  '🏁',
  '📈',
  '📉',
  '🚀',
  '👍',
  '👎',
  '🔥',
  '🤝️',
  '🚚',
  '🏆',
  '🥇',
  '🥈',
  '🥉',
  '🎉',
  '❗',
  '📊'
];

export const emoji = [
  {
    id: 1,
    name: '🎯'
  },
  {
    id: 2,
    name: '🌞'
  },
  {
    id: 3,
    name: '💸'
  },
  {
    id: 4,
    name: '👀'
  },
  {
    id: 5,
    name: '⏳'
  },
  {
    id: 6,
    name: '📞'
  },
  {
    id: 7,
    name: '📫'
  },
  {
    id: 8,
    name: '🧡'
  },
  {
    id: 9,
    name: '🏁'
  },
  {
    id: 10,
    name: '📈'
  },
  {
    id: 11,
    name: '📉'
  },
  {
    id: 12,
    name: '🚀'
  },
  {
    id: 13,
    name: '👍'
  },
  {
    id: 14,
    name: '👎'
  },
  {
    id: 15,
    name: '🔥'
  },
  {
    id: 16,
    name: '🤝️'
  },
  {
    id: 17,
    name: '🚚'
  },
  {
    id: 18,
    name: '🏆'
  },
  {
    id: 19,
    name: '🥇'
  },
  {
    id: 20,
    name: '🥈'
  },
  {
    id: 21,
    name: '🥉'
  },
  {
    id: 22,
    name: '🎉'
  },
  {
    id: 23,
    name: '❗'
  }
];
