import { UiSizes, UiSizesValues } from '@enums';
import { defaultTheme } from '@themes';
import { getColorWithOpacity } from '@styles';

interface SelectProps {
  size?: UiSizes;
  noBorder?: boolean;
}

export const selectMuiNew = (props: SelectProps = {}) => {
  const { size = UiSizes.Medium, noBorder = false } = props;

  // temp
  const isFilter = false;

  return {
    // .MuiSelect-root Styles applied to the select component root class.
    root: {
      display: 'flex',
      alignItems: 'center',
      height: `${UiSizesValues[size]}px`,
      minHeight: `${UiSizesValues[size]}px`,
      padding: '0 10px',
      borderRadius: '4px',
      '&$disabled': {
        color: getColorWithOpacity(defaultTheme.colors.black, 40),
        backgroundColor: 'transparent',
        '& ~ .MuiOutlinedInput-notchedOutline': {
          borderColor: defaultTheme.colors.gray3
        }
      },
      '&:before': {
        display: isFilter ? 'inline-block' : 'none',
        // content: `'${placeholder}'`,
        fontSize: '12px',
        lineHeight: '1.25',
        fontFamily: defaultTheme.fonts.primary,
        fontStyle: isFilter ? 'normal' : 'italic',
        fontWeight: isFilter ? '500' : '400',
        whiteSpace: 'nowrap',
        color: defaultTheme.colors.gray,
        opacity: 1,
        marginRight: '5px'
      }
    },

    // .MuiSelect-select Styles applied to the select component select class.
    select: {
      font: `500 12px/1.25 ${defaultTheme.fonts.primary}`,
      backgroundColor: defaultTheme.colors.white,
      '&:focus': {
        borderRadius: '4px',
        backgroundColor: defaultTheme.colors.white
      },
      '& > svg, & > img': {
        marginRight: '10px'
      }
    },

    // .MuiSelect-filled	Styles applied to the select component if variant="filled".
    filled: {},

    // .MuiSelect-outlined	Styles applied to the select component if variant="outlined".
    outlined: {
      '&[aria-expanded="true"] ~ .MuiOutlinedInput-notchedOutline': {
        borderColor: defaultTheme.colors.green,
        borderWidth: noBorder ? '0' : '1px'
      },

      '&[aria-disabled="true"]': {
        pointerEvents: 'none',

        '& > img, & > svg': {
          opacity: '0.4'
        }
      },

      '& ~ .MuiOutlinedInput-notchedOutline': {
        borderColor: defaultTheme.colors.gray3,
        borderWidth: noBorder ? '0' : '1px',
        transition: '0.3s ease-in-out'
      },

      '&:hover, &:focus': {
        '& ~ .MuiOutlinedInput-notchedOutline': {
          borderColor: defaultTheme.colors.gray,
          borderWidth: noBorder ? '0' : '1px'
        }
      },

      '&:hover': {
        '& ~ .MuiInputAdornment-root': {
          opacity: 1
        }
      },

      '&.Mui-error': {
        '&:hover, &:focus': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: defaultTheme.colors.red,
            borderWidth: noBorder ? '0' : '1px'
          }
        }
      },

      '& ~ .MuiInputAdornment-root': {
        marginRight: '10px',
        position: 'absolute',
        right: '25px',
        opacity: 0,
        '&:hover': {
          opacity: 1,
          '& ~ .MuiOutlinedInput-notchedOutline': {
            borderColor: defaultTheme.colors.gray
          }
        }
      },

      '& .MuiInputBase-inputAdornedEnd': {
        paddingRight: '64px'
      }
    },

    // .MuiSelect-selectMenu	Styles applied to the select component selectMenu class.
    selectMenu: {},

    // .Mui-disabled	Pseudo-class applied to the select component disabled class.
    disabled: {
      cursor: 'not-allowed'
    },

    // .MuiSelect-icon	Styles applied to the icon component.
    icon: {
      width: '28px',
      height: '28px',
      top: 'calc(50% - 14px)',
      right: '8px',
      padding: '4px',
      borderRadius: '20px',
      color: defaultTheme.colors.gray2,
      '&$disabled': {
        opacity: '0.4'
      },
      '&:hover, &:focus': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)'
      }
    },

    // .MuiSelect-iconOpen	Styles applied to the icon component if the popup is open.
    iconOpen: {},

    // .MuiSelect-iconFilled	Styles applied to the icon component if variant="filled".
    iconFilled: {},

    // .MuiSelect-iconOutlined	Styles applied to the icon component if variant="outlined".
    iconOutlined: {},

    // .MuiSelect-nativeInput	Styles applied to the underlying native input component.
    nativeInput: {}
  };
};
