import { Button } from '@kit/ui/Button';
import { colors, scroll } from '@styles';
import styled from 'styled-components';

export const FormField = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;

  > *:last-child {
    width: 100%;
  }
`;

export const FieldLabel = styled.div`
  color: #828d9a;
  font-size: 12px;
  font-weight: 400;
  width: 48px;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`;

export const BodyWithActionButtons = styled.div<{ size: 'small' | 'default' }>`
  position: relative;

  .ql-editor {
    min-height: ${({ size }) => (size === 'small' ? '40px' : '120px')};
    word-break: break-word;
  }
`;

export const ActionButtons = styled.div<{ size: string; isBodyWithError: boolean }>`
  position: absolute;
  z-index: 1;
  bottom: ${({ isBodyWithError, size }) => {
    if (isBodyWithError) {
      return '26px';
    }

    if (size === 'small') {
      return '6px';
    }

    return '8px';
  }};
  right: ${({ size }) => (size === 'small' ? '4px' : '12px')};

  display: flex;
  align-items: center;
  gap: 8px;
`;

export const SendIconButton = styled.button`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: ${colors.green};
  cursor: pointer;
  border: 0;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2px;

  svg {
    margin-left: -2px;
    rotate: 40deg;
  }
`;

export const ExpandButton = styled(Button)``;

export const ExpandedContainer = styled.div`
  max-width: 1124px;
  width: 75vw;
  padding: 24px;
  background-color: #f7f7fc;
`;

export const CommentList = styled.div`
  max-height: 300px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  ${scroll};

  > *:first-child > * {
    padding-top: 0;
    border-top: 0;
  }

  > * {
    padding-left: 0;
    border-right: 0;
  }
`;
