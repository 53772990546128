import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { ButtonVariant } from '@kit/ui/Button';
import { Plus } from 'react-feather';
import { v4 as uuid } from '@lukeed/uuid';
import { AnalyticsModule, FilterGroupOperator, WidgetFilters } from '@features/Analytics/types';
import { useEffectOnceBy } from '@hooks/useEffectOnceBy';
import { useUpdateEffect } from '@react-hookz/web';
import { FilterGroup } from './FilterGroup';
import { FilterTree } from './types';
import { AddButton, Separator } from './styled';
import { mapFormFilterTreeToWidgetFilters, mapWidgetFiltersToFormFilterTree } from './helpers';
import { useFilterOptions } from './useFilterOptions';

interface Props {
  initialFilters?: WidgetFilters;
  module: AnalyticsModule;
  onChange: (filters: WidgetFilters) => void;
}

export const Filters = forwardRef<
  {
    getFilters: () => WidgetFilters;
    resetFilters: () => void;
  },
  Props
>(({ initialFilters, module, onChange }, ref) => {
  const { options: filterFieldOptions, isLoading } = useFilterOptions(module);

  const [root, setRoot] = useState<FilterTree>();

  useEffectOnceBy(
    () => {
      if (!isLoading) {
        if (initialFilters) {
          setRoot(mapWidgetFiltersToFormFilterTree(initialFilters, filterFieldOptions));
        }
      }
    },
    () => !isLoading,
    [initialFilters, filterFieldOptions]
  );

  const handleAddFilterToRoot = useCallback(() => {
    if (!root) {
      setRoot({ id: uuid(), operator: FilterGroupOperator.AND, children: [{ id: uuid() }] });
    } else {
      setRoot((root) => ({ ...root, children: [...root.children, { id: uuid() }] }));
    }
  }, [root]);

  const handleChange = useCallback((updated: FilterTree) => {
    setRoot(updated);
  }, []);

  const handleResetFilters = useCallback(() => {
    setRoot(undefined);
  }, []);

  useImperativeHandle(ref, () => ({
    getFilters: () => (root ? mapFormFilterTreeToWidgetFilters(root) : undefined),
    resetFilters: () => setRoot(undefined)
  }));

  useUpdateEffect(() => {
    onChange(root ? mapFormFilterTreeToWidgetFilters(root) : undefined);
  }, [root, onChange]);

  return (
    <div>
      {root && <Separator className="separator" />}

      {root && (
        <FilterGroup module={module} onRemove={handleResetFilters} isRoot group={root} onChange={handleChange} />
      )}

      <AddButton variant={ButtonVariant.Flat} onClick={handleAddFilterToRoot}>
        <Plus size={16} />
        Add filter
      </AddButton>
    </div>
  );
});
