import styled from 'styled-components';
import { colors, datepicker, fonts } from '@styles';

export const Container = styled.div`
  width: 100%;
  &&& {
    .MuiInputBase-root,
    input {
      cursor: pointer;
    }

    .date-field-input .MuiOutlinedInput-notchedOutline {
      border-color: #dfdfe8;
      transition: border-color 0.15s ease-in-out;
    }

    .date-field-input:hover .MuiOutlinedInput-notchedOutline {
      border-color: ${colors.green};
    }

    .dateTimePickerPopper {
      z-index: 2;
    }
  }
`;

export const DatePickerWrapper = styled.div<{ visibleTimeList?: boolean }>`
  box-shadow:
    0px 5px 5px -3px #00000033,
    0px 8px 10px 1px #00000024,
    0px 3px 14px 2px #0000001f;

  ${datepicker};
`;

export const TimePickerWrapper = styled.div`
  ${datepicker};
  padding: 0;

  .react-datepicker {
    &__time-container {
      display: block;
    }
  }
`;

export const DatePickerFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  color: #828d9a;

  label {
    margin-left: 0 !important;
    margin-right: auto;
  }

  .react-datepicker__input-container input {
    width: 87px;
    border: 1px solid #dfdfe8;
    border-radius: 4px;
    color: ${colors.black};
    text-transform: lowercase;
    font-size: 14px;
    font-weight: 500;
    font-family: ${fonts.primary};
    padding: 7.5px 12px;
    cursor: pointer;

    &:hover {
      border-color: ${colors.green};
    }

    &:focus-visible {
      outline: ${colors.green} auto 1px;
    }
  }
`;

export const Dash = styled.div`
  padding: 0 8px;
`;
