import { TextFieldFormik } from '@common/ui';
import { colors } from '@styles';
import styled from 'styled-components';
import { EditableFieldContainer } from '../styled';

export const TitleField = styled(TextFieldFormik)`
  && {
    .MuiInputBase-input {
      font-size: 24px;
      height: 46.5px;
      padding: 0 13px;
      letter-spacing: normal;

      &::placeholder {
        font-size: 20px;
        color: ${colors.gray};
        font-weight: 500;
        font-style: normal;
      }
    }
  }
`;

export const TitleView = styled(EditableFieldContainer)`
  font-size: 24px;
  font-weight: 500;
  margin-top: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;

  @media (max-width: 767px) {
    white-space: initial;
  }
`;
