import { Property } from '@types';

export enum FilterOperators {
  Like = 'Like',
  EqualTo = 'EqualTo',
  NotEqualTo = 'NotEqualTo',
  In = 'In',
  NotIn = 'NotIn',
  LessThan = 'LessThan',
  GreaterThan = 'GreaterThan',
  GreaterThanOrEqualTo = 'GreaterThanOrEqualTo',
  LessThanOrEqualTo = 'LessThanOrEqualTo',
  Contains = 'Contains',
  ContainsLike = 'ContainsLike',
  NotContains = 'NotContains',
  ContainedBy = 'ContainedBy',
  NotContainedBy = 'NotContainedBy',
  Before = 'Before',
  After = 'After',
  Within = 'Within',
  NotWithin = 'NotWithin',
  IsEmpty = 'IsEmpty',
  IsNotEmpty = 'IsNotEmpty'
}

export const enum LogicOp {
  AND = 'AND',
  OR = 'OR'
}

export type ConditionFormFields = {
  op?: FilterOperators;
  property: Property;
} & (
  | {
      task: number;
      value: number[];
    }
  | {
      stage: number;
      value: string;
    }
  | {
      value: string;
    }
);

export type Condition = ConditionFormFields & {
  property: Partial<Property>;
};

export type ConditionTree = {
  logicOp: LogicOp;
  children: (ConditionTree | Condition)[];
};
