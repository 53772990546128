import { ReactQueryKey } from '@enums';
import { Label } from '@generated/types/graphql';
import { useAppSelector } from '@hooks/store';
import { postGraphql } from '@services/api/base/graphql';
import { selectWorkspaceId } from '@state/selectors';
import { apiErrorHandler } from '@utils/api';
import { gql } from 'graphql-request';
import { useQuery } from 'react-query';

export const useLabelList = () => {
  const companyId = useAppSelector(selectWorkspaceId);

  return useQuery(
    [ReactQueryKey.LabelList, companyId],
    async () => {
      try {
        return (
          await postGraphql<{ labels: Label[] }>(
            gql`
              query LABELS_QUERY($companyId: Int!) {
                labels(
                  filter: { companyId: { equalTo: $companyId }, isActive: { equalTo: true } }
                  orderBy: [LABEL_ASC]
                ) {
                  id
                  label
                  color

                  createdByUser {
                    id
                    firstName
                    lastName
                    email
                  }
                }
              }
            `,
            { companyId }
          )
        ).labels;
      } catch (error) {
        throw apiErrorHandler('Error fetching Labels', error);
      }
    },
    {
      refetchOnMount: false
    }
  );
};
