import { Action, ActionStatus } from '@generated/types/graphql';
import React, { useCallback, useState } from 'react';
import { Popover } from '@kit/ui/Popover';
import { ChevronUpIcon } from '@kit/ui/icons/ChevronUp';
import { ChevronDownIcon } from '@kit/ui/icons/ChevronDown';
import { ACTION_ITEM_STATUS_COLORS } from '@styles';
import { ACTION_STATUS_LABELS, ActionItemStatus } from '@kit/components/ActionItemStatus';
import { useUpdateActionItem } from '@hooks/actionItems/useUpdateActionItem';
import { ArrowRight } from 'react-feather';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { useModal } from '@common/PromiseModal';
import { Container, SplitArrow, SplitButton, StatusText, TransitionItem, Transitions } from './styled';
import { RequestAgain } from './RequestAgain';

const POSSIBLE_TRANSITIONS: Record<ActionStatus, ActionStatus[]> = {
  [ActionStatus.Requested]: [ActionStatus.InReview, ActionStatus.Completed],
  [ActionStatus.InReview]: [ActionStatus.Completed],
  [ActionStatus.Completed]: [],
  [ActionStatus.Cancelled]: []
};

interface Props {
  action: Action;
  onDescriptionUpdated?: (description: string) => void;
}

export const StatusButton = ({ action, onDescriptionUpdated }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { status } = action;
  const currentStatusColors = ACTION_ITEM_STATUS_COLORS[status];

  const { openModal } = useModal();

  const isReadOnly = POSSIBLE_TRANSITIONS[status].length === 0;

  const { mutateAsync: update, isLoading } = useUpdateActionItem();

  const handleChangeStatus = useCallback(
    (newStatus: ActionStatus) => {
      if (isLoading) {
        return;
      }
      update({ id: action.id, dto: { status: newStatus } });
      setIsOpen(false);
    },
    [update, action, isLoading]
  );

  const handleRequestAgain = useCallback(async () => {
    const newDescription = await openModal<string>(
      ({ onClose }) => <RequestAgain action={action} onClose={onClose} />,
      { title: 'Request Docs Upload again' }
    );

    onDescriptionUpdated?.(newDescription);
  }, [action, onDescriptionUpdated, openModal]);

  if (status === ActionStatus.InReview) {
    return (
      <Container>
        <SplitButton color={currentStatusColors.color} background={currentStatusColors.background}>
          <StatusText>{ACTION_STATUS_LABELS[status]}</StatusText>
        </SplitButton>

        <ArrowRight size="16px" color="#9C9CAA" />

        <Button variant={ButtonVariant.Primary} onClick={() => handleChangeStatus(ActionStatus.Completed)}>
          Approve
        </Button>

        <Button variant={ButtonVariant.Danger} onClick={handleRequestAgain}>
          Request again
        </Button>
      </Container>
    );
  }

  return (
    <Container>
      <SplitButton color={currentStatusColors.color} background={currentStatusColors.background}>
        {!isReadOnly && (
          <Popover
            open={isOpen}
            onClose={() => setIsOpen(false)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            content={
              <Transitions>
                {POSSIBLE_TRANSITIONS[status].map((newStatus) => (
                  <TransitionItem key={newStatus} onClick={() => handleChangeStatus(newStatus)}>
                    <ActionItemStatus status={newStatus} />
                  </TransitionItem>
                ))}
              </Transitions>
            }
          >
            <SplitArrow onClick={() => setIsOpen(true)}>
              {isOpen ? <ChevronUpIcon size="16px" /> : <ChevronDownIcon size="16px" />}
            </SplitArrow>
          </Popover>
        )}
        <StatusText>{ACTION_STATUS_LABELS[status]}</StatusText>
      </SplitButton>
    </Container>
  );
};
