import React from 'react';
import { NoResult } from '@kit/components/NoResult';
import { useRecordDetail } from '@hooks/useRecordDetail';
import { Container, Wrapper, Content, ErrorWrapper } from './styled';
import { Overview } from './Overview';
import { RightPanel } from './RightPanel';
import { Header } from './Header';

interface Props {
  requestId: string;
}

export const Request = React.memo(({ requestId }: Props) => {
  const { data: record, isFetching } = useRecordDetail(+requestId);

  if (!isFetching && !record?.id) {
    return (
      <ErrorWrapper>
        <NoResult subject="request" />
      </ErrorWrapper>
    );
  }

  return (
    <Container>
      <Header recordId={+requestId} />
      <Wrapper>
        <Content>
          <Overview projectId={+requestId} />
        </Content>

        <RightPanel projectId={+requestId} />
      </Wrapper>
    </Container>
  );
});
