import { useRecordDetail } from '@hooks/useRecordDetail';
import { RecordType } from '@types';
import { extractStageOccasions, toEndDate, toStartDate, apiErrorHandler, flatPagesData } from '@utils';
import { useInfiniteQuery, QueryFunctionContext } from 'react-query';
import { FeedsConnection, FeedFilter, SmsActivity, CommentScope } from '@generated/types/graphql';
import {
  ReactQueryKey,
  TaskEventType,
  ProjectEventType,
  EntityType,
  EventTypes,
  FileEventType,
  EmailEventType,
  WorkflowEventType,
  PortalEventType,
  ActionEventType
} from '@enums';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { useToast } from '@hooks/useToast';
import { DeepPartial } from 'redux';
import { selectWorkspaceId } from '@state/selectors';
import { cloneDeep } from 'lodash';
import { useAppSelector } from './store';

type Destination = {
  id: number;
  name: string;
  type: 'contact' | 'user' | 'unknown' | 'company-phone';
  phone: string;
};

const DEFAULT_PER_PAGE = 30;
export const AUTOMATION_USER_ID = 'coperniq-automation';
export const RESTRICTED_TYPES = [
  {
    entity: EntityType.TASK,
    event: TaskEventType.ASSIGNED_TO_ME
  },
  {
    entity: EntityType.TASK,
    event: TaskEventType.COMMENT_MENTIONED
  },
  {
    entity: EntityType.PROJECT,
    event: ProjectEventType.COMMENT_MENTIONED
  },
  {
    entity: EntityType.FILE,
    event: FileEventType.COMMENT_MENTIONED
  }
];

type SMSDestination = SmsActivity & { from: Destination; to: Destination };
export const smsAdapter = (smsActivity: SmsActivity) =>
  smsActivity
    ? (['from', 'to'].reduce((acc, prefix) => {
        if (smsActivity[`${prefix}Contact`]) {
          return {
            ...acc,
            [prefix]: {
              id: smsActivity[`${prefix}Contact`].id,
              name: smsActivity[`${prefix}Contact`].name,
              type: 'contact',
              phone: smsActivity[`${prefix}Phone`]
            }
          };
        }

        if (smsActivity[`${prefix}User`]) {
          return {
            ...acc,
            [prefix]: {
              id: smsActivity[`${prefix}User`].id,
              name: `${smsActivity[`${prefix}User`].firstName} ${smsActivity[`${prefix}User`].lastName}`,
              type: 'user',
              phone: smsActivity[`${prefix}Phone`],
              avatarUrl: smsActivity[`${prefix}User`].avatarUrl
            }
          };
        }

        if (smsActivity[`${prefix}CompanyPhone`]) {
          return {
            ...acc,
            [prefix]: {
              id: smsActivity[`${prefix}CompanyPhone`].id,
              name: smsActivity[`${prefix}CompanyPhone`].alias,
              type: 'company-phone',
              phone: smsActivity[`${prefix}Phone`]
            }
          };
        }

        if (smsActivity[`${prefix}Jurisdiction`]) {
          return {
            ...acc,
            [prefix]: {
              id: smsActivity[`${prefix}Jurisdiction`].id,
              name: smsActivity[`${prefix}Jurisdiction`].alias,
              type: 'jurisdiction',
              phone: smsActivity[`${prefix}Phone`]
            }
          };
        }

        return {
          ...acc,
          [prefix]: {
            type: 'unknown',
            name: smsActivity[`${prefix}Phone`],
            phone: smsActivity[`${prefix}Phone`]
          }
        };
      }, smsActivity) as SMSDestination)
    : smsActivity;

export type UseActivityProps = {
  recordId?: number;
  stageIds?: number[];
  types?: {
    entity: EntityType;
    event: EventTypes;
  }[];
  notTypes?: {
    entity: EntityType;
    event: EventTypes;
  }[];
  userIds?: (number | typeof AUTOMATION_USER_ID)[];
  teamIds?: number[];
  startDate?: string;
  endDate?: string;
  propertyIds?: number[];
  search?: string;
  fileId?: number;
  taskId?: number;
  systemId?: number;
  queryKey?: string;
  feedItemId?: number;
  onSuccess?: (data: any) => void;
  parentId?: number;
  equalOrBeforeDate?: string;
  isPortal?: boolean;
};

export const useActivity = ({
  queryKey,
  recordId,
  stageIds = [],
  types = [],
  notTypes = [],
  userIds: $userIds = [],
  teamIds = [],
  startDate,
  endDate,
  propertyIds = [],
  search = '',
  fileId,
  taskId,
  systemId,
  feedItemId,
  onSuccess,
  parentId,
  equalOrBeforeDate,
  isPortal
}: UseActivityProps) => {
  const companyId = useAppSelector(selectWorkspaceId);
  const { data: record } = useRecordDetail(recordId, { refetchOnMount: false });
  const { showError } = useToast();

  const clientId = record?.type === RecordType.ACCOUNT ? recordId : record?.account?.id;

  const userIds = $userIds.filter((id) => id !== AUTOMATION_USER_ID) as number[];
  const withAutomationUser = userIds.length < $userIds.length;

  const projectIdsToFetch =
    record?.type === RecordType.ACCOUNT
      ? record?.deals
          .map(({ id }) => id)
          .concat(record?.projects.map(({ id }) => id))
          .concat([recordId])
      : [recordId];

  const stageIdsDateRanges = stageIds.reduce(
    (acc, stageId) => [...acc, ...extractStageOccasions(stageId, record?.stageUpdates ?? [])],
    []
  );

  const mapDates = (range: { startDate: any; endDate: any }) => ({
    virtualCreatedAt: {
      ...(range.startDate ? { greaterThanOrEqualTo: range.startDate } : {}),
      ...(range.endDate ? { lessThanOrEqualTo: range.endDate } : {})
    }
  });

  const recordFilterForOneProject: DeepPartial<FeedFilter> =
    record?.type === RecordType.ACCOUNT
      ? { projectId: { equalTo: recordId } }
      : {
          or: [
            { projectId: { equalTo: recordId } },
            clientId && {
              projectId: { equalTo: clientId },
              eventType: { equalTo: PortalEventType.COMMUNICATION },
              event: { equalTo: EntityType.PORTAL }
            }
          ].filter(Boolean)
        };

  const recordFilter: DeepPartial<FeedFilter> =
    projectIdsToFetch.length === 1
      ? recordFilterForOneProject
      : {
          or: [
            {
              projectId: { equalTo: recordId }
            },
            {
              projectId: { in: projectIdsToFetch.filter((id) => id !== recordId) },
              eventType: {
                in: [
                  ProjectEventType.CREATED,
                  ProjectEventType.ARCHIVED,
                  TaskEventType.COMPLETED,
                  ProjectEventType.DELETED,
                  EmailEventType.RECEIVED,
                  EmailEventType.SENT,
                  WorkflowEventType.EXECUTED_SUCCESSFULLY,
                  WorkflowEventType.FAILED_TO_EXECUTE,
                  ActionEventType.ACTION_CREATED
                ]
              }
            }
          ]
        };

  const filter: DeepPartial<FeedFilter> = feedItemId
    ? { id: { equalTo: feedItemId } }
    : {
        and: [
          {
            or: propertyIds.map((id) => ({ payload: { contains: { updatedProperties: [{ id }] } } })),
            ...(recordId ? recordFilter : {}),
            ...(fileId ? { fileId: { equalTo: fileId } } : {}),
            ...(taskId ? { event: { equalTo: 'TASK' }, taskId: { equalTo: taskId } } : {}),
            ...(systemId ? { systemId: { equalTo: systemId } } : {}),
            ...(parentId ? { parentId: { equalTo: parentId } } : { parentExists: false }),
            ...{ companyId: { equalTo: companyId } },
            ...(isPortal ? { relatedComment: { scope: { equalTo: CommentScope.PortalCommunication } } } : {}),
            // ...(equalOrBeforeDate ? { virtualCreatedAt: { lessThanOrEqualTo: equalOrBeforeDate } } : {}),
            and: [
              ...(startDate && endDate
                ? [
                    {
                      startDate: toStartDate(startDate),
                      endDate: toEndDate(endDate)
                    }
                  ].map(mapDates)
                : []),
              {
                or: stageIdsDateRanges.map(mapDates)
              }
            ].filter(Boolean),
            createdByUser: {
              or: [
                {
                  ...(teamIds.length ? { userTeams: { some: { teamId: { in: teamIds } } } } : {}),
                  ...(userIds.length ? { id: { in: userIds } } : {})
                },
                {
                  ...(withAutomationUser ? { email: { equalTo: 'automation@coperniq.io' } } : {})
                }
              ]
            }
          },
          notTypes.length > 0
            ? {
                and: notTypes.map((type) => ({
                  not: {
                    event: { equalTo: type.entity },
                    eventType: { equalTo: type.event }
                  }
                }))
              }
            : null,
          {
            and: RESTRICTED_TYPES.map((type) => ({
              not: {
                event: { equalTo: type.entity },
                eventType: { equalTo: type.event }
              }
            }))
          },
          search
            ? {
                or: [
                  {
                    emailMessage: {
                      body: {
                        includesInsensitive: search
                      }
                    }
                  },
                  {
                    smsActivity: {
                      text: {
                        includesInsensitive: search
                      }
                    }
                  },
                  {
                    relatedComment: {
                      comment: {
                        includesInsensitive: search
                      }
                    }
                  }
                ]
              }
            : null
        ].filter(Boolean),
        or: types.map((type) => ({
          event: { equalTo: type.entity },
          eventType: { equalTo: type.event }
        }))
      };

  const QUERY_KEY = [
    ReactQueryKey.ProjectActivity,
    queryKey,
    { fileId, recordId, taskId },
    filter,
    equalOrBeforeDate
  ].filter(Boolean);

  return useInfiniteQuery(
    QUERY_KEY,
    async ({ pageParam }: QueryFunctionContext) => {
      try {
        const offset = 0;

        const filterCopy = cloneDeep(filter);

        if (!pageParam && equalOrBeforeDate) {
          filterCopy.and.push({
            virtualCreatedAt: {
              lessThanOrEqualTo: equalOrBeforeDate
            }
          });
        }

        if (pageParam?.type === 'next') {
          filterCopy.and.push({
            virtualCreatedAt: {
              lessThan: pageParam.date
            }
          });
        }

        if (pageParam?.type === 'prev') {
          filterCopy.and.push({
            virtualCreatedAt: {
              greaterThan: pageParam.date
            }
          });
        }

        const { feedsConnection } = await postGraphql<{ feedsConnection: FeedsConnection }>(
          gql`
            query FEED_QUERY($filter: FeedFilter, $offset: Int, $first: Int, $last: Int) {
              feedsConnection(
                filter: $filter
                offset: $offset
                first: $first
                last: $last
                orderBy: [VIRTUAL_CREATED_AT_DESC]
              ) {
                nodes {
                  companyId
                  createdAt
                  virtualCreatedAt
                  createdBy: createdByUser {
                    id
                    firstName
                    lastName
                    avatarUrl
                    phone
                  }

                  createdByContact {
                    id
                    name
                    portalStatus
                  }
                  parentId
                  call {
                    id
                    fromNumber
                    fromContact {
                      id
                      name
                    }
                    toNumber
                    toContact {
                      id
                      name
                    }
                    isInbound
                    outcome
                    startTime
                    endTime
                    reason
                    disposition
                    note
                    durationInMs
                  }
                  emailMessage {
                    id
                    receivedAt
                    from
                    to
                    cc
                    bcc
                    subject
                    body
                    attachments
                    tracking
                    isOutbox
                    isScheduled
                    originProjectId
                  }
                  event
                  eventType
                  id
                  payload
                  relatedComment {
                    id
                    scope
                    comment
                    createdAt
                    updatedAt
                    createdByType
                    createdByContact {
                      id
                      name
                      portalStatus
                    }
                    type
                    isPinned
                    file {
                      metaData
                      annotations
                    }

                    files {
                      id
                      name
                      metaData
                      downloadUrl
                      type
                      projectId
                    }
                  }

                  task {
                    uid
                  }

                  retryable
                  retryCount
                  retriedAt

                  childFeedsConnection(
                    filter: { eventType: { in: ["COMMENTED", "SENT", "RECEIVED"] } }
                    orderBy: CREATED_AT_ASC
                  ) {
                    nodes {
                      parentId
                      createdAt
                      createdBy: createdByUser {
                        id
                        firstName
                        lastName
                        avatarUrl
                        phone
                      }
                      event
                      eventType
                      id
                      payload
                      relatedComment {
                        id
                        comment
                        createdAt
                        updatedAt
                        type
                        file {
                          metaData
                          annotations
                        }

                        files {
                          id
                          name
                          metaData
                          downloadUrl
                          type
                        }
                      }

                      emailMessage {
                        id
                        receivedAt
                        from
                        to
                        cc
                        bcc
                        subject
                        body
                        attachments
                        tracking
                        isOutbox
                        isScheduled
                        originProjectId
                      }
                    }
                  }
                  smsActivity {
                    text
                    fromPhone
                    fromUser {
                      id
                      firstName
                      lastName
                      avatarUrl
                      phone
                    }
                    fromContact {
                      id
                      name
                      phones
                      emails
                      status
                    }
                    fromCompanyPhone {
                      id
                      alias
                      phone
                    }
                    fromJurisdiction {
                      id
                      name
                      phones
                      emails
                      address
                    }
                    toUser {
                      id
                      firstName
                      lastName
                      avatarUrl
                      phone
                    }
                    toContact {
                      id
                      name
                      phones
                      emails
                      status
                    }
                    toCompanyPhone {
                      id
                      alias
                      phone
                    }
                    toJurisdiction {
                      id
                      name
                      phones
                      emails
                      address
                    }
                    text
                    status
                    isIncoming
                  }
                }
                totalCount
              }
            }
          `,
          {
            filter: filterCopy,
            offset: pageParam?.type === 'prev' ? undefined : offset,
            last: pageParam?.type === 'prev' ? DEFAULT_PER_PAGE : undefined,
            first: pageParam?.type !== 'prev' ? DEFAULT_PER_PAGE : undefined
          }
        );

        return {
          results: feedsConnection.nodes.map((feed) => ({
            ...feed,
            payload: {
              ...feed.payload,
              sms: smsAdapter(feed.smsActivity),
              emailMessage: feed.emailMessage,
              comment: feed.projectComment ?? feed.taskComment ?? feed.fileComment ?? feed.payload?.comment
            }
          })),
          nextPage: { type: 'next', date: feedsConnection.nodes[feedsConnection.nodes.length - 1]?.virtualCreatedAt },
          previousPage: { type: 'prev', date: feedsConnection.nodes[0]?.virtualCreatedAt },
          total: feedsConnection.totalCount
        };
      } catch (error) {
        throw apiErrorHandler('exception on activity fetch.', error);
      }
    },
    {
      enabled: Boolean((recordId && record) || !recordId),
      getPreviousPageParam: (firstPage) => (firstPage.previousPage?.date ? firstPage.previousPage : undefined),
      getNextPageParam: (lastPage) => (lastPage.nextPage?.date ? lastPage.nextPage : undefined),
      onSuccess,
      onError: () => showError("Couldn't fetch activities"),
      select: (data) => {
        const flat = flatPagesData(data);

        return {
          ...data,
          total: (data.pages || [])[0]?.total ?? 0,
          pages: (data.pages ?? []).map(({ results }) => {
            const normalized = results
              // copied from @find-by-project.ts from server
              .filter((item) => {
                if (item.event !== EntityType.EMAIL) {
                  return true;
                }

                // account's threads would include sub-records messages
                // filter them out as it's too hard on sql query level
                const itemPayload = item.payload;
                const hasDuplicateForAccount = flat.some((accountItemCandidate) => {
                  const accountCandidatePayload = accountItemCandidate.payload;

                  return (
                    accountCandidatePayload.emailMessage &&
                    itemPayload.emailMessage &&
                    accountCandidatePayload.emailMessage.id === itemPayload.emailMessage.id &&
                    accountCandidatePayload.project.id === itemPayload.project.parentProjectId
                  );
                });

                return !hasDuplicateForAccount;
              })
              .map((feed) => ({
                ...feed,
                smsActivity: smsAdapter(feed.smsActivity)
              }));

            return { results: normalized };
          })
        };
      }
    }
  );
};
