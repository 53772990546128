import { ReactQueryKey } from '@enums';
import { errorHandler } from '@services/api/helpers';
import { useMutation, useQueryClient } from 'react-query';
import { useToast } from '@hooks/useToast';
import { actionItemApi, CreateActionItemPayload } from '@services/api/actionItemApi';

export const useCreateActionItem = () => {
  const queryClient = useQueryClient();
  const { showSuccess } = useToast();

  return useMutation<number, Error, CreateActionItemPayload>(
    async (req) => {
      try {
        const {
          data: { id }
        } = await actionItemApi.create(req);

        return id;
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: () => {
        showSuccess('Action Item created successfully');
        queryClient.invalidateQueries([ReactQueryKey.ActionItemList]);
        queryClient.invalidateQueries([ReactQueryKey.ProjectActivity]);
      }
    }
  );
};
