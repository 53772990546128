import { ModalBody, ModalFooter } from '@common/PromiseModal';
import { MainUser, TaskStatus, TaskStatusEntity } from '@generated/types/graphql';
import { Button, ButtonVariant } from '@kit/ui/Button';
import React, { useMemo } from 'react';
import { Tool } from 'react-feather';
import { WorkOrderStatus } from '@components/WorkOrder/WorkOrderStatus';
import { useWorkOrderStatuses } from '@hooks/workOrders/useWorkOrderStatuses';
import { useAppSelector } from '@hooks/index';
import { selectCurrentUserId, selectWorkspaceId } from '@state/selectors';
import { Description, Item, ItemTitle, List } from './styled';

interface Props {
  onClose: () => void;
  assignee: MainUser;
  newStatus: TaskStatus;
  workOrders: {
    id: number;
    uid: boolean;
    title: string;
    status: TaskStatus;
  }[];
}

export const ChangeStatusConflict = ({ newStatus, onClose, assignee, workOrders }: Props) => {
  const { data: statuses } = useWorkOrderStatuses();

  const companyId = useAppSelector(selectWorkspaceId);

  const currentUserId = useAppSelector(selectCurrentUserId);

  const isYou = assignee?.id === currentUserId;

  const statusesMap = useMemo(() => {
    if (!statuses) {
      return {} as Record<TaskStatus, TaskStatusEntity>;
    }

    return statuses.reduce(
      (acc, status) => {
        acc[status.id] = status;

        return acc;
      },
      {} as Record<TaskStatus, TaskStatusEntity>
    );
  }, [statuses]);

  // should be only one because it is not possible to have more than one active work order
  const [activeWorkOrder] = workOrders;

  return (
    <>
      <ModalBody width="752px">
        <Description>
          You cannot switch to “{statusesMap[newStatus]?.label}“ because{' '}
          {isYou ? 'you already have' : `${assignee.firstName} ${assignee.lastName} already has`} one Work Order in “
          {activeWorkOrder && statusesMap[activeWorkOrder.status]?.label}” Status:
        </Description>
        <List>
          {activeWorkOrder && (
            <a target="_blank" href={`/${companyId}/scheduler/workOrders/${activeWorkOrder.id}`} rel="noreferrer">
              <Item>
                <ItemTitle>
                  <Tool size="12px" />
                  <div>
                    #{activeWorkOrder.uid}: {activeWorkOrder.title}
                  </div>
                </ItemTitle>
                {statusesMap[activeWorkOrder.status] && (
                  <WorkOrderStatus status={statusesMap[activeWorkOrder.status]} />
                )}
              </Item>
            </a>
          )}
        </List>
        <Description>
          Please complete or update the Status of this Work Order before switching to a new Status.
        </Description>
      </ModalBody>
      <ModalFooter>
        <Button variant={ButtonVariant.Secondary} onClick={onClose}>
          Cancel
        </Button>
      </ModalFooter>
    </>
  );
};
