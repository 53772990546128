import React from 'react';

import { ButtonProps, ButtonVariant, LinkButtonProps, RegularButtonProps } from './types';
import { Anchor, Container, IconButton, LinkContainer } from './styled';

export const Button = ({ variant, type = 'button', ...props }: ButtonProps) => {
  switch (variant) {
    case ButtonVariant.Link: {
      const { to, target } = props as LinkButtonProps;

      if (target === '_blank') {
        return <Anchor href={to} {...(props as LinkButtonProps)} />;
      }

      return <LinkContainer to={to} {...(props as LinkButtonProps)} />;
    }
    default:
      return <Container type={type} variant={variant} {...(props as RegularButtonProps)} />;
  }
};

export { IconButton };
