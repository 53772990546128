import React, { useMemo } from 'react';
import { makeStyles, Switch, SwitchProps } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { SwitcherVariant } from '@common/ui/Switcher/type';
import { switcher } from './styled';

interface SwitcherProps extends SwitchProps {
  variant?: SwitcherVariant
}

export const Switcher: React.FC<SwitcherProps> = (props) => {
  const {
    variant = SwitcherVariant.DEFAULT,
    ...rest
  } = props;

  const makeStylesParam = useMemo(() => (
    switcher()
  ), []);
  const classes = makeStyles(makeStylesParam)();

  return (
    <>
      {variant === SwitcherVariant.DEFAULT && (
        <Switch
          classes={classes}
          disableRipple
          color="primary"
          {...rest}
        />
      )}
      {variant === SwitcherVariant.EYE && (
        <Switch
          classes={classes}
          disableRipple
          color="primary"
          icon={<VisibilityOff />}
          checkedIcon={<Visibility />}
          {...rest}
        />
      )}
    </>
  );
};
