import { System } from '@generated/types/graphql';
import { Form, FormValidationRules, SelectField, useForm } from '@kit/components/Form';
import { Badge, BadgeSize } from '@kit/ui/Badge';
import { Popover } from '@kit/ui/Popover';

import React, { useCallback, useState } from 'react';
import { useFleetList } from '@hooks/workspace/systems/fleets/useFleetList';
import { Skeleton } from '@material-ui/lab';
import { useUpdateSystemsFleet } from '@hooks/systems';
import { useConfirmModal } from '@common/PromiseModal';
import { FieldWrapper } from './styled';

interface Props {
  systemId: number;
  value: System['profile'];
}

interface FormValues {
  fleet: { id: number; name: string };
}

const RULES: FormValidationRules<FormValues> = {
  fleet: {
    isRequired: true
  }
};

export const FleetEditableChip = ({ systemId, value }: Props) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const { data: fleets } = useFleetList();

  const { mutateAsync: updateFleet } = useUpdateSystemsFleet();

  const confirm = useConfirmModal();

  const { form, handleSubmit } = useForm<FormValues>({
    mode: 'onBlur',
    defaultValues: {
      fleet: value
    },
    onSubmit: async ({ fleet }, { reset }) => {
      if (fleet.id === value?.id) {
        return;
      }

      if (!(await confirm({ confirmText: 'Are you sure you want to update the Fleet?\nIt can affect the budget.' }))) {
        reset();

        return;
      }

      setIsUpdating(true);

      await updateFleet({
        systemIds: [systemId],
        fleetId: fleet.id
      });

      setTimeout(() => {
        setIsUpdating(false);
      }, 100);
    }
  });

  const {
    control,
    formState: { isSubmitting }
  } = form;
  const handleChanged = useCallback(() => {
    if (!isUpdating || isSubmitting) {
      handleSubmit();
    }
  }, [handleSubmit, isUpdating, isSubmitting]);

  return (
    <Popover
      onClose={handleChanged}
      content={
        <FieldWrapper>
          <Form rules={RULES} onSubmit={handleSubmit}>
            <SelectField
              control={control}
              name="fleet"
              options={fleets}
              clearOnUnmount={false}
              isClearable={false}
              getOptionLabel={(option) => option.name}
            />
            <button disabled={isSubmitting} type="submit" style={{ display: 'none' }} />
          </Form>
        </FieldWrapper>
      }
    >
      {!isUpdating && (
        <Badge bgColor="#fff" color="#1D1D35" size={BadgeSize.Large}>
          {value ? value.name : 'Fleet not assigned'}
        </Badge>
      )}

      {isUpdating && <Skeleton animation="wave" width={100} height={24} />}
    </Popover>
  );
};
