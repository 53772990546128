import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const SplitArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-right: 1px solid transparent;
  cursor: pointer;
`;

export const SplitButton = styled.div<{ color: string; background?: string }>`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
  text-transform: uppercase;

  color: ${(props) => props.color};
  border: 1px solid ${(props) => (props.background ? 'transparent' : props.color)};
  background-color: ${(props) => props.background ?? '#fff'};

  ${SplitArrow} {
    border-color: ${(props) => (props.background ? '#fff' : props.color)};
  }
`;

export const StatusText = styled.div`
  padding: 9px 16px;
  user-select: none;
`;

export const Transitions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const TransitionItem = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px;

  cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};

  transition: background-color 0.15s ease-in-out;
  &:hover {
    background-color: ${(props) => (props.isDisabled ? 'transparent' : '#f5f5f5')};
  }
`;

export const ModalFooterActions = styled.div`
  background: #ffffff;
  padding: 24px;
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: flex-end;
`;

export const ModalBody = styled.div`
  padding: 24px;
  max-width: 698px;
`;

export const RequestAgainClarification = styled.div`
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 400;

  b {
    font-weight: 500;
  }
`;
