import styled from 'styled-components';

export const Container = styled.div`
  background-color: rgba(255, 189, 19, 0.2);
  border-radius: 4px;
  padding: 12px;
  display: flex;
  align-items: ${(props) => (props.isCenterAligned ? 'center' : 'flex-start')};
  gap: 12px;
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 14px;
`;
