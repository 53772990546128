import { ReactQueryKey } from '@enums';
import { errorHandler } from '@services/api/helpers';
import { selectWorkspaceId } from '@state/selectors';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import userApi from '@services/api/userApi';
import { useToast } from '..';

type UpdateUserPayload = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  roleId: number;
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient();
  const { showSuccess } = useToast();
  const companyId = useSelector(selectWorkspaceId);

  return useMutation<void, Error, UpdateUserPayload>(
    async (req) => {
      try {
        await userApi.updateUser(companyId, req.id, req);
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: () => {
        showSuccess('User updated successfully');
        queryClient.invalidateQueries([ReactQueryKey.CompanyMembersList, companyId]);
      }
    }
  );
};
