import { ReactQueryKey } from '@enums';
import { useAppSelector } from '@hooks/store';
import { selectWorkspaceId } from '@state/selectors';
import { apiErrorHandler } from '@utils/api';
import { useQuery } from 'react-query';
import rolesAPI from '@services/api/rolesAPI';

export const useRoleList = () => {
  const companyId = useAppSelector(selectWorkspaceId);

  return useQuery(
    [ReactQueryKey.WorkspaceRoles, companyId],
    async () => {
      try {
        return (await rolesAPI.findAll(companyId)).data;
      } catch (error) {
        throw apiErrorHandler('Error fetching Roles', error);
      }
    },
    {
      refetchOnMount: false,
      staleTime: 1000 * 3600 * 24
    }
  );
};
