import { useEffectOnce } from 'react-use';

/**
 * https://developers.canny.io/install/changelog
 */
export const useCanny = () => {
  useEffectOnce(() => {
    // @ts-ignore
    Canny('initChangelog', {
      appID: '6440547fda3fa284d2ff2712',
      position: 'bottom',
      align: 'right',
      theme: 'light',
    });
  });
};