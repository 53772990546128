import { ANALYTICS_MODULE_RECORD_TYPE_MAP, RECORD_TYPE_TITLE_MAP } from '@features/Analytics/constants';
import { AnalyticsModule } from '@features/Analytics/types';

export const formatCount = (module: AnalyticsModule, amount: number) => {
  const word = RECORD_TYPE_TITLE_MAP[ANALYTICS_MODULE_RECORD_TYPE_MAP[module]] || 'Work Order';

  if (amount === 1) {
    return `1 ${word}`;
  }

  return `${amount} ${word}s`;
};

export const roundPercentValue = (value: number) => {
  return Math.round(value * 100) / 100;
};

export const percentTickFormatter = (value: string) => {
  if (value === '') {
    return '-';
  }

  return `${value}%`;
};

export const secondsToDays = (seconds: number) => {
  return Math.round(seconds / 60 / 60 / 24);
};

export const daysToSeconds = (days: number) => {
  return days * 24 * 60 * 60;
};
