import styled from 'styled-components';

export const TagsContainer = styled.div`
  padding: 4px 8px;
  > * {
    flex-shrink: 0;
    font-family: ${({ theme }) => theme.fonts.primary};

    svg:last-child {
      cursor: pointer;
    }
  }
`;
