import React from 'react';
import styled from 'styled-components';
import { Edit as EditIcon } from 'react-feather';
import { Grid, makeStyles } from '@material-ui/core';
import { colors, scroll } from '@styles';
import { Wrapper as CopyButton } from '@common/ui/CopyButton/styled';

export const useStylesPopover = makeStyles({
  root: {
    '& .MuiPopover-paper': {
      overflow: 'visible'
    }
  }
});

export const Header = styled(Grid)`
  align-items: center;
  padding: 8px 16px;
  border-top: 1px solid ${colors.gray3};
`;

export const Navigation = styled(Grid)`
  padding: 13px 16px;
  border-top: 1px solid ${({ theme }) => theme.colors.gray3};
`;

export const NavItemWrapper = styled(Grid)`
  padding: 0;
  margin-right: 8px;
`;

export const NavItem = styled.div`
  font: ${({ theme }) => theme.typefaces.text};
  background-color: ${({ theme }) => theme.colors.gray2Bg};
  color: #778ca2;
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
`;

export const Section = styled.section<{ headerHeight: number; isAccount?: boolean }>`
  height: ${({ isAccount }) => (isAccount ? `calc(var(--app-height) - 278px)` : 'calc(100% - 110px)')};
  overflow-x: hidden;
  overflow-y: auto;

  ${scroll}
`;

export const GroupHeader = styled.header`
  display: flex;
  align-items: center;
  padding: 12px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderDark};
  font: ${({ theme }) => theme.typefaces.subtitle};
  background-color: ${({ theme }) => theme.colors.gray2Bg};
`;

export const GroupHeaderTitle = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const GroupBody = styled(Grid)`
  && {
    padding: 24px;
    justify-content: normal;
    margin: 0;
  }
`;

export const PropertyTitle = styled.div`
  color: ${({ theme }) => theme.colors.gray};
  font: ${({ theme }) => theme.typefaces.textLight};
  margin-bottom: 4px;
`;

const ValueWrapperInner = styled.div<{ disableName: boolean; isOverflowAllowed: boolean }>`
  display: block;
  flex: 1;
  min-height: 19px;
  line-height: 19px;
  white-space: nowrap;
  overflow-x: ${({ isOverflowAllowed }) => (isOverflowAllowed ? 'visible' : 'hidden')};
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
`;

export const ValueWrapper = React.forwardRef((props, ref) => <ValueWrapperInner {...props} ref={ref} />);

export const Edit = styled(EditIcon)``;

export const ValueHoverableWrapper = styled(Grid)`
  align-items: center;

  ${CopyButton} {
    margin-left: initial;
  }

  ${CopyButton} + ${Edit} {
    margin-left: 4px;
  }

  ${CopyButton}, ${Edit} {
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    color: ${({ theme }) => theme.colors.gray2};

    &:hover {
      color: ${({ theme }) => theme.colors.black};
    }
  }

  &:hover {
    ${CopyButton}, ${Edit} {
      opacity: 1;
    }
  }

  ${({ theme }) => theme.devices.sm`
    ${CopyButton}, ${Edit} {
      opacity: 0;
    }
  `}
`;

export const EditorPopover = styled.div`
  width: 358px;
  padding: 12px;
`;

export const EditorTitle = styled.p`
  color: ${({ theme }) => theme.colors.black};
  font: ${({ theme }) => theme.typefaces.pagination};
  margin-bottom: 8px;
`;

export const EmptyShowSwitcher = styled(Grid)`
  width: 100%;
  align-items: center;
`;

export const IconWrapper = styled.div`
  margin-right: 8px;
  width: 20px;
  height: 20px;
`;
