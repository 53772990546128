import { defaultTheme } from '@themes';

export const switcher = () => {
  return {
    // .MuiSwitch-root Styles applied to the root element.
    root: {
      width: '40px',
      height: '22px',
      padding: '0',
      margin: '0',
    },
    // .MuiSwitch-edgeStart Styles applied to the root element if edge="start".
    edgeStart: {},
    // .MuiSwitch-edgeEnd Styles applied to the root element if edge="end".
    edgeEnd: {},
    // .MuiSwitch-switchBase Styles applied to the internal SwitchBase component's root class.
    switchBase: {
      padding: '3px 4px',
      '&$checked': {
        transform: 'translateX(16px)',
        '& + $track': {
          opacity: 1,
        },
      },
      '& .MuiSvgIcon-root': {
        width: '16px',
        height: '16px',
        padding: '1px',
        borderRadius: '100%',
        boxSizing: 'border-box',
        transform: 'rotate(180deg)',
      }
    },
    // .MuiSwitch-colorPrimary Styles applied to the internal SwitchBase component's root element if color="primary".
    colorPrimary: {
      '& $thumb': {
        backgroundColor: defaultTheme.colors.gray,
      },
      '& + $track': {
        borderColor: defaultTheme.colors.gray3,
        backgroundColor: defaultTheme.colors.white3,
      },
      '& .MuiSvgIcon-root': {
        backgroundColor: defaultTheme.colors.gray,
        fill: defaultTheme.colors.white
      },

      '&$checked': {
        '& $thumb': {
          backgroundColor: defaultTheme.colors.white
        },
        '& + $track': {
          borderColor: defaultTheme.colors.green,
          backgroundColor: defaultTheme.colors.green,
        },
        '& .MuiSvgIcon-root': {
          backgroundColor: defaultTheme.colors.white,
          fill: defaultTheme.colors.green
        }
      },

      '&$disabled': {
        '& + $track': {
          backgroundColor: defaultTheme.colors.gray3,
          borderColor: defaultTheme.colors.gray3,
          cursor: 'not-allowed',
          opacity: 0.5
        },
        '& $thumb': {
          opacity: 0.5
        },
        '&$checked .MuiSvgIcon-root': {
          opacity: 0.5,
          fill: '#00968866'
        }
      }
    },
    // .MuiSwitch-colorSecondary Styles applied to the internal SwitchBase component's root element if color="secondary"
    colorSecondary: {},
    // .MuiSwitch-sizeSmall Styles applied to the root element if size="small".
    sizeSmall: {
      width: 20,
      height: 12,
      '& $thumb': {
        width: 8,
        height: 8
      },
      '& $switchBase': {
        padding: 2,
      },
      '& $switchBase$checked': {
        transform: 'translateX(8px)',
      },
      '& $track': {
        height: '12px'
      },
      '& .MuiSvgIcon-root': {
        width: '8px',
        height: '8px'
      }
    },
    // .Mui-checked Pseudo-class applied to the internal SwitchBase component's checked class.
    checked: {},
    // .Mui-disabled Pseudo-class applied to the internal SwitchBase component's disabled class.
    disabled: {},
    // .MuiSwitch-input Styles applied to the internal SwitchBase component's input element.
    input: {},
    // .MuiSwitch-thumb Styles used to create the thumb passed to the internal SwitchBase component icon prop.
    thumb: {
      width: 16,
      height: 16,
      boxShadow: 'none',
    },
    // .MuiSwitch-track Styles applied to the track element.
    track: {
      borderRadius: 26 / 2,
      borderWidth: '1px',
      borderStyle: 'solid',
      opacity: 1,
      transition: 'all',
      height: '22px',
      boxSizing: 'border-box'
    },
  };
};
