import { colors, scroll } from '@styles';
import styled from 'styled-components';

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
`;

export const IconButton = styled.div`
  padding: 4px;
  cursor: pointer;
  color: #9c9caa;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    color: ${colors.black};
  }
`;

export const ModalTitle = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
`;

export const ModalBodyContainer = styled.div`
  background-color: #f7f7fc;
`;

export const ModalContainer = styled.div`
  min-width: 500px;
  overflow: hidden;
  border-radius: 4px;

  @media (max-width: 767px) {
    min-width: auto;
  }
`;

export const ConfirmModalBody = styled.div`
  padding: 16px 24px;
  background-color: #f7f7fc;
  text-align: center;
  white-space: pre-line;
  max-width: 60vw;
`;

export const ConfirmButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  background-color: #fff;
  padding: 16px 24px;
`;

export const WarningIconWrapper = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalFooter = styled.div`
  padding: 16px 24px;
  display: flex;
  justify-content: flex-end;
  gap: 24px;
  background-color: #fff;

  > *:first-child:nth-last-child(3) {
    margin-right: auto;
  }
`;

export const ModalBody = styled.div<{ width?: string }>`
  padding: 24px;
  background-color: #f7f7fc;
  max-height: calc(100vh - 64px - 64px - 64px);
  overflow-y: auto;
  ${scroll};

  width: ${({ width }) => width || 'auto'};
  max-width: ${({ width }) => (width ? '100%' : 'auto')};

  @media (max-width: 1200px) {
    max-height: calc(100vh - 256px);
  }
`;
