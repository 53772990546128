import { Form, FormValidationRules, RichTextField, SelectField, useForm } from '@kit/components/Form';
import React, { useCallback } from 'react';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { Call, CallDisposition, CallOutcome, CallReason, Feed } from '@generated/types/graphql';
import { useUpdateCall } from '@hooks/calls/useUpdateCall';
import { capitalize } from 'lodash';
import { UserAvatar } from '@kit/components/UserAvatar';
import { ArrowDownLeft, ArrowUpRight } from 'react-feather';
import moment from 'moment';
import { ModalFooter, useConfirmDeleteModal } from '@common/PromiseModal';
import { useDeleteCall } from '@hooks/calls/useDeleteCall';
import {
  CallMetaData,
  CommentContent,
  CommentHeader,
  CommentHeaderPart,
  CommentHeaderTitle,
  SecondaryText
} from '../styled';
import { Body, Info, Row } from './styled';

type FormValues = {
  reason: { id: string; name: string };
  disposition: { id: string; name: string };
  note: string;
};

interface Props {
  feedItem: Feed;
  call: Call;
  onClose: () => void;
}

const RULES: FormValidationRules<FormValues> = {
  reason: {
    isRequired: true
  },
  disposition: {
    isRequired: true
  }
};

type Option = { id: string; name: string };

function enumToOptions<T>(enumToConvert: T): Option[] {
  const options = Object.entries(enumToConvert).map(([_id, value]) => ({
    id: value,
    name: capitalize(value).replace('_', ' ')
  }));

  // place "Other" option at the end
  const otherOption = options.find((option) => option.name === 'Other');
  if (otherOption) {
    options.splice(options.indexOf(otherOption), 1);
    options.push(otherOption);
  }

  return options;
}

const REASON_OPTIONS = enumToOptions(CallReason);

const DISPOSITION_OPTIONS = enumToOptions(CallDisposition);

const formatDuration = (durationInMs: number) => {
  const minutes = Math.floor(durationInMs / 60000);
  const seconds = Math.round((durationInMs % 60000) / 1000);

  if (minutes === 0) {
    return `${seconds} sec${seconds === '1' ? '' : 's'}`;
  }

  return `${minutes} min${minutes === 1 ? '' : 's'} ${seconds > 0 ? `${seconds} sec${seconds === 1 ? '' : 's'}` : ''}`;
};

const svgStyle = {
  verticalAlign: 'bottom'
};

export const EditCallForm = ({ feedItem: item, call, onClose }: Props) => {
  const { mutateAsync: update } = useUpdateCall();
  const { createdBy: author = { name: '' } } = item;
  const userName = author.name || [author.firstName, author.lastName].join(' ');

  const confirmDelete = useConfirmDeleteModal();

  const { mutateAsync: deleteCall } = useDeleteCall();

  const postForm = async (values: FormValues) => {
    const payload = {
      reason: values.reason.id,
      disposition: values.disposition.id,
      note: values.note
    };

    await update({ id: call.id, dto: payload });

    onClose();
  };

  const { handleSubmit, form } = useForm<FormValues>({
    onSubmit: postForm,
    defaultValues: {
      reason: REASON_OPTIONS.find((option) => option.id === call.reason),
      disposition: DISPOSITION_OPTIONS.find((option) => option.id === call.disposition),
      note: call.note || ''
    }
  });

  const handleDelete = useCallback(async () => {
    if (await confirmDelete(`Are you sure you want to delete this call?`)) {
      await deleteCall(call.id);
    }
  }, [confirmDelete, deleteCall, call]);

  const {
    formState: { isSubmitting },
    control
  } = form;

  return (
    <Form rules={RULES} onSubmit={handleSubmit}>
      <Body>
        <Info>
          <CommentContent>
            <CommentHeader>
              <CommentHeaderPart verticalAlign="flex-start">
                {author && <UserAvatar user={author} />}
                <CommentHeaderTitle>
                  {!call.isInbound && (
                    <>
                      <b>{userName}</b> made an outbound call{' '}
                      <ArrowUpRight
                        style={svgStyle}
                        size="16px"
                        color={call.outcome === CallOutcome.Missed ? '#D54855' : '#009688'}
                      />
                    </>
                  )}
                  {call.isInbound && call.outcome === CallOutcome.Answered && (
                    <>
                      <b>{userName}</b> answered an inbound call{' '}
                      <ArrowDownLeft style={svgStyle} size="16px" color="#009688" />
                    </>
                  )}
                  {call.isInbound && call.outcome === CallOutcome.Missed && (
                    <>
                      <b>{userName}</b> missed an inbound call{' '}
                      <ArrowDownLeft style={svgStyle} size="16px" color="#D54855" />
                    </>
                  )}
                </CommentHeaderTitle>
              </CommentHeaderPart>
            </CommentHeader>

            <div>
              <CallMetaData>
                <SecondaryText>From:</SecondaryText>
                <div>
                  {call.fromContact ? `${call.fromContact.name}, ` : ''}
                  <a href={`tel:${call.fromNumber}`}>{call.fromNumber}</a>
                </div>
                <SecondaryText>To:</SecondaryText>
                <div>
                  {call.toContact ? `${call.toContact.name}, ` : ''}
                  <a href={`tel:${call.toNumber}`}>{call.toNumber}</a>
                </div>
                <SecondaryText>Duration:</SecondaryText>
                <div>{call.durationInMs ? formatDuration(call.durationInMs) : '-'}</div>
                <SecondaryText>Date & Time:</SecondaryText>
                <div>{call.startTime ? moment(call.startTime).format('MMM D, YYYY h:mm A') : '-'}</div>
              </CallMetaData>
            </div>
          </CommentContent>
        </Info>

        <Row>
          <SelectField
            label="Reason"
            name="reason"
            getOptionLabel={(option) => option.name}
            options={REASON_OPTIONS}
            control={control}
          />
          <SelectField
            label="Disposition"
            name="disposition"
            getOptionLabel={(option) => option.name}
            options={DISPOSITION_OPTIONS}
            control={control}
          />
        </Row>
        <RichTextField name="note" control={control} placeholder="Type a note here..." />
      </Body>
      <ModalFooter>
        <Button onClick={handleDelete} variant={ButtonVariant.Danger}>
          Delete
        </Button>
        <Button onClick={onClose} variant={ButtonVariant.Secondary}>
          Cancel
        </Button>
        <Button disabled={isSubmitting} type="submit" variant={ButtonVariant.Primary}>
          Update
        </Button>
      </ModalFooter>
    </Form>
  );
};
