import React, { useState } from 'react';
import { Edit3 } from 'react-feather';
import { useAppSelector } from '@hooks/store';
import { selectWorkspaceId } from '@state/selectors';
import { useUploadFileMutation } from '@hooks/useUploadFileMutation';
import { Upload } from 'antd';
import { MaxFileSize } from '@utils/files';
import { useRecordsMutations } from '@hooks/useRecords';
import { RecordType } from '@types';
import { useQueryClient } from 'react-query';
import { ReactQueryKey } from '@enums';
import { Loader } from '@kit/ui/Loader';
import { RcFile } from 'antd/es/upload';
import solarPanelImage from '@features/ProjectPortfolio/assets/solarPanel.svg';
import { IconInsideImage, Image, ImageContainer } from './styled';

interface Props {
  projectId: number;
  imageUrl: string;
  streetViewUrl: string;
}

export const ProjectImage = ({ projectId, imageUrl, streetViewUrl }: Props) => {
  const companyId = useAppSelector(selectWorkspaceId);
  const queryClient = useQueryClient();

  const [isUpdating, setIsUpdating] = useState(false);

  const {
    update: { mutateAsync: update }
  } = useRecordsMutations(companyId, RecordType.PROJECT);

  const {
    upload: { mutateAsync: upload }
  } = useUploadFileMutation(companyId);

  const handleUpload = async (file: RcFile) => {
    if (isUpdating) {
      return false;
    }

    if (file.size > MaxFileSize) {
      showError(`The file you tried to upload is too big. The maximum file size is ${MaxFileSize / 1024 / 1024}MB`);

      return false;
    }

    setIsUpdating(true);

    const { downloadUrl } = await upload({ file });

    await update(
      {
        id: projectId,
        dto: {
          imageUrl: downloadUrl
        }
      },
      {
        onSuccess: () => {
          queryClient.setQueryData([ReactQueryKey.RecordDetail, projectId], (oldData: any) => {
            if (!oldData) {
              return oldData;
            }

            return {
              ...oldData,
              imageUrl: downloadUrl
            };
          });

          queryClient.invalidateQueries([ReactQueryKey.ProjectsListInitialGroupData]);
          queryClient.invalidateQueries([ReactQueryKey.ProjectsByIds]);
        }
      }
    );

    setIsUpdating(false);

    return undefined;
  };

  return (
    <Upload accept="image/*" showUploadList={false} beforeUpload={handleUpload}>
      <ImageContainer isUpdating={isUpdating}>
        {!imageUrl && !streetViewUrl && <img src={solarPanelImage} alt="" />}
        {(imageUrl || streetViewUrl) && <Image style={{ backgroundImage: `url(${imageUrl || streetViewUrl})` }} />}
        <IconInsideImage>
          {!isUpdating && <Edit3 color="#fff" size="24px" />}
          {isUpdating && <Loader />}
        </IconInsideImage>
      </ImageContainer>
    </Upload>
  );
};
