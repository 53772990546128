import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  gap: 24px;
  align-items: flex-start;

  > * {
    flex: 1;
  }
`;

export const BodyWithActionButtons = styled.div`
  position: relative;
`;

export const ActionButtons = styled.div<{ isBodyWithError: boolean }>`
  position: absolute;
  z-index: 1;
  bottom: ${({ isBodyWithError }) => (isBodyWithError ? '26px' : '8px')};
  right: 12px;

  display: flex;
  align-items: center;
  gap: 8px;
`;
