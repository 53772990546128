import { Field, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { isNumber } from 'lodash';
import { ChevronDown } from 'react-feather';
import AutoComplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { DropDownItem } from '@common/Selector/UserSelector/DropDownItem';
import userApi from '@services/api/userApi';
import { UserFromApi } from '@types';
import { useEffectOnce } from 'react-use';
import { useAppSelector } from '@hooks';
import { selectCurrentUser } from '@state/selectors';
import { TextField, InputLabel } from '@common/ui';
import { autocomplete } from '@common/ui/Autocomplete/styled';

type BaseProps = {
  membersOnlyByProjectId?: number;
  companyId?: number;
  required?: boolean;
};

interface PersonSelectProps extends BaseProps {
  value: UserFromApi | null;
  setValue: (value: UserFromApi | null) => void;
  initial?: number | UserFromApi;
  error?: string;
  placeholder?: string;
}

export const PersonSelect: React.FC<PersonSelectProps> = ({
  value,
  setValue,
  initial,
  error,
  membersOnlyByProjectId,
  companyId,
  placeholder = 'Select account owner',
  onFocus,
  onBlur
}) => {
  const [assignees, setAssignees] = useState(value ? [value] : []);
  const currentUser = useAppSelector(selectCurrentUser);
  const defaultUser = { ...currentUser, id: currentUser.userId };

  useEffectOnce(() => {
    (async () => {
      if (initial) {
        if (isNumber(initial)) {
          const {
            data: { results }
          } = await userApi.getAssignee(initial, membersOnlyByProjectId ? companyId : undefined);

          setValue(results[0]);
        } else {
          setValue(initial);
        }
      }
    })();
  });

  const searchAssignees = async (search: string) => {
    if (!search) {
      return;
    }

    if (membersOnlyByProjectId) {
      const { data } = (await userApi.getProjectMembers(membersOnlyByProjectId, search)) as {
        data: { member: UserFromApi }[];
      };
      setAssignees(data.map(({ member }) => member));
    } else {
      const {
        data: { results }
      } = await userApi.getAssignees(search, companyId);
      setAssignees(results);
    }
  };

  const classes = makeStyles(autocomplete({}))();

  const getFullName = (user: UserFromApi) =>
    (user?.firstName && `${user?.firstName} ${user?.lastName}`) || user?.name || '';

  return (
    <AutoComplete
      classes={classes}
      multiple={false}
      openOnFocus
      onInputChange={(event: any, val: string) => searchAssignees(val)}
      options={(assignees.length ? assignees : [defaultUser]) as UserFromApi[]}
      value={value}
      onChange={(e, v) => setValue(v)}
      getOptionSelected={(option: UserFromApi, val: UserFromApi | string) => option.id === val?.id}
      getOptionLabel={(option: UserFromApi | string) => {
        if (!option?.firstName || !option?.lastName) {
          const assignee = assignees.find((a) => +a.id === +option);
          // selected from dropdown list
          if (assignee) {
            return getFullName(assignee);
          }
          // no options, but we have selected user in defaultValues
          if (value) {
            return getFullName(value);
          }
          // nothing selected
          if (!assignee) {
            return '';
          }
        }

        return getFullName(option as UserFromApi);
      }}
      // loading={loadingCompanyUsers}
      // loadingText={<Skeleton />}
      noOptionsText="User not found"
      renderOption={(option: UserFromApi) => (
        <DropDownItem avatarUrl={option.avatarUrl} name={getFullName(option)} onClick={() => setValue(option)} />
      )}
      renderInput={(params) => (
        <TextField {...params} name="assignees" placeholder={placeholder} error={!!error} helperText={error} />
      )}
      popupIcon={<ChevronDown />}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
};

export const PersonIdField: React.FC<PersonFieldProps> = ({
  name,
  label,
  membersOnlyByProjectId,
  companyId,
  required,
  placeholder,
  onFocus,
  onBlur
}) => {
  const { values, setFieldValue, errors, touched } = useFormikContext<any>();
  const [person, setPerson] = useState<UserFromApi | null>(null);

  useEffect(() => {
    setFieldValue(name, person ? person.id : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [person]);

  return (
    <>
      {label && (
        <InputLabel htmlFor={name} required={required}>
          {label}
        </InputLabel>
      )}
      <Field name={name} label={label || null}>
        {() => (
          <PersonSelect
            value={person}
            setValue={setPerson}
            initial={values[name]}
            error={touched[name] ? errors[name] : undefined}
            membersOnlyByProjectId={membersOnlyByProjectId}
            companyId={companyId}
            placeholder={placeholder}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        )}
      </Field>
    </>
  );
};

interface PersonFieldProps extends BaseProps {
  name: string;
  label?: string;
  placeholder?: string;
  onFocus?: () => void;
  onBlur?: () => void;
}

export const PersonField: React.FC<PersonFieldProps> = ({
  name,
  label,
  membersOnlyByProjectId,
  companyId,
  required,
  placeholder,
  onFocus,
  onBlur
}) => {
  const { values, setFieldValue, errors, touched } = useFormikContext<any>();

  return (
    <>
      {label && (
        <InputLabel htmlFor={name} required={required}>
          {label}
        </InputLabel>
      )}
      <Field name={name} label={label || null}>
        {() => (
          <PersonSelect
            value={values[name]}
            setValue={(p) => setFieldValue(name, p)}
            error={touched[name] ? errors[name] : undefined}
            membersOnlyByProjectId={membersOnlyByProjectId}
            companyId={companyId}
            placeholder={placeholder}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        )}
      </Field>
    </>
  );
};
