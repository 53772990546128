import React, { useCallback, useMemo } from 'react';
import { Layout, Responsive, WidthProvider } from 'react-grid-layout';
import { DashboardListItem } from '@hooks/analytics/useDashboardList';
import { useUpdateDashboard } from '@hooks/analytics/useUpdateDashboard';
import { Widget } from './Widget';
import { DragHandle } from './styled';
import { AnalyticsWidgetType } from './types';

const ResponsiveGridLayout = WidthProvider(Responsive);

const GRID_MARGINS: [number, number] = [16, 16];
const GRID_PADDING: [number, number] = [0, 0];

const GRID_BREAKPOINTS = {
  lg: 992,
  sm: 768,
  xs: 0
};

const GRID_COLS = {
  lg: 12,
  sm: 4,
  xs: 1
};

const ROW_HEIGHT = 228;

interface Props {
  dashboard: DashboardListItem;
  isEditMode: boolean;
}

export const Grid = ({ dashboard, isEditMode }: Props) => {
  // const [, cellWidthDebounced, setCellWidth] = useDebouncedState(0, 100);

  const { mutateAsync: update } = useUpdateDashboard();

  const { layout } = dashboard;

  const gridLayouts = useMemo(
    () => ({
      lg: layout.map((item) => ({
        ...item,
        w: Math.max(item.w, item.widgetConfig.widgetType === AnalyticsWidgetType.KPI ? 2 : 4),
        h: Math.max(item.h, item.widgetConfig.widgetType === AnalyticsWidgetType.KPI ? 1 : 2),
        minW: item.widgetConfig.widgetType === AnalyticsWidgetType.KPI ? 2 : 4,
        minH: item.widgetConfig.widgetType === AnalyticsWidgetType.KPI ? 1 : 2
      }))
    }),
    [layout]
  );

  const handleLayoutChange = useCallback(
    (updatedLayout: Layout[]) => {
      const merged = updatedLayout.map((item) => {
        const found = layout.find((cell) => cell.i === item.i);

        return {
          ...(found || {}),
          ...item
        };
      });

      update({
        id: dashboard.id,
        dto: {
          layout: merged
        }
      });
    },
    [dashboard, layout, update]
  );

  /*
    const handleWidthChange = useCallback((width: number, margins: [number, number], cols: number) => {
        setCellWidth((width - (margins[0] * cols - 1)) / cols);
      }, [setCellWidth]);
      */

  return (
    <ResponsiveGridLayout
      layouts={gridLayouts}
      margin={GRID_MARGINS}
      containerPadding={GRID_PADDING}
      breakpoints={GRID_BREAKPOINTS}
      cols={GRID_COLS}
      useCSSTransforms
      measureBeforeMount={false}
      className="grid-layout"
      rowHeight={ROW_HEIGHT}
      isResizable={isEditMode}
      isDraggable={isEditMode}
      resizeHandle={isEditMode && <DragHandle className="react-resizable-handle" size="20px" />}
      // onResize={handleLayoutChange}
      onResizeStop={handleLayoutChange}
      onDragStop={handleLayoutChange}
      // onWidthChange={handleWidthChange}
    >
      {layout.map((item) => (
        <Widget key={item.i} dashboard={dashboard} isEditMode={isEditMode} layoutItem={item} />
      ))}
    </ResponsiveGridLayout>
  );
};
