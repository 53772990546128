import { useRecordDetail } from '@hooks/useRecordDetail';
import React, { useCallback } from 'react';
import { CopyButton } from '@common/ui';
import { useModal } from '@common/PromiseModal';
import moment from 'moment';
import { UserAvatar } from '@kit/components/UserAvatar';
import { ContactAvatar } from '@kit/components/ContactAvatar';
import { PhoneNumber } from '@kit/ui/anchors/PhoneNumber';
import { EmailAddress } from '@kit/ui/anchors/EmailAddress';
import { Status } from '@features/ClientPortfolio/components/Status';
import { STATUS_CONFIG } from '@features/ClientPortfolio/constants';
import { AccountStatus } from '@types';
import {
  Container,
  Copyable,
  EditableWrapper,
  InfoBlock,
  PrimaryContactBlock,
  ProjectContact,
  ProjectContactInfo,
  ProjectContactLabel,
  ProjectContacts,
  ReferredBy,
  StaticPropertyValue,
  StatusContainer
} from './styled';
import { Description } from './EditableFields';
import { EditableField } from './EditableField';
import { ContactForm } from '../Stakeholders/ContactForm';
import { CustomProperties } from './CustomProperties';

interface Props {
  projectId: number;
}

export const Properties = ({ projectId }: Props) => {
  const { data: record, isLoading } = useRecordDetail(projectId);
  const { openModal } = useModal();

  const primaryContact = record?.projectContacts[0];

  const handleEditPrimaryContact = useCallback(() => {
    openModal<void>(
      ({ onClose }) => <ContactForm recordId={projectId} initialValues={primaryContact} onClose={onClose} />,
      { title: 'Edit Contact' }
    );
  }, [projectId, primaryContact, openModal]);

  if (isLoading || !record.id) {
    return null; // TODO
  }

  return (
    <Container>
      <InfoBlock>
        {record.projectContacts.length > 0 && (
          <PrimaryContactBlock>
            <div>
              <ProjectContactLabel>Primary contact</ProjectContactLabel>
            </div>

            <ProjectContacts>
              <EditableWrapper onClick={handleEditPrimaryContact}>
                <Copyable>
                  <ProjectContact>{primaryContact.name}</ProjectContact>
                  <CopyButton value={primaryContact.name} />
                </Copyable>
              </EditableWrapper>

              {primaryContact.phones?.[0] ? (
                <Copyable>
                  <PhoneNumber value={primaryContact.phones[0]} />
                  <CopyButton value={primaryContact.phones?.[0]} />
                </Copyable>
              ) : (
                <div />
              )}

              {primaryContact.emails?.[0] ? (
                <Copyable>
                  <EmailAddress value={primaryContact.emails[0]} />
                  <CopyButton value={primaryContact.emails?.[0]} />
                </Copyable>
              ) : (
                <div />
              )}
            </ProjectContacts>
          </PrimaryContactBlock>
        )}

        <ProjectContacts>
          <ProjectContact>
            <ProjectContactLabel>Owner</ProjectContactLabel>
            <EditableField project={record} propertyName="ownerId">
              <EditableWrapper>
                <ProjectContactInfo>
                  {record.owner?.id && (
                    <>
                      <UserAvatar user={record.owner} />
                      <span>{`${record.owner.firstName} ${record.owner.lastName}`}</span>
                    </>
                  )}

                  {!record.owner && '-'}
                </ProjectContactInfo>
              </EditableWrapper>
            </EditableField>
          </ProjectContact>
          {record.referrerContact && (
            <ProjectContact>
              <ProjectContactLabel>Referred by</ProjectContactLabel>
              <ReferredBy>
                <ProjectContactInfo>
                  <ContactAvatar contact={record.referrerContact} />
                  <span>{record.referrerContact.name}</span>
                </ProjectContactInfo>
              </ReferredBy>
            </ProjectContact>
          )}
          <div />
        </ProjectContacts>

        <div>
          <ProjectContactLabel>Description</ProjectContactLabel>
          <Description project={record} />
        </div>

        <ProjectContacts>
          <div>
            <ProjectContactLabel>Created at</ProjectContactLabel>
            <StaticPropertyValue>{moment(record.createdAt).format('MM/DD/YY hh:mma')}</StaticPropertyValue>
          </div>
          <div>
            <ProjectContactLabel>Last activity</ProjectContactLabel>
            <StaticPropertyValue>{moment(record.updatedAt).format('MM/DD/YY hh:mma')}</StaticPropertyValue>
          </div>
          <div>
            <ProjectContactLabel>Client Status</ProjectContactLabel>

            <StatusContainer>
              <Status status={record.accountStatus as AccountStatus} />
              {STATUS_CONFIG[record.accountStatus as AccountStatus]?.label}
            </StatusContainer>
          </div>
        </ProjectContacts>
      </InfoBlock>

      <CustomProperties recordId={projectId} />
    </Container>
  );
};
