import React from 'react';

export const TimelineChartIcon = ({
  size = '16px',
  color = 'currentColor'
}: {
  size?: string | number;
  color?: string;
}) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.4997 5.83325C17.9599 5.83325 18.333 5.46016 18.333 4.99992L18.333 1.66659C18.333 1.20635 17.9599 0.833253 17.4997 0.833253L2.49967 0.833253C2.03944 0.833253 1.66634 1.20635 1.66634 1.66659L1.66634 4.99992C1.66634 5.46016 2.03944 5.83325 2.49967 5.83325L17.4997 5.83325ZM16.6663 4.16659L3.33301 4.16659L3.33301 2.49992L16.6663 2.49992L16.6663 4.16659Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3337 12.5C13.7939 12.5 14.167 12.1269 14.167 11.6667L14.167 8.33333C14.167 7.8731 13.7939 7.5 13.3337 7.5L2.50033 7.5C2.04009 7.5 1.66699 7.8731 1.66699 8.33333L1.66699 11.6667C1.66699 12.1269 2.04009 12.5 2.50033 12.5L13.3337 12.5ZM12.5003 10.8333L3.33366 10.8333L3.33366 9.16667L12.5003 9.16667L12.5003 10.8333Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.16667 19.1667C9.6269 19.1667 10 18.7937 10 18.3334L10 15.0001C10 14.5398 9.6269 14.1667 9.16667 14.1667L2.5 14.1667C2.03976 14.1667 1.66667 14.5398 1.66667 15.0001L1.66667 18.3334C1.66667 18.7937 2.03976 19.1667 2.5 19.1667L9.16667 19.1667ZM8.33333 17.5001L3.33333 17.5001L3.33333 15.8334L8.33333 15.8334L8.33333 17.5001Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.25 18C12.25 14.8244 14.8244 12.25 18 12.25C21.1756 12.25 23.75 14.8244 23.75 18C23.75 21.1756 21.1756 23.75 18 23.75C14.8244 23.75 12.25 21.1756 12.25 18ZM18 13.75C15.6528 13.75 13.75 15.6528 13.75 18C13.75 20.3472 15.6528 22.25 18 22.25C20.3472 22.25 22.25 20.3472 22.25 18C22.25 15.6528 20.3472 13.75 18 13.75Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 14.25C18.4142 14.25 18.75 14.5858 18.75 15V17.6893L20.0303 18.9697C20.3232 19.2626 20.3232 19.7374 20.0303 20.0303C19.7374 20.3232 19.2626 20.3232 18.9697 20.0303L17.4697 18.5303C17.329 18.3897 17.25 18.1989 17.25 18V15C17.25 14.5858 17.5858 14.25 18 14.25Z"
      fill={color}
    />
  </svg>
);
