import { RecordType, SmartViewFilter, SmartViewFilterType } from '@types';
import React, { useCallback, useEffect } from 'react';
import { ProjectStatus as ProjectActiveStatus } from '@components/Project/ProjectView/types';
import { PER_PAGE_OPTIONS } from '@features/ClientPortfolio/constants';
import { SmartViewSaveButton } from '@features/SmartViews/SaveButton';
import { useCurrentSmartView } from '@hooks/smartViews/useCurrentSmartView';
import { cloneDeep, merge } from 'lodash';
import { usePortfolioColumns } from '@hooks/usePortfolioColumns';
import { ClientFilterState, DEFAULT_CLIENT_FILTERS, useClientFilterState } from '../useClientFilterState';

const mapSmartViewFilterToClientFilters = (smartView: SmartViewFilter) => {
  const {
    type,
    groupBy,
    orderBy,
    filters,
    status,
    isArchivedShown,
    perPage,
    query = ''
  } = cloneDeep(smartView.conditions.search);

  const perPageOption =
    typeof perPage === 'number' ? PER_PAGE_OPTIONS.find((option) => option.value === perPage) : perPage;

  return merge(cloneDeep(DEFAULT_CLIENT_FILTERS), {
    status,
    isArchivedShown: isArchivedShown || type === ProjectActiveStatus.archived,
    groupBy,
    sortBy: orderBy,
    filters,
    perPage: perPageOption,
    query
  });
};

export const Views = () => {
  const { clientFilters, resetFilters } = useClientFilterState();

  const currentSmartView = useCurrentSmartView();

  const { currentColumns } = usePortfolioColumns(RecordType.ACCOUNT);

  useEffect(() => {
    // for some reason React renders this component even after navigating away from the page
    if (!window.location.pathname.includes('clients')) {
      return;
    }

    if (currentSmartView) {
      resetFilters(mapSmartViewFilterToClientFilters(currentSmartView));
    } else {
      resetFilters(DEFAULT_CLIENT_FILTERS);
    }
  }, [currentSmartView, resetFilters]);

  const mapperToSvFilter = useCallback(
    (filters: ClientFilterState) => {
      return {
        properties: {
          shownColumns: currentColumns.map(({ id }) => id)
        },
        search: {
          groupBy: filters.groupBy,
          orderBy: filters.sortBy,
          filters: filters.filters,
          isArchivedShown: filters.isArchivedShown,
          status: filters.status,
          perPage: filters.perPage,
          query: filters.query
        }
      };
    },
    [currentColumns]
  );

  return (
    <SmartViewSaveButton
      defaultClientFilters={DEFAULT_CLIENT_FILTERS}
      clientFilters={clientFilters}
      type={SmartViewFilterType.ACCOUNT}
      clientFiltersToSvFiltersFn={mapperToSvFilter}
    />
  );
};
