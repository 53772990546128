import React, { forwardRef, useImperativeHandle } from 'react';
import { ModalProps, Popover as MuiPopover, PopoverProps } from '@material-ui/core';

import { Trigger, Menu } from './styled';

interface Props extends Omit<PopoverProps, 'anchorEl' | 'open' | 'onClose'> {
  children: React.ReactNode;
  content: React.ReactNode;
  open?: boolean;
  onClose?: ModalProps['onClose'];
}

export const Popover = forwardRef(({ open, onClose, children, content, hidden, ...props }: Props, ref) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose: ModalProps['onClose'] = (event, reason) => {
    setAnchorEl(null);
    onClose?.(event, reason);
  };

  useImperativeHandle(ref, () => ({
    close: handleClose
  }));

  const isOpen = open === undefined ? Boolean(anchorEl) : open;

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <Trigger isHidden={hidden} onClick={handleClick}>
        {children}
      </Trigger>
      <MuiPopover hidden={hidden} open={isOpen} anchorEl={anchorEl} onClose={handleClose} {...props}>
        <Menu>{content}</Menu>
      </MuiPopover>
    </>
  );
});
