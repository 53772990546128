import React from 'react';
import { Monitor, Tool } from 'react-feather';
import { Tooltip } from '@material-ui/core';

interface Props {
  isField: boolean;
  size?: string;
}

export const WorkOrderTypeIcon = ({ isField, size = '12px' }: Props) => {
  return (
    <Tooltip title={isField ? 'Field Work Order' : 'Office Work Order'}>
      {isField ? <Tool size={size} color="#9C9CAA" /> : <Monitor size={size} color="#9C9CAA" />}
    </Tooltip>
  );
};
