import { MENTION_TEXT_PATTERN, replacePatternsInString } from '@utils/quill';
import { flow } from 'lodash';

const sanitizeComment = (value: string) => {
  return flow(
    ($value) =>
      replacePatternsInString(
        $value,
        MENTION_TEXT_PATTERN,
        (...rest) => `@${rest[1]} `
      ),
    ($value) =>
      replacePatternsInString($value, /<\s?\/?[b|p|br/]+\s?>/g, () => '')
  )(value);
};

export const transformCommentText = (commentText: string, trimLength = 50) => {
  if (!commentText) {
    return commentText;
  }

  const sanitizedComment = sanitizeComment(commentText);
  const exposeComment = `${sanitizedComment?.substring(0, trimLength)}${
    sanitizedComment.length > trimLength ? '...' : ''
  }`;

  return exposeComment;
};
