import { colors } from '@styles';
import styled from 'styled-components';

export const DropZoneContainer = styled.div<{ height?: string; isDisabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
  width: 100%;
  height: ${(props) => props.height || '80px'};

  border-radius: 4px;
  background-color: white;
  border: 1px dashed #dfdfe8;

  > span,
  .ant-upload-drag-container,
  .ant-upload {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  text-align: center;
  font-size: 14px;
  color: ${colors.green};
`;

export const ThumbnailContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
`;

export const Thumbnail = styled.div`
  flex-shrink: 0;
  min-width: 32px;
  width: 32px;
  height: 32px;
  background-image: url(${(props) => props.url});
  background-size: cover;
  border-radius: 4px;
  cursor: pointer;
`;

export const Spinner = styled.div`
  min-width: 32px;
  height: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  border-radius: 4px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const FileRow = styled.div<{ isClickable?: boolean }>`
  margin-top: 16px;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  cursor: ${(props) => (props.isClickable ? 'pointer' : 'default')};
  transition: background-color 0.15s ease-in-out;
  &:hover {
    background-color: #fff;
  }
`;

export const FileRowLeft = styled.a`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${colors.black};
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active,
  &:visited,
  &:link {
    color: ${colors.black};
  }
`;

export const RemoveFileButton = styled.div`
  padding: 4px;
  cursor: pointer;
  color: #9c9caa;

  &:hover {
    color: ${colors.black};
  }
`;
