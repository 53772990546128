import React from 'react';
import { useFormikContext } from 'formik';
import { TagSelectField } from '@common/index';
import { Checkbox, Tooltip, makeStyles } from '@material-ui/core';
import { colors, checkboxMui } from '@styles';
import { Info } from 'react-feather';
import { FormControlLabel } from '@common/ui';
import { Label } from '@domains/labels';
import { LabelsContainer, Placeholder, SetColored } from './styled';
import { useInlineField } from '../useInlineField';

const useStylesCheckbox = makeStyles(checkboxMui({ size: '16px' }));
interface Props {
  isReadOnly?: boolean;
}

export const Labels = ({ isReadOnly }: Props) => {
  const { isEdit, setIsEdit, setIsFocused, nodeRef } = useInlineField();
  const classesCheckbox = useStylesCheckbox();
  const { values, submitForm, setFieldValue } = useFormikContext();

  if (isEdit) {
    return (
      <div ref={nodeRef}>
        <TagSelectField
          name="labels"
          // isColored={props.values.isColored}
          // setIsColored={(value) => props.setFieldValue('isColored', value)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            setIsFocused(false);
          }}
        />

        <SetColored isEdit>
          <FormControlLabel
            control={
              <Checkbox
                classes={classesCheckbox}
                checked={values.isColored}
                data-analyticsid="addBackgroundColorCheckbox"
                onChange={() => {
                  const newValue = !values.isColored;
                  setFieldValue('isColored', newValue);

                  submitForm();
                }}
                style={{ color: colors.gray }}
              />
            }
            label="Add background color"
          />
          <Tooltip title="This uses the color of the first label as the card color" placement="bottom">
            <Info size={16} color={colors.gray} />
          </Tooltip>
        </SetColored>
      </div>
    );
  }

  return (
    <div>
      <LabelsContainer isReadOnly={isReadOnly} onClick={isReadOnly ? undefined : () => setIsEdit(true)}>
        {values.labels.map((label) => (
          <Label key={label.id} label={label} />
        ))}

        {values.labels.length === 0 && <Placeholder>None</Placeholder>}
      </LabelsContainer>

      <div>
        <SetColored>
          <FormControlLabel
            control={
              <Checkbox
                classes={classesCheckbox}
                checked={values.isColored}
                data-analyticsid="addBackgroundColorCheckbox"
                disabled={isReadOnly}
                onChange={() => {
                  const newValue = !values.isColored;
                  setFieldValue('isColored', newValue);

                  submitForm();
                }}
                style={{ color: colors.gray }}
              />
            }
            label="Add background color"
          />
          <Tooltip title="This uses the color of the first label as the card color" placement="bottom">
            <Info size={16} color={colors.gray} />
          </Tooltip>
        </SetColored>
      </div>
    </div>
  );
};
