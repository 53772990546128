import { Button, ButtonVariant } from '@kit/ui/Button';
import { Popover } from '@kit/ui/Popover';
import { ChevronDownIcon } from '@kit/ui/icons/ChevronDown';
import React, { useCallback, useMemo, useState } from 'react';
import { SmartViewFilter } from '@types';
import { isEqual, isPlainObject } from 'lodash';
import { useCurrentSmartView } from '@hooks/smartViews/useCurrentSmartView';
import { useModal } from '@common/PromiseModal';
import { useUpdateSmartView } from '@hooks/smartViews/useUpdateSmartView';
import { useAppSelector } from '@hooks/store';
import { selectWorkspaceId } from '@state/selectors';
import { useAuth } from '@hooks/useAuth';
import { useUserRoleSettings } from '@hooks/useRoles';
import { hasAccess } from '@utils/roles';
import { SmartViewForm } from '../Form';
import { SmartViewTitle } from './SmartViewTitle';
import { ButtonContainer, OptionList, Option, OptionTitle, OptionDescription } from './styled';

interface Props<TClientFilters> {
  type: SmartViewFilter['type'];
  clientFilters: TClientFilters;
  defaultClientFilters: TClientFilters;
  clientFiltersToSvFiltersFn: (clientFilters: TClientFilters) => SmartViewFilter['conditions'];
}

const isSmartViewsFiltersEqual = (a: Object, b: Object): boolean => {
  return Object.keys(a).every((key) => {
    if (!b) {
      return false;
    }

    if (Array.isArray(a[key])) {
      if (a[key].length === 0 && !b[key]) {
        return true;
      }

      return isEqual(a[key], b[key]);
    }

    if (isPlainObject(a[key])) {
      return isSmartViewsFiltersEqual(a[key], b[key]);
    }

    return a[key] === b[key];
  });
};

export const SaveButton = <TClientFilters,>({
  type,
  clientFilters,
  defaultClientFilters,
  clientFiltersToSvFiltersFn
}: Props<TClientFilters>) => {
  const currentSmartView = useCurrentSmartView();

  const { openModal } = useModal();

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const companyId = useAppSelector(selectWorkspaceId);
  const { user } = useAuth();
  const { data: access } = useUserRoleSettings(companyId, user.userId);

  const { mutateAsync: update } = useUpdateSmartView();

  const isChanged = useMemo(() => {
    if (!currentSmartView) {
      return !isEqual(clientFilters, defaultClientFilters);
    }

    const mappedClientFilters = clientFiltersToSvFiltersFn(clientFilters);

    return !isSmartViewsFiltersEqual(mappedClientFilters, currentSmartView.conditions);
  }, [clientFilters, clientFiltersToSvFiltersFn, currentSmartView, defaultClientFilters]);

  const handleSaveAsNew = useCallback(async () => {
    openModal<void>(
      ({ onClose }) => (
        <SmartViewForm
          initialValues={{ type, conditions: clientFiltersToSvFiltersFn(clientFilters) }}
          onClose={onClose}
        />
      ),
      { title: 'Create Smart View' }
    );

    setIsPopoverOpen(false);
  }, [openModal, type, clientFilters, clientFiltersToSvFiltersFn]);

  const handleSaveAndUpdate = useCallback(async () => {
    await update({
      id: currentSmartView.id,
      dto: {
        sharedUsers: currentSmartView.sharedUsers.map((user) => user.id),
        sharedRoles: currentSmartView.sharedRoles.map((role) => role.id),
        sharedTeams: currentSmartView.sharedTeams.map((team) => team.id),
        conditions: clientFiltersToSvFiltersFn(clientFilters)
      }
    });

    setIsPopoverOpen(false);
  }, [clientFilters, clientFiltersToSvFiltersFn, currentSmartView, update]);

  const canCreate = hasAccess(access!, 'workspace', 'create', 'smartview');
  const canUpdate = hasAccess(access!, 'workspace', 'edit', 'smartview');

  if (!currentSmartView) {
    if (!canCreate) {
      return null;
    }

    return (
      <ButtonContainer isHidden={!isChanged}>
        <Button variant={ButtonVariant.Secondary} onClick={handleSaveAsNew}>
          Save
        </Button>
      </ButtonContainer>
    );
  }

  return (
    <>
      {canUpdate && (
        <Popover
          open={isPopoverOpen}
          onClose={() => setIsPopoverOpen(false)}
          content={
            <OptionList>
              <Option onClick={handleSaveAndUpdate}>
                <OptionTitle>Save & Update</OptionTitle>
                <OptionDescription>Save changes & update the current Smart View</OptionDescription>
              </Option>
              {canCreate && (
                <Option onClick={handleSaveAsNew}>
                  <OptionTitle>Save as...</OptionTitle>
                  <OptionDescription>Save changes as a new Smart View</OptionDescription>
                </Option>
              )}
            </OptionList>
          }
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          hidden={!isChanged}
        >
          <ButtonContainer isHidden={!isChanged}>
            <Button variant={ButtonVariant.Secondary} onClick={() => setIsPopoverOpen(true)}>
              Save
              <ChevronDownIcon size="16px" />
            </Button>
          </ButtonContainer>
        </Popover>
      )}
      {currentSmartView && (
        <SmartViewTitle name={currentSmartView.name} emoji={currentSmartView.emoji} isChanged={isChanged} />
      )}
    </>
  );
};
