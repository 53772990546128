import { useAppSelector } from '@hooks/store';
import { useAuth } from '@hooks/useAuth';
import { useRemindersMutations } from '@hooks/useReminders';
import {
  Form,
  InlineEditableDateTimeField,
  InlineEditableInputField,
  InlineEditableSelectField,
  useForm,
  FormValidationRules,
  InlineEditableRichTextField
} from '@kit/components/Form';
import { selectWorkspaceId } from '@state/selectors';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { getFullName } from '@utils/utils';
import { REMINDER_OPTIONS } from '@common/Reminder/ReminderTypeSelector';
import { useAllCompaniesUsers } from '@hooks/useCompanyUsers';
import { Reminder } from '@generated/types/graphql';
import { omit } from 'lodash';
import { parseUtcDate, roundToNearestHour } from '@utils/dates';
import { Button, ButtonSize, ButtonVariant, IconButton } from '@kit/ui/Button';
import { ContextMenu, MenuItem } from '@kit/components/ContextMenu';
import { MoreVerticalIcon } from '@kit/ui/icons/MoreVertical';
import { CalendarIcon } from '@kit/ui/icons/Calendar';

import { UserAvatar } from '@kit/components/UserAvatar';
import { isQuilValueEmpty } from '@utils/quill';
import { XIcon } from '@kit/ui/icons/X';
import { hasEntityAccessNew } from '@utils/roles';
import { useConfirmDeleteModal } from '@common/PromiseModal';
import { Check, Trash2 } from 'react-feather';
import { Badge } from '@kit/ui/Badge';
import { RecordTypeIcon } from '@common/RecordTypeIcon';
import { RecordType } from '@types';
import {
  Container,
  Header,
  Content,
  TitleInputWrapper,
  TitleView,
  FieldBlock,
  FieldLabel,
  SelectValueView,
  DescriptionWrapper,
  DescriptionView,
  PlaceholderValue,
  AvatarAndName,
  AssigneeValueView,
  OptionWithIcon,
  TwoFields
} from './styled';

const formatDate = (date: string) => {
  const format = 'MM/DD/YY h:mma';

  return moment(parseUtcDate(date)).format(format).replace(':00', '');
};

interface FormValues {
  title: string;
  assignee: any;
  type: any;
  dueDate: Date;
  description: string;
  isCompleted: boolean;
}

interface Props {
  onClose: () => void;
  initialValues: Reminder;
}

const mapInitialValuesToFormValues = (reminder: Reminder) => ({
  isCompleted: reminder.isCompleted,
  assignee: reminder.assignee,
  type: REMINDER_OPTIONS.find((option) => option.id === reminder.type),
  title: reminder.title,
  description: reminder.description,
  dueDate: reminder.dueDate ? new Date(reminder.dueDate) : null
});

export const ReminderView = ({ initialValues, onClose }: Props) => {
  const companyId = useAppSelector(selectWorkspaceId);
  const { user } = useAuth();

  const { isAppointment } = initialValues;

  const canEdit = hasEntityAccessNew(initialValues, 'edit');

  const canRemove = hasEntityAccessNew(initialValues, 'delete');
  const confirmDelete = useConfirmDeleteModal();

  const { data: companyUsers } = useAllCompaniesUsers();

  const {
    deleteMutation: { mutate: deleteReminder },
    updateMutation: { mutate: updateReminder }
  } = useRemindersMutations(companyId, isAppointment);

  const postForm = async (values: FormValues) => {
    const dto = {
      isCompleted: values.isCompleted,
      assigneeId: values.assignee.id,
      dueDate: values.dueDate.toISOString(),
      title: values.title,
      type: values.type.id,
      description: values.description
    };

    const dueDateChanged = +new Date(values.dueDate) !== +new Date(initialValues.dueDate);
    await updateReminder({
      id: initialValues.id,
      dto: dueDateChanged ? dto : omit(dto, 'dueDate')
    });
  };

  const { handleSubmit, form } = useForm<FormValues>({
    onSubmit: postForm,
    defaultValues: initialValues
      ? mapInitialValuesToFormValues(initialValues)
      : {
          assignee: null,
          type: REMINDER_OPTIONS[0],
          title: 'Follow-up',
          description: '',
          dueDate: roundToNearestHour(new Date())
        }
  });

  const triggerSubmit = () => {
    handleSubmit();
  };

  const rules = useMemo<FormValidationRules<FormValues>>(
    () => ({
      title: {
        isRequired: true
      },
      assignee: {
        isRequired: true
      },
      type: {
        isRequired: true
      },
      dueDate: {
        isRequired: true
      }
    }),
    []
  );

  const menuItems = useMemo<MenuItem[]>(
    () =>
      [
        canRemove && {
          icon: <Trash2 color="#D54855" size="16px" />,
          title: 'Delete',
          onClick: async () => {
            if (await confirmDelete('Are you sure you want to delete this Reminder?')) {
              await deleteReminder({ id: initialValues.id, recordId: initialValues.project?.id });
              onClose();
            }
          }
        }
      ].filter(Boolean),
    [canRemove, initialValues, confirmDelete, deleteReminder, onClose]
  );

  const {
    formState: { isSubmitting },
    control,
    watch,
    setValue
  } = form;

  const assignee = watch('assignee');
  const isCompleted = watch('isCompleted');

  const isAssigneeInited = useRef(false);

  useEffect(() => {
    if (isAssigneeInited.current) {
      return;
    }

    if (companyUsers.length) {
      isAssigneeInited.current = true;

      if (!assignee) {
        setValue(
          'assignee',
          companyUsers.find((companyUser) => companyUser.id === user.userId)
        );
      }
    }
  }, [assignee, companyUsers, user, setValue]);

  const toggleCompleted = useCallback(() => {
    setValue('isCompleted', !isCompleted);
    handleSubmit();
  }, [handleSubmit, isCompleted, setValue]);

  return (
    <Container>
      <Form rules={rules} onSubmit={handleSubmit}>
        <Header>
          <TitleInputWrapper>
            <InlineEditableInputField
              name="title"
              control={control}
              placeholder="Type here a Reminder title"
              onChanged={triggerSubmit}
              renderView={(value, onClick) => (
                <TitleView isDisabled={!canEdit} isPlaceholder={!value} onClick={canEdit && onClick}>
                  {value || 'Type here a Reminder title'}
                </TitleView>
              )}
            />
          </TitleInputWrapper>
          {isCompleted && (
            <Badge color="#009A47" bgColor="#CDF3DF">
              COMPLETED
            </Badge>
          )}
          <Button
            size={ButtonSize.Small}
            disabled={isSubmitting}
            variant={ButtonVariant.Flat}
            onClick={toggleCompleted}
          >
            {isCompleted ? <XIcon size="16px" /> : <Check size="16px" />}
            {isCompleted ? 'Mark as uncompleted' : 'Mark as completed'}
          </Button>
          <ContextMenu items={menuItems}>
            <MoreVerticalIcon size="24px" color="#9C9CAA" />
          </ContextMenu>
          <IconButton variant={ButtonVariant.Flat} onClick={onClose}>
            <XIcon size="24px" color="#9C9CAA" />
          </IconButton>
        </Header>
        <Content>
          <TwoFields>
            <FieldBlock isCentered>
              <FieldLabel>Type</FieldLabel>
              <InlineEditableSelectField
                name="type"
                control={control}
                onChanged={triggerSubmit}
                options={REMINDER_OPTIONS}
                getOptionLabel={(option) => option.name}
                renderOption={({ icon, name }) => (
                  <OptionWithIcon>
                    {icon.component}
                    <div>{name}</div>
                  </OptionWithIcon>
                )}
                renderView={(value, onClick) =>
                  value ? (
                    <SelectValueView
                      isDisabled={isAppointment || !canEdit}
                      onClick={!isAppointment && canEdit && onClick}
                    >
                      {!isAppointment && value.icon?.component}
                      <div>{isAppointment ? 'Appointment' : value.name}</div>
                    </SelectValueView>
                  ) : (
                    <SelectValueView isDisabled={!canEdit} onClick={canEdit && onClick}>
                      {canEdit ? 'Select type' : 'No Type'}
                    </SelectValueView>
                  )
                }
              />
            </FieldBlock>

            <div>
              <Button
                target="_blank"
                to={`/${companyId}/projects/${initialValues.project?.id}`}
                variant={ButtonVariant.Link}
              >
                <RecordTypeIcon recordType={initialValues.project?.type as RecordType} size="16px" />
                {initialValues.project?.title}
              </Button>
            </div>
          </TwoFields>
          <TwoFields>
            <FieldBlock isCentered>
              <FieldLabel>{isAppointment ? 'Date' : 'Due date'}</FieldLabel>
              <InlineEditableDateTimeField
                name="dueDate"
                control={control}
                isClearable
                onChanged={triggerSubmit}
                placeholder="No date"
                renderView={(value, onClick) =>
                  value ? (
                    <SelectValueView isDisabled={!canEdit} onClick={canEdit && onClick}>
                      <CalendarIcon size="16px" color="#9C9CAA" />
                      {formatDate(value)}
                    </SelectValueView>
                  ) : (
                    <SelectValueView isDisabled={!canEdit} onClick={canEdit && onClick}>
                      <CalendarIcon size="16px" color="#9C9CAA" />
                      <PlaceholderValue>{canEdit ? 'Select date' : 'No date'}</PlaceholderValue>
                    </SelectValueView>
                  )
                }
              />
            </FieldBlock>

            <FieldBlock isCentered>
              <FieldLabel>Assignee</FieldLabel>
              <InlineEditableSelectField
                name="assignee"
                isClearable={false}
                control={control}
                getOptionLabel={getFullName}
                onChanged={triggerSubmit}
                options={companyUsers}
                getOptionSelected={(option, value) => option.id === value.id}
                renderOption={(option) => (
                  <AvatarAndName>
                    <UserAvatar user={option} size={24} />
                    {getFullName(option)}
                  </AvatarAndName>
                )}
                renderView={(value, onClick) =>
                  value ? (
                    <AssigneeValueView isDisabled={!canEdit} onClick={canEdit && onClick}>
                      <AvatarAndName>
                        <UserAvatar user={value} size={24} />
                        {getFullName(value)}
                      </AvatarAndName>
                    </AssigneeValueView>
                  ) : (
                    <SelectValueView isDisabled={!canEdit} onClick={canEdit && onClick}>
                      <PlaceholderValue>{canEdit ? 'Select Assignee' : 'No Assignee'}</PlaceholderValue>
                    </SelectValueView>
                  )
                }
              />
            </FieldBlock>
          </TwoFields>

          <DescriptionWrapper>
            <InlineEditableRichTextField
              placeholder="Reminder description..."
              label="Description"
              name="description"
              control={control}
              onChanged={triggerSubmit}
              renderView={(value, onClick) => (
                <DescriptionView
                  isDisabled={!canEdit}
                  onClick={canEdit && onClick}
                  isPlaceholder={isQuilValueEmpty(value)}
                >
                  {!isQuilValueEmpty(value) ? (
                    <div className="ql-editor" dangerouslySetInnerHTML={{ __html: value }} />
                  ) : (
                    'Reminder description...'
                  )}
                </DescriptionView>
              )}
            />
          </DescriptionWrapper>
        </Content>
      </Form>
    </Container>
  );
};
