import React, { useCallback } from 'react';
import { FieldPath, FieldValues, useFieldArray } from 'react-hook-form';
import { Input } from '@kit/ui/Input';
import { TrashIcon } from '@kit/ui/icons/Trash';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { Plus } from 'react-feather';
import { FormControl, FormInputPropsToOmit } from '../../types';
import { InputField } from '../InputField';
import { AddButtonContainer, FieldWithButton, IconButton } from './styled';

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends Omit<React.ComponentProps<typeof Input>, FormInputPropsToOmit>,
    FormControl<TFieldValues, TName> {
  addButtonText: string;
  firstInputLabel?: string;
  validate?: (value: any) => string | undefined;
}

export const MultiInputField = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  name,
  control,
  addButtonText,
  label,
  firstInputLabel = label,
  validate,
  ...inputProps
}: Props<TFieldValues, TName>) => {
  const { fields, append, remove } = useFieldArray<TFieldValues>({
    control,
    name,
    rules: validate
      ? {
          validate
        }
      : undefined
  });

  const handleAdd = useCallback(() => {
    append({ value: '' });
  }, [append]);

  const handleRemove = useCallback(
    (index: number) => () => {
      remove(index);
    },
    [remove]
  );

  return (
    <div>
      {fields.map((field, index) => (
        <FieldWithButton>
          <InputField
            label={index === 0 ? firstInputLabel : label}
            key={field.id}
            name={`${name}.${index}.value`}
            control={control}
            {...inputProps}
          />
          {index > 0 && (
            <IconButton onClick={handleRemove(index)}>
              <TrashIcon size="16px" color="#D54855" />
            </IconButton>
          )}
        </FieldWithButton>
      ))}
      <AddButtonContainer>
        <Button onClick={handleAdd} variant={ButtonVariant.Flat} isUpperCase={false}>
          <Plus size="16px" />
          {addButtonText}
        </Button>
      </AddButtonContainer>
    </div>
  );
};
