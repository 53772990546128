import { useAppDispatch } from '@hooks/store';
import { useLocation } from '@reach/router';
import { logOut } from '@state/actions/authentication/authenticationAction';
import { noop } from 'lodash';
import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import { Badge } from '@kit/ui/Badge';
import { useDrawerOpenState } from '../MainMenu/useDrawerOpenState';
import { NavBar } from './NavBar';

const usePageTitle = () => {
  const location = useLocation();

  if (location.pathname.match(/\/inbox/)) {
    return 'Inbox';
  }

  if (location.pathname.match(/\/(scheduler|tasks|list)/)) {
    return 'Schedule';
  }

  if (location.pathname.match(/\/dispatcher/)) {
    return 'Dispatch';
  }

  if (location.pathname.match(/\/clients/)) {
    return 'Clients';
  }

  if (location.pathname.match(/\/projects/)) {
    return 'Projects';
  }

  if (location.pathname.match(/\/requests/)) {
    return 'Requests';
  }

  if (location.pathname.match(/\/workspace/)) {
    return 'Workspace settings';
  }

  if (location.pathname.includes('settings')) {
    return 'Profile settings';
  }

  if (location.pathname.match(/\/analytics/)) {
    return 'Analytics';
  }

  if (location.pathname.match(/\d+\/contacts\/?.*$/)) {
    return 'Contacts';
  }

  if (location.pathname.match(/\/import/)) {
    return 'Import';
  }

  if (location.pathname.match(/\/systems/)) {
    return (
      <Box display="flex" alignItems="center" gridGap={8}>
        Systems
        <Badge bgColor="#DBE5FF">Beta</Badge>
      </Box>
    );
  }

  return '';
};

export const TopBar = () => {
  const pageTitle = usePageTitle();
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useDrawerOpenState();

  const handleLogout = useCallback(() => {
    dispatch(logOut());
  }, [dispatch]);

  return (
    <NavBar
      name={pageTitle}
      onLogout={handleLogout}
      onSelectMenu={noop}
      toggleSideBar={() => setIsOpen((prev) => !prev)}
      isSidebarOpen={isOpen}
    />
  );
};
