import React, { useCallback, useEffect, useState } from 'react';

import { useFormikContext } from 'formik';
import { DateField } from '@common/index';
import { formatDateTime } from '@components/Scheduler/helpers/formatWorkOrderDates';
import moment from 'moment';
import { Calendar } from 'react-feather';
import { ViewContainer, Placeholder } from './styled';
import { useInlineField } from '../useInlineField';

interface Props {
  fieldName: string;
  allDayFieldName: string;
  filterDate?: (date: Date) => boolean;
  isReadOnly?: boolean;
}

export const DateTime = ({ fieldName, allDayFieldName, filterDate, isReadOnly = false }: Props) => {
  const { isEdit, setIsEdit } = useInlineField();
  const [isOpen, setIsOpen] = useState(false);

  const { values } = useFormikContext();

  useEffect(() => {
    if (isEdit) {
      setIsOpen(true);
    }
  }, [isEdit]);

  const toggleOpen = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  if (isEdit) {
    return (
      <DateField
        name={fieldName}
        allDayFieldName={allDayFieldName}
        showTimeSelect
        defaultNoon
        isOpen={isOpen}
        onClose={() => {
          toggleOpen();
          setIsEdit(false);
        }}
        onOpen={toggleOpen}
        overwrites={
          filterDate
            ? {
                filterDate
              }
            : undefined
        }
      />
    );
  }

  return (
    <ViewContainer isReadOnly={isReadOnly} onClick={isReadOnly ? undefined : () => setIsEdit(true)}>
      {values[fieldName] && (
        <>
          <Calendar color="#9C9CAA" size="16px" /> {formatDateTime(moment(values[fieldName]), values[allDayFieldName])}
        </>
      )}
      {!values[fieldName] && (
        <Placeholder>
          <Calendar size="16px" /> No date
        </Placeholder>
      )}
    </ViewContainer>
  );
};
