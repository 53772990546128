import React, { useMemo, useCallback, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import 'react-quill/dist/quill.snow.css';
import { set, noop, debounce, toString, isUndefined } from 'lodash';
import { useMount, usePrevious } from 'react-use';
import { useField } from 'formik';
import { convertTextToQuillFormat, isBrowser, isQuilValueEmpty } from '@utils';
import { ListSubheader } from '@common/ui/Select/styled';
import { InputLabel } from '@common/ui/InputLabel';
import { FieldError } from '@common/ui/FieldError';
import { Controller } from 'react-hook-form';
import { v4 as uuid } from '@lukeed/uuid';
import { Image, File as FileIcon, Play } from 'react-feather';

import { TrashIcon } from '@kit/ui/icons/Trash';
import { CircularProgress } from '@material-ui/core';
import { colors } from '@styles';
import { File } from '@generated/types/graphql';
import { formatVideoDuration, isVideo } from '@utils/files';
import { OptionItem } from './OptionItem';
import './mentions.css';
import {
  ActiveFileDropZone,
  AttachmentToolbarButton,
  FilesGrid,
  RemoveFileButton,
  Spinner,
  Thumbnail,
  ThumbnailContainer,
  VideoBadge,
  Wrapper,
  WrapperAsInput
} from './styled';
import { useDropFiles } from './useDropFiles';

class PreserveWhiteSpace {
  constructor(
    private quill: any,
    private options: {}
  ) {
    // eslint-disable-next-line no-param-reassign
    quill.container.style.whiteSpace = 'pre-line';
  }
}

let ReactQuill: any = null;

if (isBrowser) {
  // eslint-disable-next-line global-require
  ReactQuill = require('react-quill');
  // eslint-disable-next-line global-require
  require('./blots/mentionBlot');
  // eslint-disable-next-line global-require
  require('./blots/tokenBlot');
  // eslint-disable-next-line global-require
  require('./blots/links');
  // eslint-disable-next-line global-require
  ReactQuill.Quill.register('modules/mentions', require('quill-mention'));
  ReactQuill.Quill.register('modules/preserveWhiteSpace', PreserveWhiteSpace);
}

export type MentionEntity = {
  value: string;
  id: string | number;
  avatarUrl?: string;
  groupName?: string;
};

type GetModulesParams = {
  getMentions?: (searchTerm: string) => MentionEntity[];
  getTokens?: (searchTerm: string) => MentionEntity[];
  toolbarId: string;
};

const getModules = ({ getMentions, getTokens, toolbarId }: GetModulesParams) => {
  const isMention = Boolean(getMentions);
  const getter = getMentions || getTokens;

  return {
    mention: getter
      ? {
          allowedChars: /^[A-Za-z\s]*$/,
          blotName: isMention ? 'mention-strong' : 'token-strong',
          mentionDenotationChars: isMention ? ['@'] : ['/'],
          source: (searchTerm: string, renderList: (...params: any[]) => any, _denotationChar) => {
            const values = getter(searchTerm);

            if (searchTerm.length === 0) {
              renderList(values, searchTerm);
            } else {
              const search = searchTerm.toLowerCase();

              renderList(
                // eslint-disable-next-line no-bitwise
                values.filter(({ value }) => ~value.toLowerCase().indexOf(search)),
                searchTerm
              );
            }
          },
          renderItem: (item: MentionEntity) =>
            ReactDOMServer.renderToString(
              <>
                {item.groupName ? (
                  <ListSubheader className="cq-user-select-dropdown-group-name" key={item.groupName}>
                    {item.groupName}
                  </ListSubheader>
                ) : null}
                <OptionItem key={item.value} name={item.value} avatarUrl={item.avatarUrl} hideAvatar={!isMention} />
              </>
            ),
          positioningStrategy: 'fixed'
        }
      : undefined,
    preserveWhiteSpace: true,
    toolbar: {
      container: `#ql-toolbar-${toolbarId}`
    }
  };
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'mention',
  'mention-strong',
  'token-strong'
];

type QuillRef = { getEditor: () => any };
interface RichEditorProps {
  value?: string;
  error?: boolean;
  onChange: (value: string) => void;
  onFocus?: () => void;
  placeholder?: string;
  readOnly?: boolean;
  getMentions?: GetModulesParams['getMentions'];
  getTokens?: GetModulesParams['getTokens'];
  editorRef?: React.MutableRefObject<any>;
  style?: React.CSSProperties;
  hideToolbar?: boolean;
  hideToolbarCompletly?: boolean;
  formikMode?: boolean;
  isAttachmentAllowed?: boolean;
  attachments?: File[];
  onAttach?: (file: File) => void;
  onAttachmentsChange?: (files: File[]) => void;
}

export const RichEditor: React.FC<RichEditorProps> = (props) => {
  const {
    onChange,
    onFocus,
    value,
    error = false,
    placeholder = 'Please enter description...',
    readOnly = false,
    getMentions,
    getTokens,
    editorRef,
    hideToolbar,
    hideToolbarCompletly,
    formikMode,
    onBlur,
    className,
    isAttachmentAllowed,
    attachments = [],
    onAttach,
    onAttachmentsChange,
    openDrawer,
    ...rest
  } = props;

  const [toolbarId] = useState(() => uuid());

  const prevValue = usePrevious(value);
  const refFallback = useRef<QuillRef>();

  const ref = editorRef || refFallback;

  const { uploadingFiles, getRootProps, getInputProps, isDragActive, openFileDialog } = useDropFiles(
    (newAttachment) => {
      onAttach?.(newAttachment);
    }
  );

  const setValue = (newValue?: string) => {
    let editorInstance;

    try {
      editorInstance = ref.current?.getEditor();
    } catch {
      editorInstance = null;
    }

    if (editorInstance && !isUndefined(newValue) && prevValue !== newValue) {
      const selection = editorInstance.getSelection();

      editorInstance.setContents(
        editorInstance.clipboard.convert(convertTextToQuillFormat(toString(newValue))),
        'silent'
      );

      Promise.resolve().then(() => {
        if (selection) {
          const length = editorInstance.getLength();
          selection.index = Math.max(0, Math.min(selection.index, length - 1));
          selection.length = Math.max(0, Math.min(selection.length, length - 1 - selection.index));

          editorInstance.setSelection(selection);
        }
      });
    }
  };

  useMount(() => {
    setValue(value);
  });

  setValue(value);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChange = useCallback(
    debounce((newValue, event, type) => {
      if (type === 'api' || type === 'silent') {
        return;
      }

      const { ops = [] } = event;

      const theFirstInsertOperation = ops.find(({ insert }) => insert);
      const isNewLineOperation =
        ops.indexOf(theFirstInsertOperation) === ops.length - 1 && !!theFirstInsertOperation.insert.match?.(/^\n$/);
      const isTokenInsertionEvent = theFirstInsertOperation && toString(theFirstInsertOperation).match(/^(\s?[/@]$)/);
      // const isDeleteEvent = ops?.[0]?.retain && ops?.[1]?.delete === 1;

      if ((formikMode && isNewLineOperation) || isTokenInsertionEvent) {
        return;
      }

      onChange?.(newValue);
    }, 10),
    [onChange]
  );

  const modules = useMemo(() => getModules({ getMentions, getTokens, toolbarId }), [toolbarId, getMentions, getTokens]);

  const handleBlur = useCallback(
    (event: any, source: any) => {
      if (source === 'silent') {
        // ReactQuill triggers blur event when user pasted text into editor
        return;
      }
      onBlur?.(event, source);
    },
    [onBlur]
  );

  return (
    <Wrapper
      {...(isAttachmentAllowed ? getRootProps() : {})}
      hideToolbar={hideToolbar}
      hideToolbarCompletly={hideToolbarCompletly}
      className={className}
    >
      {isAttachmentAllowed && isDragActive && <ActiveFileDropZone>Drop file here</ActiveFileDropZone>}
      <ReactQuill
        ref={ref}
        theme="snow"
        modules={modules}
        formats={formats}
        defaultValue=""
        placeholder={placeholder}
        error={error}
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={handleBlur}
        readOnly={readOnly}
        {...rest}
      />

      <div id={`ql-toolbar-${toolbarId}`}>
        {isAttachmentAllowed && (attachments.length > 0 || uploadingFiles.length > 0) && (
          <FilesGrid>
            {attachments.map((file) => (
              <ThumbnailContainer
                key={file.id}
                onClick={() => {
                  openDrawer(
                    'doc',
                    file.id,
                    attachments.map((a) => a.id)
                  );
                }}
              >
                {file?.metaData?.thumbnailUrl ? (
                  <Thumbnail url={file.metaData.thumbnailUrl} />
                ) : (
                  <FileIcon size="16px" color="#9C9CAA" />
                )}

                {isVideo(file) && (
                  <VideoBadge color="#1d1d35" bgColor="#fff">
                    <Play size="12px" color="#1d1d35" />
                    {formatVideoDuration(file.metaData?.duration ?? 0)}
                  </VideoBadge>
                )}
                <RemoveFileButton
                  onClick={(e) => {
                    e.stopPropagation();
                    onAttachmentsChange(attachments.filter((a) => a.id !== file.id));
                  }}
                >
                  <TrashIcon size="16px" />
                </RemoveFileButton>
              </ThumbnailContainer>
            ))}

            {uploadingFiles.map((file, index) => (
              <ThumbnailContainer key={`${file.name}_${index}`}>
                <Spinner>
                  <CircularProgress size={16} style={{ color: colors.green }} />
                </Spinner>
              </ThumbnailContainer>
            ))}
          </FilesGrid>
        )}
        <button type="button" className="ql-bold" />
        <button type="button" className="ql-italic" />
        <button type="button" className="ql-underline" />
        <button type="button" className="ql-list" value="ordered" />
        <button type="button" className="ql-list" value="bullet" />
        <button type="button" className="ql-link" />
        {isAttachmentAllowed && (
          <>
            <AttachmentToolbarButton type="button" onClick={openFileDialog}>
              <Image size="16px" />
            </AttachmentToolbarButton>
            <input {...getInputProps()} />
          </>
        )}
      </div>
    </Wrapper>
  );
};

interface RichEditorFormikProps extends Omit<RichEditorProps, 'onChange'> {
  name: string;
  label?: string;
  onChange?: RichEditorProps['onChange'];
}

export const RichEditorFormik: React.FC<RichEditorFormikProps> = (props) => {
  const { label, name, onBlur, onFocus } = props;

  const [{ value }, meta, { setValue, setTouched }] = useField(props);
  const handleFocus = useCallback(() => {
    setTouched(true, false);
    onFocus?.();
  }, [setTouched, onFocus]);

  return (
    <>
      {label ? (
        <InputLabel htmlFor={name}>
          {label}
          {meta.error && meta.touched && <FieldError>{meta.error}</FieldError>}
        </InputLabel>
      ) : null}
      <RichEditor {...props} value={value} onChange={setValue} onFocus={handleFocus} onBlur={onBlur} formikMode />
    </>
  );
};

export const RichEditorAsInputFormik: React.FC<RichEditorFormikProps> = (props) => {
  const ref = useRef();
  const [{ value }] = useField(props);

  let editorInstance: any = null;
  try {
    editorInstance = ref.current?.getEditor();
  } catch {
    editorInstance = null;
  }

  set(editorInstance, 'keyboard.bindings[13][5].handler', noop);

  return (
    <WrapperAsInput isEmpty={isQuilValueEmpty(value)}>
      <RichEditorFormik {...props} editorRef={ref} hideToolbar hideToolbarCompletly formikMode />
    </WrapperAsInput>
  );
};

export const RichEditorReactForm: React.FC<RichEditorFormikProps> = (props) => {
  const { label, name } = props;

  return (
    <>
      <InputLabel>{label}</InputLabel>
      <Controller
        name={name}
        render={({ field }) => (
          <RichEditor {...props} value={field.value} onChange={field.onChange} onFocus={field.onBlur} formikMode />
        )}
      />
    </>
  );
};
