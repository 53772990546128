import React, { useCallback, useMemo } from 'react';
import {
  LineChart as RechartsLineChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Line
} from 'recharts';
import { formatCurrencyWithoutCents, formatNumber } from '@utils/utils';
import { formatDuration } from '@utils/dates';
import { BarLikeProps, Point } from '../types';
import { NoData } from './NoData';

const formatter = {
  number: formatNumber,
  currency: formatCurrencyWithoutCents,
  time: formatDuration
};

export const LineChart = ({ numberFormat = 'number', points, onClick }: BarLikeProps) => {
  const handleDotClick = useCallback(
    (_props: any, data: any) => {
      const point = data.payload as Point;

      onClick(point);
    },
    [onClick]
  );

  const leftMargin = useMemo(() => {
    const max = Math.max(...points.map((point) => point.y));

    const resultValue = formatter[numberFormat](max);

    return resultValue.length * 5;
  }, [points, numberFormat]);

  if (!points.length) {
    return <NoData />;
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <RechartsLineChart
        width={500}
        height={300}
        data={points}
        margin={{
          top: 16,
          right: 16,
          left: leftMargin,
          bottom: 32
        }}
      >
        <CartesianGrid strokeDasharray="4 4" />
        <XAxis tickLine={false} dataKey="x" stroke="#828D9A" />
        <YAxis
          allowDecimals={false}
          tickFormatter={formatter[numberFormat]}
          tickLine={false}
          axisLine={false}
          stroke="#828D9A"
        />
        <Tooltip formatter={formatter[numberFormat]} />
        <Line name="Value" type="monotone" dataKey="y" stroke="#009688" activeDot={{ onClick: handleDotClick }} />
      </RechartsLineChart>
    </ResponsiveContainer>
  );
};
