import { EditProperties as ProjectEditProperties } from '@features/ProjectPortfolio/Project/Overview/Properties/FullProperties';
import { colors, device, scroll } from '@styles';
import styled, { css } from 'styled-components';

export const Container = styled.div<{ isOneColumn: boolean }>`
  display: grid;

  grid-template-columns: 1fr;
  row-gap: 16px;

  ${device.md`
  gap: 16px;
  grid-template-columns: ${({ isOneColumn }) => (isOneColumn ? '1fr' : '1fr 1fr')};
  > * {
    ${({ isOneColumn }) => isOneColumn && 'grid-column: 1 / 3 !important;'})}
  }
  `};
`;

export const Widget = styled.div<{ size?: 'default' | 'large'; isFullWidth?: boolean }>`
  height: auto;
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  min-width: 0;
  max-height: ${({ size = 'default' }) => (size === 'default' ? '240px' : 'none')};
  border: 1px solid #dfdfe8;

  ${({ isFullWidth = false }) =>
    css`
      ${device.md`
            grid-column: ${isFullWidth ? '1 / 3' : 'auto'};
          `};
    `}
`;

export const WidgetDetailsButton = styled.span`
  color: ${colors.green};
  cursor: pointer;
  padding: 3px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;

  &:hover {
    background-color: ${colors.green};
    color: ${colors.white};
  }
`;

export const WidgetHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const WidgetTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

export const WidgetIcon = styled.div<{ backgroundColor: string }>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

export const Details = styled.div`
  background-color: #f7f7fc;
  position: fixed;
  top: 48px;
  right: 0;
  bottom: 0;
  padding: 16px;
  z-index: 2;
  margin-left: auto;
  width: 100%;

  ${device.md`
    top: 66px;
    position: absolute;
  `};
`;

export const DetailsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const DetailsTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
`;

export const DetailsContent = styled.div`
  ${scroll};
  overflow: hidden;
  max-height: calc(100% - 52px);
  height: 100%;
  display: flex;

  > * {
    flex: 1;
    max-width: 100%;
  }
`;

export const IconButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  color: ${colors.gray};
  &:hover {
    color: ${colors.black};
  }
`;

export const CreateWork = styled.div`
  margin-left: 4px;
  @media (min-width: 1600px) {
    margin-left: 16px;
  }

  .MuiButton-startIcon {
    width: initial;
    height: initial;
  }
`;

export const EditIconButton = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1d1d35;
  cursor: pointer;
  background-color: #dfdfe8;
`;

export const EditProperties = styled(ProjectEditProperties)`
  ${device.md`
  top: 66px;
  position: absolute;
  `};
`;
