import styled from 'styled-components';

export const Description = styled.div`
  font-size: 14px;
  margin-bottom: 24px;

  b {
    font-weight: 500;
  }
`;

export const TwoFields = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;

  > * {
    flex: 1;
  }
`;
