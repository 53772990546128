import { Property, RecordType } from '@types';
import { useCallback, useEffect, useMemo } from 'react';
import UserPreferences, { queryByRecord } from '@services/UserPreferences';
import { selectWorkspaceId } from '@state/selectors';
import { defaultOrdersByRecordType } from '@utils/properties';
import { ProjectViewType } from '@enums';
import { createGlobalState } from 'react-use';
import { useAppSelector } from './store';
import { useCompanyProperties } from './useCompanyProperties';
import { useCurrentSmartView } from './smartViews/useCurrentSmartView';

const applyOrder = (columns: Property[], order: { [id: number | string]: number }) => {
  return [...columns].sort((a, b) => {
    if (a.id in order && b.id in order) {
      return order[a.id] - order[b.id];
    }

    if (a.id in order) {
      return -1;
    }

    if (b.id in order) {
      return 1;
    }

    return +a.id - +b.id;
  });
};

const useUserPreferencesColumnsState = createGlobalState<{ [type in RecordType]: Property[] }>({
  [RecordType.PROJECT]: [],
  [RecordType.DEAL]: [],
  [RecordType.ACCOUNT]: []
});

const useChangedColumnsState = createGlobalState<{ [smartViewId: number]: Property[] }>({});

export const usePortfolioColumns = (recordType: RecordType) => {
  const companyId = useAppSelector(selectWorkspaceId);

  const currentSmartView = useCurrentSmartView();
  const { scopeToColumns } = useCompanyProperties();

  const [changedColumns, setChangedColumns] = useChangedColumnsState();

  const [userPreferredColumns, setUserPreferredColumns] = useUserPreferencesColumnsState();

  useEffect(() => {
    const userPreferredColumnsIds = UserPreferences.propertiesShown.get(companyId)?.[recordType]?.TABLE;
    const order = UserPreferences.propertiesOrder.get(queryByRecord({ companyId, recordType }));

    if (userPreferredColumnsIds?.length) {
      const userPreferredColumns = userPreferredColumnsIds
        .map((id) => scopeToColumns[recordType].find((c) => c.id === id))
        .filter(Boolean);
      setUserPreferredColumns((prev) => ({
        ...prev,
        [recordType]: order ? applyOrder(userPreferredColumns, order) : userPreferredColumns
      }));
    }
  }, [companyId, recordType, scopeToColumns, setUserPreferredColumns]);

  const handleUserPreferredColumnsChange = useCallback(
    (columns: number[]) => {
      if (!currentSmartView) {
        setUserPreferredColumns((prev) => ({
          ...prev,
          [recordType]: columns.map((id) => scopeToColumns[recordType].find((c) => c.id === id)).filter(Boolean)
        }));

        const previous = UserPreferences.propertiesShown.get(companyId) || {};

        // columns already in the order so we dont need separate storage for them
        UserPreferences.propertiesOrder.remove(queryByRecord({ companyId, recordType }));
        UserPreferences.propertiesShown.set(companyId, {
          ...previous,
          [recordType]: {
            [ProjectViewType.table]: columns
          }
        });
      } else {
        setChangedColumns((prev) => ({
          ...prev,
          [currentSmartView.id]: columns
            .map((id) => scopeToColumns[recordType].find((c) => c.id === id))
            .filter(Boolean)
        }));
      }
    },
    [companyId, recordType, currentSmartView, scopeToColumns, setUserPreferredColumns, setChangedColumns]
  );

  const currentColumns = useMemo(() => {
    if (!scopeToColumns[recordType]?.length) {
      return [];
    }

    if (currentSmartView?.type === recordType) {
      if (changedColumns[currentSmartView.id]) {
        return changedColumns[currentSmartView.id];
      }

      const { shownColumns, columnsOrder } = currentSmartView.conditions.properties ?? {};

      if (Array.isArray(shownColumns)) {
        const smartViewColumns = shownColumns
          .map((id) => scopeToColumns[recordType].find((c) => c.id === id))
          .filter(Boolean);

        return columnsOrder ? applyOrder(smartViewColumns, columnsOrder) : smartViewColumns;
      }
    }

    const defaultColumns = scopeToColumns[recordType].filter(({ id }) => typeof id === 'number' && id < 0);
    const sortedDefaultColumns = applyOrder(defaultColumns, defaultOrdersByRecordType[recordType]);

    return userPreferredColumns[recordType]?.length ? userPreferredColumns[recordType] : sortedDefaultColumns;
  }, [recordType, scopeToColumns, currentSmartView, userPreferredColumns, changedColumns]);

  return {
    currentColumns,
    saveUserPreferredColumns: handleUserPreferredColumnsChange
  };
};
