import React from 'react';
import { Avatar as MuiAvatar, makeStyles } from '@material-ui/core';
import { getAvatarColors } from '@utils/colors';
import { avatarStyles } from './styled';

interface Props {
  user: {
    id: number;
    firstName: string;
    lastName: string;
    avatarUrl: string | null;
  };
  size?: number;
}

const getFirstLetters = (str: string) => {
  if (!str) {
    return '';
  }
  const [first = '', second = ''] = str.split(' ');

  return [first[0], second[0]].filter(Boolean).join('');
};

const getLetters = (firstName: string, lastName: string) => {
  if (firstName && lastName) {
    return getFirstLetters(`${firstName} ${lastName}`);
  }

  return getFirstLetters(firstName ?? lastName);
};

export const Avatar = React.memo(({ user, size = 24 }: Props) => {
  const nameLetters = getLetters(user.firstName, user.lastName);
  const colors = getAvatarColors([user.firstName, user.lastName].filter(Boolean).join(' '));

  const styles = makeStyles(avatarStyles({ size, color: colors.bg, textColor: colors.text }))();

  return (
    <MuiAvatar classes={styles} src={user.avatarUrl} variant="rounded" size>
      {nameLetters}
    </MuiAvatar>
  );
});
