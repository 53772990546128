import React from 'react';
import { ArrowUp } from 'react-feather';
import { colors } from '@styles';

interface Props {
  priority: number;
  size?: string;
}

const DEFAULT_COLOR = '#FF9D23';

const COLOR_BY_PRIORITY: { [key: number]: string } = {
  1: colors.red,
  2: colors.yellow,
  3: colors.green
};

export const PriorityIcon = ({ priority, size = '16px' }: Props) => {
  return <ArrowUp size={size} color={COLOR_BY_PRIORITY[priority] ?? DEFAULT_COLOR} />;
};
