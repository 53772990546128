import React from 'react';
import { useWorkflow } from '@components/templates/Workspace/Automations/hooks';
import { AutomationFromApi } from '@types';
import { PaperTooltip } from '@common/ui';
import { useAppSelector } from '@hooks';
import { selectWorkspaceId } from '@state/selectors';
import { TooltipProps } from '@material-ui/core/Tooltip/Tooltip';
import { Zap } from 'react-feather';
import { IconAndCounter, TooltipAutomation, TooltipHeader, TooltipWrapper } from './styled';

interface TaskAutomationsZapProps {
  taskId: number;
  companyId?: number;
  onAutomationClick: (automation: AutomationFromApi) => void;
}

export const AutomationsZap = ({
  placement = 'top',
  automations,
  onClick,
  className
}: {
  placement?: TooltipProps['placement'];
  className?: string;
  automations: AutomationFromApi[];
  onClick: (automation: AutomationFromApi) => void;
}) => {
  const hasInactiveAutomations = automations.some((automation) => !automation.isActive);

  if (!automations.length) {
    return null;
  }

  return (
    <PaperTooltip
      placement={placement}
      interactive
      title={
        <TooltipWrapper>
          <TooltipHeader>Active automations</TooltipHeader>
          {automations
            .filter((automation) => automation.isActive)
            .map((automation) => (
              <TooltipAutomation
                key={automation.id}
                onClick={(e) => {
                  e.stopPropagation();
                  onClick(automation);
                }}
              >
                {automation.name}
                {automation.isActive && <Zap fill="#F7A902" color="#F7A902" size="16px" />}
              </TooltipAutomation>
            ))}

          {hasInactiveAutomations && (
            <>
              <TooltipHeader>Inactive automations</TooltipHeader>
              {automations
                .filter((automation) => !automation.isActive)
                .map((automation) => (
                  <TooltipAutomation
                    key={automation.id}
                    onClick={(e) => {
                      e.stopPropagation();
                      onClick(automation);
                    }}
                  >
                    {automation.name}
                  </TooltipAutomation>
                ))}
            </>
          )}
        </TooltipWrapper>
      }
    >
      <IconAndCounter className={className}>
        <Zap fill="#F7A902" color="#F7A902" size="16px" />
        {automations.length}
      </IconAndCounter>
    </PaperTooltip>
  );
};

export const TaskAutomationsZap: React.FC<TaskAutomationsZapProps> = (props) => {
  const { taskId, companyId: $companyId, onAutomationClick } = props;
  const guessCompanyId = useAppSelector(selectWorkspaceId);
  const companyId = $companyId || guessCompanyId;

  const {
    fetch: { data: automationsPage },
    fetchTaskAutomations: taskAutomations
  } = useWorkflow(companyId);

  if (!automationsPage) {
    return null;
  }

  const automations: AutomationFromApi[] = taskAutomations[taskId] || [];

  if (!automations.length) {
    return null;
  }

  const handleClickAutomation = (automation: AutomationFromApi) => {
    // navigate(getAutomationPage(companyId, automation.id));
    onAutomationClick(automation);
  };

  return <AutomationsZap automations={automations} onClick={handleClickAutomation} />;
};
