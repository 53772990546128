import styled from 'styled-components';
import { colors, scroll } from '@styles';

export const Container = styled.div`
  overflow: auto;
  max-height: calc(100% - 32px);
  ${scroll};
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 5px 4px 8px;
  border-bottom: 1px solid #dfdfe8;
  transition: background-color 0.15s ease-in-out;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color: #9c9caa;

  &:hover {
    background-color: #e8ecef;
  }

  svg {
    flex-shrink: 0;
  }

  &:last-child {
    border-bottom: 0;
    margin-left: auto;
  }

  > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
  }
`;

export const Title = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  color: ${colors.black};
  margin-right: auto;
`;

export const Meta = styled.div`
  color: #828d9a;
  font-size: 14px;
  font-weight: 400;
  flex-shrink: 0;
`;

export const FileImageThumbnail = styled.img<{ size: number }>`
  object-fit: fill;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 4px;
`;

export const Icon = styled.span`
  display: flex;
  justify-content: center;
`;

export const Placeholder = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #828d9a;
`;
