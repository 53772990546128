import React from 'react';

import { Container } from './styled';
import { Table } from './Table';
import { Header } from './Header';
import { StatusTiles } from './StatusTiles';

export const List = React.memo(() => {
  return (
    <Container>
      <Header />
      <StatusTiles />
      <Table />
    </Container>
  );
});
