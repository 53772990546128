import React, { useMemo } from 'react';
import { Input } from '@kit/ui/Input';
import { PropertyType, FilterOperators, VirtualPropertyType } from '@types';
import { isStage, isStatus } from '@utils/properties';
import { Status } from '@features/ProjectPortfolio/components/Status';
import { DropDownItem } from '@common/Selector/UserSelector/DropDownItem';
import { capitalize } from 'lodash';
import { getFullName } from '@utils/utils';
import { DateTimePicker } from '@kit/ui/DateTimePicker';
import { useAllCompaniesUsers } from '@hooks/useCompanyUsers';
import { useStageList } from '@hooks/workspace/stages/useStageList';
import { ANALYTICS_MODULE_RECORD_TYPE_MAP } from '@features/Analytics/constants';
import { useWorkOrderStatuses } from '@hooks/workOrders/useWorkOrderStatuses';
import { ProjectStatusOption } from './styled';
import { FilterFieldType } from '../types';
import { useDateRangeOptions } from '../useDateRangeOptions';
import { FilterValueProps } from './types';
import { SelectWrapper } from './SelectWrapper';

export const PropertyFilterValue = ({ module, filter, onChange }: FilterValueProps) => {
  const dateRangeOptions = useDateRangeOptions();
  const { data: companyUsers = [] } = useAllCompaniesUsers();
  const { data: stages = [] } = useStageList();

  const { data: workOrderStastuses = [] } = useWorkOrderStatuses();

  const workOrderStatusOptions = useMemo(() => {
    if (filter.field.type !== FilterFieldType.PROPERTY) {
      return [];
    }

    const { property } = filter.field;

    if (property.virtualType !== VirtualPropertyType.taskStatus) {
      return [];
    }

    return workOrderStastuses.filter((status) => property.additional?.values?.includes(status.id));
  }, [filter, workOrderStastuses]);

  const stageOptions = useMemo(() => {
    const recordType = ANALYTICS_MODULE_RECORD_TYPE_MAP[module];

    if (!recordType || !stages?.length) {
      return [];
    }

    return stages.filter((stage) => stage.scope === recordType);
  }, [module, stages]);

  const { field, operator, value } = filter;

  if (field.type !== FilterFieldType.PROPERTY) {
    return null;
  }

  const { property } = field;

  switch (property.type) {
    case PropertyType.Text:
    case PropertyType.Link: {
      return <Input value={value || ''} onChange={(e) => onChange(e.target.value)} />;
    }
    case PropertyType.Numeric: {
      return <Input type="number" value={value || ''} onChange={(e) => onChange(e.target.value)} />;
    }

    case PropertyType.Dropdown: {
      if (isStatus(property)) {
        return (
          <SelectWrapper
            getOptionLabel={(option) => option.label}
            value={value ?? []}
            onChange={(_e, value) => onChange(value)}
            isMulti
            renderOption={(option: any) => (
              <ProjectStatusOption>
                <Status status={option.id} />
                <div>{option.label}</div>
              </ProjectStatusOption>
            )}
            getOptionSelected={(option: any, value: any) => option.id === value?.id || option.id === value}
            options={(property.additional?.values || []).map((value) => ({
              label: capitalize(value.replace('_', ' ')),
              id: value
            }))}
          />
        );
      }

      if (isStage(property)) {
        return (
          <SelectWrapper
            getOptionLabel={(option) => option.name}
            value={value ?? []}
            onChange={(_e, value) => onChange(value)}
            isMulti
            options={stageOptions}
          />
        );
      }

      if (property.virtualType === VirtualPropertyType.taskStatus) {
        return (
          <SelectWrapper
            getOptionLabel={(option) => option.label}
            value={value ?? []}
            onChange={(_e, value) => onChange(value)}
            isMulti
            options={workOrderStatusOptions}
          />
        );
      }

      const isMulti = property.multiple || [FilterOperators.In, FilterOperators.NotIn].includes(filter.operator.op);

      return (
        <SelectWrapper
          value={value ?? (isMulti ? [] : null)}
          onChange={(_e, value) => onChange(value)}
          isMulti={property.multiple || [FilterOperators.In, FilterOperators.NotIn].includes(filter.operator.op)}
          options={property.additional?.values || []}
        />
      );
    }

    case PropertyType.Person: {
      return (
        <SelectWrapper
          isMulti
          value={value ?? []}
          onChange={(_e, value) => onChange(value)}
          options={companyUsers}
          getOptionLabel={getFullName}
          noOptionsText="User not found"
          renderOption={(option: any) => (
            <DropDownItem id={option.id} avatarUrl={option.avatarUrl} name={getFullName(option)} />
          )}
        />
      );
    }

    case PropertyType.Date:
    case PropertyType.DateTime: {
      if ([FilterOperators.Within, FilterOperators.NotWithin].includes(operator.op)) {
        return (
          <SelectWrapper
            getOptionLabel={(option) => option.name}
            options={dateRangeOptions}
            value={value ?? null}
            onChange={(_e, value) => onChange(value)}
          />
        );
      }

      return (
        <DateTimePicker
          placeholder="Select date"
          isClearable
          isOnlyDate={property.type === PropertyType.Date}
          withPortal
          value={value}
          onClose={onChange}
        />
      );
    }

    default:
      return null;
  }
};
