import React from 'react';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { Body, Footer } from './styled';

interface Props {
  onConfirmClosing: () => void;
  onCancelClosing: () => void;
  onSave: () => void;
}

export const ConfirmClosing = ({ onConfirmClosing, onCancelClosing, onSave }: Props) => {
  return (
    <>
      <Body>
        <p>
          There are <b>unsaved changes</b>. You can save your changes, keep editing, or leave & discard changes.
        </p>
      </Body>
      <Footer>
        <Button variant={ButtonVariant.Secondary} onClick={onConfirmClosing}>
          Leave & Discard
        </Button>
        <Button variant={ButtonVariant.Secondary} onClick={onCancelClosing}>
          Keep editing
        </Button>
        <Button variant={ButtonVariant.Primary} onClick={onSave}>
          Save changes
        </Button>
      </Footer>
    </>
  );
};
