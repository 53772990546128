import styled from 'styled-components';
import { defaultTheme } from '@themes';

export const Wrapper = styled.div`
  background: ${defaultTheme.colors.white};
  border: 1px solid ${defaultTheme.colors.gray3};
  border-radius: 4px;
  height: 40px;
  padding: 0 15px;
  display: flex;
  alignItems: center;
`;

export const slider = () => {
  return {
    // .MuiSlider-root	Styles applied to the root element.
    root: {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      boxSizing: 'border-box',
      padding: '0',
      height: '40px'
    },
    // .MuiSlider-colorPrimary	Styles applied to the root element if color="primary".
    colorPrimary: {
      color: defaultTheme.colors.green
    },
    // .MuiSlider-colorSecondary	Styles applied to the root element if color="secondary".
    colorSecondary: {},
    // .MuiSlider-marked	Styles applied to the root element if marks is provided with at least one label.
    marked: {},
    // .MuiSlider-vertical	Pseudo-class applied to the root element if orientation="vertical".
    vertical: {},
    // .Mui-disabled	Pseudo-class applied to the root and thumb element if disabled={true}.
    disabled: {},
    // .MuiSlider-rail	Styles applied to the rail element.
    rail: {
      height: 6,
      borderRadius: 6,
      backgroundColor: defaultTheme.colors.gray3,
      opacity: '1'
    },
    // .MuiSlider-track	Styles applied to the track element.
    track: {
      height: 6,
      borderRadius: 6,
    },
    // .MuiSlider-trackFalse	Styles applied to the track element if track={false}.
    trackFalse: {},
    // .MuiSlider-trackInverted	Styles applied to the track element if track="inverted".
    trackInverted: {},
    // .MuiSlider-thumb	Styles applied to the thumb element.
    thumb: {
      width: '20px',
      height: '20px',
      marginTop: 0,
      marginLeft: -10,
      backgroundColor: '#fff',
      border: '2px solid #E4E4E4',
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
      '&:after': {

      }
    },
    // .MuiSlider-thumbColorPrimary	Styles applied to the thumb element if color="primary".
    thumbColorPrimary: {},
    // .MuiSlider-thumbColorSecondary	Styles applied to the thumb element if color="secondary".
    thumbColorSecondary: {},
    // .MuiSlider-active	Pseudo-class applied to the thumb element if it's active.
    active: {},
    // .Mui-focusVisible	Pseudo-class applied to the thumb element if keyboard focused.
    focusVisible: {},
    // .MuiSlider-valueLabel	Styles applied to the thumb label element.
    valueLabel: {},
    // .MuiSlider-mark	Styles applied to the mark element.
    mark: {},
    // .MuiSlider-markActive	Styles applied to the mark element if active (depending on the value).
    markActive: {},
    // .MuiSlider-markLabel	Styles applied to the mark label element.
    markLabel: {},
    // .MuiSlider-markLabelActive	Styles applied to the mark label element if active (depending on the value).
    markLabelActive: {},
  };
};
