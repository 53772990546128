// Copy-pasted from be-utils/TriggerEnum
export enum Trigger {
  TASK_COMPLETED = 'TASK_COMPLETED',
  TASK_STATUS_MOVEMENT = 'TASK_STATUS_MOVEMENT',
  DEAL_MOVEMENT = 'DEAL_MOVEMENT',
  PROJECT_MOVEMENT = 'PROJECT_MOVEMENT',
  PROJECT_SLA_VIOLATION = 'PROJECT_SLA_VIOLATION',
  DEAL_SLA_VIOLATION = 'DEAL_SLA_VIOLATION',
  APPOINTMENT_CREATED = 'APPOINTMENT_CREATED',
  APPOINTMENT_RESCHEDULED = 'APPOINTMENT_RESCHEDULED',
  CALL_UPSERTED = 'CALL_UPSERTED',
  PROJECT_PROPERTY_UPDATED = 'PROJECT_PROPERTY_UPDATED',
  DEAL_PROPERTY_UPDATED = 'DEAL_PROPERTY_UPDATED',
  PROJECT_STATUS_MOVEMENT = 'PROJECT_STATUS_MOVEMENT',
  DEAL_STATUS_MOVEMENT = 'DEAL_STATUS_MOVEMENT'
}

// Copy-pasted from be-utils/ActionEnum
export enum Action {
  SEND_EMAIL = 'SEND_EMAIL',
  SEND_SMS = 'SEND_SMS',
  UPDATE_PROPERTY = 'UPDATE_PROPERTY',
  CREATE_PROJECT = 'CREATE_PROJECT',
  ASSIGN_TASK = 'ASSIGN_TASK',
  REPLACE_ASSIGNEE = 'REPLACE_ASSIGNEE',
  CREATE_TASK = 'CREATE_TASK',
  CALL_WEBHOOK = 'CALL_WEBHOOK',
  CREATE_REMINDER = 'CREATE_REMINDER'
}

// Copy-pasted from be-utils/RequiredForDynamicEnum
export enum RequiredForDynamic {
  PROJECT_ID = 'projectId',
  TASK_ID = 'taskId',
  STAGE_ID = 'stageId',
  COMPANY_ID = 'companyId'
}

// Copy-pasted from be-utils/InputTypeEnum
export enum InputType {
  TEXT = 'text',
  EMAIL = 'email',
  USER_SELECTOR = 'user-selector',
  NUMBER = 'number',
  HTML = 'html',
  DATETIME = 'datetime',
  OPTIONS_DYNAMIC = 'options-dynamic',
  CHECKBOX = 'checkbox',
  PROPERTY_SELECTOR = 'property-selector',
  PROPERTY_INPUT = 'property-input',
  CUSTOM = 'custom',
  TEST_BUTTON = 'test-button',
  /** Supposed to be rendered as a select of provided static options */
  OPTIONS_STATIC = 'options-static',
  /** Supposed to be rendered as a radio choice of provided **few** static options */
  CHOICE_STATIC = 'choice-static'
}

// Copy-pasted from be-utils/WorkflowTypeEnum
export enum AutomationStepType {
  ACTION = 'ACTION',
  TRIGGER = 'TRIGGER'
}

// Copy-pasted from be-utils/DynamicEntity
export enum DynamicEntity {
  TASK = 'TASK',
  STAGE = 'STAGE',
  TASK_STATUS = 'TASK_STATUS',
  PROJECT_STAGE = 'PROJECT_STAGE',
  DEAL_STAGE = 'DEAL_STAGE',
  PROJECT_WORKFLOW = 'PROJECT_WORKFLOW',
  EMAIL_FROM = 'EMAIL_FROM',
  PHONE_FROM = 'PHONE_FROM',
  SLA_TYPE = 'SLA_TYPE',
  TASK_TEMPLATE = 'TASK_TEMPLATE'
}

export enum StepFieldKeys {
  TO_EMAIL = 'to_email',
  CC = 'cc',
  BCC = 'bcc',
  ASSIGNEE_IDS = 'assigneeIds',
  BODY = 'body',
  SUBJECT = 'subject',
  MESSAGE = 'message',
  PHONE = 'phone',
  TASK_ID = 'taskId',
  TEMPLATE_TASK_ID = 'templateTaskId',
  SEND_FORM_ATTACHMENT = 'sendFormAttachment',
  FROM_STAGE_ID = 'fromStageId',
  TO_STAGE_ID = 'toStageId',
  STAGE_ID = 'stageId',
  SLA_TYPE = 'slaType',
  COLUMN_ID = 'columnId',
  COLUMN_VALUE = 'columnValue',
  RECORD_TYPE = 'recordType',
  DESCRIPTION = 'description',
  DUE_DATE_NUMBER = 'dueDateNumber',
  DUE_DATE_UNIT = 'dueDateUnit'
}
