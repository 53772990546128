import { createAction } from 'redux-actions';
import { api } from '@services/api/companies';
import { AsyncActionReturn } from '@state/reducers/types';
import { isLoggedIn } from '@services/AuthenticationServices/AuthenticationService';

export const fetchCompaniesStart = createAction('@companies/FETCH_START');
export const fetchCompaniesSuccess = createAction('@companies/FETCH_SUCCESS');
export const fetchCompaniesFail = createAction('@companies/FETCH_FAIL');

export const updateCompanies = createAction('@companies/UPDATE');

export const fetchCompanies = (): AsyncActionReturn => async (dispatch) => {
  dispatch(fetchCompaniesStart());

  try {
    const { data } = await api.fetchCompanies();

    if (!isLoggedIn()) {
      const message = 'Error on companies fetch since user logout';
      console.error(new Error(message));
      dispatch(fetchCompaniesFail({
        message
      }));

      return;
    }

    dispatch(fetchCompaniesSuccess());
    dispatch(updateCompanies(data));
  } catch (error) {
    console.error(new Error(`Error on companies fetch \n${error}`));
    dispatch(fetchCompaniesFail());
  }
};
