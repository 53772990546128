import React from 'react';

import { ActionStatus } from '@generated/types/graphql';
import { ACTION_ITEM_STATUS_COLORS } from '@styles';
import { Container } from './styled';

interface Props {
  status: ActionStatus;
  className?: string;
  isArchived?: boolean;
}

export const ACTION_STATUS_LABELS: Record<ActionStatus, string> = {
  [ActionStatus.Requested]: 'Requested',
  [ActionStatus.InReview]: 'In review',
  [ActionStatus.Completed]: 'Completed',
  [ActionStatus.Cancelled]: 'Cancelled'
};

export const ActionItemStatus = ({ status, className, isArchived = false }: Props) => {
  const statusColor = ACTION_ITEM_STATUS_COLORS[status];

  if (isArchived) {
    return (
      <Container className={className} color="#778CA2" background="#DFDFE8">
        Archived
      </Container>
    );
  }

  return (
    <Container className={className} color={statusColor.color} background={statusColor.background}>
      {ACTION_STATUS_LABELS[status]}
    </Container>
  );
};
