import React from 'react';
import { File } from '@generated/types/graphql';
import { useAppSelector, useToast, useUploadFileMutation } from '@hooks';
import { Upload } from 'antd';
import { RcFile } from 'antd/es/upload';
import { MaxFileSize } from '@utils/files';
import { UploadCloud } from 'react-feather';
import { without } from 'lodash';
import { FilesList } from '@components/Property/File/FilesList';
import { FileListInput } from '@components/Property/File/styled';
import { selectWorkspaceId } from '@state/selectors';
import { Button, ButtonVariant } from '@kit/ui/Button';

type FilesPropertyEditorProps = {
  value?: File[];
  onChange: (files: File[]) => void;
};

export const FilesEditor: React.FC<FilesPropertyEditorProps> = (props) => {
  const { value, onChange } = props;

  const companyId = useAppSelector(selectWorkspaceId);

  const { showError } = useToast();
  const { upload } = useUploadFileMutation(companyId);

  // eslint-disable-next-line
  const handleUpload = async (file: RcFile) => {
    if (file.size > MaxFileSize) {
      showError(
        `The file you tried to upload is too big. The maximum file size is ${MaxFileSize / 1024 / 1024} megabytes.`
      );

      return false;
    }

    const uploaded = await upload.mutateAsync({ file });
    onChange([...(value || []), uploaded]);
  };

  const handleRemove = (file: File) => onChange(without(value, file));

  return (
    <>
      <FileListInput>
        <FilesList files={value || []} onRemove={handleRemove} />
      </FileListInput>
      <Upload showUploadList={false} beforeUpload={handleUpload}>
        <Button variant={ButtonVariant.Flat}>
          <UploadCloud size="16px" />
          Upload file
        </Button>
      </Upload>
    </>
  );
};
