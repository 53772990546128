import React from 'react';
import { Skeleton } from '@material-ui/lab';
import styled from 'styled-components';
import { colors } from '@styles';

const Wrapper = styled.div`
  max-height: 172px;
  margin: 10px 10px 0 10px;
  border-radius: 4px;
  background-color: ${colors.white};
  padding: 5px 10px;
`;

const Body = styled.div`
  display: flex;
  align-items: center;
`;

export const FeedItemSkeleton: React.FC = () => (
  <Wrapper>
    <Skeleton animation="wave" width={180} height={30} />
    <Body>
      <Skeleton animation="wave" width={30} height={50} style={{marginRight: '10px'}} />
      <Skeleton animation="wave" width={250} height={50} />
    </Body>
  </Wrapper>
);
