import { Form, FormValidationRules, InputField, SelectField, useForm } from '@kit/components/Form';
import { Button, ButtonVariant } from '@kit/ui/Button';
import React, { useMemo } from 'react';
import { useFormMutations } from '@hooks/useForms';
import { useRecordStageList } from '@hooks/records/useRecordStageList';
import { useFormTemplateList } from '@hooks/templates/forms/useFormTemplateList';
import { useUpdateEffect } from '@react-hookz/web';
import { Body, Footer } from './styled';

interface Props {
  recordId: number;
  initialSection?: { name: string; id: number };
  onClose: () => void;
}

type FormValues = {
  name: string;

  template: {
    id: number;
    name: string;
  };
  section: {
    id: number;
    name: string;
  };
};

const rules: FormValidationRules<FormValues> = {
  name: {
    isRequired: true
  },
  template: {
    isRequired: true
  },
  section: {
    isRequired: true
  }
};

const OTHER_SECTION = { name: 'Other', id: -1 };

export const CreateFormForm = ({ initialSection, recordId, onClose }: Props) => {
  const {
    create: { mutateAsync: createForm }
  } = useFormMutations();
  const { data: stages = [] } = useRecordStageList(recordId);

  const { data: formTemplates = [] } = useFormTemplateList();

  const sectionOptions = useMemo(() => {
    return [...stages, OTHER_SECTION];
  }, [stages]);

  const postForm = async ({ name, template, section }: FormValues) => {
    await createForm({
      dto: {
        name,
        templateId: template.id,
        stageId: section.id > 0 ? section.id : null,
        projectId: recordId,
        isTemplate: false
      }
    });

    onClose();
  };

  const { form, handleSubmit } = useForm({
    onSubmit: postForm,
    defaultValues: {
      section: initialSection ?? OTHER_SECTION
    }
  });

  const selectedTemplate = form.watch('template');

  useUpdateEffect(() => {
    form.setValue('name', selectedTemplate.name);
  }, [selectedTemplate]);

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <Body>
        <SelectField
          getOptionLabel={(option) => option.name}
          options={formTemplates}
          control={form.control}
          name="template"
          label="Template"
        />
        <InputField control={form.control} name="name" label="Form name" />
        {stages.length > 0 && (
          <SelectField
            getOptionLabel={(option) => option.name}
            options={sectionOptions}
            control={form.control}
            name="section"
            label="Add to"
          />
        )}
      </Body>
      <Footer>
        <Button variant={ButtonVariant.Secondary} onClick={onClose}>
          Cancel
        </Button>
        <Button variant={ButtonVariant.Primary} type="submit">
          Create
        </Button>
      </Footer>
    </Form>
  );
};
