import { Tooltip } from '@material-ui/core';
import React from 'react';
import { AccountStatus } from '@types';
import { STATUS_CONFIG } from '@features/ClientPortfolio/constants';
import { StatusLine, TooltipContent } from './styled';

interface Props {
  status: AccountStatus;
}

export const Status = ({ status }: Props) => {
  const { color, label } = STATUS_CONFIG[status];

  return (
    <Tooltip
      title={
        <TooltipContent>
          <StatusLine color={color} /> <div>{label}</div>
        </TooltipContent>
      }
    >
      <StatusLine color={color} />
    </Tooltip>
  );
};
