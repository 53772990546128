import { ListItem } from '@material-ui/core';
import { colors } from '@styles';
import styled from 'styled-components';

export const MoreMenu = styled.div`
  flex-shrink: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  color: ${colors.gray};
  &:hover {
    color: ${colors.black};
  }

  svg {
    flex-shrink: 0;
  }
`;

export const MenuItem = styled(ListItem)`
  &&& {
    font-size: 14px;
    font-weight: 500;
    padding: 4px 8px;

    .MuiListItemIcon-root {
      min-width: 26px;
    }
  }
`;
