import React, { useEffect, useMemo, useRef } from 'react';
import { CommentForm } from '@features/ClientPortfolio/Client/CreateEventsPanel/forms/CommentForm';
import { QueryParamsEnum, useQueryParam, useQueryParamMutation } from '@hooks/useQueryParam';
import { useIntersectionObserver } from '@react-hookz/web/esm/useIntersectionObserver';
import { useUnmountEffect, useUpdateEffect } from '@react-hookz/web';
import { useQueryClient } from 'react-query';
import { useActivity } from '@hooks/useActivity';
import { ReactQueryKey } from '@enums';
import { flatPagesData } from '@utils/reactQuery';
import { StateMessege } from '@common/index';
import { FeedItem } from '@features/ProjectPortfolio/Project/History/FeedItem';
import { CommentType } from '@generated/types/graphql';
import { PinnedComments } from '@features/ProjectPortfolio/Project/History/PinnedComments';
import { FeedContainer, FormContainer, FeedContainerInner, FeedEvents } from './styled';

interface Props {
  clientId?: number;
  systemId: number;
  search: string;
}

export const Comments = ({ clientId, systemId, search }: Props) => {
  const loadingNodeRef = useRef<HTMLDivElement>();
  const loadingTopNodeRef = useRef<HTMLDivElement>();

  const loadingRef = useRef<boolean>(false);
  const loadingPrevRef = useRef<boolean>(false);

  const isLoadPreviousEnabled = useRef<boolean>(true);
  const isScrolledToFeedItem = useRef<boolean>(false);

  const [feedCursor] = useQueryParam(QueryParamsEnum.FeedCursor);
  const [feedId] = useQueryParam(QueryParamsEnum.FeedId);

  const entry = useIntersectionObserver(loadingNodeRef.current);
  const topEntry = useIntersectionObserver(loadingTopNodeRef.current);

  const { setParams } = useQueryParamMutation();

  const queryClient = useQueryClient();

  const { data, isLoading, fetchNextPage, fetchPreviousPage, hasNextPage, hasPreviousPage } = useActivity({
    queryKey: ReactQueryKey.SystemActivity,
    systemId,
    search,
    equalOrBeforeDate: feedCursor ? decodeURIComponent(feedCursor) : undefined
  });

  useUpdateEffect(() => {
    if (feedCursor) {
      queryClient.removeQueries([ReactQueryKey.ProjectActivity]);
    }
  }, [feedCursor, queryClient]);

  useUnmountEffect(() => {
    if (feedCursor) {
      queryClient.removeQueries([ReactQueryKey.ProjectActivity]);

      setParams(
        {
          [QueryParamsEnum.FeedCursor]: undefined,
          [QueryParamsEnum.FeedId]: undefined
        },
        true
      );
    }
  });

  const flatFeed = useMemo(() => {
    return flatPagesData(data);
  }, [data]);

  useEffect(() => {
    if (entry?.isIntersecting && hasNextPage) {
      if (!loadingRef.current) {
        loadingRef.current = true;
        fetchNextPage().finally(() => {
          loadingRef.current = false;
        });
      }
    }
  }, [entry, hasNextPage, data, fetchNextPage]);

  useEffect(() => {
    if (feedId && feedCursor && topEntry?.isIntersecting && hasPreviousPage && isLoadPreviousEnabled.current) {
      if (!loadingPrevRef.current) {
        loadingPrevRef.current = true;
        fetchPreviousPage().finally(() => {
          loadingPrevRef.current = false;

          const node = document.getElementById(`feed_${feedId}`);

          if (node && !isScrolledToFeedItem.current) {
            isLoadPreviousEnabled.current = false;
            node.scrollIntoView();
            isScrolledToFeedItem.current = true;

            setTimeout(() => {
              isLoadPreviousEnabled.current = true;
            }, 1000);
          }
        });
      }
    }
  }, [feedId, feedCursor, topEntry, flatFeed, data, fetchPreviousPage, hasPreviousPage]);

  return (
    <FeedContainer>
      <FormContainer>
        <CommentForm
          type={CommentType.System}
          recordId={clientId}
          systemId={systemId}
          size="small"
          expandSize="small"
        />
      </FormContainer>
      <FeedContainerInner>
        <FeedEvents>
          <PinnedComments systemId={systemId} />
          {flatFeed.length === 0 && !isLoading ? (
            <StateMessege subTitle="Looks like there's no activity here yet." />
          ) : (
            <>
              <p ref={loadingTopNodeRef} />
              {flatFeed.map((item) => (
                <FeedItem contextEntityId={systemId} key={item.id} item={item} context="system" />
              ))}

              <p ref={loadingNodeRef} />
            </>
          )}
        </FeedEvents>
      </FeedContainerInner>
    </FeedContainer>
  );
};
