import { Invoice, InvoiceStatus } from '@generated/types/graphql';
import { formatCurrency } from '@utils/utils';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { ContextMenu, MenuItem } from '@kit/components/ContextMenu';
import { useConfirmDeleteModal, useModal } from '@common/PromiseModal';
import { Edit2 } from 'react-feather';
import { TrashIcon } from '@kit/ui/icons/Trash';
import { useDeleteInvoice } from '@hooks/invoices/useDeleteInvoice';
import { Link } from '@reach/router';
import { RecordType } from '@types';
import { useAppSelector } from '@hooks/store';
import { selectWorkspaceId } from '@state/selectors';
import { Form } from './Form';
import { Status } from './Status';

interface Props {
  recordId: number;
  invoice: Invoice;
}

const RECORD_TYPE_TO_PATH = {
  [RecordType.ACCOUNT]: 'clients',
  [RecordType.PROJECT]: 'projects',
  [RecordType.DEAL]: 'requests'
};

export const InvoiceRow = ({ recordId, invoice }: Props) => {
  const { openModal } = useModal();
  const confirmDelete = useConfirmDeleteModal();

  const companyId = useAppSelector(selectWorkspaceId);

  const { mutateAsync: deleteInvoice } = useDeleteInvoice();

  const handleClick = useCallback(() => {
    openModal<void>(
      ({ onClose }) => <Form invoice={invoice} recordId={recordId} isBill={invoice.isBill} onClose={onClose} />,
      { title: `Edit ${invoice.isBill ? 'Bill' : 'Invoice'}` }
    );
  }, [openModal, invoice, recordId]);

  const menuItems = useMemo<MenuItem[]>(() => {
    return [
      {
        icon: <Edit2 size="16px" color="#9C9CAA" />,
        title: 'Edit',
        onClick: () => {
          handleClick();
        }
      },
      {
        icon: <TrashIcon size="16px" color="#D54855" />,
        title: 'Delete',
        onClick: async () => {
          if (
            await confirmDelete(
              `Are you sure you want to delete ${invoice.isBill ? 'bill' : 'invoice'} "#${invoice.uid}: ${
                invoice.title
              }"?`
            )
          ) {
            await deleteInvoice({ id: invoice.id });
          }
        }
      }
    ];
  }, [handleClick, invoice, confirmDelete, deleteInvoice]);

  const isOverdue = useMemo(() => {
    return invoice.status !== InvoiceStatus.Paid && moment(invoice.dueDate).isBefore(moment(), 'day');
  }, [invoice]);

  return (
    <tr onClick={handleClick}>
      <td>
        <div className="title">
          <span>#{invoice.uid}:</span> {invoice.title}
        </div>
        {invoice.project.id !== recordId && (
          <Link
            onClick={(e) => e.stopPropagation()}
            to={`/${companyId}/${RECORD_TYPE_TO_PATH[invoice.project.type]}/${invoice.project.id}`}
          >
            {invoice.project.title}
          </Link>
        )}
      </td>
      <td>{formatCurrency(+invoice.amount)}</td>
      <td className={isOverdue ? 'overdue' : undefined}>
        {formatCurrency(+invoice.amount - +invoice.amountPaid)}
        <div className="date">{moment(invoice.dueDate).format('MM/DD/YYYY')}</div>
      </td>
      <td className="status">
        <Status status={invoice.status} />
      </td>
      <td
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <ContextMenu items={menuItems} />
      </td>
    </tr>
  );
};
