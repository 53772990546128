import React from 'react';
import { AlertType } from './types';
import { Container, Title, Description } from './styled';
import { ALERT_ICONS } from './const';

interface Props {
  title: string;
  description?: string;
  type: AlertType;
  actions?: React.ReactNode;
}

export const Alert = ({ title, description, type, actions }: Props) => {
  return (
    <Container type={type}>
      <div>
        <Title>
          {ALERT_ICONS[type]}
          {title}
        </Title>

        {Boolean(description) && <Description>{description}</Description>}
      </div>
      {actions}
    </Container>
  );
};
