import { fonts } from './fonts';

export const typefaces = {
  title1: `500 20px/1.25 ${fonts.primary}`,
  title2: `500 18px/1.25 ${fonts.primary}`,
  subtitle: `500 14px/1.25 ${fonts.primary}`,
  subtitleLight: `400 14px/1.25 ${fonts.primary}`,
  caption: `500 10px/1.25 ${fonts.primary}`,
  text: `500 12px/1.25 ${fonts.primary}`,
  textLight: `400 12px/1.25 ${fonts.primary}`,
  pagination: `400 14px/1.25 ${fonts.primary}`,
  counter: `400 8px/1.25 ${fonts.primary}`
};
