import React from 'react';
import { ProjectStageType } from '@types';
import { Badge } from '@common/Badge';
import { RECORD_STAGE_TYPE_COLORS } from '@styles';

interface Props {
  stage: { type: ProjectStageType | string; name: string };
  styles?: any;
  className?: string;
}

export const ProjectStageBadge = ({ stage, styles, className }: Props) => (
  <Badge
    color={RECORD_STAGE_TYPE_COLORS[stage.type as ProjectStageType].color}
    bgColor={RECORD_STAGE_TYPE_COLORS[stage.type as ProjectStageType].background}
    style={styles}
    className={className}
  >
    <span>{stage.name}</span>
  </Badge>
);
