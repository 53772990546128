import styled from 'styled-components';
import { Grid, LinearProgress } from '@material-ui/core';
import { colors, scroll } from '@styles';

export const GroupHeader = styled.header`
  display: flex;
  align-items: center;
  padding: 12px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderDark};
  font: ${({ theme }) => theme.typefaces.subtitle};
  background-color: ${({ theme }) => theme.colors.gray2Bg};
`;

export const BodyWrapper = styled.div`
  max-height: 100%;
`;

export const GroupHeaderTitle = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const GroupBody = styled(Grid)`
  && {
    padding: 10px;
    justify-content: normal;
    margin: 0;
    max-width: 100%;
    overflow-x: auto;
  }
`;
export const IconWrapper = styled.div`
  margin-right: 8px;
  width: 20px;
  height: 20px;
`;

export const Header = styled(Grid)`
  background-color: ${({ theme }) => theme.colors.white};
  align-items: center;
  padding: 8px 16px;
  border-bottom: 1px solid ${colors.gray3};
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
`;

export const AnalyticCell = styled.div`
  width: 100%;
  padding: 12px;
`;

export const AnalyticHeaderValue = styled.div<{ isSuccess?: boolean; isNegative?: boolean }>`
  height: 20px;
  font-weight: 500;
  font-size: 16.0647px;
  color: ${({ theme, isSuccess, isNegative }) => {
    if (isNegative) {
      return theme.colors.red;
    }

    return isSuccess ? theme.colors.green : theme.colors.black;
  }};
`;

export const AnalyticHeaderName = styled.div`
  height: 16px;
  font-weight: 500;
  font-size: 12.8518px;
  color: ${({ theme }) => theme.colors.gray};
`;

export const AnalyticBarName = styled.div<{ centric?: boolean }>`
  font-weight: 400;
  font-size: 9.63884px;
  color: ${({ theme }) => theme.colors.gray};
  ${({ centric }) => (centric ? `text-align: center;` : '')}
`;

export const AnalyticBarValue = styled.div<{ centric?: boolean }>`
  font-weight: 500;
  font-size: 9.63884px;
  color: ${({ theme }) => theme.colors.black};
  ${({ centric }) => (centric ? `text-align: center;` : '')}
`;

export const AnalyticsContainer = styled(Grid)`
  background-color: ${({ theme }) => theme.colors.white};
`;

export const AnalyticTable = styled.table`
  width: 100%;
  margin-top: 20px;
`;

export const CellValue = styled.span`
  color: ${({ theme }) => theme.colors.black};
`;

export const StyledLinearProgress = styled(LinearProgress)`
  && {
    height: 25px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colors.gray4};

    .MuiLinearProgress-bar {
      background-color: ${({ theme }) => theme.colors.green};
    }
  }
`;

export const Wrapper = styled.div`
  overflow-y: auto;
  ${scroll};
`;
