import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Calendar, ChevronLeft, ChevronRight } from 'react-feather';
import { InputAdornment } from '@material-ui/core';

import moment from 'moment';
import { Input } from '@kit/ui/Input';
import { setHoursToNoon } from '@utils/dates';
import { IconButton } from '@common/ui';
import { Container, DatePickerFooter, DatePickerWrapper, TimePickerWrapper } from './styled';
import { XIcon } from '../icons/X';

interface TimeSelectProps {
  value: Date;
  onChange: (value: Date) => void;
  excludeTimes?: Date[];
}

const MIDNIGHT = new Date();
MIDNIGHT.setDate(0);
MIDNIGHT.setHours(0);
MIDNIGHT.setMinutes(0);

const TimeSelect = ({ value, onChange, excludeTimes }: TimeSelectProps) => {
  return (
    <DatePicker
      selected={value}
      onChange={onChange}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={30}
      timeCaption="Time"
      dateFormat="h:mm aa"
      excludeTimes={excludeTimes}
      calendarContainer={TimePickerWrapper}
    />
  );
};

interface Props {
  initialIsOpen?: boolean;
  placeholder?: string;
  value: Date;
  onClose: (date: Date) => void;
  isDisabled?: boolean;
  name?: string;
  isOnlyDate?: boolean;
  withPortal?: boolean;
  isClearable?: boolean;
  filterDate?: (date: Date) => boolean;
  fixHoursToNoon?: boolean;
}

const formatValue = (date: Date, startTime: Date, isOnlyDate: boolean) => {
  if (!date) {
    return '';
  }

  if (isOnlyDate) {
    return moment(date).format('MM/DD/YYYY');
  }

  return `${moment(date).format('MM/DD/YYYY')} ${moment(startTime).format('h:mmA')}`;
};

const prepareValue = (value: string | Date | null | undefined) => {
  if (!value) {
    return null;
  }

  if (typeof value === 'string') {
    const date = new Date(value);

    if (Number.isNaN(date.getTime())) {
      return null;
    }

    return date;
  }

  if (!(value instanceof Date)) {
    return null;
  }

  return value;
};

export const DateTimePicker = ({
  initialIsOpen = false,
  value,
  placeholder = 'Select date and time',
  onClose,
  isDisabled = false,
  name,
  isOnlyDate = false,
  withPortal = false,
  isClearable = false,
  fixHoursToNoon = true,
  filterDate
}: Props) => {
  const [isOpen, setIsOpen] = useState(initialIsOpen);
  const [date, setDate] = useState(prepareValue(value));
  const [startTime, setStartTime] = useState(prepareValue(value) ?? setHoursToNoon(new Date()));

  useEffect(() => {
    setDate(prepareValue(value));
    setStartTime(prepareValue(value) ?? setHoursToNoon(new Date()));
  }, [value]);

  const handleChange = useCallback((newValue: Date) => {
    setDate(newValue);
  }, []);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleStartTimeChange = useCallback((newTime: Date) => {
    setStartTime(newTime);
  }, []);

  const handleClose = useCallback(() => {
    if (!date) {
      setIsOpen(false);
      onClose(null);

      return;
    }
    const start = new Date(date);
    if (isOnlyDate) {
      setIsOpen(false);

      onClose(fixHoursToNoon ? setHoursToNoon(start) : start);

      return;
    }

    start.setHours(startTime.getHours());
    start.setMinutes(startTime.getMinutes());

    setIsOpen(false);
    onClose(start);
  }, [onClose, date, startTime, isOnlyDate, fixHoursToNoon]);

  const handleClear = useCallback(
    (e: any) => {
      e.stopPropagation();
      setDate(null);
      onClose(null);
    },
    [onClose]
  );

  const handleClickOutside = useCallback(
    (e: any) => {
      if (!initialIsOpen) {
        // onCalendarClose will be triggered instead
        return;
      }

      const isClickInside = e.target.closest('.date-field-input');

      if (!isClickInside) {
        handleClose();
      }
    },
    [handleClose, initialIsOpen]
  );

  const CalendarContainer = useMemo(
    () =>
      // eslint-disable-next-line
      ({ children }: { children: React.ReactNode }) => {
        return (
          <DatePickerWrapper>
            {children}
            {!isOnlyDate && (
              <DatePickerFooter>
                <TimeSelect value={startTime} onChange={handleStartTimeChange} />
              </DatePickerFooter>
            )}
          </DatePickerWrapper>
        );
      },
    [startTime, handleStartTimeChange, isOnlyDate]
  );

  const InputComponent = useMemo(
    () =>
      // eslint-disable-next-line
      forwardRef<
        HTMLInputElement,
        {
          onClick: React.MouseEventHandler;
        }
      >(({ onClick }, ref) => {
        return (
          <Input
            className="date-field-input"
            onClick={onClick}
            ref={ref}
            value={formatValue(date, startTime, isOnlyDate)}
            placeholder={placeholder}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Calendar />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {isClearable && date && (
                    <IconButton size="small" onClick={handleClear}>
                      <XIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              )
            }}
          />
        );
      }),
    [placeholder, date, startTime, isOnlyDate, isClearable, handleClear]
  );

  return (
    <Container>
      <DatePicker
        selected={date}
        onChange={handleChange}
        customInput={<InputComponent />}
        shouldCloseOnSelect={false}
        open={isOpen}
        disabled={isDisabled}
        name={name}
        filterDate={filterDate}
        popperPlacement="auto"
        calendarContainer={CalendarContainer}
        onCalendarClose={handleClose}
        onCalendarOpen={handleOpen}
        onClickOutside={handleClickOutside}
        previousMonthButtonLabel={<ChevronLeft />}
        nextMonthButtonLabel={<ChevronRight />}
        popperClassName="dateTimePickerPopper"
        portalId={withPortal ? 'portal_id' : undefined}
      />
    </Container>
  );
};
