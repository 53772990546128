import { useField, useFormikContext, FieldConfig } from 'formik';
import { fieldToTextField } from 'formik-material-ui';

import { FieldTypes } from '@enums';

const TYPE_TO_FIELD_TRANSFORM = {
  [FieldTypes.Text]: fieldToTextField,
};

export function usePropsForMuiComponent <T extends FieldConfig>(type: FieldTypes, props: T) {
  const [field, meta, helpers] = useField(props);
  const form = useFormikContext();

  return {
    ...TYPE_TO_FIELD_TRANSFORM[type]({ ...props, field, meta, form }),
    ...helpers
  };
}
