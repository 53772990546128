import { FormControlLabel } from '@common/ui';
import { Popover, RadioGroup } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { Radio } from '@common/ui/Radio';
import { ChevronDown, ChevronUp } from 'react-feather';
import { sortAsc as SortAsc, sortDesc as SortDesc } from '@assets/svg';
import { Menu, Container, OrderButton, Trigger, TriggerLabel, TriggerValue } from './styled';
import { SortByDirection, SortByField } from '../types';

const SORT_BY_TITLES_MAP: { [key: string]: string } = {
  priority: 'Priority',
  status: 'Status',
  createdAt: 'Created at',
  startDate: 'Start date',
  endDate: 'End date'
};

interface Props {
  field: SortByField;
  direction: SortByDirection;
  onChange: ({ field, direction }: { field: SortByField; direction: SortByDirection }) => void;
}

export const SortBy = ({ field, direction, onChange }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [isDesc, setIsDesc] = useState(direction === 'DESC');
  const [sortBy, setSortBy] = useState(field);

  useEffect(() => {
    onChange({ field: sortBy, direction: isDesc ? 'DESC' : 'ASC' });
  }, [isDesc, sortBy, onChange]);

  const open = Boolean(anchorEl);

  const handleSortByChange = useCallback((_: any, value: SortByField) => {
    setSortBy(value);
  }, []);

  const toggleOrder = useCallback(() => {
    setIsDesc((prev) => !prev);
  }, []);

  return (
    <>
      <Container>
        <Trigger onClick={handleClick}>
          <TriggerLabel>Sort by:</TriggerLabel>
          <TriggerValue>{SORT_BY_TITLES_MAP[sortBy]}</TriggerValue>
          {open ? <ChevronUp size="16px" /> : <ChevronDown size="16px" />}
        </Trigger>
        <OrderButton onClick={toggleOrder}>
          <img src={isDesc ? SortDesc : SortAsc} alt="" />
        </OrderButton>
      </Container>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Menu>
          <RadioGroup value={sortBy} aria-label="position" name="sortBy" onChange={handleSortByChange}>
            <FormControlLabel value="priority" control={<Radio />} label="Priority" labelPlacement="end" />

            <FormControlLabel value="createdAt" control={<Radio />} label="Created at" labelPlacement="end" />

            <FormControlLabel value="startDate" control={<Radio />} label="Start date" labelPlacement="end" />

            <FormControlLabel value="endDate" control={<Radio />} label="Due date" labelPlacement="end" />
          </RadioGroup>
        </Menu>
      </Popover>
    </>
  );
};
