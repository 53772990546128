import styled from 'styled-components';
import { AlertType } from './types';
import { ALERT_COLORS } from './const';

export const Container = styled.div<{ type: AlertType }>`
  padding: 16px;
  border-radius: 6px;
  background-color: ${({ type }) => ALERT_COLORS[type].bgColor};

  color: ${({ type }) => ALERT_COLORS[type].color};

  display: flex;
  align-items: center;
  gap: 16px;
`;

export const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Description = styled.div`
  margin-top: 8px;
  font-size: 14px;
`;
