import { TabItem, Tabs } from '@kit/ui/Tabs';
import React, { useCallback } from 'react';
import { v4 as uuid } from '@lukeed/uuid';
import { AnalyticsModule, FilterGroupOperator } from '@features/Analytics/types';
import { FilterTree, Filter } from './types';
import { FilterRow } from './FilterRow';
import { Group, SubGroup } from './styled';

const GroupOperatorOptions: TabItem<FilterGroupOperator>[] = [
  { title: 'AND', id: FilterGroupOperator.AND },
  { title: 'OR', id: FilterGroupOperator.OR }
];

interface Props {
  isRoot: boolean;
  group: FilterTree;
  onChange: (group: FilterTree) => void;
  onRemove: (id: string) => void;
  module: AnalyticsModule;
}

export const FilterGroup = ({ module, isRoot, group, onChange, onRemove }: Props) => {
  const handleGroupOperatorChange = useCallback(
    (selected: TabItem<FilterGroupOperator>) => {
      onChange({ ...group, operator: selected.id });
    },
    [onChange, group]
  );

  const handleRemoveFilter = useCallback(
    (id: string) => {
      const children = group.children.filter((child) => child.id !== id);

      if (children.length === 0) {
        onRemove(group.id);

        return;
      }

      if (children.length === 1 && !isRoot) {
        // If there is only one child in the group, remove the group and pass the child up
        onChange({
          ...children[0],
          id: group.id
        });

        return;
      }

      onChange({ ...group, children });
    },
    [onChange, group, onRemove, isRoot]
  );

  const handleFilterChange = useCallback(
    (filter: Filter) => {
      onChange({ ...group, children: group.children.map((child) => (child.id === filter.id ? filter : child)) });
    },
    [onChange, group]
  );

  const handleSubGroupChange = useCallback(
    (subGroup: FilterTree) => {
      onChange({ ...group, children: group.children.map((child) => (child.id === subGroup.id ? subGroup : child)) });
    },
    [onChange, group]
  );

  const handleAddSubFilter = useCallback(
    (id: string) => {
      if (isRoot) {
        onChange({
          ...group,
          children: group.children.map((child) =>
            child.id === id
              ? { id: uuid(), operator: FilterGroupOperator.AND, children: [child, { id: uuid() }] }
              : child
          )
        });
      } else {
        onChange({
          ...group,
          children: [...group.children, { id: uuid() }]
        });
      }
    },
    [onChange, group, isRoot]
  );

  const handleAddSubGroupRemove = useCallback(
    (id: string) => {
      onChange({ ...group, children: group.children.filter((child) => child.id !== id) });
    },
    [onChange, group]
  );

  return (
    <Group>
      {group.children.map((filterOrGroup, index) => (
        <React.Fragment key={filterOrGroup.id}>
          {'children' in filterOrGroup ? (
            <SubGroup>
              <FilterGroup
                module={module}
                onRemove={handleAddSubGroupRemove}
                isRoot={false}
                group={filterOrGroup as FilterTree}
                onChange={handleSubGroupChange}
              />
            </SubGroup>
          ) : (
            <FilterRow
              module={module}
              onAddFilter={handleAddSubFilter}
              onRemove={handleRemoveFilter}
              filter={filterOrGroup as Filter}
              onChange={handleFilterChange}
            />
          )}
          {group.children.length > 1 && index !== group.children.length - 1 && (
            <div>
              <Tabs selected={group.operator} tabs={GroupOperatorOptions} onChange={handleGroupOperatorChange} />
            </div>
          )}
        </React.Fragment>
      ))}
    </Group>
  );
};
