import { SmartViewFilter, SmartViewFilterType, TaskViewFilterSubType } from '@types';
import React, { useCallback } from 'react';
import { Tooltip } from '@material-ui/core';
import { BarChart2, Clipboard, Edit2, Truck } from 'react-feather';
import { useAppSelector } from '@hooks/store';
import { selectWorkspaceId } from '@state/selectors';
import { QueryParamsEnum, useQueryParam } from '@hooks/useQueryParam';
import { navigate } from 'gatsby';
import { useModal } from '@common/PromiseModal';
import { useAuth } from '@hooks/useAuth';
import { useUserRoleSettings } from '@hooks/useRoles';
import { hasAccess } from '@utils/roles';
import { AccountIcon } from '@common/icons/Account';
import { DollarIcon } from '@kit/ui/icons/Dollar';
import { BriefcaseIcon } from '@kit/ui/icons/Briefcase';
import { CalendarIcon } from '@kit/ui/icons/Calendar';
import { DashboardListItem } from '@hooks/analytics/useDashboardList';
import { DashboardSettings } from '@features/Analytics';
import { EditButton, Emoji, ItemText, ListItem } from './styled';
import { SmartViewForm } from '../Form';
import { eventTracking } from '../../../services/eventTracking';
import { RECORD_TYPE_TITLE_MAP } from '../../Analytics/constants';

const getPathBySmartView = (smartView: SmartViewFilter) => {
  switch (smartView.type) {
    case SmartViewFilterType.ACCOUNT:
      return 'clients';
    case SmartViewFilterType.DEAL:
      return 'requests';
    case SmartViewFilterType.PROJECT:
      return 'projects';
    case SmartViewFilterType.TASK: {
      switch (smartView.conditions.filter?.subType ?? TaskViewFilterSubType.SCHEDULER) {
        case TaskViewFilterSubType.SCHEDULER:
          return 'scheduler';
        case TaskViewFilterSubType.DISPATCHER:
          return 'dispatcher-new';
        case TaskViewFilterSubType.LIST:
          return 'list';
        default:
          return 'scheduler';
      }
    }
    default:
      return null;
  }
};

const getSmartViewIcon = (smartView: SmartViewFilter) => {
  switch (smartView.type) {
    case SmartViewFilterType.ACCOUNT:
      return <AccountIcon size={12} />;
    case SmartViewFilterType.DEAL:
      return <DollarIcon size="12px" />;
    case SmartViewFilterType.PROJECT:
      return <BriefcaseIcon size="12px" />;
    case SmartViewFilterType.TASK: {
      const subType = smartView.conditions?.filter?.subType as TaskViewFilterSubType;
      if (subType === TaskViewFilterSubType.DISPATCHER) {
        return <Truck size="12px" />;
      } else if (subType === TaskViewFilterSubType.LIST) {
        return <Clipboard size="12px" />;
      }

      return <CalendarIcon size="12px" />;
    }
    default:
      return null;
  }
};
interface Props {
  item: SmartViewFilter | DashboardListItem;
}

export const Item = ({ item }: Props) => {
  const companyId = useAppSelector(selectWorkspaceId);
  const [currentSmartViewId] = useQueryParam(QueryParamsEnum.SmartViewId);
  const [currentDashboardId] = useQueryParam(QueryParamsEnum.DashboardId);
  const { openModal } = useModal();

  const { user } = useAuth();
  const { data: access } = useUserRoleSettings(companyId, user.userId);

  const isSmartView = !('layout' in item);

  const handleItemClick = useCallback(() => {
    if (!isSmartView) {
      navigate(`/${companyId}/analytics/?${QueryParamsEnum.DashboardId}=${item.id}`);

      return;
    }

    const path = getPathBySmartView(item);

    if (!path) {
      return;
    }

    let resultPath = `/${companyId}/${path}?${QueryParamsEnum.SmartViewId}=${item.id}`;

    if (item.type === SmartViewFilterType.TASK && item.conditions.filter?.calendarViewType) {
      resultPath += `&${QueryParamsEnum.CalendarViewType}=${item.conditions.filter.calendarViewType}`;
    }

    eventTracking.track({
      event: 'SMART_VIEW_CLICKED',
      description: 'Clicked on smart view',
      customFields: {
        ObjectId: item.id,
        ObjectName: item.name,
        Source: 'Platform',
        SmartViewType: RECORD_TYPE_TITLE_MAP[item.type] ?? item.type
      }
    });

    navigate(resultPath);
  }, [companyId, item, isSmartView]);

  const handleEditClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();

      if (!isSmartView) {
        return;
      }

      openModal<void>(({ onClose }) => <SmartViewForm initialValues={item} onClose={onClose} />, {
        title: 'Edit Smart View'
      });
    },
    [openModal, item, isSmartView]
  );

  const handleEditDashboardClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();

      if (isSmartView) {
        return;
      }

      openModal<number>(({ onClose }) => <DashboardSettings initialValues={item} onClose={onClose} />, {
        title: 'Edit Dashboard'
      });
    },
    [openModal, item, isSmartView]
  );

  if (isSmartView) {
    return (
      <ListItem onClick={handleItemClick} isSelected={currentSmartViewId === item.id.toString()}>
        {getSmartViewIcon(item as SmartViewFilter)}
        {item.emoji && <Emoji>{item.emoji}</Emoji>}
        <ItemText>{item.name}</ItemText>
        {hasAccess(access!, 'workspace', 'edit', 'smartview') && (
          <EditButton onClick={handleEditClick}>
            <Tooltip title="Edit">
              <Edit2 size="12px" />
            </Tooltip>
          </EditButton>
        )}
      </ListItem>
    );
  }

  return (
    <ListItem onClick={handleItemClick} isSelected={currentDashboardId === item.id.toString()}>
      <BarChart2 size="12px" />
      <Emoji>{item.emoji || '📊'}</Emoji>
      <ItemText>{item.name}</ItemText>
      {hasAccess(access!, 'workspace', 'edit', 'analytics') && (
        <EditButton onClick={handleEditDashboardClick}>
          <Tooltip title="Edit">
            <Edit2 size="12px" />
          </Tooltip>
        </EditButton>
      )}
    </ListItem>
  );
};
