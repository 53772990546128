import { Urls } from '@services/api/base/axios';
import { post, put } from '@services/api/base';

export default {
  monitorSystems: (dto: { systems: number[]; profile: number | null }) =>
    post<void>(`${Urls.fleet}/systems/monitor`, dto),

  update: (id: number, dto: { notes?: string; addressForced?: string | null; projectId?: number }) =>
    put<void>(`${Urls.fleet}/systems/${id}`, dto)
};
