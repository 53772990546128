import { Urls } from '@services/api/base/axios';
import { deleteRequest, post, put } from '@services/api/base';
import { SyncFrequency } from '@generated/types/graphql';

export default {
  create: (companyId: number, dto: Required<ProfileCreateDTO>) =>
    post<void>(`${Urls.fleet}/profiles`, dto, { companyId }),

  update: (id: number, dto: Partial<ProfileCreateDTO>) => put<void>(`${Urls.fleet}/profiles/${id}`, dto),

  delete: (id: number) => deleteRequest<void>(`${Urls.fleet}/profiles/${id}`)
};

export type ProfileCreateDTO = {
  name: string;
  default: boolean;
  configs: Array<{
    integration: number;
    frequency: SyncFrequency; // actually only DAY is supported right now
    enabledMetrics: SystemMetric[];
  }>;
};

export enum SystemMetric {
  EnergyProduction = 'energyProduction',
  EnergyConsumption = 'energyConsumption',
  EnergyExport = 'energyExport',
  EnergyImport = 'energyImport',
  PowerProduction = 'powerProduction',
  PowerConsumption = 'powerConsumption',
  PowerExport = 'powerExport',
  PowerImport = 'powerImport',
  Storage = 'storage',
  Devices = 'devices',
  Metadata = 'metadata'
}
