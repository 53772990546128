/* eslint consistent-return: 0 */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { getUserToken, withTimeout } from '@utils';
import { MsgType } from '@constants/formBuilder/WebSocketMessegeType';
import { clone, uniq } from 'lodash';
import { SendDataPayload } from '@components/Project/DataBank/hooks';
import { useAllCompaniesUsers } from '@hooks/useCompanyUsers';
import { MainUser } from '@generated/types/graphql';
import { config } from '../../../../config';

const wsInstance: { current?: Socket } = {
  current: undefined
};

const RESPONSE_WAIT_TIME = 10000;

export const useSubscribeToFormUpdates = (formId: number, onFieldUpdated: (fieldId: number, update: any) => void) => {
  const [clientSocketId, setClientSocketId] = useState<string | undefined>(undefined);

  const [collaborators, setCollaborators] = useState<{ [fieldId: number]: MainUser }>([]);

  const { data: users } = useAllCompaniesUsers();

  const onFieldUpdatedCallbackRef = useRef(onFieldUpdated);

  onFieldUpdatedCallbackRef.current = onFieldUpdated;

  const usersById = useMemo(() => {
    return users?.reduce(
      (acc, user) => {
        acc[user.id] = user;

        return acc;
      },
      {} as Record<number, MainUser>
    );
  }, [users]);

  const close = () => {
    if (wsInstance.current && wsInstance.current.connected) {
      wsInstance.current.disconnect();
    }
  };

  useEffect(() => {
    if (wsInstance.current) {
      close();
    }

    wsInstance.current = io(config.wsForm, {
      query: {
        token: getUserToken(),
        formId: formId.toString()
      },
      rejectUnauthorized: false,
      path: '/project-socket'
    });

    wsInstance.current.on('connect', (...args) => {
      setClientSocketId(clone(wsInstance.current?.id));

      // eslint-disable-next-line no-console
      console.debug('WebSocket connection established with formId: ', formId, args);
    });

    wsInstance.current.on('disconnect', (...args) => {
      // eslint-disable-next-line no-console
      console.debug('WebSocket connection close', args);
    });

    wsInstance.current.on('connect_error', (...args) => {
      // eslint-disable-next-line no-console
      console.error('Error on form socket', args);
    });

    wsInstance.current.on(MsgType.currentCollaborationState, async (response) => {
      if (!response) {
        return;
      }

      const currentStateUserList: { userId: number; fieldId?: number }[] = uniq(
        response.filter(({ socketId }) => socketId !== wsInstance.current?.id)
      );

      setCollaborators(
        currentStateUserList.reduce(
          (acc, user) => {
            if (user.fieldId) {
              acc[user.fieldId] = usersById[user.userId];
            }

            return acc;
          },
          {} as Record<number, MainUser>
        )
      );
    });

    wsInstance.current.on(MsgType.responseUpdated, ({ fieldId, response }) => {
      onFieldUpdatedCallbackRef.current(fieldId, response);
    });

    return () => {
      if (wsInstance.current) {
        close();
        wsInstance.current = undefined;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formId]);

  const sendData = useCallback(async (type: MsgType, data: SendDataPayload) => {
    try {
      return await withTimeout(
        new Promise((resolve, reject) => {
          if (wsInstance.current && wsInstance.current.connected) {
            wsInstance.current.emit(type, data, (response: Record<string, any>) => {
              return response?.statusCode === 200 ? resolve(response) : reject(new Error(response.message));
            });
          }
        }),
        RESPONSE_WAIT_TIME
      );
    } catch (e) {
      console.error('form socket request failed', e, type, data, { currnet: wsInstance.current });
    }
  }, []);

  const handleFieldEnter = useCallback(
    async (fieldId: number) => {
      sendData(MsgType.enterField, { fieldId });
    },
    [sendData]
  );

  const handleFieldLeave = useCallback(
    (fieldId: number) => {
      sendData(MsgType.leftField, { fieldId });
    },
    [sendData]
  );

  return {
    clientSocketId,
    collaborators,
    onFieldEnter: handleFieldEnter,
    onFieldLeave: handleFieldLeave
  };
};
