import React from 'react';

interface Props {
  size?: string | number;
  color?: string;
}

export const DragHandle2Icon = React.forwardRef<SVGElement, Props>(
  ({ size = '16px', color = 'currentColor', ...props }, ref) => (
    <svg
      ref={ref}
      width={size}
      height={size}
      {...props}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20.5" cy="8.5" r="0.5" fill={color} />
      <circle cx="20.5" cy="16.5" r="0.5" fill={color} />
      <circle cx="20.5" cy="4.5" r="0.5" fill={color} />
      <circle cx="20.5" cy="12.5" r="0.5" fill={color} />
      <circle cx="20.5" cy="0.5" r="0.5" fill={color} />
      <circle cx="20.5" cy="6.5" r="0.5" fill={color} />
      <circle cx="20.5" cy="14.5" r="0.5" fill={color} />
      <circle cx="20.5" cy="2.5" r="0.5" fill={color} />
      <circle cx="20.5" cy="10.5" r="0.5" fill={color} />
      <circle cx="18.5" cy="8.5" r="0.5" fill={color} />
      <circle cx="18.5" cy="16.5" r="0.5" fill={color} />
      <circle cx="18.5" cy="4.5" r="0.5" fill={color} />
      <circle cx="18.5" cy="12.5" r="0.5" fill={color} />
      <circle cx="18.5" cy="18.5" r="0.5" fill={color} />
      <circle cx="18.5" cy="6.5" r="0.5" fill={color} />
      <circle cx="18.5" cy="14.5" r="0.5" fill={color} />
      <circle cx="18.5" cy="2.5" r="0.5" fill={color} />
      <circle cx="18.5" cy="10.5" r="0.5" fill={color} />
      <circle cx="16.5" cy="20.5" r="0.5" fill={color} />
      <circle cx="16.5" cy="8.5" r="0.5" fill={color} />
      <circle cx="16.5" cy="16.5" r="0.5" fill={color} />
      <circle cx="16.5" cy="4.5" r="0.5" fill={color} />
      <circle cx="16.5" cy="12.5" r="0.5" fill={color} />
      <circle cx="16.5" cy="18.5" r="0.5" fill={color} />
      <circle cx="16.5" cy="6.5" r="0.5" fill={color} />
      <circle cx="16.5" cy="14.5" r="0.5" fill={color} />
      <circle cx="16.5" cy="10.5" r="0.5" fill={color} />
      <circle cx="14.5" cy="20.5" r="0.5" fill={color} />
      <circle cx="14.5" cy="8.5" r="0.5" fill={color} />
      <circle cx="14.5" cy="16.5" r="0.5" fill={color} />
      <circle cx="14.5" cy="12.5" r="0.5" fill={color} />
      <circle cx="14.5" cy="18.5" r="0.5" fill={color} />
      <circle cx="14.5" cy="6.5" r="0.5" fill={color} />
      <circle cx="14.5" cy="14.5" r="0.5" fill={color} />
      <circle cx="14.5" cy="10.5" r="0.5" fill={color} />
      <circle cx="12.5" cy="20.5" r="0.5" fill={color} />
      <circle cx="12.5" cy="8.5" r="0.5" fill={color} />
      <circle cx="12.5" cy="16.5" r="0.5" fill={color} />
      <circle cx="12.5" cy="12.5" r="0.5" fill={color} />
      <circle cx="12.5" cy="18.5" r="0.5" fill={color} />
      <circle cx="12.5" cy="14.5" r="0.5" fill={color} />
      <circle cx="12.5" cy="10.5" r="0.5" fill={color} />
      <circle cx="10.5" cy="20.5" r="0.5" fill={color} />
      <circle cx="10.5" cy="16.5" r="0.5" fill={color} />
      <circle cx="10.5" cy="12.5" r="0.5" fill={color} />
      <circle cx="10.5" cy="18.5" r="0.5" fill={color} />
      <circle cx="10.5" cy="14.5" r="0.5" fill={color} />
      <circle cx="10.5" cy="10.5" r="0.5" fill={color} />
      <circle cx="4.5" cy="20.5" r="0.5" fill={color} />
      <circle cx="4.5" cy="16.5" r="0.5" fill={color} />
      <circle cx="4.5" cy="18.5" r="0.5" fill={color} />
      <circle cx="8.5" cy="20.5" r="0.5" fill={color} />
      <circle cx="8.5" cy="16.5" r="0.5" fill={color} />
      <circle cx="8.5" cy="12.5" r="0.5" fill={color} />
      <circle cx="8.5" cy="18.5" r="0.5" fill={color} />
      <circle cx="8.5" cy="14.5" r="0.5" fill={color} />
      <circle cx="2.5" cy="20.5" r="0.5" fill={color} />
      <circle cx="2.5" cy="18.5" r="0.5" fill={color} />
      <circle cx="6.5" cy="20.5" r="0.5" fill={color} />
      <circle cx="6.5" cy="16.5" r="0.5" fill={color} />
      <circle cx="6.5" cy="18.5" r="0.5" fill={color} />
      <circle cx="6.5" cy="14.5" r="0.5" fill={color} />
      <circle cx="0.5" cy="20.5" r="0.5" fill={color} />
    </svg>
  )
);
