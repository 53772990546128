import React, { useCallback, useState } from 'react';
import { TaskVisit } from '@generated/types/graphql';
import { FormControlLabel } from '@common/ui';
import { colors, checkboxMui } from '@styles';
import { Checkbox, makeStyles } from '@material-ui/core';
import { parseUtcDate } from '@utils/dates';
import { Plus, X } from 'react-feather';
import { useFormikContext } from 'formik';
import { Button, ButtonSize, ButtonVariant } from '@kit/ui/Button';
import { DateTimeRangePicker } from './DateTimeRangePicker';
import { Container, VisitRow, Name, IsCompleted, VisitRowRight, RemoveButton } from './styled';
import { formatOrdinal } from '@utils/format';

const useStylesCheckbox = makeStyles(checkboxMui({ size: '16px' }));

const getVisitName = (index: number) => `${formatOrdinal(index + 1)} visit`;

const AddNewVisit = ({ onAdd, isDisabled }) => {
  const [isNewRowShown, setIsNewRowShown] = useState(false);

  const handleAdd = useCallback(
    (startDate: Date, endDate: Date) => {
      setIsNewRowShown(false);
      onAdd({ startDate, endDate });
    },
    [onAdd]
  );

  if (isNewRowShown) {
    const initialStart = new Date();
    initialStart.setMinutes(Math.ceil(initialStart.getMinutes() / 30) * 30);
    initialStart.setSeconds(0, 0);
    const initialEnd = new Date(initialStart);
    initialEnd.setHours(initialEnd.getHours() + 1);
    initialEnd.setSeconds(0, 0);

    return (
      <VisitRow>
        <Name>New visit</Name>

        <DateTimeRangePicker initialIsOpen start={initialStart} end={initialEnd} onClose={handleAdd} />
      </VisitRow>
    );
  }

  return (
    <Button
      variant={ButtonVariant.Flat}
      data-testid="addNewVisit"
      size={ButtonSize.Small}
      isUpperCase={false}
      disabled={isDisabled}
      onClick={() => setIsNewRowShown(true)}
    >
      <Plus size="16px" />
      Add new visit
    </Button>
  );
};

interface Props {
  isReadOnly?: boolean;
}

export const Visits = ({ isReadOnly = false }: Props) => {
  const classesCheckbox = useStylesCheckbox();

  const { values, submitForm, setFieldValue } = useFormikContext();

  const { visits } = values;

  const handleAdd = useCallback(
    ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
      setFieldValue('visits', [
        ...visits,
        {
          startDate,
          endDate,
          startDateAllDay: false,
          endDateAllDay: false
        }
      ]);

      submitForm();
    },
    [visits, submitForm, setFieldValue]
  );

  const isSubmittingNewVisit = visits.some((visit) => !visit.id);

  const handleUpdateVisit = useCallback(
    (visit: TaskVisit, update: Partial<TaskVisit>) => {
      const visitIndexToUpdate = visits.findIndex((taskVisit) => taskVisit.id === visit.id);

      if (visitIndexToUpdate < 0) {
        return;
      }

      const updatedVisits = [...visits];

      updatedVisits[visitIndexToUpdate] = {
        ...updatedVisits[visitIndexToUpdate],
        ...update
      };

      setFieldValue('visits', updatedVisits);
      submitForm();
    },
    [visits, submitForm, setFieldValue]
  );

  const handleRemoveVisit = useCallback(
    (visitToRemove: TaskVisit) => () => {
      setFieldValue(
        'visits',
        visits.filter((visit: TaskVisit) => visit.id !== visitToRemove.id)
      );
      submitForm();
    },
    [visits, submitForm, setFieldValue]
  );

  return (
    <Container>
      {visits.map((visit, idx) => (
        <VisitRow key={visit.id}>
          <Name>{getVisitName(idx)}</Name>

          <DateTimeRangePicker
            start={parseUtcDate(visit.startDate)}
            end={parseUtcDate(visit.endDate)}
            isDisabled={isReadOnly || isSubmittingNewVisit}
            onClose={(startDate, endDate) => handleUpdateVisit(visit, { startDate, endDate })}
          />

          <VisitRowRight>
            <IsCompleted>
              <FormControlLabel
                control={
                  <Checkbox
                    classes={classesCheckbox}
                    checked={visit.isCompleted}
                    disabled={isReadOnly || isSubmittingNewVisit}
                    onChange={() => {
                      handleUpdateVisit(visit, { isCompleted: !visit.isCompleted });
                    }}
                    style={{ color: colors.gray }}
                  />
                }
                label="Completed"
              />
            </IsCompleted>

            {!isReadOnly && (
              <RemoveButton onClick={isSubmittingNewVisit ? undefined : handleRemoveVisit(visit)}>
                <X size="20px" />
              </RemoveButton>
            )}
          </VisitRowRight>
        </VisitRow>
      ))}

      {!isReadOnly && (
        <div>
          <AddNewVisit onAdd={handleAdd} isDisabled={isSubmittingNewVisit} />
        </div>
      )}
    </Container>
  );
};
