import { colors, scroll } from '@styles';
import styled from 'styled-components';

export const SectionContainer = styled.div<{ isDraggingOver: boolean }>`
  border: ${({ isDraggingOver }) => (isDraggingOver ? '1px dashed #009688' : '1px solid transparent')};
  position: relative;
`;

export const ActiveFileDropZone = styled.div`
  position: absolute;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: rgba(255, 255, 255, 0.85);
  font-size: 24px;
  z-index: 1;
  border: 2px dashed ${colors.green};

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SectionHeader = styled.div`
  padding: 0 16px;
  display: flex;
  align-items: center;
  height: 56px;
  gap: 40px;
  justify-content: space-between;
  border-bottom: 1px solid #dfdfe8;
`;

export const Scrollable = styled.div`
  overflow: auto;
  ${scroll};
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 40px;
`;

export const SectionHeaderLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
`;

export const SectionTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
`;

export const DraggableRow = styled.div<{ isDragging: boolean; isCloneOfDragging: boolean; draggingCount: number }>`
  transform: ${(props) => !props.isDragging && 'translate(0px, 0px) !important'};
  cursor: pointer !important;
  opacity: ${(props) => props.isCloneOfDragging && '0.4'};

  > * {
    background-color: ${(props) => props.isDragging && '#E3F6F4'};
    border-radius: ${(props) => props.isDragging && '8px'};
  }

  position: relative;
  border-radius: ${({ isDragging }) => (isDragging ? '8px' : '0px')};
  box-shadow: ${({ isDragging, draggingCount }) => {
    if (!isDragging || draggingCount <= 1) {
      return 'none';
    }

    if (draggingCount === 2) {
      return '0 4px 0px #CCEAE7';
    }

    return '0 4px 0px #CCEAE7, 0 8px 0px #B3E0DB';
  }};
`;

export const DraggaingCounter = styled.div`
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 21px;
    width: 21px;
    background-color: #D54855;
    border-radius 50%;
    font-size: 14px;
    font-weight: 500;
    color: #FFF;
`;

export const DraggingDoc = styled.div`
  height: 56px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  gap: 16px;
  width: 304px;
  background-color: #e3f6f4;
  border-radius: 8px;
`;
