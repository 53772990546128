import React from 'react';
import { Menu } from 'react-feather';
import { useAppSelector, useMediaSm, useRoutes } from '@hooks';
import { selectIsInsideCompanyPage } from '@state/selectors';
import { MessageBanner, MessageBannerTypeEnum } from '@common/MessageBanner';
import { useEmailAccounts, useEmailAccountsMutations } from '@hooks/useEmailAccounts';
import { useLocation } from '@reach/router';
import { SubjectAccess } from '@common/RoleAccess';
import { HelpCircleIcon } from '@kit/ui/icons/Help';
import { MegaphoneIcon } from '@kit/ui/icons/Megaphone';
import { SMART_VIEWS_TITLE_ROOT_ID } from '@features/SmartViews/SaveButton';
import { EmailAccountStatus } from '@generated/types/graphql';
import { CannyButton, PageTitle, ToggleButton, Wrapper } from './styled';
import { NavBarProps } from './types';

export const NavBar = (props: NavBarProps) => {
  const { name, toggleSideBar, hideToggleSidebar = false } = props;

  const { companyId, navigate } = useRoutes();
  const location = useLocation();

  const isWide = useMediaSm();
  const isInsideCompanyPage = useAppSelector(selectIsInsideCompanyPage);

  const { data: emailAccounts } = useEmailAccounts(companyId);
  const invalidEmailAcc = emailAccounts!.find((acc) => acc.status === EmailAccountStatus.Invalid);

  const { connect: connectEmailAccount } = useEmailAccountsMutations(companyId);
  const navigateToEmailAuth = async () => {
    const redirectUrl = await connectEmailAccount.mutateAsync({
      email: invalidEmailAcc!.emailAddress,
      redirectTo: location.pathname,
      alias: invalidEmailAcc!.alias
    });

    await navigate(redirectUrl);
  };

  return (
    <div>
      <SubjectAccess subject="workspace" scope="settings" privilege="edit">
        {!!invalidEmailAcc && false && (
          <MessageBanner
            type={MessageBannerTypeEnum.warning}
            title="Email account deactivated"
            subtitle={`It seems your email account ${invalidEmailAcc.emailAddress} authentication got invalidated by
             email server. Please authenticate it again.`}
            action={navigateToEmailAuth}
            actionName="Authenticate"
          />
        )}
      </SubjectAccess>
      <Wrapper>
        {!hideToggleSidebar && (
          <ToggleButton onClick={toggleSideBar} asButton={isInsideCompanyPage || !isWide}>
            <Menu size="20px" />
          </ToggleButton>
        )}
        <PageTitle>
          {name}
          <div id={SMART_VIEWS_TITLE_ROOT_ID} />
        </PageTitle>
        <CannyButton href="#" data-canny-changelog>
          <MegaphoneIcon size={20} color="#9C9CAA" />
        </CannyButton>
        <a href="https://coperniq.gitbook.io/" target="_blank" rel="noreferrer">
          <HelpCircleIcon size={20} color="#9C9CAA" />
        </a>
      </Wrapper>
    </div>
  );
};
