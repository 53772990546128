import React from 'react';
import { NoResult } from '@kit/components/NoResult';
import { useSystem } from '@hooks/systems/useSystem';
import { Container, Content, ErrorWrapper, Wrapper } from './styled';
import { Overview } from './Overview';
import { Header } from './Header';
import { RightPanel } from './RightPanel';

interface Props {
  systemId: number;
}

export const System = React.memo(({ systemId }: Props) => {
  const { data: system, isFetching } = useSystem(systemId);

  if (!isFetching && !system) {
    return (
      <ErrorWrapper>
        <NoResult subject="system" />
      </ErrorWrapper>
    );
  }

  return (
    <Container>
      <Header systemId={systemId} />
      <Wrapper>
        <Content>
          <Overview systemId={+systemId} />
        </Content>

        <RightPanel systemId={+systemId} />
      </Wrapper>
    </Container>
  );
});
