import React, { useCallback, useMemo } from 'react';
import { Select } from '@kit/ui/Select';
import { UserAvatar } from '@kit/components/UserAvatar';
import { getFullName } from '@utils/utils';
import { Check, Info } from 'react-feather';
import { useTeams } from '@hooks/useTeams';
import { useAppSelector } from '@hooks/store';
import { selectWorkspaceId } from '@state/selectors';
import { useCompanyUsersWithRoles } from '@hooks/useCompanyUsers';
import { useDebouncedState } from '@hooks/useDebouncedState';
import { useRoleList } from '@hooks/workspace/roles/useRoleList';
import { GROUP_TO_ICON } from './constants';
import { Option, OptionNoAccessWarning, SharedWithGroupTitle } from './styled';
import {
  AccessEntityType,
  AccessOption,
  CheckAccessFn,
  RoleAccessEntityOption,
  TAccessList,
  TeamAccessEntityOption,
  UserAccessEntityOption
} from './types';
import { pluralizeRoleName } from './helpers';

interface Props {
  checkAccessFn: CheckAccessFn;
  accessEntityName: string;
  selected: TAccessList;
  item: AccessOption;
  onChange: (itemId: string, option: AccessOption) => void;
}

export const AccessItemSelect = ({ accessEntityName, checkAccessFn, item, selected, onChange }: Props) => {
  const companyId = useAppSelector(selectWorkspaceId);
  const [inputValue, debouncedSearch, setInputValue] = useDebouncedState('', 300);

  const { data: users = [] } = useCompanyUsersWithRoles({ search: debouncedSearch });

  const { data: teams = [] } = useTeams(companyId);

  const { data: roles = [] } = useRoleList();

  const options = useMemo(() => {
    const roleOptions: RoleAccessEntityOption[] = roles.map((role) => ({
      uniqueId: `${AccessEntityType.ROLE}-${role.id}`,
      type: AccessEntityType.ROLE,
      value: role
    }));

    const teamOptions: TeamAccessEntityOption[] = teams.map((team) => ({
      uniqueId: `${AccessEntityType.TEAM}-${team.id}`,
      type: AccessEntityType.TEAM,
      value: team
    }));

    const userOptions: UserAccessEntityOption[] = users.map((user) => ({
      uniqueId: `${AccessEntityType.USER}-${user.id}`,
      type: AccessEntityType.USER,
      value: user
    }));

    return [...roleOptions, ...teamOptions, ...userOptions];
  }, [users, teams, roles]);

  const handleChange = useCallback(
    (_, option: AccessOption) => {
      onChange(item.uniqueId, option);
    },
    [item.uniqueId, onChange]
  );

  return (
    <Select
      inputValue={inputValue}
      onInputChange={(_event, value) => {
        setInputValue(value);
      }}
      getOptionDisabled={(option) => {
        const isSelected = selected.some((item) => item.uniqueId === option.uniqueId);

        if (isSelected) {
          return true;
        }

        if (option.type === AccessEntityType.ROLE) {
          return !checkAccessFn(option.value);
        }

        if (option.type === AccessEntityType.USER && option.value.role) {
          return !checkAccessFn(option.value.role);
        }

        return false;
      }}
      value={item.value}
      renderGroup={({ group, children }) => {
        return (
          <div>
            <SharedWithGroupTitle>{group}</SharedWithGroupTitle>
            {children}
          </div>
        );
      }}
      onChange={handleChange}
      getOptionLabel={(option) => (option.value.firstName ? getFullName(option.value) : option.value.name)}
      options={options}
      groupBy={(option) => {
        switch (option.type) {
          case AccessEntityType.USER:
            return 'Users';
          case AccessEntityType.TEAM:
            return 'Teams';
          case AccessEntityType.ROLE:
            return 'Roles';
          default:
            return null;
        }
      }}
      placeholder="Select a User, Team and/or Role"
      noOptionsText="No results found"
      renderOption={(option) => {
        const isSelected = selected.some((item) => item.uniqueId === option.uniqueId);

        switch (option.type) {
          case AccessEntityType.USER:
            return (
              <Option>
                <UserAvatar user={option.value} />
                <div>{getFullName(option.value)}</div>
                {option.value.role && !checkAccessFn(option.value.role) && (
                  <OptionNoAccessWarning>
                    <Info size="16px" color="#9C9CAA" />
                    This user doesn&apos;t have access to {accessEntityName}.
                  </OptionNoAccessWarning>
                )}
                {isSelected && <Check size="16px" color="#009A47" />}
              </Option>
            );
          case AccessEntityType.ROLE:
            return (
              <Option>
                {GROUP_TO_ICON[option.type as AccessEntityType]}
                <div>{pluralizeRoleName(option.value.name)}</div>
                {!checkAccessFn(option.value) && (
                  <OptionNoAccessWarning>
                    <Info size="16px" color="#9C9CAA" />
                    This role doesn&apos;t have access to {accessEntityName}.
                  </OptionNoAccessWarning>
                )}
                {isSelected && <Check size="16px" color="#009A47" />}
              </Option>
            );
          case AccessEntityType.TEAM:
            return (
              <Option>
                {GROUP_TO_ICON[option.type as AccessEntityType]}
                <div>
                  {option.value.name} ({option.value.teamUsers?.length || ''})
                </div>
                {isSelected && <Check size="16px" color="#009A47" />}
              </Option>
            );

          default:
            return null;
        }
      }}
    />
  );
};
