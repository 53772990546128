import { getColorWithOpacity } from '@styles';
import { defaultTheme } from '@themes';

export const inputStyles = () => {
  return {
    // TextField classes from .MuiTextField-root
    root: {
      width: '100%',

      // FormControl classes from https://v4.mui.com/api/form-control/#css
      '&.MuiFormControl-root': {},
      '&.MuiFormControl-marginNormal': {},
      '&.MuiFormControl-marginDense': {},

      // Input classes from https://v4.mui.com/api/input/
      '& .MuiInputBase-root': {
        background: defaultTheme.colors.white,
        padding: '8px',
        minHeight: '40px'
      },

      // OutlinedInput classes from https://v4.mui.com/api/outlined-input/
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: defaultTheme.colors.green,
            borderWidth: '1px'
          }
        },
        '&.Mui-error': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: defaultTheme.colors.red
          }
        },
        '&.Mui-disabled': {
          backgroundColor: '#f9fafb',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: getColorWithOpacity(defaultTheme.colors.gray3, 60),
            color: getColorWithOpacity(defaultTheme.colors.black, 30)
          }
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: defaultTheme.colors.green
        }
      },
      '& .Mui-focused': {},

      '& .MuiOutlinedInput-adornedStart': {
        paddingLeft: '8px'
      },
      '& .MuiOutlinedInput-adornedEnd': {
        paddingRight: '8px !important'
      },

      '& .MuiInputAdornment-root': {
        '& .MuiIconButton-root': {
          opacity: '0'
        },
        '& > svg': {
          minWidth: '28px',
          height: '28px',
          padding: '4px',
          color: defaultTheme.colors.gray
        }
      },
      '&:hover .MuiInputAdornment-root': {
        '& .MuiIconButton-root': {
          opacity: '1'
        }
      },

      '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: '1px',
        borderColor: '#DFDFE8',
        transition: '.3s'
      },
      '& .MuiOutlinedInput-input': {
        font: `400 14px/130% ${defaultTheme.fonts.primary}`,
        color: defaultTheme.colors.black,

        '&::placeholder': {
          font: `400 14px/130% ${defaultTheme.fonts.primary}`,
          color: defaultTheme.colors.gray2,
          opacity: 1
        },
        '&.Mui-disabled': {
          color: getColorWithOpacity(defaultTheme.colors.gray2, 40)
        }
      },
      '& .MuiOutlinedInput-inputMarginDense': {
        height: '32px'
      },
      '& + .MuiFormHelperText-root': {
        position: 'absolute',
        '&.Mui-error': {
          color: defaultTheme.colors.red
        }
      },

      // Label classes from
      '& .MuiInputLabel-root': {}
    }
  };
};
