import React from 'react';
import { Autocomplete as AutocompleteMui, AutocompleteProps } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';
import { ChevronDown } from 'react-feather';
import { TextField } from '@common/ui';
import { autocomplete } from './styled';

interface Props extends AutocompleteProps {
  placeholder?: string;
  onChange?: () => void;
}

const useStyles = makeStyles(autocomplete({}));

export const Autocomplete: React.FC<Props> = (props) => {
  const {
    placeholder,
    onChange,
    ...rest
  } = props;

  const classes = useStyles();

  return (
    <AutocompleteMui
      {...rest}
      classes={classes}
      popupIcon={<ChevronDown />}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          onChange={onChange}

        />
      )}
    />
  );
};
