import React from 'react';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools'
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import {
  DndProvider,
  TouchTransition,
  MouseTransition
} from 'react-dnd-multi-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';

import { defaultTheme } from '@styles/themes';

import { store } from '../configureStore';
import { queryClient } from './QueryClient';

import '../../services/eventTracking';

const HTML5toTouch = {
  backends: [
    {
      id: 'html5',
      backend: HTML5Backend,
      transition: MouseTransition
    },
    {
      id: 'touch',
      backend: TouchBackend,
      options: {
        enableMouseEvents: true,
        ignoreContextMenu: true,
        delayTouchStart: 150
      },
      preview: true,
      transition: TouchTransition
    }
  ]
};

export default ({ element }) => (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={defaultTheme}>
        <DndProvider options={HTML5toTouch}>
          {element}
        </DndProvider>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </Provider>
);
