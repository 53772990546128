import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Checkbox } from '@kit/ui/Checkbox';

import { useIntersectionObserver } from '@react-hookz/web';
import moment from 'moment';
import { FeedInbox } from '@generated/types/graphql';
import { useAppSelector } from '@hooks/store';
import { selectCurrentUserId } from '@state/selectors';
import { useUnreadNotificationCount } from '@hooks/notifications/useUnreadNotificationCount';
import { Loader } from '@kit/ui/Loader';
import { parseNotificationIdFromPathname } from '@utils/strings';
import { UserAvatar } from '@kit/components/UserAvatar';
import { ContactAvatar } from '@kit/components/ContactAvatar';
import { Badge } from '@kit/ui/Badge';
import { SelectionControl } from './SelectionControl';
import { useSelection } from './useSelection';
import { useNotifications } from './useNotifications';
import { Table, Row, PlaceholderBlock, PlaceholderTitle, PlaceholderDescription, Author } from './styled';
import { useClientFilterState } from '../useClientFilterState';
import wellDoneImg from './WellDone.svg';
import noResultsImg from './NoResults.svg';
import { useNav } from '../useNav';
import { getNotificationInfo } from './helpers';
import { NotificationEntityChip } from './NotificationEntityChip';

export const getHumanFriendlyTimeAgo = (date: moment.MomentInput) => {
  return moment.utc(date).local().calendar(null, {
    sameDay: '[Today at] hh:mma',
    lastDay: '[Yesterday at] hh:mma',
    lastWeek: 'dddd [at] hh:mma',
    sameElse: 'MM/DD/yyyy[,] hh:mma'
  });
};

export const NotificationRow = ({
  notification,
  isSelected,
  onToggleSelect,
  onClick,
  isOpened
}: {
  notification: FeedInbox;
}) => {
  const userId = useAppSelector(selectCurrentUserId);

  const isRead = notification.read;

  const info = useMemo(() => getNotificationInfo(notification, userId), [notification, userId]);

  const handleSelect = useCallback(() => {
    onToggleSelect(notification.id);
  }, [notification, onToggleSelect]);

  const handleClick = useCallback(async () => {
    onClick(notification);
  }, [onClick, notification]);

  if (!info) {
    return null;
  }

  return (
    <Row isRead={isRead} onClick={handleClick} key={notification.id} isSelected={isSelected || isOpened}>
      <td
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Checkbox isChecked={isSelected} onChange={handleSelect} />
      </td>
      <td className="title">
        <div>
          {info.icon}

          {info.author && (
            <Author>
              <UserAvatar user={info.author} size={20} />
              <div>
                {info.author.firstName} {info.author.lastName}
              </div>
            </Author>
          )}

          {info.contact && (
            <Author>
              <ContactAvatar contact={info.contact} size={20} />
              <div>{info.contact.name}</div>
              {info.badge && (
                <Badge color="#009688" bgColor="#e3f6f4">
                  {info.badge}
                </Badge>
              )}
            </Author>
          )}
        </div>
      </td>
      <td className="content">{info.content ? <div>{info.content}</div> : info.contentNode}</td>
      <td className="project">
        {info.entity && <NotificationEntityChip entity={info.entity} notification={notification} />}
      </td>
      <td className="time">{getHumanFriendlyTimeAgo(info.createdAt)}</td>
    </Row>
  );
};

interface TableProps {
  notifications: FeedInbox[];
  total: number;
  fetchNextPage: () => void;
  isLoading: boolean;
}

export const NotificationTable = ({ notifications, total, fetchNextPage, isLoading }: TableProps) => {
  const loadingRef = useRef<HTMLDivElement>();
  const scrollableNode = useRef<HTMLDivElement>();

  const { selectedCount, isAllSelected, checkIsSelected, toggle, toggleAllSelected, clearSelection, selected } =
    useSelection(notifications);

  const entry = useIntersectionObserver(loadingRef, { root: scrollableNode, rootMargin: '10px' });

  useEffect(() => {
    if (entry?.isIntersecting && notifications.length < total) {
      fetchNextPage();
    }
  }, [entry, notifications.length, total, fetchNextPage]);

  const handleSelectRow = useCallback(
    (rowId: number) => {
      toggle(rowId);
    },
    [toggle]
  );

  const openedNotificationId = parseNotificationIdFromPathname(window.location.pathname);

  const { onNotificationClick } = useNav();

  return (
    <Table>
      <thead>
        <th>
          <Checkbox isChecked={isAllSelected} onChange={toggleAllSelected} />
        </th>

        <th colSpan={2}>
          {selectedCount > 0 && <SelectionControl clearSelection={clearSelection} selectedIds={selected} />}
          {selectedCount === 0 && !isLoading && `Total: ${total}`}
          {selectedCount === 0 && isLoading && <Loader size={32} />}
        </th>
        <th colSpan={2} />
      </thead>
      <tbody>
        {notifications.map((notification) => (
          <NotificationRow
            key={notification.id}
            notification={notification}
            isSelected={checkIsSelected(notification.id)}
            onToggleSelect={handleSelectRow}
            onClick={onNotificationClick}
            isOpened={openedNotificationId === notification.id}
          />
        ))}

        <div ref={loadingRef} />
      </tbody>
    </Table>
  );
};

export const List = () => {
  const { notifications, total, fetchNextPage, isLoading, isFetching } = useNotifications();

  const { data: unreadCount, isLoading: isUnreadCounterLoading } = useUnreadNotificationCount();

  const { clientFilters } = useClientFilterState();

  const showWellDone =
    !isUnreadCounterLoading &&
    unreadCount === 0 &&
    clientFilters.statuses.length === 1 &&
    clientFilters.statuses[0] === 'unread';

  if ((isLoading || isFetching) && notifications.length === 0) {
    return (
      <PlaceholderBlock>
        <Loader />
      </PlaceholderBlock>
    );
  }

  if (showWellDone) {
    return (
      <PlaceholderBlock>
        <img src={wellDoneImg} alt="" />
        <PlaceholderTitle>Well done!</PlaceholderTitle>
        <PlaceholderDescription>You don’t have more inbox messages to review</PlaceholderDescription>
      </PlaceholderBlock>
    );
  }

  if (!isLoading && !isFetching && notifications.length === 0) {
    return (
      <PlaceholderBlock>
        <img src={noResultsImg} alt="" />
        <PlaceholderTitle>No results found</PlaceholderTitle>
        <PlaceholderDescription>Try adjusting your search filters</PlaceholderDescription>
      </PlaceholderBlock>
    );
  }

  return (
    <NotificationTable
      notifications={notifications}
      total={total}
      fetchNextPage={fetchNextPage}
      isLoading={isLoading || isFetching}
    />
  );
};
