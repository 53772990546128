import React, { useCallback, useMemo } from 'react';
import { ChevronDownIcon } from '@kit/ui/icons/ChevronDown';
import { colors } from '@styles';
import { getGeoLink } from '@utils/getGeoLink';
import { Clipboard, Edit2, MapPin, Plus } from 'react-feather';
import { ContextMenu, MenuItem } from '@kit/components/ContextMenu';
import { TrashIcon } from '@kit/ui/icons/Trash';
import { useConfirmDeleteModal, useModal } from '@common/PromiseModal';
import { useCompanyPropertiesMutations } from '@hooks/useCompanyProperties';
import { PropertyType } from '@types';
import { useRecordDetail } from '@hooks/useRecordDetail';
import { BriefcaseIcon } from '@kit/ui/icons/Briefcase';
import { useTaskDrawer } from '@components/Scheduler/useTaskDrawer';
import { Form as RequestForm } from '@features/RequestPortfolio/List/Form';
import { Form as ProjectForm } from '@features/ProjectPortfolio/List/Form';

import { DollarIcon } from '@kit/ui/icons/Dollar';
import { ButtonVariant, IconButton } from '@kit/ui/Button';
import { hasAccess } from '@utils/roles';
import { useUserRoleSettings } from '@hooks/useRoles';
import { useAppSelector } from '@hooks/store';
import { selectCurrentUser, selectWorkspaceId } from '@state/selectors';
import { Form } from './Form';
import { Accordion, AccordionDetails, AccordionSummary, AddressLink, SiteMenuContainer } from './styled';
import { Site } from './types';
import { SiteTabs } from './SiteTabs';

interface Props {
  clientId: number;
  site: Site;
  isExpanded: boolean;
  onToggleExpand: (site: Site, isExpanded: boolean) => void;
}

export const SiteConent = ({ clientId, site, isExpanded, onToggleExpand }: Props) => {
  const confirmDelete = useConfirmDeleteModal();
  const { openModal } = useModal();
  const companyId = useAppSelector(selectWorkspaceId);
  const user = useAppSelector(selectCurrentUser);

  const { data: record } = useRecordDetail(clientId, { refetchOnMount: false });
  const { data: access } = useUserRoleSettings(companyId, user.userId);

  const {
    updateProjectProperty: { mutateAsync: updateProjectProperty }
  } = useCompanyPropertiesMutations();

  const { onAddClick: onAddWorkOrder } = useTaskDrawer();

  const canCreateRecord = hasAccess(access, 'project', 'create');
  const canUpdateRecord = hasAccess(access, 'project', 'edit');

  const canCreateWO = hasAccess(access, 'task', 'create');

  const menuItems = useMemo<MenuItem[]>(() => {
    if (!canUpdateRecord) {
      return [];
    }

    return [
      {
        icon: <Edit2 size="16px" color="#9C9CAA" />,
        title: 'Edit',
        onClick: () => {
          openModal<void>(({ onClose }) => <Form clientId={clientId} site={site} onClose={onClose} />, {
            title: 'Edit Site'
          });
        },
        isVisible: true
      },
      {
        icon: <TrashIcon size="16px" color="#D54855" />,
        title: 'Delete',
        onClick: async () => {
          if (await confirmDelete(`Are you sure you want to delete "${site.address}" Site?`)) {
            // since we allow delete site when it does not have any requests or projects
            // we need to update client address property only
            await updateProjectProperty({
              projectId: clientId,
              property: {
                id: -3,
                isAdditional: false,
                type: PropertyType.Text,
                mappedName: 'address'
              },
              newValue: record.address.filter((address) => address !== site.address)
            });
          }
        },
        isVisible: site.requests.length === 0 && site.projects.length === 0 && site.workOrders.length === 0
      }
    ].filter((item) => item.isVisible);
  }, [site, confirmDelete, clientId, updateProjectProperty, record, openModal, canUpdateRecord]);

  const addMenuItems = useMemo<MenuItem[]>(() => {
    return [
      {
        icon: <DollarIcon size="16px" color="#9C9CAA" />,
        title: 'Create Request',
        onClick: () => {
          openModal<void>(
            ({ onClose }) => (
              <RequestForm
                initialValues={{
                  title: record.title,
                  client: record,
                  address: site.address,
                  primaryContact: record.projectContacts[0],
                  primaryContactPhone: record.projectContacts[0]?.phones[0] ?? '',
                  primaryContactEmail: record.projectContacts[0]?.emails[0] ?? ''
                }}
                onClose={onClose}
              />
            ),
            {
              title: 'Create Request'
            }
          );
        },
        isVisible: canCreateRecord
      },
      {
        icon: <BriefcaseIcon size="16px" color="#9C9CAA" />,
        title: 'Create Project',
        onClick: () => {
          openModal<void>(
            ({ onClose }) => (
              <ProjectForm
                initialValues={{
                  title: record.title,
                  client: record,
                  address: site.address,
                  primaryContact: record.projectContacts[0],
                  primaryContactPhone: record.projectContacts[0]?.phones[0] ?? '',
                  primaryContactEmail: record.projectContacts[0]?.emails[0] ?? ''
                }}
                onClose={onClose}
              />
            ),
            {
              title: 'Create Project'
            }
          );
        },
        isVisible: canCreateRecord
      },
      {
        icon: <Clipboard size="16px" color="#9C9CAA" />,
        title: 'Create Service Work Order',
        onClick: () => {
          onAddWorkOrder({ project: record, address: site.address });
        },
        isVisible: canCreateWO
      }
    ].filter((item) => item.isVisible);
  }, [onAddWorkOrder, record, site.address, openModal, canCreateRecord, canCreateWO]);

  const handleToggle = useCallback(
    (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      onToggleExpand(site, isExpanded);
    },
    [site, onToggleExpand]
  );

  return (
    <Accordion key={site.address} expanded={isExpanded} onChange={handleToggle}>
      <AccordionSummary
        expandIcon={<ChevronDownIcon color={colors.green} />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <AddressLink href={getGeoLink(undefined, site.address)} target="_blank" rel="noreferrer">
          <MapPin size={13} />
          <div>{site.address || 'No address specified'}</div>
        </AddressLink>
        {menuItems.length > 0 && (
          <SiteMenuContainer
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <ContextMenu items={menuItems} />
          </SiteMenuContainer>
        )}

        {addMenuItems.length > 0 && (
          <div onClick={(e) => e.stopPropagation()}>
            <ContextMenu items={addMenuItems}>
              <IconButton variant={ButtonVariant.Secondary}>
                <Plus size="16px" />
              </IconButton>
            </ContextMenu>
          </div>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <div>
          <SiteTabs site={site} />
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
