import React from 'react';
import { Table as TableMui, TableProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { table } from './styled';

export interface Props extends TableProps {
  fullWidthTable?: boolean;
}

export const Table: React.FC<Props> = (props) => {
  const {
    fullWidthTable = true
  } = props;

  const useStylesTable = makeStyles(table({ fullWidthTable }));
  const classesTable = useStylesTable();

  return (
    <TableMui
      classes={classesTable}
      {...props}
    />
  );
};
