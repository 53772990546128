import { createAction } from 'redux-actions';
import { NotificationSettings } from '../../../components/templates/Settings/Notification/type';
import notificationsApi from '../../../services/api/notificationsApi';

export const fetchNotificationSettingsRequest = createAction(
  'FETCH_NOTIFICATION_SETTINGS_REQUEST'
);
export const fetchNotificationSettingsSuccess = createAction(
  'FETCH_NOTIFICATION_SETTINGS_SUCCESS'
);
export const fetchNotificationSettingsError = createAction(
  'FETCH_NOTIFICATION_SETTINGS_ERROR'
);

export const fetchNotificationSettings = () => async (dispatch) => {
  dispatch(fetchNotificationSettingsRequest());
  try {
    const response = await notificationsApi.fetchNotificationSettings();
    const res = response.data;
    if (res && res?.notificationSettings?.events) {
      const notificationSettings = res.notificationSettings
        .events as NotificationSettings;
      dispatch(
        fetchNotificationSettingsSuccess({
          notificationSettings
        })
      );
    } else {
      dispatch(fetchNotificationSettingsError());
    }
  } catch (error) {
    console.log('Error: ', error);
    dispatch(fetchNotificationSettingsError());
  }
};

export const updateNotificationSettingsRequest = createAction(
  'UPDATE_NOTIFICATION_SETTINGS_REQUEST'
);
export const updateNotificationSettingsSuccess = createAction(
  'UPDATE_NOTIFICATION_SETTINGS_SUCCESS'
);
export const updateNotificationSettingsError = createAction(
  'UPDATE_NOTIFICATION_SETTINGS_ERROR'
);

export const updateNotificationSettings = (
  settings: NotificationSettings
) => async (dispatch) => {
  dispatch(updateNotificationSettingsRequest());
  try {
    const response = await notificationsApi.updateNotificationSettings(
      settings
    );
    const res = response.data;
    if (res && res?.notificationSettings?.events) {
      const notificationSettings = res.notificationSettings
        .events as NotificationSettings;
      dispatch(
        updateNotificationSettingsSuccess({
          notificationSettings
        })
      );
    } else {
      dispatch(updateNotificationSettingsError());
    }
  } catch (error) {
    console.log('Error: ', error);
    dispatch(updateNotificationSettingsError());
  }
};
