import { colors } from '@styles';
import styled from 'styled-components';

export const EmptySectionDropZone = styled.div`
  padding: 12px 16px;
  background-color: #ffffff;
`;

export const EmptySectionDropZoneInner = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #dfdfe8;
`;

export const EmptySectionDropZoneContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ActionText = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  color: ${colors.green};
  cursor: pointer;
`;
