import { useDebouncedState as useReactHookzDebouncedState } from '@react-hookz/web/cjs/useDebouncedState';
import { useSafeState } from '@react-hookz/web/cjs/useSafeState';

import { SetStateAction, useCallback } from 'react';

const useDebouncedState = <T>(initialState: T, delay: number): [T, T, (value: SetStateAction<T>) => void] => {
  const [currentState, setCurrentState] = useSafeState(initialState);
  const [debouncedState, setDebouncedState] = useReactHookzDebouncedState(initialState, delay);

  const setState = useCallback(
    (value: SetStateAction<T>) => {
      setCurrentState(value);
      setDebouncedState(value);
    },
    [setCurrentState, setDebouncedState]
  );

  return [currentState, debouncedState, setState];
};

export { useDebouncedState };
