import { UiSizes, UiSizesValues } from '@enums';
import { makeStyles } from '@material-ui/core';
import { colors } from '../../colors';
import { fonts } from '../../fonts';

type Props = {
  width?: String;
  height?: String;
  borderWidth?: String;
  size?: UiSizes;
};

const getHeightFromProps = (props: Props) => {
  if (props.size) {
    return UiSizesValues[props.size];
  }

  return (props.height && props.height.replace(/px/, '')) || 40;
};

const input = (params: Props) => {
  return {
    root: {
      width: `${params.width || '100%'}`,
      height: `${getHeightFromProps(params)}px`,
      padding: '0',
      '& .MuiInputBase-root': {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        background: colors.white,
        position: 'relative',
        borderRadius: '4px'
      },
      '& .MuiInputBase-input.Mui-disabled': {
        opacity: '0.4'
      },
      '& .MuiOutlinedInput-input': {
        height: '100%',
        color: colors.black,
        padding: '0 16px',
        minHeight: '40px',
        font: `400 14px/17px ${fonts.primary}`,
        '&::placeholder': {
          fontWeight: 300,
          color: colors.gray2,
          opacity: 1
        }
      },
      '& .MuiInputLabel-root': {
        color: colors.gray
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#DFDFE8',
        transition: '0.3s ease-out'
      },
      '&:hover .MuiOutlinedInput-notchedOutline, &:focus .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.green
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
        color: colors.black
      },
      '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.gray3
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.green,
        borderWidth: `${params.borderWidth || '1px'}`
      },
      '& .MuiInputBase-root + .Mui-error': {
        margin: '0',
        position: 'absolute',
        top: '-30px',
        right: '0'
      }
    }
  };
};

export default input;

export const useStylesInput = makeStyles(input({ height: '40px' }));
