import styled, { css } from 'styled-components';
import { ProjectStageBadge } from '@components/ProjectStages';
import { SlaFlag } from '@common/SlaFlag';

export const CheckboxContainer = styled.div`
  position: absolute;

  left: 2px;
  top: 2px;
  z-index: 1;
`;

export const Td = styled.td<{ isSelected: boolean }>`
  &&& {
    width: 318px;
    min-width: 318px;
    max-width: 318px;
    padding: 0;
    background-color: ${({ isSelected }) => (isSelected ? '#E3F6F4' : '#fff')};
    position: sticky;
    left: 0px;
    z-index: 2;
    border-right: 1px solid #dfdfe8;
  }

  &:hover {
    ${CheckboxContainer} {
      display: block;
    }
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      ${CheckboxContainer} {
        display: block;
      }
    `};
`;

export const Container = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const ImageAndCheckboContainer = styled.div`
  position: relative;
  flex-shrink: 0;
`;

export const Image = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 44px;
  height: 44px;
  flex-shrink: 0;
  border-radius: 4px;
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: space-between;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const FirstLine = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;

  svg {
    flex-shrink: 0;
  }
`;

export const SecondLine = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  > * {
    font-weight: 400;
  }
`;

export const Title = styled.a<{ isGray: boolean }>`
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({ isGray }) => (isGray ? '#828D9A' : '#1d1d35')};
  display: block;
  text-decoration: none;

  &:hover,
  &:active,
  &:link,
  &:visited,
  &:focus {
    color: ${({ isGray }) => (isGray ? '#828D9A' : '#1d1d35')};
  }

  span {
    font-weight: 400;
  }
`;

export const StageBadge = styled(ProjectStageBadge)`
  width: auto;
  max-width: 150px;
  height: 20px;
`;

export const OverdueBadge = styled(SlaFlag)`
  height: 20px;
`;

export const WorkOrderCounters = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 14px;
  font-weight: 400;
`;

export const WorkOrderCounter = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
