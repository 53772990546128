import React from 'react';
import { makeStyles, TextField, TextFieldProps } from '@material-ui/core';
import { inputStyles } from './styled';

interface Props extends Omit<TextFieldProps, 'variant'> {}

const useStyles = makeStyles(inputStyles());

export const Textarea = React.forwardRef((props: Props, ref) => {
  const classes = useStyles();

  return <TextField minRows={5} inputRef={ref} variant="outlined" classes={classes} fullWidth {...props} multiline />;
});
