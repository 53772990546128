import React from 'react';
import { History } from '@features/ProjectPortfolio/Project/History';
import { CreateEventsPanel } from '@features/ClientPortfolio/Client/CreateEventsPanel';
import { RecordType } from '@types';
import { RightPanel as Container } from './styled';

interface Props {
  projectId: number;
}

export const RightPanel = ({ projectId }: Props) => {
  return (
    <Container>
      <CreateEventsPanel recordId={projectId} recordType={RecordType.DEAL} />
      <History recordId={projectId} />
    </Container>
  );
};
