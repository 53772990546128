import { colors } from '../colors';

export const cell = () => `
  min-width: 220px;
  max-width: 220px;
  width: 220px;
  height: 44px;

  margin: 0px;
  padding: 0px 12px !important;

  font-size: 14px;
  line-height: 1.25;
  font-weight: 400;
  text-align: left;
  text-overflow: ellipsis;
  color: ${colors.gray} !important;

  border: none;
  border-right: 1px solid #d8d8d8;

  overflow: hidden;

  &:last-child {
    border-right: none;
  }

  &:focus {
    outline: none;
  }
  
  &:hover {
    cursor: text;
    //border-bottom: 2px solid #009688;
  }
`;

export default {
  cell
};
