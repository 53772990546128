import { useToast } from '@hooks/useToast';
import { formsApi } from '@services/api/formsApi';
import { errorHandler } from '@services/api/helpers';
import { useMutation, useQueryClient } from 'react-query';
import download from 'downloadjs';
import { ReactQueryKey } from '@enums';

type ExportFormParams = {
  saveAsFile: boolean;
  includeImageMetadata: boolean;
  imagesInRow: number;
  exportType: 'PDF' | 'ZIP';
  imagesOnly: boolean;
};

export const useExportForm = () => {
  const { showError } = useToast();
  const queryClient = useQueryClient();

  return useMutation<void, Error, { formId: number; dto?: ExportFormParams }>(
    async ({ formId, dto = {} }) => {
      try {
        const { data, headers } = await formsApi.export({
          formId,
          ...dto
        });

        // TODO get file name from headers

        const content = headers['content-type'];

        const fileName = decodeURIComponent(
          headers['content-disposition'].split(/;(.+)/)[1].split(/=(.+)/)[1].replace(/['"]/g, '')
        );

        download(data, fileName, content);
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: (_, { dto = {} }) => {
        if (dto.saveAsFile) {
          queryClient.invalidateQueries([ReactQueryKey.DatabankTree]);
        }
      }
    }
  );
};
