import { Button, ButtonSize, ButtonVariant } from '@kit/ui/Button';
import React from 'react';

interface SubmitButtonProps {
  text?: string;
  disabled?: boolean;
  analyticsContext?: string;
  onClick?: () => unknown;
}

export const SubmitButton: React.FC<SubmitButtonProps> = (props) => {
  const {
    text = 'Submit',
    disabled = false,
    analyticsContext,
    onClick,
    ...rest
  } = props;

  return (
    <Button
      {...rest}
      variant={ButtonVariant.Primary}
      type="submit"
      onClick={onClick}
      disabled={disabled}
      data-analyticsid={`submitBtn_${analyticsContext}`}
    >
      {text}
    </Button>
  );
};
