import React from 'react';
import { useWorkOrderTemplateList } from '@hooks/templates/workOrders/useWorkOrderTemplateList';
import { PredefinedWidgetFilterFieldId } from '@features/Analytics/types';
import { Input } from '@kit/ui/Input';
import { useAllCompaniesUsers } from '@hooks/useCompanyUsers';
import { getFullName } from '@utils/utils';
import { DropDownItem } from '@common/Selector/UserSelector/DropDownItem';
import { isWorkOrderDateFilterField, isWorkOrderTextFilterField } from '@features/Analytics/helpers';
import { DateTimePicker } from '@kit/ui/DateTimePicker';
import { FilterOperators } from '@types';
import { useWorkOrderStatuses } from '@hooks/workOrders/useWorkOrderStatuses';
import { TaskStatusEntity } from '@generated/types/graphql';
import { PRIORITY_OPTIONS } from '@components/Scheduler/constants';
import { useLabelList } from '@hooks/workspace/labels/useLabelList';
import { useDateRangeOptions } from '../useDateRangeOptions';
import { FilterValueProps } from './types';
import { SelectWrapper } from './SelectWrapper';

export const WorkOrderFilterValue = ({ filter, onChange }: FilterValueProps) => {
  const { data: companyUsers = [] } = useAllCompaniesUsers();
  const { data: statuses = [] } = useWorkOrderStatuses();
  const { data: labels = [] } = useLabelList();
  const dateRangeOptions = useDateRangeOptions();

  const { data: workOrderTemplates = [] } = useWorkOrderTemplateList();
  const { field, value, operator } = filter;

  switch (field.id) {
    case PredefinedWidgetFilterFieldId.WORK_ORDER_TYPE:
      return (
        <SelectWrapper
          getOptionLabel={(option) => option.name}
          value={value ?? null}
          onChange={(_e, value) => onChange(value)}
          options={[
            {
              id: 'office',
              name: 'Office Work Order'
            },
            {
              id: 'field',
              name: 'Field Work Order'
            }
          ]}
        />
      );

    case PredefinedWidgetFilterFieldId.WORK_ORDER_TEMPLATE:
      return (
        <SelectWrapper
          isMulti
          getOptionLabel={(option) => option.title}
          value={value ?? []}
          onChange={(_e, value) => onChange(value)}
          options={workOrderTemplates}
        />
      );

    case PredefinedWidgetFilterFieldId.WORK_ORDER_ASSIGNEE:
      return (
        <SelectWrapper
          isMulti
          value={value ?? []}
          onChange={(_e, value) => onChange(value)}
          options={companyUsers}
          getOptionLabel={getFullName}
          noOptionsText="User not found"
          renderOption={(option: any) => (
            <DropDownItem id={option.id} avatarUrl={option.avatarUrl} name={getFullName(option)} />
          )}
        />
      );

    case PredefinedWidgetFilterFieldId.WORK_ORDER_STATUS:
      return (
        <SelectWrapper
          isMulti
          value={value ?? []}
          onChange={(_e, value) => onChange(value)}
          options={statuses}
          getOptionLabel={(option: TaskStatusEntity) => option.label}
          noOptionsText="Status not found"
        />
      );

    case PredefinedWidgetFilterFieldId.WORK_ORDER_PRIORITY:
      return (
        <SelectWrapper
          isMulti
          value={value ?? []}
          onChange={(_e, value) => onChange(value)}
          options={PRIORITY_OPTIONS}
          getOptionLabel={(option) => option.title}
          noOptionsText="Priority not found"
        />
      );

    case PredefinedWidgetFilterFieldId.WORK_ORDER_LABEL:
      return (
        <SelectWrapper
          isMulti
          value={value ?? []}
          onChange={(_e, value) => onChange(value)}
          options={labels}
          getOptionLabel={(option) => option.label}
          noOptionsText="Label not found"
        />
      );

    default: {
      if (isWorkOrderTextFilterField(field.id)) {
        return <Input value={value || ''} onChange={(e) => onChange(e.target.value)} />;
      }

      if (isWorkOrderDateFilterField(field.id)) {
        if ([FilterOperators.Within, FilterOperators.NotWithin].includes(operator.op)) {
          return (
            <SelectWrapper
              getOptionLabel={(option) => option.name}
              options={dateRangeOptions}
              value={value ?? null}
              onChange={(_e, value) => onChange(value)}
            />
          );
        }

        return (
          <DateTimePicker
            placeholder="Select date"
            isClearable
            isOnlyDate
            withPortal
            value={value}
            onClose={onChange}
          />
        );
      }

      return null;
    }
  }
};
