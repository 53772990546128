import { fonts } from '@styles';
import styled from 'styled-components';

export const Menu = styled.div`
  && {
    .MuiTypography-body2 {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      font-family: ${fonts.primary};
    }

    padding: 8px;
  }
`;

export const Trigger = styled.div<{ isHidden: boolean }>`
  cursor: ${({ isHidden }) => (isHidden ? 'default' : 'pointer')};
  display: flex;
`;
