import colors from '../colors';

export const wrapper = () => `
  progress {
    -webkit-appearance: none;
    width: 100%;
    height: 4px;
    border-radius: 4px;
  }
  
  progress::-webkit-progress-bar {
    -webkit-appearance: none;
    width: 100%;
    height: 4px;
    border-radius: 4px;
  }
  
  progress::-webkit-progress-value {
    -webkit-appearance: none;
    width: 100%;
    height: 4px;
    border-radius: 4px;
  }
  
  progress::-moz-progress-bar {
    -webkit-appearance: none;
    width: 100%;
    height: 4px;
    border-radius: 4px;
  }

  progress::-webkit-progress-value {
    background-color: ${colors.green} !important;
  }
  
  progress[value]::-webkit-progress-bar {
    background-color: ${colors.gray3};
    background-image: ${colors.green};
    border-radius: 4px;
  }
`;

export default wrapper;
