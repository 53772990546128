import React, { useMemo } from 'react';
import {
  FormControlLabel as FormControlLabelMui,
  FormControlLabelProps as FormControlLabelPropsMui,
  makeStyles
} from '@material-ui/core';
import { formControlLabel } from './styled';

interface FormControlLabelProps extends FormControlLabelPropsMui {

}

export const FormControlLabel: React.FC<FormControlLabelProps> = (props) => {
  const {
    ...rest
  } = props;

  const makeStylesParam = useMemo(() => (formControlLabel()), []);
  const classes = makeStyles(makeStylesParam)();

  return (
    <FormControlLabelMui
      classes={classes}
      {...rest}
    />
  );
};
