import { Button, ButtonSize, ButtonVariant } from '@kit/ui/Button';
import React from 'react';

interface CancelButtonProps {
  text?: string;
  onClick?: () => unknown;
  disabled?: boolean;
}

export const CancelButton: React.FC<CancelButtonProps> = (props) => {
  const {
    text = 'Close',
    onClick,
    disabled = false
  } = props;

  return (
    <Button
      onClick={onClick}
      variant={ButtonVariant.Secondary}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};
