import { useEffect, useCallback } from 'react';
import { isNull } from 'lodash/fp';
import { createGlobalState, useLocation } from 'react-use';

import { useRoutes } from '@hooks';
import UserPreferencesService from '@services/UserPreferences';
import { getRecordTypeFromUrl, parseWorkspaceIdFromUrl } from '@utils';
import { PlayModeStorage } from '@types';

const defaultState = {} as PlayModeStorage;

const usePlayModeState = createGlobalState<PlayModeStorage>(
  UserPreferencesService.playMode.get({
    companyId: parseWorkspaceIdFromUrl(),
    recordType: getRecordTypeFromUrl()
  }) || defaultState
);

export const usePlayMode = () => {
  const { companyId, recordType, navigateTo, getRecordDetailsPagePath } = useRoutes();
  const [state, setState] = usePlayModeState();
  const { pathname } = useLocation();

  const urlToMatch = getRecordDetailsPagePath({
    companyId: state.companyId, id: state.list?.[state.pointer], type: state.recordType
  });
  const isMatch = pathname.startsWith(urlToMatch);

  const updateState = useCallback((newState: typeof state) => {
    UserPreferencesService.playMode.set({
      companyId,
      recordType
    }, newState);
    setState(newState);
  }, [companyId, recordType, setState]);
  const exit = useCallback(() => updateState(defaultState), [updateState]);

  useEffect(() => {
    if (!isNull(state.pointer) && !isMatch) {
      exit();
    }
  }, [isMatch, state.pointer, exit]);

  const create = async (list: PlayModeStorage['list']) => {
    await navigateTo.recordDetailPage(list[0]);

    updateState({
      recordType,
      companyId,
      pointer: 0,
      list,
    });
  };

  const goTo = async (newPointer: number) => {
    updateState({
      ...state,
      pointer: newPointer,
    });
    navigateTo.recordDetailPage(state.list[newPointer]);
  };

  const next = () => {
    goTo(state.pointer + 1);
  };

  const prev = () => {
    goTo(state.pointer - 1);
  };

  return {
    create,
    next: state.pointer !== state.list?.length - 1 ? next : null,
    prev: state.pointer !== 0 ? prev : null,
    exit,
    pointer: state.pointer,
    list: state.list
  };
};
