import React from 'react';
import { Flag } from 'react-feather';
import { Badge } from '@common/Badge';
import { defaultTheme } from '@themes';
import { ProjectDetail } from '@types';
import { getRedSlaOverdueInDays, getYellowSlaOverdueInDays } from '@utils';

interface SlaFlagProps {
  record: ProjectDetail;
  className?: string;
}

const baseStyles = {
  color: defaultTheme.colors.white,
  font: defaultTheme.typefaces.text
};

const warningStyles = {
  backgroundColor: defaultTheme.colors.yellow,
  ...baseStyles
};
const alertStyles = {
  backgroundColor: defaultTheme.colors.red,
  ...baseStyles
};

export const SlaFlag: React.FC<SlaFlagProps> = ({ record, className }) => {
  const redSlaOverdue = getRedSlaOverdueInDays(record);
  const yellowSlaOverdue = getYellowSlaOverdueInDays(record);

  const days = redSlaOverdue || yellowSlaOverdue;

  if (!days) {
    return null;
  }

  return (
    <Badge className={className} style={(redSlaOverdue && alertStyles) || (yellowSlaOverdue && warningStyles)}>
      <Flag color={defaultTheme.colors.white} />
      {days}d
    </Badge>
  );
};
