import { FormLayout } from '@generated/types/graphql';
import { useCompanyProperties } from '@hooks/useCompanyProperties';
import {
  CheckboxGroupField,
  DateTimeField,
  InputField,
  RadioGroupField,
  SelectField,
  TextareaField,
  RichTextField
} from '@kit/components/Form';
import { PropertyType, RecordType } from '@types';
import React, { useMemo } from 'react';
import { DropDownItem } from '@common/Selector/UserSelector/DropDownItem';
import { getFullName } from '@utils/utils';
import { useAllCompaniesUsers } from '@hooks/useCompanyUsers';
import { InfoIcon } from '@kit/ui/icons/Info';
import { FileField } from '../FileField';
import { FieldLabel } from '../../FieldLabel';
import { DeletedProperty, FieldPlaceholder } from './styled';

interface Props {
  field: FormLayout;
  control: any;
  formId: number;
  isFullScreen?: boolean;
  onFileClick?: (fileId: number) => void;
  onBlur?: () => void;
  fieldName: string;
}

export const ProjectField = ({
  isFullScreen = false,
  formId,
  field,
  fieldName,
  control,
  onFileClick,
  onBlur
}: Props) => {
  const {
    scopeToColumns,
    scopeToAllColumns,
    fetchQuery: { isFetching: isLoadingColumns }
  } = useCompanyProperties({
    recordType: RecordType.PROJECT,
    enabled: true
  });

  const { data: companyUsers } = useAllCompaniesUsers();

  const columnId = field.column!.projectColumnId;

  const selectedColumn = useMemo(() => {
    const column = scopeToColumns[RecordType.PROJECT].find(
      (c) => c.mappedName !== 'lastActivity' && +c.id === +columnId
    );

    if (column) {
      return { ...column, noAccess: false };
    }

    const columnWithNoAccess = scopeToAllColumns[RecordType.PROJECT].find((c) => +c.id === +columnId);

    return columnWithNoAccess ? { ...columnWithNoAccess, noAccess: true } : null;
  }, [columnId, scopeToColumns, scopeToAllColumns]);

  if (isLoadingColumns) {
    return null;
  }

  if (!selectedColumn || selectedColumn.noAccess) {
    return (
      <DeletedProperty>
        <FieldLabel isDisabled isProjectProperty fieldName={fieldName} notes="" />
        <FieldPlaceholder>
          <InfoIcon size="16px" />
          {selectedColumn?.noAccess ? 'You don’t have access to this Property' : 'This Property was deleted.'}
        </FieldPlaceholder>
      </DeletedProperty>
    );
  }

  switch (selectedColumn.type) {
    case PropertyType.Date:
    case PropertyType.DateTime:
      return (
        <DateTimeField
          placeholder="Select date"
          isOnlyDate={selectedColumn.type === PropertyType.Date}
          label={<FieldLabel isProjectProperty fieldName={fieldName} notes={field.column.notes} />}
          name={`field_${field.column.id}`}
          control={control}
          withPortal
          isDisabled={field.column.isReadOnly}
          onClose={onBlur}
          isClearable
        />
      );
    case PropertyType.Numeric:
      return (
        <InputField
          type="number"
          label={<FieldLabel isProjectProperty fieldName={fieldName} notes={field.column.notes} />}
          name={`field_${field.column.id}`}
          control={control}
          disabled={field.column.isReadOnly}
        />
      );
    case PropertyType.Text:
      if (selectedColumn.mappedName === 'description') {
        return (
          <RichTextField
            label={<FieldLabel isProjectProperty fieldName={fieldName} notes={field.column.notes} />}
            name={`field_${field.column.id}`}
            control={control}
            disabled={field.column.isReadOnly}
          />
        );
      }

      return (
        <TextareaField
          label={<FieldLabel isProjectProperty fieldName={fieldName} notes={field.column.notes} />}
          name={`field_${field.column.id}`}
          control={control}
          minRows={1}
          disabled={field.column.isReadOnly}
        />
      );

    case PropertyType.Link:
      return (
        <InputField
          label={<FieldLabel isProjectProperty fieldName={fieldName} notes={field.column.notes} />}
          name={`field_${field.column.id}`}
          control={control}
          disabled={field.column.isReadOnly}
          valuePrefix="https://"
        />
      );
    case PropertyType.Dropdown:
      if (selectedColumn.multiple) {
        return (
          <CheckboxGroupField
            label={<FieldLabel isProjectProperty fieldName={fieldName} notes={field.column.notes} />}
            name={`field_${field.column.id}`}
            control={control}
            isDisabled={field.column.isReadOnly}
            options={selectedColumn.additional?.values?.map((value) => ({ label: value, value })) ?? []}
          />
        );
      }

      return (
        <RadioGroupField
          label={<FieldLabel isProjectProperty fieldName={fieldName} notes={field.column.notes} />}
          name={`field_${field.column.id}`}
          control={control}
          disabled={field.column.isReadOnly}
          options={selectedColumn.additional?.values?.map((value) => ({ label: value, value })) ?? []}
        />
      );

    case PropertyType.File:
      return (
        <FileField
          label={<FieldLabel isProjectProperty fieldName={fieldName} notes={field.column.notes} />}
          name={`field_${field.column.id}`}
          control={control}
          formId={formId}
          onFileClick={onFileClick}
          isFullScreen={isFullScreen}
          disabled={field.column.isReadOnly}
          references={
            field.column.referenceFiles ?? field.column.projectPropertyFileReferencesByColumnId?.map((ref) => ref.file)
          }
        />
      );

    case PropertyType.Person:
      return (
        <SelectField
          label={<FieldLabel isProjectProperty fieldName={fieldName} notes={field.column.notes} />}
          name={`field_${field.column.id}`}
          control={control}
          getOptionLabel={getFullName}
          options={companyUsers}
          disabled={field.column.isReadOnly}
          noOptionsText="User not found"
          renderOption={(option: any) => (
            <DropDownItem id={option.id} avatarUrl={option.avatarUrl} name={getFullName(option)} />
          )}
        />
      );

    default:
      return `Unsupported project property type ${selectedColumn.type}`;
  }
};
