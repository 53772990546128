import styled from 'styled-components';

export const Sections = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const SectionTitle = styled.div`
  color: #828d9a;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

export const TwoFields = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;

  > * {
    flex: 1;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
`;

export const ContactOptionTitle = styled.div`
  font-weight: 500;
`;
export const ContactOptionMeta = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #9c9caa;
`;
