import { scroll } from '@styles';
import styled from 'styled-components';

export const List = styled.div`
  ${scroll};
  overflow: auto;

  max-height: 500px;
`;

export const Title = styled.div<{ isCompleted: boolean }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 2 !important;
  text-align: left !important;
  font-weight: 500;
  text-decoration: ${({ isCompleted }) => (isCompleted ? 'line-through' : 'none')};
`;

export const Meta = styled.div`
  color: #1d1d35;
  font-size: 14px;
  font-weight: 400;

  display: flex;
  align-items: center;
  gap: 4px;

  svg {
    flex-shrink: 0;
  }
`;

export const Assignee = styled.div`
  flex: 0 0 24px;
  min-width: 24px;
  width: 24px;
  max-width: 24px;
`;

export const AvatarPlaceholder = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid #dfdfe8;
  color: #dfdfe8;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Row = styled.div<{ isHighlighted?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 4px;
  border-bottom: 1px solid #dfdfe8;
  transition: background-color 0.15s ease-in-out;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  background-color: ${({ isHighlighted }) => (isHighlighted ? '#E3F6F4' : 'transparent')};

  &:hover {
    background-color: #e8ecef;
  }

  svg {
    flex-shrink: 0;
  }

  &:last-child {
    border-bottom: 0;
  }
`;

export const EmptyText = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #828d9a;
`;
