import React, { useCallback, useState } from 'react';
import { Collapse } from '@material-ui/core';
import { ChevronDownIcon } from '@kit/ui/icons/ChevronDown';
import { ChevronUpIcon } from '@kit/ui/icons/ChevronUp';
import { Draggable } from 'react-beautiful-dnd';
import { Checkbox } from '@kit/ui/Checkbox';
import { RecordDocListItem } from '@hooks/documents/useRecordDocList';
import { RecordType } from '@types';
import { DocListItem } from '../components/DocListItem';
import { useDocListGroupedByStage } from './useDocListGroupedByStage';
import { useDocsAccess } from '../useDocsAccess';
import { SelectionType } from './useSelection';
import {
  ActiveFileDropZone,
  DraggableRow,
  DraggaingCounter,
  SectionContainer,
  SectionHeader,
  SectionHeaderLeft,
  SectionTitle
} from './styled';
import { EmptyDropZone } from '../components/EmptyDropZone';
import { useDropFiles } from '../useDropFiles';

interface Props {
  recordId: number;
  recordType: RecordType;
  group: ReturnType<typeof useDocListGroupedByStage>['groupedDocs'][number];
  isDraggingOver?: boolean;
  currentlyDraggingIds: number[];
  isAllSelected: boolean;
  onToggleAllSelected: (group: number, meta: SelectionType) => void;
  checkIsItemSelected: (group: number, project: RecordDocListItem) => boolean;
  toggleItem: (group: number, project: RecordDocListItem, meta: SelectionType) => void;
  onRowClick: (doc: RecordDocListItem) => void;
}

export const Group = ({
  currentlyDraggingIds,
  recordId,
  recordType,
  group,
  isDraggingOver,
  isAllSelected,
  onToggleAllSelected,
  checkIsItemSelected,
  toggleItem,
  onRowClick
}: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(group.docs.length === 0);
  const { canCreateFile } = useDocsAccess();

  const toggleCollapse = useCallback(() => {
    setIsCollapsed((prev) => !prev);
  }, []);

  const onToggleAllSelectedInGroup = useCallback<React.MouseEventHandler<HTMLLabelElement>>(
    (e) => {
      onToggleAllSelected(group.stage.id, { withShift: e.shiftKey, withCtrl: e.ctrlKey });
    },
    [group.stage.id, onToggleAllSelected]
  );

  const toggleItemInGroup = useCallback(
    (doc: RecordDocListItem, meta: SelectionType) => {
      toggleItem(group.stage.id, doc, meta);
    },
    [group.stage.id, toggleItem]
  );

  const checkIsItemSelectedInGroup = useCallback(
    (doc: RecordDocListItem) => {
      return checkIsItemSelected(group.stage.id, doc);
    },
    [checkIsItemSelected, group.stage.id]
  );

  const { getRootProps, getInputProps, isDragActive, openFileDialog } = useDropFiles({
    recordId,
    stageId: group.stage.id > 0 ? group.stage.id : undefined
  });

  return (
    <SectionContainer {...getRootProps()} isDraggingOver={canCreateFile && isDraggingOver}>
      <input {...getInputProps()} />
      {canCreateFile && isDragActive && <ActiveFileDropZone>Upload file into {group.stage.name}</ActiveFileDropZone>}
      <SectionHeader>
        <SectionHeaderLeft onClick={toggleCollapse}>
          <Checkbox
            isDisabled={group.docs.length === 0}
            isChecked={isAllSelected}
            onClick={onToggleAllSelectedInGroup}
          />
          <SectionTitle>
            {group.stage.name} ({group.docs.length})
          </SectionTitle>

          {isCollapsed ? (
            <ChevronUpIcon size="24px" color="#9C9CAA" />
          ) : (
            <ChevronDownIcon size="24px" color="#9C9CAA" />
          )}
        </SectionHeaderLeft>
      </SectionHeader>

      <Collapse in={!isCollapsed} timeout="auto">
        {group.docs.length === 0 && (
          <EmptyDropZone recordId={recordId} section={group.stage} openFileDialog={openFileDialog} />
        )}
        {group.docs.map((doc, index) => (
          <Draggable key={doc.id} draggableId={doc.id.toString()} index={index}>
            {(provided, snapshot) => {
              return (
                <>
                  <DraggableRow
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={provided.draggableProps.style}
                    isDragging={snapshot.isDragging}
                    isCloneOfDragging={!snapshot.isDragging && currentlyDraggingIds.includes(doc.id)}
                    draggingCount={currentlyDraggingIds.length}
                  >
                    <DocListItem
                      isSelected={checkIsItemSelectedInGroup(doc)}
                      toggleItem={toggleItemInGroup}
                      doc={doc}
                      recordType={recordType}
                      onClick={onRowClick}
                    />

                    {snapshot.isDragging && currentlyDraggingIds.length > 1 && (
                      <DraggaingCounter>{currentlyDraggingIds.length}</DraggaingCounter>
                    )}
                  </DraggableRow>

                  {snapshot.isDragging && (
                    <DraggableRow isDragging={false} isCloneOfDragging>
                      <DocListItem
                        isSelected={checkIsItemSelectedInGroup(doc)}
                        toggleItem={toggleItemInGroup}
                        doc={doc}
                      />
                    </DraggableRow>
                  )}
                </>
              );
            }}
          </Draggable>
        ))}
      </Collapse>
    </SectionContainer>
  );
};
