import { useTask } from '@components/Scheduler/useTaskDrawer';
import { useAppSelector } from '@hooks/store';
import { getToken } from '@services/api/base/axios';
import { selectUser, selectWorkspaceId } from '@state/selectors';
import { useCallback, useEffect } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as io from 'socket.io-client';
import { config } from '../../../../config';

const socketInstance: { current: io.Socket | null } = {
  current: null
};

export const useSubscribeToUpdates = (id: number, onUpdated: (workOrder: any) => void, onRemoved: () => void) => {
  const user = useAppSelector(selectUser);
  const token = getToken();
  const companyId = useAppSelector(selectWorkspaceId);
  const { refetch } = useTask(id);

  const handleWorkOrderRemove = useCallback(
    (payload) => {
      if (payload.id === id) {
        onRemoved();
      }
    },
    [id, onRemoved]
  );

  const handleWorkOrderUpdate = useCallback(
    async (payload) => {
      if (payload.id === id) {
        const { data } = await refetch();
        onUpdated(data);
      }
    },
    [id, refetch, onUpdated]
  );

  useEffect(() => {
    if (user.userId && user.userId !== 0 && (socketInstance.current === null || !socketInstance.current.connected)) {
      // @ts-ignore
      socketInstance.current = io(`${config.socketIoUrl}`, {
        query: `jwt=${token}`,
        path: '/socket',
        rejectUnauthorized: false
      });

      socketInstance.current?.on('connect', () => {
        socketInstance.current?.emit('join', `WORK-ORDER-CHANGES-${companyId}`);
      });

      socketInstance.current?.on('WORK-ORDER-UPDATED', handleWorkOrderUpdate);

      socketInstance.current?.on('WORK-ORDER-DELETED', handleWorkOrderRemove);
    }

    return () => {
      if (socketInstance.current != null) {
        socketInstance.current.disconnect();
      }
    };
  }, [user, companyId, handleWorkOrderUpdate, handleWorkOrderRemove, token]);
};
