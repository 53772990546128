import { PortalStatus } from '@generated/types/graphql';
import React, { useMemo } from 'react';
import { Avatar } from '@kit/ui/Avatar';
import { Tooltip } from '@material-ui/core';
import { PortalIcon } from '@kit/ui/icons/Portal';
import { isCustomerPortalEnabled } from '@state/selectors';
import { useAppSelector } from '@hooks/store';
import { Container, PortalIconWrapper } from './styled';

interface Props {
  contact: {
    id: number;
    name: string;
    portalStatus: PortalStatus;
  };

  isPortalStatusShown?: boolean;

  size?: number;
}

export const ContactAvatar = ({ contact, size, isPortalStatusShown = true }: Props) => {
  const isPortalEnabled = useAppSelector(isCustomerPortalEnabled);

  const avatarUser = useMemo(() => {
    return {
      id: contact.id,
      firstName: contact.name,
      lastName: '',
      avatarUrl: ''
    };
  }, [contact]);

  return (
    <Container>
      <Avatar user={avatarUser} size={size} />
      {isPortalEnabled && isPortalStatusShown && contact.portalStatus === PortalStatus.Invited && (
        <Tooltip title="Contact is invited to Portal">
          <PortalIconWrapper>
            <PortalIcon size="12px" color="#FFF" />
          </PortalIconWrapper>
        </Tooltip>
      )}
    </Container>
  );
};
