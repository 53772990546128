import { DependencyList, EffectCallback, useEffect, useState } from 'react';

export const useEffectOnceBy = (effect: EffectCallback, condition: () => boolean, deps?: DependencyList) => {
  const [ran, setRan] = useState(false);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!ran && condition()) {
      setRan(true);

      return effect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
