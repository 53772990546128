import { useQuery } from 'react-query';

import { ReactQueryKey } from '@enums';
import { apiErrorHandler } from '@utils';
import columnApi from '@services/api/columnApi';

export const usePropertyMappedName = ($name: 'blueprintId' | 'stageId' | 'isActive') => {
  const QUERY_KEY = [ReactQueryKey.PropertyMappedName, $name];

  const findQuery = useQuery(
    QUERY_KEY,
    async ({ queryKey }) => {
      try {
        const [, name] = queryKey;
        const { data } = await columnApi.findByMappedName(name);

        return data;
      } catch (error) {
        throw apiErrorHandler('error fetching project stages',error);
      }
    },
    {
      initialData: { id: undefined }
    }
  );

  return findQuery;
};
