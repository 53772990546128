import { md } from './isMobile';

const isPhone = md.phone();

export const getGeoLink = (geoLocation: string, address: string) => {
  if (isPhone && geoLocation) {
    return md.is('iOS') ? `maps://?q=${geoLocation}` : `geo:${geoLocation}`;
  }

  return `http://maps.google.co.in/maps?q=${address}`;
};
