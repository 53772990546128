import styled from 'styled-components';

export const DeletedProperty = styled.div`
  margin-bottom: 32px;
`;

export const FieldPlaceholder = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #828d9a;
`;
