import React from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { RadioGroup } from '@kit/ui/RadioGroup';
import { FormField } from '../FormField';
import { FormControl, FormInputPropsToOmit } from '../types';
import { useControllerWithValidation } from '../useControllerWithValidation';

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends Omit<React.ComponentProps<typeof RadioGroup>, FormInputPropsToOmit | 'isChecked'>,
    FormControl<TFieldValues, TName> {
  label?: string;
  options: { value: string; label: string }[];
  placeholder?: string;
}

export const RadioGroupField = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  label,
  description,
  name,
  control,
  options,
  placeholder = 'No options',
  clearOnUnmount,
  ...inputProps
}: Props<TFieldValues, TName>) => {
  const {
    field: { ...controlProps },
    fieldState: { error }
  } = useControllerWithValidation(name, control, label, clearOnUnmount);

  return (
    <FormField name={name} label={label} error={error?.message} description={description}>
      {options.length === 0 && <div>{placeholder}</div>}
      {options.length > 0 && (
        <RadioGroup options={options} {...controlProps} {...inputProps} data-test-id={`field-${controlProps.name}`} />
      )}
    </FormField>
  );
};
