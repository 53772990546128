import styled from 'styled-components';
import { WidgetTitle } from '../styled';

export const Title = styled(WidgetTitle)`
  color: #828d9a;
  font-size: 16px;
  font-weight: 400;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  svg {
    width: 100%;
    height: auto;
  }
`;

export const Amount = styled.div`
  color: #828d9a;
  font-size: 32px;
  font-weight: 400;
`;

export const ComingSoon = styled.div`
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: #fcf2db;
  white-space: nowrap;

  color: #f1aa12;
  font-size: 12px;
  font-weight: 400;
`;

export const SwitchContainer = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  font-weight: 500;
  opacity: 0.3;
`;
