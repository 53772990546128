import React from 'react';
import { Pagination as MuiPagination, PaginationItem, PaginationProps } from '@material-ui/lab';
import { useStylesPagination, useStylesPaginationItem } from './styled';

export type { PaginationProps } from '@material-ui/lab';

export const Pagination: React.FC<PaginationProps> = (props) => {
  const { ...rest } = props;

  const classesPagination = useStylesPagination();
  const classesPaginationItem = useStylesPaginationItem();

  return (
    <MuiPagination
      {...rest}
      classes={classesPagination}
      renderItem={(item) => <PaginationItem {...item} classes={classesPaginationItem} />}
    />
  );
};
