import { IActionType } from '../actionTypes/actionTypeInterface';
import * as actionTypes from '../actionTypes/actionTypes';
import { getNewRefreshToken } from '../../../services/AuthenticationServices/AuthenticationService';
import { updateCompanies } from '../companies';
import { setSelectedCompany } from '../ui';
import UserPreferences from '@services/UserPreferences';

export const formLoginStart = (): IActionType => {
  return {
    type: actionTypes.FORM_LOGIN_START
  };
};

export const loginSuccess = (payload): IActionType => {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    payload
  };
};

export const formLoginFailed = (error) => {
  return {
    type: actionTypes.FORM_LOGIN_FAILED,
    error
  };
};

export const generateResetPasswordTokenStart = (): IActionType => {
  return {
    type: actionTypes.GENERATE_RESET_PASSWORD_TOKEN_START
  };
};

export const generateResetPasswordTokenSuccess = (payload): IActionType => {
  return {
    type: actionTypes.GENERATE_RESET_PASSWORD_TOKEN_SUCCESS,
    payload
  };
};

export const generateResetPasswordTokenFailed = (error) => {
  return {
    type: actionTypes.GENERATE_RESET_PASSWORD_TOKEN_FAILED,
    error
  };
};

export const resetPasswordResetState = () => {
  return {
    type: actionTypes.RESET_PASSWORD_RESET_STATE
  };
};

export const googleLoginStart = (): IActionType => {
  return {
    type: actionTypes.GOOGLE_LOGIN_START
  };
};

export const googleLoginFailed = (error) => {
  return {
    type: actionTypes.GOOGLE_LOGIN_FAILED,
    error
  };
};

export const companyRegisterStart = (): IActionType => {
  return {
    type: actionTypes.COMPANY_REGISTER_START
  };
};

export const companyRegisterSuccess = (payload): IActionType => {
  return {
    type: actionTypes.COMPANY_REGISTER_SUCCESS,
    payload
  };
};

export const companyRegisterFailed = (error) => {
  return {
    type: actionTypes.COMPANY_REGISTER_FAILED,
    error
  };
};

export const refreshTokenTimeOutUpdate = (checkTokenTimeout?: NodeJS.Timeout) => ({
  type: actionTypes.REFRESH_TOKEN_TIMEOUT_UPDATE,
  checkTokenTimeout
});

const logOutAction = (err?) => ({
  type: actionTypes.LOGOUT
});

export const logOut = () => async (dispatch) => {
  localStorage.removeItem('token');
  dispatch(logOutAction());
  dispatch(updateCompanies([]));
  dispatch(setSelectedCompany(undefined));
};

export const checkAuthTimeOut = (expirationTime) => {
  return (dispatch) => {
    const checkTokenTimeout = setTimeout(() => {
      dispatch(authCheckState());
    }, expirationTime * 1000);

    dispatch(refreshTokenTimeOutUpdate(checkTokenTimeout));
  };
};

export const authenticationUpdateAction = (payload) => {
  return {
    type: actionTypes.AUTHENTICATION_UPDATE,
    payload
  };
};

export const authCheckState = (isPrivatePage = true, updateUserState = false) => {
  return (dispatch) => {
    const token = localStorage.getItem('token');
    if (!token && !isPrivatePage) return;
    if (!token) {
      dispatch(logOut());
      return;
    }

    let parseToken;

    try {
      parseToken = JSON.parse(token);
    } catch (error) {
      dispatch(logOut());
      return;
    }

    if (!parseToken || !parseToken.accessToken || !parseToken.refreshToken) {
      dispatch(logOut());
      return;
    }

    const {
      accessToken: { expiresIn: accessTokenExpireDate },
      refreshToken: { expiresIn: refreshTokenExpiresDate, token: refreshToken },
      user
    } = parseToken;

    // Clears old token, created before the 0.3 release
    if (user?.companyId) {
      dispatch(logOut());
      return;
    }

    const companyIdFromUrl = window.location.pathname.match(/^\/(\d+)\/.*/)?.[1];

    if (companyIdFromUrl) {
      UserPreferences.selectedCompanyId.set(+companyIdFromUrl);
    }

    const now = new Date().getTime() / 1000;
    // check access token and if it's not expired set timer
    if (accessTokenExpireDate > now) {
      dispatch(checkAuthTimeOut(accessTokenExpireDate - now));
      if (updateUserState) {
        dispatch(loginSuccess({ ...parseToken, method: 'auto' }));
      }
      return;
    }

    // if access token is expired but refresh token not, then generate new one
    if (refreshTokenExpiresDate > now) {
      dispatch(getNewRefreshToken(refreshToken));
      return;
    }
    dispatch(logOut());
  };
};
