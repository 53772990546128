import React, { useCallback, useState } from 'react';
import { PieChart as RechartsPieChart, ResponsiveContainer, Tooltip, Pie, Cell, Legend, Sector } from 'recharts';
import { formatCurrencyWithoutCents, formatNumber } from '@utils/utils';
import { formatDuration } from '@utils/dates';
import { BarLikeProps, Point } from '../types';
import { NoData } from './NoData';

const COLORS = ['#009688', '#8683DF', '#509DEA', '#71C5C5', '#7DC875', '#F8D272', '#F09334', '#AA0101', '#C8C8D3'];

const formatterMap = {
  number: formatNumber,
  currency: formatCurrencyWithoutCents,
  time: formatDuration
};

interface Props extends BarLikeProps {
  innerRadius?: number | string;
}

export const PieChart = ({ numberFormat = 'number', points, innerRadius, onClick }: Props) => {
  const [activeIndex, setActiveIndex] = useState<number>(null);

  const handleSectionClick = useCallback(
    (data: any) => {
      const point = data.payload as Point;

      onClick(point);
    },
    [onClick]
  );

  const handleMouseEnter = useCallback((_: any, index: number) => {
    setActiveIndex(index);
  }, []);

  if (!points.length) {
    return <NoData innerRadius={innerRadius} />;
  }

  const formatter = formatterMap[numberFormat];

  return (
    <ResponsiveContainer width="100%" height="100%">
      <RechartsPieChart
        width={400}
        height={400}
        data={points}
        margin={{
          top: 16,
          right: 16,
          left: 16,
          bottom: 32
        }}
      >
        <Pie
          dataKey="y"
          nameKey="x"
          activeIndex={activeIndex}
          activeShape={innerRadius ? renderActiveShape(formatter) : undefined}
          data={points}
          cx="50%"
          cy="50%"
          outerRadius="70%"
          innerRadius={innerRadius}
          fill="#009688"
          onClick={handleSectionClick}
          onMouseEnter={handleMouseEnter}
        >
          {points.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color ?? COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Legend
          formatter={(value) => {
            if (typeof value === 'string') {
              if (value.length > 17) {
                return value.substring(0, 20) + '...';
              }
            }

            return value;
          }}
          wrapperStyle={{ fontSize: 12 }}
          verticalAlign="middle"
          align="right"
          layout="vertical"
        />
        {!innerRadius && <Tooltip formatter={formatter} />}
      </RechartsPieChart>
    </ResponsiveContainer>
  );
};

const renderActiveShape = (formatter: (value: number) => string) =>
  function (props: any) {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload, value } = props;

    return (
      <g>
        <text x={cx} y={cy - 10} dy={8} textAnchor="middle" fill={fill}>
          {payload.x}
        </text>

        <text x={cx} y={cy + 20} dy={8} textAnchor="middle" fill={fill}>
          {formatter(value)}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
  };
