import { Action, ActionStatus } from '@generated/types/graphql';
import { useUpdateActionItem } from '@hooks/actionItems/useUpdateActionItem';
import { Form, FormValidationRules, TextareaField, useForm } from '@kit/components/Form';
import { Button, ButtonVariant } from '@kit/ui/Button';
import React, { useMemo } from 'react';
import { ModalBody, ModalFooterActions, RequestAgainClarification } from './styled';

type FormValues = {
  description: string;
};

interface Props {
  action: Action;
  onClose: (description: string) => void;
}

export const RequestAgain = ({ action, onClose }: Props) => {
  const { mutateAsync: update } = useUpdateActionItem();

  const postForm = async (values: FormValues) => {
    await update({
      id: action.id,
      dto: {
        status: ActionStatus.Requested,
        description: values.description
      }
    });

    onClose(values.description);
  };

  const { handleSubmit, form } = useForm<FormValues>({
    onSubmit: postForm,
    defaultValues: {
      description: action.description
    }
  });

  const rules = useMemo<FormValidationRules<FormValues>>(
    () => ({
      description: {
        isRequired: true
      }
    }),
    []
  );

  const {
    formState: { isSubmitting },
    control
  } = form;

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <ModalBody>
        <RequestAgainClarification>
          You are going to request “<b>{action.title}</b>“ again. Please, update the description to make it clear for
          the Homeowner.
        </RequestAgainClarification>
        <TextareaField name="description" control={control} label="Description" />
      </ModalBody>
      <ModalFooterActions>
        <Button variant={ButtonVariant.Secondary} onClick={() => onClose(action.description)}>
          Cancel
        </Button>
        <Button disabled={isSubmitting} type="submit" variant={ButtonVariant.Primary}>
          Update & Send
        </Button>
      </ModalFooterActions>
    </Form>
  );
};
