import React from 'react';
import { EmailForm } from '@features/ClientPortfolio/Client/CreateEventsPanel/forms/EmailForm';
import { Container, EmailList } from './styled';
import { FeedItem } from '../types';

interface Props {
  parentEmail: FeedItem;
  onClose: () => void;
  children: React.ReactNode;
  replyTo: string[];
  replyToMessageId: number;
  replyFrom?: string;
}

export const EmailReplyForm = ({ onClose, parentEmail, children, replyTo, replyFrom, replyToMessageId }: Props) => {
  return (
    <Container>
      <EmailList>{children}</EmailList>
      <EmailForm
        recordId={parentEmail.payload?.project?.id}
        recordType={parentEmail.payload?.project?.type}
        onClose={onClose}
        reply={{
          toMessageId: replyToMessageId,
          to: replyTo.map((to) => ({ email: to })),
          subject: parentEmail.emailMessage.subject,
          from: replyFrom
        }}
        isExpandable={false}
      />
    </Container>
  );
};
