export enum EntityType {
  TASK = 'TASK',
  PROJECT = 'PROJECT',
  TEAM = 'TEAM',
  FORM = 'FORM',
  FOLDER = 'FOLDER',
  WORKFLOW = 'WORKFLOW',
  REMINDER = 'REMINDER',
  SMS = 'SMS',
  EMAIL = 'EMAIL',
  LINE_ITEM = 'LINE_ITEM',
  FILE = 'FILE',
  PORTAL = 'PORTAL',
  GEOLOCATION = 'GEOLOCATION',
  CALL = 'CALL',
  ACTION = 'ACTION',
  SYSTEM = 'SYSTEM'
}
