import styled from 'styled-components';

export const AddButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: -12px;
`;

export const AddButton = styled.div`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  color: #009688;
  cursor: pointer;
`;

export const FieldWithButton = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  > *:first-child {
    flex: 1;
  }
`;

export const IconButton = styled.div`
  padding: 8px;
  cursor: pointer;
  margin-top: 5px;
`;
