import { AlertStatus } from '@generated/types/graphql';
import { deleteRequest, post, put } from '@services/api/base';
import { Urls } from '@services/api/base/axios';
import { SystemMetric } from '@services/api/systemProfilesAPI';

export default {
  updateAlert: (id: number, dto: Partial<SystemAlertDTO>) => put(`${Urls.fleet}/alerts/${id}`, dto),

  createAlertConfig: (companyId: number, dto: Required<AlertConfigDTO>) =>
    post<void>(`${Urls.fleet}/alerts/config`, dto, { companyId }),

  updateAlertConfig: (id: number, dto: Partial<AlertConfigDTO>) => put<void>(`${Urls.fleet}/alerts/config/${id}`, dto),

  deleteAlertConfig: (id: number) => deleteRequest<void>(`${Urls.fleet}/alerts/config/${id}`)
};

type SystemAlertDTO = {
  status: AlertStatus;
};

// type of JSON field `condition` of SystemAlert
export type SystemAlertCondition = {
  metric: SystemMetric;
  comparator: 'less' | 'more';
  percent: number; // 1...1000
  days: number; // 1...30
};

export type AlertConfigDTO = {
  name: string;
  profiles: number[];
  metric: SystemMetric; // currently only supported are SystemMetric.energyProduction, SystemMetric.energyConsumption
  comparator: 'less' | 'more';
  percent: number; // 1...1000
  days: number; // 1...30
  showInPortal: boolean;
};

// type of JSON field `context` of SystemAlertEvent
export type SystemAlertEventContext = {
  actualValue: number;
  referenceValue: number;
  day: string; // ISO date
};
