import React, { useMemo } from 'react';
import { SelectFormik, SelectProps } from '@common/ui';
import { Column, RecordType } from '@types';
import { useCompanyProperties } from '@hooks/useCompanyProperties';
import { isFiles } from '@utils/properties';

type PropertyTypeSelectProps = Omit<SelectProps<Column>, 'options' | 'optionTitleKey' | 'optionIdKey'> & {
  recordType?: RecordType;
};

export const PropertyTypeSelect: React.FC<PropertyTypeSelectProps> = ({ recordType, ...props }) => {
  const { scopeToEditableColumns } = useCompanyProperties();

  const filteredColumns = useMemo(() => {
    return scopeToEditableColumns[recordType]?.filter((property) => !isFiles(property)) ?? [];
  }, [scopeToEditableColumns, recordType]);

  return <SelectFormik {...props} options={filteredColumns} optionTitleKey="name" optionIdKey="id" />;
};
