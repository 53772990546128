import { scroll } from '@styles';
import styled from 'styled-components';

export const Header = styled.div`
  padding: 16px 16px 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const WidgetTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const WidgetIcon = styled.div<{ backgroundColor: string }>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

export const FormContainer = styled.div`
  padding: 0 16px;
`;

export const TabsAndSearch = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 0 16px;
  margin-top: 16px;
`;

export const FeedContainer = styled.div`
  display: flex;
  overflow: auto;
  height: auto;
  overflow: hidden;
  flex-direction: column;
  height: 100%;
`;

export const SyncsContainer = styled(FeedContainer)`
  overflow: auto;
  ${scroll};
`;

export const FeedContainerInner = styled.div`
  display: flex;
  overflow: auto;
  height: auto;
  overflow: hidden;
`;

export const FeedEvents = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  ${scroll};

  & > div:first-of-type > * {
    border-top: 0;
  }
`;
