import React, { useState, useMemo, useCallback } from 'react';
import { Plus } from 'react-feather';
import { DollarIcon } from '@kit/ui/icons/Dollar';
import { useInvoiceList } from '@hooks/invoices/useInvoiceList';
import { useModal } from '@common/PromiseModal';
import { TabItem, Tabs } from '@kit/ui/Tabs';
import moment from 'moment';
import { formatCurrency } from '@utils/utils';
import { InvoiceStatus } from '@generated/types/graphql';
import { uniqBy } from 'lodash';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { InvoiceRow } from './InvoiceRow';
import { Form } from './Form';
import { Buttons, Totals, Label, AmountDue, AmountTotal, Amounts, Table, Placeholder } from './styled';
import { Widget, WidgetHeader, WidgetIcon, WidgetTitle } from '../styled';

const TABS: TabItem[] = [
  {
    id: 'invoices',
    title: 'Invoices'
  },
  {
    id: 'bills',
    title: 'Bills'
  }
];

interface Props {
  recordId: number;
}

export const Accounting = ({ recordId }: Props) => {
  const [tab, setTab] = useState<TabItem>(TABS[0]);
  const { data: invoices = [] } = useInvoiceList(recordId);
  const { openModal } = useModal();

  const flattenList = useMemo(() => {
    return uniqBy(
      invoices
        .map((invoice) => [
          invoice,
          ...invoice.project.projectsByParentProjectId.map((project) => project.invoicesByProjectId).flat()
        ])
        .flat(),
      (invoice) => invoice.id
    ).sort((a, b) => b.uid - a.uid);
  }, [invoices]);

  const invoiceList = useMemo(() => {
    return flattenList.filter((invoice) => !invoice.isBill);
  }, [flattenList]);

  const billList = useMemo(() => {
    return flattenList.filter((invoice) => invoice.isBill);
  }, [flattenList]);

  const totalInvoicesDue = useMemo(() => {
    const [totalAmount, totalDue] = invoiceList.reduce(
      (acc, invoice) => {
        acc[0] += +invoice.amount;
        acc[1] += +invoice.amount - +invoice.amountPaid;

        return acc;
      },
      [0, 0]
    );

    return {
      totalAmount,
      totalDue
    };
  }, [invoiceList]);

  const totalBillsDue = useMemo(() => {
    const [totalAmount, totalDue] = billList.reduce(
      (acc, invoice) => {
        acc[0] += +invoice.amount;
        acc[1] += +invoice.amount - +invoice.amountPaid;

        return acc;
      },
      [0, 0]
    );

    return {
      totalAmount,
      totalDue
    };
  }, [billList]);

  const isSomeInvoiceOverdue = useMemo(() => {
    return invoiceList.some(
      (invoice) => invoice.status !== InvoiceStatus.Paid && moment(invoice.dueDate).isBefore(moment(), 'day')
    );
  }, [invoiceList]);

  const isSomeBillOverdue = useMemo(() => {
    return billList.some(
      (invoice) => invoice.status !== InvoiceStatus.Paid && moment(invoice.dueDate).isBefore(moment(), 'day')
    );
  }, [billList]);

  const handleCreateInvoice = useCallback(() => {
    openModal<void>(({ onClose }) => <Form recordId={recordId} onClose={onClose} />, { title: 'Create Invoice' });
  }, [openModal, recordId]);

  const handleCreateBill = useCallback(() => {
    openModal<void>(({ onClose }) => <Form recordId={recordId} isBill onClose={onClose} />, { title: 'Create Bill' });
  }, [openModal, recordId]);

  const list = tab.id === 'invoices' ? invoiceList : billList;

  return (
    <Widget isFullWidth size="large">
      <WidgetHeader>
        <WidgetTitle>
          <WidgetIcon backgroundColor="#CCEAE7">
            <DollarIcon size="16px" color="#009688" />
          </WidgetIcon>
          Accounting
          <Buttons>
            <Button onClick={handleCreateInvoice} variant={ButtonVariant.Secondary}>
              <Plus size="16px" />
              Invoice
            </Button>
            <Button onClick={handleCreateBill} variant={ButtonVariant.Secondary}>
              <Plus size="16px" />
              Bill
            </Button>
          </Buttons>
        </WidgetTitle>
      </WidgetHeader>

      <Totals>
        <div>
          <Label>Total invoices due</Label>
          <Amounts>
            <AmountDue isRed={isSomeInvoiceOverdue}>{formatCurrency(totalInvoicesDue.totalDue)}</AmountDue>
            <AmountTotal>/ {formatCurrency(totalInvoicesDue.totalAmount)}</AmountTotal>
          </Amounts>
        </div>
        <div>
          <Label>Total bills due</Label>
          <Amounts>
            <AmountDue isRed={isSomeBillOverdue}>{formatCurrency(totalBillsDue.totalDue)}</AmountDue>
            <AmountTotal>/ {formatCurrency(totalBillsDue.totalAmount)}</AmountTotal>
          </Amounts>
        </div>
      </Totals>

      <Tabs tabs={TABS} selected={tab.id} onChange={setTab} variant="outline" />

      {list.length === 0 && <Placeholder>No {tab.id === 'invoices' ? 'invoices' : 'bills'} yet</Placeholder>}

      {list.length > 0 && (
        <Table>
          <thead>
            <th>{tab.id === 'invoices' ? 'Invoice' : 'Bill'}</th>
            <th>Amount</th>
            <th>Amount due</th>
            <th>Status</th>
            <th />
          </thead>
          <tbody>
            {list.map((invoice) => (
              <InvoiceRow key={invoice.id} invoice={invoice} recordId={recordId} />
            ))}
          </tbody>
        </Table>
      )}
    </Widget>
  );
};
