import React from 'react';

export const DollarIcon = ({ size = '16px', color = 'currentColor' }: { size?: string | number; color?: string }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.9987 0C8.36689 0 8.66536 0.298477 8.66536 0.666667V2.66667H11.332C11.7002 2.66667 11.9987 2.96514 11.9987 3.33333C11.9987 3.70152 11.7002 4 11.332 4H8.66536V7.33333H9.66536C10.461 7.33333 11.2241 7.6494 11.7867 8.21201C12.3493 8.77462 12.6654 9.53768 12.6654 10.3333C12.6654 11.129 12.3493 11.892 11.7867 12.4547C11.2241 13.0173 10.461 13.3333 9.66536 13.3333H8.66536V15.3333C8.66536 15.7015 8.36689 16 7.9987 16C7.63051 16 7.33203 15.7015 7.33203 15.3333V13.3333H3.9987C3.63051 13.3333 3.33203 13.0349 3.33203 12.6667C3.33203 12.2985 3.63051 12 3.9987 12H7.33203V8.66667H6.33203C5.53638 8.66667 4.77332 8.3506 4.21071 7.78799C3.6481 7.22538 3.33203 6.46232 3.33203 5.66667C3.33203 4.87102 3.6481 4.10796 4.21071 3.54535C4.77332 2.98274 5.53638 2.66667 6.33203 2.66667H7.33203V0.666667C7.33203 0.298477 7.63051 0 7.9987 0ZM7.33203 4H6.33203C5.89 4 5.46608 4.17559 5.15352 4.48816C4.84096 4.80072 4.66536 5.22464 4.66536 5.66667C4.66536 6.10869 4.84096 6.53262 5.15352 6.84518C5.46608 7.15774 5.89 7.33333 6.33203 7.33333H7.33203V4ZM8.66536 8.66667V12H9.66536C10.1074 12 10.5313 11.8244 10.8439 11.5118C11.1564 11.1993 11.332 10.7754 11.332 10.3333C11.332 9.89131 11.1564 9.46738 10.8439 9.15482C10.5313 8.84226 10.1074 8.66667 9.66536 8.66667H8.66536Z"
      fill={color}
    />
  </svg>
);
