import React, { useCallback } from 'react';

import { Bar, YAxis } from 'recharts';
import { AnalyticsModule } from '@features/Analytics/types';
import { formatDuration } from '@utils/dates';
import { ChartBase } from './ChartBase';

import { Container, RightAxisTitles } from './styled';
import { Point } from '../types';
import { StatusTick } from './StatusTick';
import { NoData } from './NoData';

interface Props {
  module: AnalyticsModule;
  points: { id: string; name: string; value: number; accumSum: number }[];
  statuses: { id: string; name: string }[];
  onClick: (point: Point) => void;
}

export const TimelineByStatus = ({ module, points, statuses, onClick }: Props) => {
  const handleBarClick = useCallback(
    (data: any) => {
      const point = data.payload;

      onClick({
        x: point.name,
        originalX: point.id,
        isEmpty: false,
        y: point.value
      });
    },
    [onClick]
  );

  if (!points.length) {
    return <NoData />;
  }

  return (
    <Container>
      <RightAxisTitles noWrap>
        <div>Time in Status</div>
        <div>Total time</div>
      </RightAxisTitles>
      <ChartBase points={points} xAxisTickFormatter={formatDuration}>
        <YAxis
          width={150}
          axisLine={false}
          tickLine={false}
          orientation="left"
          tick={<StatusTick module={module} statuses={statuses} />}
          dataKey="name"
          type="category"
        />

        <YAxis
          width={70}
          stroke="#1D1D35"
          tickFormatter={formatDuration}
          yAxisId="average"
          axisLine={false}
          tickLine={false}
          orientation="right"
          dataKey="value"
          type="category"
          fontSize={12}
        />
        <YAxis
          width={80}
          yAxisId="accumSum"
          axisLine={false}
          tickLine={false}
          stroke="#828D9A"
          orientation="right"
          dataKey="accumSum"
          tickFormatter={formatDuration}
          type="category"
          fontSize={12}
        />

        <Bar maxBarSize={16} dataKey="value" fill="#71C4C4" onClick={handleBarClick} />
      </ChartBase>
    </Container>
  );
};
