import { Quill } from 'react-quill';

const Link = Quill.import('formats/link');

Link.sanitize = function (url: string) {
  // quill by default creates relative links if scheme is missing.
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return `https://${url}`;
  }

  return url;
};
