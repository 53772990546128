import styled from 'styled-components';

export const Container = styled.div`
  > *:first-child {
    position: sticky;
    top: 0;
    z-index: 3;
    background-color: #1d1d35;
    margin-bottom: 8px;
  }
`;

export const EditButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
  position: absolute;
  right: 4px;
  padding-left: 4px;
  z-index: 1;
  background-color: #414156;
`;

export const ListItem = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  padding: 2px;
  gap: 4px;
  min-height: 25px;
  background-color: ${({ isSelected }) => (isSelected ? '#414156' : 'transparent')};

  svg {
    flex-shrink: 0;
  }

  &:hover {
    cursor: pointer;
    background-color: #414156;

    ${EditButton} {
      display: block;
    }
  }
`;

export const ItemText = styled.div`
  font-size: 12px;
  font-weight: 500;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Emoji = styled.span`
  min-width: 15px;
  min-height: 15px;
  display: inline-block;
`;

export const FolderItem = styled.div`
  color: #828d9a;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  background-color: #1d1d35;
  position: sticky;
  top: 32px;
  z-index: 2;

  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 0;

  &:hover {
    ${EditButton} {
      margin-left: auto;
      display: block;
      background-color: transparent;
      color: #fff;
    }
  }
`;

export const FolderName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
