import { createAction } from 'redux-actions';
import download from 'downloadjs';

import api from '@services/api/databankApi';
import { Pager } from '@services/api/types';
import { selectWorkspaceId } from '@state/selectors';
import { AsyncActionReturn } from '@state/reducers/types';

import { dataBankDownloadMultipleURL } from '@services/service.config';

const wrapActionName = (name: string) => `@databank/${name}`;

const STATUSES = ['START', 'SUCCESS', 'FAIL'];
const createAsyncActions = (name: string) =>
  STATUSES.map((status) => createAction(wrapActionName(`${name}_${status}`)));

export const updateLabels = createAction(wrapActionName('UPDATE_LABELS'));
export const clearFetchedLabels = updateLabels;

export const [fetchLabelsPaginationStart, fetchLabelsPaginationSuccess, fetchLabelsPaginationFail] =
  createAsyncActions('FETCH_LABELS_PAGINATION');

export const fetchLabelsPagination =
  (
    pager: Partial<Pager>,
    apiUrl?: typeof api.fetchLabelsAll,
    ownerId?: number,
    providedCompanyId?: number
  ): AsyncActionReturn =>
  async (dispatch, getState) => {
    dispatch(fetchLabelsPaginationStart());

    try {
      const companyId = providedCompanyId ?? selectWorkspaceId(getState());

      const { data } = await (apiUrl || api.fetchLabelsPagination)(pager, companyId);
      const newLabels =
        data && data.results
          ? data.results.map((item: { id: any; label: any }) => ({
              ...item,
              labelName: item.label
            }))
          : [];
      const total = data?.total || 0;

      dispatch(
        fetchLabelsPaginationSuccess({
          newLabels,
          total,
          pager
        })
      );
    } catch (error) {
      console.error(new Error(`Error on labels pagination fetch \n${error}`));
      dispatch(fetchLabelsPaginationFail(error));
    }
  };

export const downloadMultiple = async (
  contents: {
    stageIds?: number[];
    files?: number[];
    fileIds?: number[];
    projectId: number;
  },
  ...rest: Parameters<typeof dataBankDownloadMultipleURL>
): Promise<Blob | null> => {
  try {
    const { data } = await api.downloadMultiple(
      {
        ...{ stageIds: [], files: [] },
        ...contents
      },
      ...rest
    );

    download(data, `${Date.now()}.zip`, 'application/zip');

    return data;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('failed while downloading', err);
  }

  return null;
};

export const downloadSingleFile = async (id: number, name: string) => {
  try {
    const { data } = await api.downloadSingleFile(id);
    download(data, name);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('failed on downloading single file', e);
  }
};
