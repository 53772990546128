import React from 'react';
import { useProjectStagesRest } from '@hooks/useProjectStages';
import { RecordType, WorkspaceStageFromAPI } from '@types';
import { SelectFormik, SelectProps } from '@common/ui';
import { concat, differenceBy, find, map, sortBy } from 'lodash';
import styled from 'styled-components';
import { useBlueprints } from '@hooks';
import { ProjectStageBadge } from '@components/ProjectStages';

const Wrapper = styled.div`
  width: 150px;
`;

type ProjectStageSelectProps = {
  name: string;
  companyId: number;
  recordType: RecordType;
  blueprintId?: number;
  onChange?: (
    event: React.ChangeEvent<{ name?: string | undefined; value: number }>,
    child: React.ReactNode,
    stage?: WorkspaceStageFromAPI
  ) => unknown;
  disabled?: boolean;
  withAny?: boolean;
  hideNonblueprintStages?: boolean;
} & Omit<SelectProps<WorkspaceStageFromAPI>, 'options' | 'optionTitleKey' | 'optionIdKey'>;

const ANY_STAGE = {
  id: 0,
  name: 'Any'
} as unknown as WorkspaceStageFromAPI;

/**
 * @deprecated Prefer {@link client/src/components/ProjectStages/SelectProjectStage}
 */
const ProjectStageSelect: React.FC<ProjectStageSelectProps> = ({
  name,
  companyId,
  blueprintId,
  onChange,
  disabled,
  recordType,
  withAny,
  hideNonblueprintStages = false,
  ...rest
}) => {
  const {
    fetchAll: { data: workspaceStages }
  } = useProjectStagesRest(companyId);

  const {
    fetchAll: { data: blueprints }
  } = useBlueprints({
    filters: [
      {
        col: 'id',
        op: '=',
        val: blueprintId || -1
      }
    ]
  });

  const blueprint = blueprints?.results?.[0];
  const blueprintStages = map(sortBy(blueprint?.projectStages, 'position'), 'projectStage').filter(
    (stage) => stage.scope === recordType
  );

  if (!workspaceStages || (blueprintId && !blueprint)) {
    return null;
  }

  const renderStage = (id?: number) => {
    const stage = find([ANY_STAGE].concat(workspaceStages), { id });

    return stage && <ProjectStageBadge stage={stage} />;
  };

  const stagesNotInBlueprint = differenceBy(
    workspaceStages.filter((stage) => stage.scope === recordType),
    blueprintStages,
    'id'
  );

  const sortedStages = concat(blueprintStages, hideNonblueprintStages ? [] : stagesNotInBlueprint) ?? [];

  const grouping = (stageId: number): string | null => {
    if (blueprintStages.length) {
      if (stageId === blueprintStages[0].id) {
        return blueprint!.name;
      }

      if (stageId === stagesNotInBlueprint[0]?.id) {
        return 'Other';
      }

      return null;
    }

    return null;
  };

  const handleChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: number }>,
    child: React.ReactNode
  ) => {
    onChange?.(
      event,
      child,
      sortedStages.find((stage) => stage.id === event.target.value)
    );
  };

  return (
    <Wrapper>
      <SelectFormik<WorkspaceStageFromAPI, number>
        name={name}
        options={(withAny ? [ANY_STAGE] : []).concat(sortedStages.filter((stage) => stage.scope === recordType))}
        renderValue={(id) => renderStage(id)}
        renderOption={renderStage}
        grouping={grouping}
        onChange={handleChange}
        disabled={disabled}
        {...rest}
      />
    </Wrapper>
  );
};

export default ProjectStageSelect;
