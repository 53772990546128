import {
  UpdatePropertyMutationProps,
  useCompanyProperties,
  useCompanyPropertiesMutations
} from '@hooks/useCompanyProperties';
import { RecordDetail } from '@hooks/useRecordDetail';
import { Property, PropertyType, RecordType } from '@types';
import React, { useCallback, useEffect, useState } from 'react';
import { capitalize, get, set } from 'lodash';
import { isFiles, isStatus } from '@utils/properties';
import { useAllCompaniesUsers } from '@hooks/useCompanyUsers';
import { Form, useForm } from '@kit/components/Form';
import { PropertyValueField } from '@features/Platform/Properties/PropertyValueField';
import { Popover } from '@kit/ui/Popover';
import { Skeleton } from '@material-ui/lab';
import { useQueryClient } from 'react-query';
import { ReactQueryKey } from '@enums';
import { useModal } from '@common/PromiseModal';
import { FieldWrapper } from './EditableFields/styled';
import { useRecordWithProperties } from './FullProperties/EditProperties';
import { StatusChangeComment } from './StatusChangeComment';

const SOURCE_PROPERTY_NAME_MAP: { [key: string]: string } = {
  projectManagerId: 'projectManager',
  salesRepId: 'salesRep',
  ownerId: 'owner'
};

const getDefaultFormValues = ({ project, property, companyUsers }: any) => {
  const name = property.isAdditional ? `additional.${property.id}` : property.mappedName;

  const result = {};

  const sourceName = SOURCE_PROPERTY_NAME_MAP[name] || name;

  const existingValue = get(project.projectDetail, sourceName);

  if (property.type === PropertyType.Person && typeof existingValue === 'number') {
    const user = companyUsers.find((companyUser) => companyUser.id === existingValue);

    set(result, name, user);
  } else if (isStatus(property)) {
    set(result, name, { id: existingValue, label: capitalize(existingValue.replace('_', ' ')) });
  } else {
    set(result, name, existingValue);
  }

  return result;
};

interface InnerProps {
  project: RecordDetail;
  children: React.ReactNode;
  property: Property;
}

export const EditableFieldInner = ({ project: record, children, property }: InnerProps) => {
  const project = useRecordWithProperties(record.id);
  const [isUpdating, setIsUpdating] = useState(false);

  const queryClient = useQueryClient();

  const { openModal } = useModal();

  const name = property.isAdditional ? `additional.${property.id}` : property.mappedName;
  const { updateProjectProperty } = useCompanyPropertiesMutations();
  const { data: companyUsers } = useAllCompaniesUsers();

  const postForm = async (values: any) => {
    const recordDTO = {
      ...values,
      ownerId: values.ownerId?.id ?? null,
      salesRepId: values.salesRepId?.id ?? null,
      projectManagerId: values.projectManagerId?.id ?? null,
      status: values.status?.id ?? null
    };

    const dto = {
      projectId: project.projectId
    } as UpdatePropertyMutationProps;

    if (values.additional) {
      dto.property = property;
      dto.newValue = get(values, name);

      if (isFiles(property)) {
        dto.newValue = dto.newValue.map((file: File) => file.id);
      }
    } else {
      dto.property = property;
      dto.newValue = recordDTO[name];
    }

    setIsUpdating(true);

    await updateProjectProperty.mutateAsync(dto);

    queryClient.setQueryData([ReactQueryKey.RecordDetail, record.id], (oldData: any) => {
      if (!oldData) {
        return oldData;
      }

      const sourceName = SOURCE_PROPERTY_NAME_MAP[name] || name;

      return {
        ...oldData,
        [sourceName]: name === 'status' ? values[name].id : values[name]
      };
    });

    setTimeout(() => {
      setIsUpdating(false);
    }, 100);

    if (isStatus(property) && recordDTO.status !== record.status) {
      openModal(
        ({ onClose }) => <StatusChangeComment onClose={onClose} project={record} newStatus={recordDTO.status} />,
        { title: 'Project Status changed' }
      );
    }
  };

  const { form, handleSubmit } = useForm({
    onSubmit: postForm,
    mode: 'onBlur',
    defaultValues: getDefaultFormValues({ project, property, companyUsers })
  });

  const {
    formState: { isSubmitting }
  } = form;

  useEffect(() => {
    form.reset(getDefaultFormValues({ project, property, companyUsers }));
  }, [project, form, property, companyUsers]);

  const handleChanged = useCallback(() => {
    if (!isUpdating || isSubmitting) {
      handleSubmit();
    }
  }, [handleSubmit, isUpdating, isSubmitting]);

  return (
    <Popover
      onClose={handleChanged}
      content={
        <FieldWrapper width="400px">
          <Form onSubmit={handleSubmit}>
            <PropertyValueField
              name={property.isAdditional ? `additional.${property.id}` : property.mappedName}
              property={property}
              control={form.control}
              isAutoFocus
            />
            <button disabled={isSubmitting} type="submit" style={{ display: 'none' }} />
          </Form>
        </FieldWrapper>
      }
    >
      {isUpdating && <Skeleton animation="wave" width={100} height={24} />}
      {!isUpdating && children}
    </Popover>
  );
};

interface Props {
  project: RecordDetail;
  children: React.ReactNode;
  propertyName: string;
}

export const EditableField = ({ project, children, propertyName }: Props) => {
  const { properties } = useCompanyProperties({ recordType: RecordType.PROJECT });
  const property = properties.find((prop) => prop.mappedName === propertyName);

  if (!property) {
    return null;
  }

  return (
    <EditableFieldInner project={project} property={property}>
      {children}
    </EditableFieldInner>
  );
};
