import { XIcon } from '@kit/ui/icons/X';
import React, { useCallback } from 'react';
import { colors } from '@styles';
import { Inbox, Mail } from 'react-feather';
import { useMarkReadNotification } from '@hooks/notifications/useMarkReadNotification';
import { BulkActionButton, SelectedLabel, SelectionControlPanel } from './styled';

interface Props {
  selectedIds: number[];
  clearSelection: () => void;
}

export const SelectionControl = ({ selectedIds, clearSelection }: Props) => {
  const { mutateAsync: markRead } = useMarkReadNotification();

  const handleMarkRead = useCallback(async () => {
    await markRead({ inboxIds: selectedIds, read: true });

    clearSelection();
  }, [selectedIds, markRead, clearSelection]);

  const handleMarkUnread = useCallback(async () => {
    await markRead({ inboxIds: selectedIds, read: false });

    clearSelection();
  }, [selectedIds, markRead, clearSelection]);

  return (
    <SelectionControlPanel>
      <XIcon onClick={clearSelection} size="24px" color="#9C9CAA" />
      <SelectedLabel>{selectedIds.length} selected:</SelectedLabel>

      <BulkActionButton onClick={handleMarkRead}>
        <Inbox size="16px" color={colors.green} />
        Mark as read
      </BulkActionButton>

      <BulkActionButton onClick={handleMarkUnread}>
        <Mail size="16px" color={colors.green} />
        Mark as unread
      </BulkActionButton>
    </SelectionControlPanel>
  );
};
