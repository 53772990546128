import { defaultTheme } from '@themes';
import { getColorWithOpacity } from '@styles';

interface AutocompleteProps {
  hideInput?: boolean | undefined;
}

export const autocomplete = (props: AutocompleteProps) => {
  const { hideInput = false } = props;

  return {
    // .MuiAutocomplete-root Styles applied to the root element.
    root: {},

    // .MuiAutocomplete-fullWidth Styles applied to the root element if fullWidth={true}.
    fullWidth: {},

    // .Mui-focused Pseudo-class applied to the root element if focused.
    focused: {},

    // .MuiAutocomplete-tag Styles applied to the tag elements, e.g. the chips.
    tag: {
      // default Chip component
      '& .MuiChip-label': {
        padding: '0 4px',
        display: 'block',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
      },
      '& .MuiChip-icon': {
        minWidth: '20px',
        width: '20px',
        height: '20px',
        margin: '0 0 0 2px',
        color: defaultTheme.colors.green
      },
      '& .MuiChip-deleteIcon': {
        width: '16px',
        height: '16px',
        color: getColorWithOpacity(defaultTheme.colors.green, 20),
        margin: '0 4px 0 0',
        transition: '.3s ease-in-out',
        '&:hover, &:focus': {
          color: defaultTheme.colors.green
        }
      },
      '&.MuiChip-root': {
        margin: '4px 2px',
        height: '24px',
        borderRadius: '4px',
        padding: 0,
        backgroundColor: getColorWithOpacity(defaultTheme.colors.green, 20),
        color: defaultTheme.colors.green,
        font: defaultTheme.typefaces.text
      },
      '&.MuiChip-deletable': {
        '&:focus': {
          backgroundColor: getColorWithOpacity(defaultTheme.colors.green, 20),
          outlineWidth: '2px',
          outlineColor: defaultTheme.colors.gray,
          outlineStyle: 'dotted',
          '& .MuiChip-deleteIcon': {
            color: defaultTheme.colors.green
          }
        }
      }
      // end of default Chip component
    },

    // .MuiAutocomplete-tagSizeSmall Styles applied to the tag elements, e.g. the chips if size="small".
    tagSizeSmall: {},

    // .MuiAutocomplete-hasPopupIcon Styles applied when the popup icon is rendered.
    hasPopupIcon: {},

    // .MuiAutocomplete-hasClearIcon Styles applied when the clear icon is rendered.
    hasClearIcon: {},

    // .MuiAutocomplete-inputRoot Styles applied to the Input element.
    inputRoot: {
      '&[class*="MuiOutlinedInput-root"]': {
        // medium size
        padding: '4px',
        '& .MuiAutocomplete-input': {
          height: '32px',
          padding: '0 6px'
        },
        // small size
        '&[class*="MuiOutlinedInput-marginDense"]': {
          padding: '2px',
          '& .MuiAutocomplete-input': {
            height: '24px',
            padding: '0 4px'
          }
        }
      }
    },

    // .MuiAutocomplete-input Styles applied to the input element.
    input: {
      height: hideInput ? '0 !important' : 'auto'
    },

    // .MuiAutocomplete-inputFocused Styles applied to the input element if tag focused.
    inputFocused: {},

    // .MuiAutocomplete-endAdornment Styles applied to the endAdornment element.
    endAdornment: {
      right: '8px'
    },

    // .MuiAutocomplete-clearIndicator Styles applied to the clear indicator.
    clearIndicator: {
      color: defaultTheme.colors.gray2,
      padding: '4px',
      '& svg': {
        width: '20px',
        height: '20px'
      }
    },

    // .MuiAutocomplete-clearIndicatorDirty Styles applied to the clear indicator if the input is dirty.
    clearIndicatorDirty: {},

    // .MuiAutocomplete-popupIndicator Styles applied to the popup indicator.
    popupIndicator: {
      color: defaultTheme.colors.gray2,
      padding: '4px',
      marginRight: '0',
      '& svg': {
        width: '20px',
        height: '20px'
      }
    },

    // .MuiAutocomplete-popupIndicatorOpen Styles applied to the popup indicator if the popup is open.
    popupIndicatorOpen: {},

    // .MuiAutocomplete-popper Styles applied to the popper element.
    popper: {},

    // .MuiAutocomplete-popperDisablePortal Styles applied to the popper element if disablePortal={true}.
    popperDisablePortal: {},

    // .MuiAutocomplete-paper Styles applied to the Paper component.
    paper: {},

    // .MuiAutocomplete-listbox Styles applied to the listbox component.
    listbox: {
      '&::-webkit-scrollbar': {
        width: '4px',
        height: '6px',
        borderRadius: '4px'
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: defaultTheme.colors.white2
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: defaultTheme.colors.gray,
        outline: 'none',
        borderRadius: '4px'
      }
    },

    // .MuiAutocomplete-loading Styles applied to the loading wrapper.
    loading: {},

    // .MuiAutocomplete-noOptions Styles applied to the no option wrapper.
    noOptions: {
      color: '#98A9BC',
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '1.25',
      fontFamily: defaultTheme.fonts.primary
    },

    // .MuiAutocomplete-option Styles applied to the option elements.
    option: {
      fontSize: '13px',
      lineHeight: '1.25',
      fontFamily: defaultTheme.fonts.primary,
      color: defaultTheme.colors.black,
      margin: '2px 6px',
      padding: '0 8px',
      borderRadius: '4px',
      minHeight: '32px',
      '&[data-focus="true"]': {
        backgroundColor: defaultTheme.colors.white3
      },
      '&[aria-selected="true"]': {
        backgroundColor: defaultTheme.colors.white3
      },
      '&[aria-disabled="true"]': {
        opacity: 1,
        color: '#C8C8D3'
      },
      '& > span': {
        display: 'block',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      },
      '& > svg, & > div': {
        marginRight: '4px'
      }
    },

    // .MuiAutocomplete-groupLabel Styles applied to the group's label elements.
    groupLabel: {
      color: '#98A9BC',
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '1.25',
      fontFamily: defaultTheme.fonts.primary,
      marginBottom: '8px'
    },

    // .MuiAutocomplete-groupUl Styles applied to the group's ul elements.
    groupUl: {
      '& $option': {
        paddingLeft: '10px'
      }
    }
  };
};
