import { useWorkOrderStatuses } from '@hooks/workOrders/useWorkOrderStatuses';
import { useProjectStages } from '@hooks/useProjectStages';
import { RecordType } from '@types';
import { useMemo } from 'react';
import { AnalyticsModule } from '../types';
import { CLIENT_STATUS_OPTIONS, PROJECT_STATUS_OPTIONS, REQUEST_STATUS_OPTIONS } from '../constants';

export const useSubmeasureOptions = (module: AnalyticsModule) => {
  const { data: workOrderStatuses = [], isLoading: isWoStatusesLoading } = useWorkOrderStatuses();
  const { data: projectStages = [], isLoading: isProjectStagesLoading } = useProjectStages(RecordType.PROJECT);
  const { data: requestStages = [], isLoading: isRequestStageLoading } = useProjectStages(RecordType.DEAL);

  return useMemo(() => {
    if (isWoStatusesLoading || isProjectStagesLoading || isRequestStageLoading) {
      return [];
    }

    switch (module) {
      case AnalyticsModule.WORK_ORDERS:
        return workOrderStatuses.map((status) => ({
          ...status,
          id: `status_${status.id}`
        }));
      case AnalyticsModule.CLIENTS:
        return CLIENT_STATUS_OPTIONS.map((status) => ({
          ...status,
          id: `status_${status.id}`
        }));
      case AnalyticsModule.REQUESTS:
        return [
          ...REQUEST_STATUS_OPTIONS.map((status) => ({
            ...status,
            id: `status_${status.id}`,
            group: 'Statuses'
          })),
          ...requestStages.map((stage) => ({
            id: `stage_${stage.id}`,
            name: stage.name,
            group: 'Stages'
          }))
        ];
      case AnalyticsModule.PROJECTS:
        return [
          ...PROJECT_STATUS_OPTIONS.map((status) => ({
            ...status,
            id: `status_${status.id}`,
            group: 'Statuses'
          })),
          ...projectStages.map((stage) => ({
            id: `stage_${stage.id}`,
            name: stage.name,
            group: 'Stages'
          }))
        ];
      default:
        return [];
    }
  }, [
    module,
    workOrderStatuses,
    projectStages,
    requestStages,
    isWoStatusesLoading,
    isProjectStagesLoading,
    isRequestStageLoading
  ]);
};

interface SubmeasureOption {
  id: string;
  name: string;
  group?: string;
}

export const getSubmeasureOptionGroupBy = (option: SubmeasureOption) => {
  return option.group;
};
