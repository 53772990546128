import { handleActions } from 'redux-actions';
import { NotificationSettings } from '../../../components/templates/Settings/Notification/type';
import {
  fetchNotificationSettingsError,
  fetchNotificationSettingsRequest,
  fetchNotificationSettingsSuccess,
  updateNotificationSettingsError,
  updateNotificationSettingsRequest,
  updateNotificationSettingsSuccess
} from '../../actions/settingsAction/notificationSettingsAction';

export interface NotificationSettingsReducer {
  settings: NotificationSettings;
  isLoading: boolean;
  isSaving: boolean;
}

const notificationSettingsDefaultReducer = {
  isLoading: false,
  settings: {} as NotificationSettings,
  isSaving: false
};

type FetchNotificationSettingsResponse = {
  payload: { notificationSettings: NotificationSettings };
};
const fetchNotificationSettingsSuccessReducer = (
  state: NotificationSettingsReducer,
  { payload }: FetchNotificationSettingsResponse
): NotificationSettingsReducer => {
  const { notificationSettings: settings } = payload;

  return {
    ...state,
    isLoading: false,
    settings
  };
};

const fetchNotificationSettingsRequestReducer = (
  state: NotificationSettingsReducer
): NotificationSettingsReducer => ({
  ...state,
  isLoading: true
});

const fetchNotificationSettingsErrorReducer = (
  state: NotificationSettingsReducer
): NotificationSettingsReducer => ({
  ...state,
  isLoading: false
});

const updateNotificationSettingsSuccessReducer = (
  state: NotificationSettingsReducer,
  { payload }: any
): NotificationSettingsReducer => {
  const { notificationSettings: settings } = payload;

  return {
    ...state,
    isSaving: false,
    settings
  };
};

const updateNotificationSettingsRequestReducer = (
  state: NotificationSettingsReducer
): NotificationSettingsReducer => ({
  ...state,
  isSaving: true
});

const updateNotificationSettingsErrorReducer = (
  state: NotificationSettingsReducer
): NotificationSettingsReducer => ({
  ...state,
  isSaving: false
});

const notificationSettingsReducer = handleActions(
  {
    [fetchNotificationSettingsSuccess]: fetchNotificationSettingsSuccessReducer,
    [fetchNotificationSettingsRequest]: fetchNotificationSettingsRequestReducer,
    [fetchNotificationSettingsError]: fetchNotificationSettingsErrorReducer,
    [updateNotificationSettingsSuccess]: updateNotificationSettingsSuccessReducer,
    [updateNotificationSettingsRequest]: updateNotificationSettingsRequestReducer,
    [updateNotificationSettingsError]: updateNotificationSettingsErrorReducer
  },
  notificationSettingsDefaultReducer
);

export default notificationSettingsReducer;
