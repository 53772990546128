import { colors } from '../colors';
import scroll from '../Utils/scroll';

export const wrapper = () => `
  display: flex;
  align-items: center;
`;

export const thumb = (props) => `
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: ${props.color};
  color: ${colors.white};
  margin-right: 12px;
  font-size: 12px;
`;

export const title = () => `
  text-overflow: ellipsis;
  max-width: calc(100% - 30px);
  white-space: nowrap;
  overflow: hidden;
  text-transform: none;
`;

export const menu = (props) => `
  width: 200px;
  ul {
    display: flex;
    flex-direction: column;
    max-height: 240px;
    overflow-y: auto;
    ${scroll};
    
    &:before {
      display: ${props.isWorkspaces ? 'block' : 'none'};
      content: 'My workspaces';
      order: 0;
      font-weight: 500;
      font-size: 12px;
      line-height: 1.25;
      color: ${colors.gray};
      padding: 0 16px;
      margin: 8px 0;
    }
    
    &:after {
      display: ${props.isWorkspaces ? 'block' : 'none'};
      content: 'Shared with me';
      order: 2;
      font-weight: 500;
      font-size: 12px;
      line-height: 1.25;
      color: ${colors.gray};
      padding: 0 16px;
      margin: 8px 0;
    }
  }
`;

export const menuItem = (props) => `
  min-width: auto;
  width: calc(100% - 20px);
  margin-left: 10px;
  order: ${props.workspace};
  
  > div {
    text-overflow: ellipsis;
    max-width: calc(100% - 30px);
    white-space: nowrap;
    overflow: hidden;
  }
  
  &.Mui-selected {
    background-color: ${colors.white2};
    
    &:hover, &:focus {
      background-color: ${colors.white3};
    }
  }
`;

export default {
  wrapper,
  thumb,
  title,
  menu,
  menuItem
};
