import { useMemo } from 'react';

import { useMembers } from '@hooks/useMembers';
import { MemberFromProjectListApi } from '@types';
import { useCompanyUsers } from '@hooks/useCompanyUsers';

const normalizeUsers = (collection: MemberFromProjectListApi['member'][], excludeIds: number[], groupName: string) =>
  collection
    .filter(({ id }) => !excludeIds.includes(id))
    .map(({ id, lastName, firstName, avatarUrl }, index) =>
      index === 0
        ? {
            id,
            avatarUrl,
            value: `${firstName} ${lastName}`,
            groupName
          }
        : {
            id,
            avatarUrl,
            value: `${firstName} ${lastName}`
          }
    );

export const useMentions = (projectId?: number) => {
  const { list: members } = useMembers(projectId);
  const {
    data: { data: { results: users } } = {
      data: {
        results: []
      }
    }
  } = useCompanyUsers();

  const getMentions = useMemo(
    () => () => {
      const normalizedMembers = normalizeUsers(
        members.map(({ member }) => member),
        [],
        'Project members'
      );

      const normalizedUsers = normalizeUsers(
        users as MemberFromProjectListApi['member'][],
        normalizedMembers.map(({ id }) => id),
        'Workspace members'
      );

      return [...normalizedMembers, ...normalizedUsers];
    },
    [members, users]
  );

  return {
    getMentions
  };
};
