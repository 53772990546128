import styled from 'styled-components';
import { colors } from '@styles';

export const Checkmark = styled.span`
  height: 20px;
  width: 20px;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #c8c8d3;
  display: block;
  position: relative;
  flex-shrink: 0;
`;

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

export const Container = styled.label<{ withLabel: boolean; isDisabled?: boolean; intermediate?: boolean }>`
  display: inline-flex;
  position: relative;
  gap: 8px;
  cursor: pointer;
  user-select: none;
  min-width: 20px;
  min-height: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  opacity: ${(props) => (props.isDisabled ? 0.4 : 1)};

  /* When the checkbox is checked, add a blue background */
  & ${Input}:checked ~ ${Checkmark} {
    background-color: ${colors.green};
    border-color: ${colors.green};
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  & ${Checkmark}:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  & ${Input}:checked ~ ${Checkmark}:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  & ${Checkmark}:after {
    ${(props) =>
      props.intermediate
        ? `
        top: 8px;
    height: 2px;
    width: 12px;
    left: 3px;
    border-radius: 4px;
    background-color: white;
    `
        : `
        left: 6px;
    top: 3px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    `}
  }
`;
