import React from 'react';
import { Dialog, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { X } from 'react-feather';
import { ModalContainer, ModalHeader, ModalBodyContainer, ModalTitle, IconButton } from './styled';

interface ModalProps {
  isOpen?: boolean;
  onClose: () => void;
  title?: string;
  children?: React.ReactNode;
  isHeaderShown?: boolean;
  isFullScreen?: boolean;
}

const useStyles = makeStyles({
  root: {
    overflow: 'visible'
  }
});

const Modal = ({
  isOpen,
  onClose,
  isHeaderShown = true,
  isFullScreen = false,
  children,
  title,
  ...props
}: ModalProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const classes = useStyles();

  return (
    <Dialog
      PaperProps={{ classes }}
      maxWidth={false}
      open={isOpen}
      fullScreen={isFullScreen || fullScreen}
      onClose={onClose}
      {...props}
    >
      <ModalContainer>
        {isHeaderShown && (
          <ModalHeader>
            <ModalTitle>{title}</ModalTitle>

            <IconButton onClick={onClose}>
              <X />
            </IconButton>
          </ModalHeader>
        )}
        <ModalBodyContainer>{children}</ModalBodyContainer>
      </ModalContainer>
    </Dialog>
  );
};

export { Modal };
export type { ModalProps };
