import { useAuth, useEffectOnceBy, useMediaSm } from '@hooks';
import { getFullName } from '@utils';
import { config } from '../config';

/**
 * https://developers.intercom.com/installing-intercom/web/installation/
 */
export const useIntercom = () => {
  const { user } = useAuth();

  const isWide = useMediaSm();

  useEffectOnceBy(
    () => {
      window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'mwfkwokl',
        name: getFullName(user),
        email: user.email,
        user_id: `${config.activeEnv}_${user.id}`,
        avatar: user.avatarUrl
          ? {
              type: 'avatar',
              image_url: user.avatarUrl
            }
          : undefined,
        companies: user.companies.map((company) => ({
          id: `${config.activeEnv}_${company.id}`,
          name: company.name,
          created_at: new Date(company.createdAt).getTime() / 1000
        }))
      });
    },
    () => isWide && user?.id,
    [isWide, user]
  );
};
