import React from 'react';
import { NumberIcon } from '@kit/ui/icons/Number';
import { BarChartIcon } from '@kit/ui/icons/BarChart';
import { LineChartIcon } from '@kit/ui/icons/LineChart';
import { FunnelChartIcon } from '@kit/ui/icons/FunnelChartIcon';
import { TimelineChartIcon } from '@kit/ui/icons/TimelineChartIcon';
import { PipelineChartIcon } from '@kit/ui/icons/PipelineChart';
import { PieChartIcon } from '@kit/ui/icons/PieChart';
import { DoughnutChartIcon } from '@kit/ui/icons/DoughnutChart';
import { LeaderboardIcon } from '@kit/ui/icons/Leaderboard';
import { RecordType, AccountStatus } from '@types';
import { ProjectRequestStatus, ProjectStatus } from '@generated/types/graphql';
import { colors } from '@styles';
import {
  AnalyticsModule,
  AnalyticsWidgetType,
  AnalyticsWidgetAggregateFunction,
  AnalyticsSubmeasureType,
  DimensionType,
  PositiveTrendType,
  TimeGranularity,
  UserSubdimentionType,
  PredefinedMeasureId
} from './types';

export const ANALYTICS_MODULE_RECORD_TYPE_MAP: Record<AnalyticsModule, RecordType> = {
  [AnalyticsModule.CLIENTS]: RecordType.ACCOUNT,
  [AnalyticsModule.REQUESTS]: RecordType.DEAL,
  [AnalyticsModule.PROJECTS]: RecordType.PROJECT,
  [AnalyticsModule.WORK_ORDERS]: null
};

export const USER_DIMENSION_TYPES = [
  DimensionType.ASSIGNEE,
  DimensionType.CREATOR,
  DimensionType.OWNER,
  DimensionType.PROJECT_MANAGER,
  DimensionType.SALES_REP
];

export const VISITS_PER_WORK_ORDER_PROPERTY_ID = 'visitsPerWorkOrder';
export const TIME_TO_COMPLETE_PROPERTY_ID = 'timeToComplete';
export const WORKFLOW_PROJECT_STATUS_LIFECYCLE_ID = 'project_status_workflow';
export const WORKFLOW_CLIENT_STATUS_LIFECYCLE_ID = 'client_status_workflow';
export const WORKFLOW_REQUEST_STATUS_LIFECYCLE_ID = 'request_status_workflow';
export const WORKFLOW_WORK_ORDER_STATUS_LIFECYCLE_ID = 'work_order_status_workflow';

export const STATUS_LIFECYCLE_WORKFLOWS = [
  WORKFLOW_PROJECT_STATUS_LIFECYCLE_ID,
  WORKFLOW_CLIENT_STATUS_LIFECYCLE_ID,
  WORKFLOW_REQUEST_STATUS_LIFECYCLE_ID,
  WORKFLOW_WORK_ORDER_STATUS_LIFECYCLE_ID
];

export const RECORD_TYPE_TITLE_MAP: Record<RecordType, string> = {
  [RecordType.ACCOUNT]: 'Client',
  [RecordType.PROJECT]: 'Project',
  [RecordType.DEAL]: 'Request'
};

export const MODULE_OPTIONS = [
  { label: 'Clients', value: AnalyticsModule.CLIENTS },
  { label: 'Requests', value: AnalyticsModule.REQUESTS },
  { label: 'Projects', value: AnalyticsModule.PROJECTS },
  { label: 'Work Orders', value: AnalyticsModule.WORK_ORDERS }
];

export const WIDGET_TYPE_OPTIONS = [
  { label: 'KPI', value: AnalyticsWidgetType.KPI, icon: <NumberIcon size="24px" /> },
  { label: 'Bar', value: AnalyticsWidgetType.BAR, icon: <BarChartIcon size="24px" /> },
  { label: 'Line', value: AnalyticsWidgetType.LINE, icon: <LineChartIcon size="24px" /> },
  { label: 'Funnel', value: AnalyticsWidgetType.FUNNEL, icon: <FunnelChartIcon size="24px" /> },
  { label: 'Timeline', value: AnalyticsWidgetType.TIMELINE, icon: <TimelineChartIcon size="24px" /> },
  { label: 'Pipeline', value: AnalyticsWidgetType.PIPELINE, icon: <PipelineChartIcon size="24px" /> },
  { label: 'Pie', value: AnalyticsWidgetType.PIE, icon: <PieChartIcon size="24px" /> },
  { label: 'Doughnut', value: AnalyticsWidgetType.DOUGHNUT, icon: <DoughnutChartIcon size="24px" /> },
  { label: 'Leaderboard', value: AnalyticsWidgetType.LEADERBOARD, icon: <LeaderboardIcon size="24px" /> }
];

export const AGGREGATE_FUNCTION_COUNT_OPTION = { id: AnalyticsWidgetAggregateFunction.COUNT, name: 'Count' };

export const AGGREGATE_FUNCTION_OPTIONS = [
  { id: AnalyticsWidgetAggregateFunction.AVG, name: 'Avg' },
  { id: AnalyticsWidgetAggregateFunction.SUM, name: 'Sum' },
  { id: AnalyticsWidgetAggregateFunction.MIN, name: 'Min' },
  { id: AnalyticsWidgetAggregateFunction.MAX, name: 'Max' }
];

export const SUBMEASURE_TYPE_OPTIONS = [
  { id: AnalyticsSubmeasureType.ENTERED, name: 'Entered' },
  { id: AnalyticsSubmeasureType.EXITED, name: 'Exited' }
];

export const CLIENT_STATUS_OPTIONS = [
  { id: AccountStatus.LEAD, name: 'Lead' },
  { id: AccountStatus.PROSPECT, name: 'Prospect' },
  { id: AccountStatus.CUSTOMER, name: 'Customer' }
];

export const REQUEST_STATUS_OPTIONS = [
  { id: ProjectRequestStatus.New, name: 'New' },
  { id: ProjectRequestStatus.AppointmentScheduled, name: 'Appointment scheduled' },
  { id: ProjectRequestStatus.AppointmentCompleted, name: 'Appointment completed' },
  { id: ProjectRequestStatus.Overdue, name: 'Appointment overdue' }
];

export const PROJECT_STATUS_OPTIONS = [
  { id: ProjectStatus.Active, name: 'Active' },
  { id: ProjectStatus.OnHold, name: 'On hold' },
  { id: ProjectStatus.Completed, name: 'Completed' },
  { id: ProjectStatus.Cancelled, name: 'Cancelled' }
];

export const PROJECT_STATUS_RADIO_OPTIONS = PROJECT_STATUS_OPTIONS.map(({ id, name }) => ({
  value: id,
  label: name
}));

export const DIMENSION_TYPE_LABELS: Record<DimensionType, string> = {
  [DimensionType.CREATOR]: 'Creator',
  [DimensionType.OWNER]: 'Owner',
  [DimensionType.PROJECT_MANAGER]: 'Project Manager',
  [DimensionType.SALES_REP]: 'Sales Rep',
  [DimensionType.DROPDOWN_PROPERTY]: 'Dropdown Property',
  [DimensionType.PRODUCT_CATEGORY]: 'Product/Service Category',
  [DimensionType.TIME]: 'Time',
  [DimensionType.PRIORITY]: 'Priority',
  [DimensionType.TEMPLATE]: 'Template',
  [DimensionType.ASSIGNEE]: 'Assignee',
  [DimensionType.LABEL]: 'Label',
  [DimensionType.STATUS]: 'Status'
};

const RECORD_DIMENSION_TYPES = [
  DimensionType.CREATOR,
  DimensionType.OWNER,
  DimensionType.PROJECT_MANAGER,
  DimensionType.SALES_REP,
  DimensionType.DROPDOWN_PROPERTY,
  DimensionType.PRODUCT_CATEGORY
];

const RECORD_DIMENSION_TYPES_WITH_TIME = [DimensionType.TIME, ...RECORD_DIMENSION_TYPES];

const CLIENT_DIMENSION_TYPES = [DimensionType.CREATOR, DimensionType.OWNER, DimensionType.DROPDOWN_PROPERTY];
const CLIENT_DIMENSION_TYPES_WITH_TIME = [DimensionType.TIME, ...CLIENT_DIMENSION_TYPES];

const WORK_ORDER_DIMENSION_TYPES = [
  DimensionType.PRIORITY,
  DimensionType.TEMPLATE,
  DimensionType.ASSIGNEE,
  DimensionType.LABEL,
  DimensionType.STATUS
];

const WORK_ORDER_DIMENSION_TYPES_WITH_TIME = [DimensionType.TIME, ...WORK_ORDER_DIMENSION_TYPES];

export const DIMENSION_TYPE_OPTIONS_BY_MODULE_WIDGET: Record<
  AnalyticsModule,
  Record<AnalyticsWidgetType, DimensionType[]>
> = {
  [AnalyticsModule.CLIENTS]: {
    [AnalyticsWidgetType.KPI]: [],
    [AnalyticsWidgetType.BAR]: CLIENT_DIMENSION_TYPES_WITH_TIME,
    [AnalyticsWidgetType.LINE]: [DimensionType.TIME],
    [AnalyticsWidgetType.FUNNEL]: [],
    [AnalyticsWidgetType.TIMELINE]: [],
    [AnalyticsWidgetType.PIPELINE]: [],
    [AnalyticsWidgetType.PIE]: CLIENT_DIMENSION_TYPES,
    [AnalyticsWidgetType.DOUGHNUT]: CLIENT_DIMENSION_TYPES,
    [AnalyticsWidgetType.LEADERBOARD]: CLIENT_DIMENSION_TYPES
  },
  [AnalyticsModule.REQUESTS]: {
    [AnalyticsWidgetType.KPI]: [],
    [AnalyticsWidgetType.BAR]: RECORD_DIMENSION_TYPES_WITH_TIME,
    [AnalyticsWidgetType.LINE]: [DimensionType.TIME],
    [AnalyticsWidgetType.FUNNEL]: [],
    [AnalyticsWidgetType.TIMELINE]: [],
    [AnalyticsWidgetType.PIPELINE]: [],
    [AnalyticsWidgetType.PIE]: RECORD_DIMENSION_TYPES,
    [AnalyticsWidgetType.DOUGHNUT]: RECORD_DIMENSION_TYPES,
    [AnalyticsWidgetType.LEADERBOARD]: RECORD_DIMENSION_TYPES
  },
  [AnalyticsModule.PROJECTS]: {
    [AnalyticsWidgetType.KPI]: [],
    [AnalyticsWidgetType.BAR]: RECORD_DIMENSION_TYPES_WITH_TIME,
    [AnalyticsWidgetType.LINE]: [DimensionType.TIME],
    [AnalyticsWidgetType.FUNNEL]: [],
    [AnalyticsWidgetType.TIMELINE]: [],
    [AnalyticsWidgetType.PIPELINE]: [],
    [AnalyticsWidgetType.PIE]: RECORD_DIMENSION_TYPES,
    [AnalyticsWidgetType.DOUGHNUT]: RECORD_DIMENSION_TYPES,
    [AnalyticsWidgetType.LEADERBOARD]: RECORD_DIMENSION_TYPES
  },
  [AnalyticsModule.WORK_ORDERS]: {
    [AnalyticsWidgetType.KPI]: [],
    [AnalyticsWidgetType.BAR]: WORK_ORDER_DIMENSION_TYPES_WITH_TIME,
    [AnalyticsWidgetType.LINE]: [DimensionType.TIME],
    [AnalyticsWidgetType.FUNNEL]: [],
    [AnalyticsWidgetType.TIMELINE]: [],
    [AnalyticsWidgetType.PIPELINE]: [],
    [AnalyticsWidgetType.PIE]: WORK_ORDER_DIMENSION_TYPES,
    [AnalyticsWidgetType.DOUGHNUT]: WORK_ORDER_DIMENSION_TYPES,
    [AnalyticsWidgetType.LEADERBOARD]: WORK_ORDER_DIMENSION_TYPES
  }
};

export const DIMENSION_TYPES_WITH_SUBDIMENSION = [
  DimensionType.DROPDOWN_PROPERTY,
  DimensionType.PRODUCT_CATEGORY,
  DimensionType.TIME,
  DimensionType.ASSIGNEE,
  DimensionType.CREATOR,
  DimensionType.OWNER,
  DimensionType.PROJECT_MANAGER,
  DimensionType.SALES_REP
];

export const USER_SUBDIMENSION_OPTIONS = [
  { id: UserSubdimentionType.USER, name: 'User' },
  { id: UserSubdimentionType.TEAM, name: 'Team' }
];

export const TIME_SUBDIMENSION_OPTIONS = [
  { id: TimeGranularity.DAY, name: 'Day' },
  { id: TimeGranularity.WEEK, name: 'Week' },
  { id: TimeGranularity.MONTH, name: 'Month' },
  { id: TimeGranularity.QUARTER, name: 'Quarter' },
  { id: TimeGranularity.YEAR, name: 'Year' }
];

export const POSITIVE_TREND_TYPE_OPTIONS = [
  { value: PositiveTrendType.UP, label: 'Trending UP is positive' },
  { value: PositiveTrendType.DOWN, label: 'Trending DOWN is positive' }
];

export const WIDGET_TYPES_WITH_TREND = [AnalyticsWidgetType.KPI, AnalyticsWidgetType.LEADERBOARD];

export const WIDGET_TYPES_WITH_WORKFLOW = [
  AnalyticsWidgetType.TIMELINE,
  AnalyticsWidgetType.PIPELINE,
  AnalyticsWidgetType.FUNNEL
];

export const WORKFLOW_FIELD_LABEL_BY_WIDGET_TYPE = {
  [AnalyticsWidgetType.TIMELINE]: 'Timeline by',
  [AnalyticsWidgetType.PIPELINE]: 'Pipeline by',
  [AnalyticsWidgetType.FUNNEL]: 'Funnel by'
};

export const WORK_ORDER_PRIORITY_CONFIG: Record<number, { id: number; color: string; name: string }> = {
  1: {
    id: 1,
    color: colors.red,
    name: 'High'
  },
  2: {
    id: 2,
    color: colors.yellow,
    name: 'Medium'
  },
  3: {
    id: 3,
    color: colors.green,
    name: 'Low'
  }
};

export const MEASURES_WITHOUT_SUBMEASURE = [
  PredefinedMeasureId.VISITS,
  PredefinedMeasureId.VISITS_PER_WORK_ORDER,
  PredefinedMeasureId.TIME_TO_COMPLETE
] as string[];
