import React, { useEffect, useState } from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { radioMui } from '@styles';

import { StyledRadio } from './StyledRadio';
import Props from './types';

const useStyles = makeStyles(radioMui({}));

const RadioButton: React.FC<Props> = (props: Props) => {
  const { options, defaultValue, onChange, disabled, isRow, optionLabelRenderer } = props;
  const [value, setValue] = useState(defaultValue);
  const classes = useStyles();

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = (event.target as HTMLInputElement).value;
    setValue(val);
    onChange?.(val);
  };

  const optionsRender = options.map((o) => (
    <FormControlLabel
      key={o.value}
      value={o.value}
      control={<StyledRadio disabled={disabled} />}
      label={
        optionLabelRenderer ? optionLabelRenderer(o) : <Typography className={classes.label}>{o.label}</Typography>
      }
      disabled={disabled}
    />
  ));

  return (
    <RadioGroup key={value} value={value} onChange={handleChange} row={isRow}>
      {optionsRender}
    </RadioGroup>
  );
};

export default RadioButton;
