import { defaultTheme } from '@themes';

export const formControlLabel = () => {
  return {
    // .MuiFormControlLabel-root Styles applied to the root element.
    root: {
      minHeight: '26px',
      marginLeft: '-9px'
    },
    // .MuiFormControlLabel-labelPlacementStart Styles applied to the root element if labelPlacement="start".
    labelPlacementStart: {
      marginLeft: 0,
      marginRight: 0,
      flexDirection: 'row-reverse',
      justifyContent: 'flex-end',
      '& $label': {
        marginRight: '8px'
      }
    },
    // .MuiFormControlLabel-labelPlacementTop Styles applied to the root element if labelPlacement="top".
    labelPlacementTop: {},
    // .MuiFormControlLabel-labelPlacementBottom Styles applied to the root element if labelPlacement="bottom".
    labelPlacementBottom: {},
    // .Mui-disabled Pseudo-class applied to the root element if disabled={true}.
    disabled: {
      opacity: 0.5
    },
    // .MuiFormControlLabel-label Styles applied to the label's Typography component.
    label: {
      fontSize: 14,
      fontWeight: 400,
      color: defaultTheme.colors.black
    }
  };
};
