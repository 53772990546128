export enum BadgeSize {
  Default = 'default',
  Large = 'large'
}

export interface BadgeProps {
  size?: BadgeSize;
  children?: React.ReactNode;
  color?: string;
  bgColor?: string;
  maxWidth?: string;
}
