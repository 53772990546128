import {
  DateTimeField,
  Form,
  FormValidationRules,
  InputField,
  RichTextField,
  SelectField,
  useForm
} from '@kit/components/Form';
import { Button, ButtonVariant } from '@kit/ui/Button';
import React, { useEffect } from 'react';
import { Call, CallDisposition, CallOutcome, CallReason } from '@generated/types/graphql';
import { capitalize } from 'lodash';
import { useCreateCall } from '@hooks/calls/useCreateCall';
import { useUpdateCall } from '@hooks/calls/useUpdateCall';
import { QueryParamsEnum, useQueryParamMutation } from '@hooks/useQueryParam';
import { Row, ActionButtons, BodyWithActionButtons } from './styled';

enum CallType {
  Inbound = 'Inbound',
  Outbound = 'Outbound'
}

type FormValues = {
  fromNumber: string;
  toNumber: string;
  type: { id: CallType; name: string };
  outcome: { id: CallOutcome; name: string };
  startTime: Date | null;
  reason: { id: string; name: string };
  disposition: { id: string; name: string };
  note: string;
};

interface Props {
  initialValues?: Call;
  recordId: number;
  onClose?: () => void;
}

const RULES: FormValidationRules<FormValues> = {
  fromNumber: {
    isRequired: true,
    validate: (value) => {
      if (!/^(\+|\()?(\d[\d\s-]*|\(\d+\)[\d\s-]*)$/.test(value)) {
        return 'Invalid phone number';
      }

      return undefined;
    }
  },
  toNumber: {
    isRequired: true,
    validate: (value) => {
      if (!/^(\+|\()?(\d[\d\s-]*|\(\d+\)[\d\s-]*)$/.test(value)) {
        return 'Invalid phone number';
      }

      return undefined;
    }
  },
  type: {
    isRequired: true
  },
  outcome: {
    isRequired: true
  },
  startTime: {
    isRequired: true
  },
  reason: {
    isRequired: true
  },
  disposition: {
    isRequired: true
  }
};

type Option = { id: string; name: string };

function enumToOptions<T>(enumToConvert: T): Option[] {
  const options = Object.entries(enumToConvert).map(([_id, value]) => ({
    id: value,
    name: capitalize(value).replace('_', ' ')
  }));

  // place "Other" option at the end
  const otherOption = options.find((option) => option.name === 'Other');
  if (otherOption) {
    options.splice(options.indexOf(otherOption), 1);
    options.push(otherOption);
  }

  return options;
}

const INBOUND_OUTCOME_OPTIONS = [
  { id: CallOutcome.Answered, name: 'Answered' },
  { id: CallOutcome.Missed, name: 'Missed' }
];

const OUTBOUND_OUTCOME_OPTIONS = [
  { id: CallOutcome.Answered, name: 'Reached' },
  { id: CallOutcome.Missed, name: 'No answer' }
];

const TYPE_OPTIONS = enumToOptions(CallType);

const REASON_OPTIONS = enumToOptions(CallReason);

const DISPOSITION_OPTIONS = enumToOptions(CallDisposition);

export const CallForm = ({ recordId, initialValues, onClose }: Props) => {
  const isNew = !initialValues?.id;

  const { mutateAsync: create } = useCreateCall();
  const { mutateAsync: update } = useUpdateCall();

  const { setParams } = useQueryParamMutation();

  const postForm = async (values: FormValues) => {
    const payload = {
      fromNumber: values.fromNumber,
      toNumber: values.toNumber,
      isInbound: values.type.id === CallType.Inbound,
      outcome: values.outcome.id,
      reason: values.reason.id,
      disposition: values.disposition.id,
      projectId: recordId,
      startTime: values.startTime,
      endTime: values.startTime,
      note: values.note
    };

    if (isNew) {
      await create(payload);
    } else {
      await update({ id: initialValues!.id, dto: payload });
    }

    setParams(
      {
        [QueryParamsEnum.FeedCursor]: undefined,
        [QueryParamsEnum.FeedId]: undefined
      },
      true
    );

    onClose?.();
  };

  const { handleSubmit, form } = useForm<FormValues>({
    onSubmit: postForm,
    defaultValues: {
      type: TYPE_OPTIONS[0],
      outcome: INBOUND_OUTCOME_OPTIONS[0]
    }
  });

  const {
    formState: { isSubmitting },
    control,
    watch,
    setValue
  } = form;

  const type = watch('type');

  const isInbound = type?.id === CallType.Inbound;

  useEffect(() => {
    if (isInbound) {
      setValue('outcome', INBOUND_OUTCOME_OPTIONS[0]);
    } else {
      setValue('outcome', OUTBOUND_OUTCOME_OPTIONS[0]);
    }
  }, [isInbound, setValue]);

  return (
    <Form rules={RULES} onSubmit={handleSubmit}>
      <div>
        <Row>
          <InputField label="From" type="tel" name="fromNumber" control={control} />
          <InputField label="To" type="tel" name="toNumber" control={control} />
        </Row>
        <Row>
          <SelectField
            label="Direction"
            name="type"
            getOptionLabel={(option) => option.name}
            options={TYPE_OPTIONS}
            control={control}
            isClearable={false}
          />
          <SelectField
            label="Outcome"
            name="outcome"
            isClearable={false}
            getOptionLabel={(option) => option.name}
            options={isInbound ? INBOUND_OUTCOME_OPTIONS : OUTBOUND_OUTCOME_OPTIONS}
            control={control}
          />
        </Row>
        <DateTimeField withPortal label="Date & Time" name="startTime" control={control} />
        <Row>
          <SelectField
            label="Reason"
            name="reason"
            getOptionLabel={(option) => option.name}
            options={REASON_OPTIONS}
            control={control}
          />
          <SelectField
            label="Disposition"
            name="disposition"
            getOptionLabel={(option) => option.name}
            options={DISPOSITION_OPTIONS}
            control={control}
          />
        </Row>
      </div>
      <BodyWithActionButtons>
        <RichTextField name="note" control={control} placeholder="Type a note here..." />

        <ActionButtons isBodyWithError={false}>
          <Button variant={ButtonVariant.Flat} onClick={onClose}>
            Cancel
          </Button>
          <Button disabled={isSubmitting} type="submit" variant={ButtonVariant.Primary}>
            Add
          </Button>
        </ActionButtons>
      </BodyWithActionButtons>
    </Form>
  );
};
