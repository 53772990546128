import React from 'react';
import { TableBody as TableBodyMui, TableBodyProps } from '@material-ui/core';
import { useStylesTableBody } from './styled';

export const TableBody: React.FC<TableBodyProps> = (props) => {
  const classesTableBody = useStylesTableBody();

  return (
    <TableBodyMui
      classes={classesTableBody}
      {...props}
    />
  );
};
