import { getRecordTypeFromUrl, parseRecordIdFromUrl } from '@utils';
import {
  getRecordDetailsPagePath,
  getRecordActivityPagePath,
  getRecordPropertiesPagePath,
  getRecordKanbanPagePath,
  getRecordWorkPagePath,
  getRecordDatabankPagePath,
  getRecordMainPagePath,
  getPathnameByRecordType,
  isPipelinePage,
  getRecordListPagePath,
  getIntegrationSettingsPage,
  isContactPage,
  getRecordViewTypeFromUrl
} from '@utils/routes';
import { useLocation, navigate, useMatch } from '@reach/router';
import {
  selectIsProjectRelatedPage,
  selectIsCalendarPage,
  selectIsCalendarRelatedPage,
  selectWorkspaceId,
  selectIsDealRelatedPage
} from '@state/selectors';
import { useAppSelector } from './store';

export const useRoutes = () => {
  const location = useLocation();
  const isProjectRelatedPage = useAppSelector(selectIsProjectRelatedPage);
  const isDealRelatedPage = useAppSelector(selectIsDealRelatedPage);
  const isCalendarPage = useAppSelector(selectIsCalendarPage);
  const isCalendarRelatedPage = useAppSelector(selectIsCalendarRelatedPage);
  const companyId = useAppSelector(selectWorkspaceId);
  const recordType = getRecordTypeFromUrl(location);
  const viewType = (!!companyId && !!recordType && getRecordViewTypeFromUrl(location, companyId, recordType)) || null;

  return {
    companyId,
    isProjectRelatedPage,
    isDealRelatedPage,
    isCalendarPage,
    isCalendarRelatedPage,
    isContactPage: isContactPage(location),
    recordType,
    viewType,
    location,
    getPathnameByRecordType,
    getRecordDetailsPagePath,
    getRecordPropertiesPagePath,
    getRecordActivityPagePath,
    getRecordKanbanPagePath,
    getRecordWorkPagePath,
    getRecordDatabankPagePath,
    getRecordMainPagePath,
    getRecordListPagePath,
    getIntegrationSettingsPage,
    isPipelinePage: isPipelinePage(location),
    /** @deprecated use props like normal people */
    recordId: parseRecordIdFromUrl(location?.pathname, location.search),
    navigate,
    navigateTo: {
      recordDetailPage: (id: number) => {
        navigate(getRecordActivityPagePath({ companyId, id }));
      }
    },
    useMatch
  };
};
