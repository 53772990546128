import { Project, Task } from '@generated/types/graphql';

export type Site = {
  address: string;
  requests: Project[];
  workOrders: Task[];
  projects: Project[];
  estimates: any[]; // TODO
  systems: any[]; // TODO
};

export enum SiteTab {
  Requests = 'requests',
  Estimates = 'estimates',
  Projects = 'projects',
  Systems = 'systems',
  Service = 'service'
}
