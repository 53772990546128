import { useMutation, useQueryClient } from 'react-query';
import { FileComment } from '@generated/types/graphql';
import { FileCommentCreateDto, FileCommentUpdateDto } from '@types';
import api from '@services/api/fileCommentApi';
import { errorHandler } from '@services/api/helpers';
import { ReactQueryKey } from '@enums';
import { useToast } from '@hooks/useToast';
import { useRoutes } from '@hooks/useRoutes';

export const useFileCommentMutation = () => {
  const { showError, showSuccess } = useToast();
  const { companyId } = useRoutes();
  const queryClient = useQueryClient();

  const create = useMutation<FileComment, Error, { dto: FileCommentCreateDto; fileId: number }>(
    async ({ dto, fileId }) => {
      try {
        return (await api.create(companyId, fileId, dto)).data;
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(ReactQueryKey.ProjectActivity);
        showSuccess('Successfully created the comment');
      }
    }
  );

  const update = useMutation<void, Error, { dto: FileCommentUpdateDto; id: number }>(
    async ({ dto, id }) => {
      try {
        await api.update(companyId, id, dto);
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(ReactQueryKey.ProjectActivity);
        showSuccess('Successfully updated the comment');
      }
    }
  );

  const remove = useMutation<void, Error, { id: number }>(
    async ({ id }) => {
      try {
        await api.remove(id);
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(ReactQueryKey.ProjectActivity);
        showSuccess('Successfully deleted the comment');
      }
    }
  );

  return {
    create,
    update,
    remove
  };
};
