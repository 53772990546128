import React, { useEffect } from 'react';
import { TableCellProps } from '@material-ui/core';
import { useFirstCellMeasure } from '@components/Project/ProjectTableView/TableView/hooks';
import { StyledTableCell } from './styled';

interface Props extends TableCellProps {
  colspan?: number;
  width?: number;
  isSticky?: boolean;
  stickyPosition?: number;
  noBorder?: boolean;
  styles?: any;
  reportWidth?: boolean;
  isEditing?: boolean;
}

export const TableCell: React.FC<Props> = (props) => {
  const {
    colspan,
    width,
    isSticky,
    stickyPosition = 0,
    noBorder,
    styles,
    isEditing,
    reportWidth = false,
    ...rest
  } = props;

  const ref = React.useRef<HTMLElement>(null);

  const [globalWidth, setGlobalFirstCellWidth] = useFirstCellMeasure();
  const shouldReportWidth = reportWidth && !!ref.current;

  useEffect(() => {
    if (shouldReportWidth) {
      const newWidth = ref.current!.getBoundingClientRect().width;

      if (newWidth) {
        setGlobalFirstCellWidth(newWidth);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldReportWidth, globalWidth]);

  return (
    <StyledTableCell
      {...rest}
      colSpan={colspan}
      style={styles}
      ref={ref}
      width={width}
      isSticky={isSticky}
      stickyPosition={stickyPosition}
      noBorder={noBorder}
      isEditing={isEditing}
    />
  );
};
