import React from 'react';
import styled from 'styled-components';
import { device } from '@styles';
import Props from './types';
import ModalHeader from './ModalHeader';
import BackDrop from '../Backdrop';
import { modal } from '../../../styles';
import { Button, ButtonSize, ButtonVariant } from '@kit/ui/Button';

const StyledModal = styled.div`
  ${modal.modal};

  /* Temp styles, remove after refactor to Mui modal */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 130;

  ${device.sm`
    width: 100vw;
    height: 100vh;
  `};
`;
const Wrapper = styled.div`
  ${modal.wrapper};
  /* Temp styles, remove after refactor to Mui modal */
  z-index: 140;
`;

const Body = styled.div`
  ${modal.body};
`;

const Footer = styled.div`
  ${modal.footer};
`;

const Modal: React.FC<Props> = (props) => {
  const {
    channelType,
    show,
    title,
    deleteAllowed,
    deleteHandler,
    submitHandler,
    closeHandler,
    closeButton,
    submitText,
    bodyStyles, //temp
    disabled,
    hideCancel,
    enableKeyDownEvents = false,
    hideSubmit = false,
    size,
    justify = 'center'
  } = props;

  const onModalKeyDown = (event) => {
    if (!enableKeyDownEvents) return;
    event.stopPropagation();
    if (event.key === 'Enter') submitHandler();
    if (event.key === 'Escape') closeHandler();
  };

  const cancelButton = hideCancel ? null : (
    <Button
      onClick={closeHandler}
      data-testid="modalButtonCancel"
      variant={ButtonVariant.Secondary}
    >
      CANCEL
    </Button>
  );

  const deleteButton =
    deleteAllowed && channelType === 'update' ? (
      <Button
        style={{ backgroundColor: '#D54855', color: 'white' }}
        onClick={deleteHandler}
        data-testid="modalButtonDelete"
      >
        DELETE
      </Button>
    ) : null;

  return show ? (
    <StyledModal>
      <BackDrop isVisible={show} onClick={closeHandler} />
      <Wrapper onKeyDown={onModalKeyDown} size={size}>
        <ModalHeader
          title={title}
          handleClose={closeHandler}
          closeButton={closeButton}
        />
        <Body style={bodyStyles}>{props.children}</Body>
        <Footer justify={justify}>
          {deleteButton}
          {cancelButton}
          {!hideSubmit && (
            <Button
              variant={ButtonVariant.Primary}
              onClick={submitHandler}
              disabled={disabled}
              data-testid="modalButtonOk"
              data-analyticsid="modalSubmitBtn"
            >
              {submitText || 'SAVE'}
            </Button>
          )}
        </Footer>
      </Wrapper>
    </StyledModal>
  ) : null;
};

export default Modal;
