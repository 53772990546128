import React from 'react';

export const LineChartIcon = ({
  size = '16px',
  color = 'currentColor'
}: {
  size?: string | number;
  color?: string;
}) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.45455 1.5C2.98173 1.5 3.40909 1.92736 3.40909 2.45455V20.5909H21.5455C22.0726 20.5909 22.5 21.0183 22.5 21.5455C22.5 22.0726 22.0726 22.5 21.5455 22.5H2.45455C1.92736 22.5 1.5 22.0726 1.5 21.5455V2.45455C1.5 1.92736 1.92736 1.5 2.45455 1.5Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.4848 7.95964C20.8753 8.35017 20.8753 8.98333 20.4848 9.37385L14.9292 14.9294C14.5387 15.3199 13.9055 15.3199 13.515 14.9294L9.77767 11.1921L7.15144 13.8183C6.76092 14.2088 6.12775 14.2088 5.73723 13.8183C5.3467 13.4278 5.3467 12.7946 5.73723 12.4041L9.07056 9.07075C9.46109 8.68023 10.0943 8.68023 10.4848 9.07075L14.2221 12.8081L19.0706 7.95964C19.4611 7.56912 20.0943 7.56912 20.4848 7.95964Z"
      fill={color}
    />
  </svg>
);
