import { useCallback, useMemo } from 'react';
import querystring from 'querystring';
import { navigate } from '@reach/router';
import { isEqual } from 'lodash';
import { useLocation } from 'react-use';

export enum QueryParamsEnum {
  SmartViewId = 'smartViewId',
  FormId = 'formId',
  FileId = 'fileId',
  StageId = 'stageId',
  ProjectId = 'projectId',
  InboxProjectId = 'inboxProjectId',
  InboxFileId = 'inboxFileId',
  DatabankSearch = 'databankSearch',
  ShowArchived = 'showArchived',
  DatabankSortBy = 'databankSortBy',
  DatabankSortOrder = 'databankSortOrder',
  WorkOrderId = 'taskId',
  CalendarViewType = 'calendarView',
  WorkOrderTemplateId = 'workOrderTemplateId',
  ActionItemTemplateId = 'actionItemTemplateId',
  FromId = 'fromId',
  FeedCursor = 'feedCursor',
  FeedId = 'feedId',
  ReminderId = 'reminderId',
  RequestId = 'requestId',
  InvoiceId = 'invoiceId',
  Email = 'email',
  DashboardId = 'dashboardId'
}

type QueryParamValue = string | string[] | undefined;

type UseQueryParam = (
  name: QueryParamsEnum
) => [QueryParamValue, (newValue: QueryParamValue | number) => ReturnType<typeof navigate>];

export const useQueryParam: UseQueryParam = (queryParamName) => {
  const location = useLocation();
  const searchParams = useMemo(() => querystring.parse(location.search?.substring(1)), [location.search]);
  const currentValue = useMemo(() => searchParams[queryParamName], [queryParamName, searchParams]);
  const handleSetValue = useCallback(
    (newValue: QueryParamValue | number) => {
      if (isEqual(newValue, searchParams[queryParamName])) {
        return;
      }

      const copy = { ...searchParams };

      copy[queryParamName] = `${newValue}`;

      if (typeof newValue === 'undefined') {
        delete copy[queryParamName];
      }

      navigate(`?${querystring.encode(copy)}`);
    },
    [queryParamName, searchParams]
  );

  return [currentValue, handleSetValue];
};

export const useQueryParamMutation = () => {
  const location = useLocation();
  const searchParams = useMemo(() => querystring.parse(location.search?.substring(1)), [location.search]);

  const setParams = (params: { [key in QueryParamsEnum]?: any }, replace = false) => {
    const copy = { ...searchParams };
    Object.entries(params).forEach(([key, value]) => {
      copy[key] = `${value}`;

      if (typeof value === 'undefined') {
        delete copy[key];
      }
    });

    return navigate(`?${querystring.encode(copy)}`, { replace });
  };

  return { setParams };
};
