import { useFileMutation } from '@hooks/useFiles';
import { Form, FormValidationRules, SelectField, useForm } from '@kit/components/Form';
import { Button, ButtonVariant } from '@kit/ui/Button';
import React, { useMemo } from 'react';
import { useFormMutations } from '@hooks/useForms';
import { useRecordStageList } from '@hooks/records/useRecordStageList';
import { Body, Footer } from './styled';

type Doc = {
  id: number;
  name: string;
  form: {
    id: number;
  };
  type: string;
  projectId: number;
};

interface Props {
  doc: Doc;
  onClose: () => void;
}

type FormValues = {
  section: {
    id: number;
    name: string;
  };
};

const rules: FormValidationRules<FormValues> = {
  section: {
    isRequired: true
  }
};

export const MoveDoc = ({ doc, onClose }: Props) => {
  const {
    update: { mutateAsync: updateFile }
  } = useFileMutation();
  const {
    update: { mutateAsync: updateForm }
  } = useFormMutations();
  const { data: stages = [] } = useRecordStageList(doc.projectId);

  const options = useMemo(() => {
    return [...stages.map(({ id, name }) => ({ label: name, id })), { label: 'Other', id: -1 }];
  }, [stages]);

  const postForm = async ({ section }: FormValues) => {
    if (doc.type === 'form') {
      await updateForm({
        formId: doc.form.id,
        dto: {
          name: doc.name,
          stageId: section.id > 0 ? section.id : null
        }
      });
    } else {
      await updateFile({
        fileId: doc.id,
        stageId: section.id > 0 ? section.id : null
      });
    }

    onClose();
  };

  const { form, handleSubmit } = useForm({
    onSubmit: postForm,
    defaultValues: { name: doc.name }
  });

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <Body>
        <SelectField
          getOptionLabel={(option) => option.label}
          options={options}
          control={form.control}
          name="section"
          label="Move to"
        />
      </Body>
      <Footer>
        <Button variant={ButtonVariant.Secondary} onClick={onClose}>
          Cancel
        </Button>
        <Button variant={ButtonVariant.Primary} type="submit">
          Move
        </Button>
      </Footer>
    </Form>
  );
};
