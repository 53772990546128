import * as config from '@services/service.config';
import { patch, post } from '@services/api/base';

export type UpdateFormFieldValueDto = {
  fieldId: number;
  value: any;
  action: 'replace' | 'add' | 'remove';
};

export const formsApi = {
  export: ({
    formId,
    ...exportConfig
  }: {
    formId: number;
    saveAsFile: boolean;
    includeImageMetadata: boolean;
    imagesInRow: number;
    exportType: 'PDF' | 'ZIP';
    imagesOnly: boolean;
  }) =>
    post(`${config.dataBankService}/forms/${formId}/export`, exportConfig, { axiosParams: { responseType: 'blob' } }),
  updateFormFieldValue: ({
    clientSocketId,
    formId,
    companyId,
    dto
  }: {
    clientSocketId: string;
    formId: number;
    companyId: number;
    dto: UpdateFormFieldValueDto;
  }) =>
    patch(
      `${config.formBuilderUrl()}/${formId}/columns/values?companyId=${companyId}&clientSocketId=${clientSocketId}`,
      dto
    )
};
