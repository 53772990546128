import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100vw;
  min-width: 100vw;
  min-height: 100vh;
  max-width: 100vw;
  position: relative;
`;

export const ContentUnderTopMenu = styled.div`
  @media screen and (max-width: 767px) {
    max-height: calc(100vh - 48px);
    overflow: auto;
  }
`;
