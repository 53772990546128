import { Search } from '@components/Project/ProjectView/types';

import { post, put, get, deleteRequest } from './base';
import { generateParams } from './helpers';
import { Urls } from './base/axios';
import { FormTemplateApiRequest } from './types';

type GenerateParamsArgumentsType = Parameters<typeof generateParams>[0];
export default {
  find: (search: Partial<Search>) => {
    return get(`${Urls.dataBank}/form-templates`, generateParams({ search }));
  },

  create: (req: FormTemplateApiRequest, params: GenerateParamsArgumentsType) =>
    post(`${Urls.dataBank}/form-templates`, req, generateParams(params)),

  update: (id: number, req: Partial<FormTemplateApiRequest>, companyId?: number) =>
    put(`${Urls.dataBank}/form-templates/${id}${companyId ? `?companyId=${companyId}` : ''}`, req),

  remove: (id: number, companyId?: number) =>
    deleteRequest(
      `${Urls.dataBank}/form-templates/${id}${
        companyId ? `?companyId=${companyId}` : ''
      }`
    )
};
