import { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';

import { textField } from '@common/ui/TextField/styled';
import { selectMuiNew } from '@styles';
import { useStylesCheckbox } from '@common/ui/Select/styled';
import { useStylesRadio } from '../styles/Components/mui/radio';

export const useMuiClasses = () => {
  const classesRadio = useStylesRadio();
  const classesTextField = makeStyles(textField())();
  const classesSelect = makeStyles(selectMuiNew())();
  const classesCheckbox = useStylesCheckbox();

  const classes = useMemo(() => ({
    textField: classesTextField,
    select: classesSelect,
    checkbox: classesCheckbox,
    radio: classesRadio
  }), [classesCheckbox, classesRadio, classesSelect, classesTextField]);

  return classes;
};
