import { selectIsGeolocationDebugEnabled } from '@state/selectors';
import usePlacesAutocomplete from 'use-places-autocomplete';
import { useAppSelector } from '@hooks/store';

const defaultOption = {
  componentRestrictions: {
    country: ['us']
  }
};

export const usePlaces = () => {
  const geolocationDebug = useAppSelector(selectIsGeolocationDebugEnabled);

  return usePlacesAutocomplete(geolocationDebug ? {} : { requestOptions: defaultOption });
};
