import React, { useState } from 'react';
import { List } from 'react-feather';

import { TabItem, Tabs } from '@kit/ui/Tabs';
import { QueryParamsEnum, useQueryParamMutation } from '@hooks/useQueryParam';
import { useUpdateEffect } from '@react-hookz/web';
import { useSystem } from '@hooks/systems/useSystem';
import { Comments } from './Comments';
import { Search } from './Search';
import { Header, WidgetTitle, WidgetIcon, TabsAndSearch } from './styled';
import { Syncs } from './Syncs';

const TABS: TabItem[] = [
  {
    id: 'comments',
    title: 'Comments'
  },
  {
    id: 'syncs',
    title: 'Syncs'
  }
];

interface Props {
  systemId: number;
}

export const Feed = ({ systemId }: Props) => {
  const [tab, setTab] = useState(TABS[0]);

  const { data: system } = useSystem(systemId);

  const [search, setSearch] = useState('');

  const { setParams } = useQueryParamMutation();

  useUpdateEffect(() => {
    setParams(
      {
        [QueryParamsEnum.FeedCursor]: undefined,
        [QueryParamsEnum.FeedId]: undefined
      },
      true
    );
  }, [tab]);

  if (!system) {
    return null;
  }

  return (
    <>
      <div>
        <Header>
          <WidgetTitle>
            <WidgetIcon backgroundColor="#ECD4F0">
              <List size="16px" color="#B04EC2" />
            </WidgetIcon>
            Feed
          </WidgetTitle>
        </Header>

        <TabsAndSearch>
          <Tabs variant="outline" tabs={TABS} selected={tab.id} onChange={setTab} />
          <Search onChange={setSearch} />
        </TabsAndSearch>
      </div>

      {tab.id === 'comments' && <Comments clientId={system.project?.id} systemId={systemId} search={search} />}

      {tab.id === 'syncs' && <Syncs systemId={systemId} />}
    </>
  );
};
