import styled from 'styled-components';

export const Container = styled.div`
  width: 752px;
`;

export const Body = styled.div`
  padding: 24px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 24px;
  padding: 16px 24px;
  background-color: #fff;
`;

export const SquareRadios = styled.div`
  .MuiFormGroup-row {
    gap: 24px;
  }
  .MuiFormControlLabel-root {
    cursor: pointer;
    align-items: flex-start;

    .MuiFormControlLabel-root {
      align-items: center;
    }
  }

  .MuiIconButton-root {
    min-height: unset;
  }
`;

export const RadioLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 16px;
  margin-top: 2px;
`;

export const StepLabel = styled.div`
  margin-bottom: 24px;
`;

export const FieldLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
`;

export const PhotosPerRow = styled.div`
  display: flex;
  gap: 40px;
  align-items: center;
  margin-bottom: 16px;

  > * {
    margin: 0;
  }
`;
