export enum TaskEventType {
  CREATED = 'CREATED',
  ASSIGNED = 'ASSIGNED',
  ASSIGNEE_ASSIGNED = 'ASSIGNEE_ASSIGNED',
  COMPLETED = 'COMPLETED',
  UPDATED = 'UPDATED',
  DELETED = 'DELETED',
  UNASSIGNED = 'UNASSIGNED',
  DUE_DATE = 'DUE_DATE',
  DUE_DATE_PASSED = 'DUE_DATE_PASSED',
  MOVED = 'MOVED',
  COMMENTED = 'COMMENTED',
  TASK_GROUP = 'TASK_GROUP',
  ASSIGNED_TO_ME = 'ASSIGNED_TO_ME',
  RESCHEDULED = 'RESCHEDULED',
  COMMENT_MENTIONED = 'COMMENT_MENTIONED',
  STATUS_MOVED = 'STATUS_MOVED',
  VISIT_SCHEDULED = 'VISIT_SCHEDULED'
}

export enum FormEventType {
  CREATED = 'CREATED',
  ASSIGNED = 'ASSIGNED',
  COMPLETED = 'COMPLETED',
  UPDATED = 'UPDATED',
  DELETED = 'DELETED',
  UNASSIGNED = 'UNASSIGNED',
  ARCHIVED = 'ARCHIVED'
}

export enum TeamEventType {
  CREATED = 'CREATED',
  DELETED = 'DELETED',
  MEMBER_ADDED = 'MEMBER_ADDED',
  MEMBER_REMOVED = 'MEMBER_REMOVED',
  UPDATED = 'UPDATED'
}

export enum ProjectEventType {
  MEMBER_ADDED = 'MEMBER_ADDED',
  MEMBER_REMOVED = 'MEMBER_REMOVED',
  CREATED = 'CREATED',
  DELETED = 'DELETED',
  ARCHIVED = 'ARCHIVED',
  COMPLETED = 'COMPLETED',
  UPDATED = 'UPDATED',
  COMMENTED = 'COMMENTED',
  PROPERTY_UPDATED = 'PROPERTY_UPDATED',
  ADDED_AS_KEY_MEMBER = 'ADDED_AS_KEY_MEMBER',
  STAGE_UPDATED = 'STAGE_UPDATED',
  COMMENT_MENTIONED = 'COMMENT_MENTIONED'
}

export enum FolderEventType {
  UPDATED = 'UPDATED',
  DELETED = 'DELETED',
  ARCHIVED = 'ARCHIVED'
}

export enum WorkflowEventType {
  WORKFLOW_EXECUTED = 'WORKFLOW_EXECUTED',
  EXECUTED_SUCCESSFULLY = 'EXECUTED_SUCCESSFULLY',
  FAILED_TO_EXECUTE = 'FAILED_TO_EXECUTE'
}

export enum ReminderEventType {
  DUE_DATE = 'DUE_DATE',
  ASSIGNED_TO_ME = 'ASSIGNED_TO_ME',
  RESCHEDULED = 'RESCHEDULED'
}

export enum LineItemEventType {
  CHANGE_ORDER = 'CHANGE_ORDER'
}

export enum SmsEventType {
  RECEIVED = 'RECEIVED',
  SENT = 'SENT'
}

export enum CallEventType {
  CALL_CREATED = 'CALL_CREATED'
}

export enum EmailEventType {
  RECEIVED = 'RECEIVED',
  SENT = 'SENT'
}

export enum FileEventType {
  COMMENTED = 'COMMENTED',
  COMMENT_MENTIONED = 'COMMENT_MENTIONED'
}

export enum PortalEventType {
  COMMUNICATION = 'COMMUNICATION',
  COMMUNICATION_MENTIONED = 'COMMUNICATION_MENTIONED'
}

export enum ActionEventType {
  ACTION_CREATED = 'ACTION_CREATED',
  ASSIGNED_TO_CONTACT = 'ASSIGNED_TO_CONTACT',
  ACTION_UPDATED = 'ACTION_UPDATED',
  ACTION_DELETED = 'ACTION_DELETED'
}

export type EventTypes =
  | TaskEventType
  | FolderEventType
  | TeamEventType
  | FormEventType
  | ProjectEventType
  | WorkflowEventType
  | SmsEventType
  | EmailEventType
  | LineItemEventType
  | FileEventType
  | ReminderEventType
  | PortalEventType;
