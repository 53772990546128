import styled from 'styled-components';
import { colors } from '@styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: calc(100% - 52px);

  margin: 0 !important;
`;

export const Image = styled.img`
  width: 300px;
  object-fit: fill;
`;

export const Text = styled.p`
  margin: 30px 0 0 0;
  font-weight: 500;
  font-size: 24px;
  line-height: 1.25;

  color: ${colors.green};
`;
