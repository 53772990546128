import React from 'react';
import { Form } from 'formik';

type FormikFormType = {
  children?: React.ReactNode;
  className?: string;
};

const FormikForm: React.FC<FormikFormType> = (props) => {
  const { children, className } = props;
  return <Form className={className} autoComplete="off">{children}</Form>;
};

export default FormikForm;
