import { makeStyles } from '@material-ui/core/styles';

const tableBody = () => {
  return {
    root: {

    }
  };
};

export const useStylesTableBody = makeStyles(tableBody());
