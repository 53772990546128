import { colors, device, scroll } from '@styles';
import styled from 'styled-components';
import { Link } from 'gatsby';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #f7f7fc;
  overflow: auto;
`;
export const Wrapper = styled.div`
  padding: 16px;
  ${device.md`
    display: flex;
    overflow: auto;
    gap: 16px;

    > * {
      flex: 1;
    }
    
  `};
`;

export const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 48px);
`;

export const Content = styled.div`
  overflow: auto;
  ${scroll};
`;

export const HeaderAndTabs = styled.div`
  min-width: 0;
  width: 100%;
  padding: 16px;

  ${device.md`
  padding: 16px 16px 16px 24px;
  `};
`;

export const RightPanel = styled.div`
  width: 100%;
  flex-shrink: 0;
  background-color: ${colors.white};
  display: flex;
  border-radius: 8px;
  flex-direction: column;
  gap: 24px;
  position: relative;
  border: 1px solid #dfdfe8;
  ${scroll};
  overflow: auto;

  ${device.md`
    width: 50%;
  `};
`;

export const BackNavigationWrapper = styled.div`
  padding: 20px 0 30px;
`;

export const BackNavigationLink = styled(Link)`
  display: flex;
  align-items: center;
  color: #009688;
  text-decoration: none;

  & svg {
    margin-right: 6px;
  }
`;
