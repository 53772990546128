/**
 * This code is mostly copy-pasted from @hooks/useSmartViews.ts
 */
import { Property, PropertyType, SmartViewFilter } from '@types';

import { find, castArray, get, isArray, isEmpty, isString, map, reduce, set, toNumber } from 'lodash';
import { SmartViewDTO } from '@services/api/types';
import { covertFilterRecursive } from '@features/Analytics';
import { Filter } from '@generated/types/graphql';

interface MakeConvertValues<T extends SmartViewDTO | SmartViewFilter | SmartViewFilter[] = any> {
  (
    condition: (value: any) => boolean,
    convert: (value: any, convertNumber?: boolean) => string | Array<any>
  ): (smartViews: T | T[], properties?: Property[]) => T;
}
// Unused since the new Operators set
const makeConvertValues: MakeConvertValues = (condition, convert) => (smartViews, properties) => {
  const result = map(castArray(smartViews), (smartView) => {
    const advPath = 'conditions.search.filters';
    const advFilters = get(smartView, advPath);

    if (isEmpty(advFilters)) {
      return smartView;
    }

    set(
      smartView,
      advPath,
      reduce(
        advFilters,
        (acc, value, key) => {
          const multipleValue = ['=', '!=', 'in', 'notIn', '[=]', '[!=]', '[===]', '[!==]', '[like]'].includes(
            value.op
          );
          const matched = condition(value.val);
          const property = find(properties, { id: value.col });

          if (!multipleValue || !matched) {
            return {
              ...acc,
              [key]: value
            };
          }

          return {
            ...acc,
            [key]: {
              ...value,
              val: convert(value.val, property?.type !== PropertyType.Dropdown)
            }
          };
        },
        {}
      )
    );

    return smartView;
  });

  return isArray(smartViews) ? result : result[0];
};

export const convertMultiValuesToArray = makeConvertValues(
  (value) => isString(value),
  (value, convertNumber = true) =>
    value.split(',').map((item: string) => (convertNumber && /^\d+$/.test(item) ? toNumber(item) : item))
);

export const convertMultiValuesToString = makeConvertValues(
  (value) => isArray(value),
  (value) => value.join(',')
);

export const migrateOldFiltersToNew = (smartView: Filter): Filter => {
  if (!smartView.conditions.search?.filters) {
    return smartView;
  }

  const { filters } = smartView.conditions.search;

  if (!filters) {
    return smartView;
  }

  if (Object.keys(filters).length === 0) {
    return {
      ...smartView,
      conditions: {
        ...smartView.conditions,
        search: {
          ...smartView.conditions.search,
          filters: undefined
        }
      }
    };
  }

  if ('operator' in filters) {
    return smartView;
  }

  return {
    ...smartView,
    conditions: {
      ...smartView.conditions,
      search: {
        ...smartView.conditions.search,
        filters: covertFilterRecursive(filters)
      }
    }
  };
};
