import styled from 'styled-components';
import { getColorWithOpacity, scroll, device } from '@styles';

export const Wrapper = styled.div<{ isFocused: boolean; light: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  width: auto;
  transition: 0.3s;

  ${({ theme, isFocused, light }) => {
    if (light) {
      return `
        border-color: ${isFocused ? theme.colors.white : getColorWithOpacity(theme.colors.white, 30)};
        background: ${isFocused ? getColorWithOpacity(theme.colors.white, 10) : 'transparent'};      
      `;
    }

    return `
      border-color: ${theme.colors.gray3};
      background: ${theme.colors.white};   
    `;
  }}

  ${({ light }) => {
    if (light) {
      return `
        .MuiInputBase-root {
          background-color: transparent !important;
        }
        .MuiOutlinedInput-input {
          color: white !important;

          &::placeholder {
            color: white !important;
          }
        }
      `;
    }

    return null;
  }}
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.25;
  color: ${({ theme }) => theme.colors.gray2};
  border-right: 1px solid ${({ theme }) => theme.colors.gray3};
  padding: 0 8px;
  white-space: nowrap;

  .MuiInputBase-root {
    width: 100px;

    .MuiSelect-root {
      padding-left: 7px;
      padding-right: 30px;
    }

    ${device.sm`
      width: auto;
    `}
  }

  .MuiSelect-icon {
    display: block !important;
  }
`;

export const SearchButton = styled.button<{ isFocused: boolean; light: boolean }>`
  border: none;
  background: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  min-width: 20px;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  padding: 0;
  color: ${({ theme, isFocused }) => (isFocused ? theme.colors.green : theme.colors.gray)};

  ${({ theme, isFocused, light }) => {
    if (light) {
      return `
        color: ${isFocused ? theme.colors.white : getColorWithOpacity(theme.colors.white, 30)};
      `;
    }

    return null;
  }}
`;

export const ResultsWrapper = styled.div<{ isVisible: boolean }>`
  position: absolute;
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
  pointer-events: ${({ isVisible }) => (isVisible ? 'all' : 'none')};
  min-width: 100%;
  top: calc(100% + 2px);
  left: 0;
  background-color: #fff;
  padding: 0;
  border-radius: 4px;
  box-shadow: 0 10px 20px -20px #000;
  overflow: hidden;
  z-index: 1;
`;

export const ResultsBody = styled.div`
  max-height: 160px;
  overflow-y: auto;
  padding-top: 10px;

  ${scroll}
`;
