import UserPreferences from '@services/UserPreferences';
import { UiState } from './types';

// const actions = actionsExport as unknown as { [key: string]: string };

const initialState: UiState = {
  selectedCompany: UserPreferences.selectedCompanyId.get()
};

export type Payloads = any;
// export type Reducer = ReducerBaseType<UiState, Payloads>;
export const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case '@ui/setSelectedCompany': {
      return {
        ...state,
        selectedCompany: action.payload
      };
    }
    default:
      return state;
  }
};

export default reducer;
