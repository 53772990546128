import React from 'react';
import { RecordType } from '@types';
import { AccountIcon } from '@common/icons/Account';
import { DollarIcon } from '@kit/ui/icons/Dollar';
import { Briefcase } from 'react-feather';
import { Link } from '@reach/router';
import { useAppSelector } from '@hooks/store';
import { selectWorkspaceId } from '@state/selectors';
import { getPathnameByRecordType } from '@utils/routes';
import { RelatedRecordBadge } from './styled';

interface Props {
  record: {
    id: number;
    type: RecordType;
    title: string;
  };
  onClose: () => void;
}

const ICON_MAP: Record<RecordType, React.ReactNode> = {
  [RecordType.ACCOUNT]: <AccountIcon size={12} />,
  [RecordType.DEAL]: <DollarIcon size="12px" />,
  [RecordType.PROJECT]: <Briefcase size={12} />
};

export const RecordBadge = ({ record, onClose }: Props) => {
  const companyId = useAppSelector(selectWorkspaceId);

  return (
    <Link onClick={onClose} to={`/${companyId}/${getPathnameByRecordType(record.type)}/${record.id}`}>
      <RelatedRecordBadge>
        {ICON_MAP[record.type]}
        <div>{record.title}</div>
      </RelatedRecordBadge>
    </Link>
  );
};
