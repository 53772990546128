import React from 'react';
import { Globe } from 'react-feather';
import { FieldName, FieldNotes } from './styled';

interface Props {
  fieldName: string;
  notes: string;
  isProjectProperty?: boolean;
  isDisabled?: boolean;
}

export const FieldLabel = ({ fieldName, notes, isProjectProperty, isDisabled }: Props) => {
  return (
    <div>
      <FieldName isDisabled={isDisabled}>
        {fieldName}
        {isProjectProperty && <Globe size="16px" color="#9C9CAA" />}
      </FieldName>
      {notes && <FieldNotes>{notes}</FieldNotes>}
    </div>
  );
};
