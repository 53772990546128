import { ProjectRequestStatus } from '@generated/types/graphql';

export const PER_PAGE_OPTIONS = [10, 20, 50].map((value) => ({ label: value.toString(), value }));

export const STATUS_CONFIG: Record<ProjectRequestStatus, { label: string; color: string }> = {
  [ProjectRequestStatus.New]: {
    label: 'New',
    color: '#4D7CFE'
  },
  [ProjectRequestStatus.AppointmentScheduled]: {
    label: 'Appointment scheduled',
    color: '#778CA2'
  },
  [ProjectRequestStatus.AppointmentCompleted]: {
    label: 'Appointment completed',
    color: '#009A47'
  },
  [ProjectRequestStatus.Overdue]: {
    label: 'Appointment overdue',
    color: '#D54855'
  }
};
