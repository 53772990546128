import { ProjectStage } from '@generated/types/graphql';
import { useStageList } from '@hooks/workspace/stages/useStageList';
import { useMemo } from 'react';

export const useStagesMap = () => {
  const { data: stages = [], isLoading } = useStageList();

  const stagesMap = useMemo(
    () =>
      stages.reduce(
        (acc, stage) => {
          acc[stage.id] = stage;

          return acc;
        },
        {} as Record<number, ProjectStage>
      ),
    [stages]
  );

  return {
    stagesMap,
    isStagesLoading: isLoading
  };
};
