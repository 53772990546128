import post from '@services/api/base/post';
import { FileComment } from '@generated/types/graphql';
import { Urls } from '@services/api/base/axios';
import { FileCommentCreateDto, FileCommentUpdateDto } from '@types';
import { patch, deleteRequest } from '@services/api/base';

const BASE_URL = `${Urls.project}`;

export default {
  create: (companyId: number, fileId: number, dto: FileCommentCreateDto) =>
    post<FileComment>(`${BASE_URL}/files/${fileId}/comments`, dto, { companyId }),

  update: (companyId: number, id: number, dto: FileCommentUpdateDto) =>
    patch<FileComment>(`${BASE_URL}/comments/${id}`, dto, { companyId }),

  remove: (id: number) => deleteRequest(`${BASE_URL}/comments/${id}`)
};
