import { defaultTheme } from '@themes';

export const sliderStyles = () => {
  return {
    // .MuiSlider-root	Styles applied to the root element.
    root: {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      boxSizing: 'border-box',
      padding: '0',
      height: '40px'
    },
    // .MuiSlider-colorPrimary	Styles applied to the root element if color="primary".
    colorPrimary: {
      color: defaultTheme.colors.green
    },
    // .MuiSlider-colorSecondary	Styles applied to the root element if color="secondary".
    colorSecondary: {},
    // .MuiSlider-marked	Styles applied to the root element if marks is provided with at least one label.
    marked: {},
    // .MuiSlider-vertical	Pseudo-class applied to the root element if orientation="vertical".
    vertical: {},
    // .Mui-disabled	Pseudo-class applied to the root and thumb element if disabled={true}.
    disabled: {},
    // .MuiSlider-rail	Styles applied to the rail element.
    rail: {
      height: 8,
      borderRadius: 4,
      backgroundColor: '#DFDFE8',
      opacity: '1'
    },
    // .MuiSlider-track	Styles applied to the track element.
    track: {
      height: 8,
      borderRadius: 4,
      background: 'linear-gradient(90deg, #009688 0%, #19c9b9 100%)'
    },
    // .MuiSlider-trackFalse	Styles applied to the track element if track={false}.
    trackFalse: {},
    // .MuiSlider-trackInverted	Styles applied to the track element if track="inverted".
    trackInverted: {},
    // .MuiSlider-thumb	Styles applied to the thumb element.
    thumb: {
      width: '16px',
      height: '16px',
      marginTop: 0,
      marginLeft: -10,
      background: 'linear-gradient(90deg, #009688 0%, #19C9B9 100%)',
      border: '1px solid #FFFFFF',
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit'
      },
      '&:after': {}
    },
    // .MuiSlider-thumbColorPrimary	Styles applied to the thumb element if color="primary".
    thumbColorPrimary: {},
    // .MuiSlider-thumbColorSecondary	Styles applied to the thumb element if color="secondary".
    thumbColorSecondary: {},
    // .MuiSlider-active	Pseudo-class applied to the thumb element if it's active.
    active: {},
    // .Mui-focusVisible	Pseudo-class applied to the thumb element if keyboard focused.
    focusVisible: {},
    // .MuiSlider-valueLabel	Styles applied to the thumb label element.
    valueLabel: {
      fontSize: '12px',
      fontWeight: 400,
      left: '-50%',
      top: '15.5px',
      '& *': {
        background: 'transparent',
        color: '#1D1D35'
      }
    },
    // .MuiSlider-mark	Styles applied to the mark element.
    mark: {
      display: 'none'
    },
    // .MuiSlider-markActive	Styles applied to the mark element if active (depending on the value).
    markActive: {},
    // .MuiSlider-markLabel	Styles applied to the mark label element.
    markLabel: {
      fontSize: '12px',
      fontWeight: 400,
      color: '#828D9A'
    },
    // .MuiSlider-markLabelActive	Styles applied to the mark label element if active (depending on the value).
    markLabelActive: {}
  };
};
