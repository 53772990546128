import { useMemo } from 'react';
import { DateRangeOption, dateRangeConfig } from '../dateRangeConfig';
import { AnalyticsWidgetDateRangeType } from '../types';

const EXCLUDED_DATE_RANGES: AnalyticsWidgetDateRangeType[] = [
  AnalyticsWidgetDateRangeType.CUSTOM,
  AnalyticsWidgetDateRangeType.ALL_TIME,
  AnalyticsWidgetDateRangeType.CUSTOM_TO_DATE
];

export const useDateRangeOptions = (): DateRangeOption[] => {
  return useMemo(() => Object.values(dateRangeConfig).filter(({ id }) => !EXCLUDED_DATE_RANGES.includes(id)), []);
};
