import { post, put } from './base';

import { Urls } from './base/axios';

export default {
  generateResetPasswordToken: (email: string) =>
    post(`${Urls.user}/users/password-token`, { email }),
  resetPassword: (token: string, password: string) =>
    put(`${Urls.user}/users/password-reset`, { token, password })
};
