import React, { useCallback, useMemo } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { Select, CreatableSelect } from '@kit/ui/Select';
import { identity, isPlainObject } from 'lodash';
import { FormField } from '../FormField';
import { FormControl, FormInputPropsToOmit } from '../types';
import { useControllerWithValidation } from '../useControllerWithValidation';

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends Omit<React.ComponentProps<typeof Select>, FormInputPropsToOmit>,
    FormControl<TFieldValues, TName> {
  isMulti?: boolean;
  isCreatable?: boolean;
  getOptionValue?: (option: any) => any;
  getOptionLabel?: (option: any) => string;
  fieldValueIsOptionValue?: boolean;
}

const getOptionLabelDefault = (option: any) => (typeof option === 'string' ? option : option.name || option.label);

const SelectFieldInner = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  label,
  description,
  name,
  hint,
  control,
  getOptionValue = identity,
  getOptionLabel = getOptionLabelDefault,
  options,
  isCreatable,
  clearOnUnmount,
  fieldValueIsOptionValue = false,
  ...inputProps
}: Props<TFieldValues, TName>) => {
  const {
    field: { value, onChange, ...controlProps },
    fieldState: { error }
  } = useControllerWithValidation(name, control, label, clearOnUnmount);

  const handleChange = useCallback(
    (_: any, newValue: TFieldValues) => {
      onChange(fieldValueIsOptionValue ? getOptionValue(newValue) || null : newValue);
    },
    [onChange, fieldValueIsOptionValue, getOptionValue]
  );

  const valueForSelect = useMemo(() => {
    const getOptionByValue = (optionValue: any) => {
      if (isPlainObject(optionValue)) {
        return optionValue;
      }

      return options.find((option) => getOptionValue(option) === optionValue);
    };

    if (inputProps.isMulti) {
      const valueOrEmptyArray = value ?? [];

      return (Array.isArray(valueOrEmptyArray) ? valueOrEmptyArray : [value]).map(getOptionByValue).filter(Boolean);
    }

    return getOptionByValue(value) ?? null;
  }, [inputProps.isMulti, value, getOptionValue, options]);

  const SelectComponent = isCreatable ? CreatableSelect : Select;

  return (
    <FormField hint={hint} name={name} label={label} error={error?.message} description={description}>
      <SelectComponent
        options={options}
        {...controlProps}
        {...inputProps}
        value={valueForSelect}
        data-test-id={`field-${controlProps.name}`}
        onChange={handleChange}
        getOptionLabel={getOptionLabel}
      />
    </FormField>
  );
};

export const SelectField = React.memo(SelectFieldInner) as typeof SelectFieldInner;
