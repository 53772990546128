import styled from 'styled-components';
import { getLighterBgColor } from '@utils';

export const Badge = styled.div<{ color: string; bgColor?: string }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  column-gap: 4px;
  max-width: 100%;
  height: 24px;
  border-radius: 4px;
  padding: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.25;
  color: ${(props) => props.color};
  background-color: ${({ color, bgColor }) => bgColor || getLighterBgColor(color)};
  white-space: nowrap;

  svg {
    min-width: 15px;
    width: 15px;
    height: 15px;
  }

  span {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export default Badge;
