import React from 'react';

import { useRemoteSearch, UseRemoteSearchProps } from '@hooks';
import { FilterSelect, FilterSelectProps } from '@components/common/FilterSelect';
import { capitalizeFirstLetter } from '@utils';

type InheritedProps = Pick<
  FilterSelectProps,
  'label' | 'isWorkspaces' | 'allowAll' | 'optionValueKey' | 'className' | 'grouping'
>;

export interface FilterSelectWithSearchProps<T extends { id: number } = any> extends InheritedProps {
  fieldName?: string;
  getItems?: UseRemoteSearchProps['getItems'],
  options?: T[];
  selectedOptions?: T['id'][];
  onSelect: (values: { [key in keyof T]: T[keyof T] | T[keyof T][] }, rawValue: T['id']) => unknown;
  getTextForMenu?: (option: T) => string;
  defaultValue?: any;
}

export function FilterSelectWithSearch<T extends { id: number | string; }>(props: FilterSelectWithSearchProps<T>) {
  const {
    fieldName,
    getItems,
    label,
    isWorkspaces,
    options,
    selectedOptions,
    onSelect,
    getTextForMenu = (option: { name: string; }) => option.name,
    defaultValue,
    ...rest
  } = props;
  const { items, initialItems, search, isLoading } = useRemoteSearch({ getItems });

  const defaultOptions = !getItems ? options : initialItems;

  return (
    <FilterSelect
      label={capitalizeFirstLetter(label || fieldName)}
      selectedValue={selectedOptions}
      onChange={({ target }) => onSelect?.({ [`${fieldName || label}`]: target.value }, target.value)}
      data-analyticsid={`filterSelect_${fieldName || label}`}
      onInputValueChange={search}
      options={options || items}
      defaultOptions={defaultOptions}
      optionValueKey="id"
      getTextForMenu={isWorkspaces ? undefined : getTextForMenu}
      withTextFilter={!isWorkspaces}
      isLoading={isLoading}
      isWorkspaces={isWorkspaces}
      defaultValue={defaultValue}
      {...rest}
    />
  );
};
