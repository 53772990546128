import React from 'react';

export const MoveToFolderIcon = ({
  size = '16px',
  color = 'currentColor'
}: {
  size?: string | number;
  color?: string;
}) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 4C3.44772 4 3 4.44772 3 5V19C3 19.5523 3.44772 20 4 20H20C20.5523 20 21 19.5523 21 19V8C21 7.44772 20.5523 7 20 7H11C10.6656 7 10.3534 6.8329 10.1679 6.5547L8.46482 4H4ZM1 5C1 3.34315 2.34315 2 4 2H9C9.33435 2 9.64658 2.1671 9.83205 2.4453L11.5352 5H20C21.6569 5 23 6.34315 23 8V19C23 20.6569 21.6569 22 20 22H4C2.34315 22 1 20.6569 1 19V5Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.75 13.5C15.75 13.9142 15.4142 14.25 15 14.25L9 14.25C8.58579 14.25 8.25 13.9142 8.25 13.5C8.25 13.0858 8.58579 12.75 9 12.75L15 12.75C15.4142 12.75 15.75 13.0858 15.75 13.5Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5303 12.9697C15.8232 13.2626 15.8232 13.7374 15.5303 14.0303L13.2803 16.2803C12.9874 16.5732 12.5126 16.5732 12.2197 16.2803C11.9268 15.9874 11.9268 15.5126 12.2197 15.2197L13.9393 13.5L12.2197 11.7803C11.9268 11.4874 11.9268 11.0126 12.2197 10.7197C12.5126 10.4268 12.9874 10.4268 13.2803 10.7197L15.5303 12.9697Z"
      fill={color}
    />
  </svg>
);
