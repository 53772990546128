import React, { useCallback } from 'react';
import { Select } from '@kit/ui/Select';
import { ButtonVariant, IconButton } from '@kit/ui/Button';
import { AddFilterIcon } from '@kit/ui/icons/AddFilter';
import { XIcon } from '@kit/ui/icons/X';
import { AnalyticsModule } from '@features/Analytics/types';
import { Filter } from './types';
import { FilterRowContainer } from './styled';
import { getFilterOptionsGroupBy, useFilterOptions } from './useFilterOptions';
import { useFilterOperatorOptions } from './useFilterOperatorOptions';
import { FilterValue } from './FilterValue';

interface Props {
  filter: Filter;
  module: AnalyticsModule;
  onChange: (filter: Filter) => void;
  onRemove: (id: string) => void;
  onAddFilter: (parentId: string) => void;
}

export const FilterRow = ({ module, filter, onAddFilter, onChange, onRemove }: Props) => {
  const { options } = useFilterOptions(module);
  const operatorOptions = useFilterOperatorOptions(filter.field);

  const handleRemove = useCallback(() => {
    onRemove(filter.id);
  }, [onRemove, filter.id]);

  const handleAdd = useCallback(() => {
    onAddFilter(filter.id);
  }, [onAddFilter, filter.id]);

  const handleValueChange = useCallback(
    (value) => {
      onChange({ ...filter, value });
    },
    [onChange, filter]
  );

  const handleFilterFieldChange = useCallback(
    (_e, selected) => {
      onChange({ ...filter, field: selected, operator: null, value: null });
    },
    [onChange, filter]
  );

  const handleFilterOperatorChange = useCallback(
    (_e, selected) => {
      onChange({ ...filter, operator: selected, value: null });
    },
    [onChange, filter]
  );

  return (
    <FilterRowContainer>
      <Select
        getOptionLabel={(option) => option.name}
        value={filter.field ?? null}
        onChange={handleFilterFieldChange}
        placeholder="Select"
        options={options}
        groupBy={getFilterOptionsGroupBy}
      />
      <Select
        key={filter.field?.id} // select value (at least visually) is not cleared when field changes
        value={filter.operator ?? null}
        getOptionLabel={(option) => option.title}
        options={operatorOptions}
        onChange={handleFilterOperatorChange}
      />
      <FilterValue module={module} filter={filter} onChange={handleValueChange} />
      <IconButton type="button" variant={ButtonVariant.Flat} onClick={handleAdd}>
        <AddFilterIcon size="24px" />
      </IconButton>
      <IconButton type="button" variant={ButtonVariant.Flat} onClick={handleRemove}>
        <XIcon size="24px" color="#D54855" />
      </IconButton>
    </FilterRowContainer>
  );
};
