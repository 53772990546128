import React from 'react';

export const DrawLineIcon = ({ size = '16px', color = 'currentColor' }: { size?: string | number; color?: string }) => (
  <svg width={size} height={size} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2247 1.33276C12.4747 1.0828 12.8138 0.942383 13.1673 0.942383C13.5209 0.942383 13.8599 1.0828 14.11 1.33276L15.1674 2.39017C15.4173 2.64021 15.5578 2.97935 15.5578 3.33291C15.5578 3.68646 15.4174 4.02554 15.1675 4.27557L9.83413 9.60891C9.8341 9.60893 9.83415 9.60888 9.83413 9.60891C9.58415 9.85893 9.24502 9.9995 8.89146 9.99957H7.83398C7.48036 9.99957 7.14122 9.8591 6.89118 9.60905C6.64113 9.359 6.50065 9.01986 6.50065 8.66624V7.60891C6.50073 7.25535 6.64123 6.91615 6.89125 6.66617C6.89122 6.66619 6.89127 6.66615 6.89125 6.66617L12.2247 1.33276ZM12.1101 3.33291L13.1673 4.3901L14.2245 3.33291L13.1673 2.27572L12.1101 3.33291ZM12.2245 5.33291L11.1673 4.27572L7.83406 7.60898L7.83398 8.66624L8.89118 8.66624L12.2245 5.33291ZM2.41977 9.25203C2.79484 8.87696 3.30355 8.66624 3.83398 8.66624H4.50065C4.86884 8.66624 5.16732 8.96472 5.16732 9.33291C5.16732 9.7011 4.86884 9.99957 4.50065 9.99957H3.83398C3.65717 9.99957 3.4876 10.0698 3.36258 10.1948C3.23756 10.3199 3.16732 10.4894 3.16732 10.6662C3.16732 10.8431 3.23756 11.0126 3.36258 11.1376C3.4876 11.2627 3.65717 11.3329 3.83398 11.3329H13.1673C13.6978 11.3329 14.2065 11.5436 14.5815 11.9187C14.9566 12.2938 15.1673 12.8025 15.1673 13.3329C15.1673 13.8633 14.9566 14.372 14.5815 14.7471C14.2065 15.1222 13.6978 15.3329 13.1673 15.3329H10.5007C10.1325 15.3329 9.83398 15.0344 9.83398 14.6662C9.83398 14.2981 10.1325 13.9996 10.5007 13.9996H13.1673C13.3441 13.9996 13.5137 13.9293 13.6387 13.8043C13.7637 13.6793 13.834 13.5097 13.834 13.3329C13.834 13.1561 13.7637 12.9865 13.6387 12.8615C13.5137 12.7365 13.3441 12.6662 13.1673 12.6662H3.83398C3.30355 12.6662 2.79484 12.4555 2.41977 12.0805C2.0447 11.7054 1.83398 11.1967 1.83398 10.6662C1.83398 10.1358 2.0447 9.6271 2.41977 9.25203Z"
      fill={color}
    />
  </svg>
);
