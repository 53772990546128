import React, { CSSProperties } from 'react';
import { Property } from '@types';

export const renderPropertyIcon = ({
  property,
  style,
}: { property: Property, style?: CSSProperties }) => (property.iconUrl ? (
  <i style={{
    backgroundImage: `url("${property.iconUrl}")`,
    backgroundPosition: 'center',
    backgroundClip: 'initial',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    width: 24,
    height: 24,
    ...(style || {})
  }}
  />
) : undefined);
