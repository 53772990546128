import React from 'react';
import ReactDOM from 'react-dom';
import { SMART_VIEWS_TITLE_ROOT_ID } from './constants';
import { Asterisk } from './styled';

interface Props {
  emoji: string;
  name: string;
  isChanged: boolean;
}

export const SmartViewTitle = ({ emoji, name, isChanged }: Props) => {
  const container = document.getElementById(SMART_VIEWS_TITLE_ROOT_ID);

  if (!container) {
    return null;
  }

  return ReactDOM.createPortal(
    <div>
      <span>&nbsp;&nbsp;/&nbsp;</span>
      {emoji} {name}
      {isChanged && <Asterisk>{' *'}</Asterisk>}
    </div>,
    container
  );
};
