import React from 'react';
import { Main } from './styled';

interface Props {
  children: React.ReactNode;
}

export const Content = ({ children }: Props) => {
  return <Main>{children}</Main>;
};
